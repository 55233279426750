import React from "react";

import { ApolloConsumer } from "@apollo/react-hooks";
import { useSearchParams } from "react-router-dom";

import { setClientBranch } from "../../../../graphql/branch";
import { ScmStatus } from "../../../../types";
import useScmConfig from "../../../common/hooks/useScmConfig";

interface BranchContextValue {
  branch: string | undefined;
  initBranch: (s: ScmStatus | null) => void;
  selectedBranch: string | undefined;
  defaultBranch: string | undefined;
}

const initialBranchContext: BranchContextValue = {
  branch: undefined,
  selectedBranch: undefined,
  defaultBranch: undefined,
  initBranch: () => {}
};

export const BranchContext =
  React.createContext<BranchContextValue>(initialBranchContext);

interface Props {
  children: React.ReactNode;
}

export const BranchContextContainer = ({ children }: Props) => {
  const config = useScmConfig();
  const [searchParams] = useSearchParams();

  const urlBranch = searchParams.get("__branch");
  const defaultBranch = config?.branchName;

  const [selectedBranch, _setSelectedBranch] = React.useState(urlBranch || undefined);
  const [branch, _setBranch] = React.useState(urlBranch || undefined);

  const initBranch = React.useCallback(
    (s: ScmStatus | null) => {
      if (s === ScmStatus.CONNECTED && !selectedBranch && !branch) {
        _setBranch(defaultBranch);
      }
    },
    [selectedBranch, defaultBranch, branch]
  );

  React.useEffect(() => {
    _setSelectedBranch(urlBranch || undefined);
    _setBranch(urlBranch || undefined);
  }, [urlBranch]);

  const value = React.useMemo(
    (): BranchContextValue => ({
      selectedBranch,
      branch,
      defaultBranch,
      initBranch
    }),
    [selectedBranch, branch, defaultBranch, initBranch]
  );

  return (
    <BranchContext.Provider value={value}>
      <ApolloConsumer>
        {client => {
          setClientBranch(client, selectedBranch);
          return null;
        }}
      </ApolloConsumer>
      {children}
    </BranchContext.Provider>
  );
};

export const useBranchContext = () => React.useContext(BranchContext);
