import Icon from "../../../../../assets/spaceicons/json.svg";
import {
  SpaceComponentPackage,
  SpaceComponentHostType,
  SpaceComponentTags
} from "../../../../../types";
import { DEFAULT_SIZES, INPUT_MIN_WIDTH } from "../../../layout/constants";
import { ElementLayout } from "../../../layout/util";

import Config, { InlineConfig, TerseConfig } from "./Config";
import errorSelector from "./Config/errorSelector";
import componentConfigReducer, {
  ensureSpaceJsonInputComponent,
  makeInitialState,
  JsonInputConfigState,
  getComponentProperties
} from "./Config/reducer";
import { getSchema } from "./getSchema";
import SpaceJsonInput, { GUTTER_WIDTH } from "./SpaceJsonInput";
import { SpaceJsonInputComponent, SpaceJsonInputProperties } from "./types";

const pkg: SpaceComponentPackage<
  SpaceJsonInputProperties,
  SpaceJsonInputComponent,
  JsonInputConfigState
> = {
  type: "JSON_INPUT",
  displayName: "JSON Input",
  Icon,
  allowedHosts: new Set<SpaceComponentHostType>([
    "SPACE",
    "TABLE",
    "DETAIL",
    "CARD_LIST",
    "FUNCTION_FORM",
    "FUNCTION_MODAL_FORM",
    "FLEX_BOX",
    "CLOUD_UPLOADER"
  ]),
  Component: SpaceJsonInput,
  ConfigurationComponent: Config,
  TerseConfigurationComponent: TerseConfig,
  InlineConfigurationComponent: InlineConfig,
  componentConfigReducer,
  getInitialDraftState: makeInitialState,
  ensureComponent: ensureSpaceJsonInputComponent,
  getComponentProperties,
  getSchema,
  defaultElementLayout: new ElementLayout({
    width: DEFAULT_SIZES.STANDARD.width,
    height: DEFAULT_SIZES.INPUT.height,
    snapToGrid: false,
    minWidth: INPUT_MIN_WIDTH + GUTTER_WIDTH
  }),
  fallbackHeight: 96,
  allowSelfBinding: true,
  allowAncestorBinding: true,
  tags: [SpaceComponentTags.Input],
  isHeadless: false,
  errorSelector,
  isContainer: false
};

export default pkg;
