import React, { useMemo } from "react";

import { Icon } from "antd";
import { round } from "lodash";
import styled from "styled-components";

import { MAX_VIEW_ROWS } from "../../../../../constants";
import { AbsoluteErrorIcon } from "../../../../common/Icons";
import { AbsoluteWarningIcon } from "../../../../common/Icons/Icons";
import { WarningTooltip } from "../../../../common/ToolTips";
import { useStableSpaceContext } from "../../SpaceContext";
import { ColumnType } from "../common/ColumnListManager";
import { useEvaluatedConditions } from "../common/Conditionals/evalConditional";
import useRefreshComponent from "../common/useRefreshComponent";
import useView, { QueryExecutionRequirement } from "../common/useView";
import { Row } from "../common/useView/useView";
import ViewEnsurer from "../common/ViewEnsurer/ViewEnsurer";
import { useComponentStateContext } from "../contexts/ComponentStateContext";
import { AggregationFunction, AggregationFunctionType } from "../SpaceChart/types";
import { Props } from "../SpaceComponent";

import { LARGE_FONT_SIZE } from "./Config/DesignConfig";
import { ensureSpaceStatComponent } from "./Config/reducer";

export default function SpaceStat({ spaceComponent, hasConfigError, spaceApi }: Props) {
  const statComponent = ensureSpaceStatComponent(spaceComponent);
  const { spaceId } = useStableSpaceContext();
  const { input, updateOutput } = useComponentStateContext();
  const viewResult = useView(spaceId, statComponent, input, {
    limit: MAX_VIEW_ROWS,
    queryExecutionRequirement: !!statComponent?.properties?.is_filter_required
      ? QueryExecutionRequirement.ANY_FILTER
      : QueryExecutionRequirement.NONE
  });
  const { rows, loading, bindingsSatisfied, hasNextPage, refresh } = viewResult;

  useRefreshComponent(spaceComponent, spaceApi, refresh);

  const stat = aggregateView(rows || [], statComponent.properties.aggregation_function);

  React.useEffect(() => {
    updateOutput({ value: stat });
  }, [stat, updateOutput]);

  const { conditional_styles, theme_properties } = statComponent.properties;
  const conditionals = useMemo(
    () => conditional_styles.map(cs => cs.conditional_expression),
    [conditional_styles]
  );
  const evaledConditions = useEvaluatedConditions(conditionals, input);
  const conditionalStyleIndex = (evaledConditions || []).indexOf(true);
  const activeStyle = conditional_styles[conditionalStyleIndex];

  const statTextStyle = activeStyle?.rule_sets.find(
    rs => rs.target === "stat_text"
  )?.style;

  const errorIcon = hasConfigError ? <AbsoluteErrorIcon /> : null;
  const warningIcon = hasNextPage ? (
    <WarningIcon
      title={`This figure is incomplete. It is calculated based on a maximum of ${MAX_VIEW_ROWS} records, but there were more available.`}
    />
  ) : null;
  const feedbackIcon = errorIcon || warningIcon || null;

  const fontSize = theme_properties ? theme_properties["font-size"] : undefined;

  if (loading && bindingsSatisfied) {
    return (
      <Root>
        <ViewEnsurer viewResult={viewResult}>
          {feedbackIcon}
          <AdjustableText fontSize={fontSize}>
            <MidColorText>
              <Icon type="loading" />
            </MidColorText>
          </AdjustableText>
        </ViewEnsurer>
      </Root>
    );
  }

  if (evaledConditions === null) return null;

  const { label } = statComponent.properties;
  const content = (
    <Root>
      {feedbackIcon}
      <AdjustableText style={statTextStyle} fontSize={fontSize}>
        {stat}
      </AdjustableText>
      {label && <Label>{label}</Label>}
    </Root>
  );

  const canExecute = !statComponent.notVisibleFunctions?.edges.length;

  if (canExecute) {
    return content;
  }

  return (
    <WarningTooltip
      message="This component is disabled"
      description="You don't have access to one or more fields required to complete this action. Please contact your admin to update your permissions."
      placement="left"
    >
      <AbsoluteErrorIcon />
      {content}
    </WarningTooltip>
  );
}

function aggregateView(rows: Row[], func: AggregationFunction): string {
  switch (func.type) {
    case AggregationFunctionType.COUNT:
      return String(rows.length);
    case AggregationFunctionType.SUM: {
      const sum = rows.reduce((acc, curr) => {
        const col = curr.columns.find(c => {
          if (c[0] === ColumnType.ATTRIBUTE) {
            return c[2].sourceName === func.attribute;
          }
          return false;
        });
        if (!col) return acc;

        acc += parseFloat(col[3] as any);
        return acc;
      }, 0);
      return String(round(sum, 2)) || "0";
    }
    case AggregationFunctionType.PREAGGREGATED: {
      if (rows[0] === undefined) return "N/A";
      const col = rows[0].columns.find(c => {
        if (c[0] === ColumnType.ATTRIBUTE) {
          return c[2].sourceName === func.attribute;
        }
        return false;
      });
      if (!col) return "N/A";
      return String(round(parseFloat(col[3] as any), 2)) || "N/A";
    }
    default:
      return "N/A";
  }
}

const Root = styled.div`
  padding: ${props => props.theme.spacermd};
  border: solid 1px ${props => props.theme.borderGrey};
  border-radius: 6px;
  background-color: white;
  text-align: center;
`;

const AdjustableText = styled.div<{ fontSize?: string }>`
  font-size: ${props => props.fontSize || LARGE_FONT_SIZE};
`;

const Label = styled.div`
  min-height: 32px; // 2 lines
  line-height: 16px;
  color: ${props => props.theme.textColorMid};
`;

const MidColorText = styled.span`
  color: ${props => props.theme.textColorMid};
`;

const WarningIcon = styled(AbsoluteWarningIcon)`
  cursor: help;
  pointer-events: all;
`;
