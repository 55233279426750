import React from "react";

import classNames from "classnames";
import styled from "styled-components";

import Portal from "../components/common/Portal/Portal";
import ThemeContainer, { Theme } from "../components/common/ThemeContainer";
import OnboardingModal from "../components/onboarding/OnboardingModal/OnboardingModal";

import Header, { LightHeader } from "./Header/Header";

const LayoutRoot = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const LayoutContent = styled(LayoutRoot)`
  width: 100%;
`;

const Cols = styled.div`
  display: flex;
  position: relative;
  background: white;
  flex-grow: 1;
  overflow: hidden;
`;

const LeftColPortal = styled.div``;

const LeftColRoot = styled.div`
  height: 100%;
  max-width: 0;
  overflow: hidden;
  transition: all ${props => props.theme.animationFn};
  transform: translate(-250px);
  &.open {
    max-width: 250px;
    transform: translate(0);
  }
`;

const StyledThemeContainer = styled(ThemeContainer)`
  height: 100%;
`;

const NavWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  max-width: 250px;
  padding: 9px 0 24px 0;
`;

const NavWrapperDarkMode = styled(NavWrapper)`
  color: white;
  background: ${props => props.theme.leftNavBackgroundColor};
`;

export function LeftCol({
  children,
  open,
  darkMode = false,
  className = ""
}: {
  children: React.ReactNode;
  open: boolean;
  darkMode?: boolean;
  className?: string;
}) {
  const Wrapper = darkMode ? NavWrapperDarkMode : NavWrapper;
  return (
    <Portal id="left-col-portal">
      <LeftColRoot className={classNames(className, { open })}>
        <StyledThemeContainer theme={darkMode ? Theme.Dark : Theme.Default}>
          <Wrapper>{children}</Wrapper>
        </StyledThemeContainer>
      </LeftColRoot>
    </Portal>
  );
}

const Body = styled.main`
  display: flex;
  flex: 1;
  background: white;
  overflow-y: auto;
  transition: none;
  > * {
    flex: 1;
  }
  &.grey {
    background: ${props => props.theme.backgroundColor};
  }
  &.black {
    background: ${props => props.theme.surfacePrimaryTint};
  }
  &.padded {
    padding: 77px;

    &.inlineHeader {
      margin-top: ${props => props.theme.headerHeight};
    }
  }
`;

interface LayoutProps {
  backgroundColor?: "white" | "grey" | "black";
  inlineHeader?: boolean;
  children: React.ReactNode;
}

export default function Layout({ backgroundColor = "white", children }: LayoutProps) {
  return (
    <LayoutRoot>
      <div id="header-portal" />
      <Cols>
        <LeftColPortal id="left-col-portal" />
        <Body className={backgroundColor}>{children}</Body>
      </Cols>
      <OnboardingModal />
    </LayoutRoot>
  );
}

export function PaddedLayout({
  children,
  backgroundColor = "white",
  inlineHeader = false
}: LayoutProps) {
  return (
    <LayoutRoot>
      {!inlineHeader && <div id="header-portal" />}
      <Body
        className={`padded ${backgroundColor} ${inlineHeader ? "inlineHeader" : ""}`}
      >
        {children}
      </Body>
      <OnboardingModal />
    </LayoutRoot>
  );
}

export function BasicLayout(props: LayoutProps) {
  return (
    <Layout backgroundColor="grey">
      <Header />
      <>{props.children}</>
    </Layout>
  );
}

export function LightBasicLayout(props: LayoutProps) {
  return (
    <Layout backgroundColor="grey">
      <LightHeader />
      <>{props.children}</>
    </Layout>
  );
}

export function NoHeaderLayout({ backgroundColor, children }: LayoutProps) {
  return (
    <LayoutRoot>
      <Body className={backgroundColor}>{children}</Body>
    </LayoutRoot>
  );
}

export function NoHeaderSidebarLayout({ backgroundColor, children }: LayoutProps) {
  return (
    <LayoutContent>
      <Body className={backgroundColor}>{children}</Body>
    </LayoutContent>
  );
}
