import { Condition, Conjunction, Operator } from "./types";

export enum ConditionTypeTab {
  BUILDER = "Builder",
  JAVASCRIPT = "JavaScript"
}

export interface State {
  activeTab: ConditionTypeTab;
  loaded: boolean;
  expression?: string;
  conditions: Condition[];
  conjunctions: Conjunction[];
}

export function createInitialState(): State {
  return {
    activeTab: ConditionTypeTab.BUILDER,
    loaded: false,
    expression: undefined,
    conditions: [],
    conjunctions: []
  };
}

export enum ActionType {
  LOAD_CONDITION = "LOAD_CONDITION",
  ADD_CONDITION = "ADD_CONDITION",
  REMOVE_CONDITION = "REMOVE_CONDITION",
  SET_CONDITION_EXPRESSION = "SET_CONDITION_EXPRESSION",
  SET_CONDITION_PROPERTY = "SET_CONDITION_PROPERTY",
  SET_CONDITION_OPERATOR = "SET_CONDITION_OPERATOR",
  SET_CONDITION_VALUE = "SET_CONDITION_VALUE",
  SET_ACTIVE_TAB = "SET_ACTIVE_TAB"
}

export interface LoadConditionAction {
  type: ActionType.LOAD_CONDITION;
  payload: {
    conditions: Condition[];
    conjunctions: Conjunction[];
    expression?: string;
  };
}

export interface AddConditionAction {
  type: ActionType.ADD_CONDITION;
  payload: {
    conjunction?: Conjunction;
  };
}

export interface SetConditionExpressionAction {
  type: ActionType.SET_CONDITION_EXPRESSION;
  payload: {
    expression: string;
  };
}

export interface RemoveConditionAction {
  type: ActionType.REMOVE_CONDITION;
  payload: {
    conditionIndex: number;
  };
}

export interface SetConditionPropertyAction {
  type: ActionType.SET_CONDITION_PROPERTY;
  payload: {
    conditionIndex: number;
    property: string;
  };
}

export interface SetConditionOperatorAction {
  type: ActionType.SET_CONDITION_OPERATOR;
  payload: {
    conditionIndex: number;
    operator: Operator;
  };
}

export interface SetConditionValueAction {
  type: ActionType.SET_CONDITION_VALUE;
  payload: {
    conditionIndex: number;
    value: string;
  };
}

export interface SetActiveTabAction {
  type: ActionType.SET_ACTIVE_TAB;
  payload: {
    value: ConditionTypeTab;
  };
}

export type Action =
  | LoadConditionAction
  | AddConditionAction
  | RemoveConditionAction
  | SetConditionExpressionAction
  | SetConditionPropertyAction
  | SetConditionOperatorAction
  | SetConditionValueAction
  | SetActiveTabAction;

export function reducer(state: State, action: Action): State {
  switch (action.type) {
    case ActionType.LOAD_CONDITION: {
      return {
        ...state,
        conditions: action.payload.conditions,
        conjunctions: action.payload.conjunctions,
        expression: action.payload.expression,
        loaded: true
      };
    }
    case ActionType.SET_ACTIVE_TAB: {
      return {
        ...state,
        activeTab: action.payload.value
      };
    }
    case ActionType.ADD_CONDITION: {
      const conjunction = action.payload.conjunction;

      if (state.conditions.length && !conjunction) {
        throw new Error(
          "Must provide a conjunction for all conditions past the first one"
        );
      }

      return {
        ...state,
        conditions: state.conditions.concat({
          property: "",
          operator: undefined,
          value: "``" // default value to string mode
        }),
        conjunctions: conjunction
          ? state.conjunctions.concat(conjunction)
          : state.conjunctions
      };
    }
    case ActionType.REMOVE_CONDITION: {
      const conditions = state.conditions.filter(
        (_, index) => index !== action.payload.conditionIndex
      );

      return {
        ...state,
        conditions,
        conjunctions: state.conjunctions.filter(
          (_, index) => index !== action.payload.conditionIndex - 1
        )
      };
    }
    case ActionType.SET_CONDITION_EXPRESSION: {
      return {
        ...state,
        expression: action.payload.expression
      };
    }
    case ActionType.SET_CONDITION_PROPERTY: {
      const conditions = state.conditions.map((condition, index) => {
        if (index === action.payload.conditionIndex) {
          return {
            ...condition,
            property: action.payload.property
          };
        }

        return condition;
      });

      return {
        ...state,
        conditions
      };
    }
    case ActionType.SET_CONDITION_OPERATOR: {
      const conditions = state.conditions.map((condition, index) => {
        if (index === action.payload.conditionIndex) {
          return {
            ...condition,
            operator: action.payload.operator
          };
        }

        return condition;
      });

      return {
        ...state,
        conditions
      };
    }
    case ActionType.SET_CONDITION_VALUE: {
      const conditions = state.conditions.map((condition, index) => {
        if (index === action.payload.conditionIndex) {
          return {
            ...condition,
            value: action.payload.value
          };
        }

        return condition;
      });

      return {
        ...state,
        conditions
      };
    }
  }
}
