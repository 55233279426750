import React from "react";

import moment from "moment";
import styled from "styled-components";

import Spacer from "../../../common/Spacer";
import { B3 } from "../../../common/StyledComponents";
import AutomationToggle from "../AutomationToggle";
import { useAutomationById } from "../queries/AutomationById";

const VERB_MAP = {
  start: ["Started", "Starts"],
  stop: ["Stopped", "Stops"]
};

const NoDateText = styled.div`
  color: ${props => props.theme.textColorMid};
`;

interface DateProps {
  type: "start" | "stop";
  date: string | null;
}
const DateText = ({ type, date }: DateProps) => {
  if (!date) {
    return <NoDateText>No {type} date</NoDateText>;
  }
  const momentDate = moment(date);
  const isFuture = momentDate.valueOf() > Date.now();
  return (
    <B3>
      {VERB_MAP[type][+isFuture]} {momentDate.format("M/D/YY h:mm a z")}
    </B3>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px 0;
  margin: 24px 0;
  border-top: 1px solid ${props => props.theme.newContainerPrimaryTint};
  border-bottom: 1px solid ${props => props.theme.newContainerPrimaryTint};
`;

interface Props {
  id: string;
  onChange: () => void;
}

const AutomationStatus = ({ id, onChange }: Props) => {
  const { data, refetch } = useAutomationById({
    variables: {
      id
    }
  });
  const automation = data?.node;

  const handleStatusChange = React.useCallback(() => {
    refetch();
    onChange();
  }, [refetch, onChange]);

  if (!automation) return null;
  return (
    <Container>
      <AutomationToggle id={id} onComplete={handleStatusChange} />
      <Spacer size="md" />
      <DateText type="start" date={automation.startAt} />
      <Spacer size="sm" />
      <DateText type="stop" date={automation.endAt} />
    </Container>
  );
};

export default AutomationStatus;
