import React, { useMemo } from "react";

import { isEqual } from "lodash";

import { MAX_VIEW_ROWS } from "../../../../../../constants";
import { SpaceComponentObject } from "../../../../../../types";
import useCodeSandbox from "../../../../../common/CodeSandbox/useCodeSandbox";
import usePrevious from "../../../../../common/hooks/usePrevious";
import { createPath } from "../../../../../util/binding";
import { useStableSpaceContext } from "../../../SpaceContext";
import { useComponentStateContext } from "../../contexts/ComponentStateContext";
import injectState from "../../util/injectState";
import { Option, parseOptionsCsv } from "../HardcodedOptionFields/utils";
import { ensureSelectComponent } from "../SelectBackedComponent/Config/reducer";
import { getMinWidth } from "../SelectBackedComponent/utils";
import useNestedStatus from "../useNestedStatus";
import useView, { viewRowToStateRow, QueryExecutionRequirement } from "../useView";

export default function useSelectOptions(spaceComponent: SpaceComponentObject) {
  const component = useMemo(
    () => ensureSelectComponent(spaceComponent),
    [spaceComponent]
  );
  const { editMode, spaceId } = useStableSpaceContext();
  const { isInline } = useNestedStatus();
  const requiresHardcodedOptions = !spaceComponent.sourceType;
  const { input } = useComponentStateContext();
  const { evaluateExpressions, getConsoleError } = useCodeSandbox();
  const [evaluatedOptions, setEvaluatedOptions] = React.useState<
    { label: string; value: string }[]
  >([]);

  const { rows, hasNextPage, loading, refresh } = useView(
    spaceId,
    component,
    input || null,
    {
      limit: MAX_VIEW_ROWS,
      queryExecutionRequirement: !!component?.properties?.is_filter_required
        ? QueryExecutionRequirement.ANY_FILTER
        : QueryExecutionRequirement.NONE
    }
  );

  const previousRows = usePrevious(rows);
  React.useEffect(() => {
    if (!rows || !rows.length) {
      setEvaluatedOptions([]);
      return;
    }
    if (isEqual(previousRows, rows)) return;

    const inputStates: any[] = rows.map(row => {
      const stateRow = viewRowToStateRow(row);
      return injectState(input, createPath(["option", "data"]), stateRow.data);
    });

    const evalExpressions = async () => {
      try {
        const displayValues = await evaluateExpressions(
          [spaceComponent.properties.display_template],
          inputStates
        );
        const values = await evaluateExpressions(
          [spaceComponent.properties.value_template],
          inputStates
        );
        const options = (displayValues as string[]).map((label, index) => {
          return {
            label,
            value: (values as string[])[index]
          };
        });
        setEvaluatedOptions(options);
      } catch (e) {
        // noop in view mode because this can be valid (ie. due to inputState missing values)
        if (editMode) {
          console.warn(getConsoleError(e));
        }
      }
    };
    evalExpressions();
  }, [
    evaluateExpressions,
    rows,
    spaceComponent.properties.display_template,
    spaceComponent.properties.value_template,
    input,
    previousRows,
    editMode,
    getConsoleError
  ]);

  const hardcodedOptions = React.useMemo(() => {
    return requiresHardcodedOptions
      ? parseOptionsCsv(spaceComponent.properties.hardcoded_options || "")
      : [];
  }, [requiresHardcodedOptions, spaceComponent.properties.hardcoded_options]);

  const options: Option[] = React.useMemo(() => {
    let value: Option[] = [];
    if (editMode) return value;
    if (requiresHardcodedOptions) {
      value = hardcodedOptions;
    } else {
      value = evaluatedOptions;
    }
    return value;
  }, [editMode, requiresHardcodedOptions, hardcodedOptions, evaluatedOptions]);

  const minWidth = React.useMemo(() => {
    return getMinWidth(isInline, options, component.properties.placeholder);
  }, [isInline, options, component.properties.placeholder]);

  return {
    options,
    hasNextPage,
    loading: loading,
    minWidth,
    refresh
  };
}
