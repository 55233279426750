import React, { ReactElement } from "react";

import { Skeleton, Alert, Icon } from "antd";
import styled from "styled-components";

import InputWaitSvg from "../../../../../../assets/icons/box-right-arrow.svg";
import { SourceType, SpaceComponentObject } from "../../../../../../types";
import useDebouncedValue from "../../../../../common/hooks/useDebouncedValue";
import ViewErrorMessage from "../../../../../common/ViewErrorMessage";
import { assertNever } from "../../../../../util/assertNever";
import { useSpaceConsoleContext } from "../../../SpaceConsoleContext";
import EmptyState from "../EmptyState";
import { ViewResult, FetchType } from "../useView/useView";

export const MIN_TRANSITION = 300;

export enum ViewBlockingStates {
  PENDING = "PENDING",
  LOADING = "LOADING",
  LACKING_REQUIRED_FILTER = "LACKING_REQUIRED_FILTER",
  EDIT_MODE_BINDING_SOURCE = "EDIT_MODE_BINDING_SOURCE",
  MISSING_FUNCTION = "MISSING_FUNCTION",
  MISSING_FUNCTION_PERMISSION = "MISSING_FUNCTION_PERMISSION",
  VIEW_ERROR = "VIEW_ERROR",
  NO_DATA = "NO_DATA",
  NONE = "NONE"
}

const TERMINAL_VIEW_BLOCKING_STATE = [
  ViewBlockingStates.EDIT_MODE_BINDING_SOURCE,
  ViewBlockingStates.VIEW_ERROR,
  ViewBlockingStates.NO_DATA,
  ViewBlockingStates.MISSING_FUNCTION,
  ViewBlockingStates.NONE
];

export interface OverrideElements {
  [ViewBlockingStates.PENDING]?: ReactElement;
  [ViewBlockingStates.LOADING]?: ReactElement;
  [ViewBlockingStates.LACKING_REQUIRED_FILTER]?: ReactElement;
  [ViewBlockingStates.EDIT_MODE_BINDING_SOURCE]?: ReactElement;
  [ViewBlockingStates.MISSING_FUNCTION]?: ReactElement;
  [ViewBlockingStates.MISSING_FUNCTION_PERMISSION]?: ReactElement;
  [ViewBlockingStates.VIEW_ERROR]?: ReactElement;
  [ViewBlockingStates.NO_DATA]?: ReactElement;
  [ViewBlockingStates.NONE]?: ReactElement;
}

export interface UseCommonViewComponentStatesOptions {
  component: SpaceComponentObject;
  viewResult: ViewResult;
  editMode: boolean;
  overrides?: OverrideElements;
}

export default function useViewBlockingStates({
  component,
  viewResult,
  editMode,
  overrides
}: UseCommonViewComponentStatesOptions) {
  const spaceConsole = useSpaceConsoleContext();
  const [viewBlockingState, _setViewBlockingState] = React.useState(
    ViewBlockingStates.PENDING
  );
  const setViewBlockingState = (nextEmptyState: ViewBlockingStates) =>
    nextEmptyState !== viewBlockingState && _setViewBlockingState(nextEmptyState);

  // Debounce view blocking state transition to prevent rapidly flashing
  // between states. Flush debounced value if a terminal state occurs.
  const debouncedEmptyState = useDebouncedValue(
    viewBlockingState,
    MIN_TRANSITION,
    TERMINAL_VIEW_BLOCKING_STATE.includes(viewBlockingState)
  );

  const isFilterRequirementMet =
    !component?.properties?.is_filter_required || !!viewResult.filters.length;
  const isLoading =
    viewResult.loading &&
    viewResult.fetchType === FetchType.INITIAL_FETCH &&
    isFilterRequirementMet;
  const isBindingSourceInEditMode =
    editMode && viewResult.sourceType === SourceType.BINDING;
  const hasMissingFunction =
    viewResult.sourceType === SourceType.VIEW && !viewResult.viewFunction;
  const missingFunctionPermissions =
    (component.notVisibleFunctions?.edges || []).length !== 0;
  const hasViewError = !!viewResult.errorCode;
  const lacksRequiredFilter = !viewResult.rows?.length && !isFilterRequirementMet;
  const noData =
    !viewResult.loading && (viewResult.rows === null || viewResult.rows.length === 0);

  if (isLoading) {
    setViewBlockingState(ViewBlockingStates.LOADING);
  } else if (isBindingSourceInEditMode) {
    setViewBlockingState(ViewBlockingStates.EDIT_MODE_BINDING_SOURCE);
  } else if (missingFunctionPermissions) {
    setViewBlockingState(ViewBlockingStates.MISSING_FUNCTION_PERMISSION);
  } else if (hasMissingFunction) {
    setViewBlockingState(ViewBlockingStates.MISSING_FUNCTION);
  } else if (hasViewError) {
    setViewBlockingState(ViewBlockingStates.VIEW_ERROR);
  } else if (lacksRequiredFilter) {
    setViewBlockingState(ViewBlockingStates.LACKING_REQUIRED_FILTER);
  } else if (noData) {
    setViewBlockingState(ViewBlockingStates.NO_DATA);
  } else {
    setViewBlockingState(ViewBlockingStates.NONE);
  }

  function getEmptyStateNode() {
    switch (debouncedEmptyState) {
      case ViewBlockingStates.PENDING:
        return overrides?.[ViewBlockingStates.PENDING] || <div />;
      case ViewBlockingStates.LOADING:
        return (
          overrides?.[ViewBlockingStates.LOADING] || (
            <PaddedContainer>
              <Skeleton active />
            </PaddedContainer>
          )
        );
      case ViewBlockingStates.EDIT_MODE_BINDING_SOURCE:
        return (
          overrides?.[ViewBlockingStates.EDIT_MODE_BINDING_SOURCE] || (
            <EmptyState
              message="Awaiting input from another component"
              icon={<Icon component={InputWaitSvg} />}
            />
          )
        );
      case ViewBlockingStates.MISSING_FUNCTION_PERMISSION:
        return editMode ? (
          overrides?.[ViewBlockingStates.MISSING_FUNCTION_PERMISSION] || (
            <EmptyState message="No data associated with this component." />
          )
        ) : (
          <Alert
            showIcon
            type="warning"
            message="This component is diabled."
            description="You don't have access to one or more fields required to complete this form. Please contact your admin to update your permissions."
          />
        );
      case ViewBlockingStates.MISSING_FUNCTION:
        return editMode ? (
          overrides?.[ViewBlockingStates.MISSING_FUNCTION] || (
            <EmptyState message={"No data associated with this component"} />
          )
        ) : (
          <Alert
            showIcon
            type="warning"
            message="This component needs to be updated."
            description="The function associated with this component cannot be found. It's possible that the name was changed or it was removed from your system."
          />
        );
      case ViewBlockingStates.VIEW_ERROR:
        return (
          overrides?.[ViewBlockingStates.VIEW_ERROR] || (
            <ViewErrorMessage
              errorCode={viewResult.errorCode!}
              customCTAText="See details"
              customOnClick={() => spaceConsole.setVisible(true)}
            />
          )
        );
      case ViewBlockingStates.LACKING_REQUIRED_FILTER:
        return (
          overrides?.[ViewBlockingStates.LACKING_REQUIRED_FILTER] || (
            <EmptyState message="Try searching or selecting a record to view data." />
          )
        );
      case ViewBlockingStates.NO_DATA:
        return (
          overrides?.[ViewBlockingStates.NO_DATA] || (
            <EmptyState
              message="This component's function returned no data."
              icon={<Icon type="search" />}
            />
          )
        );
      case ViewBlockingStates.NONE:
        return overrides?.[ViewBlockingStates.NONE] || null;
      default:
        assertNever(debouncedEmptyState);
    }
  }

  return {
    viewBlockingStateNode: getEmptyStateNode(),
    viewBlockingState: debouncedEmptyState
  };
}

const PaddedContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: ${props => props.theme.spacerlg};
`;
