import React from "react";

import { Collapse } from "antd";

import {
  APIFiltersOption,
  APISortByOption,
  FunctionParameterInput,
  ViewFilter
} from "../../../../../types";
import {
  PaginationParameterSet,
  ParameterDataValue,
  ParameterValues,
  ReservedListIdentifierMap,
  ReservedListParameter,
  SortingParameterSet
} from "../../index";

import { LeftAlignCollapsePanel } from "./common/styledComponents";
import { ParametersForm } from "./ParametersForm/ParametersForm";
import { SortByForm } from "./SortByForm/SortByForm";
import { ViewFiltersForm } from "./ViewFiltersForm/ViewFiltersForm";

export type Props = {
  filtersOptions: APIFiltersOption[];
  parameters: FunctionParameterInput[];
  sortByOptions: APISortByOption[];
  parameterValues: ParameterValues;
  onParameterValuesChange?: (values: ParameterValues) => void;
};

export const ParameterValuesForm = ({
  filtersOptions,
  parameters,
  sortByOptions,
  parameterValues,
  onParameterValuesChange = () => {}
}: Props) => {
  const onViewFiltersChange = (viewFilters: Partial<ViewFilter>[]) => {
    onParameterValuesChange({
      ...parameterValues,
      filters: viewFilters
    });
  };
  const onParameterValueChange = (name: string, value: ParameterDataValue) => {
    onParameterValuesChange({
      ...parameterValues,
      [name]: value
    });
  };

  const unreserved = parameters.filter(
    p => !ReservedListIdentifierMap[p.name as ReservedListParameter]
  );
  const sorting = parameters.filter(p =>
    SortingParameterSet.has(p.name as ReservedListParameter)
  );
  const pagination = parameters.filter(p =>
    PaginationParameterSet.has(p.name as ReservedListParameter)
  );

  const viewFilters = (parameterValues[ReservedListParameter.FILTERS] ||
    []) as Partial<ViewFilter>[];
  const filtersHeader = viewFilters.length
    ? `Filters (${viewFilters.length})`
    : "Filters";

  const sortBy = parameterValues[ReservedListParameter.SORT_BY] as string | undefined;
  const sortAscending = parameterValues[ReservedListParameter.SORT_ASCENDING] as
    | boolean
    | undefined;

  return (
    <div>
      <Collapse bordered={false} defaultActiveKey="parameters">
        {unreserved.length && (
          <Collapse.Panel key="parameters" header="Parameters">
            <ParametersForm
              parameters={unreserved}
              values={parameterValues}
              onParameterValueChange={onParameterValueChange}
            />
          </Collapse.Panel>
        )}
        {parameters.some(
          p =>
            p.name === ReservedListParameter.FILTERS ||
            p.name === ReservedListParameter.FILTER
        ) && (
          <LeftAlignCollapsePanel key="filters" header={filtersHeader}>
            <ViewFiltersForm
              filtersOptions={filtersOptions}
              viewFilters={viewFilters}
              onViewFiltersChange={onViewFiltersChange}
            />
          </LeftAlignCollapsePanel>
        )}
        {sorting.length && (
          <LeftAlignCollapsePanel key="sorting" header="Sorting">
            <SortByForm
              sortByOptions={sortByOptions}
              sortBy={sortBy}
              sortAscending={sortAscending}
              onParameterValueChange={onParameterValueChange}
            />
          </LeftAlignCollapsePanel>
        )}
        {pagination.length && (
          <LeftAlignCollapsePanel key="pagination" header="Pagination">
            <ParametersForm
              parameters={pagination}
              values={parameterValues}
              onParameterValueChange={onParameterValueChange}
            />
          </LeftAlignCollapsePanel>
        )}
      </Collapse>
    </div>
  );
};
