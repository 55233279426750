import React, { useEffect, useMemo } from "react";

import { SpaceComponentObject, SpaceComponentPackage } from "../../../../types";
import ErrorBoundary from "../../../common/ErrorBoundary";
import { reportException } from "../../../util/exceptionReporting";
import { AbsoluteElement, StaticElement, ElementProps } from "../../layout/Element";
import TransformingElement from "../../layout/Element/TransformingElement";
import { useCurrentTransformingComponentContext } from "../../layout/TransformationContext/TransformationContext";
import { PositionOption, ElementLayout, LayoutUnit } from "../../layout/util";
import { useSpaceConfigContext } from "../../SpaceConfig/SpaceConfigContext";
import { useStableSpaceContext } from "../SpaceContext";

import { SUPERFICIAL_COMPONENT_TYPES } from "./constants";
import { useComponentContext } from "./contexts/ComponentContext";
import { useComponentStateContext } from "./contexts/ComponentStateContext";

export default function LayoutComponent(props: {
  component: SpaceComponentObject;
  children: React.ReactNode;
}) {
  let Element: React.ComponentType<ElementProps>;
  const { editMode } = useStableSpaceContext();
  const { pkg } = useComponentContext();
  const { componentNode } = useComponentStateContext();
  const { transformingSlug, selectedSlug } = useCurrentTransformingComponentContext();
  const layout = useEnsuredLayout();
  const isTransformingElement =
    transformingSlug === props.component.slug ||
    (selectedSlug === props.component.slug &&
      layout?.position === PositionOption.ABSOLUTE);

  if (
    componentNode === undefined &&
    !SUPERFICIAL_COMPONENT_TYPES.includes(props.component.type)
  ) {
    return null;
  } else if (isTransformingElement) {
    Element = TransformingElement;
  } else if (editMode && pkg.forceTransformingElement) {
    Element = TransformingElement;
  } else if (pkg?.isHeadless && pkg.hasConfigIcon) {
    Element = AbsoluteElement;
  } else if (pkg?.isHeadless) {
    Element = HeadlessElement;
  } else if (layout?.position === PositionOption.STATIC) {
    Element = StaticElement;
  } else if (layout?.position === PositionOption.ABSOLUTE) {
    Element = AbsoluteElement;
  } else {
    Element = HeadlessElement;
    reportException(new Error("Could not determine LayoutElement to use."), {
      extra: {
        slug: props.component.slug,
        componentType: props.component.type,
        layout: props.component.layout
      }
    });
  }

  return (
    <Element layout={layout!} component={props.component}>
      <ErrorBoundary>{props.children}</ErrorBoundary>
    </Element>
  );
}

function HeadlessElement(props: ElementProps) {
  return <>{props.children}</>;
}

/*
  ensureLayout
  Enforces contraints on `ElementLayouts` as well as ensuring
  that all layout properties are present. Any missing layout
  properties will be defaulted.
*/
export function ensureLayout(
  component: SpaceComponentObject,
  pkg: SpaceComponentPackage,
  layout: ElementLayout | undefined
): ElementLayout | undefined {
  const isNested = !!component.container;
  const { type } = component;

  // Migrate existing layouts if any layout props not yet present
  if (pkg.isHeadless && !pkg.hasConfigIcon) return;
  const ensured = new ElementLayout(layout);
  const defaultLayout = pkg.defaultElementLayout || new ElementLayout();
  const position = isNested ? PositionOption.STATIC : PositionOption.ABSOLUTE;
  Object.entries(defaultLayout).forEach(([key, value]) => {
    if (layout && layout[key] === undefined) {
      ensured[key] = value;
    }
  });
  if (ensured.position !== position) {
    ensured.position = position;
  }

  // For some component types, if nested and dimensions not yet present,
  // default dimensions to 100% if component is contained by scrollable panel
  // and auto otherwise.
  if (isNested) {
    ["width", "height"].forEach(dim => {
      if (layout && layout[dim] === undefined) {
        if (
          [
            "CARD_LIST",
            "CHART",
            "DATA_VIEWER",
            "DETAIL",
            "FUNCTION_FORM",
            "IMAGE",
            "TABLE",
            "VIEWLESS_IMAGE",
            "JSON_INPUT"
          ].includes(type)
        ) {
          ensured[dim] = "100" + LayoutUnit.PERCENTAGE;
        } else if (["FLEX_BOX"].includes(type)) {
          ensured[dim] = LayoutUnit.AUTO;
        }
      }
    });
  }
  return ensured;
}

export function useEnsuredLayout() {
  const { component, pkg } = useComponentContext();
  const { dispatch } = useSpaceConfigContext();
  const maybeLayout = component.layout;
  const layout: ElementLayout | undefined = useMemo(() => {
    return ensureLayout(component, pkg, maybeLayout);
  }, [component, maybeLayout, pkg]);

  useEffect(() => {
    if (
      layout === undefined ||
      (layout instanceof ElementLayout && layout.isEqual(component.layout))
    ) {
      return;
    }
    dispatch({
      type: "UPDATE_COMPONENT_LAYOUT",
      payload: {
        slug: component.slug,
        layout
      }
    });
  }, [layout, component.layout, component.slug, dispatch]);
  return layout;
}
