import { AttributeTypes } from "../../../../../constants";
import { SpaceComponentObject } from "../../../../../types";
import { DefaultValueType } from "../../constants";
import { getInitialFilterState } from "../common/FilterListManager/reducer/reducer";
import { getInitialParameterState } from "../common/ParametersManager/reducer/reducer";
import {
  getCommonDefaultProperties,
  INITIAL_STATE
} from "../common/SelectBackedComponent/Config/reducer";
import { SelectConfigState } from "../common/SelectBackedComponent/types";
import { getCommonInputProperties, toProperty } from "../common/util";
import { hasInputComponentProperties } from "../util/util";

import { SpaceTagSelectorComponent, SpaceTagSelectorProperties } from "./types";

export const getComponentProperties = (
  properties: Partial<SpaceTagSelectorProperties>
) => {
  const validationType =
    properties.validation_type || (toProperty(AttributeTypes.JSON) as string);
  const isJson = validationType === toProperty(AttributeTypes.JSON);
  return {
    ...properties,
    ...{
      columns: properties.columns || [],
      filters: properties.filters || [],
      order: properties.order || [],
      input_parameters: properties.input_parameters || []
    },
    ...getCommonDefaultProperties(properties),
    ...getCommonInputProperties(
      properties,
      DefaultValueType.CSV_VALUE,
      "",
      AttributeTypes.JSON,
      true
    ),
    serialize_value_as_csv:
      typeof properties.serialize_value_as_csv === "boolean"
        ? properties.serialize_value_as_csv
        : isJson
        ? false
        : true
  };
};

export function ensureTagSelectorComponent(
  component: SpaceComponentObject
): SpaceTagSelectorComponent {
  if (
    Array.isArray(component.properties.columns) &&
    Array.isArray(component.properties.filters) &&
    Array.isArray(component.properties.order) &&
    Array.isArray(component.properties.input_parameters) &&
    hasInputComponentProperties(component)
  ) {
    return component;
  }

  return {
    ...component,
    properties: getComponentProperties(component.properties)
  };
}

export function makeInitialState(
  draftComponent: SpaceComponentObject
): SelectConfigState {
  return {
    ...INITIAL_STATE,
    type: "TAG_SELECTOR",
    draftComponent: ensureTagSelectorComponent(draftComponent),
    ...getInitialFilterState(draftComponent),
    ...getInitialParameterState(draftComponent)
  };
}
