import React from "react";

import styled from "styled-components";

import { AttributeTypes } from "../../../constants";
import { AttributeNode, FunctionAttributeNode } from "../../../types";

import BinaryValue from "./BinaryValue";
import BooleanValue from "./BooleanValue";
import DateTimeValue from "./DateTimeValue";
import DateValue from "./DateValue";
import FileValue from "./FileValue";
import JsonValue from "./JsonValue";
import NumberValue, { NumberValueBase } from "./NumberValue";
import StringValue, { StringValueBase } from "./StringValue";
import TimestampValue from "./TimestampValue";
import TimeValue from "./TimeValue";
export interface AttributeValueBaseProps {
  value: any;
  className?: string;
  isTerse?: boolean; // passed in if a shortened view of attribute is needed
  isCollapsed?: boolean; // if attribute should be collapsed by default
  template?: string;
  style?: React.CSSProperties;
}
export interface AttributeValueProps extends AttributeValueBaseProps {
  attribute: AttributeNode | FunctionAttributeNode;
}

const Container = styled.div`
  word-break: break-word;
  white-space: normal;
`;

const AttributeValue = (props: AttributeValueProps) => {
  const { style } = props;
  if (props.value === null) {
    return (
      <div style={style} className={props.className || ""}>
        null
      </div>
    );
  }

  switch (props.attribute.sourceType) {
    case AttributeTypes.BINARY:
    case AttributeTypes.BOOL:
    case AttributeTypes.FILE:
    case AttributeTypes.TIMESTAMP:
    case AttributeTypes.DATETIME:
    case AttributeTypes.TIME:
    case AttributeTypes.DATE:
    case AttributeTypes.JSON: {
      const { attribute, ...rest } = props;
      return (
        <SimpleAttributeValue style={style} type={attribute.sourceType} {...rest} />
      );
    }
    case AttributeTypes.INT:
    case AttributeTypes.DECIMAL:
    case AttributeTypes.FLOAT: {
      const renderValueComponent = () => <NumberValue {...props} />;
      return <Container style={style}>{renderValueComponent()}</Container>;
    }
    case AttributeTypes.STRING:
    default: {
      const renderValueComponent = () => <StringValue {...props} />;
      return <Container style={style}>{renderValueComponent()}</Container>;
    }
  }
};

export default AttributeValue;

export interface SimpleAttributeValueProps extends AttributeValueBaseProps {
  type: AttributeTypes;
}

// Renders value based on type (AttributeType). Similar to AttributeValue
//  but decoupled from AttributeNode and FunctionAttributeNode
export const SimpleAttributeValue = (props: SimpleAttributeValueProps) => {
  if (props.value === null) {
    return (
      <div style={props.style} className={props.className || ""}>
        null
      </div>
    );
  }

  const renderValueComponent = () => {
    switch (props.type) {
      case AttributeTypes.BINARY: {
        return <BinaryValue {...props} />;
      }
      case AttributeTypes.BOOL: {
        return <BooleanValue {...props} />;
      }
      case AttributeTypes.FILE: {
        return <FileValue {...props} />;
      }
      case AttributeTypes.TIMESTAMP: {
        return <TimestampValue {...props} />;
      }
      case AttributeTypes.DATETIME: {
        return <DateTimeValue {...props} />;
      }
      case AttributeTypes.TIME:
        return <TimeValue {...props} />;
      case AttributeTypes.DATE: {
        return <DateValue {...props} />;
      }
      case AttributeTypes.JSON: {
        return <JsonValue {...props} />;
      }
      case AttributeTypes.INT:
      case AttributeTypes.DECIMAL:
      case AttributeTypes.FLOAT: {
        return <NumberValueBase {...props} />;
      }
      case AttributeTypes.STRING: {
        return <StringValueBase {...props} />;
      }
      default: {
        return <StringValueBase {...props} />;
      }
    }
  };

  return <Container>{renderValueComponent()}</Container>;
};
