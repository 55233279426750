import { useCallback } from "react";

import { ElementLayout } from "../../../../layout/util";
import { useSpaceConfigContext } from "../../../../SpaceConfig/SpaceConfigContext";

export default function useCreateSubSpace() {
  const { dispatch, predictSlug } = useSpaceConfigContext();
  return useCallback(
    function createSubSpace(parentSlug: string, name: string) {
      const flexBoxSlug = predictSlug("FLEX_BOX");
      const subSpaceSlug = predictSlug("SUB_SPACE");
      dispatch({
        type: "INSERT_COMPONENT",
        payload: {
          componentType: "FLEX_BOX",
          parentSlug,
          componentConfig: {
            layout: new ElementLayout({ width: "100%", height: "100%" })
          }
        }
      });
      dispatch({
        type: "CREATE_SUB_SPACE",
        payload: { sourceComponentSlug: flexBoxSlug, name }
      });
      return subSpaceSlug;
    },
    [dispatch, predictSlug]
  );
}
