import React from "react";

import { Link } from "react-router-dom";
import styled from "styled-components";

import QueuesIcon from "../../../assets/queues-icon.svg";
import usePaths from "../../common/hooks/usePaths";

export const HomeLink = styled(Link)`
  width: 24px;
  height: 24px;
`;

export default function HomeButton() {
  const { queuesapp_getHome } = usePaths();
  return (
    <HomeLink to={queuesapp_getHome()}>
      <QueuesIcon />
    </HomeLink>
  );
}
