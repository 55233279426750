import Icon from "../../../../../assets/spaceicons/modal.svg";
import {
  SpaceComponentPackage,
  SpaceComponentHostType,
  SpaceComponentTags
} from "../../../../../types";
import { ElementLayout } from "../../../layout/util";

import Config from "./Config";
import errorSelector from "./Config/errorSelector";
import componentConfigReducer, {
  ensureSpaceModalComponent,
  makeInitialState,
  SpaceModalConfigState
} from "./Config/reducer";
import { getSchema } from "./getSchema";
import SpaceModal from "./SpaceModal";
import { SpaceModalComponent, SpaceModalProperties } from "./types";

const pkg: SpaceComponentPackage<
  SpaceModalProperties,
  SpaceModalComponent,
  SpaceModalConfigState
> = {
  type: "MODAL",
  displayName: "Modal",
  Icon,
  allowedHosts: new Set<SpaceComponentHostType>([
    "SPACE",
    "TABLE",
    "CARD_LIST",
    "DETAIL",
    "FLEX_BOX"
  ]),
  Component: SpaceModal,
  ConfigurationComponent: Config,
  componentConfigReducer,
  getInitialDraftState: makeInitialState,
  ensureComponent: ensureSpaceModalComponent,
  getSchema,
  defaultElementLayout: new ElementLayout({
    width: "75%",
    height: "auto",
    snapToGrid: false
  }),
  allowSelfBinding: false,
  allowAncestorBinding: true,
  hasInlineChildren: true,
  tags: [SpaceComponentTags.Base],
  isHeadless: true,
  hasConfigIcon: true,
  isContainer: true,
  errorSelector,
  forceTransformingElement: true,
  featureFlag: "MODAL"
};

export default pkg;
