import React, { useCallback, useMemo } from "react";

import { Checkbox, Input } from "antd";
import invariant from "invariant";

import { AttributeTypeSelect } from "../../../AttributeTypeSelect";
import * as common from "../../common/styledComponents";
import { PipelineParameterInput } from "../../useFunctionEditor/queries";
import * as commonInput from "../styledComponents";

import * as styled from "./styledComponents";
import { getErrors } from "./util";

interface Props {
  parameters: PipelineParameterInput[];
  editable: boolean;
  isMutation: boolean;
  onIsMutationChange: (isMutation: boolean) => void;
  onPipelineParameterChange: (parameter: PipelineParameterInput) => void;
}

export default function PipelineFunctionInput({
  parameters,
  editable,
  isMutation,
  onIsMutationChange,
  onPipelineParameterChange
}: Props) {
  const idToErrors = useMemo(() => {
    return getErrors(parameters);
  }, [parameters]);

  const setParameterAttribute = useCallback(
    // Only allow changing pipelineName or type
    function <T>(id: string, property: "pipelineName" | "type", value: T) {
      const idx = parameters.findIndex(p => p.id === id);
      invariant(idx !== -1, "parameter must exist");
      onPipelineParameterChange({ ...parameters[idx], [property]: value });
    },
    [onPipelineParameterChange, parameters]
  );

  return (
    <styled.Container>
      <commonInput.CategoriesContent>
        <Checkbox
          checked={isMutation}
          onChange={e => onIsMutationChange(e.target.checked)}
        >
          Works with forms and buttons
        </Checkbox>
      </commonInput.CategoriesContent>
      <styled.Hr />
      <styled.SectionHeader>Parameters</styled.SectionHeader>
      <commonInput.Section data-test="pipelineParametersPane">
        <styled.PipelineParametersContent>
          <styled.FieldColumnHeader>Step</styled.FieldColumnHeader>
          <styled.FieldColumnHeader>Name</styled.FieldColumnHeader>
          <styled.FieldColumnHeader>Pipeline parameter name</styled.FieldColumnHeader>
          <styled.FieldColumnHeader>Data type</styled.FieldColumnHeader>
          <styled.FieldColumnHeader>Required</styled.FieldColumnHeader>
          {parameters.map(p => (
            <React.Fragment key={p.id}>
              <div>{p.stepNumber + 1}</div>
              <div>{p.name}</div>
              <div>
                <Input
                  value={p.pipelineName}
                  onChange={event =>
                    setParameterAttribute(
                      p.id,
                      "pipelineName",
                      event.currentTarget.value
                    )
                  }
                />
                {idToErrors.has(p.id) && (
                  <styled.ErrorText>{idToErrors.get(p.id)}</styled.ErrorText>
                )}
              </div>
              <AttributeTypeSelect
                value={p.type}
                disabled={!editable}
                onChange={type => setParameterAttribute(p.id, "type", type)}
              />
              <common.Checkbox checked={p.required} disabled={true} />
            </React.Fragment>
          ))}
        </styled.PipelineParametersContent>
      </commonInput.Section>
    </styled.Container>
  );
}
