import { Node, parse } from "acorn";
import { ancestor, simple } from "acorn-walk";

import { AttributeTypes } from "../../constants";

// Rather than use global object directly, which changes given context and includes
// a lot more than the core global objects, explicitly hardcoding values here for known
// global values, functions, and objects (used to exclude when extracting identifiers).
// See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects
export const GLOBAL_JS = [
  // Value properties
  "Infinity",
  "NaN",
  "undefined",
  "globalThis",
  // Function properties
  "eval",
  "uneval",
  "isFinite",
  "isNaN",
  "parseFloat",
  "parseInt",
  "decodeURI",
  "decodeURIComponent",
  "encodeURI",
  "encodeURIComponent",
  "Deprecated",
  "escape",
  "unescape",
  // Fundamental objects
  "Object",
  "Function",
  "Boolean",
  "Symbol",
  // Error objects
  "Error",
  "AggregateError",
  "EvalError",
  "InternalError",
  "RangeError",
  "ReferenceError",
  "SyntaxError",
  "TypeError",
  "URIError",
  // Numbers and dates
  "Number",
  "BigInt",
  "Math",
  "Date",
  // Text processing
  "String",
  "RegExp",
  // Indexed collections
  "Array",
  "Int8Array",
  "Uint8Array",
  "Uint8ClampedArray",
  "Int16Array",
  "Uint16Array",
  "Int32Array",
  "Uint32Array",
  "Float32Array",
  "Float64Array",
  "BigInt64Array",
  "BigUint64Array",
  // Keyed collections
  "Map",
  "Set",
  "WeakMap",
  "WeakSet",
  // Structured data
  "ArrayBuffer",
  "SharedArrayBuffer",
  "Atomics",
  "DataView",
  "JSON",
  // Control abstraction objects
  "Promise",
  "Generator",
  "GeneratorFunction",
  "AsyncFunction",
  // Reflection
  "Reflect",
  "Proxy",
  // Internationalization
  "Intl",
  // WebAssembly
  "WebAssembly"
];

// Acorn types that are not defined in their exported types
interface FunctionParamNode extends Node {
  name: string;
}

interface FunctionNode extends Node {
  type: string;
  params: FunctionParamNode[];
  body: Node;
}

interface FunctionDeclarationNode extends FunctionNode {
  type: "FunctionDeclaration";
  id: IdentifierNode;
}

interface FunctionExpressionNode extends FunctionNode {
  type: "FunctionExpression";
}

interface LocalVariableData {
  name: string;
  start: number;
  end: number;
}

interface VariableDeclarator extends Node {
  type: "VariableDeclarator";
  id: IdentifierNode;
  init: Node | null;
}

interface IdentifierNode extends Node {
  type: "Identifier";
  name: string;
}

interface ClassDeclarationNode extends Node {
  type: "ClassDeclaration";
  id: IdentifierNode;
}

export type Identifier = {
  name: string;
  type?: AttributeTypes;
};

export type IdentifierMap = Record<string, Identifier>;

// parseIdentifiers from a javascript expression, or code that can be converted into an expression
// by wrapping it in parentheses
export function parseIdentifiers(input: string): IdentifierMap {
  try {
    return unsafeParseIdentifiers(input);
  } catch {
    return {};
  }
}

export function unsafeParseIdentifiers(input: string): IdentifierMap {
  const identifiers: IdentifierMap = {};
  const ast = parse(`(${input})`);
  const localVars: LocalVariableData[] = [];
  const addFunctionParameters = (node: FunctionExpressionNode) => {
    node.params.forEach((paramNode: FunctionParamNode) => {
      if (paramNode.type === "Identifier") {
        localVars.push({
          name: paramNode.name,
          start: node.start,
          end: node.end
        });
      }
    });
  };

  // walk through AST to extract local variables and scope
  ancestor(ast, {
    FunctionExpression(node: Node) {
      addFunctionParameters(node as FunctionExpressionNode);
    },
    ArrowFunctionExpression(node: Node) {
      addFunctionParameters(node as FunctionExpressionNode);
    },
    VariableDeclarator(node: any, ancestors: Node[]) {
      // sort from bottom up (current node --> farthest ancestor)
      // make a copy because changing this will change the calling function variable otherwise
      const ancestorsCopy = ancestors.slice();
      ancestorsCopy.reverse().shift();
      const parentBlock = ancestorsCopy.find(
        ancestor =>
          // find the closest scope within which the variable is declared for start/end data
          ancestor.type === "BlockStatement" ||
          (ancestor.type === "FunctionExpression" &&
            (ancestor as FunctionExpressionNode).body.type === "BlockStatement")
      );

      if (parentBlock) {
        localVars.push({
          name: node.id.name,
          start: parentBlock.start,
          end: parentBlock.end
        });
      }
    }
  });

  // walk through AST to extract identifiers
  simple(ast, {
    Identifier(node) {
      const name = (node as any).name;
      const isLocalVar = localVars.some(localVar => {
        return (
          localVar.name === name &&
          node.start > localVar.start &&
          node.end < localVar.end
        );
      });
      if (!GLOBAL_JS.includes(name) && !isLocalVar) {
        identifiers[name] = { name };
      }
    }
  });
  return identifiers;
}

/**
 * Find the closest containing scope Node, whether that's a Block Statement, FunctionExpression,
 * or the program itself.
 *
 * The first ancestor is expected to be the node we are looking to find the containing scope of.
 * The last ancestor is expected to be of type 'Program', and will be used if nothing closer is found.
 */
function findContainingScope(ancestors: Node[]): Node {
  const copyAncestors = ancestors.slice();

  // sort from bottom up (current node --> farthest ancestor)
  copyAncestors.reverse().shift();

  const node = copyAncestors.find(
    ancestor =>
      // find the closest scope within which the variable is declared for start/end data
      ancestor.type === "BlockStatement" ||
      (ancestor.type === "FunctionExpression" &&
        (ancestor as FunctionExpressionNode).body.type === "BlockStatement") ||
      ancestor.type === "Program"
  );

  if (!node) {
    throw Error("Ancestor not found. At least 'Program' was expected");
  }

  return node;
}

// parses identifiers from a full javascript program, can include functions, classes, etc.
// If the progrma starts with a `{`, in order to make it return something, it is wrapped in
// parentheses to make it an expression
export function parseIdentifiersFromJavascriptProgram(input: string): IdentifierMap {
  // Technically @input is javascript, not an expression, but it's confusing to have
  // `{k: "v"}` treated as a block statement resulting in an error. If
  // the expression begins with a `{` we attempt to wrap it in parentheses to
  // convert it to an expression first and if that fails we rerun using the
  // original expression.
  if (input.startsWith("{")) {
    try {
      return unsafeParseIdentifiersFromJavascriptProgram(`(${input})`);
    } catch {
      // Do nothing
    }
  }

  return unsafeParseIdentifiersFromJavascriptProgram(input);
}

// unsafe here means that it can throw exceptions
export function unsafeParseIdentifiersFromJavascriptProgram(
  input: string
): IdentifierMap {
  // function names, or anything specific we don't want to allow to be an identifier
  const localReservedKeywords = new Set(["console"]);
  const localVars: LocalVariableData[] = [];
  const identifiers: IdentifierMap = {};

  const ast = parse(input);

  const addIdentifierInContainingScope = (id: IdentifierNode, ancestors: Node[]) => {
    const parentBlock = findContainingScope(ancestors);
    localVars.push({
      name: id.name,
      start: parentBlock.start,
      end: parentBlock.end
    });
  };

  const addFunctionParameters = (node: FunctionNode) => {
    node.params.forEach((paramNode: FunctionParamNode) => {
      if (paramNode.type === "Identifier") {
        localVars.push({
          name: paramNode.name,
          start: node.start,
          end: node.end
        });
      }
    });
  };

  // walk through AST to extract local variables and scope
  ancestor(ast, {
    FunctionDeclaration(node: Node, ancestors: Node[]) {
      const functionNode = node as FunctionDeclarationNode;

      addIdentifierInContainingScope(functionNode.id, ancestors);
      addFunctionParameters(functionNode);
    },
    FunctionExpression(node: Node) {
      // FunctionExpressions "const a = function(){...}" are caught in VariableDeclarator, "a" in this case.
      addFunctionParameters(node as FunctionNode);
    },
    ArrowFunctionExpression(node: Node) {
      // ArrowFunctionExpressions "const a = () => {...}" are caught in VariableDeclarator, "a" in this case.
      addFunctionParameters(node as FunctionNode);
    },
    VariableDeclarator: (node: Node, ancestors: Node[]) => {
      const variableDeclarator = node as VariableDeclarator;
      addIdentifierInContainingScope(variableDeclarator.id, ancestors);
    },
    ClassDeclaration: (node: Node, ancestors: Node[]) => {
      const classDeclarationNode = node as ClassDeclarationNode;
      addIdentifierInContainingScope(classDeclarationNode.id, ancestors);
    }
  });

  // walk through AST to extract identifiers
  simple(ast, {
    Identifier(node: Node) {
      const name = (node as any).name;

      if (localReservedKeywords.has(name) || GLOBAL_JS.includes(name)) {
        return;
      }

      const isLocalVar = localVars.some(localVar => {
        return (
          localVar.name === name &&
          node.start >= localVar.start &&
          node.end <= localVar.end
        );
      });

      if (!isLocalVar) {
        identifiers[name] = { name };
      }
    }
  });

  return identifiers;
}
