import { Variables } from "./queries/useCreateScmConfig";
import { Variables as UpdateVariables } from "./queries/useUpdateScmConfig";
import { State } from "./reducer";

export const toCreateVariables = (state: State): Variables => ({
  provider: state.provider!,
  githubAppAppId: state.githubAppAppId!,
  githubAppBranch: state.githubAppBranch!,
  githubAppEnterpriseUrl: state.githubAppEnterpriseUrl!,
  githubAppEnterpriseApiUrl: state.githubAppEnterpriseApiUrl!,
  githubAppInstallationId: state.githubAppInstallationId!,
  githubAppOwner: state.githubAppOwner!,
  githubAppPrivateKey: state.githubAppPrivateKey!,
  githubAppRepo: state.githubAppRepo!
});

export const toUpdateVariables = (state: State): UpdateVariables => {
  const variables = toCreateVariables(state) as UpdateVariables;
  if (!variables.githubAppPrivateKey) {
    delete variables.githubAppPrivateKey;
  }
  return {
    ...variables,
    id: state.id!
  };
};
