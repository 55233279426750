import gql from "graphql-tag";

import { SpaceNode } from "../../../types";
import {
  FUNCTION_PARAMETER_FRAGMENT,
  FUNCTION_ATTRIBUTE_FRAGMENT
} from "../../common/FunctionEditor/useFunctionEditor/queries";

export const TREE_NODE_FRAGMENT = gql`
  fragment treeNodeFields on SpaceComponentNode {
    id
    type
    name
    slug
    container {
      id
      slug
      type
    }
    isRemovable
    sourceType
    notVisibleFunctions {
      edges {
        node {
          id
        }
      }
    }
    functions {
      edges {
        node {
          id
          name
          title
          returnSchema
          isUserGenerated
          isPipeline
          metadata
          access {
            onlyParameters
            onlyAttributes
          }
          dataSource {
            id
            name
          }
          functionParameters {
            edges {
              node {
                ...FunctionParameterFragment
              }
            }
          }
          functionAttributes {
            edges {
              node {
                ...FunctionAttributeFragment
              }
            }
          }
        }
      }
    }
    properties
    layout
    view {
      id
      function {
        id
        name
        access {
          onlyParameters
          onlyAttributes
        }
      }
    }
  }
  ${FUNCTION_PARAMETER_FRAGMENT}
  ${FUNCTION_ATTRIBUTE_FRAGMENT}
`;

export const SPACE_PARAMETER_FRAGMENT = gql`
  fragment spaceParameterFields on SpaceVersionNode {
    parameters {
      id
      name
      bindingType
    }
  }
`;

export interface SpaceData {
  space: SpaceNode;
}

export interface SpaceVars {
  slug: string;
  includeUnpublished: boolean;
}

export const SPACE_QUERY = gql`
  query Space($slug: String!, $includeUnpublished: Boolean) {
    space(slug: $slug) {
      __typename
      ... on NotFoundErrorResult {
        message
      }
      ... on SpaceNode {
        id
        name
        slug
        isFavorite
        branchCount
        scmIsCurrent
        scmStatus
        scmSyncStatus
        branches {
          edges {
            node {
              id
              name
            }
          }
        }
        version(includeUnpublished: $includeUnpublished) {
          __typename
          ... on NoPublishedVersionErrorResult {
            message
          }
          ... on SpaceVersionNode {
            id
            scmVersion
            branch {
              id
              name
            }
            ...spaceParameterFields
            components {
              ...treeNodeFields
            }
            subSpaces {
              slug
              id
              name
              branchCount
              scmIsCurrent
              scmStatus
              scmSyncStatus
              branches {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              version(includeUnpublished: $includeUnpublished) {
                __typename
                ... on NoPublishedVersionErrorResult {
                  message
                }
                ... on SpaceVersionNode {
                  id
                  scmVersion
                  branch {
                    id
                    name
                  }
                  ...spaceParameterFields
                  components {
                    ...treeNodeFields
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${TREE_NODE_FRAGMENT}
  ${SPACE_PARAMETER_FRAGMENT}
`;
