import React from "react";

import { Progress as AntProgress, Tooltip } from "antd";
import styled from "styled-components";

import { colors } from "../../../../../cssConstants";
import { useEvaluaterContext } from "../../../../common/CodeSandbox/EvaluaterContext";
import { FlexContainer } from "../../../../common/StyledComponents";
import { useStableSpaceContext } from "../../SpaceContext";
import { useComponentStateContext } from "../contexts/ComponentStateContext";
import { Props } from "../SpaceComponent";

import { SpaceProgressComponent } from "./types";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const Progress = styled(AntProgress)`
  .ant-progress-inner {
    background-color: ${props => props.theme.borderColor};
  }
`;

export default function SpaceProgress({ spaceComponent }: Props) {
  const progressComponent = spaceComponent as SpaceProgressComponent;
  const { input, updateOutput } = useComponentStateContext();
  const { evaluate, getConsoleError } = useEvaluaterContext();
  const { editMode } = useStableSpaceContext();
  const [value, setValue] = React.useState<number | undefined>();

  const { title, template, theme_properties } = progressComponent.properties;

  const barColor = theme_properties?.color || colors.primaryColor;

  React.useEffect(() => {
    if (!template) {
      setValue(undefined);
      return;
    }

    (async () => {
      try {
        const evaluated = parseFloat(await evaluate(template, input));
        const value = Math.max(0, Math.min(evaluated, 1));
        setValue(isNaN(value) ? undefined : value);
      } catch (e) {
        if (editMode) {
          console.warn(getConsoleError(e));
        }
        setValue(undefined);
      }
    })();
  }, [template, setValue, input, evaluate, editMode, getConsoleError]);

  React.useEffect(() => updateOutput({ value }), [updateOutput, value]);

  // To indicate an indeterminate status, show a half completed progress bar
  const percent = value !== undefined ? value * 100 : 50;

  return (
    <Root>
      <FlexContainer>
        <Tooltip title={title}>
          <Progress
            percent={percent}
            status={value === undefined ? "active" : undefined}
            showInfo={false}
            strokeColor={barColor}
          />
        </Tooltip>
      </FlexContainer>
    </Root>
  );
}
