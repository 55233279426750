import React from "react";

import styled from "styled-components";

import { ExecutionStatus, UserNode } from "../../queries/common";
import RunDateAndUser from "../RunDateAndUser";
import RunStatus from "../RunStatus";

const Container = styled.div`
  display: flex;
  align-items: center;
`;
const DotSeparator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  font-size: 13px;
`;

interface Props {
  status: ExecutionStatus;
  user: UserNode;
  createdAt: string;
}
export const ActivityRunInfo = (props: Props) => {
  return (
    <Container>
      <RunDateAndUser user={props.user} createdAt={props.createdAt} />
      <DotSeparator>•</DotSeparator>
      <RunStatus status={props.status} />
    </Container>
  );
};

export default ActivityRunInfo;
