import { BindingShape } from "../../../../../../types";
import { useEnvironmentContext } from "../../../../../common/contexts/EnvironmentContext";
import useAuthUser from "../../../../../common/hooks/useAuthUser";
import {
  getCurrentUserSchema,
  getCurrentUserValues
} from "../../../../../common/schema";
import { SpaceContextParams } from "../../../../../spaces/SpaceRoot/SpaceContext/SpaceContext";
import { TaskNode } from "../../useQueueClient/queries/AllTasks";

import { createTaskSchema, createTaskValue } from "./utils";

export default function useSpaceContextParams(task?: TaskNode): SpaceContextParams {
  const { authUser } = useAuthUser();
  const { getCurrentEnvironment } = useEnvironmentContext();
  const currentEnvironment = getCurrentEnvironment();

  return {
    user: {
      title: "User",
      value: getCurrentUserValues(authUser),
      schema: getCurrentUserSchema()
    },
    environment: {
      title: "Environment",
      value: {
        current: {
          name: currentEnvironment.name,
          slug: currentEnvironment.slug
        }
      },
      schema: [
        {
          title: "Current",
          name: "current",
          shape: BindingShape.OBJECT,
          attributes: [
            {
              title: "Name",
              name: "name",
              shape: BindingShape.SCALAR
            },
            {
              title: "Slug",
              name: "slug",
              shape: BindingShape.SCALAR
            }
          ]
        }
      ]
    },
    task: {
      title: "Task",
      value: task ? createTaskValue(task) : {},
      schema: task ? createTaskSchema(task) : []
    }
  };
}
