import React from "react";

import { Input } from "antd";

import ColorPicker from "../../../common/ColorPicker";
import { MaterialIconType } from "../../../common/Icons/MaterialIcons";
import Message from "../../../common/Message";
import { ModalNew } from "../../../common/Modal";
import { ensureHexValue } from "../../../common/utils";

import { Data, useUpdateAutomationSettings } from "./queries";
import * as styled from "./styledComponents";

const MAX_DESCRIPTION_LENGTH = 1000;

interface AutomationSettingsInput {
  id: string;
  name: string;
  description: string;
  icon?: string;
  color?: string;
}

export default function SettingsModal({
  automation,
  onCancel,
  onComplete
}: {
  automation: AutomationSettingsInput;
  onCancel: () => void;
  onComplete: () => void;
}) {
  const [draftAutomation, setDraftAutomation] = React.useState(automation);
  const [updateAutomation, { loading, error }] = useUpdateAutomationSettings({
    onCompleted: (data: Data) => {
      if (data.updateAutomationSettings.__typename === "ValidationErrorResult") {
        const message = data.updateAutomationSettings.messages
          .map(message => message.text)
          .join(" ");
        Message.error(message);
      } else {
        Message.success("Automation settings updated.");
        onComplete();
      }
    }
  });

  React.useEffect(() => {
    if (error) {
      Message.error("Something went wrong. Please try again.");
    }
  }, [error]);

  const onChangeAutomation = (change: Partial<AutomationSettingsInput>) => {
    setDraftAutomation({
      ...draftAutomation,
      ...change
    });
  };

  return (
    <ModalNew
      visible
      title="Automation settings"
      confirmLoading={loading}
      onOk={async () => {
        const { id, name, icon, color, description } = draftAutomation;
        updateAutomation({
          variables: {
            automation: {
              id,
              name,
              icon,
              color,
              description
            }
          }
        });
      }}
      onCancel={onCancel}
    >
      <label>
        Name
        <styled.Input
          data-test="automationNameInput"
          value={draftAutomation.name}
          onChange={ev => {
            onChangeAutomation({ name: ev.target.value });
          }}
        />
      </label>
      <styled.Cols>
        <styled.IconSelectLabel>
          Icon (optional)
          <styled.IconSelect
            data-test="iconSelectDropdown"
            value={draftAutomation.icon}
            color={draftAutomation.color}
            onChange={(icon: MaterialIconType) => {
              onChangeAutomation({ icon });
            }}
          />
        </styled.IconSelectLabel>
        <label>
          Color
          <ColorPicker
            value={ensureHexValue(draftAutomation.color || "")}
            strategy="fixed"
            border={false}
            onChange={color => {
              onChangeAutomation({ color: color.substring(1) });
            }}
          ></ColorPicker>
        </label>
      </styled.Cols>
      <label>
        Description (optional)
        <Input.TextArea
          data-test="automationDescInput"
          value={draftAutomation.description}
          maxLength={1000}
          onChange={ev => {
            onChangeAutomation({ description: ev.target.value });
          }}
        />
        <styled.LimitCounter
          current={draftAutomation.description.length}
          limit={MAX_DESCRIPTION_LENGTH}
        />
      </label>
    </ModalNew>
  );
}
