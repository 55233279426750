import React from "react";

import { ConditionActionType, Conditional } from "../../../useFunctionEditor/queries";
import {
  ActionIcon,
  BottomText,
  LinearCenterContainer,
  TopText,
  VerticalContainer
} from "../StepSummary/styledComponents";

import { ConditionSummaryContainer } from "./styledComponents";

interface Props {
  condition: Conditional;
  className?: string;
}

export default function ConditionSummary({ condition, className }: Props) {
  const func = condition.function;

  return (
    <ConditionSummaryContainer className={className}>
      {condition.actionType === ConditionActionType.PENDING && (
        <LinearCenterContainer>
          <ActionIcon integration={undefined}></ActionIcon>
          <VerticalContainer>
            <TopText>Action</TopText>
            <BottomText>Choose action</BottomText>
          </VerticalContainer>
        </LinearCenterContainer>
      )}
      {condition.actionType === ConditionActionType.NOOP && (
        <VerticalContainer>
          <TopText>Pipeline</TopText>
          <BottomText>Do nothing</BottomText>
        </VerticalContainer>
      )}
      {condition.actionType === ConditionActionType.FUNCTION && (
        <LinearCenterContainer>
          <ActionIcon integration={func?.dataSource?.integration}></ActionIcon>
          <VerticalContainer>
            <TopText>Function</TopText>
            <BottomText>{func?.title}</BottomText>
          </VerticalContainer>
        </LinearCenterContainer>
      )}
    </ConditionSummaryContainer>
  );
}
