import gql from "graphql-tag";
import { MutationHookOptions, useMutation } from "react-apollo";

export interface UserInvite {
  email: string;
  roleId: string;
}

export interface InviteResult {
  email: string;
  error: string | null;
}

export interface Variables {
  users: UserInvite[];
}

export interface Data {
  inviteUsers: {
    error: string;
    inviteResults: InviteResult[];
  };
}

export const QUERY = gql`
  mutation InviteUsers($users: [UserInvite!]!) {
    inviteUsers(users: $users) {
      error
      inviteResults {
        email
        error
      }
    }
  }
`;

export const useInviteUsers = (options?: MutationHookOptions<Data, Variables>) => {
  return useMutation<Data, Variables>(QUERY, options);
};
