import React from "react";

import { AttributeValueBaseProps } from "..";
import ClickEventCapturer from "../../ClickEventCapturer";
import JsonViewer from "../../JsonViewer";
import { StringValueBase } from "../StringValue";

export default function JsonValue({
  className = "",
  value,
  isTerse,
  isCollapsed = true,
  style = {}
}: AttributeValueBaseProps) {
  let json = value;
  try {
    json = JSON.parse(value);
  } catch (e) {
    // noop
  }
  return typeof json === "object" ? (
    <ClickEventCapturer>
      <JsonViewer
        json={json}
        isTerse={isTerse}
        canCollapse={isCollapsed}
        defaultExpandedDepth={1}
        showRawModeToggle
      />
    </ClickEventCapturer>
  ) : (
    <StringValueBase
      style={style}
      className={className}
      value={JSON.stringify(value)}
    />
  );
}
