import React, { useEffect, useMemo, useState } from "react";

import { Select } from "antd";

import {
  BindingShape,
  FunctionNode,
  FunctionScope,
  InputParameter
} from "../../../../../../types";
import FormBuilderModal from "../../../../FormBuilderModal";
import { FunctionNode as FormBuilderFunctionNode } from "../../../../FormBuilderModal/types";
import FunctionPicker from "../../../../FunctionPicker";
import Message from "../../../../Message";
import { BindingSchema, getUserEnvironmentBindingSchema } from "../../../../schema";
import { LinkButtonNew } from "../../../../StyledComponents";
import {
  Conditional,
  ConditionActionType,
  PipelineStep
} from "../../../useFunctionEditor/queries";
import { SetPipelineStepConditionArguments } from "../../../useFunctionEditor/useFunctionEditor";
import * as common from "../../styledComponents";
import { useGetFunctionById } from "../common/queries";
import * as styled from "../common/styledComponents";
import ConditionCreator from "../ConditonCreator";
import { SUPPORTED_PARAM_TYPES } from "../utils";

import {
  Arrow,
  ConditionIconWrapper,
  ConditionRightContent,
  ConditionSummaryButton,
  ConditionSummaryHeader,
  HorizontalContent,
  Hr,
  SelectLabel
} from "./styledComponents";

const { Option } = Select;

interface ConditionCaseProps {
  className?: string;
  headerText: string;
  condition: Conditional;
  expanded: boolean;
  onClick?: () => void;
  onSetConditionActionType: (actionType: ConditionActionType) => void;
  onSetConditionFunctionId: (functionId?: string) => void;
  showFormBuilderModal: (func: FunctionNode) => void;
  onLoadPipelineStepConditionFunction: (func: FunctionNode) => void;
}

function ConditionCase({
  className,
  headerText,
  condition,
  expanded,
  onClick = () => null,
  onSetConditionActionType,
  onSetConditionFunctionId,
  showFormBuilderModal,
  onLoadPipelineStepConditionFunction
}: ConditionCaseProps) {
  const { error } = useGetFunctionById({
    variables: { id: condition.functionId! },
    skip: !condition.functionId,
    onCompleted: data => {
      onLoadPipelineStepConditionFunction(data.node);
    }
  });

  useEffect(() => {
    if (error) {
      Message.error("Something went wrong. Please try again.");
      console.error(error);
    }
  }, [error]);

  const func = condition.function;

  return (
    <div className={className} onClick={() => onClick()}>
      <styled.SubsectionHeader>{headerText}</styled.SubsectionHeader>
      <HorizontalContent>
        <ConditionIconWrapper>
          <Arrow />
        </ConditionIconWrapper>
        {!expanded ? (
          <ConditionSummaryButton condition={condition} />
        ) : (
          <styled.SubstepContainer>
            <ConditionSummaryHeader condition={condition} />
            <Hr margin={0} />
            <ConditionRightContent>
              <SelectLabel>Type</SelectLabel>
              <common.Select
                getPopupContainer={trigger => trigger.parentNode as HTMLElement}
                data-test="actionTypeSelectConditional"
                placeholder="Select an action type"
                value={
                  condition.actionType === ConditionActionType.PENDING
                    ? undefined
                    : condition.actionType
                }
                onChange={value =>
                  onSetConditionActionType(value as ConditionActionType)
                }
              >
                <Option
                  value={ConditionActionType.FUNCTION}
                  key={ConditionActionType.FUNCTION}
                >
                  Function
                </Option>
                <Option value={ConditionActionType.NOOP} key={ConditionActionType.NOOP}>
                  Do nothing
                </Option>
              </common.Select>
              {condition.actionType === ConditionActionType.FUNCTION && (
                <div>
                  <FunctionPicker
                    functionId={condition.functionId || null}
                    functionScope={FunctionScope.Submittable}
                    onDataSourceChange={() => onSetConditionFunctionId(undefined)}
                    onChange={functionId =>
                      onSetConditionFunctionId(functionId || undefined)
                    }
                    showLabels
                    allowPipelineFunctions
                  />
                  <styled.ActionLinks>
                    <LinkButtonNew
                      disabled={!func || !func.functionParameters?.edges.length}
                      onClick={() => showFormBuilderModal(func!)}
                    >
                      Configure
                    </LinkButtonNew>
                  </styled.ActionLinks>
                </div>
              )}
            </ConditionRightContent>
          </styled.SubstepContainer>
        )}
      </HorizontalContent>
    </div>
  );
}

function getConditionHeaderText(conditionIndex: number) {
  if (conditionIndex === 0) {
    return "is true, do the following:";
  } else if (conditionIndex === 1) {
    return "is false, do the following:";
  }

  return `Condition ${conditionIndex}`;
}

interface Props {
  className?: string;
  step: PipelineStep;
  selectedConditionIndex?: number;
  previousStepsSchema: Record<string, BindingSchema> | undefined;
  showErrors: boolean;
  onSelectCondition: (conditionIndex: number) => void;
  onSetPipelineStepInputParameters: (
    stepIndex: number,
    parameters: InputParameter[]
  ) => void;
  onSetPipelineStepCondition: (
    stepIndex: number,
    payload: SetPipelineStepConditionArguments
  ) => void;
  onSetPipelineStepConditionActionType: (
    stepIndex: number,
    conditionIndex: number,
    actionType: ConditionActionType
  ) => void;
  onSetPipelineStepConditionFunction: (
    stepIndex: number,
    conditionIndex: number,
    functionId?: string
  ) => void;
  onSetPipelineStepConditionInputParameters: (
    stepIndex: number,
    conditionIndex: number,
    parameters: InputParameter[]
  ) => void;
  onLoadPipelineStepConditionFunction: (
    stepIndex: number,
    conditionIndex: number,
    func: FunctionNode
  ) => void;
}

export default function ConfigConditionalStep({
  className,
  step,
  selectedConditionIndex,
  previousStepsSchema,
  showErrors,
  onSelectCondition,
  onSetPipelineStepInputParameters,
  onSetPipelineStepCondition,
  onSetPipelineStepConditionActionType,
  onSetPipelineStepConditionFunction,
  onSetPipelineStepConditionInputParameters,
  onLoadPipelineStepConditionFunction
}: Props) {
  if (step.conditions?.length !== 2) {
    throw new Error("Expected two conditions, true and false");
  }

  const [formBuilderFunction, setFormBuilderFunction] = useState<
    FunctionNode | undefined
  >(undefined);

  const selectedCondition =
    selectedConditionIndex !== undefined
      ? step.conditions![selectedConditionIndex]
      : undefined;

  const formBuilderFunctionNode: FormBuilderFunctionNode | undefined = useMemo(() => {
    if (!formBuilderFunction) {
      return undefined;
    }

    return {
      functionParameters: {
        edges: formBuilderFunction.functionParameters?.edges || []
      }
    };
  }, [formBuilderFunction]);

  const schema = useMemo(() => {
    return {
      ...previousStepsSchema,
      ...getUserEnvironmentBindingSchema()
    };
  }, [previousStepsSchema]);

  return (
    <div className={className}>
      {step.conditions?.length && (
        <ConditionCreator
          title={step.title}
          condition={step.conditions[0]}
          previousStepsSchema={previousStepsSchema}
          showErrors={showErrors}
          inputParameters={step.inputParameters}
          onSetInputParameters={inputParameters =>
            onSetPipelineStepInputParameters(step.order, inputParameters)
          }
          onConditionChange={conditionPayload =>
            onSetPipelineStepCondition(step.order, conditionPayload)
          }
        />
      )}
      {step.conditions.map((condition, index) => (
        <div key={index}>
          <Hr />
          <ConditionCase
            onClick={() => selectedConditionIndex !== index && onSelectCondition(index)}
            headerText={getConditionHeaderText(index)}
            expanded={selectedConditionIndex === index}
            condition={condition}
            onSetConditionActionType={(actionType: ConditionActionType) => {
              onSetPipelineStepConditionActionType(step.order, index, actionType);
            }}
            onSetConditionFunctionId={(functionId?: string) => {
              onSetPipelineStepConditionFunction(step.order, index, functionId);
            }}
            onLoadPipelineStepConditionFunction={func =>
              onLoadPipelineStepConditionFunction(step.order, index, func)
            }
            showFormBuilderModal={(func: FunctionNode) => setFormBuilderFunction(func)}
          />
        </div>
      ))}
      {formBuilderFunction &&
        formBuilderFunctionNode &&
        selectedConditionIndex !== undefined && (
          <FormBuilderModal
            visible={true}
            func={formBuilderFunctionNode!}
            title={formBuilderFunction.title || ""}
            schema={schema}
            allowedParameterTypes={SUPPORTED_PARAM_TYPES}
            initialInputParameters={selectedCondition?.inputParameters}
            onCancel={() => setFormBuilderFunction(undefined)}
            allowedBindingShapes={[BindingShape.SCALAR, BindingShape.OBJECT]}
            onSave={e => {
              setFormBuilderFunction(undefined);
              onSetPipelineStepConditionInputParameters(
                step.order,
                selectedConditionIndex,
                e
              );
            }}
          />
        )}
    </div>
  );
}
