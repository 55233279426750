import React from "react";

import { Button, Tooltip } from "antd";
import { Mutation, MutationFunction } from "react-apollo";
import styled from "styled-components";

import Message from "../../common/Message";

import { RESEND_INVITE_MUTATION } from "./queries/ResendInvite";

const StyledButton = styled(Button)`
  color: rgba(0, 0, 0, 0.65);
`;

type ResendInviteMutationFn = MutationFunction<any, any>;

interface Props {
  email: string;
}

const InviteEmailButton = (props: Props) => {
  return (
    <Mutation
      mutation={RESEND_INVITE_MUTATION}
      onCompleted={() => {
        Message.info(`Another invite email has been sent to ${props.email}`);
      }}
      onError={() => {
        Message.error(
          `An error occurred while inviting ${props.email}. Please, try again.`
        );
      }}
    >
      {(resendInvite: ResendInviteMutationFn, { loading }: any) => (
        <Tooltip
          title="Click to resend invite email"
          getPopupContainer={trigger => trigger.parentNode as HTMLElement}
        >
          <StyledButton
            type="link"
            icon="mail"
            loading={loading}
            onClick={e => {
              resendInvite({
                variables: {
                  email: props.email
                }
              });
              e.stopPropagation();
            }}
          />
        </Tooltip>
      )}
    </Mutation>
  );
};

export default InviteEmailButton;
