import { Alert, Button } from "antd";
import styled from "styled-components";

import { H5 } from "../../StyledComponents";
import { Tabs as CommonTabs } from "../common/styledComponents";

import EmptyPreview from "./EmptyPreview";

export const PreviewContent = styled.div`
  display: flex;
  height: 100%;
`;

export const Reminder = styled.div`
  border: 1px solid ${props => props.theme.tableBorderColor};
  border-radius: ${props => props.theme.borderRadiusmd};
  margin-top ${props => props.theme.spacermd};
  padding: 12px;
`;

export const LinkButton = styled(Button)`
  padding: 0;
`;

export const StyledAlert = styled(Alert)`
  margin-top: ${props =>
    props.theme.spacerlg}; /* add margin top for spacing between components */
  width: 100%;
`;

export const TempContainer = styled.div`
  height: 100%;
  display: flex;
`;

export const EmptyFunctionPreview = styled(EmptyPreview)`
  width: 100%;
  height: 100%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 16px 16px 8px;
`;

export const ResultsLabel = styled(H5)`
  margin-bottom: 0;
`;

export const PreviewTabs = styled(CommonTabs)`
  .ant-tabs-content {
    padding-bottom: 0;
  }
`;
