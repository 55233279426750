import React from "react";

import { AttributeValueBaseProps } from "..";

export default function BooleanValue({
  className = "",
  style = {},
  value
}: AttributeValueBaseProps) {
  return (
    <div style={style} className={className} title={String(value)}>
      {String(value)}
    </div>
  );
}
