import styled from "styled-components";

import { SelectLabel, SubsectionHeader } from "../common/styledComponents";

export const LoopSectionHeader = styled(SubsectionHeader)`
  margin-top: 24px;
  margin-bottom: 16px;
`;

export const IteratorNameLabel = styled(SelectLabel)`
  margin-top: 16px;
`;
