import styled from "styled-components";

import {
  Main as BaseMain,
  RightRail as BaseRightRail
} from "../../../common/layout/styledComponents";

import BaseGettingStarted from "./GettingStarted";

const RIGHT_RAIL_WIDTH = "400px";
export const GettingStartedContainer = styled(BaseMain)`
  padding: 0;
  overflow: initial;
`;

export const GettingStarted = styled(BaseGettingStarted)`
  padding: 48px 32px 24px;
`;

export const Main = styled(BaseMain)`
  margin-right: ${RIGHT_RAIL_WIDTH};
`;

export const RightRail = styled(BaseRightRail)`
  position: absolute;
  width: ${RIGHT_RAIL_WIDTH};
  overflow: auto;
  right: 0;
  bottom: 0;
  top: 0;
`;
