import React from "react";

import { Checkbox, Select as AntSelect, Tooltip } from "antd";

import { AttributeTypes } from "../../../../../../../constants";
import { FunctionParameterNode, SpaceComponentType } from "../../../../../../../types";
import { DebouncedAttributeInput } from "../../../../../../common/AttributeInput";
import { BooleanSelect } from "../../../../../../common/AttributeInput/BooleanInput";
import {
  FieldType,
  getFieldType
} from "../../../../../../common/AttributeInput/constants";
import useStableId from "../../../../../../common/hooks/useStableId";
import Label from "../../../../../../common/Label";
import { FunctionParameterDescriptor } from "../../../../../FunctionExecutor/FunctionExecutor";
import { useTransformationActionContext } from "../../../../../layout/TransformationContext/TransformationContext";
import { useSpaceConfigContext } from "../../../../../SpaceConfig/SpaceConfigContext";
import { SpaceConfigAction } from "../../../../../types";
import useComponentNode from "../../../../../util/useComponentNode";
import { BlankValueType } from "../../../../constants";
import { useStableSpaceContext } from "../../../../SpaceContext";
import { findSpaceComponentPackage } from "../../../../SpaceContext/StableSpaceContext";
import {
  BINDING_SHAPES_BY_TYPE,
  PARAMETER_BLANK_VALUE_TYPES,
  BlankValueTypesDisplayNames
} from "../../../util/util";
import { BindingCascader } from "../../BindingCascader";
import { useComponentConfigContext } from "../../ComponentConfigContext";
import ValidationError from "../../ComponentConfigContext/ValidationError";
import { ConfigSection } from "../../ConfigPanel";
import { Field, Select } from "../../ConfigPanel/styledComponents";
import { DebouncedTemplateEditor, Height } from "../../TemplateEditor";
import { InputParameter, ParameterType } from "../../useFuncParams";
import { InputParameterBinding } from "../../useFuncParams/types";
import { findDescendentNodeBySlug } from "../../util";
import { ParameterConfigActionTypes } from "../reducer/reducer";
import { createInsertComponentPayload, getBindingPath } from "../utils";

import { ComponentConfigButton } from "./StyledComponents";
import {
  getCurrentSource,
  getDisplayOptions,
  getSupportedComponentTypes
} from "./util";

const { Option } = AntSelect;

interface Props {
  index: number;
  inputParameter: InputParameter;
  functionParameterNode: FunctionParameterNode;
  shouldDisambiguateParameter?: boolean;
  functionParameterDescriptor?: FunctionParameterDescriptor;
  allowUserInput: boolean;
  allowFileInput: boolean;
  dispatch: React.Dispatch<SpaceConfigAction>;
  onConfigure: (
    payload: {
      name: string;
    } & Partial<InputParameter>
  ) => void;
}

export default function ParameterConfigurationPanel({
  index,
  inputParameter,
  functionParameterNode,
  shouldDisambiguateParameter,
  functionParameterDescriptor,
  allowUserInput,
  allowFileInput,
  dispatch,
  onConfigure
}: Props) {
  const spaceConfigContext = useSpaceConfigContext();
  const { getSpaceComponentPackages } = useStableSpaceContext();
  const { state } = useComponentConfigContext();
  const componentNode = useComponentNode();
  const { select } = useTransformationActionContext();
  const parameterType = functionParameterNode.type;
  const options = getDisplayOptions(
    parameterType,
    allowUserInput,
    allowFileInput,
    functionParameterNode.required
  );

  const configure = (payload: Partial<InputParameter>) => {
    onConfigure({ ...payload, name: functionParameterNode.name });
  };

  const currentSource = getCurrentSource(
    inputParameter,
    functionParameterNode.required
  );

  const onBindingChange = (path: string) => {
    configure({
      binding: path
    });
  };

  const onInputChange = (newValue: any) => {
    let value = newValue;
    if (parameterType === AttributeTypes.BOOL) {
      value = newValue === BooleanSelect.TRUE ? true : false;
    }
    configure({
      type: inputParameter.type,
      value
    });
  };

  const onTypeChange = (newType: any) => {
    // if changing from component type, remove component
    let configOverrides: Partial<InputParameter> = {};
    if (
      inputParameter.type === ParameterType.COMPONENT &&
      newType !== ParameterType.COMPONENT &&
      inputParameter.component_slug
    ) {
      // reset component specific param fields
      configOverrides = {
        component_slug: undefined,
        component_type: undefined,
        binding: undefined,
        error: undefined,
        field_type: getFieldType(functionParameterNode.type)
      };

      spaceConfigContext.dispatch({
        type: "REMOVE_COMPONENT",
        payload: { slug: inputParameter.component_slug }
      });
    } else if (
      inputParameter.type !== ParameterType.COMPONENT &&
      newType === ParameterType.COMPONENT
    ) {
      // Changing to component
      configOverrides = {
        field_type: undefined // reset so field_type options don't appear
      };
    } else if (
      // Transitioning to an InputParameterBinding
      newType !== ParameterType.BINDING &&
      typeof (inputParameter as InputParameterBinding).binding === "string"
    ) {
      configOverrides = {
        binding: undefined
      };
    }

    let configuration: {
      type: Exclude<ParameterType, ParameterType.UNRESOLVED>;
      value: any;
    };
    if (newType === "null") {
      configuration = {
        type: "value" as Exclude<ParameterType, ParameterType.UNRESOLVED>,
        value: null,
        ...configOverrides
      };
    } else {
      configuration = {
        type: newType,
        value:
          newType === "value" && parameterType === AttributeTypes.BOOL
            ? false
            : undefined,
        ...configOverrides
      };
    }

    configure(configuration);
  };

  const bindingPath =
    inputParameter.type === ParameterType.BINDING ? inputParameter.binding : "";

  const domId = useStableId("filterConfigurationPanel");

  const currentOption = options.find(pair => pair[0] === currentSource);
  const currentValue = currentOption ? currentSource : undefined;

  const packages = getSpaceComponentPackages();

  // order of component options is determined by order in ATTRIBUTE_TYPES_WITH_COMPONENT_SUPPORT
  const supportedComponentTypes = getSupportedComponentTypes(
    state.draftComponent.type,
    functionParameterNode.type,
    packages
  );

  const pkg =
    inputParameter.type === ParameterType.COMPONENT
      ? findSpaceComponentPackage(inputParameter.component_type)
      : undefined;
  const TerseConfig = pkg?.TerseConfigurationComponent;

  const bindingShapes = BINDING_SHAPES_BY_TYPE[functionParameterNode.type];

  const shouldDisambiguate =
    shouldDisambiguateParameter && functionParameterDescriptor !== undefined;

  const title = shouldDisambiguate
    ? functionParameterDescriptor?.param.name
    : inputParameter.name;

  return (
    <ConfigSection
      title={title}
      subTitle={shouldDisambiguate ? functionParameterDescriptor?.fn.title : undefined}
      id={domId}
    >
      {(options.length > 1 || inputParameter.type !== ParameterType.COMPONENT) && (
        <Field>
          <label>Value</label>
          <Select
            placeholder="Please select a value"
            value={currentValue}
            dropdownMatchSelectWidth={false}
            data-test="prefillSourceSelect"
            onChange={onTypeChange}
            getPopupContainer={() => document.getElementById(domId) as HTMLElement}
          >
            {options.map(pair => {
              return (
                <Option key={pair[0]} title={pair[1]}>
                  {pair[1]}
                </Option>
              );
            })}
          </Select>
        </Field>
      )}

      {inputParameter.type === ParameterType.STATIC && inputParameter.value !== null && (
        <Field>
          <label>Enter default value.</label>
          <DebouncedAttributeInput
            sourceName={inputParameter.name}
            onChange={onInputChange}
            value={inputParameter.value}
            sourceNullable={false}
            required
            verbose
            useSimpleJsonInput
            sourceType={parameterType}
            onValidate={valid =>
              dispatch({
                type: ParameterConfigActionTypes.SET_PARAMETER_VALIDITY,
                payload: { name: inputParameter.name, valid }
              })
            }
          />
        </Field>
      )}
      {inputParameter.type === ParameterType.TEMPLATE && (
        <Field>
          <DebouncedTemplateEditor
            value={inputParameter.template || "``"}
            placeholder={"${variable}"}
            minHeight={Height.Medium}
            onChange={template => configure({ template })}
          />
        </Field>
      )}
      {inputParameter.type === ParameterType.BINDING && (
        <Field>
          <label>Bind value to:</label>
          <BindingCascader
            defaultValue={bindingPath}
            selectable={bindingShapes}
            onChange={onBindingChange}
            value={bindingPath}
          />
        </Field>
      )}
      {inputParameter.type === ParameterType.COMPONENT && (
        <>
          <Field>
            <label>Component Type</label>
            <Select
              value={
                supportedComponentTypes.includes(inputParameter.component_type)
                  ? inputParameter.component_type
                  : undefined
              }
              placeholder="Select a component"
              dropdownMatchSelectWidth={false}
              data-test="field_componentSelect"
              getPopupContainer={() => document.getElementById(domId) as HTMLElement}
              onChange={e => {
                const previousComponentSlug = inputParameter.component_slug;
                const previousComponent = previousComponentSlug
                  ? findDescendentNodeBySlug(
                      previousComponentSlug,
                      state.draftComponent
                    )
                  : undefined;
                const previousProperties = previousComponent
                  ? { ...previousComponent.properties }
                  : {};
                if (previousComponentSlug) {
                  spaceConfigContext.dispatch({
                    type: "REMOVE_COMPONENT",
                    payload: { slug: previousComponentSlug }
                  });
                }

                const componentType: SpaceComponentType = (
                  e as string
                ).toUpperCase() as SpaceComponentType;
                const nextSlug = spaceConfigContext.predictSlug(componentType);
                spaceConfigContext.dispatch({
                  type: "INSERT_COMPONENT",
                  payload: createInsertComponentPayload(
                    componentType,
                    state.draftComponent.slug,
                    functionParameterNode,
                    previousProperties
                  )
                });
                configure({
                  type: ParameterType.COMPONENT,
                  component_type: e as SpaceComponentType,
                  component_slug: nextSlug,
                  binding: getBindingPath(componentNode, nextSlug, "value"),
                  error: {
                    binding: getBindingPath(componentNode, nextSlug, "error")
                  }
                });
              }}
            >
              {supportedComponentTypes.map(type => {
                const displayName = packages.find(p => p.type === type)?.displayName;
                return (
                  <Option key={type} title={displayName}>
                    {displayName}
                  </Option>
                );
              })}
            </Select>
            <ValidationError alwaysRender field="COMPONENT_TYPE" index={index} />
          </Field>
          {TerseConfig &&
            inputParameter.component_slug &&
            spaceConfigContext.getComponentConfig(inputParameter.component_slug) && (
              <TerseConfig slug={inputParameter.component_slug} />
            )}
        </>
      )}
      {/* component parameters have allow blank and null values configured in component config */}
      {inputParameter.type !== ParameterType.COMPONENT && (
        <>
          <Field>
            <Checkbox
              data-test="field_required"
              checked={!inputParameter.required}
              disabled={currentSource === "null"}
              onChange={({ target: { checked } }) => configure({ required: !checked })}
            >
              <Tooltip
                placement="right"
                title="Blank values include null, undefined, or empty strings."
              >
                Allow this field to be blank
              </Tooltip>
            </Checkbox>
          </Field>
          {inputParameter.field_type !== FieldType.BOOL_INPUT && (
            <Field>
              <Label
                showInfoIcon
                title="This will determine what value to use when the field is blank."
              >
                Treat blank values as
              </Label>
              <Select
                data-test="field_blankValueType"
                disabled={inputParameter.required}
                defaultValue={inputParameter.blank_value_type}
                onChange={value =>
                  configure({ blank_value_type: value as BlankValueType })
                }
                getPopupContainer={trigger => trigger.parentNode as HTMLElement}
              >
                {PARAMETER_BLANK_VALUE_TYPES.map(blankValueType => {
                  const isDisabled =
                    functionParameterNode.required &&
                    blankValueType === BlankValueType.UNDEFINED;
                  return (
                    <Option
                      value={blankValueType}
                      key={blankValueType}
                      disabled={isDisabled}
                      title={
                        isDisabled
                          ? "This field is associated with a required parameter, therefore this option is not available."
                          : undefined
                      }
                    >
                      {BlankValueTypesDisplayNames[blankValueType]}
                    </Option>
                  );
                })}
              </Select>
            </Field>
          )}
        </>
      )}

      {inputParameter.type === ParameterType.COMPONENT &&
        !!inputParameter.component_type && (
          <ComponentConfigButton
            type="link"
            onClick={() => {
              if (inputParameter.component_slug === undefined) return;
              select(inputParameter.component_slug);
            }}
          >
            Configure{" "}
            {packages.find(p => p.type === inputParameter.component_type)
              ?.displayName || "Component"}
          </ComponentConfigButton>
        )}
    </ConfigSection>
  );
}
