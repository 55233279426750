import React from "react";

import { Button, Icon } from "antd";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import DarkThemeModal from "../../../../common/DarkThemeModal";
import usePaths from "../../../../common/hooks/usePaths";
import Message from "../../../../common/Message";
import { useStableSpaceContext } from "../../../SpaceRoot/SpaceContext";
import { useBranchContext } from "../../../SpaceRoot/SpaceContext/BranchContext";
import { useSpaceConfigContext } from "../../SpaceConfigContext";
import { MutationType } from "../../SpaceConfigContext/useSpaceConfig";
import {
  getFirstSpaceError,
  getSpaceErrors
} from "../../SpaceConfigContext/useSpaceConfig/reducer";
import { SpaceError } from "../../SpaceConfigContext/useSpaceConfig/reducer/util/getSpaceErrors";
import useSaveStatus, { Status } from "../useSaveFlow/useSaveFlow";

export const SaveLinkButton = styled(Button)`
  &.ant-btn {
    padding: 0;
    color: ${props => props.theme.inputColor};
    line-height: 30px;
    height: 30px;

    &:hover,
    &:focus,
    &:active {
      color: ${props => props.theme.inputColor};
    }
  }
`;

const LogoButton = styled.div``;

const BigIcon = styled(Icon)`
  font-size: ${props => props.theme.selectFontSize};
`;

interface Props {
  icon?: React.ReactNode;
}

export default function ExitButton({ icon }: Props) {
  const { status } = useSaveStatus();
  const { getDashboard, getSpace } = usePaths();
  const navigate = useNavigate();
  const [showModal, setShowModal] = React.useState(false);
  const [leaveWhenAvailable, setLeaveWhenAvailable] = React.useState(false);
  const { spaceSlug, findSpaceComponentPackage } = useStableSpaceContext();
  const { branch } = useBranchContext();
  const spaceConfigResult = useSpaceConfigContext();
  const { state, dispatch, save, componentsWithErrors } = spaceConfigResult;
  const leaveEditMode = React.useCallback(() => {
    if (spaceSlug) {
      navigate(getSpace(spaceSlug, { branch }));
    } else {
      navigate(getDashboard());
    }
  }, [spaceSlug, branch, navigate, getDashboard, getSpace]);

  // This flag is necessary because there mutation onCompleted callback is static
  // wrt the space. the goal is to set it when "Save Draft and Exit" is pressed
  // and leave the state when it is successful. There are two cases:

  // CASE 1:
  // 1. the user attempts to leave but has unsaved changes.
  // when they see the warning modal, they say "save" and leave when available is set to true
  // 2. status changes to saving, we need to keep leave when available
  // and not leave
  // 3a. status changes again to saved successfully, we want to leave
  // 3b. status changes to saved unsuccessfully, leave unavailable should be false

  // CASE 2:
  // 1. the user attempts to leave but has unsaved changes.
  // when they see the warning modal, they say "save" and leave when available is set to true
  // 2. the space has an error and the state never changes to saving
  // 3. the user fixes the error and saves again but unless they save
  // through this modal, we want save when available to be false
  React.useEffect(() => {
    if (!leaveWhenAvailable) return;
    // Case 1, step 3b
    if (status === Status.SAVE_UNSUCCESSFUL) {
      setLeaveWhenAvailable(false);
    }
    // Case 1 step 3a
    if (status !== Status.SAVE_SUCCESSFUL) return;
    leaveEditMode();
  }, [leaveWhenAvailable, status, leaveEditMode]);

  const getSpaceErrorBlockingSubmit = React.useCallback(() => {
    return Array.from(state.spaces.values()).reduce<SpaceError>((acc, curr) => {
      return {
        ...acc,
        ...getSpaceErrors(
          curr,
          findSpaceComponentPackage,
          Array.from(componentsWithErrors),
          state.apiErrors
        )
      };
    }, {} as SpaceError);
  }, [state, findSpaceComponentPackage, componentsWithErrors]);

  const ButtonComponent: any = !!icon ? LogoButton : SaveLinkButton;

  return (
    <>
      <ButtonComponent
        data-test="exitButton"
        onClick={() => {
          if (!state.dirty) {
            leaveEditMode();
            return;
          }
          setShowModal(true);
        }}
        type="link"
      >
        {icon || <BigIcon type="close" />}
      </ButtonComponent>
      {showModal && (
        <DarkThemeModal
          visible={showModal}
          title="You have unsaved changes."
          onCancel={() => setShowModal(false)}
          content="If you don’t save, these changes will be discarded. How do you want to proceed?"
          footer={
            <div>
              <Button data-test="stayInSpace" onClick={() => setShowModal(false)}>
                Stay on Page
              </Button>
              <Button data-test="discardDraft" onClick={() => leaveEditMode()}>
                Discard Changes
              </Button>
              <Button
                data-test="saveAndGo"
                type="primary"
                onClick={() => {
                  const errors = getSpaceErrorBlockingSubmit();
                  if (errors.NAME.length > 0) {
                    dispatch({ type: "SET_NAME_ERROR" });
                  }
                  const error = getFirstSpaceError(errors);
                  setShowModal(false);
                  if (error) {
                    Message.error(error);
                    return;
                  }
                  save();
                  setLeaveWhenAvailable(true);
                }}
                loading={spaceConfigResult.mutationLoading === MutationType.SAVE}
              >
                Save Draft
              </Button>
            </div>
          }
        />
      )}
    </>
  );
}
