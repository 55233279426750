import React from "react";

import { Tooltip } from "antd";

import ButtonNew from "../../../../common/ButtonNew/ButtonNew";
import copyTextToClipboard from "../../../../util/copyToClipboard";

interface Props {
  href: string;
}

export default function CopyLinkButton({ href }: Props) {
  const [visible, setVisible] = React.useState(false);
  return (
    <Tooltip title="Copied!" visible={visible} placement="right">
      <ButtonNew
        type="noFill"
        onClick={_ => {
          copyTextToClipboard(href);
          setVisible(true);
          setTimeout(() => setVisible(false), 600);
        }}
      >
        Copy link
      </ButtonNew>
    </Tooltip>
  );
}
