import { ClientError } from "../../../../../../common/executeGatewayFunction/errors";
import Message from "../../../../../../common/Message";
import SpaceApi from "../../../../../SpaceApi";
import { EventHandler } from "../../../../../types";
import { useComponentContext } from "../../../contexts/ComponentContext";
import { useComponentStateContext } from "../../../contexts/ComponentStateContext";

export enum SubmittableEventType {
  SUBMIT_SUCCESS = "submit_success",
  SUBMIT_FAILURE = "submit_failure"
}

type SubmittableEvent =
  | { type: SubmittableEventType.SUBMIT_SUCCESS }
  | { type: SubmittableEventType.SUBMIT_FAILURE; error: Error | ClientError };

export default function useSubmittableEffects(
  spaceApi: SpaceApi,
  resetForm: () => void = () => {}
) {
  const { component } = useComponentContext();
  const { recursivelyClearOutput } = useComponentStateContext();
  const effects: EventHandler[] = component.properties.effects;

  function triggerEffects(ev: SubmittableEvent) {
    effects
      .filter(e => e.type === ev.type)
      .forEach(({ effect }) => {
        switch (effect.type) {
          case "display_message":
            let message = effect.options.message;
            // If message is empty fall back to system messages. Importantly
            // allow data source to provide customized error messages.
            if (!message) {
              if (ev.type === SubmittableEventType.SUBMIT_SUCCESS) {
                message = "Your changes have been made.";
              } else if (ev.type === SubmittableEventType.SUBMIT_FAILURE) {
                const { error } = ev;
                if (typeof error === "string") {
                  message = error;
                } else if ("details" in error && "message" in error.details) {
                  message = error.details.message;
                } else {
                  message = error.message;
                }
              }
            }
            Message[effect.options.type](message);
            break;
          case "refresh_component":
            spaceApi.notify("refresh_component", {
              components: [effect.options.component]
            });
            break;
          case "reset_form_inputs":
            resetForm();
            recursivelyClearOutput("fieldset");
            break;
        }
      });
  }

  return {
    triggerEffects
  };
}
