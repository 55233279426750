import { useEffect } from "react";

import { SpaceComponentObject } from "../../../../../../types";
import { useStableSpaceContext } from "../../../SpaceContext";
import {
  useChildComponentInput,
  useComponentStateContext
} from "../../contexts/ComponentStateContext";
import { ConditionalExpressionType, useEvaluatedConditions } from "../Conditionals";

const emptyRules: ConditionalExpressionType[] = [];
export default function useIsComponentVisible({
  visibility_rules = emptyRules
}: {
  visibility_rules: ConditionalExpressionType[] | undefined;
}) {
  const { editMode } = useStableSpaceContext();
  const { input, updateOutput } = useComponentStateContext();
  const evaluatedConditions = useEvaluatedConditions(visibility_rules, input);

  let visible: boolean | undefined = true;
  if (editMode || !visibility_rules.length) {
    visible = true;
  } else if (evaluatedConditions === null) {
    visible = undefined;
  } else {
    visible = evaluatedConditions.every(r => r);
  }

  useEffect(() => {
    updateOutput({ visible });
  }, [visible, updateOutput]);

  return visible;
}

/*
  useIsChildComponentVisible
  This hook may be used by a managing parent component like SpaceForm to determine
  whether a label, or similar UI should be rendered for its child component.
*/
export function useIsChildComponentVisible(component: SpaceComponentObject) {
  const { editMode } = useStableSpaceContext();
  const input = useChildComponentInput(component);
  const { visibility_rules = emptyRules } = component.properties;
  const evaluatedConditions = useEvaluatedConditions(visibility_rules, input);
  let visible: boolean | undefined = true;
  if (editMode || !visibility_rules.length) {
    visible = true;
  } else if (evaluatedConditions === null) {
    visible = undefined;
  } else {
    visible = evaluatedConditions.every(r => r);
  }
  return visible;
}
