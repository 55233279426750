import React from "react";

import Select from "react-windowed-select";
import styled from "styled-components";

import { colors, colorTokens } from "../../../../cssConstants";
import Message from "../../../common/Message";
import { ensureHexValue } from "../../../common/utils";
import { toGlobalId } from "../../../util/graphql";
import { Option, StateSelect } from "../../App/Queue/StateSelect/StateSelect";
import AnalyticCard, { DEFAULT_ERROR_MESSAGE } from "../AnalyticCard/AnalyticCard";
import { ShortBlock } from "../common/common";
import {
  StyledProgress,
  Column,
  Main,
  StatValue,
  DashContainer,
  Dash
} from "../common/styledComponents";
import { TimeRange } from "../DurationSelect/DurationSelect";

import {
  StateNode,
  TransitionActivity,
  TransitionNode,
  useQueueBySlug,
  useTransitionActivity
} from "./queries";
import {
  InputContainer,
  InputSection,
  ProgressContainer,
  SelectLabel,
  TransitionActivityContainer,
  TransitionLabel,
  TransitionStatsColumn
} from "./styledComponents";

interface TransitionOption {
  label: string;
  value: string;
}

export function TransitionSelect({
  className,
  selectedTransitionId,
  transitions,
  setTransitionId = () => null
}: {
  className?: string;
  selectedTransitionId?: string;
  transitions: TransitionNode[];
  setTransitionId: (id: string) => void;
}) {
  const transitionOptions = transitions.map(t => ({
    label: `Action button: ${t.name}`,
    value: t.id
  }));

  const selectedOption = transitionOptions.find(t => t.value === selectedTransitionId);

  return (
    <Select
      className={className}
      options={transitionOptions}
      value={selectedOption}
      placeholder="Select a transition"
      onChange={(option: TransitionOption) => setTransitionId(option.value)}
      isClearable={false}
      isSearchable={false}
      menuPortalTarget={document.getElementById("select-portal")}
      styles={{
        dropdownIndicator: (provided: Record<string, any>) => ({
          ...provided,
          color: colors.dropdownIndicator
        }),
        indicatorSeparator: (provided: Record<string, any>) => ({
          ...provided,
          display: "none"
        })
      }}
      theme={(provided: Record<string, any>) => {
        return {
          ...provided,
          colors: {
            ...provided.colors,
            primary25: colorTokens.purpleTransparent400,
            primary50: colorTokens.purpleTransparent400,
            primary75: colorTokens.purpleTransparent400,
            primary: colorTokens.purple700,
            danger: colorTokens.red300
          }
        };
      }}
    />
  );
}

export function TransitionActivityInput({
  states,
  className,
  stateId,
  transitionId,
  setStateId = () => null,
  setTransitionId = () => null
}: {
  states: StateNode[];
  className?: string;
  stateId?: string;
  transitionId?: string;
  setStateId: (id: string) => void;
  setTransitionId: (id: string) => void;
}) {
  const stateOptions = states.map(s => ({
    label: s.name,
    value: s.id,
    color: ensureHexValue(s.color),
    fromTransitions: s.fromTransitions
  }));

  const selectedState = stateOptions.find(option => option.value === stateId);

  return (
    <InputContainer className={className}>
      <div>
        <SelectLabel>State</SelectLabel>
        <StateSelect
          options={stateOptions}
          value={selectedState}
          onSelected={(option: Option) => setStateId(option.value)}
        />
      </div>
      <div>
        <SelectLabel>Action</SelectLabel>
        <TransitionSelect
          transitions={selectedState?.fromTransitions || []}
          selectedTransitionId={transitionId}
          setTransitionId={setTransitionId}
        />
      </div>
      <StatValue>
        Activity is calculated by how many times the selected action was taken during
        the specified time frame
      </StatValue>
    </InputContainer>
  );
}

const StyledTransitionActivityInput = styled(TransitionActivityInput)`
  width: 80%;
`;

export function TransitionActivityStatisticsView({
  statistics,
  className
}: {
  statistics: TransitionActivity[];
  className?: string;
}) {
  const total = statistics.reduce((prev, current) => {
    return prev + current.count;
  }, 0);

  return (
    <Main className={className}>
      <TransitionStatsColumn grow={1}>
        {statistics.map(stat => (
          <TransitionLabel key={`${stat.user.id}-name`}>
            <StatValue>{stat.user.name}</StatValue>
            {stat.count !== 0 && <StatValue>{stat.count}</StatValue>}
            {stat.count === 0 && (
              <DashContainer>
                <Dash />
              </DashContainer>
            )}
          </TransitionLabel>
        ))}
      </TransitionStatsColumn>
      <Column grow={5}>
        {statistics.map(stat => (
          <ProgressContainer key={`${stat.user.id}-progress`}>
            {stat.count !== 0 && (
              <StyledProgress
                strokeWidth={10}
                strokeColor={colors.primaryColor}
                percent={(stat.count / total) * 100}
                showInfo={false}
              />
            )}
          </ProgressContainer>
        ))}
      </Column>
    </Main>
  );
}

export function LockedTransitionActivityStatisticsView({
  statistics,
  className
}: {
  statistics: TransitionActivity[];
  className?: string;
}) {
  return (
    <Main className={className}>
      <TransitionStatsColumn grow={1}>
        {statistics.map(stat => (
          <TransitionLabel key={`${stat.user.id}-name`}>
            <StatValue>{stat.user.name}</StatValue>
          </TransitionLabel>
        ))}
      </TransitionStatsColumn>
      <Column grow={5}>
        {statistics.map(stat => (
          <ShortBlock key={`${stat.user.id}-progress`} />
        ))}
      </Column>
    </Main>
  );
}

const StyledTransitionActivityStatisticsView = styled(TransitionActivityStatisticsView)`
  width: 50%;
`;

const StyledLockedTransitionActivityStatisticsView = styled(
  LockedTransitionActivityStatisticsView
)`
  width: 50%;
`;

export default function TransitionActivityStatistics({
  queueId,
  queueSlug,
  timeRange,
  locked,
  className
}: {
  queueId?: string;
  queueSlug?: string;
  className?: string;
  timeRange: TimeRange;
  locked?: boolean;
}) {
  const [stateId, setStateId] = React.useState<string | undefined>();
  const [transitionId, setTransitionId] = React.useState<string | undefined>();

  const {
    data: queueData,
    loading: queueLoading,
    error: queueError
  } = useQueueBySlug({
    variables: {
      slug: queueSlug!
    },
    skip: !queueSlug
  });

  const { data, loading, error } = useTransitionActivity({
    variables: {
      queueId: queueId!,
      stateId: toGlobalId("StateNode", stateId!),
      transitionId: toGlobalId("TransitionNode", transitionId!),
      fromTimestamp: timeRange.fromTimestamp,
      toTimestamp: timeRange.toTimestamp
    },
    skip: !queueId || !stateId || !transitionId,
    fetchPolicy: "cache-and-network"
  });

  React.useEffect(() => {
    if (queueError || error) {
      Message.error("Something went wrong. Please try again.");
    }
  }, [error, queueError]);

  const setStateIdWithTransition = React.useCallback(
    (stateId: string) => {
      setStateId(stateId);
      const state = queueData?.queueBySlug.states.find(s => s.id === stateId);
      if (state?.fromTransitions.length) {
        setTransitionId(state.fromTransitions[0].id);
      } else {
        setTransitionId(undefined);
      }
    },
    [setStateId, setTransitionId, queueData]
  );

  React.useEffect(() => {
    if (queueData?.queueBySlug.states && !stateId) {
      const state = queueData?.queueBySlug.states[0];
      setStateIdWithTransition(state.id);
    }
  }, [queueData, stateId, setStateIdWithTransition]);

  return (
    <AnalyticCard
      title="Activity by action"
      loading={loading || queueLoading}
      errorMessage={error ? DEFAULT_ERROR_MESSAGE : undefined}
      className={className}
      showUpsell={locked}
    >
      <TransitionActivityContainer>
        <InputSection>
          <StyledTransitionActivityInput
            states={queueData?.queueBySlug.states || []}
            stateId={stateId}
            transitionId={transitionId}
            setStateId={setStateIdWithTransition}
            setTransitionId={setTransitionId}
          />
        </InputSection>
        {locked && (
          <StyledLockedTransitionActivityStatisticsView
            statistics={data?.transitionActivity || []}
          />
        )}
        {!locked && (
          <StyledTransitionActivityStatisticsView
            statistics={data?.transitionActivity || []}
          />
        )}
      </TransitionActivityContainer>
    </AnalyticCard>
  );
}
