import React from "react";

import styled from "styled-components";

import { H5 } from "../../common/StyledComponents";

export interface InviteError {
  email: string;
  error: string;
}

export const ErrorList = styled.ul`
  text-align: left;
  list-style-type: none;
  padding-left: 0;
`;

export const ErrorListItem = styled.li`
  padding-bottom: ${props => props.theme.xs};
`;

export const Title = styled(H5)`
  text-align: left;
  display: block;
  margin-bottom: ${props => props.theme.spacersmd};
`;

export default function ErrorPanel({ errors }: { errors: InviteError[] }) {
  return (
    <div>
      <Title>Could not invite all users</Title>
      <ErrorList>
        {errors.map(m => (
          <li key={m.email}>
            <strong>{m.email}</strong>: {m.error}
          </li>
        ))}
      </ErrorList>
    </div>
  );
}
