import React from "react";

import styled, { ThemeContext } from "styled-components";

import { colorTokens } from "../../../../../cssConstants";

const Container = styled.div<{ themeName: string }>`
  border-radius: ${props => props.theme.containerPrimaryBorderRadius};
  padding: ${props => props.theme.spacermd};
  // These are based on what ReactJson uses and the themes we provide in
  // src/components/common/JsonViewer.tsx
  background-color: ${props =>
    props.themeName === "tomorrow" ? colorTokens.grey2400 : "rgba(0,0,0,0)"};
`;

export default function EmptyPreview({ className }: { className?: string }) {
  const theme = React.useContext(ThemeContext);

  return (
    <Container
      className={className}
      themeName={theme.reactJsonStyle === "dark" ? "tomorrow" : "rjv-default"}
    />
  );
}
