import React from "react";

import { Container, DashWrapper, Text } from "./styledComponents";

interface Props {
  children: string;
}

export default function DashText({ children }: Props) {
  return (
    <Container>
      <DashWrapper>
        <Text>{children}</Text>
      </DashWrapper>
    </Container>
  );
}
