import React, { useReducer } from "react";

import { ReturnSchema } from "../../../../../constants";
import { FunctionNode } from "../../../../../types";
import { toGlobalId } from "../../../../util/graphql";
import { useGetFunctionById } from "../../../queries/useGetFunctionById";
import { Queue } from "../../index";
import SpaceComponentContext from "../spaces/SpaceComponentContext";
import { createComponent, SpaceForm } from "../spaces/SpaceForm";
import useSpaceContextParams from "../spaces/useSpaceContextParams";
import useAssigneeInputParameters from "../useAssigneeInputParameters";

import { useAllQueues } from "./queries/useAllQueues";
import { ActionType, getDefaultState, reducer } from "./reducer";

interface Props {
  queue: Queue;
  visible?: boolean;
  onCancel?: () => void;
  onCompleted?: (taskId: string) => void;
}

function ModalFormContainer({
  queue,
  visible = false,
  onCancel = () => null,
  onCompleted = () => null
}: Props) {
  const [state, dispatch] = useReducer(reducer, {
    ...getDefaultState(),
    selectedQueueSlug: !queue.isLocal ? queue.slug : undefined
  });

  const selectedQueue = state.queues.find(q => q.slug === state.selectedQueueSlug);
  const enqueueTaskFunctionId =
    selectedQueue &&
    toGlobalId("FunctionNode", selectedQueue.enqueueTaskForm.functionId);

  const { loading: queuesLoading } = useAllQueues({
    fetchPolicy: "cache-and-network",
    onCompleted: data =>
      dispatch({
        type: ActionType.LOAD_QUEUES,
        payload: data.allQueues.edges.map(({ node }) => node)
      })
  });

  const { loading: functionLoading } = useGetFunctionById({
    variables: { id: enqueueTaskFunctionId! },
    skip: !enqueueTaskFunctionId,
    fetchPolicy: "cache-and-network",
    onCompleted: data => {
      dispatch({
        type: ActionType.LOAD_FUNCTION,
        payload: data.node
      });
    }
  });

  const contextParams = useSpaceContextParams();

  const func: FunctionNode = {
    ...state.func,
    id: state.func?.id || "",
    name: "client_generated",
    returnSchema: ReturnSchema.UNKNOWN
  };

  const injectAssigneeInputParameters = useAssigneeInputParameters();

  const taskInputParameters = React.useMemo(() => {
    return injectAssigneeInputParameters(
      selectedQueue?.enqueueTaskForm.inputParameters
    );
  }, [selectedQueue?.enqueueTaskForm.inputParameters, injectAssigneeInputParameters]);

  const component = createComponent("Create task", func, taskInputParameters);

  if (!visible) return null;

  return (
    <SpaceComponentContext component={component} contextParams={contextParams}>
      <SpaceForm
        inputParameters={component.properties.input_parameters}
        contentLoading={queuesLoading || functionLoading || !enqueueTaskFunctionId}
        onCancel={onCancel}
        onCompleted={data => {
          const idContainer = data as { id: string };
          if (idContainer.id) {
            onCompleted(idContainer.id);
          }
        }}
      />
    </SpaceComponentContext>
  );
}

export default ModalFormContainer;
