import React from "react";

import { CheckIcon, Container, Title } from "./styledComponents";

export default function EmailSent() {
  return (
    <Container>
      <CheckIcon theme="filled" type="check-circle" />
      <Title>Email sent</Title>
    </Container>
  );
}
