import gql from "graphql-tag";

import { Connection, RelayNode, RoleNode } from "../../../../types";

export interface SpaceFeaturedRoleData {
  space: {
    id: string;
    name: string;
    slug: string;
    featuredRoles: Connection<RoleNode>;
  };
}

export const SPACE_FEATURED_ROLE_DATA = gql`
  fragment SpaceFeaturedRole on SpaceNode {
    id
    name
    slug
    featuredRoles {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const FETCH_SPACE_FEATURED_ROLES = gql`
  query SpaceFeaturedRoles($slug: String!) {
    allRoles {
      edges {
        node {
          id
          name
          scopes
          isManaged
          slug
        }
      }
    }
    space(slug: $slug) {
      ...SpaceFeaturedRole
    }
  }
  ${SPACE_FEATURED_ROLE_DATA}
`;

export interface Space {
  id: string;
  name: string;
  slug: string;
  featuredRoles: Connection<RoleNode>;
}

export interface FetchSpaceFeaturedRolesData {
  allRoles: Connection<RoleNode>;
  space: Space;
}

export const SET_SPACE_FEATURED_FOR_ROLE = gql`
  mutation SetSpaceFeaturedForRole($spaceId: ID!, $roleId: ID!, $isFeatured: Boolean!) {
    setSpaceFeaturedForRole(
      spaceId: $spaceId
      roleId: $roleId
      isFeatured: $isFeatured
    ) {
      space {
        ...SpaceFeaturedRole
      }
    }
  }
  ${SPACE_FEATURED_ROLE_DATA}
`;

export interface CloneSpaceData {
  spaceClone: {
    space: {
      id: string;
      slug: string;
    };
  };
}

export interface CloneSpaceVariables {
  spaceId: string;
  name: string;
}

export const CLONE_SPACE = gql`
  mutation CloneSpace($spaceId: ID!, $name: String!) {
    spaceClone(id: $spaceId, name: $name) {
      space {
        id
        slug
      }
    }
  }
`;

export interface SetSpaceFeaturedForRoleVariables {
  spaceId: string;
  roleId: string;
  isFeatured: boolean;
}

export const FRAGMENT_FUNCTION_DATA = gql`
  fragment FunctionData on FunctionNodeConnection {
    totalCount
    edges {
      node {
        id
        name
        title
        dataSource {
          id
          name
        }
        policies(roleId: $roleId) {
          edges {
            node {
              allowsAll
              policyFunctionParameters {
                edges {
                  node {
                    functionParameter {
                      id
                      name
                    }
                  }
                }
              }
              policyFunctionAttributes {
                edges {
                  node {
                    functionAttribute {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
        functionParameters {
          edges {
            node {
              id
              name
              required
            }
          }
        }
        functionAttributes {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export interface Identifiable {
  id: string;
}

export interface Nameable {
  name: string;
}

export interface TotalCountable {
  totalCount: number;
}

export interface PolicyFunctionParameters extends RelayNode {
  functionParameter: Identifiable & Nameable;
}

export interface PolicyFunctionAttributes extends RelayNode {
  functionAttribute: Identifiable & Nameable;
}

export interface RoleFunctionPermissions {
  allowsAll: boolean;
  policyFunctionParameters: Connection<PolicyFunctionParameters>;
  policyFunctionAttributes: Connection<PolicyFunctionAttributes>;
}

export interface FunctionNode extends Identifiable, Nameable {
  title: string;
  dataSource: Identifiable & Nameable;
  policies: Connection<RoleFunctionPermissions & Identifiable>;
  functionAttributes: Connection<Identifiable & Nameable>;
  functionParameters: Connection<Identifiable & Nameable & { required: boolean }>;
}

export interface Permission {
  id: string;
  name: string;
  type: "attribute" | "parameter";
  permitted: boolean;
  required: boolean;
}

export interface SpaceEnvironmentFunction {
  id: string;
  title: string;
  dataSource: string;
  permissionsGranted: number;
  permissions: Array<Permission>;
}
