import React from "react";

import {
  BindingCascader as CommonBindingCascader,
  Props as CommonBindingCascaderProps
} from "../../../../../common/BindingCascader";
import { useSpaceConfigContext } from "../../../../SpaceConfig/SpaceConfigContext";
import useComponentNode from "../../../../util/useComponentNode";
import { ComponentNode } from "../../../RenderTreeContext";
import {
  findSpaceComponentPackage as findPackage,
  getSpaceComponentDisplayName as getDisplayName
} from "../../../SpaceContext/StableSpaceContext";
import { useComponentConfigContext } from "../ComponentConfigContext/ComponentConfigContext";
import { useSpaceConfigPanelContext } from "../ConfigPanel/ConfigPanelContext";

import { getOptions } from "./util";

type Props = Omit<CommonBindingCascaderProps, "options">;

export const labelProvider = (node: ComponentNode, isAncestor: boolean) => {
  const pkg = findPackage(node.component.type);
  const pronoun = pkg.grammaticalNumber === "plural" ? "These" : "This";
  return isAncestor ? `${pronoun} ${pkg.displayName}` : getDisplayName(node.component);
};

export const valueProvider = (node: ComponentNode) => {
  return findPackage(node.component.type).isPseudoComponent
    ? node.component.type.toLowerCase()
    : node.component.slug;
};

export function BindingCascader(props: Props) {
  const {
    state: { spaces, rootSpaceSlug }
  } = useSpaceConfigContext();
  const { generateGetPopupContainer } = useSpaceConfigPanelContext();
  const { state } = useComponentConfigContext();
  const pivot = useComponentNode();
  const options = getOptions(
    pivot,
    (node: ComponentNode) => findPackage(node.component.type),
    spaces,
    rootSpaceSlug,
    labelProvider,
    valueProvider
  );
  return (
    <CommonBindingCascader
      {...props}
      selectionCacheKey={`${rootSpaceSlug}-${state.draftComponent.slug}`}
      options={options}
      popupContainerFactory={generateGetPopupContainer}
    />
  );
}
