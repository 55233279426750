import React from "react";

import classNames from "classnames";
import { uniqueId } from "lodash";
import styled from "styled-components";

import mouseCoords from "../../../util/mouseCoords";
import { useComponentPathContext } from "../../SpaceRoot/SpaceComponent/contexts/ComponentPathContext";
import {
  useSelectionStateContext,
  useTransformationActionContext
} from "../TransformationContext/TransformationContext";
import { getRectCorners, getDistanceBetweenPoints, getIsPtInsideRect } from "../util";

// Currently this is naive as the only type of `DropTarget`
// parent is a FlexBox. As more "container" components
// are added this will need to be extended
function getInsertionAxis(el: HTMLElement) {
  if (el.style.flexDirection === "" || el.style.flexDirection === "row") {
    return "x";
  } else {
    return "y";
  }
}

export default function DropTarget({
  children,
  className = "",
  style = {}
}: {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}) {
  const elRef = React.useRef<HTMLDivElement>(null);
  const dropTargetId = React.useRef(uniqueId("dropTarget"));
  const path = useComponentPathContext();
  const { activeDropTarget } = useSelectionStateContext();
  const { registerDropTarget, deregisterDropTarget } = useTransformationActionContext();
  const clientRectCache = React.useRef(new Map());

  function getRect(node: HTMLElement) {
    let key = node.id;
    if (!key) {
      key = node.id = uniqueId("layout-node");
    }
    if (clientRectCache.current.has(key)) {
      return clientRectCache.current.get(key);
    } else {
      const rect = node.getBoundingClientRect();
      clientRectCache.current.set(key, rect);
      return rect;
    }
  }

  const pollerHandle = React.useRef(null) as any;
  const checkMouseOver = React.useRef(() => {
    // If the ref is no longer around, got unmounted so clearSelf
    if (!elRef.current) {
      clearInterval(pollerHandle.current);
      pollerHandle.current = null;
    }

    const rect = elRef.current?.getBoundingClientRect() || new DOMRect();
    const isMouseOver = getIsPtInsideRect(rect, mouseCoords.client);

    if (!isMouseOver) {
      clientRectCache.current = new Map();
      deregisterDropTarget(path);
      clearInterval(pollerHandle.current);
      pollerHandle.current = null;
      return;
    }
    const evt = mouseCoords.event;
    const closestTarget =
      evt.target instanceof HTMLElement
        ? (evt.target.closest(".dropTarget") as HTMLElement)
        : undefined;
    if (
      !closestTarget ||
      closestTarget.dataset?.dropTargetId !== dropTargetId.current
    ) {
      return;
    }
    const containerEl = closestTarget;
    if (!containerEl) return;

    if (!containerEl.children.length) {
      registerDropTarget({ path, index: 0 });
      return;
    }

    // See if the cursor is over a child
    let el = ([] as HTMLElement[]).find.call(containerEl.children, c => {
      const rect = getRect(c);
      return (
        rect.left <= evt.clientX &&
        rect.right >= evt.clientX &&
        rect.top <= evt.clientY &&
        rect.bottom >= evt.clientY
      );
    });

    // If not find the closest child to insert relative to
    if (!el) {
      let closestEl = [-1, Number.POSITIVE_INFINITY];
      const mousePoint = new DOMPoint(evt.clientX, evt.clientY);
      ([] as HTMLElement[]).forEach.call(containerEl.children, (c, i) => {
        const corners = getRectCorners(getRect(c));
        corners.forEach(c => {
          const distance = getDistanceBetweenPoints(c, mousePoint);
          if (distance < closestEl[1]) {
            closestEl = [i, distance];
          }
        });
      });

      if (closestEl[0] !== -1) {
        el = containerEl.children[closestEl[0]] as HTMLElement;
      }
    }

    if (!el) return;

    let idx = ([] as HTMLElement[]).indexOf.call(containerEl.children, el);

    // If the cursor is a preceding element decrement idx to ignore it
    let prev = el.previousElementSibling;
    while (prev) {
      if (prev.id === "drop-target-cursor") {
        idx--;
        break;
      }
      prev = prev.previousElementSibling;
    }

    // Determine whether component will insert horizontally or vertically
    const insertionAxis = getInsertionAxis(containerEl);
    const elDOMRect = getRect(el);

    // Position drop target cursor after if mouse position past mid point
    if (insertionAxis === "x") {
      const midX = elDOMRect.left + elDOMRect.width / 2;
      if (evt.clientX > midX) {
        idx++;
      }
    } else {
      const midY = elDOMRect.top + elDOMRect.height / 2;
      if (evt.clientY > midY) {
        idx++;
      }
    }
    registerDropTarget({ path, index: idx });
  });

  let processedChildren = React.Children.toArray(children);
  if (activeDropTarget?.path === path) {
    processedChildren = [
      ...processedChildren.slice(0, activeDropTarget.index),
      <DropTargetCursor key="drop-target-cursor" id="drop-target-cursor" />,
      ...processedChildren.slice(activeDropTarget.index)
    ];
  }

  return (
    <Root
      ref={elRef}
      className={classNames("dropTarget", className)}
      style={style}
      data-drop-target-id={dropTargetId.current}
      onMouseMove={() => {
        if (!pollerHandle.current) {
          pollerHandle.current = setInterval(checkMouseOver.current, 25);
        }
      }}
    >
      {processedChildren}
    </Root>
  );
}

const Root = styled.div`
  min-width: 20px;
  min-height: 20px !important;
`;

const DropTargetCursor = styled.div`
  max-height: 100%;
  width: 2px;
  background-color: ${props => props.theme.primaryColor};
  animation: blink 1s steps(1, end) infinite;

  /* HACK: Cursors in a modal weren't rendering when they didn't have text content */
  &:after {
    content: "|";
    visibility: hidden;
  }

  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
