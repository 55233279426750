import React, { forwardRef, HTMLAttributes } from "react";

import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MeasuringConfiguration,
  MeasuringStrategy,
  PointerSensor,
  useDndContext,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable
} from "@dnd-kit/sortable";
import { CSS, isKeyboardEvent } from "@dnd-kit/utilities";
import classNames from "classnames";
import { truncate } from "lodash";
import { useNavigate } from "react-router";
import styled from "styled-components";

import { colors } from "../../../cssConstants";
import { Icon } from "../../common/Icons/MaterialIcons";
import { H5, SmallLightText } from "../../common/StyledComponents";
import withErrorBoundary from "../../hoc/withErrorBoundary";
import { useEnvironmentContext } from "../contexts/EnvironmentContext";
import { ensureHexValue } from "../utils";

const measuring: MeasuringConfiguration = {
  droppable: {
    strategy: MeasuringStrategy.Always
  }
};

export interface Item {
  id: string;
  name: string;
  description: string;
  slug: string;
  href: string;
  icon?: string;
  color?: string;
}

type CardListProps = {
  items: Item[];
  moreButton: React.ReactNode;
  selectedSlug: string | null;
  loading: boolean;
  emptyState?: React.ReactNode;
  className?: string;
  onSelect: (slug: string | null) => void;
  onOrder?: (id: string, beforeId?: string, afterId?: string) => void;
};

const Root = styled.div`
  overflow-y: scroll;
  overflow-y: overlay;
  scrollbar-gutter: stable;
  margin: 0 -32px;
  padding: 0 32px;
  padding-bottom: ${props => props.theme.spacerlg};
  user-select: none;
`;

const CardListRoot = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: ${props => props.theme.spacermd};
  opacity: 1;
  transition: ${props => props.theme.animationFn};
  &.loading {
    opacity: 0.6;
  }
`;

enum Position {
  Before = -1,
  After = 1
}

function CardList({
  loading,
  items,
  moreButton,
  selectedSlug,
  onSelect,
  onOrder,
  className = "",
  emptyState = <DefaultEmptyState />
}: CardListProps) {
  const [activeId, setActiveId] = React.useState<null | string>(null);
  const activeIndex = activeId ? items.findIndex(item => item.id === activeId) : -1;

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
  );

  return (
    <Root className={className}>
      {!loading && !items.length && emptyState}
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        measuring={measuring}
        onDragStart={ev => {
          setActiveId(ev.active.id);
        }}
        onDragCancel={() => {
          setActiveId(null);
        }}
        onDragEnd={ev => {
          const overIndex = items.findIndex(item => item.id === ev.over?.id);
          const cursorIndex = overIndex <= activeIndex ? overIndex : overIndex + 1;
          const afterIndex = cursorIndex - 1;
          const afterId = items[afterIndex]?.id;
          const beforeId = afterId === undefined ? items[0].id : undefined;
          onOrder && activeId && onOrder(activeId, afterId, beforeId);
          setActiveId(null);
        }}
      >
        <SortableContext items={items}>
          <CardListRoot className={classNames({ loading })}>
            {items.map((item, i) => (
              <SortableCard
                id={item.id}
                key={item.id}
                item={item}
                index={i}
                activeIndex={activeIndex}
                isSelected={selectedSlug === item.slug}
                draggable={typeof onOrder === "function"}
                onSelectItem={onSelect}
              />
            ))}
          </CardListRoot>
        </SortableContext>
        <DragOverlay>
          {activeId ? (
            <CardOverlay id={activeId} item={items[activeIndex]} nodes={items} />
          ) : null}
        </DragOverlay>
      </DndContext>
      {moreButton}
    </Root>
  );
}

export default withErrorBoundary(CardList);

interface CardProps extends HTMLAttributes<HTMLDivElement> {
  id: string;
  item: Item;
  isSelected?: boolean;
  index: number;
  draggable: boolean;
  isClone?: boolean;
  insertPosition?: Position;
  isActiveDrag?: boolean;
  onSelectItem?: CardListProps["onSelect"];
}

function CardOverlay({
  id,
  nodes,
  ...props
}: Omit<CardProps, "index" | "isSelected" | "draggable" | "onSelectItem"> & {
  nodes: Item[];
}) {
  const { activatorEvent, over } = useDndContext();
  const isKeyboardSorting = isKeyboardEvent(activatorEvent);
  const activeIndex = nodes.findIndex(n => n.id === id);
  const overIndex = over?.id ? nodes.findIndex(n => n.id === over?.id) : -1;

  return (
    <Card
      id={id}
      {...props}
      index={activeIndex}
      draggable
      isClone
      insertPosition={
        isKeyboardSorting && overIndex !== activeIndex
          ? overIndex > activeIndex
            ? Position.After
            : Position.Before
          : undefined
      }
    />
  );
}

function SortableCard({
  id,
  activeIndex,
  ...props
}: CardProps & { activeIndex: number }) {
  const {
    attributes,
    listeners,
    index,
    isDragging,
    isSorting,
    over,
    setNodeRef,
    transform,
    transition
  } = useSortable({
    id,
    animateLayoutChanges: () => true
  });

  return (
    <Card
      ref={setNodeRef}
      id={id}
      isActiveDrag={isDragging}
      style={{
        transition,
        transform: isSorting ? undefined : CSS.Translate.toString(transform)
      }}
      insertPosition={
        over?.id === id
          ? index > activeIndex
            ? Position.After
            : Position.Before
          : undefined
      }
      {...props}
      {...attributes}
      {...listeners}
    />
  );
}

const Card = forwardRef<HTMLDivElement, CardProps>(function Card(
  {
    item,
    isSelected,
    index,
    draggable,
    isActiveDrag,
    isClone,
    insertPosition,
    onSelectItem = () => {},
    ...props
  },
  ref
) {
  const navigate = useNavigate();
  const { getCurrentEnvironment } = useEnvironmentContext();

  return (
    <CardRoot
      data-test="card"
      ref={ref}
      className={classNames({
        isSelected,
        isActiveDrag,
        isClone,
        insertBefore: insertPosition === Position.Before,
        insertAfter: insertPosition === Position.After
      })}
      onClick={() => onSelectItem(isSelected ? null : item.slug)}
      onDoubleClick={() => navigate(item.href)}
    >
      <CardTop>
        {item.icon ? (
          <StyledIcon
            style={{
              color: item.color ? ensureHexValue(item.color) : colors.midGrey
            }}
            type={item.icon}
          />
        ) : (
          <div />
        )}
        {draggable && (
          <DragHandle className="hoverReveal" {...props}>
            <DragDots />
          </DragHandle>
        )}
      </CardTop>
      <CardBottom>
        <Title title={item.name}>
          {truncate(item.name, { length: 40, omission: "…" })}
        </Title>
        <SmallLightText>{getCurrentEnvironment().name}</SmallLightText>
      </CardBottom>
    </CardRoot>
  );
});

const BaseCard = styled.section`
  padding: ${props => props.theme.spacermd};
  background: ${props => props.theme.cardBackgroundColor};
  border: solid 1px transparent;
  border-radius: 4px;
`;

const CardRoot = styled(BaseCard)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 160px;

  .hoverReveal svg {
    opacity: 0;
    transition: opacity 150ms cubic-bezier(0.18, 0.67, 0.6, 1.22);
  }

  &:hover {
    background-color: rgba(41, 41, 45, 0.09);

    .hoverReveal svg {
      opacity: 1;
    }
  }

  &.isSelected {
    border-color: ${props => props.theme.primaryColor};
  }

  &.isActiveDrag {
    opacity: 0.4;
  }

  &.isClone {
    margin-top: 10px;
    margin-left: 10px;
    transform: scale(1.025);
    animation: pop 150ms cubic-bezier(0.18, 0.67, 0.6, 1.22);
    box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 6px 0 rgba(34, 33, 81, 0.3);
    background-color: white;
    cursor: grabbing;
  }

  &:not(.isActiveDrag, .isClone) {
    &.insertBefore:after,
    &.insertAfter:after {
       {
        content: "";
        position: absolute;
        background-color: ${props => props.theme.primaryColor};
        top: 0;
        bottom: 0;
        width: 2px;
      }
    }
  }

  &.insertBefore:after,
  &.insertAfter:after {
    top: 0;
    bottom: 0;
    width: 2px;
  }

  &.insertBefore:after {
    left: -10px;
  }

  &.insertAfter:after {
    right: -10px;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes pop {
    0% {
      transform: translate3d(-10px, -10px, 0) scale(1);
    }
    100% {
      transform: translate3d(0px, 0px, 0) scale(1.025);
    }
  }
`;

const CardTop = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledIcon = styled(Icon)`
  font-size: 36px;
`;

const DragHandle = styled.div`
  cursor: grab;
`;

const CardBottom = styled.div``;

const DragDots = () => (
  <svg
    width="10"
    height="16"
    viewBox="0 0 10 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 14C4 15.1 3.1 16 2 16C0.9 16 0 15.1 0 14C0 12.9 0.9 12 2 12C3.1 12 4 12.9 4 14ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0ZM8 4C9.1 4 10 3.1 10 2C10 0.9 9.1 0 8 0C6.9 0 6 0.9 6 2C6 3.1 6.9 4 8 4ZM8 6C6.9 6 6 6.9 6 8C6 9.1 6.9 10 8 10C9.1 10 10 9.1 10 8C10 6.9 9.1 6 8 6ZM8 12C6.9 12 6 12.9 6 14C6 15.1 6.9 16 8 16C9.1 16 10 15.1 10 14C10 12.9 9.1 12 8 12Z"
      fill="currentColor"
    />
  </svg>
);

const Title = styled.h2`
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 0;
  overflow-wrap: break-word;
`;

function DefaultEmptyState() {
  return <H5>No items yet</H5>;
}
