import React from "react";

import { ExclamationCircleFilled } from "@ant-design/icons";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";

import AutomationsIcon from "../../assets/automations-icon.svg";
import QueuesIcon from "../../assets/queues-icon.svg";
import SpacesIcon from "../../assets/spaces-icon.svg";
import useAuthUser from "../../components/common/hooks/useAuthUser";
import usePaths from "../../components/common/hooks/usePaths";
import { A } from "../../components/common/StyledComponents";
import withErrorBoundary from "../../components/hoc/withErrorBoundary";
import ContactModal from "../../components/onboarding/ContactModal/ContactModal";
import { useConfigContext } from "../../ConfigContext";
import { Urls } from "../../constants";

const GET_CHANGES_REQUIRED = gql`
  query DowngradeRequirementsQuery {
    getDowngradeRequirements {
      changesRequired
    }
  }
`;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 48px 32px 24px;
`;

const NavRoot = styled.ul`
  margin: 0;
  padding: 0;
`;

const NavItemRoot = styled.li`
  list-style: none;
  margin-bottom: ${props => props.theme.spacermd};
`;

const AppNavItemRoot = styled.li<{ active: boolean }>`
  display: flex;
  list-style: none;
  align-items: center;
  margin: ${props => props.theme.spacerxs} 0;
  font-size: 12px;
  font-weight: 500;
  padding: 12px 16px;
  color: ${props => (props.active ? "#fff" : props.theme.textColor)}
  background-color: ${props =>
    props.active ? props.theme.darkBackgroundColor : "none"};
  border-radius: 4px;
  cursor: pointer;
`;

const DowngradeNoticeIconWarning = styled(ExclamationCircleFilled)<any>`
  color: ${props => props.theme.errorColor};
  font-size: 13px;
  margin-right: 20px;
  margin-left: 6px;
`;

const IconContainer = styled.div`
  margin-right: 16px;
  height: 24px;
  width: 24px;
`;

const InactiveLink = styled(Link)`
  color: ${props => props.theme.textColorMid};
  font-size: 13px;
  line-height: 20px;
  &:hover {
    text-decoration: underline;
  }
`;

const StyledA = styled.a`
  color: ${props => props.theme.textColorMid};
  font-size: 13px;
  line-height: 20px;
  &:hover {
    text-decoration: underline;
  }
`;

const ActiveLink = styled(InactiveLink)`
  color: ${props => props.theme.textColor};
  font-weight: 600;
`;

function Nav() {
  const paths = usePaths();
  const { authUser } = useAuthUser();
  const { isOnPrem } = useConfigContext();
  const [isContactModalOpen, setIsContactModalOpen] = React.useState(false);

  const { data: downgradeRequirementData } = useQuery(GET_CHANGES_REQUIRED, {
    fetchPolicy: "cache-and-network",
    skip: !authUser?.isAdmin
  });

  const downgradeData = downgradeRequirementData?.getDowngradeRequirements || {};
  const changesRequired = downgradeData.changesRequired;

  return (
    <Root>
      <div>
        <NavRoot>
          <AppNavItem icon={<SpacesIcon />} path={paths.getSpacesHome()}>
            Spaces
          </AppNavItem>
          <AppNavItem icon={<QueuesIcon />} path={paths.queuesapp_getHome()}>
            Queues
          </AppNavItem>
          <AppNavItem icon={<AutomationsIcon />} path={paths.automations_getHome()}>
            Automations
          </AppNavItem>
        </NavRoot>
        <hr />
        {authUser?.isAdmin && (
          <NavRoot>
            <NavItem path={paths.getUsersSettings()}>Users</NavItem>
            <NavItem path={paths.getSettingsRolesAndPermissions()}>
              Roles and Permissions
            </NavItem>
            <NavItem path={paths.getDataSourceSettings()}>
              Data Sources and Functions
            </NavItem>
            <NavItem path={paths.getAuthSettings()}>Authorization Providers</NavItem>
            <NavItem path={paths.getSecuritySettings()}>Security</NavItem>
            <NavItem path={paths.getScmSettings()}>Source Control Management</NavItem>
            {!isOnPrem && !authUser.organization.customLicensing && (
              <>
                {authUser.organization.currentPlan?.slug === "enterprise" ? (
                  <NavItem path={paths.getProductSettings()}>Change Plan</NavItem>
                ) : (
                  <NavItem path={paths.getProductSettings()}>Upgrade Plan</NavItem>
                )}
                <NavItem path={paths.getBillingSettings()}>
                  Account and Billing{" "}
                  {changesRequired && <DowngradeNoticeIconWarning />}
                </NavItem>
              </>
            )}
            <NavItem href={Urls.DOCUMENTATION}>Documentation</NavItem>
            <NavItem
              onClick={() => {
                setIsContactModalOpen(true);
              }}
            >
              Get in touch
            </NavItem>
          </NavRoot>
        )}
      </div>
      <PromoBox />
      {isContactModalOpen && (
        <ContactModal onClose={() => setIsContactModalOpen(false)} />
      )}
    </Root>
  );
}

export default withErrorBoundary(Nav);

function NavItem({
  children,
  path,
  href,
  onClick
}: {
  children: React.ReactNode;
  path?: string;
  href?: string;
  onClick?: () => void;
}) {
  if (path) {
    const active = window.location.pathname.indexOf(path) > -1;
    const Link = active ? ActiveLink : InactiveLink;
    return (
      <NavItemRoot>
        <Link to={path}>{children}</Link>
      </NavItemRoot>
    );
  } else if (href) {
    return (
      <NavItemRoot>
        <StyledA href={href} target="_blank" rel="noopener noreferrer">
          {children}
        </StyledA>
      </NavItemRoot>
    );
  } else if (onClick) {
    return (
      <NavItemRoot>
        <StyledA onClick={onClick}>{children}</StyledA>
      </NavItemRoot>
    );
  }
  return null;
}

function AppNavItem({
  children,
  path,
  icon
}: {
  children: React.ReactNode;
  icon: React.ReactNode;
  path: string;
}) {
  const navigate = useNavigate();
  const active = window.location.pathname.indexOf(path) > -1;
  const onClick = () => {
    navigate(path);
  };
  return (
    <AppNavItemRoot onClick={onClick} active={active}>
      <IconContainer>{icon}</IconContainer>
      <span>{children}</span>
    </AppNavItemRoot>
  );
}

const _PromoBoxRoot = styled.aside`
  display: flex;
  flex-direction: column;
  margin: 0 -12px;
  padding: ${props => props.theme.spacermd}
  border-radius: 8px;
  border: solid 1px ${props => props.theme.borderGreyStrong};
`;

const _PromoBoxA = styled(A)`
  display: flex;
  align-items: center;
`;

function PromoBox() {
  return null;
  // return (
  //   <PromoBoxRoot data-test="promoBox">
  //     <H6>🎉 Save the Date: Feb 7</H6>
  //     <P>Be the first to learn about big product updates at 10 am PT!</P>
  //     <PromoBoxA
  //       href="https://www.internal.io/webinar?utm_source=dashboard&utm_campaign=webinar&utm_id=feb2023_product_update"
  //       target="_blank"
  //     >
  //       Sign up now&nbsp;
  //       <OpenNewWindowIcon />
  //     </PromoBoxA>
  //   </PromoBoxRoot>
  // );
}
