import {
  Binding,
  BindingShape,
  ObjectBinding,
  SpaceComponentObject
} from "../../../../../types";
import { BaseSpaceConfig } from "../../../SpaceConfig/SpaceConfigContext/useSpaceConfig/reducer/reducer";
import { SpaceDefinition } from "../../../types";
import { ComponentNode } from "../../RenderTreeContext";
import { getChildrenSchema } from "../util";

import { SubSpaceComponent } from "./types";

export const getVariablesSchema = (space: BaseSpaceConfig): ObjectBinding => {
  return {
    name: "variables",
    shape: BindingShape.OBJECT,
    attributes: Array.from(getVariables(space)).map(v => ({
      name: v,
      shape: BindingShape.UNKNOWN
    }))
  };
};

export default function getSchema(
  componentNode: ComponentNode,
  spaces: Map<string, BaseSpaceConfig | SpaceDefinition>
): Binding[] {
  const component = componentNode.component as SubSpaceComponent;
  const space = spaces.get(component.properties.sub_space)!;
  const schema = [
    {
      name: "params",
      shape: BindingShape.OBJECT,
      attributes: space.parameters.map(p => ({ ...p.bindingType, name: p.name }))
    },
    ...getChildrenSchema(
      componentNode,
      (componentNode.component.componentTreeNodes as SpaceComponentObject[]).map(
        ctn => ctn.slug
      ),
      spaces
    )
  ];
  // TODO: Currently components are only available on space if its a BaseSpaceConfig.
  // This is the case when getSchema is called from the BindingCascader, but not during
  // validation. This needs to get refactored.
  if ("components" in space) {
    const variablesSchema = getVariablesSchema(space);
    if (variablesSchema.attributes.length) {
      schema.push(getVariablesSchema(space));
    }
  }
  return schema;
}

function getVariables(space: BaseSpaceConfig): Set<string> {
  let vars = new Set<string>();
  space.components.forEach(c => {
    vars = new Set([...vars, ...searchProps(c.draftComponent.properties)]);
  });
  return vars;
}

function searchProps(props: Record<string, any>): Set<string> {
  let vars = new Set<string>();
  for (const key in props) {
    if (key.includes("variable_name")) {
      vars.add(String(props[key]));
    }
    if (typeof props[key] === "object") {
      vars = new Set([...vars, ...searchProps(props[key])]);
    } else if (Array.isArray(props[key])) {
      for (const p of props[key]) {
        vars = new Set([...vars, ...searchProps(p)]);
      }
    }
  }
  return vars;
}
