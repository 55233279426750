import { useMutation } from "@apollo/react-hooks";
import { MutationHookOptions } from "@apollo/react-hooks/lib/types";
import gql from "graphql-tag";

import { ClientErrorResult } from "../../../../../types";

interface InitSpaceScmSuccess {
  __typename: "InitSpaceScmSuccess";
  approvalHref: string;
}

type Result = InitSpaceScmSuccess | ClientErrorResult;

interface Data {
  initSpaceScm: Result;
}

interface Variables {
  spaceId: string;
}

const QUERY = gql`
  mutation InitSpaceScm($spaceId: ID!) {
    initSpaceScm(spaceId: $spaceId) {
      __typename
      ... on InitSpaceScmSuccess {
        approvalHref
      }
      ... on ClientErrorResult {
        code
        message
      }
    }
  }
`;

const useInitSpaceScm = (options?: MutationHookOptions<Data, Variables>) =>
  useMutation(QUERY, options);

export default useInitSpaceScm;
