import React from "react";

import { ConfigValidationError, SpaceComponentObject } from "../../../../../../types";
import useStableId from "../../../../../common/hooks/useStableId";
import { BaseComponentConfigState, SpaceConfigAction } from "../../../../types";
import { ConfigSection } from "../ConfigPanel";
import { Field, Select } from "../ConfigPanel/styledComponents";
import useS3DataSources from "../useS3DataSources";

import * as styled from "./styledComponents";

interface Props<S> {
  state: S;
  dispatch: React.Dispatch<SpaceConfigAction>;
  errors: ConfigValidationError[]; // only used for config panel UI
}

export default function CloudStorageConfig<
  O extends SpaceComponentObject,
  S extends BaseComponentConfigState<O>
>({ state, dispatch }: Props<S>) {
  const { draftComponent } = state;
  const { authentication } = draftComponent.properties;
  const { s3DataSources } = useS3DataSources();
  const domId = useStableId("cloudStorageConfig");

  return (
    <>
      {s3DataSources && Object.keys(s3DataSources).length > 0 && (
        <ConfigSection id={domId} title="Sign your image">
          <Field>
            <Select
              getPopupContainer={() => document.getElementById(domId) as HTMLElement}
              value={authentication?.function_id || "NONE"}
              onChange={val => {
                dispatch({
                  type: "SET_DRAFT_COMPONENT",
                  payload: {
                    path: "properties.authentication",
                    value:
                      val === "NONE" ? null : { type: "s3.presign", function_id: val }
                  }
                });
              }}
            >
              {Object.keys(s3DataSources).map((key: string) => {
                const ds = s3DataSources[key];
                return (
                  <styled.Option key={ds.presignId}>
                    {`${ds.name}'s credentials`}
                  </styled.Option>
                );
              })}
              <styled.Option key="NONE">Do not sign</styled.Option>
            </Select>
          </Field>
        </ConfigSection>
      )}
    </>
  );
}
