import { parseIdentifiers } from "../../common/parseUtils";
import { TEMPLATE_BINDING_EXTRACTER } from "../SpaceRoot/SpaceComponent/constants";

type Properties = { [key: string]: any };

export default function findInputBindings(
  props: Properties | [],
  bindings: Set<string> = new Set([]),
  extractFullPath = false
): Set<string> {
  if (typeof props === "object" && props !== null) {
    Object.keys(props).forEach(key => {
      // TODO: This guard shouldn't be needed
      if (
        key === "binding" &&
        (props as Properties)[key] !== null &&
        (props as Properties)[key] !== undefined
      ) {
        bindings.add((props as Properties)[key]);
      } else if (
        key.indexOf("template") > -1 ||
        (key.indexOf("expression") > -1 && typeof (props as any)[key] === "string")
      ) {
        if (!extractFullPath) {
          const identifiers = Object.keys(parseIdentifiers((props as Properties)[key]));
          identifiers.forEach(identifier => bindings.add(identifier));
        } else {
          ((props as Properties)[key]?.match(TEMPLATE_BINDING_EXTRACTER) || [])
            .map((re: string) => re.replace(TEMPLATE_BINDING_EXTRACTER, `$1`))
            .forEach(bindings.add, bindings);
        }
      } else {
        bindings = findInputBindings(
          (props as Properties)[key],
          bindings,
          extractFullPath
        );
      }
    });
  } else if (Array.isArray(props)) {
    (props as Array<any>).forEach(prop => {
      bindings = findInputBindings(prop, bindings, extractFullPath);
    });
  }

  return bindings;
}
