import React from "react";

import { Select } from "antd";

import { SpaceComponentType } from "../../../../../../../types";
import { useSpaceContext } from "../../../../SpaceContext";

import { EffectOptionField } from "./styledComponents";
import { OptionsComponent, EffectErrorSelector } from "./types";

interface RefreshComponentOptionsValue {
  component: string | undefined;
}
const REFRESHABLE_COMPONENTS: SpaceComponentType[] = [
  "CARD_LIST",
  "CHART",
  "DETAIL",
  "DROPDOWN",
  "IMAGE",
  "STAT",
  "TABLE"
];

const RefreshComponentOptions: OptionsComponent<RefreshComponentOptionsValue> = ({
  value,
  onChange
}) => {
  const { components } = useSpaceContext();
  const refreshableComponents = React.useMemo(() => {
    return components.filter(component =>
      REFRESHABLE_COMPONENTS.includes(component.type)
    );
  }, [components]);
  return (
    <EffectOptionField>
      <Select
        value={value.component}
        placeholder="Select a component"
        onChange={(val: string) => onChange({ component: val })}
        getPopupContainer={trigger => trigger.parentNode as HTMLElement}
      >
        {refreshableComponents.map(({ slug, name }) => (
          <Select.Option value={slug} key={slug}>
            {name}
          </Select.Option>
        ))}
      </Select>
    </EffectOptionField>
  );
};

type OptionsValue = Parameters<typeof RefreshComponentOptions>[0]["value"];

const errorSelector: EffectErrorSelector = (eventHandler, _, _2, componentLookup) => {
  // Will this work as a hook where its called from??
  const options: OptionsValue = eventHandler.effect.options;
  return options.component && !!componentLookup[options.component]
    ? null
    : "This effect requires a component.";
};

export const REFRESH_COMPONENT = {
  name: "Refresh component",
  type: "refresh_component" as const,
  Options: RefreshComponentOptions,
  useEffectLabel: (options: OptionsValue) => {
    const { components } = useSpaceContext();
    const component = components.find(c => c.slug === options.component);
    return `Refresh ${component?.name || "missing component"}`;
  },
  errorSelector
};
