import React, { useEffect, useRef, useState } from "react";

import { CascaderOptionType, CascaderProps } from "antd/lib/cascader";

import { BindingShape } from "../../../types";
import { createPath, parsePath } from "../../util/binding";
import { reportException } from "../../util/exceptionReporting";
import Message from "../Message";

import * as styled from "./styledComponents";
import { filterByShape, Option } from "./util";

const SELECTION_CACHE = new Map<string, string>();
export interface Props
  extends Omit<
    CascaderProps,
    "options" | "getPopupContainer" | "onChange" | "value" | "defaultValue"
  > {
  options: Option[];
  selectable?: BindingShape[];
  selectionCacheKey?: string;
  selectionCache?: Map<string, string>;
  children?: React.ReactNode;
  defaultValue?: string;
  value?: string;
  onChange?: (
    path: string,
    parts: string[],
    selectedOptions?: CascaderOptionType[]
  ) => void;
  onClose?: (path: string) => void;
  popupContainerFactory?: (
    domId?: string | undefined
  ) => ((triggerNode: HTMLElement) => HTMLElement) | undefined;
}

export function BindingCascader({
  options,
  value,
  defaultValue,
  selectable = [BindingShape.SCALAR],
  selectionCacheKey,
  selectionCache = SELECTION_CACHE,
  onChange = () => {},
  onClose,
  popupContainerFactory = () => undefined,
  ...rest
}: Props) {
  const ref = useRef<HTMLDivElement | null>(null);
  const pathRef = useRef(value || "");
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (isMounted) return;
    setIsMounted(true);
    if (defaultValue) return;
    // If there is an item in cache and it is valid, use it. Otherwise
    // clean up the cache.
    if (selectionCacheKey && selectionCache.has(selectionCacheKey)) {
      const cachedPath = selectionCache.get(selectionCacheKey);
      if (!cachedPath) return;
      const cachedParts = parsePath(cachedPath || "");
      if (optionsHasPath(options, cachedParts)) {
        onChange(cachedPath, cachedParts as string[]);
      } else {
        selectionCache.delete(selectionCacheKey);
      }
    }
  }, [isMounted, defaultValue, options, onChange, selectionCache, selectionCacheKey]);

  const filtered = React.useMemo(
    () => filterByShape(options, new Set(selectable)),
    [options, selectable]
  );

  const placeholder = rest.placeholder
    ? rest.placeholder
    : filtered.length
    ? "Select a binding"
    : "No bindings available";

  const _onChange = (parts: string[], selectedOptions?: CascaderOptionType[]) => {
    try {
      const path = createPath(parts);
      pathRef.current = path;
      onChange(path, parts, selectedOptions);
      onChange(path, parts, selectedOptions);
      // If there is a selection cache key, update the cache with the selected path.
      // This simplifies the UX when the user is binding multiple fields / params,
      // to the same source, which is a common use case.
      if (selectionCacheKey) selectionCache.set(selectionCacheKey, path);
    } catch (e) {
      reportException(e, { extra: { parts } });
      Message.error(
        "There was an error selecting that binding. Please contact support for assistance."
      );
    }
  };

  return (
    <styled.Root ref={ref}>
      <styled.Cascader
        {...(filtered.length ? rest : {})}
        defaultValue={defaultValue && parsePath(defaultValue)}
        value={value && parsePath(value)}
        onChange={_onChange}
        onPopupVisibleChange={(visible: boolean) => {
          if (!visible && onClose) onClose(pathRef.current);
          if (rest.onPopupVisibleChange) rest.onPopupVisibleChange(visible);
        }}
        getPopupContainer={popupContainerFactory() || (() => ref.current)}
        options={filtered}
        placeholder={placeholder}
        disabled={!filtered.length}
        data-test="binding-cascader"
      />
    </styled.Root>
  );
}

function optionsHasPath(options: Option[], parts: Array<string | number>) {
  let optionBranch: Option[] | undefined = options;
  const partsCopy = [...parts];
  while (partsCopy.length > 0) {
    const part: string | number | undefined = partsCopy.shift();
    if (!optionBranch) return false;
    const option: Option | undefined = optionBranch.find(o => o.value === part)!;
    optionBranch = option?.children;
  }
  return true;
}
