import React, { useMemo } from "react";

import { FunctionScope, InputParameter } from "../../../../../../../types";
import ButtonNew from "../../../../../../common/ButtonNew/ButtonNew";
import FormBuilderModal from "../../../../../../common/FormBuilderModal";
import FunctionPicker from "../../../../../../common/FunctionPicker";
import { getUserEnvironmentBindingSchema } from "../../../../../../common/schema";
import { fromGlobalId, toGlobalId } from "../../../../../../util/graphql";
import { useGetFunctionById } from "../../../../../queries/useGetFunctionById";
import { ReducerActions } from "../../../../reducer/actions";
import { getTaskSchemaFromFields } from "../../../../reducer/utils";
import { ExecuteFunctionOptions } from "../../../../types";
import * as common from "../styledComponents";
import { ActionProps } from "../types";

import * as styled from "./styledComponents";

const ExecuteFunction = ({
  action,
  dispatch,
  index,
  state
}: ActionProps<ExecuteFunctionOptions>) => {
  const [showFormBuilder, setShowFormBuilder] = React.useState(false);

  const schema = useMemo(() => {
    return {
      ...getUserEnvironmentBindingSchema(),
      ...getTaskSchemaFromFields(state.fields)
    };
  }, [state.fields]);

  const globalFunctionId = action.options?.functionId
    ? toGlobalId("FunctionNode", action.options?.functionId)
    : null;

  const { loading, data } = useGetFunctionById({
    variables: { id: globalFunctionId! },
    skip: !globalFunctionId,
    onCompleted: data => {
      dispatch({
        type: ReducerActions.LOAD_EXECUTE_FUNCTION,
        payload: {
          index,
          func: data.node
        }
      });
    }
  });

  const onFunctionChange = (functionId: string | null) =>
    dispatch({
      type: ReducerActions.UPDATE_EXECUTE_FUNCTION_ID,
      payload: {
        index,
        functionId: functionId ? fromGlobalId(functionId)[1] : null
      }
    });

  const onInputParametersSave = (inputParameters: InputParameter[]) => {
    dispatch({
      type: ReducerActions.UPDATE_ACTION_INPUT_PARAMETERS,
      payload: {
        index,
        inputParameters
      }
    });
    setShowFormBuilder(false);
  };

  return (
    <common.Container>
      <FunctionPicker
        functionId={globalFunctionId}
        functionScope={FunctionScope.Submittable}
        onChange={onFunctionChange}
      />
      <styled.ConfigureContainer>
        <ButtonNew
          loading={loading}
          disabled={loading || !action.options?.functionId || !data?.node}
          onClick={() => setShowFormBuilder(true)}
        >
          Edit form
        </ButtonNew>
      </styled.ConfigureContainer>
      {showFormBuilder && (
        <FormBuilderModal
          title={data!.node.title}
          schema={schema}
          func={data!.node} // CTA to show form should be disabled unless data?.node is truthy so ! is safe
          initialInputParameters={action.options?.inputParameters}
          onSave={onInputParametersSave}
          onCancel={() => setShowFormBuilder(false)}
        />
      )}
    </common.Container>
  );
};

export default ExecuteFunction;
