import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Icon, Input } from "antd";
import { usePopper } from "react-popper";

import ConditonalIcon from "../../../../../../assets/icons/conditional-icon.svg";
import JavascriptIcon from "../../../../../../assets/icons/javascript-icon.svg";
import LoopsIcon from "../../../../../../assets/icons/loops-icon.svg";
import { colorTokens } from "../../../../../../cssConstants";
import ButtonNew from "../../../../ButtonNew/ButtonNew";
import MenuButton from "../../../../MenuButton/MenuButton";
import { PipelineStep } from "../../../useFunctionEditor/queries";
import { PipelineStepType } from "../constants";

import {
  ActionIcon,
  TopText,
  Spacer,
  BigIcon,
  StepNameContainer,
  InputErrorContainer,
  ErrorText,
  PopperContainer,
  Cover,
  LinearCenterContainer,
  IconWrapper,
  IconTitle,
  VerticalContainer,
  Container
} from "./styledComponents";

interface StepNameProps {
  step: PipelineStep;
  editMode: boolean;
  onSetName?: (stepIndex: number, newName: string) => void;
  onSetEditMode: (editMode: boolean) => void;
}

enum ActionType {
  AddAction,
  Function,
  Expression,
  Conditional,
  Loop
}

function getActionFromStep(step: PipelineStep): ActionType {
  if (!step.type) {
    return ActionType.AddAction;
  }

  if (step.type === PipelineStepType.FUNCTION) {
    if (step.dataSource) {
      return ActionType.Function;
    }

    // Without a data source, we don't know what to display, so default to AddAction
    return ActionType.AddAction;
  }

  if (step.type === PipelineStepType.EXPRESSION) {
    return ActionType.Expression;
  }

  if (step.type === PipelineStepType.CONDITIONAL) {
    return ActionType.Conditional;
  }

  return ActionType.Loop;
}

function getStepNameError(name: string): string | null {
  // No name is not an error, it defaults to the function name in that case
  if (!name.length) {
    return null;
  }

  const firstCharacter = name.charAt(0);
  if (firstCharacter === " ") {
    return "Cannot start with a space";
  }

  if (firstCharacter >= "0" && firstCharacter <= "9") {
    return "Cannot start with a number";
  }

  if (name.includes(".")) {
    return "Cannot have '.' character";
  }

  const regex = new RegExp("^[_$a-zA-Z][_$a-zA-Z0-9 ]*$");
  if (!regex.test(name)) {
    return "Name must be alphanumeric";
  }

  return null;
}

function StepName({ step, editMode, onSetName, onSetEditMode }: StepNameProps) {
  const [pendingName, setPendingName] = useState(step.title);
  const error = getStepNameError(pendingName);

  useEffect(() => {
    setPendingName(step.title);
  }, [step.title, setPendingName]);

  const popperMods = useMemo(() => {
    return [
      {
        name: "offset",
        options: {
          offset: [0, 5]
        }
      },
      {
        name: "width",
        enabled: true,
        fn: ({ state }: any) => {
          // Match the width, or do a minimum of 200px
          const newWidth = Math.max(state.rects.reference.width, 200);
          state.styles.popper.width = `${newWidth}px`;
        },
        phase: "beforeWrite" as const,
        requires: ["computeStyles"],
        effect({ state }: any) {
          // Match the width, or do a minimum of 200px
          const newWidth = Math.max(state.elements.reference.offsetWidth, 200);
          state.elements.popper.style.width = `${newWidth}px`;
        }
      }
    ];
  }, []);

  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
    modifiers: popperMods
  });

  const inputRef = useRef<Input>(null);
  useEffect(() => {
    if (editMode) {
      // Focus and highlight the entire input contents when it becomes visible
      inputRef.current?.focus();
      if (inputRef.current?.input.value) {
        inputRef.current.input.setSelectionRange(
          0,
          inputRef.current.input.value.length
        );
      }
    }
  }, [editMode]);

  const onEditStop = useCallback(
    (pendingName: string, error: string | null) => {
      if (!error) {
        // If name is cleared out, use the current step title
        const newName = pendingName ? pendingName : step.title;
        onSetName?.(step.order, newName);
      } else {
        setPendingName(step.title);
      }

      onSetEditMode(false);
    },
    [step, onSetEditMode, onSetName, setPendingName]
  );

  const onKeyUp = React.useCallback(
    (e: React.KeyboardEvent) => {
      // Dont let parent form submit
      e.stopPropagation();

      // On Escape, cancel any changes
      if (e.key === "Escape") {
        onEditStop(step.title, "escape");
      }
    },
    [step, onEditStop]
  );

  const onKeyPress = React.useCallback(
    (e: React.KeyboardEvent, pendingName: string, error: string | null) => {
      e.stopPropagation();

      if (e.key === "Enter") {
        onEditStop(pendingName, error);
      }
    },
    [onEditStop]
  );

  return (
    <>
      <StepNameContainer ref={setReferenceElement}>
        <IconTitle>{step.title}</IconTitle>
        {onSetName && (step.function || step.type !== PipelineStepType.FUNCTION) && (
          <Icon type="edit" onClick={() => onSetEditMode(true)} />
        )}
      </StepNameContainer>

      {editMode && (
        <>
          <Cover onClick={() => onEditStop(pendingName, error)} />
          <PopperContainer
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
          >
            <InputErrorContainer
              onKeyPress={e => onKeyPress(e, pendingName, error)}
              onKeyUp={onKeyUp}
            >
              <Input
                ref={inputRef}
                value={pendingName}
                onChange={e => setPendingName(e.currentTarget.value)}
                placeholder={step.title}
              />
              {error && <ErrorText>{error}</ErrorText>}
            </InputErrorContainer>
          </PopperContainer>
        </>
      )}
    </>
  );
}

interface Props {
  step: PipelineStep;
  deleteDisabled?: boolean;
  defaultActionText?: string;
  onRemoveStep?: (stepIndex: number) => void;
  onSetStepName?: (stepIndex: number, newName: string) => void;
  className?: string;
  onClick?: () => void;
}

export default function StepSummary({
  step,
  defaultActionText = "Add action",
  deleteDisabled,
  className,
  onRemoveStep,
  onSetStepName,
  onClick = () => null
}: Props) {
  const [editMode, setEditMode] = useState(false);

  const actionType = getActionFromStep(step);
  const integration = step.dataSource?.integration;
  const integrationName = step.dataSource?.name;

  const menuActions = useMemo(() => {
    if (!onRemoveStep || deleteDisabled) {
      return [];
    }

    return [
      {
        key: "delete-step",
        button: (
          <ButtonNew
            icon="delete"
            title="Delete step"
            type="primary"
            onClick={event => {
              event.stopPropagation();
              onRemoveStep(step.order);
            }}
          >
            Delete
          </ButtonNew>
        )
      }
    ];
  }, [onRemoveStep, step.order, deleteDisabled]);

  const handleStepNameChange = !onSetStepName
    ? undefined
    : (stepIndex: number, newName: string) => {
        setEditMode(false);
        onSetStepName(stepIndex, newName);
      };

  return (
    <Container className={className} onClick={onClick}>
      {actionType === ActionType.AddAction && (
        <LinearCenterContainer>
          <ActionIcon integration={integration}></ActionIcon>
          <IconTitle>{defaultActionText}</IconTitle>
        </LinearCenterContainer>
      )}
      {actionType === ActionType.Function && (
        <LinearCenterContainer>
          <ActionIcon integration={integration}></ActionIcon>
          <VerticalContainer>
            <TopText>
              {integrationName}
              {/* Only display function title if the step has a custom name or we are in edit mode */}
              {(step.title !== step.function?.title || editMode) &&
                step.function &&
                ` (${step.function.title})`}
            </TopText>
            <StepName
              step={step}
              editMode={editMode}
              onSetName={handleStepNameChange}
              onSetEditMode={setEditMode}
            />
          </VerticalContainer>
        </LinearCenterContainer>
      )}
      {actionType === ActionType.Expression && (
        <LinearCenterContainer>
          <IconWrapper>
            <JavascriptIcon />
          </IconWrapper>
          <VerticalContainer>
            <TopText>Execute JavaScript</TopText>
            <StepName
              step={step}
              editMode={editMode}
              onSetName={handleStepNameChange}
              onSetEditMode={setEditMode}
            />
          </VerticalContainer>
        </LinearCenterContainer>
      )}
      {actionType === ActionType.Conditional && (
        <LinearCenterContainer>
          <IconWrapper>
            <ConditonalIcon />
          </IconWrapper>
          <VerticalContainer>
            <TopText>Condition</TopText>
            <StepName
              step={step}
              editMode={editMode}
              onSetName={handleStepNameChange}
              onSetEditMode={setEditMode}
            />
          </VerticalContainer>
        </LinearCenterContainer>
      )}
      {actionType === ActionType.Loop && (
        <LinearCenterContainer>
          <IconWrapper>
            <LoopsIcon />
          </IconWrapper>
          <VerticalContainer>
            <TopText>Loop</TopText>
            <StepName
              step={step}
              editMode={editMode}
              onSetName={handleStepNameChange}
              onSetEditMode={setEditMode}
            />
          </VerticalContainer>
        </LinearCenterContainer>
      )}
      <Spacer />
      <MenuButton
        disabled={deleteDisabled}
        stopEventPropagation={true}
        button={<BigIcon disabled={deleteDisabled} type="more" />}
        actions={menuActions}
        menuStyles={{ backgroundColor: colorTokens.grey1837 }}
      />
    </Container>
  );
}
