import React, { ChangeEvent, Dispatch } from "react";

import { Checkbox, Input } from "antd";

import ButtonNew from "../../../common/ButtonNew/ButtonNew";
import { CachedColorPicker } from "../../../common/ColorPicker/ColorPicker";
import { InfoIconButton } from "../../../common/InfoIconButton";
import { B3, SurfaceSecondary } from "../../../common/StyledComponents";
import * as common from "../common/styledComponents";
import { ReducerAction, ReducerActions } from "../reducer/actions";
import { ConfigState } from "../reducer/reducer";
import { DispatcherType, Event } from "../types";

import Assignment from "./Assignment";
import * as styled from "./styledComponents";
import {
  TransitionLinkDirection,
  TransitionLinks
} from "./TransitionLink/TransitionLink";
import TransitionList from "./TransitionList";

interface Props {
  state: ConfigState;
  dispatch: Dispatch<ReducerAction>;
}

const State = ({ state, dispatch }: Props) => {
  const onAddTransition = () => dispatch({ type: ReducerActions.ADD_TRANSITION });

  const onAddStateAction = () =>
    dispatch({
      type: ReducerActions.ADD_ACTION,
      payload: {
        dispatcherIndex: state.selectedStateIndex,
        dispatcherType: DispatcherType.STATE,
        event: Event.BEFORE_ENTER
      }
    });

  const onStateNameChange = (e: ChangeEvent<HTMLInputElement>) =>
    dispatch({
      type: ReducerActions.UPDATE_SELECTED_STATE,
      payload: {
        name: e.target.value
      }
    });

  const onStateColorChange = (color: string) =>
    dispatch({
      type: ReducerActions.UPDATE_SELECTED_STATE,
      payload: {
        color
      }
    });

  const onStateIsArchiveChange = (isArchive: boolean) =>
    dispatch({
      type: ReducerActions.UPDATE_SELECTED_STATE,
      payload: {
        isArchive
      }
    });

  const selectedState = state.states[state.selectedStateIndex];

  return (
    <>
      <styled.Title>{selectedState.name}</styled.Title>
      <styled.TransitionLinksContainer>
        <TransitionLinks
          state={state}
          selectedState={selectedState}
          dispatch={dispatch}
          direction={TransitionLinkDirection.from}
        />
        <TransitionLinks
          state={state}
          selectedState={selectedState}
          dispatch={dispatch}
          direction={TransitionLinkDirection.to}
        />
      </styled.TransitionLinksContainer>
      <styled.Section>
        <common.FieldsetBox>
          <common.Fieldset>
            <common.Label>Color</common.Label>
            <CachedColorPicker
              value={selectedState.color}
              placement="bottom-end"
              onChange={onStateColorChange}
            />
          </common.Fieldset>
          <common.Fieldset>
            <common.Label>State name</common.Label>
            <Input
              placeholder="Enter name"
              value={selectedState.name}
              onChange={onStateNameChange}
            />
          </common.Fieldset>
        </common.FieldsetBox>
        <common.FieldsetBox>
          <common.Fieldset>
            <common.Label>Archive</common.Label>
            <Checkbox
              checked={selectedState.isArchive}
              onChange={evt => onStateIsArchiveChange(evt.target.checked)}
            >
              <B3>
                <SurfaceSecondary>
                  Tasks entering this state are archived and hidden in the "All open"
                  tab.
                </SurfaceSecondary>
              </B3>
            </Checkbox>
          </common.Fieldset>
        </common.FieldsetBox>
      </styled.Section>
      <styled.Section>
        <styled.SectionHeader>
          <styled.SectionTitleContainer>
            <common.SubTitleWithDescription>Assignment</common.SubTitleWithDescription>
            <common.SectionDescription>
              Change the assignee when a task enters this state
            </common.SectionDescription>
          </styled.SectionTitleContainer>
          <ButtonNew
            type="primary"
            icon="plus"
            onClick={onAddStateAction}
            disabled={selectedState.subscriptions.beforeEnter.length !== 0}
          >
            Add assignment
          </ButtonNew>
        </styled.SectionHeader>
        <Assignment
          index={state.selectedStateIndex}
          dispatch={dispatch}
          state={state}
        />
      </styled.Section>
      <styled.Section>
        <styled.SectionHeader>
          <styled.SectionTitleContainer>
            <common.SubTitleWithDescription>
              Call-to-actions{" "}
              <InfoIconButton title="Call-to-actions (CTAs) are buttons that appear in the task detail view. They are used to perform actions on tasks." />
            </common.SubTitleWithDescription>
            <common.SectionDescription>
              Add call-to-actions to this state
            </common.SectionDescription>
          </styled.SectionTitleContainer>
          <ButtonNew icon="plus" type="primary" size="sm" onClick={onAddTransition}>
            New CTA
          </ButtonNew>
        </styled.SectionHeader>
        <TransitionList
          currentState={selectedState}
          dispatch={dispatch}
          state={state}
        />
      </styled.Section>
    </>
  );
};

export default State;
