import React from "react";

import { Tooltip, Icon } from "antd";
import { TooltipProps } from "antd/lib/tooltip";
import styled from "styled-components";

interface Props extends TooltipProps {
  message: string;
  description: React.ReactNode;
  children: React.ReactNode;
}

const TipTitle = styled.h4`
  display: flex;
  align-items: center;
  color: white;
  font-weight: 600;
`;

const WarningIcon = styled(Icon)`
  margin-right: ${props => props.theme.spacersm};
  color: ${props => props.theme.warningColor};
  font-size: ${props => props.theme.h3FontSize};
`;

export default function WarningTooltip({
  message,
  description,
  children,
  ...rest
}: Props) {
  return (
    <Tooltip
      title={
        <div>
          <TipTitle>
            <WarningIcon type="warning" />
            {message}
          </TipTitle>
          <p>{description}</p>
        </div>
      }
      {...rest}
    >
      {children}
    </Tooltip>
  );
}
