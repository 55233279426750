import { useEvaluaterContext } from "../../../../../../common/CodeSandbox/EvaluaterContext";
import { EventHandler } from "../../../../../types";
import { useComponentContext } from "../../../contexts/ComponentContext";
import { useComponentStateContext } from "../../../contexts/ComponentStateContext";
import { setVariable } from "../Effects/SetVariable";

export enum DialogEventType {
  CLOSE = "close"
}

type DialogEvents = { type: DialogEventType.CLOSE };

export default function useDialogEffects() {
  const { component } = useComponentContext();
  const componentStateContext = useComponentStateContext();
  const { evaluate } = useEvaluaterContext();
  const effects: EventHandler[] = component.properties.effects;

  function triggerEffects(ev: DialogEvents) {
    effects
      .filter(e => e.type === ev.type)
      .forEach(({ effect }) => {
        switch (effect.type) {
          case "set_variable": {
            const setVarEffect = effect;
            setVariable(
              setVarEffect,
              evaluate,
              componentStateContext.input,
              componentStateContext.setVariable
            );
            break;
          }
        }
      });
  }

  return {
    triggerEffects
  };
}
