import Icon from "../../../../../assets/spaceicons/function.svg";
import {
  SpaceComponentHostType,
  SpaceComponentObject,
  SpaceComponentPackage,
  SpaceComponentTags
} from "../../../../../types";
import { ElementLayout } from "../../../layout/util";
import {
  errorSelector,
  getOutputBinding,
  getSchema
} from "../common/FunctionBackedPopover";
import reducer, {
  ensureSubmittableComponent,
  makeInitialState
} from "../common/FunctionBackedPopover/reducer/reducer";

import Config from "./Config";
import SpaceFunctionHeadless from "./SpaceFunctionHeadless";
import {
  SpaceHeadlessFunctionComponent,
  SpaceHeadlessFunctionComponentProps,
  SpaceHeadlessFunctionConfigState
} from "./types";

const _makeInitialState = (
  draftComponent: SpaceComponentObject
): SpaceHeadlessFunctionConfigState => {
  return {
    ...makeInitialState(draftComponent),
    type: "FUNCTION_HEADLESS",
    filterValidityTrackers: []
  };
};

const _ensureComponent = (sc: SpaceComponentObject): SpaceHeadlessFunctionComponent => {
  ensureSubmittableComponent(sc);
  sc.properties.filters = [];
  return sc;
};

const pkg: SpaceComponentPackage<
  SpaceHeadlessFunctionComponentProps,
  SpaceHeadlessFunctionComponent,
  SpaceHeadlessFunctionConfigState
> = {
  Icon,
  Component: SpaceFunctionHeadless,
  ConfigurationComponent: Config,
  allowAncestorBinding: true,
  allowSelfBinding: true,
  allowedHosts: new Set<SpaceComponentHostType>(),
  displayName: "Function",
  componentConfigReducer: reducer,
  ensureComponent: _ensureComponent,
  errorSelector,
  getInitialDraftState: _makeInitialState,
  getOutputBinding,
  getSchema,
  defaultElementLayout: new ElementLayout({
    width: "10%",
    height: "32px",
    snapToGrid: false
  }),
  isHeadless: true,
  hasConfigIcon: true,
  type: "FUNCTION_HEADLESS",
  tags: [SpaceComponentTags.Base],
  isContainer: false
};
export default pkg;
