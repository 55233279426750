import React, { useState } from "react";

import { useQuery } from "@apollo/react-hooks";
import { Input, Icon, Tooltip } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { Connection, EnvironmentNode } from "../../../types";
import useAuthUser from "../../common/hooks/useAuthUser";
import { P } from "../../common/StyledComponents";
import { nextPlanUp } from "../../util/billing";

import reducer, { getError, initialState } from "./manageEnvironmentsReducer";
import { ALL_ENVIRONMENTS } from "./queries";
import * as styledComp from "./styledComponents";
import useSetEnvironmentsMutation from "./useSetEnvironmentsMutation";

interface ManageEnvironmentsProps {
  onClose: () => void;
}

interface AllEnvironmentsData {
  allEnvironments: Connection<EnvironmentNode>;
}

const EnvironmentPaywall = styled(styledComp.Modal)`
  left: -190px
  top: 200px;
`;

const EnvModal = styled(styledComp.Modal)`
  position: relative;
`;

const EnvPaywallBody = styled(P)`
  padding: 20px;
`;

export default function ManageEnvironmentsModal({ onClose }: ManageEnvironmentsProps) {
  const { data, loading } = useQuery<AllEnvironmentsData>(ALL_ENVIRONMENTS, {
    fetchPolicy: "network-only"
  });
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { save, loading: mutationLoading } = useSetEnvironmentsMutation(state, onClose);
  const { authUser } = useAuthUser();
  const [showEnvPaywall, setShowEnvPaywall] = useState<boolean>(false);

  const features = authUser
    ? authUser.organization.licenseData
    : { environments: undefined };

  React.useEffect(() => {
    if (state.environments.length > 0 || !data) return;
    dispatch({
      type: "INITIALIZE_ENVIRONMENTS",
      payload: {
        environments: data.allEnvironments.edges.map(e => e.node)
      }
    });
  }, [data, state.environments]);

  const columns = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      width: "45%",
      render: (v: string, record: any) => {
        return (
          <Input
            data-test={`editName-${record.id}`}
            placeholder="Name your Environment"
            onChange={e =>
              dispatch({
                type: "SET_ENVIRONMENT",
                payload: { id: record.id, values: { name: e.target.value } }
              })
            }
            value={v}
          />
        );
      }
    },
    {
      title: "Data sources",
      dataIndex: "dataSourceCount",
      key: "dataSourceCount",
      width: "25%"
    },
    {
      title: "Default",
      key: "isDefault",
      dataIndex: "isDefault",
      width: "20%",
      render: (val: boolean) =>
        val && (
          <div>
            <Icon type="check" />
          </div>
        )
    },
    {
      title: null,
      key: "remove",
      width: "10%",
      render: (_: any, record: any, index: number) => {
        let disabled = false;
        let disabledReason = "";
        if (record.dataSourceCount > 0) {
          disabled = true;
          disabledReason =
            "You cannot remove an environment if it has at least one data source.";
        }

        if (record.isDefault) {
          disabled = true;
          disabledReason = "You cannot remove your default environment.";
        }
        return (
          <Tooltip
            placement="top"
            trigger={disabled ? "hover" : undefined}
            title={disabledReason}
          >
            <styledComp.RemoveEnvironmentButton
              data-test={`remove-${record.id}`}
              icon="minus"
              onClick={() =>
                dispatch({
                  type: "REMOVE_ENVIRONMENT",
                  payload: {
                    index
                  }
                })
              }
              disabled={disabled}
              type="link"
            />
          </Tooltip>
        );
      }
    }
  ];

  const error = getError(state);

  return (
    <EnvModal
      visible
      title="Manage Environments"
      okButtonProps={{ loading: mutationLoading }}
      onOk={() => {
        dispatch({ type: "SUBMIT" });
        if (!error) {
          save();
        }
      }}
      okText="Save"
      cancelText="Cancel"
      onCancel={onClose}
      width="650px"
    >
      <div>
        <styledComp.Table
          loading={loading}
          rowKey={record => (record as EnvironmentNode).id}
          dataSource={state.environments}
          columns={columns}
          pagination={false}
        />
        <styledComp.AddNewButton
          type="link"
          disabled={!features?.environments}
          data-test="addEnvironment"
          onMouseEnter={() => setShowEnvPaywall(true)}
          onClick={() =>
            dispatch({
              type: "ADD_ENVIRONMENT"
            })
          }
        >
          + Add new
        </styledComp.AddNewButton>
        {state.submitted && error && (
          <styledComp.Error data-test="manageEnvironmentsError">
            {error}
          </styledComp.Error>
        )}
      </div>

      {!features?.environments && (
        <EnvironmentPaywall
          title="Upgrade to configure multiple environments"
          visible={showEnvPaywall}
          onCancel={() => setShowEnvPaywall(false)}
          mask={false}
          footer={null}
          width={270}
        >
          <EnvPaywallBody>
            Your current plan does not support multiple environments. To add this
            feature, upgrade to the{" "}
            <Link to="/settings/change-plan">
              {nextPlanUp(authUser?.organization.currentPlan?.slug)} plan
            </Link>
            .
          </EnvPaywallBody>
        </EnvironmentPaywall>
      )}
    </EnvModal>
  );
}
