import Icon from "../../../../../assets/spaceicons/tabs.svg";
import {
  SpaceComponentPackage,
  SpaceComponentHostType,
  SpaceComponentTags
} from "../../../../../types";
import { DEFAULT_SIZES } from "../../../layout/constants";
import { ElementLayout } from "../../../layout/util";

import Config from "./Config";
import errorSelector from "./Config/errorSelector";
import componentConfigReducer, {
  ensureSpaceTabsComponent,
  makeInitialState,
  TabsConfigState
} from "./Config/reducer";
import { getSchema } from "./getSchema";
import SpaceTabs from "./SpaceTabs";
import { SpaceTabsComponent, SpaceTabsProperties } from "./types";

const pkg: SpaceComponentPackage<
  SpaceTabsProperties,
  SpaceTabsComponent,
  TabsConfigState
> = {
  type: "TABS",
  displayName: "Tabs",
  Icon,
  allowedHosts: new Set<SpaceComponentHostType>([
    "SPACE",
    "TABLE",
    "CARD_LIST",
    "DETAIL",
    "FLEX_BOX"
  ]),
  Component: SpaceTabs,
  ConfigurationComponent: Config,
  componentConfigReducer,
  getInitialDraftState: makeInitialState,
  ensureComponent: ensureSpaceTabsComponent,
  getSchema,
  defaultElementLayout: new ElementLayout({
    width: DEFAULT_SIZES.STANDARD.width,
    height: DEFAULT_SIZES.STANDARD.height,
    snapToGrid: true
  }),
  allowSelfBinding: false,
  allowAncestorBinding: true,
  hasInlineChildren: true,
  tags: [SpaceComponentTags.Base],
  isHeadless: false,
  isContainer: false,
  errorSelector,
  grammaticalNumber: "plural"
};

export default pkg;
