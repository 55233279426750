import gql from "graphql-tag";

import { DataSourceNode, RelayNode } from "../../../../types";

export interface UserNode extends RelayNode {
  firstName: string;
  lastName: string;
}

export interface AutomationNode extends RelayNode {
  name: string;
}

export interface FunctionNode extends RelayNode {
  name: string;
  title: string;
  dataSource: DataSourceNode;
}

export enum ExecutionStatus {
  Started = "STARTED",
  Succeeded = "SUCCEEDED",
  Failed = "FAILED"
}

export const AUTOMATION_BASIC_FRAGMENT = gql`
  fragment AutomationBasicFragment on AutomationNode {
    id
    name
    description
    icon
    color
    slug
    startAt
    endAt
    isActive
  }
`;

export const AUTOMATION_FRAGMENT = gql`
  fragment AutomationFragment on AutomationNode {
    id
    name
    description
    icon
    color
    slug
    startAt
    endAt
    isActive
    automationEnvironments {
      edges {
        node {
          id
          environment {
            id
          }
          lastRunAt
          nextRunAt
        }
      }
    }
  }
`;
