import { getClientProvidedComponents } from "../../../SpaceRoot/SpaceContext/useClientProvidedComponents";
import { injectSpaces } from "../../../util/injectSpaces";

import { selectSpaceComponentTree, SpaceConfigState } from "./reducer";

export function selectSpaceFromConfig(state: SpaceConfigState) {
  const clientProvidedComponents = getClientProvidedComponents(
    Array.from(state.packages.values())
  );
  const spaceTrees = new Map(
    Array.from(state.spaces.values()).map(s => [s.slug, selectSpaceComponentTree(s)])
  );
  const rootSpaceTree = spaceTrees.get(state.rootSpaceSlug);
  if (!rootSpaceTree) return [];
  const injectedComponents = injectSpaces(rootSpaceTree, spaceTrees);
  return injectedComponents.concat(clientProvidedComponents);
}
