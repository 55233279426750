import { cloneDeep, flow, partial } from "lodash";

import { SpaceComponentObject } from "../../../../../../types";
import commonComponentReducer from "../../../../SpaceConfig/SpaceConfigContext/useSpaceConfig/reducer/componentReducer";
import {
  SpaceConfigAction,
  BaseComponentConfigState,
  ComponentConfigState
} from "../../../../types";
import { SpaceModalComponent } from "../types";

export interface SpaceModalConfigState
  extends BaseComponentConfigState<SpaceModalComponent> {
  type: "MODAL";
}

export const INITIAL_STATE = {
  type: "MODAL" as const,
  draftComponent: {} as SpaceModalComponent
};

function isModalConfigState(
  state: ComponentConfigState
): state is SpaceModalConfigState {
  return state.type === "MODAL";
}

export function ensureModalConfigState(
  state: ComponentConfigState
): SpaceModalConfigState {
  if (isModalConfigState(state)) return state;

  throw new Error("Expected MODAL config state.");
}

export function ensureSpaceModalComponent(
  component: SpaceComponentObject
): SpaceModalComponent {
  if (Array.isArray(component.properties.tabs)) return component;
  return {
    ...component,
    properties: {
      title: "",
      size: "md",
      has_close_button: true,
      effects: [],
      ...component.properties
    }
  };
}

export function makeInitialState(
  draftComponent: SpaceComponentObject
): SpaceModalConfigState {
  return {
    ...INITIAL_STATE,
    draftComponent: ensureSpaceModalComponent(cloneDeep(draftComponent))
  };
}

function reducer(
  state: SpaceModalConfigState,
  _action: SpaceConfigAction
): SpaceModalConfigState {
  return state;
}

export default (state: SpaceModalConfigState, action: SpaceConfigAction) =>
  flow([commonComponentReducer, partial(reducer, partial.placeholder, action)])(
    state,
    action
  );
