import RWSelect from "react-windowed-select";
import styled from "styled-components";

import Logo from "../../../../../assets/github-mark-white.svg";

export const GitHubLogoWhite = styled(Logo)`
  height: 18px;
  padding-left: 8px;
`;

export const Select = styled(RWSelect)`
  min-width: 114px;
  max-width: 114px;

  & .react-select__value-container--has-value {
    padding-left: 8px;
  }

  .react-select__input {
    color: white;
    font-size: 12px;
    input {
      font-weight: 500;
    }
  }
`;
