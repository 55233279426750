import React from "react";

import { captureMessage, Severity } from "@sentry/browser";
import { Alert as AntAlert } from "antd";
import { isEqual } from "lodash";
import styled from "styled-components";

import { Props } from "..";
import { MAX_VIEW_ROWS } from "../../../../../constants";
import { SpacingUnitValue } from "../../../../../cssConstants";
import useObservedRect from "../../../../common/hooks/useObservedRect";
import { useResizeContext } from "../../ResizeContext";
import { useStableSpaceContext } from "../../SpaceContext";
import EmptyState from "../common/EmptyState";
import Panel from "../common/Panel";
import useRefreshComponent from "../common/useRefreshComponent";
import useView, { QueryExecutionRequirement } from "../common/useView";
import { ViewBlockingStates } from "../common/useViewBlockingStates/useViewBlockingStates";
import ViewComponentTitle from "../common/ViewComponentTitle/ViewComponentTitle";
import ViewEnsurer from "../common/ViewEnsurer/ViewEnsurer";
import { useComponentStateContext } from "../contexts/ComponentStateContext";

import Chart, { ChartProps } from "./Chart";
import { ensureSpaceChartComponent } from "./Config/reducer";

export const RECORDS_PER_PAGE = MAX_VIEW_ROWS;
// This is not ideal but difficult to measure from outside of the panel
const FLEX_BOX_HEADER_HEIGHT = 77;

const FullHeightDiv = styled.div`
  height: 100%;
`;

const ChartContainer = styled.div`
  padding: ${props => props.theme.spacerlg};
  padding-left: 0; /* Charts include a large left gutter already */
`;

const Alert = styled(AntAlert)`
  margin-bottom: ${props => props.theme.spacermd};
`;
Alert.displayName = "Alert";

export default function SpaceChart(props: Props) {
  const { spaceApi, spaceComponent } = props;
  const chartComponent = ensureSpaceChartComponent(props.spaceComponent);

  const [height, setHeight] = React.useState(0);
  const { lastResize } = useResizeContext();
  const rootEl = React.useRef<HTMLDivElement | null>(null);
  const alertEl = React.useRef<HTMLDivElement | null>(null);

  const { spaceId } = useStableSpaceContext();
  const { input } = useComponentStateContext();

  const viewResult = useView(spaceId, chartComponent, input, {
    limit: RECORDS_PER_PAGE,
    queryExecutionRequirement: !!chartComponent?.properties?.is_filter_required
      ? QueryExecutionRequirement.ANY_FILTER
      : QueryExecutionRequirement.NONE
  });
  const { rows, loading, hasNextPage, refresh } = viewResult;

  useRefreshComponent(spaceComponent, spaceApi, refresh);

  React.useEffect(() => {
    if (hasNextPage) {
      captureMessage(
        `Chart query result contains more than ${RECORDS_PER_PAGE} rows`,
        Severity.Warning
      );
    }
  }, [hasNextPage]);

  // To get a fixed height and fix https://github.com/recharts/recharts/issues/135
  // we need to wrap the <Panel />, since its <Body /> is part of the flex-grow problem.
  // Unfortunately this means we need to factor in panel header height, panel padding,
  //  and if there is an alert about the row count, we need to account for that too.
  const calculateHeight = React.useCallback(() => {
    const panelHeight = rootEl.current?.getBoundingClientRect().height || 0;
    const alertHeight = alertEl.current?.getBoundingClientRect().height || 0;
    return (
      panelHeight -
      alertHeight -
      FLEX_BOX_HEADER_HEIGHT -
      2 * SpacingUnitValue.lg - // padding in Panel Body
      2 // fudge to avoid scrollbars
    );
  }, []);

  const oservedRect = useObservedRect(rootEl);

  React.useLayoutEffect(() => {
    const newHeight = calculateHeight();
    if (newHeight && newHeight !== height) {
      setHeight(newHeight);
    }
  }, [lastResize, oservedRect.height, calculateHeight, height, loading]);

  const alertDescription = (
    <div>
      This chart is incomplete. It is displaying data from the first {RECORDS_PER_PAGE}{" "}
      records, but more data is available.{" "}
      <a
        href="https://www.internal.io/docs/custom-sql-resources"
        target="_blank"
        rel="noopener noreferrer"
      >
        Learn more
      </a>
      .
    </div>
  );

  return (
    <FullHeightDiv ref={rootEl}>
      <Panel
        title={
          chartComponent.properties.is_header_enabled && (
            <ViewComponentTitle component={chartComponent} viewResult={viewResult} />
          )
        }
        hasError={props.hasConfigError}
      >
        <ViewEnsurer
          viewResult={viewResult}
          overrides={{
            [ViewBlockingStates.MISSING_FUNCTION]: (
              <EmptyState message="No data associated with this chart" />
            )
          }}
        >
          <ChartContainer>
            {hasNextPage && (
              <div ref={alertEl}>
                <Alert
                  message={null}
                  showIcon
                  type="warning"
                  description={alertDescription}
                />
              </div>
            )}
            <div style={{ height }}>
              <MemoChart
                rows={rows || []}
                component={ensureSpaceChartComponent(props.spaceComponent)}
              />
            </div>
          </ChartContainer>
        </ViewEnsurer>
      </Panel>
    </FullHeightDiv>
  );
}

const MemoChart = React.memo(Chart, (prevProps: ChartProps, nextProps: ChartProps) => {
  return (
    prevProps.rows === nextProps.rows &&
    isEqual(prevProps.component, nextProps.component)
  );
});
