import React, { ReactNode } from "react";

import styled from "styled-components";

import { B3 } from "../../../../../common/StyledComponents";

const Root = styled.main`
  overflow: hidden;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.newContainerPrimary};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${props => props.theme.spacersm};
  padding: ${props => props.theme.spacermd};
`;

const IconWrapper = styled.div`
  color: ${props => props.theme.surfaceSecondary};
  svg {
    width: 30px;
    height: 30px;
  }
`;

const Message = styled(B3)`
  color: ${props => props.theme.surfaceSecondary};
`;

const InlineMessage = styled.div`
  color: ${props => props.theme.textColorMid};
  text-align: center;
  font-size: ${props => props.theme.smallFontSize};
`;

export default function EmptyState({
  message = "Select data to display",
  inline = false,
  details,
  icon,
  children,
  className
}: {
  message?: string;
  inline?: boolean;
  details?: string;
  icon?: ReactNode;
  children?: React.ReactNode;
  className?: string;
}) {
  if (inline) return <InlineMessage>{message}</InlineMessage>;

  return (
    <Root className={className}>
      <Content>
        {icon && <IconWrapper>{icon}</IconWrapper>}
        <Message>{message}</Message>
        {details && <Message>{details}</Message>}
        {children}
      </Content>
    </Root>
  );
}
