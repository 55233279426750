import { cloneDeep, flow, partial } from "lodash";

import { SpaceComponentObject, SourceType } from "../../../../../../types";
import { createSpaceFunction } from "../../../../FunctionExecutor/FunctionExecutor";
import commonComponentReducer from "../../../../SpaceConfig/SpaceConfigContext/useSpaceConfig/reducer/componentReducer";
import {
  SpaceConfigAction,
  BaseComponentConfigState,
  ComponentConfigState
} from "../../../../types";
import filterListManagerReducer, {
  INITIAL_STATE as FILTER_LIST_MANAGER_INITIAL_STATE,
  FilterListManagerState,
  getInitialFilterState
} from "../../common/FilterListManager/reducer/reducer";
import parameterConfigReducer, {
  INITIAL_STATE as PARAMETER_CONFIG_INITIAL_STATE,
  getInitialParameterState,
  ParameterConfigState
} from "../../common/ParametersManager/reducer/reducer";
import viewDataConfigReducer, {
  INITIAL_STATE as VIEW_DATA_CONFIG_INITIAL_STATE,
  ViewConfigState
} from "../../common/useViewConfig/reducer";
import { functionAttributeToAttribute } from "../../common/useViewConfig/util";
import { AggregationFunctionType } from "../../SpaceChart/types";
import { SpaceStatComponent } from "../types";

export interface StatConfigState
  extends BaseComponentConfigState<SpaceStatComponent>,
    ViewConfigState,
    FilterListManagerState,
    ParameterConfigState {
  type: "STAT";
}

export const INITIAL_STATE = {
  type: "STAT" as const,
  draftComponent: {} as SpaceStatComponent,
  ...VIEW_DATA_CONFIG_INITIAL_STATE,
  ...FILTER_LIST_MANAGER_INITIAL_STATE,
  ...PARAMETER_CONFIG_INITIAL_STATE
};

function isStatConfigState(state: ComponentConfigState): state is StatConfigState {
  return state.type === "STAT";
}

export function ensureStatConfigState(state: ComponentConfigState): StatConfigState {
  if (isStatConfigState(state)) return state;

  throw new Error("Expected Stat config state.");
}

export function ensureSpaceStatComponent(
  component: SpaceComponentObject
): SpaceStatComponent {
  if (
    Array.isArray(component.properties.columns) &&
    Array.isArray(component.properties.filters) &&
    Array.isArray(component.properties.order) &&
    Array.isArray(component.properties.input_parameters) &&
    Array.isArray(component.properties.conditional_styles)
  ) {
    return component;
  }
  return {
    ...component,
    sourceType: component.sourceType || SourceType.VIEW,
    properties: {
      ...component.properties,
      columns: Array.isArray(component.properties.columns) || [],
      filters: component.properties.filters || [],
      is_filter_required: component.properties.is_filter_required ?? false,
      order: component.properties.order || [],
      input_parameters: component.properties.input_parameters || [],
      aggregation_function: component.properties.aggregation_function || {
        type: AggregationFunctionType.COUNT,
        attribute: undefined
      },
      conditional_styles: component.properties.conditional_styles || []
    }
  };
}

export function makeInitialState(
  draftComponent: SpaceComponentObject
): StatConfigState {
  return {
    ...INITIAL_STATE,
    attributes: createSpaceFunction(draftComponent).functionAttributes.map(a =>
      functionAttributeToAttribute(a)
    ),
    draftComponent: ensureSpaceStatComponent(cloneDeep(draftComponent)),
    ...getInitialFilterState(draftComponent),
    ...getInitialParameterState(draftComponent)
  };
}

function reducer(state: StatConfigState, action: SpaceConfigAction): StatConfigState {
  switch (action.type) {
    case "CHANGE_FUNCTION":
    case "CHANGE_SOURCE":
    case "RESET_SOURCE":
    case "CHANGE_BINDING": {
      return {
        ...state,
        draftComponent: {
          ...state.draftComponent,
          properties: {
            ...state.draftComponent.properties,
            aggregation_function: {
              ...state.draftComponent.properties.aggregation_function,
              attribute: undefined
            }
          }
        }
      };
    }
    case "MERGE_DRAFT_COMPONENT": {
      if (!action.payload.change.properties?.aggregation_function?.type) {
        return state;
      }
      return {
        ...state,
        draftComponent: {
          ...state.draftComponent,
          properties: {
            ...state.draftComponent.properties,
            aggregation_function: {
              ...state.draftComponent.properties.aggregation_function,
              attribute: undefined
            }
          }
        }
      };
    }
    default:
      return state;
  }
}

export default (state: StatConfigState, action: SpaceConfigAction) =>
  flow([
    commonComponentReducer,
    partial(viewDataConfigReducer, partial.placeholder, action),
    partial(parameterConfigReducer, partial.placeholder, action),
    partial(filterListManagerReducer, partial.placeholder, action),
    partial(reducer, partial.placeholder, action)
  ])(state, action);
