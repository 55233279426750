import React from "react";

import { Icon as BaseIcon } from "antd";
import styled from "styled-components";

import { newColors } from "../../../../../cssConstants";
import { ExecutionStatus } from "../../queries/common";

const Icon = styled(BaseIcon)`
  font-size: 20px;
  color: ${props => props.theme.primaryAccent};
`;

interface Props {
  status: ExecutionStatus;
}

export default function RunStatusIcon({ status }: Props) {
  switch (status) {
    case ExecutionStatus.Started:
      return <Icon type="loading" />;
    case ExecutionStatus.Succeeded:
      return (
        <Icon
          type="check-circle"
          theme="filled"
          style={{ color: newColors.primaryAccent }}
        />
      );
    case ExecutionStatus.Failed:
      return (
        <Icon
          type="exclamation-circle"
          theme="filled"
          style={{ color: newColors.error }}
        />
      );
  }
}
