import React from "react";

import { Form, Input } from "antd";
import { FormComponentProps } from "antd/lib/form/Form";

import Message from "../../../../common/Message";
import { ModalNew } from "../../../../common/Modal";
import { useSpaceContext } from "../../SpaceContext";
import * as common from "../styledComponents";

import useCommitLatestSpaceVersion, {
  SpaceVersionNode
} from "./useCommitLatestSpaceVersion";

interface Props extends FormComponentProps {
  visible: boolean;
  onCancel?: () => void;
  onSuccess?: (version: SpaceVersionNode) => void;
}

function CommitModal({
  form,
  visible,
  onCancel = () => null,
  onSuccess = () => null
}: Props) {
  const { space } = useSpaceContext();
  const { getFieldDecorator, getFieldError, isFieldTouched } = form;

  const [commit, { loading }] = useCommitLatestSpaceVersion({
    onCompleted: data => {
      switch (data.commitLatestSpaceVersion.__typename) {
        case "SpaceVersionNode":
          onSuccess(data.commitLatestSpaceVersion);
          break;
        case "ScmErrorResult":
          Message.error(data.commitLatestSpaceVersion.message);
          break;
        default:
          Message.error("Unknown error");
      }
    },
    onError: e => {
      Message.error(e.message.replace("GraphQL error: ", ""));
    }
  });

  const handleSubmit = React.useCallback(() => {
    form.validateFields((err, values) => {
      if (err) return;

      commit({ variables: { spaceId: space.id, message: values.message } });
    });
  }, [commit, form, space.id]);

  const onSubmit = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      handleSubmit();
    },
    [handleSubmit]
  );

  const messageError = isFieldTouched("message") && getFieldError("message");

  return (
    <ModalNew
      confirmLoading={loading}
      destroyOnClose
      onCancel={onCancel}
      okButtonProps={{ disabled: !!messageError }}
      okText="Commit and push"
      onOk={handleSubmit}
      title="Commit and push"
      visible={visible}
    >
      <common.Form onSubmit={onSubmit}>
        <Form.Item validateStatus={messageError ? "error" : ""}>
          Commit message
          {getFieldDecorator("message", {
            rules: [{ required: true, message: "Please enter a commit message" }]
          })(<Input autoComplete="off" placeholder="Enter commit message" />)}
        </Form.Item>
      </common.Form>
    </ModalNew>
  );
}

const CommitModalForm = Form.create<Props>({ name: "commit-form" })(CommitModal);

export default CommitModalForm;
