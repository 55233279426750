import { DataSourceNodeWithFunctions } from "../components/common/FunctionEditor/forms/types";
import { SupportedIntegration } from "../components/common/FunctionEditor/support";
import { toGlobalId } from "../components/util/graphql";
import { AttributeTypes, ReturnSchema } from "../constants";
import {
  AttributeNode,
  BaseFunctionNodeBasic,
  BasicAttributeNode,
  Connection,
  DatabaseCredentials,
  DataSourceNode,
  Edge,
  EnvironmentNode,
  FiltersOption,
  FunctionAttributeNode,
  FunctionNode,
  FunctionNodeBasic,
  FunctionParameterNode,
  HttpCredentials,
  PageInfo,
  RelayNode,
  ResourceNode,
  RoleNode,
  UserNode,
  ViewFilterOperator,
  ViewNode
} from "../types";

export const createEnvironmentNode = (
  overrides?: Partial<EnvironmentNode>
): EnvironmentNode => ({
  id: "",
  dataSourceCount: 0,
  isDefault: false,
  name: "",
  slug: "",
  ...overrides
});

export const createConnection = <T extends RelayNode>(
  items: T[],
  overrides?: Partial<Connection<T>>,
  edgeOverrides?: Partial<Edge<T>>
) => ({
  edges: items.map(node => ({ node, ...edgeOverrides })),
  ...overrides
});

export const createDataSourceNode = (
  overrides: Partial<DataSourceNode> = {}
): DataSourceNode => ({
  id: "",
  name: "",
  autoSyncEnabled: false,
  __typename: "DataSourceNode",
  ...overrides
});

export const createDatabaseCredentials = (
  overrides?: Partial<DatabaseCredentials>
): DatabaseCredentials => ({
  adapter: "postgresql",
  database: "",
  host: "",
  port: "",
  user: "",
  ...overrides
});

export const createHttpCredentials = (
  overrides?: Partial<HttpCredentials>
): HttpCredentials => ({
  adapter: "http",
  headers_json: "",
  host: "",
  path: "",
  port: "",
  scheme: "",
  shared_secret: "",
  user: "",
  ...overrides
});

export function createDataSourceNodeWithFunctions<
  T extends FunctionNodeBasic | BaseFunctionNodeBasic
>(
  overrides: Partial<DataSourceNodeWithFunctions<T>> = {}
): DataSourceNodeWithFunctions<T> {
  return {
    id: "",
    integration: SupportedIntegration.HTTP,
    name: "",
    __typename: "DataSourceNode",
    credentials: createDatabaseCredentials(),
    functions: { edges: [] },
    ...overrides
  };
}

export const createResourceNode = (
  mixin: Partial<ResourceNode> = {}
): ResourceNode => ({
  __typename: "ResourceNode",
  id: "",
  name: "",
  slug: "",
  sourceName: "",
  ...mixin
});

export const createBasicAttributeNode = (
  mixin: Partial<BasicAttributeNode> = {}
): BasicAttributeNode => ({
  id: toGlobalId("AttributeNode", "abc123"),
  name: "",
  sourceName: "",
  sourceIndex: 0,
  sourceType: AttributeTypes.STRING,
  sourcePrimaryKey: false,
  sourceNullable: false,
  renderTemplate: null,
  recordIndex: 0,
  ...mixin
});

export const createAttributeNode = (
  mixin: Partial<AttributeNode> = {}
): AttributeNode => ({
  ...createBasicAttributeNode(),
  ...{
    resource: undefined
  },
  ...mixin
});

export const createFunctionAttributeNode = (
  overrides?: Partial<FunctionAttributeNode>
): FunctionAttributeNode => ({
  id: "",
  sourceName: "",
  name: "",
  sourceIndex: 0,
  sourceKey: false,
  sourceType: AttributeTypes.INT,
  ...overrides
});

export const createPageInfo = (mixin: Partial<PageInfo> = {}): PageInfo => ({
  endCursor: "",
  hasNextPage: false,
  __typename: "PageInfo",
  ...mixin
});

export const createViewNode = (mixin: Partial<ViewNode> = {}): ViewNode => ({
  data: undefined,
  function: undefined,
  id: "",
  rows: {
    edges: [],
    pageInfo: createPageInfo(),
    __typename: "ViewRowConnection"
  },
  ...mixin
});

export const createFiltersOption = (
  mixin: Partial<FiltersOption> = {}
): FiltersOption => ({
  name: "",
  operators: [ViewFilterOperator.EQUALS],
  sourceName: "",
  sourceType: AttributeTypes.STRING,
  ...mixin
});

export const createUserNode = (mixin?: Partial<UserNode>): UserNode => ({
  __typename: "UserNode",
  id: btoa("UserNode:1"),
  email: "user@test.com",
  isAdmin: false,
  scopes: [],
  roles: { __typename: "RoleNodeConnection", edges: [] },
  organization: {
    __typename: "OrganizationNode",
    id: btoa("OrganizationNode:1"),
    name: "Test"
  },
  ...mixin
});

export const createRoleNode = (mixin?: Partial<RoleNode>): RoleNode => ({
  __typename: "RoleNode",
  id: btoa("RoleNode:1"),
  name: "test-role",
  slug: "testrole123",
  isManaged: false,
  ...mixin
});

export const createFunctionNode = (
  overrides?: Partial<FunctionNode>
): FunctionNode => ({
  id: "test-base-request-function",
  name: "request",
  returnSchema: ReturnSchema.UNKNOWN,
  description: "",
  title: "Base Request Function",
  functionParameters: {
    edges: []
  },
  baseFunction: undefined,
  baseFunctionParameterMapping: {},
  reducer: "data",
  metadata: {},
  isUserGenerated: false,
  isPipeline: false,
  __typename: "FunctionNode",
  ...overrides
});

export const createFunctionParameter = (
  overrides?: Partial<FunctionParameterNode>
): FunctionParameterNode => ({
  id: "test-function-parameter-1",
  name: "FunctionParameter 1",
  type: AttributeTypes.STRING,
  required: true,
  __typename: "FunctionParameterNode",
  ...overrides
});

export const createFunctionAttribute = (
  overrides?: Partial<FunctionAttributeNode>
): FunctionAttributeNode => ({
  id: "test-function-attribute-1",
  name: "FunctionAttribute 1",
  sourceIndex: 0,
  sourceType: AttributeTypes.STRING,
  sourceKey: false,
  sourceName: "attr1",
  ...overrides
});
