import React, { Dispatch, useMemo } from "react";

import { InputParameter } from "../../../../../types";
import ButtonNew from "../../../../common/ButtonNew/ButtonNew";
import FormBuilderModal from "../../../../common/FormBuilderModal";
import { getUserEnvironmentBindingSchema } from "../../../../common/schema";
import formBuilderReducer, {
  INTERNAL_TASK_PARAMETERS
} from "../../common/formBuilderReducer";
import * as common from "../../common/styledComponents";
import { ReducerAction, ReducerActions } from "../../reducer/actions";
import { ConfigState } from "../../reducer/reducer";
import {
  fieldsToCreateFunctionNode,
  getTaskSchemaFromFields
} from "../../reducer/utils";

interface Props {
  state: ConfigState;
  dispatch: Dispatch<ReducerAction>;
}

export default function Form({ state, dispatch }: Props) {
  const [showFormBuilder, setShowFormBuilder] = React.useState(false);
  const schema = useMemo(() => {
    return {
      ...getUserEnvironmentBindingSchema(),
      ...getTaskSchemaFromFields(state.fields)
    };
  }, [state.fields]);

  const onInputParametersSave = (inputParameters: InputParameter[]) => {
    dispatch({
      type: ReducerActions.UPDATE_CREATE_TASK_INPUT_PARAMETERS,
      payload: {
        inputParameters
      }
    });
    setShowFormBuilder(false);
  };

  return (
    <common.Section>
      <common.Header>
        <div>
          <common.SubTitleWithDescription>
            Form configuration
          </common.SubTitleWithDescription>
          <common.SectionDescription>
            When the user creates a new task or updates an existing task, this form will
            be displayed.
          </common.SectionDescription>
        </div>
        <ButtonNew type="primary" icon="edit" onClick={() => setShowFormBuilder(true)}>
          Edit form
        </ButtonNew>
        {showFormBuilder && (
          <FormBuilderModal
            title="Create task form"
            schema={schema}
            func={fieldsToCreateFunctionNode(state.fields)}
            initialInputParameters={state.createTaskInputParameters}
            onSave={onInputParametersSave}
            onCancel={() => setShowFormBuilder(false)}
            internalFields={INTERNAL_TASK_PARAMETERS}
            customReducer={formBuilderReducer}
          />
        )}
      </common.Header>
    </common.Section>
  );
}
