import { throttle } from "lodash";

const coords = {
  client: new DOMPoint(),
  page: new DOMPoint(),
  event: new MouseEvent("mousemove")
};

document.addEventListener("DOMContentLoaded", function () {
  const trackMouse = throttle(
    (evt: MouseEvent) => {
      coords.client = new DOMPoint(evt.clientX, evt.clientY);
      coords.page = new DOMPoint(evt.pageX, evt.pageY);
      coords.event = evt;
    },
    25,
    { leading: false }
  );
  window.addEventListener("mousemove", trackMouse);
});

export default coords;
