import {
  SpaceComponentObject,
  Filter,
  Order,
  AttributeNode,
  BaseComponentProperties
} from "../../../../../types";
import { EventHandler } from "../../../types";
import { Column } from "../common/ColumnListManager";
import { ConditionalExpression } from "../common/Conditionals/types";
import { InputParameter } from "../common/useFuncParams/types";

import { TableConfigState as _TableConfigState } from "./Config/reducer";
import { SpaceStateTableRow } from "./useRows";

export type SpaceTableProperties = BaseComponentProperties & {
  binding: string | undefined;
  title: string;
  columns: Column[] | null;
  filters: Filter[];
  order: Order[];
  is_header_enabled: boolean;
  is_search_enabled: boolean;
  is_csv_export_enabled: boolean;
  is_export_hidden_columns_enabled: boolean;
  is_filter_required: boolean;
  is_resource_table: boolean;
  allow_select_multiple: boolean;
  visibility_rules: ConditionalExpression[] | undefined;
  effects: EventHandler[];
  input_parameters: InputParameter[];
};

export type SpaceTableComponent = SpaceComponentObject<SpaceTableProperties>;

export enum SpaceTableStatus {
  IDLE = "IDLE",
  INITIAL_LOADING = "INITIAL_LOADING",
  INITIAL_LOAD_COMPLETE = "INITIAL_LOAD_COMPLETE",
  RELOADING = "RELOADING",
  RELOAD_COMPLETE = "RELOAD_COMPLETE"
}

export interface SpaceTableState {
  attributes: AttributeNode[];
  selectedRow?: SpaceStateTableRow;
  selectedRows?: SpaceStateTableRow[];
  status: SpaceTableStatus;
}

export type TableConfigState = _TableConfigState;
