import React from "react";

import {
  BaseFunctionName,
  DataSourceNode,
  FunctionNode,
  InputParameter
} from "../../../../../types";
import { ConditionActionType, PipelineStep } from "../../useFunctionEditor/queries";
import { SetPipelineStepConditionArguments } from "../../useFunctionEditor/useFunctionEditor";

import { PipelineStepType } from "./constants";
import * as styled from "./styledComponents";

interface Props {
  baseFunctionId?: string;
  steps: PipelineStep[];
  selectedStepIndex?: number;
  selectedStepConditionIndex?: number;
  className?: string;
  showErrors: boolean;
  setBaseFunctionName: (name: BaseFunctionName) => void;
  onAddPipelineStep: () => void;
  onRemovePipelineStep: (stepIndex: number) => void;
  onSelectPipelineStep: (stepIndex: number) => void;
  onSelectPipelineStepCondition: (conditionIndex: number) => void;
  onSetPipelineStepName: (stepIndex: number, newName: string) => void;
  onSetPipelineStepType: (stepIndex: number, type: PipelineStepType) => void;
  onSetPipelineStepDataSource: (stepIndex: number, dataSource: DataSourceNode) => void;
  onSetPipelineStepFunction: (stepIndex: number, func: FunctionNode) => void;
  onSetPipelineStepExpression: (stepIndex: number, expression: string) => void;
  onSetPipelineStepCondition: (
    stepIndex: number,
    payload: SetPipelineStepConditionArguments
  ) => void;
  onSetPipelineStepConditionFunction: (
    stepIndex: number,
    conditionIndex: number,
    functionId?: string
  ) => void;
  onSetPipelineStepConditionActionType: (
    stepIndex: number,
    conditionIndex: number,
    actionType: ConditionActionType
  ) => void;
  onSetPipelineStepInputParameters: (
    stepIndex: number,
    parameters: InputParameter[]
  ) => void;
  onSetPipelineStepConditionInputParameters: (
    stepIndex: number,
    conditionIndex: number,
    parameters: InputParameter[]
  ) => void;
  onLoadPipelineStepConditionFunction: (
    stepIndex: number,
    conditionIndex: number,
    func: FunctionNode
  ) => void;
  onSetPipelineStepIteratorBinding: (
    stepIndex: number,
    iteratorBinding: string
  ) => void;
  onSetPipelineStepIteratorItemName: (
    stepIndex: number,
    iteratorItemName: string
  ) => void;
}

export default function PipelineActionForm({
  steps,
  selectedStepIndex,
  selectedStepConditionIndex,
  className,
  showErrors,
  onAddPipelineStep,
  onRemovePipelineStep,
  onSelectPipelineStep,
  onSelectPipelineStepCondition,
  onSetPipelineStepName,
  onSetPipelineStepType,
  onSetPipelineStepDataSource,
  onSetPipelineStepFunction,
  onSetPipelineStepExpression,
  onSetPipelineStepCondition,
  onSetPipelineStepConditionActionType,
  onSetPipelineStepConditionFunction,
  onSetPipelineStepInputParameters,
  onSetPipelineStepConditionInputParameters,
  onLoadPipelineStepConditionFunction,
  onSetPipelineStepIteratorBinding,
  onSetPipelineStepIteratorItemName
}: Props) {
  return (
    <styled.StepListContainer className={className}>
      {steps.map((step: PipelineStep, index: number) => (
        <styled.Step
          key={index}
          number={index + 1}
          selected={index === selectedStepIndex}
          showBottomLine={index < steps.length - 1}
        >
          {index !== selectedStepIndex ? (
            <styled.StepSummaryButton
              step={step}
              onClick={() => onSelectPipelineStep(index)}
              onRemoveStep={onRemovePipelineStep}
            />
          ) : (
            <styled.ConfigPipelineStep
              step={step}
              steps={steps}
              showErrors={showErrors}
              selectedStepConditionIndex={selectedStepConditionIndex}
              onSelectStepCondition={onSelectPipelineStepCondition}
              onRemovePipelineStep={onRemovePipelineStep}
              onSetPipelineStepName={onSetPipelineStepName}
              onSetPipelineStepType={onSetPipelineStepType}
              onSetPipelineStepDataSource={onSetPipelineStepDataSource}
              onSetPipelineStepFunction={onSetPipelineStepFunction}
              onSetPipelineStepExpression={onSetPipelineStepExpression}
              onSetPipelineStepInputParameters={onSetPipelineStepInputParameters}
              onSetPipelineStepCondition={onSetPipelineStepCondition}
              onSetPipelineStepConditionActionType={
                onSetPipelineStepConditionActionType
              }
              onSetPipelineStepConditionFunction={onSetPipelineStepConditionFunction}
              onSetPipelineStepConditionInputParameters={
                onSetPipelineStepConditionInputParameters
              }
              onLoadPipelineStepConditionFunction={onLoadPipelineStepConditionFunction}
              onSetPipelineStepIteratorBinding={onSetPipelineStepIteratorBinding}
              onSetPipelineStepIteratorItemName={onSetPipelineStepIteratorItemName}
            />
          )}
        </styled.Step>
      ))}
      <styled.AddStepButton icon="plus" size="small" onClick={onAddPipelineStep} />
    </styled.StepListContainer>
  );
}
