import { AttributeTypes } from "../../constants";
import { Binding, BindingShape, UserNode } from "../../types";
import { fromGlobalId } from "../util/graphql";

export interface BindingSchema {
  title: string;
  schema: Binding[];
}

export const getCurrentUserValues = (user: UserNode | null) => {
  if (!user) return {};

  return {
    id: fromGlobalId(user.id)[1],
    email: user.email,
    role: { name: user.roles?.edges[0]?.node.name }
  };
};

export const getCurrentUserSchema = (): Binding[] => [
  {
    name: "id",
    title: "ID",
    shape: BindingShape.SCALAR,
    type: AttributeTypes.STRING
  },
  {
    name: "email",
    title: "Email",
    shape: BindingShape.SCALAR,
    type: AttributeTypes.STRING
  },
  {
    name: "role",
    title: "Role",
    shape: BindingShape.OBJECT,
    attributes: [
      {
        name: "name",
        title: "Name",
        shape: BindingShape.SCALAR,
        type: AttributeTypes.STRING
      }
    ]
  }
];

export function getUserBindingSchema(): Record<string, BindingSchema> {
  return {
    user: {
      title: "User",
      schema: getCurrentUserSchema()
    }
  };
}

export const EnvironmentBindingSchema: Record<string, BindingSchema> = {
  environment: {
    title: "Environment",
    schema: [
      {
        title: "Current",
        name: "current",
        shape: BindingShape.OBJECT,
        attributes: [
          {
            title: "Name",
            name: "name",
            shape: BindingShape.SCALAR
          },
          {
            title: "Slug",
            name: "slug",
            shape: BindingShape.SCALAR
          }
        ]
      }
    ]
  }
};

export function getUserEnvironmentBindingSchema(): Record<string, BindingSchema> {
  return {
    ...getUserBindingSchema(),
    ...EnvironmentBindingSchema
  };
}
