import { cloneDeep } from "lodash";

import {
  SpaceComponentObject,
  SpaceComponentPackage,
  SpaceNode
} from "../../../../../../../types";
import { ElementLayout } from "../../../../../layout/util";
import { INITIAL_STATE, TreeNode, SpaceConfigState } from "../reducer";

export function extractTreeNodes(
  components: SpaceComponentObject[],
  container: TreeNode
): TreeNode[] {
  return components
    .filter(c =>
      container.slug === "root"
        ? c.container === null
        : c.container?.slug === container.slug
    )
    .map(c => {
      const node: TreeNode = {
        slug: c.slug,
        type: c.type,
        treeNodes: [],
        container
      };
      node.treeNodes = extractTreeNodes(components, node);
      return node;
    });
}

export function extractComponentState(
  components: SpaceComponentObject[],
  packages: Map<string, SpaceComponentPackage>
) {
  const treeRoot = {
    slug: "root",
    container: null,
    type: null,
    treeNodes: [] as TreeNode[]
  };
  treeRoot.treeNodes = extractTreeNodes(components, treeRoot);

  return {
    tree: treeRoot,
    components: new Map(
      Object.values(components).map(c => {
        const pkg = packages.get(c.type);
        return [
          c.slug,
          pkg?.getInitialDraftState
            ? pkg.getInitialDraftState(c)
            : { draftComponent: c, type: c.type }
        ];
      })
    ),
    elementLayouts: Object.values(components).reduce<
      Map<string, Partial<ElementLayout>>
    >((acc, c) => {
      if (c.layout) {
        acc.set(c.slug, c.layout);
      }
      return acc;
    }, new Map())
  };
}

export default function extractState(
  space: SpaceNode | Omit<SpaceNode, "id">,
  packages: Map<string, SpaceComponentPackage>
): SpaceConfigState {
  const spaces = [space, ...(space.version?.subSpaces || [])];
  return {
    ...cloneDeep(INITIAL_STATE),
    rootSpaceSlug: space.slug,
    spaces: new Map(
      spaces.map(({ version, __typename, ...s }) => {
        return [
          s.slug,
          {
            ...s,
            parameters: version?.parameters || [],
            name: s.name,
            slug: s.slug,
            versionId: version?.id,
            scmVersion: version?.scmVersion,
            ...extractComponentState(version?.components || [], packages)
          }
        ];
      })
    )
  };
}
