import React from "react";

import { Input } from "antd";
import styled from "styled-components";

import { DefaultTheme } from "../../../../../cssConstants";
import { AbsoluteErrorIcon } from "../../../../common/Icons";
import { FormErrorField, FlexContainer } from "../../../../common/StyledComponents";
import { IconTooltipPopper } from "../../../../common/ToolTips";
import { useSpaceContext, useStableSpaceContext } from "../../SpaceContext";
import EditModeInteractionBlocker from "../common/EditModeInteractionBlocker";
import PermissionWarningIcon from "../common/PermissionWarningIcon";
import useDebouncedOutputSyncing from "../common/useDebouncedOutputSyncing";
import { Props } from "../SpaceComponent";

import { ensureCustomFieldComponent } from "./Config/reducer";

interface RootProps {
  editMode: boolean;
  hasError: boolean;
}
const Root = styled.div<RootProps>`
  display: flex;
  flex-direction: column;
  min-width: 0;

  &.blank,
  &.noAccess,
  &.error {
    input::placeholder {
      font-style: italic;
    }
  }

  ${props =>
    props.editMode &&
    `
      & input[disabled] {
        color: ${props.theme.textColor};
        background-color: white;
        cursor: not-allowed;
        opacity: 1;
      }
    `}

  ${props =>
    props.hasError &&
    `
    .ant-input,
    .ant-input:focus,
    .ant-input:hover {
      border-color: ${props.theme.errorColor};
      box-shadow: none;
    }
  `}

  .ant-switch {
    margin-right: 3px;
  }
`;

export default function SpaceCustomField({ spaceComponent, hasConfigError }: Props) {
  const [focused, setFocused] = React.useState(false);
  const { properties } = ensureCustomFieldComponent(spaceComponent);
  const { placeholder, default_value_template } = properties;
  const { editMode } = useStableSpaceContext();
  const { debugMode } = useSpaceContext();
  const {
    localValue,
    setLocalValue,
    errorMessage,
    hasPermissionError,
    localValueError,
    localValueIsBlank
  } = useDebouncedOutputSyncing();

  let placeholderOverride = "";
  let className = "";
  const feedbackIcons = [];
  const showFeedback = editMode || debugMode;
  if (hasPermissionError || (localValue && String(localValue).match("no access"))) {
    placeholderOverride = "no access";
    className = "noAccess";
    feedbackIcons.push(<PermissionWarningIcon />);
  }
  let hasCodeFeedback = false;
  if (!focused && showFeedback) {
    if (!localValue && localValueError) {
      placeholderOverride = "error";
      className = "error";
      hasCodeFeedback = true;
      feedbackIcons.push(
        <IconTooltipPopper
          iconType="warning"
          color={DefaultTheme.warningColor}
          tooltip={<Code>{localValueError}</Code>}
        />
      );
    } else if (
      (!localValue && localValueIsBlank) ||
      String(localValue).match("undefined")
    ) {
      placeholderOverride = "blank";
      className = "blank";
      hasCodeFeedback = true;
      feedbackIcons.push(
        <IconTooltipPopper
          iconType="question-circle"
          tooltip="This resolves to a blank value."
        />
      );
    }
    if (hasCodeFeedback && default_value_template) {
      feedbackIcons.push(
        <IconTooltipPopper
          key="code-feedback"
          iconType="code"
          tooltip={<Code>{default_value_template}</Code>}
        />
      );
    }
  }

  return (
    <EditModeInteractionBlocker>
      <Root editMode={editMode} className={className} hasError={!!errorMessage}>
        <FlexContainer>
          {hasConfigError && <AbsoluteErrorIcon />}
          <Input
            placeholder={placeholder || placeholderOverride}
            value={
              hasPermissionError || localValue === undefined || localValue === null
                ? ""
                : localValue
            }
            onFocus={() => {
              setFocused(true);
            }}
            onBlur={() => {
              setFocused(false);
            }}
            onChange={e => setLocalValue(e.target.value)}
          />
          <IconContainer>{feedbackIcons}</IconContainer>
        </FlexContainer>
        {errorMessage && <FormErrorField>{errorMessage}</FormErrorField>}
      </Root>
    </EditModeInteractionBlocker>
  );
}

const Code = styled.code`
  font-size: 12px;
`;

const IconContainer = styled.div`
  position: absolute;
  pointer-events: all;
  right: 0;
  top: 8px
  display: flex;
  gap: 4px;
  right: 12px;
  align-items: center;
  justify-content: flex-end;
  background: white;
  opacity: 0.6;

  & .anticon {
    vertical-align: 0;
  }
`;
