import React from "react";

import { Popover } from "antd";
import { useNavigate } from "react-router";

import { colors } from "../../../../../cssConstants";
import { LinkButtonNew } from "../../../../common/StyledComponents";

import {
  BadgeWrapper,
  PopupContent,
  PopupTitle,
  PopupWrapper
} from "./styledComponents";

interface Props {
  className?: string;
}

export default function UpsellBadge({ className }: Props) {
  const navigate = useNavigate();

  return (
    <Popover
      overlayStyle={{ backgroundColor: colors.newBackgroundSecondaryColor }}
      content={
        <PopupWrapper>
          <PopupTitle>Upgrade to Enterprise</PopupTitle>
          <PopupContent>
            You're currently on the Starter plan. To view historical data for this
            queue, upgrade to the
            <LinkButtonNew
              onClick={() => navigate("/settings/change-plan")}
              inline={true}
            >
              Enterprise
            </LinkButtonNew>{" "}
            plan.
          </PopupContent>
        </PopupWrapper>
      }
      trigger="hover"
    >
      <BadgeWrapper className={className}>Enterprise</BadgeWrapper>
    </Popover>
  );
}
