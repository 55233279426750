import React, { useEffect, useState } from "react";

import { useQuery } from "@apollo/react-hooks";
import _ from "lodash";
import { useSearchParams } from "react-router-dom";

import { useConfigContext } from "../../../ConfigContext";
import { DARK_THEME_POPPER_PORTAL_ID } from "../../../constants";
import Drawer from "../../common/Drawer";
import IntegrationLogo from "../../common/IntegrationLogo";
import Message from "../../common/Message";
import CreateDataSourceFormContainer from "../common/CreateDataSourceFormContainer";
import CredentialsForm, { inboundIpConfig, singleDataSource } from "../CredentialsForm";
import GoogleSheets from "../GoogleSheets";
import ZendeskForm from "../ZendeskForm";

import IPHelp from "./IPHelp";
import {
  FetchCredentialsSchemaVariables,
  CredentialsSchemaData,
  FETCH_CREDENTIALS_SCHEMA
} from "./queries";
import {
  DataSourceButton,
  DataSourceContainer,
  MissingLogo,
  RoundedSquareLogo
} from "./styledComponents";

// The ordered list of integrations to show.
const integrations = [
  "mysql",
  "postgresql",
  "mssql",
  "google_sheets",
  "mongo",
  "http",
  "graphql",
  "firestore",
  "snowflake",
  "redshift",
  "gcs",
  "s3",
  "dynamodb",
  "salesforce",
  "stripe",
  "zendesk",
  "cassandra",
  "bigquery",
  "airtable",
  "mailgun"
];

function DataSourceLogo({ integration }: { integration: string }) {
  // The http icon has a background and a square shape
  // the container is a rectangle. To make the http logo look decent we inset it.
  // Otherwise it hugs the sides and has whitespace top/bottom.
  if (integration === "http") {
    return (
      <RoundedSquareLogo>
        <IntegrationLogo name={integration} defaultLogo={MissingLogo} />
      </RoundedSquareLogo>
    );
  }

  return <IntegrationLogo name={integration} defaultLogo={MissingLogo} />;
}

const SelectIntegration = () => {
  const [params] = useSearchParams();
  const [selected, setSelected] = useState<string | null>(null);
  const config = useConfigContext();

  const integration = (selected === "graphql" ? "http" : selected) || "";
  const { data } = useQuery<CredentialsSchemaData, FetchCredentialsSchemaVariables>(
    FETCH_CREDENTIALS_SCHEMA,
    {
      skip: !selected,
      variables: { integration }
    }
  );
  let schema = data?.credentialsSchema;
  if (schema && selected === "graphql") {
    schema = { ...schema, title: "GraphQL" };
  }

  const error = params.get("error");
  useEffect(() => {
    // https://tools.ietf.org/html/rfc6749#section-4.1.2.1
    if (!error) {
      return;
    }

    Message.error("An error occurred while authenticating, please try again.");
  }, [error]);

  const enabled: Record<string, boolean> = {
    gcs: window.FEATURE_FLAGS.includes("GCS"),
    google_sheets: config.isGoogleOAuthEnabled
  };
  const rows = _.chunk(
    integrations.filter(name => enabled[name] || enabled[name] === undefined),
    4
  );

  let form: React.ReactNode | null = null;
  if (selected === "google_sheets") {
    form = <GoogleSheets />;
  } else if (selected === "zendesk") {
    form = <ZendeskForm />;
  } else if (selected && schema) {
    form = (
      <CredentialsForm
        integration={integration}
        schema={schema}
        displayName={singleDataSource.has(integration)}
        help={inboundIpConfig.has(integration) ? <IPHelp /> : undefined}
      />
    );
  }

  return (
    <>
      <DataSourceContainer>
        <h1>Select an integration to get started.</h1>
        <p>
          If you're connecting a database, Internal only requires read-only access. You
          can also always connect to a replica.
        </p>
        {rows.map((row, i) => {
          return (
            <div key={i} className="row">
              {row.map((integration: string) => {
                const button = (
                  <DataSourceButton
                    key={integration}
                    onClick={() => setSelected(integration)}
                    id={integration}
                  >
                    <DataSourceLogo integration={integration} />
                  </DataSourceButton>
                );
                return button;
              })}
            </div>
          );
        })}
      </DataSourceContainer>
      <Drawer
        placement="right"
        closable={false}
        onClose={() => setSelected(null)}
        getContainer={document.getElementById(DARK_THEME_POPPER_PORTAL_ID)!}
        visible={!!form}
        width={500}
      >
        <CreateDataSourceFormContainer title={schema?.title || ""}>
          {form}
        </CreateDataSourceFormContainer>
      </Drawer>
    </>
  );
};

export default SelectIntegration;
