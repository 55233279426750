import React from "react";

import { Input, Spin, Tooltip } from "antd";
import TextArea from "antd/es/input/TextArea";

import ButtonNew from "../../common/ButtonNew/ButtonNew";
import Message from "../../common/Message";
import Modal from "../../common/Modal";
import Spacer from "../../common/Spacer";
import {
  B2,
  B3,
  C2,
  H5,
  H6,
  SurfaceTertiary,
  White
} from "../../common/StyledComponents";

import useCreateScmConfig from "./queries/useCreateScmConfig";
import useDeleteScmConfig from "./queries/useDeleteScmConfig";
import useGetScmConfig from "./queries/useGetScmConfig";
import useUpdateScmConfig from "./queries/useUpdateScmConfig";
import reducer, { ActionType, Field, getDefaultState, Action } from "./reducer";
import * as styled from "./styledComponents";
import { toCreateVariables, toUpdateVariables } from "./util";

import { Provider } from "./index";

export default function ScmConfig() {
  const [state, dispatch] = React.useReducer(reducer, getDefaultState());
  const [isConfigModalOpen, setIsConfigModalOpen] = React.useState(false);
  const isConnected = !!state.id;

  const { loading } = useGetScmConfig({
    onCompleted: data => {
      if (data.authOrganization.scmConfig) {
        dispatch({
          type: ActionType.LOAD_SCM_CONFIG_NODE,
          payload: data.authOrganization.scmConfig
        });
      }
    }
  });

  const setField = React.useCallback(
    (name: Field, value: unknown) => {
      dispatch({
        type: ActionType.SET_FIELD,
        payload: { name, value }
      });
    },
    [dispatch]
  );

  return loading ? (
    <styled.Container>
      <Spin size="large" />
    </styled.Container>
  ) : (
    <styled.Container>
      <B2>
        Integrate with external platforms to add branch and PR controls to apps on
        Internal. This allows you to manage your apps as part of your existing
        development workflow.
      </B2>
      <Spacer size="xl" />
      {isConnected && (
        <>
          <H6>Connected repository</H6>
          <Spacer size="lg" />
          <styled.ProviderContainer>
            <Card
              isConnected
              icon={<styled.GithubIcon />}
              name="Github"
              description="Provides a managed hosting solution for version control using Git."
              onClick={() => setIsConfigModalOpen(true)}
            />
          </styled.ProviderContainer>
          <hr />
        </>
      )}
      <H6>Available repositories</H6>
      <Spacer size="lg" />
      <styled.ProviderContainer>
        <Card
          icon={<styled.GithubIcon />}
          name="Github"
          description="Provides a managed hosting solution for version control using Git."
          isConnected={false}
          onClick={
            isConnected
              ? undefined
              : () => {
                  setField("provider", Provider.GITHUB_APP);
                  setIsConfigModalOpen(true);
                }
          }
        />
        <Card
          icon={<styled.GitlabIcon />}
          name="Gitlab"
          description="Provides a managed hosting solution for version control using Git."
          isConnected={false}
          onClick={
            isConnected
              ? undefined
              : () => {
                  setField("provider", Provider.GITLAB);
                  setIsConfigModalOpen(true);
                }
          }
        />
      </styled.ProviderContainer>
      {isConfigModalOpen && (
        <ConfigModal
          isConnected={isConnected}
          state={state}
          setField={setField}
          dispatch={dispatch}
          onClose={() => setIsConfigModalOpen(false)}
        />
      )}
    </styled.Container>
  );
}

function Card({
  icon,
  name,
  description,
  isConnected,
  onClick
}: {
  icon: React.ReactNode;
  name: string;
  description: string;
  isConnected: boolean;
  onClick?: () => void;
}) {
  const MaybeTooltip = isConnected ? Tooltip : React.Fragment;
  return (
    <styled.StyledCard>
      {icon}
      <H5>{name}</H5>
      <C2>
        <SurfaceTertiary>
          {isConnected ? <>✅ Connected</> : <>Not connected</>}
        </SurfaceTertiary>
      </C2>
      <Spacer size="sm" />
      <B3>{description}</B3>
      <Spacer size="md" />
      <MaybeTooltip
        title={
          <B3>
            <White>
              You can only connect to one repository at time. Please disconnect from
              your current repository first.
            </White>
          </B3>
        }
      >
        <ButtonNew
          block
          type={isConnected ? "secondary" : "primary"}
          disabled={!onClick}
          onClick={onClick}
        >
          {isConnected ? <>Manage settings</> : <>Connect</>}
        </ButtonNew>
      </MaybeTooltip>
    </styled.StyledCard>
  );
}

function ConfigModal({
  state,
  isConnected,
  dispatch,
  setField,
  onClose
}: {
  state: ReturnType<typeof getDefaultState>;
  isConnected: boolean;
  dispatch: React.Dispatch<Action>;
  setField: (name: Field, value: unknown) => void;
  onClose: () => void;
}) {
  const [isDisconnectModalOpen, setIsDisconnectModalOpen] = React.useState(false);
  const [confirmationText, setConfirmationText] = React.useState("");
  const [createSCM, { loading: creating }] = useCreateScmConfig({
    refetchQueries: ["authUserQuery"],
    onCompleted: data => {
      switch (data.createScmConfig.__typename) {
        case "ScmConfigNode":
          dispatch({
            type: ActionType.LOAD_SCM_CONFIG_NODE,
            payload: data.createScmConfig
          });
          Message.success("Saved");
          break;
        case "ValidationErrorResult":
          throw new Error("TODO: not implemented");
        default:
          throw new Error("Unexpected error");
      }
      onClose();
    },
    onError: error => {
      Message.error(error.toString());
    }
  });

  const [updateSCM, { loading: updating }] = useUpdateScmConfig({
    refetchQueries: ["authUserQuery"],
    onCompleted: data => {
      switch (data.updateScmConfig.__typename) {
        case "ScmConfigNode":
          dispatch({
            type: ActionType.LOAD_SCM_CONFIG_NODE,
            payload: data.updateScmConfig
          });
          Message.success("Saved");
          break;
        case "ValidationErrorResult":
          throw new Error("TODO: not implemented");
        default:
          throw new Error("Unexpected error");
      }
      onClose();
    },
    onError: error => {
      Message.error(error.toString());
    }
  });

  const [deleteSCM, { loading: deleting }] = useDeleteScmConfig({
    refetchQueries: ["authUserQuery"],
    onCompleted: _ => {
      dispatch({ type: ActionType.RESET, payload: undefined });
      Message.success("Disconnected");
      setTimeout(() => {
        window.location.reload();
      }, 100);
    },
    onError: error => {
      Message.error(error.toString());
    }
  });

  if (state.provider === Provider.GITLAB) {
    return (
      <Modal visible footer={null} onCancel={onClose}>
        <H5>Gitlab integration</H5>
        <Spacer size="xl" />
        <B2>Coming soon…</B2>
        <Spacer size="xl" />
        <styled.StyledModalActions>
          <ButtonNew type="secondary" size="lg" onClick={onClose}>
            OK
          </ButtonNew>
        </styled.StyledModalActions>
      </Modal>
    );
  }
  return (
    <>
      <Modal visible={!isDisconnectModalOpen} footer={null} onCancel={onClose}>
        <H5>{isConnected ? "Update GitHub settings" : "Connect to GitHub"}</H5>
        <styled.FormSection>
          <H6>App settings</H6>
          <label>
            App ID
            <Input
              value={state.githubAppAppId}
              onChange={e => setField("githubAppAppId", e.target.value)}
            />
          </label>
          <label>
            Private key
            <TextArea
              value={state.githubAppPrivateKey}
              onChange={e => setField("githubAppPrivateKey", e.target.value)}
            />
            {isConnected && (
              <small>
                <em>* Leave blank to continue using current private key</em>
              </small>
            )}
          </label>
          <label>
            Installation ID
            <Input
              value={state.githubAppInstallationId}
              onChange={e => setField("githubAppInstallationId", e.target.value)}
            />
          </label>
        </styled.FormSection>
        <styled.FormSection>
          <H6>Repository settings</H6>
          <label>
            Repository
            <Input
              value={state.githubAppRepo}
              onChange={e => setField("githubAppRepo", e.target.value)}
            />
          </label>
          <label>
            Branch
            <Input
              value={state.githubAppBranch}
              onChange={e => setField("githubAppBranch", e.target.value)}
            />
          </label>
          <label>
            Owner
            <Input
              value={state.githubAppOwner}
              onChange={e => setField("githubAppOwner", e.target.value)}
            />
          </label>
        </styled.FormSection>
        <styled.FormSection>
          <H6>Enterprise Settings (Only required for GHE)</H6>
          <label>
            Enterprise URL
            <Input
              value={state.githubAppEnterpriseUrl}
              onChange={e => setField("githubAppEnterpriseUrl", e.target.value)}
            />
          </label>
          <label>
            Enterprise API URL
            <Input
              value={state.githubAppEnterpriseApiUrl}
              onChange={e => setField("githubAppEnterpriseApiUrl", e.target.value)}
            />
          </label>
        </styled.FormSection>
        <styled.StyledModalActions>
          {isConnected && (
            <styled.Button
              type="destructive"
              onClick={() => setIsDisconnectModalOpen(true)}
            >
              Disconnect
            </styled.Button>
          )}
          <styled.StyledMainActions>
            <ButtonNew type="secondary" size="lg" onClick={onClose}>
              Cancel
            </ButtonNew>
            <ButtonNew
              type="brand"
              size="lg"
              loading={creating || updating}
              onClick={_ =>
                isConnected
                  ? updateSCM({ variables: toUpdateVariables(state) })
                  : createSCM({ variables: toCreateVariables(state) })
              }
            >
              {isConnected ? "Save" : "Save and connect"}
            </ButtonNew>
          </styled.StyledMainActions>
        </styled.StyledModalActions>
      </Modal>
      {isDisconnectModalOpen && (
        <Modal
          visible
          footer={null}
          height="fit-content"
          width="500px"
          onCancel={() => setIsDisconnectModalOpen(false)}
        >
          <H5>Are you sure?</H5>
          <Spacer size="md" />
          <B3>
            Your internal.io organization is connected to GitHub. Disconnecting will
            remove all credentials and version control with GitHub. This action cannot
            be undone.
          </B3>
          <Spacer size="md" />
          <B3>
            Please type <strong>GitHub</strong> to confirm.
          </B3>
          <Spacer size="sm" />
          <Input
            value={confirmationText}
            onChange={evt => setConfirmationText(evt.target.value)}
          />
          <Spacer size="xl" />
          <styled.StyledMainActions>
            <ButtonNew type="secondary" onClick={() => setIsDisconnectModalOpen(false)}>
              Cancel
            </ButtonNew>
            <ButtonNew
              type="destructive"
              disabled={confirmationText !== "GitHub"}
              loading={deleting}
              onClick={_ => deleteSCM()}
            >
              Disconnect GitHub
            </ButtonNew>
          </styled.StyledMainActions>
        </Modal>
      )}
    </>
  );
}
