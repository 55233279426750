import React from "react";

import { Form, Input } from "antd";
import { FormComponentProps } from "antd/lib/form/Form";

import { ModalNew } from "../../../../common/Modal";
import * as common from "../styledComponents";

interface Props extends FormComponentProps {
  loading: boolean;
  okText: string;
  operationFn: (branch: string) => void;
  title: string;
  children?: React.ReactNode;
  onCancel?: () => void;
  visible?: boolean;
}

const BranchOperationModal = ({
  children,
  form,
  loading,
  okText,
  operationFn,
  title,
  visible,
  onCancel = () => null
}: Props) => {
  const { getFieldDecorator, getFieldError, isFieldTouched } = form;

  const handleSubmit = React.useCallback(() => {
    form.validateFields((err, values) => {
      if (err) return;

      operationFn(values.branch);
    });
  }, [form, operationFn]);

  const onSubmit = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      handleSubmit();
    },
    [handleSubmit]
  );

  const branchError = isFieldTouched("branch") && getFieldError("branch");

  return (
    <ModalNew
      confirmLoading={loading}
      destroyOnClose
      title={title}
      visible={visible}
      okText={okText}
      onOk={handleSubmit}
      okButtonProps={{ disabled: !!branchError }}
      onCancel={onCancel}
    >
      {children}
      <common.Form onSubmit={onSubmit}>
        <Form.Item validateStatus={branchError ? "error" : ""}>
          <div>Branch name:</div>
          {getFieldDecorator("branch", {
            rules: [{ required: true, message: "Please enter a branch name" }]
          })(
            <Input
              disabled={loading}
              autoComplete="off"
              placeholder="Enter branch name"
            />
          )}
        </Form.Item>
      </common.Form>
    </ModalNew>
  );
};

const BranchOperationModalForm = Form.create<Props>({ name: "branch-operation" })(
  BranchOperationModal
);

export default BranchOperationModalForm;
