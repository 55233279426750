import gql from "graphql-tag";

import { BaseCode } from "..";
import { ReturnSchema } from "../../../../constants";
import {
  Connection,
  DataSourceNode,
  FunctionAttribute,
  FunctionAttributeNode,
  FunctionAuthorizationFlowInput,
  FunctionNode,
  FunctionNodeBasic,
  FunctionParameterInput,
  FunctionParameterNode,
  InputParameter,
  Metadata,
  RelayNode
} from "../../../../types";
import { PipelineStepType } from "../forms/pipeline/constants";
import { SupportedIntegration } from "../support";

// TODO: Use this wherever FunctionNodeBasic is used
export const FUNCTION_BASIC_FRAGMENT = gql`
  fragment FunctionBasicFragment on FunctionNode {
    id
    name
    title
  }
`;

export const FUNCTION_PARAMETER_FRAGMENT = gql`
  fragment FunctionParameterFragment on FunctionParameterNode {
    id
    name
    type
    required
  }
`;

export const FUNCTION_ATTRIBUTE_FRAGMENT = gql`
  fragment FunctionAttributeFragment on FunctionAttributeNode {
    id
    name
    sourceIndex
    sourceName
    sourceType
    sourceKey
    outboundReferenceLink {
      id
      toFunction {
        id
      }
      parameterAttributeMapping
    }
  }
`;

export const FUNCTION_AUTHORIZATION_FLOW_FRAGMENT = gql`
  fragment FunctionAuthorizationFlowFragment on FunctionAuthorizationFlowNode {
    id
    authorizationFlow {
      id
    }
    environment {
      id
    }
  }
`;

export const FUNCTION_FRAGMENT = gql`
  fragment FunctionFragment on FunctionNode {
    id
    name
    title
    isUserGenerated
    baseFunction {
      ...FunctionBasicFragment
    }
    baseFunctionParameterMapping
    metadata
    reducer
    metadataReducer
    returnSchema
    dataSource {
      id
      name
      integration
    }
    functionParameters {
      edges {
        node {
          ...FunctionParameterFragment
        }
      }
    }
    functionAttributes {
      edges {
        node {
          ...FunctionAttributeFragment
        }
      }
    }
    pipelineSteps {
      edges {
        node {
          id
          title
          slug
          type
          order
          expression
          iteratorItemName
          iteratorBinding
          cases
          pipelineFunction {
            id
            name
            title
          }
          function {
            id
            name
            title
            returnSchema
            functionParameters {
              edges {
                node {
                  ...FunctionParameterFragment
                }
              }
            }
            functionAttributes {
              edges {
                node {
                  ...FunctionAttributeFragment
                }
              }
            }
            dataSource {
              id
              name
              slug
              integration
              integrationTitle
            }
          }
          inputParameters {
            name
            type
            componentProperties
            componentType
            template
            binding
            resolver
            value
          }
        }
      }
    }
  }
  ${FUNCTION_BASIC_FRAGMENT}
  ${FUNCTION_PARAMETER_FRAGMENT}
  ${FUNCTION_ATTRIBUTE_FRAGMENT}
`;

export interface FunctionAuthorizationFlowNode extends RelayNode {
  authorizationFlow: RelayNode;
  environment: RelayNode;
}

interface FunctionFragmentDataSourceNode extends RelayNode {
  name: string;
  integration: SupportedIntegration;
}

export interface FunctionFragmentNode<M extends Metadata = Metadata> extends RelayNode {
  name: string;
  title: string;
  isUserGenerated: boolean;
  baseFunction: FunctionNodeBasic;
  baseFunctionParameterMapping: string;
  dataSource: FunctionFragmentDataSourceNode;
  metadata: M;
  reducer: string;
  metadataReducer: string;
  returnSchema: ReturnSchema;
  functionParameters: Connection<FunctionParameterNode>;
  functionAttributes: Connection<FunctionAttributeNode>;
  pipelineSteps: Connection<PipelineStepNode>;
}

export interface FetchFunctionByIdData<F extends FunctionFragmentNode> {
  node: F;
}

export interface FetchFunctionByIdVars {
  id: string;
}

export interface EditorFunctionNode<M> extends FunctionFragmentNode<M> {
  environmentsWithCredentials: Connection<RelayNode>;
  functionAuthorizationFlows: Connection<FunctionAuthorizationFlowNode>;
}

export const EDITOR_FUNCTION_FRAGMENT = gql`
  fragment EditorFunctionFragment on FunctionNode {
    ...FunctionFragment
    ... on FunctionNode {
      environmentsWithCredentials {
        edges {
          node {
            id
          }
        }
      }
      functionAuthorizationFlows {
        edges {
          node {
            ...FunctionAuthorizationFlowFragment
          }
        }
      }
    }
  }
  ${FUNCTION_FRAGMENT}
  ${FUNCTION_AUTHORIZATION_FLOW_FRAGMENT}
`;

export const FETCH_FUNCTION_BY_ID = gql`
  query FetchFunctionById($id: ID!) {
    node(id: $id) {
      ...EditorFunctionFragment
    }
  }
  ${EDITOR_FUNCTION_FRAGMENT}
`;

export interface ConditionParts {
  lhs: string;
  operator: string;
  rhs: string;
}

export interface ApiCondition {
  condition?: string;
  parts?: ConditionParts[];
  conjunctions?: string[];
  function_id: string | null;
  input_parameters?: InputParameter[];
  action_type: string;
}

export enum ConditionActionType {
  PENDING = "PENDING",
  FUNCTION = "FUNCTION",
  NOOP = "NOOP"
}
export interface ConditionalInput {
  condition?: string;
  parts?: ConditionParts[];
  conjunctions?: string[];
  functionId?: string;
  inputParameters?: InputParameter[];
  actionType: ConditionActionType;
}

export interface PipelineStepInput {
  id?: string;
  title: string;
  slug: string;
  type: PipelineStepType;
  inputParameters: InputParameter[];
  functionId?: string;
  expression?: string;
  iteratorBinding?: string;
  iteratorItemName?: string;
  cases?: ConditionalInput[];
}

export interface PipelineStepNode extends RelayNode {
  title: string;
  slug: string;
  type?: PipelineStepType;
  order: number;
  pipelineFunction?: FunctionNode;
  dataSource?: DataSourceNode;
  function?: FunctionNode;
  cases?: string; // JSON
  iteratorBinding?: string;
  iteratorItemName?: string;
  inputParameters: InputParameter[];
}

export interface Conditional {
  condition?: string;
  parts?: ConditionParts[];
  conjunctions?: string[];
  functionId?: string; // Locally stored as the function uuid, not graphql id
  function?: FunctionNode; // Loaded locally for additional data
  actionType: ConditionActionType;
  inputParameters: InputParameter[];
}

export interface PipelineStep {
  id?: string;
  title: string;
  slug: string;
  type?: PipelineStepType;
  order: number;
  pipelineFunction?: FunctionNode;
  // Only when function is selected. You select a data source first, then function.
  dataSource?: DataSourceNode;
  function?: FunctionNode;
  expression?: string; // js expression, only when type === EXPRESSION
  conditions?: Conditional[]; // only when type === CONDITIONAL
  iteratorBinding?: string; // only when type === LOOP. The step slug that we are binding data to.
  iteratorItemName?: string; // only when type === LOOP.
  inputParameters: InputParameter[];
  stopOnError: boolean;
  returnsData: boolean;
}

export interface PipelineParameterInput extends FunctionParameterInput {
  id: string; // Local only, not used in API.
  stepNumber: number;
  stepSlug: string;
  prefix: string; // identifies where the parameter came from
  pipelineName: string; // Name unique among all step parameters
}

export interface WriteFunctionVariables<M = BaseCode> {
  baseFunctionParameterMapping: any;
  metadata: Metadata<M>;
  title: string;
  reducer?: string;
  metadataReducer?: string;
  parameters: FunctionParameterInput[];
  // TODO: Make this required when old function editors are removed.
  attributes?: FunctionAttribute[];
  returnSchema: ReturnSchema;
  authorizationFlows: FunctionAuthorizationFlowInput[];
  pipelineSteps?: PipelineStepInput[];
  isTemporary?: boolean;
}

export interface CreateFunctionData {
  createFunction?: {
    function: EditorFunctionNode<Metadata>;
  };
}

export interface CreateFunctionVariables<M = BaseCode>
  extends WriteFunctionVariables<M> {
  baseFunctionId: string;
}

export const CREATE_FUNCTION = gql`
  mutation CreateFunction(
    $baseFunctionId: ID!
    $baseFunctionParameterMapping: GenericScalar!
    $metadata: GenericScalar!
    $title: String!
    $reducer: String
    $metadataReducer: String
    $parameters: [FunctionParameterInput]!
    $attributes: [FunctionAttributeInput]
    $returnSchema: ReturnSchema!
    $authorizationFlows: [FunctionAuthorizationFlowInput]!
    $pipelineSteps: [PipelineStepInput]
    $isTemporary: Boolean
  ) {
    createFunction(
      baseFunctionId: $baseFunctionId
      baseFunctionParameterMapping: $baseFunctionParameterMapping
      metadata: $metadata
      title: $title
      reducer: $reducer
      metadataReducer: $metadataReducer
      parameters: $parameters
      attributes: $attributes
      returnSchema: $returnSchema
      authorizationFlows: $authorizationFlows
      pipelineSteps: $pipelineSteps
      isTemporary: $isTemporary
    ) {
      function {
        ...EditorFunctionFragment
      }
    }
  }
  ${EDITOR_FUNCTION_FRAGMENT}
`;

export interface CloneFunctionData {
  cloneFunction: {
    function: {
      id: string;
      name: string;
    };
  };
}

export interface CloneFunctionVariables {
  functionId: string;
}

export const CLONE_FUNCTION = gql`
  mutation CloneFunction($functionId: ID!) {
    cloneFunction(id: $functionId) {
      function {
        id
        name
      }
    }
  }
`;

export interface UpdateFunctionData {
  updateFunction?: {
    function: EditorFunctionNode<Metadata>;
  };
}

export interface UpdateFunctionVariables<M = BaseCode>
  extends WriteFunctionVariables<M> {
  functionId: string;
}

export const UPDATE_FUNCTION = gql`
  mutation UpdateFunction(
    $functionId: ID!
    $baseFunctionParameterMapping: GenericScalar!
    $metadata: GenericScalar!
    $title: String!
    $reducer: String
    $metadataReducer: String
    $parameters: [FunctionParameterInput]!
    $attributes: [FunctionAttributeInput]
    $returnSchema: ReturnSchema!
    $authorizationFlows: [FunctionAuthorizationFlowInput]!
    $pipelineSteps: [PipelineStepInput]
  ) {
    updateFunction(
      functionId: $functionId
      baseFunctionParameterMapping: $baseFunctionParameterMapping
      metadata: $metadata
      title: $title
      reducer: $reducer
      metadataReducer: $metadataReducer
      parameters: $parameters
      attributes: $attributes
      returnSchema: $returnSchema
      authorizationFlows: $authorizationFlows
      pipelineSteps: $pipelineSteps
    ) {
      function {
        ...EditorFunctionFragment
      }
    }
  }
  ${EDITOR_FUNCTION_FRAGMENT}
`;

export interface DeleteFunctionData {
  deleteFunction?: {
    ok: boolean;
  };
}

export interface DeleteFunctionVariables {
  functionId: string;
}

export const DELETE_FUNCTION = gql`
  mutation DeleteFunction($functionId: ID!) {
    deleteFunction(functionId: $functionId) {
      ok
    }
  }
`;
