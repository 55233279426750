import React from "react";

import styled from "styled-components";

import { useStableSpaceContext } from "../../SpaceContext";
import Panel from "../common/Panel";
import useSignedUrl from "../common/useSignedUrl";
import { useComponentStateContext } from "../contexts/ComponentStateContext";
import { Props } from "../SpaceComponent";

import { SpaceVideoComponent } from "./types";

const Root = styled(Panel)<{ editMode: boolean }>`
  background-color: transparent;

  ${props => !props.editMode && "border: none;"}
`;

export default function SpaceVideo({ spaceComponent, hasConfigError }: Props) {
  const videoComponent = spaceComponent as SpaceVideoComponent;
  const { input } = useComponentStateContext();
  const { editMode } = useStableSpaceContext();
  const { url } = useSignedUrl(spaceComponent, input, true);
  const videoRef = React.useRef<HTMLVideoElement | null>(null);
  const sourceRef = React.useRef<HTMLSourceElement | null>(null);

  const { title } = videoComponent.properties;

  // Don't load videos in edit mode because re-rendering will
  // create multiple requests to download
  const src = editMode ? "" : url;

  React.useEffect(() => {
    if (editMode || !videoRef.current || !sourceRef.current) return;

    sourceRef.current.src = src;
    videoRef.current.load();
  }, [editMode, src]);

  return (
    <Root title={title} hasError={hasConfigError} editMode={editMode}>
      <video ref={videoRef} controls height="auto" width="100%">
        <source ref={sourceRef} />
      </video>
    </Root>
  );
}
