import gql from "graphql-tag";

import useAsyncMutation, {
  AsyncMutationHookOptions
} from "../../../hooks/useAsyncMutation";

interface Data {
  syncSpaceScm: {
    taskId: string;
  };
}

interface Variables {
  spaceId: string;
  branch: string;
}

const QUERY = gql`
  mutation SyncSpaceScm($spaceId: ID!, $branch: String!) {
    syncSpaceScm(spaceId: $spaceId, branch: $branch) {
      taskId
    }
  }
`;

export default function useSyncSpaceScm(
  options?: AsyncMutationHookOptions<Data, Variables>
) {
  return useAsyncMutation<Data, Variables>(QUERY, d => d.syncSpaceScm.taskId, options);
}
