import { useCallback, useReducer, useState } from "react";

import { ApolloError } from "apollo-client";
import pluralize from "pluralize";
import { useMutation } from "react-apollo";

import { BaseFunctionParameterMapping, PreviewResult } from "..";
import { ReturnSchema } from "../../../../constants";
import {
  BaseFunctionName,
  ClientErrorInfo,
  FunctionAuthorizationFlowInput,
  FunctionParameterInput,
  Metadata
} from "../../../../types";
import { tryError } from "../../../util";
import { assertNever } from "../../../util/assertNever";
import { fromGlobalId } from "../../../util/graphql";
import executeGatewayFunction, { encodeParameters } from "../../executeGatewayFunction";
import { SupportedIntegration } from "../support";
import { parseIdentifiers } from "../useFunctionEditor/parsers";
import {
  CreateFunctionData,
  CreateFunctionVariables,
  CREATE_FUNCTION,
  PipelineStep
} from "../useFunctionEditor/queries";

import {
  HttpTransformExceptions,
  HttpTransformResults,
  PreviewResponse,
  PreviewVariables,
  PREVIEW_FUNCTION_MUTATION
} from "./queries";
import { getInitialState, reducer as functionExecuteReducer } from "./reducer";
import {
  fillDefaults,
  isValidListOutput,
  readAttributes,
  readReturnSchema,
  serialize,
  toPipelineStepInput,
  validatePipelineSteps
} from "./util";

const GENERAL_ERROR = "An error occurred while running preview.";

interface ExecuteFunctionData<M> {
  baseFunctionId: string;
  baseFunctionName: BaseFunctionName;
  environmentId: string;
  integration: SupportedIntegration;
  reducer: string;
  metadata: Metadata<M>;
  parameterValues: any;
  // TODO: remove `string` type
  baseFunctionParameterMapping: BaseFunctionParameterMapping | string;
  metadataReducer: string;
  pipelineSteps?: PipelineStep[];
}

interface Props {
  authorizationFlows: FunctionAuthorizationFlowInput[];
  parameters: FunctionParameterInput[];
  onPreviewResult: (result: PreviewResult) => void;
}

export default function useFunctionPreviewExecuter<M>({
  authorizationFlows,
  parameters,
  onPreviewResult = () => null
}: Props) {
  const [state, dispatch] = useReducer(functionExecuteReducer, getInitialState());
  const [isPipelinePreviewLoading, setPipelinePreviewLoading] = useState(false);

  const [previewFunction, { loading }] = useMutation<
    PreviewResponse<HttpTransformResults, HttpTransformExceptions>,
    PreviewVariables<M>
  >(PREVIEW_FUNCTION_MUTATION);

  const [createFunctionMutation] = useMutation<
    CreateFunctionData,
    CreateFunctionVariables<M>
  >(CREATE_FUNCTION, {
    onError: (e: ApolloError) => {
      throw new Error(`${e.message.replace("GraphQL error:", "")}`);
    }
  });

  const previewPipeline = useCallback(
    async (variables: PreviewVariables<M>) => {
      const createFunctionResult = await createFunctionMutation({
        variables: {
          title: `functionPreview${Date.now()}`,
          isTemporary: true,
          baseFunctionId: variables.functionId,
          baseFunctionParameterMapping: variables.parameterMapping,
          reducer: variables.reducer.transformedData,
          metadataReducer: variables.reducer.transformedMetadata,
          parameters: variables.parameters.map(p => ({
            name: p.name,
            type: p.type,
            required: p.required
          })),
          returnSchema: ReturnSchema.UNKNOWN,
          authorizationFlows: [],
          pipelineSteps: variables.pipelineSteps,
          metadata: variables.metadata || { categories: ["mutation"] }
        }
      });
      const previewFunction = createFunctionResult.data?.createFunction?.function;
      if (previewFunction) {
        const encodedValues = encodeParameters(variables.values, variables.parameters);
        return executeGatewayFunction(
          fromGlobalId(previewFunction.id)[1],
          fromGlobalId(variables.environmentId)[1],
          undefined,
          encodedValues
        );
      }
    },
    [createFunctionMutation]
  );

  const onPreviewError = useCallback(
    (message: string, errorInfo?: ClientErrorInfo) => {
      dispatch({ type: "SET_ERROR", payload: { message, errorInfo } });
    },
    [dispatch]
  );

  const onPreviewResponse = useCallback(
    (results: HttpTransformResults, exceptions: HttpTransformExceptions) => {
      const isValidResponse = isValidListOutput(results.transformedData);
      const returnSchema = readReturnSchema(results.transformedData);
      dispatch({
        type: "SET_RESPONSE",
        payload: { results, exceptions, returnSchema, isValidResponse }
      });

      if (isValidResponse) {
        onPreviewResult({
          returnSchema: readReturnSchema(results.transformedData),
          attributes: readAttributes(results.transformedData)
        });
      }
    },
    [dispatch, onPreviewResult]
  );

  const execute = useCallback(
    async (data: ExecuteFunctionData<M>) => {
      dispatch({ type: "RESET" });

      const {
        baseFunctionId,
        baseFunctionName,
        baseFunctionParameterMapping,
        environmentId,
        integration,
        metadata,
        metadataReducer,
        parameterValues,
        reducer,
        pipelineSteps
      } = data;

      const [_, error] = parseIdentifiers(
        integration,
        baseFunctionName,
        baseFunctionParameterMapping,
        metadata
      );
      if (error) {
        return onPreviewError(error.message);
      }

      const serializedValues = serialize(fillDefaults(parameterValues));
      const previewParameters = parameters.filter(p => p.name in serializedValues);

      const authorizationFlowId = authorizationFlows.find(
        flow => flow.environmentId === environmentId
      )?.authorizationFlowId;

      const variables: PreviewVariables<M> = {
        functionId: baseFunctionId,
        environmentId: environmentId!,
        parameterMapping: baseFunctionParameterMapping,
        parameters: previewParameters,
        reducer: {
          metadata: "metadata",
          data: "data",
          transformedData: reducer,
          transformedMetadata: metadataReducer
        },
        values: serializedValues,
        authorizationFlowId
      };

      const isPipeline = integration === "pipelines";
      if (isPipeline) {
        const errors = validatePipelineSteps(pipelineSteps);
        if (!errors.length) {
          variables.pipelineSteps = pipelineSteps!.map(toPipelineStepInput);
          variables.metadata = metadata;
        } else {
          return onPreviewError(
            `Could not execute preview due to the following ${pluralize(
              "error",
              errors.length
            )}: ${errors.join(", ")}.`
          );
        }
        try {
          setPipelinePreviewLoading(true);
          const response = await previewPipeline(variables);
          setPipelinePreviewLoading(false);
          return onPreviewResponse(
            {
              transformedData: response
            },
            {} // TODO exceptions should be rendered as errors until they are caught and handled
          );
        } catch (err) {
          setPipelinePreviewLoading(false);
          return onPreviewError(tryError(err).message);
        }
      } else {
        try {
          const result = await previewFunction({ variables });
          const response = result.data?.previewFunction;

          if (!response?.__typename) {
            return onPreviewError(GENERAL_ERROR);
          }

          switch (response.__typename) {
            case "PreviewFunctionResultSuccess":
              return onPreviewResponse(response.results, response.exceptions);
            case "PermissionErrorResult":
              return onPreviewError(response.message);
            case "ClientErrorResult":
              return onPreviewError(response.message, response.errorInfo);
            default:
              return assertNever(response);
          }
        } catch (err) {
          const e = err as ApolloError;
          const message = e.graphQLErrors?.length
            ? e.graphQLErrors[0].message
            : GENERAL_ERROR;
          onPreviewError(message);
        }
      }
    },
    [
      authorizationFlows,
      parameters,
      onPreviewError,
      onPreviewResponse,
      previewFunction,
      previewPipeline
    ]
  );

  return {
    state,
    loading: loading || isPipelinePreviewLoading,
    execute
  };
}
