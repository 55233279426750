import React from "react";

import { HotKeys } from "react-hotkeys";
import styled from "styled-components";

import { colorTokens } from "../../../../cssConstants";
import { KEY_MAP, useHotKeyHandlers } from "../../hotKeys";
import { useStableSpaceContext } from "../../SpaceRoot/SpaceContext";
import { useCanvasViewportContext } from "../Canvas/CanvasViewportContext";
import { RELATIVE_GRID_SIZE } from "../constants";
import { addPoints } from "../util";

const GRID_DOT_SIZE = 2;
const GRID_COLOR = colorTokens.white;
const GRID_DOT_COLOR = colorTokens.grey700;

const GridRoot = styled(HotKeys)`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const GridContext = React.createContext({
  findClosestGridPoint: (_point: DOMPoint) => new DOMPoint(),
  snapRectToGrid: (_rect: DOMRect) => new DOMRect(),
  gridUnit: 0,
  gridReady: false
});

export default GridContext;

export const useGridContext = () => React.useContext(GridContext);

export function Grid({ children }: { children: React.ReactNode }) {
  const { editMode } = useStableSpaceContext();
  const canvasRef = React.useRef<HTMLCanvasElement | null>(null);
  const { viewportRect } = useCanvasViewportContext();
  const [gridImage, setGridImage] = React.useState("");
  // Grid unit must be not be fractional and must be accurate. If it is fractional the grid guides will not align with the
  // real grid as the canvas used to draw the guides is in whole pixels when snap shotted for the background image.
  const gridUnit = React.useMemo(() => {
    return viewportRect.width * RELATIVE_GRID_SIZE;
  }, [viewportRect.width]);

  React.useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas === null) return;
    const ctx = canvas.getContext("2d");
    if (ctx === null) return;
    ctx.fillStyle = GRID_COLOR;
    ctx.fillRect(0, 0, gridUnit, gridUnit);
    ctx.fillStyle = GRID_DOT_COLOR;
    ctx.fillRect(0, 0, GRID_DOT_SIZE / 2, GRID_DOT_SIZE / 2);
    ctx.fillRect(0, gridUnit - GRID_DOT_SIZE / 2, GRID_DOT_SIZE / 2, GRID_DOT_SIZE / 2);
    ctx.fillRect(
      gridUnit - GRID_DOT_SIZE / 2,
      gridUnit - GRID_DOT_SIZE / 2,
      GRID_DOT_SIZE / 2,
      GRID_DOT_SIZE / 2
    );
    ctx.fillRect(gridUnit - GRID_DOT_SIZE / 2, 0, GRID_DOT_SIZE / 2, GRID_DOT_SIZE / 2);
    setGridImage(canvas.toDataURL());
  }, [gridUnit, setGridImage]);

  const findClosestGridPoint = React.useCallback(
    function findClosestGridPoint(point: DOMPoint) {
      if (gridUnit === 0) return point;
      const gridXOffset = point.x % gridUnit;
      const gridXAdjustment =
        gridXOffset > gridUnit / 2 ? gridUnit - gridXOffset : -1 * gridXOffset;

      const gridYOffset = point.y % gridUnit;
      const gridYAdjustment =
        gridYOffset > gridUnit / 2 ? gridUnit - gridYOffset : -1 * gridYOffset;

      const adjustmentPoint = new DOMPoint(gridXAdjustment, gridYAdjustment);
      return addPoints(point, adjustmentPoint);
    },
    [gridUnit]
  );

  const snapRectToGrid = React.useCallback(
    function snapRectToGrid(rect: DOMRect) {
      const origin = findClosestGridPoint(new DOMPoint(rect.x, rect.y));
      const extent = findClosestGridPoint(
        new DOMPoint(origin.x + rect.width, origin.y + rect.height)
      );
      return new DOMRect(origin.x, origin.y, extent.x - origin.x, extent.y - origin.y);
    },
    [findClosestGridPoint]
  );

  const value = React.useMemo(() => {
    return {
      findClosestGridPoint,
      snapRectToGrid,
      gridUnit,
      gridReady: gridUnit > 0
    };
  }, [findClosestGridPoint, snapRectToGrid, gridUnit]);

  const hotKeyHandlers = useHotKeyHandlers();
  const hotKeyProps = {
    keyMap: KEY_MAP,
    handlers: hotKeyHandlers,
    attach: window,
    focused: true
  };

  if (!editMode) return <GridRoot {...hotKeyProps}>{children}</GridRoot>;

  return (
    <GridContext.Provider value={value}>
      <GridRoot
        data-key="grid-root"
        {...hotKeyProps}
        style={{
          backgroundImage: `url(${gridImage})`,
          userSelect: "none"
        }}
      >
        <canvas
          ref={canvasRef}
          data-test="canvas"
          style={{ position: "absolute", left: "-1000px" }}
          width={gridUnit}
          height={gridUnit}
        />
        {children}
      </GridRoot>
    </GridContext.Provider>
  );
}
