import React from "react";

import { Button, Icon, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { GlobalStyleVariables } from "../../../../../cssConstants";
import DarkThemeModal from "../../../../common/DarkThemeModal";
import useAuthUser from "../../../../common/hooks/useAuthUser";
import usePaths from "../../../../common/hooks/usePaths";
import Message from "../../../../common/Message";
import copyToClipboard from "../../../../util/copyToClipboard";
import { fromGlobalId } from "../../../../util/graphql";
import { useStableSpaceContext } from "../../../SpaceRoot/SpaceContext";
import { useBranchContext } from "../../../SpaceRoot/SpaceContext/BranchContext";
import { useSpaceConfigContext } from "../../SpaceConfigContext";
import { MutationType } from "../../SpaceConfigContext/useSpaceConfig";
import VersionHistoryDrawer from "../../VersionHistoryDrawer";
import HeaderPanel, { HeaderPanelMenu, HeaderPanelMenuButton } from "../HeaderPanel";
import ImportModal from "../ImportModal";
import UnpublishModal from "../UnpublishModal";

export const SaveLinkButton = styled(Button)`
  &.ant-btn {
    padding: 0;
    color: ${props => props.theme.inputColor};
    line-height: 30px;
    height: 30px;

    &:hover,
    &:focus,
    &:active {
      color: ${props => props.theme.inputColor};
    }
  }
`;

interface ExportTooltipProps {
  disabled?: boolean;
  children: React.ReactNode;
}

const ExportTooltip = ({ disabled, children }: ExportTooltipProps) =>
  disabled ? (
    <>{children}</>
  ) : (
    <Tooltip title="Space must be saved before exporting" placement="bottomRight">
      {children}
    </Tooltip>
  );

export default function MoreOptions() {
  const navigate = useNavigate();
  const {
    getDashboard,
    getEditSpace,
    getEncodedHashParam,
    getSpaceExportPath,
    updateHashParams
  } = usePaths();
  const { branch } = useBranchContext();
  const { authUser } = useAuthUser();

  const [visible, setVisible] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [showImportModal, setShowImportModal] = React.useState(false);
  const [showUnpublishModal, setShowUnpublishModal] = React.useState(false);

  const {
    destroy,
    mutationLoading,
    state: { dirty, spaces }
  } = useSpaceConfigContext();
  const { spaceId, spaceSlug } = useStableSpaceContext();

  const space = spaces.get(spaceSlug);
  const showVersionHistoryDrawer = getEncodedHashParam("drawer") === "version-history";
  const organization = authUser?.organization;

  const exportSpaceLink =
    space && space.versionId
      ? getSpaceExportPath(spaceSlug, fromGlobalId(space.versionId)[1])
      : undefined;

  return (
    <>
      <SaveLinkButton onClick={() => setVisible(true)} type="link" data-test="moreMenu">
        <Icon type="ellipsis" />
      </SaveLinkButton>
      {visible && (
        <HeaderPanel maskClosable onCancel={() => setVisible(false)}>
          <HeaderPanelMenu>
            <HeaderPanelMenuButton
              onClick={() => {
                if (!spaceId) {
                  navigate(getDashboard());
                  return;
                }
                setVisible(false);
                setShowDeleteModal(true);
              }}
            >
              Delete
            </HeaderPanelMenuButton>
            <HeaderPanelMenuButton
              onClick={() => {
                setVisible(false);
                setShowUnpublishModal(true);
              }}
            >
              Unpublish
            </HeaderPanelMenuButton>
            {!!spaceId && organization?.licenseData?.versionHistory && (
              <HeaderPanelMenuButton
                onClick={() => {
                  setVisible(false);
                  const nextPath = updateHashParams({
                    drawer: "version-history"
                  });
                  navigate(nextPath);
                }}
              >
                Version History
              </HeaderPanelMenuButton>
            )}
            <HeaderPanelMenuButton
              onClick={() => {
                setVisible(false);
                setShowImportModal(true);
              }}
            >
              Import version
            </HeaderPanelMenuButton>
            <ExportTooltip disabled={!dirty}>
              <HeaderPanelMenuButton
                disabled={dirty || !exportSpaceLink}
                download={`${spaceSlug}.json`}
                href={exportSpaceLink || ""}
              >
                Export as JSON
              </HeaderPanelMenuButton>
            </ExportTooltip>
            {process.env.INTERNALIO_ONPREM === "1" && (
              <HeaderPanelMenuButton
                onClick={() => {
                  copyToClipboard(
                    JSON.stringify({
                      space,
                      errors: window.__ERRORS.map(errObj => {
                        const err: { [key: string]: any } = {};
                        Object.getOwnPropertyNames(errObj).forEach(function (propName) {
                          err[propName] = (
                            errObj as {
                              [index: string]: any;
                            }
                          )[propName];
                        });
                        return err;
                      })
                    })
                  );
                  setVisible(false);
                }}
              >
                Copy Debug Data
              </HeaderPanelMenuButton>
            )}
          </HeaderPanelMenu>
        </HeaderPanel>
      )}
      {showUnpublishModal && (
        <UnpublishModal
          modalVisible={showUnpublishModal}
          onClose={() => setShowUnpublishModal(false)}
        />
      )}
      {showDeleteModal && (
        <DarkThemeModal
          visible={showDeleteModal}
          title="Are you sure?"
          onCancel={() => setShowDeleteModal(false)}
          width={GlobalStyleVariables.confirmModal}
          content="This space and all of its configuration will be permanently deleted."
          okText="Delete"
          onOk={destroy}
          okButtonProps={{
            loading: mutationLoading === MutationType.DESTROY
          }}
        />
      )}
      {showImportModal && (
        <ImportModal
          visible={showImportModal}
          spaceId={spaceId || undefined}
          onImportSuccess={space => {
            setShowImportModal(false);
            if (spaceId) {
              Message.success("Import successful. Reloading...");
              setTimeout(() => {
                window.location.reload();
              }, 200);
            } else {
              Message.success("Import successful.");
              navigate(getEditSpace(space.slug, { branch }));
            }
          }}
          onImportError={_error => {
            setShowImportModal(false);
            Message.error(
              "An error occurred while importing your space. Please contact support."
            );
          }}
          onCancel={() => setShowImportModal(false)}
        />
      )}
      {showVersionHistoryDrawer && (
        <VersionHistoryDrawer
          onClose={() => {
            const nextPath = updateHashParams({ drawer: undefined });
            navigate(nextPath);
          }}
        />
      )}
    </>
  );
}
