import React from "react";

import { useNavigate } from "react-router";

import BackgroundBits from "../../../../assets/analytics_background_bits.svg";

import {
  BackgroundContainer,
  Banner,
  Body,
  BottomGradient,
  Title,
  UpgradeButton
} from "./styledComponents";

export default function UpsellBanner() {
  const navigate = useNavigate();

  return (
    <Banner>
      <Title>Upgrade to unlock premium analytics</Title>
      <Body>
        Upgrade to Enterprise to view historical data including average time to close,
        time in state, and activity by action.
      </Body>
      <UpgradeButton onClick={() => navigate("/settings/change-plan")} type="brand">
        Upgrade to Enterprise
      </UpgradeButton>
      <BottomGradient />
      <BackgroundContainer>
        <BackgroundBits />
      </BackgroundContainer>
    </Banner>
  );
}
