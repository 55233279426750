import styled from "styled-components";

import { colorTokens } from "../../../../../cssConstants";
import { C2, H6 } from "../../../StyledComponents";

export const Container = styled.div`
  padding-left: 18px;
`;

export const PipelineParametersContent = styled.div`
  padding-top: 12px;
  display: grid;
  grid-template-columns: minmax(max-content, 80px) minmax(max-content, 100px) 200px minmax(
      max-content,
      200px
    ) min-content;
  grid-column-gap: ${props => props.theme.spacersm};
  grid-row-gap: ${props => props.theme.spacersm};
  align-items: baseline;
`;

// This is copied from the Reszier and Resizer.horizontal styling from
// src/components/common/ThemeContainer/styledComponents.ts
export const Hr = styled.div`
  background: rgba(228, 233, 242, 0.15);
  opacity: 1;
  z-index: 1;
  box-sizing: border-box;
  background-clip: padding-box;

  min-height: 9px;
  height: 9px;
  margin: -4px 0;
  border-top: 4px solid rgba(255, 255, 255, 0);
  border-bottom: 4px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
`;

export const SectionHeader = styled(H6)`
  margin-top: 18px;
  color: ${colorTokens.white};
`;

export const FieldColumnHeader = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: ${colorTokens.grey765};
`;

export const ErrorText = styled(C2)`
  margin-top: 4px;
  color: ${props => props.theme.errorColor};
`;
