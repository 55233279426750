import aesjs from "aes-js";
import * as encoding from "text-encoding";

import EmptyImage from "./assets/empty_search_icon.png";
import GoogleLogoPng from "./assets/g-logo.png";
import SpreadsheetIcon from "./assets/spreadsheet_icon.png";
import { reportException } from "./components/util/exceptionReporting";
import {
  Cursor,
  CursorFilter,
  RecordKey,
  ViewFilterOperator,
  CursorType,
  ResourceQueryDescriptor
} from "./types";

export enum ReturnSchema {
  OBJECT = "OBJECT",
  OBJECT_ARRAY = "OBJECT_ARRAY",
  UNKNOWN = "UNKNOWN"
}

export const ReturnSchemaDisplayNames: Record<ReturnSchema, string> = {
  [ReturnSchema.OBJECT]: "Single Object",
  [ReturnSchema.OBJECT_ARRAY]: "Array of Objects",
  [ReturnSchema.UNKNOWN]: "Unknown"
};

export enum AttributeTypes {
  BINARY = "BINARY",
  BOOL = "BOOL",
  DATE = "DATE",
  DATETIME = "DATETIME",
  DECIMAL = "DECIMAL",
  FILE = "FILE",
  FLOAT = "FLOAT",
  INT = "INT",
  JSON = "JSON",
  TIME = "TIME",
  STRING = "STRING",
  TIMESTAMP = "TIMESTAMP"
}

export const AttributeTypesDisplayNames: Record<AttributeTypes, string> = {
  [AttributeTypes.BINARY]: "Binary",
  [AttributeTypes.BOOL]: "Boolean",
  [AttributeTypes.DATE]: "Date",
  [AttributeTypes.DATETIME]: "Date/Time",
  [AttributeTypes.TIMESTAMP]: "Date/Time (w/ timezone)",
  [AttributeTypes.DECIMAL]: "Decimal",
  [AttributeTypes.FILE]: "File Object",
  [AttributeTypes.FLOAT]: "Float",
  [AttributeTypes.INT]: "Integer",
  [AttributeTypes.JSON]: "JSON",
  [AttributeTypes.TIME]: "Time",
  [AttributeTypes.STRING]: "Plain Text"
};

export const TIME_DISPLAY_FORMAT = "h:mm:ss A"; // [1-12]:[0-59]:[0-59] [AM/PM]
export const TIME_SAVE_FORMAT = "HH:mm:ss"; // [00-23]:[00-59]:[00-59] (ISO 8601)

export const DATE_DISPLAY_FORMAT = "ll"; // Sep 4, 1986
export const DATE_SAVE_FORMAT = "YYYY-MM-DD"; // [0001-9999]-[01-12]-[01-31] (ISO 8601)

export const DATE_TIME_DISPLAY_FORMAT = "MM/DD/YYYY hh:mm:ss A";
export const DATE_TIME_SAVE_FORMAT = "YYYY-MM-DD HH:mm:ss";

export const TIMESTAMP_DISPLAY_FORMAT = "MM/DD/YYYY hh:mm:ss A zz";
export const TIMESTAMP_SAVE_FORMAT = "YYYY-MM-DD HH:mm:ssZ";

export class ErrorValue {
  message: string;

  constructor(message: string) {
    this.message = message;
  }

  toJSON() {
    return Symbol.for(this.message);
  }

  valueOf() {
    return Symbol.for(this.message);
  }

  toString() {
    return this.message;
  }
}

export const ErrorValues = {
  permissionDenied: new ErrorValue("no access")
};

const BLANK_VALUES: [null, void, "", ErrorValue] = [
  null,
  undefined,
  "",
  ErrorValues.permissionDenied
];

export const isBlankValue = (value: any, includeEmptyArray?: boolean) =>
  BLANK_VALUES.includes(value) ||
  (includeEmptyArray && Array.isArray(value) && !value.length);

export const recordKeyFromFilters = (filters: CursorFilter[]): RecordKey => {
  return filters.reduce((key: RecordKey, f) => {
    if (f.operator !== "=") {
      throw new Error(
        `cannot convert filter with operator ${f.operator} to record key`
      );
    }

    key[f.attribute] = f.value;
    return key;
  }, {});
};

export const encode = (input: string) => {
  try {
    const hex = Buffer.from(window.INTERNAL_CLIENT_SECRET, "hex");
    const aesCtr = new aesjs.ModeOfOperation.ctr(hex);
    const encoded = new encoding.TextEncoder("utf-8").encode(input);
    const encrypted = aesCtr.encrypt(encoded);
    const encryptedString = String.fromCharCode(...Array.from(encrypted));
    return btoa(encryptedString);
  } catch (e) {
    reportException(e);
    return "";
  }
};

export const decode = <T>(encoded: string): T => {
  const hex = Buffer.from(window.INTERNAL_CLIENT_SECRET, "hex");
  const aesCtr = new aesjs.ModeOfOperation.ctr(hex);
  const encryptedString = atob(encoded);
  const encrypted = Buffer.from(encryptedString, "ascii");
  const decrypted = aesCtr.decrypt(encrypted);
  return JSON.parse(new encoding.TextDecoder("utf-8").decode(decrypted));
};

export const decodeCursor = (encoded: string): Cursor => {
  return decode<Cursor>(encoded);
};

export const encodeCursor = (cursor: Cursor): string => {
  return encode(JSON.stringify(cursor));
};

export const createResourceCursor = (slug: string, filters: CursorFilter[]) => {
  return encodeCursor({ slug, filters, type: CursorType.RESOURCE });
};

export const createCursorFilters = (recordKey: RecordKey = {}): CursorFilter[] =>
  Object.keys(recordKey).map(k => {
    return {
      attribute: k,
      operator: ViewFilterOperator.EQUALS,
      value: recordKey[k]
    };
  });

export const encodeResourceQueryDescriptor = (
  slug: string,
  filters: CursorFilter[]
) => {
  return encode(JSON.stringify({ slug, filters }));
};

export const decodeResourceQueryDescriptor = (
  encoded: string
): ResourceQueryDescriptor => {
  return decode<ResourceQueryDescriptor>(encoded);
};

export const Routes = {
  LOGIN: "/login",
  LOGIN_TWO_FACTOR: "/two-factor",
  LOGIN_GOOGLE_AUTH: "/api/sso/login/google-oauth2", // api path
  USER_PROFILE: "/profile",
  DASHBOARD: "/dashboard",
  SIGNUP: "/signup",
  SIGNUP_CONFIRM_EMAIL: "/signup/confirm/:uid/:token",
  SIGNUP_GOOGLE_AUTH: "/api/sso/signup/google-oauth2", // api path
  ACCEPT_INVITE: "/signup/accept/:inviteId/:key",
  SETUP_TWO_FACTOR: "/two-factor/setup",
  CONFIRM_TWO_FACTOR: "/two-factor/confirm",
  RESET_PASSWORD: "/signup/reset-password/request",
  RESET_PASSWORD_SENT: "/signup/reset-password/sent",
  NOT_FOUND: "/404",
  RESET_PASSWORD_CONFIRM: "/signup/reset-password/confirm/:uid/:token",
  SETUP_DATA_SOURCE: "/setup-data-source",
  SELECT_DATA_SOURCE: "/select-data-source/:dataSourceProviderId",

  SETTINGS_AUTH_PROVIDERS: "/settings/auth-providers",
  SETTINGS_AUTH_PROVIDERS_DETAILS: "/settings/auth-providers/:authProviderId",
  SETTINGS_DATA_SOURCES: "/settings/data-sources",
  SETTINGS_DATA_SOURCE_DETAILS: "/settings/data-sources/:dataSourceId",
  SETTINGS_USERS: "/settings/users",
  SETTINGS_USER_DETAILS: "/settings/users/:userId",
  SETTINGS_ROLES_AND_PERMISSIONS: "/settings/roles-and-permissions",
  SETTINGS_VIEW_ROLE: "/settings/roles-and-permissions/:roleId",
  SETTINGS_SCM: "/settings/scm",
  SETTINGS_SECURITY: "/settings/security",
  SETTINGS_SECURITY_DRAWER: "/settings/security/:drawerId",
  SETTINGS_PLAN: "/settings/change-plan",
  SETTINGS_BILLING: "/settings/billing",

  SPACES_HOME: "/environments/:environment/spaces",
  SPACE_EDIT: "/environments/:environment/spaces/:spaceSlug/edit",
  SPACE: "/environments/:environment/spaces/:spaceSlug",
  SPACE_WITH_RESOURCE_CURSOR:
    "/environments/:environment/spaces/:spaceSlug/:resourceCursor",
  EMBED_SPACE: "/environments/:environment/spaces/:spaceSlug/embed",
  SPACE_NEW: "/environments/:environment/spaces/new",
  SPACE_ACTIVITY: "/environments/:environment/spaces/:spaceSlug/activity",
  RECORD: "/environments/:environment/record/:cursor/view/:view",
  RECORD_WITH_CONTEXT:
    "/environments/:environment/record/:cursor/view/:view/context/:contextCursor"
};

export const Urls = {
  ABOUT_US: "https://www.internal.io/about-us",
  AGENCIES_AND_CONSULTANTS:
    "https://www.internal.io/internal-for-agencies-and-consultants",
  AUTOMATIONS_DOCUMENTATION: "https://www.internal.io/docs/introduction-to-automations",
  BLOG: "https://www.internal.io/blog",
  BULK_IMPORT_TOOL: "https://www.internal.io/bulk-import-tool",
  CAREERS: "https://jobs.lever.co/internal",
  CUSTOMER_SUPPORT: "https://www.internal.io/customer-support",
  DATABASES: "https://www.internal.io/databases",
  DEMO: "https://www.internal.io/demo",
  DOCUMENTATION: "https://www.internal.io/docs",
  EXAMPLES: "https://www.internal.io/solutions",
  FOR_DEVELOPERS: "https://www.internal.io/internal-for-developers",
  GOOGLE_SHEETS: "https://www.internal.io/google-sheets",
  HOME_PAGE: "https://www.internal.io",
  INTERNALPEDIA: "https://www.internal.io/development-glossary",
  PRICING: "https://www.internal.io/pricing",
  PRIVACY_POLICY: "https://www.internal.io/privacy",
  QUEUES_DOCUMENTATION: "https://www.internal.io/docs/introduction-to-queues",
  RELEASES: "https://www.internal.io/releases",
  SECURITY: "https://www.internal.io/security",
  SUPPORT_URL: "https://internalapp.freshdesk.com/support/tickets/new",
  TERMS_AND_CONDITIONS: "https://www.internal.io/site-terms"
};

export { EmptyImage, GoogleLogoPng, SpreadsheetIcon };

export const DARK_THEME_POPPER_PORTAL_ID = "dark-theme-popper-portal";
export const DEFAULT_THEME_POPPER_PORTAL_ID = "default-theme-popper-portal";

export const MAX_VIEW_ROWS = 5000;
export const MAX_TABLE_WITH_BINDING_ROWS = 500;
