import { useMutation } from "@apollo/react-hooks";
import { MutationHookOptions } from "@apollo/react-hooks/lib/types";
import gql from "graphql-tag";

import { ValidationErrorResult } from "../../../../types";
import { Provider } from "../index";

import { SCM_CONFIG_FRAGMENT, ScmConfigNode } from "./ScmConfigFragment";

const QUERY = gql`
  mutation CreateScmConfig(
    $provider: Provider!
    $githubAppAppId: Int!
    $githubAppBranch: String!
    $githubAppInstallationId: Int!
    $githubAppOwner: String!
    $githubAppPrivateKey: String!
    $githubAppRepo: String!
    $githubAppEnterpriseUrl: String
    $githubAppEnterpriseApiUrl: String
  ) {
    createScmConfig(
      provider: $provider
      githubAppAppId: $githubAppAppId
      githubAppBranch: $githubAppBranch
      githubAppInstallationId: $githubAppInstallationId
      githubAppOwner: $githubAppOwner
      githubAppPrivateKey: $githubAppPrivateKey
      githubAppRepo: $githubAppRepo
      githubAppEnterpriseUrl: $githubAppEnterpriseUrl
      githubAppEnterpriseApiUrl: $githubAppEnterpriseApiUrl
    ) {
      ... on ScmConfigNode {
        ...ScmConfigFragment
      }
    }
  }
  ${SCM_CONFIG_FRAGMENT}
`;

interface Data {
  createScmConfig: ScmConfigNode | ValidationErrorResult;
}

export interface Variables {
  provider: Provider;
  githubAppAppId: number;
  githubAppBranch: string;
  githubAppEnterpriseUrl: string;
  githubAppEnterpriseApiUrl: string;
  githubAppInstallationId: number;
  githubAppOwner: string;
  githubAppPrivateKey: string;
  githubAppRepo: string;
}

const useCreateScmConfig = (options?: MutationHookOptions<Data, Variables>) =>
  useMutation<Data, Variables>(QUERY, options);

export default useCreateScmConfig;
