import React from "react";

import { Input } from "antd";

import withDebouncedValue from "../../../../../../common/withDebouncedValue";
import { EffectConfig } from "../../../../../types";
import { DebouncedTemplateEditor } from "../../TemplateEditor/TemplateEditor";

import { EffectOptionField, EffectOptionLabel } from "./styledComponents";
import { OptionsComponent, EffectErrorSelector } from "./types";

const DebouncedInput = withDebouncedValue(Input, {
  selectOnChangeValue: evt => evt.target.value
});

interface SetVariableOptionsValue {
  variable_name: string;
  expression: string;
}
const SetVariableOptions: OptionsComponent<SetVariableOptionsValue> = ({
  value: { variable_name, expression },
  onChange
}) => {
  return (
    <div>
      <EffectOptionField>
        <EffectOptionLabel>
          Name
          <DebouncedInput
            value={variable_name}
            onChange={value => onChange({ variable_name: value })}
          />
        </EffectOptionLabel>
      </EffectOptionField>
      <EffectOptionField>
        <EffectOptionLabel>
          Value
          <DebouncedTemplateEditor
            value={expression}
            placeholder="true"
            mode="javascript"
            onChange={value => onChange({ expression: value })}
          />
        </EffectOptionLabel>
      </EffectOptionField>
    </div>
  );
};

const errorSelector: EffectErrorSelector = eventHandler => {
  const options: Extract<EffectConfig, { type: "set_variable" }>["options"] =
    eventHandler.effect.options;
  if (!options.variable_name) {
    return "Please name your variable.";
  }

  return null;
};

export const SET_VARIABLE = {
  name: "Set variable",
  type: "set_variable" as const,
  Options: SetVariableOptions,
  errorSelector
};

export function setVariable(
  effect: Extract<EffectConfig, { type: "set_variable" }>,
  evaluate: (
    expression: string,
    input: Record<string, any> | null,
    postFix?: any
  ) => Promise<any>,
  input: Record<string, any> | null,
  setVariable: (name: string, value: any) => void,
  idx = 0
) {
  const evalAndSet = async () => {
    const value = await evaluate(effect.options.expression, input, idx);
    setVariable(effect.options.variable_name, value);
  };
  evalAndSet();
}
