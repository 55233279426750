import React from "react";

import styled from "styled-components";

import { ComponentConfigProps } from "../../../../../types";
import BaseComponentConfigSection from "../common/BaseComponentConfigSection";
import {
  ComponentConfigContextContainer,
  useComponentConfigContext
} from "../common/ComponentConfigContext";
import { Field } from "../common/ConfigPanel";
import NameFields from "../common/NameFields";
import { DebouncedTemplateEditor, Height } from "../common/TemplateEditor";
import VisibilityRulesManagerSection from "../common/VisibilityRulesManager";

import DesignConfig from "./DesignConfig";
import { SpaceProgressComponent } from "./types";

const Label = styled.label`
  margin-bottom: ${props => props.theme.spacerlg};
  font-size: ${props => props.theme.largeFontSize};
`;

export default function Config({ slug }: ComponentConfigProps) {
  return (
    <ComponentConfigContextContainer
      dataConfig={<ProgressConfig />}
      designConfig={<DesignConfig />}
      slug={slug}
    />
  );
}

function ProgressConfig() {
  const { dispatch, state } = useComponentConfigContext();
  const {
    properties: { template }
  } = state.draftComponent as SpaceProgressComponent;

  // Note: value defaults to "" if null/undefined to work around
  // DebouncedTemplateEditor overwriting null/undefined as "``".
  return (
    <>
      <BaseComponentConfigSection>
        <NameFields shouldRenderTitle />
        <Field>
          <Label>Value</Label>
          <DebouncedTemplateEditor
            mode="javascript"
            value={template || ""}
            placeholder="0.5"
            minHeight={Height.Small}
            onChange={(value: string) =>
              dispatch({
                type: "SET_DRAFT_COMPONENT",
                payload: { path: "properties.template", value }
              })
            }
          />
        </Field>
      </BaseComponentConfigSection>
      <VisibilityRulesManagerSection />
    </>
  );
}
