import React from "react";

import { Skeleton } from "antd";
import styled from "styled-components";

import { AttributeTypes } from "../../../../../constants";
import { colors } from "../../../../../cssConstants";
import intercom from "../../../../../integrations/intercom/intercom";
import { SimpleAttributeValue } from "../../../../common/AttributeValue";
import ButtonNew, { ButtonProps } from "../../../../common/ButtonNew/ButtonNew";
import useAuthUser from "../../../../common/hooks/useAuthUser";
import IconButton, { SvgIconButton } from "../../../../common/IconButton";
import MenuButton from "../../../../common/MenuButton/MenuButton";
import Message from "../../../../common/Message";
import PerspectiveTimestamp from "../../../../common/PerspectiveTimestamp/PerspectiveTimestamp";
import Rail from "../../../../common/Rail/Rail";
import { H4, B3 } from "../../../../common/StyledComponents";
import TextWithLinks from "../../../../common/TextWithLinks/TextWithLinks";
import { fromGlobalId } from "../../../../util/graphql";
import StatePill from "../../../common/StatePill";
import { CommentList } from "../Comments/CommentList/CommentList";
import {
  reducer as commentsReducer,
  createInitialState as createCommentInitialState,
  ReducerActions
} from "../Comments/CommentList/reducer";
import TransitionButton from "../TransitionButton";
import { TaskNode, TransitionNode } from "../useQueueClient/queries/AllTasks";
import { useTask } from "../useQueueClient/queries/Task";

import { TabOption, TaskTabs } from "./TaskTabs";

interface TaskDrawerProps {
  taskId?: string;
  selectedTaskIndex: number;
  tab: TabOption;
  taskCount: number;
  className?: string;
  onSelectTask: (taskIndexes: number[]) => void;
  onDeleteTask: (taskId: string) => void;
  onUpdateTask: (taskId: string) => void;
  onTransitionTask: (taskId: string) => void;
  onChangeTab: (tab: TabOption) => void;
}

const Task = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
`;

const TaskHeader = styled.div`
  flex-shrink: 0;
  padding: 36px 36px 0;
`;

const TaskContent = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 32px 36px 0;
  scrollbar-gutter: stable;
`;

const TaskTitle = styled(H4)`
  word-break: break-word;
  user-select: none;
  margin-bottom: 24px;
`;

const DetailsTab = styled.div<{ visible: boolean }>`
  display: ${props => (props.visible ? "block" : "none")};
  padding-bottom: 22px;
`;

const Controls = styled.nav`
  display: flex;
  justify-content: space-between;
  padding-bottom: 36px;
`;

const Details = styled.div`
  flex-basis: 0;
  flex-grow: 1;
`;

const RowNavigator = styled.div`
  display: flex;
  gap: 16px;
`;

const DetailTable = styled.table`
  width: 100%;
`;

const TR = styled.tr`
  td {
    vertical-align: top;
    padding-bottom: 15px;
  }
`;

const LabelTD = styled.td`
  width: 50%;
`;

const Label = styled(B3)`
  color: ${props => props.theme.surfaceSecondary};
`;

const Value = styled(B3)`
  color: ${props => props.theme.surfacePrimary};
`;

const ValueTD = styled.td`
  width: 50%;
`;

const StyledActions = styled(Actions)`
  flex-shrink: 0;
  padding: 0px 36px 20px 36px;
`;

function selectTaskDetails(task: TaskNode) {
  return [
    ["State", <StatePill state={task.state} />, undefined],
    ["Requested by", task.requester?.name || "Unknown", AttributeTypes.STRING],
    ["Created at", <PerspectiveTimestamp isoDate={task.createdAt} />, undefined],
    ["Updated at", <PerspectiveTimestamp isoDate={task.updatedAt} />, undefined],
    [
      "Transitioned at",
      <PerspectiveTimestamp isoDate={task.transitionedAt} />,
      undefined
    ],
    ["Assigned to", task.assignee?.name || "Unassigned", AttributeTypes.STRING],
    ...(task.fieldValues || []).map(fieldValue => [
      fieldValue.name,
      fieldValue.value,
      fieldValue.type
    ])
  ];
}

const CommentsTab = styled.div<{ visible: boolean }>`
  flex-basis: 0;
  flex-grow: 1;
  display: ${props => (props.visible ? "block" : "none")};
`;

const MoreMenuButton = styled(IconButton)`
  margin-left: 8px;
  color: ${colors.buttonSecondaryText};
  background-color: ${colors.buttonSecondaryFill};
  border-color: ${colors.buttonSecondaryFill};

  &:hover,
  &:focus {
    background-color: ${colors.buttonSecondaryFillHover};
    border-color: ${colors.buttonSecondaryFillHover};
    color: ${colors.buttonSecondaryText};
  }

  &:active {
    background-color: ${colors.buttonSecondaryFillActive};
    border-color: ${colors.buttonSecondaryFillActive};
    color: ${colors.buttonSecondaryText};
  }
`;

const StyledTabs = styled(TaskTabs)`
  width: 100%;
`;

const FormattedTextWithLinks = styled(TextWithLinks)`
  overflow-wrap: anywhere;
  white-space: pre-wrap;
`;

interface TaskPermissions {
  delete: boolean;
  update: boolean;
}

export default function TaskDrawer({
  taskId,
  selectedTaskIndex,
  tab,
  taskCount,
  className,
  onSelectTask,
  onDeleteTask,
  onUpdateTask,
  onTransitionTask,
  onChangeTab
}: TaskDrawerProps) {
  const { data, loading, error } = useTask({
    variables: {
      id: taskId!
    },
    skip: !taskId,
    fetchPolicy: "cache-and-network"
  });

  React.useEffect(() => {
    if (error) {
      Message.error("Something went wrong. Please try again.");
    }
  }, [error]);

  const task = data?.task;

  const { isAdmin, authUser } = useAuthUser();
  const permissions: TaskPermissions = React.useMemo(() => {
    if (!authUser) {
      return {
        update: false,
        delete: false
      };
    }

    // Convert the id to just the uuid part because the task requester comes in that way
    const uuid = fromGlobalId(authUser.id)[1];
    const allowed = isAdmin || uuid === task?.requester?.id;

    return {
      update: allowed,
      delete: allowed
    };
  }, [authUser, isAdmin, task]);

  const [commentsState, commentsDispatch] = React.useReducer(
    commentsReducer,
    createCommentInitialState()
  );
  React.useEffect(() => {
    if (taskId) {
      commentsDispatch({
        type: ReducerActions.SetTask,
        payload: taskId
      });
    }
  }, [taskId]);

  React.useEffect(() => {
    if (typeof taskId === "string") {
      intercom.hideLauncher();
    } else {
      intercom.showLauncher();
    }
    return () => {
      intercom.showLauncher();
    };
  }, [taskId]);

  return (
    <Rail isActive={!!task} className={className}>
      {!task && loading && <Skeleton active />}
      {!!task && (
        <Task>
          <TaskHeader>
            <Controls>
              <SvgIconButton icon="close" onClick={() => onSelectTask([])} />
              <RowNavigator>
                <SvgIconButton
                  icon="left"
                  disabled={selectedTaskIndex === 0}
                  onClick={() => onSelectTask([selectedTaskIndex - 1])}
                />
                <SvgIconButton
                  icon="right"
                  disabled={selectedTaskIndex === taskCount - 1}
                  onClick={() => onSelectTask([selectedTaskIndex + 1])}
                />
              </RowNavigator>
            </Controls>
            <StyledTabs
              activeTab={tab}
              taskId={task.id}
              commentsLoading={commentsState.totalCount === undefined}
              commentsCount={commentsState.totalCount || 0}
              onTabSelected={tab => onChangeTab(tab)}
            />
          </TaskHeader>
          <TaskContent>
            <DetailsTab visible={tab === TabOption.Details}>
              <Details>
                <TaskTitle>{task.title}</TaskTitle>
                <DetailTable>
                  <tbody>
                    {selectTaskDetails(task).map(([key, value, attrType]) => {
                      const val =
                        attrType === undefined ? (
                          value
                        ) : attrType === "STRING" && !!value ? (
                          <FormattedTextWithLinks text={value} />
                        ) : (
                          <SimpleAttributeValue value={value} type={attrType} />
                        );
                      return (
                        <TR key={key}>
                          <LabelTD>
                            <Label>{key}</Label>
                          </LabelTD>
                          <ValueTD>
                            <Value>{val}</Value>
                          </ValueTD>
                        </TR>
                      );
                    })}
                  </tbody>
                </DetailTable>
              </Details>
            </DetailsTab>
            <CommentsTab visible={tab === TabOption.Comments}>
              <CommentList state={commentsState} dispatch={commentsDispatch} />
            </CommentsTab>
          </TaskContent>
          {tab === "details" &&
            (task.state.fromTransitions?.length || permissions.delete) && (
              <StyledActions
                transitions={task.state.fromTransitions || []}
                task={task}
                canDelete={permissions.delete}
                canUpdate={permissions.update}
                onDeleteTask={onDeleteTask}
                onUpdateTask={onUpdateTask}
                onTransitionTask={onTransitionTask}
              />
            )}
        </Task>
      )}
    </Rail>
  );
}

/**
 * MenuButtonActionNew adapts a ButtonNew to a MenuButton action.
 * It takes in the onComplete callback, and does nothing with it.
 * This prevents warnings as onComplete is used in MenuButton
 */
function MenuButtonActionNew(props: ButtonProps & { onComplete?: () => void }) {
  const { children, onComplete, ...otherProps } = props;
  return <ButtonNew {...otherProps}>{children}</ButtonNew>;
}

const ActionsRoot = styled.div`
  display: flex;
  gap: 4px;
`;

function Actions({
  transitions,
  task,
  className = "",
  canUpdate,
  onUpdateTask,
  canDelete,
  onDeleteTask,
  onTransitionTask
}: {
  transitions: TransitionNode[];
  task: TaskNode;
  className?: string;
  canUpdate?: boolean;
  onUpdateTask: (taskId: string) => void;
  canDelete?: boolean;
  onDeleteTask: (taskId: string) => void;
  onTransitionTask: (taskId: string) => void;
}) {
  const mode =
    transitions.length <= 1 ? "single" : transitions.length === 2 ? "double" : "more";
  let topLevelTransitions = transitions.filter(t => mode !== "more" || t.isPrimary);
  if (transitions.length) {
    topLevelTransitions =
      topLevelTransitions.length === 0 ? [transitions[0]] : topLevelTransitions;
  }

  const moreTransitions = transitions.filter(t => !topLevelTransitions.includes(t));
  const moreTransitionActions = React.useMemo(() => {
    const actions = moreTransitions.map(t => ({
      key: t.id,
      button: (
        <TransitionButton
          transition={t}
          task={task}
          onComplete={() => {
            // Providing a func for on onComplete
            // will delay the closing of the <MenuButton /> until
            // the transition is complete.
            onTransitionTask(task.id);
          }}
          onCancel={() => {
            // Providing a func for onCancel
            // will delay the closing of the <MenuButton /> until
            // the transition is cancelled.
          }}
        />
      )
    }));

    if (canUpdate) {
      actions.push({
        key: "update-task",
        button: (
          <MenuButtonActionNew
            icon="setting"
            title="Update task"
            type="secondary"
            onClick={() => onUpdateTask(task.id)}
          >
            Update task
          </MenuButtonActionNew>
        )
      });
    }

    if (canDelete) {
      actions.push({
        key: "delete-task",
        button: (
          <MenuButtonActionNew
            icon="delete"
            title="Delete task"
            type="secondary"
            onClick={() => onDeleteTask(task.id)}
          >
            Delete task
          </MenuButtonActionNew>
        )
      });
    }

    return actions;
  }, [
    moreTransitions,
    canUpdate,
    canDelete,
    onDeleteTask,
    onUpdateTask,
    task,
    onTransitionTask
  ]);

  return (
    <ActionsRoot className={className}>
      {topLevelTransitions.length > 0 &&
        topLevelTransitions.map(t => (
          <TransitionButton
            key={t.id}
            task={task}
            transition={t}
            block={true}
            onComplete={() => onTransitionTask(task.id)}
          />
        ))}
      {!topLevelTransitions.length && (
        <ButtonNew block disabled>
          No actions
        </ButtonNew>
      )}
      {moreTransitionActions.length > 0 && (
        <MenuButton
          button={<MoreMenuButton shape="circle" type="default" icon="more" />}
          actions={moreTransitionActions}
        />
      )}
    </ActionsRoot>
  );
}
