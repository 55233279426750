import React from "react";

import { useQuery, useMutation } from "@apollo/react-hooks";
import { NetworkStatus } from "apollo-client";
import gql from "graphql-tag";
import { useSearchParams } from "react-router-dom";

import { Connection, RelayNode, ScmStatus } from "../../../../types";
import { useEnvironmentContext } from "../../../common/contexts/EnvironmentContext";
import usePrevious from "../../../common/hooks/usePrevious";
import useSearchParamsUpdater from "../../../common/hooks/useSearchParamsUpdater";
import Message from "../../../common/Message";

const SPACES_PER_PAGE = 24;

interface SpaceFilters {
  environmentId?: string;
  isFeatured?: boolean;
  isFavorite?: boolean;
  onlyUnpublished?: boolean;
  allSpaces?: boolean;
  nameContains?: string;
  orderBy?: "name" | "-name" | "favorite" | "-favorite";
  spaceType?: SpaceType;
}
const strToBool = (val: string) => val === "true";
const filterParams = {
  isFeatured: strToBool,
  isFavorite: strToBool,
  onlyUnpublished: strToBool,
  allSpaces: strToBool,
  nameContains: String,
  orderBy: String
} as const;

function extractFilters(searchParams: URLSearchParams): SpaceFilters {
  let filters: SpaceFilters = {};
  filters = Object.entries(filterParams).reduce<SpaceFilters>(
    (acc, [key, ensureType]) => {
      const val = searchParams.get(key);
      if (val !== null) {
        (acc as any)[key] = ensureType(val);
      }
      return acc;
    },
    filters
  );

  return filters;
}

export function useSpaceFilters() {
  const [searchParams] = useSearchParams();
  const updateSearchParams = useSearchParamsUpdater();
  const filters = extractFilters(searchParams);

  return React.useMemo(
    () => ({
      filters,
      updateFilters: (params: Partial<SpaceFilters>) => updateSearchParams(params)
    }),
    [filters, updateSearchParams]
  );
}

function spaceFiltersToQueryVariables(
  filters: SpaceFilters,
  environmentId: string,
  defaultTab: "isFeatured" | "isFavorite" | undefined,
  forceSearch?: boolean
): FetchSpaceVariables {
  const { onlyUnpublished, ...rest } = filters;

  if (filters.nameContains || forceSearch) {
    return {
      nameContains: filters.nameContains,
      first: SPACES_PER_PAGE,
      spaceType: filters.spaceType
    };
  }
  // Fallback to a default query if none explicitly provided
  if (
    !onlyUnpublished &&
    !filters.allSpaces &&
    rest.isFavorite === undefined &&
    rest.isFeatured === undefined
  ) {
    if (defaultTab === "isFavorite") {
      rest.isFavorite = true;
      if (!rest.orderBy) rest.orderBy = "favorite";
    } else {
      rest.isFeatured = true;
      if (!rest.orderBy) rest.orderBy = "name";
    }
  }
  delete rest.allSpaces;

  // Enforce allowed orderBy
  if (rest.orderBy === "favorite" && !rest.isFavorite) {
    rest.orderBy = "name";
  }

  return {
    ...rest,
    isPublished: rest.isFavorite ? undefined : !onlyUnpublished,
    environmentId: rest.isFavorite ? undefined : environmentId,
    spaceType: filters.spaceType,
    first: SPACES_PER_PAGE
  };
}

export default function useSpaceManager({
  forceSearch,
  variableOverrides,
  skip = false
}: {
  forceSearch?: boolean;
  variableOverrides?: Partial<SpaceFilters>;
  skip?: boolean;
} = {}) {
  const { filters } = useSpaceFilters();
  const [hasLoaded, setHasLoaded] = React.useState(false);
  const { getCurrentEnvironment } = useEnvironmentContext();
  const hasFavorites = useHasFavorites();
  const hasFeatured = useHasFeaturedSpaces();
  const [defaultTab, setDefaultTab] = React.useState<
    undefined | "isFavorite" | "isFeatured"
  >();
  React.useEffect(() => {
    // Do not change default mode after its been set or tabs
    // will switch when a first favorite is added.
    if (hasFavorites !== undefined && defaultTab === undefined) {
      setDefaultTab(hasFavorites ? "isFavorite" : "isFeatured");
    }
  }, [hasFavorites, defaultTab]);

  const env = getCurrentEnvironment();
  const nextVars = spaceFiltersToQueryVariables(
    { ...filters, ...variableOverrides },
    env.id,
    defaultTab,
    forceSearch
  );
  const spacesQuery = useQuery<FetchSpacesData, FetchSpaceVariables>(FETCH_SPACES, {
    variables: nextVars,
    fetchPolicy: "cache-and-network",
    skip: skip || typeof hasFavorites !== "boolean"
  });
  const fetchMore = () => {
    spacesQuery.fetchMore({
      variables: {
        ...nextVars,
        after: spacesQuery.data?.allSpaces.pageInfo?.endCursor
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (
          fetchMoreResult === undefined ||
          !Array.isArray(fetchMoreResult.allSpaces.edges)
        ) {
          return previousResult;
        }

        const { edges, pageInfo } = fetchMoreResult.allSpaces;
        return {
          ...previousResult,
          allSpaces: {
            ...previousResult.allSpaces,
            edges: [...previousResult.allSpaces.edges, ...edges],
            pageInfo
          }
        };
      }
    });
  };

  const [orderSpaceFavorite, orderSpaceFavoriteMutation] = useMutation<
    {},
    OrderSpaceFavoriteVariables
  >(ORDER_SPACE_FAVORITE);

  const [deleteSpaceFavorite, deleteSpaceFavoriteMutation] = useMutation<
    {},
    DeleteSpaceFavoriteVariables
  >(DELETE_SPACE_FAVORITE);

  const error =
    spacesQuery.error ||
    orderSpaceFavoriteMutation.error ||
    deleteSpaceFavoriteMutation.error;
  React.useEffect(() => {
    if (error) {
      Message.error("An error occurred. Please try again.");
      console.error(error);
    }
  }, [error]);

  /*
    Track whether the current query is considered loaded as query vars change
    If a query variable changes that represents a tab or sort order flag `hasLoaded`
    to false, allowing consumers to hide current data and show a loader. When `nameContains`
    changes only flag `hasLoaded` if the change is a transition between searching
    and not searching, otherwise rapid loading states will transition rapidly.
  */
  const { variables, networkStatus } = spacesQuery;
  const prevVariables = usePrevious(spacesQuery.variables);

  React.useEffect(() => {
    if (hasLoaded && prevVariables) {
      const searchingChanged =
        !!variables.nameContains !== !!prevVariables?.nameContains;
      const filterChanged = (
        [
          "isPublished",
          "environmentId",
          "isFeatured",
          "isFavorite",
          "orderBy"
        ] as Array<keyof FetchSpaceVariables>
      ).some(vn => variables[vn] !== prevVariables[vn]);
      if (searchingChanged || filterChanged) {
        setHasLoaded(false);
      }
    } else if (!hasLoaded && networkStatus === NetworkStatus.ready) {
      setHasLoaded(true);
    }
  }, [variables, prevVariables, networkStatus, hasLoaded]);

  const pageInfo = spacesQuery.data?.allSpaces?.pageInfo;
  const spaceNodes = spacesQuery.data?.allSpaces?.edges.map(({ node }) => node) || [];

  // If a space is deleted, the end cursor of the query must be decremented
  // to account for the now missing space, otherwise paging would result
  // in a space being skipped.
  function decrementEndCursor() {
    if (!pageInfo?.endCursor) {
      return undefined;
    }
    const cursorParts = atob(pageInfo.endCursor).split(":");
    if (cursorParts.length !== 2) {
      return pageInfo.endCursor;
    }
    const nextCount = parseInt(cursorParts[1]) - 1;
    return btoa(`arrayconnection:${nextCount}`);
  }

  // Only a user's canonical set of spaces may be sorted.
  const orderable =
    env.isDefault &&
    variables.isFavorite &&
    variables.orderBy === "favorite" &&
    !variables.nameContains &&
    !filters.onlyUnpublished;

  const { client } = spacesQuery;

  return {
    fetchMore,
    loading: spacesQuery.loading,
    refetching:
      spacesQuery.networkStatus === NetworkStatus.refetch ||
      spacesQuery.networkStatus === NetworkStatus.setVariables,
    fetchingMore: spacesQuery.networkStatus === NetworkStatus.fetchMore,
    pageInfo,
    spaceNodes,
    hasLoaded,
    hasFavorites,
    hasFeatured,
    isEmpty: !!(pageInfo && spaceNodes.length === 0),
    orderable,
    queryVariables: variables,
    activeQuery: selectActiveQuery(variables),
    updateSpaceListQuery: (
      action: "REFETCH" | "DELETE",
      payload: { id: string } | undefined = undefined
    ) => {
      if (action === "REFETCH" || action === "DELETE") {
        if (action === "REFETCH") {
          spacesQuery.refetch();
        } else if (action === "DELETE") {
          client.writeQuery({
            query: FETCH_SPACES,
            data: {
              allSpaces: {
                pageInfo: {
                  ...pageInfo,
                  endCursor: decrementEndCursor()
                },
                edges: spaceNodes
                  .filter(node => node.id !== payload?.id)
                  .map(node => ({ node, __typename: "SpaceNodeEdge" })),
                __typename: "SpaceNodeConnection"
              }
            },
            variables
          });
        }
        // If current space query is favorites and this is the last favorite,
        // then clean up favorited spaces exist query.
        if (
          variables.isFavorite &&
          spaceNodes.length === 1 &&
          spaceNodes[0].id === payload?.id
        ) {
          client.writeQuery({
            query: FETCH_FAVORITE_SPACES_EXIST,
            data: {
              allSpaces: {
                edges: [],
                __typename: "SpaceNodeConnection"
              }
            }
          });
        }
      }
    },
    favorite: (spaceId: string, refetchQueries = false) => {
      orderSpaceFavorite({
        variables: { spaceId },
        refetchQueries: refetchQueries ? ["FetchSpaces"] : undefined
      });
      const { client } = orderSpaceFavoriteMutation;
      if (!client) return;
      client.writeFragment({
        id: `SpaceNode:${spaceId}`,
        fragment: gql`
          fragment FavoriteSpaceNode on SpaceNode {
            id
            isFavorite
            __typename
          }
        `,
        data: {
          id: spaceId,
          isFavorite: true,
          __typename: "SpaceNode"
        }
      });
      // Ensure favorites tab is displayed when first space is favorited
      client.writeQuery({
        query: FETCH_FAVORITE_SPACES_EXIST,
        data: {
          allSpaces: {
            edges: [
              {
                node: { id: spaceId, isFavorite: true, __typename: "SpaceNode" },
                __typename: "SpaceNodeEdge"
              }
            ],
            __typename: "SpaceNodeConnection"
          }
        }
      });
    },
    unfavorite: (spaceId: string) => {
      deleteSpaceFavorite({ variables: { spaceId } });
      const { client } = deleteSpaceFavoriteMutation;
      if (!client) return;
      client.writeFragment({
        id: `SpaceNode:${spaceId}`,
        fragment: gql`
          fragment FavoriteSpaceNode on SpaceNode {
            id
            isFavorite
            __typename
          }
        `,
        data: {
          id: spaceId,
          isFavorite: false,
          __typename: "SpaceNode"
        }
      });
    },
    orderFavorite: (spaceId: string, afterSpaceId?: string, beforeSpaceId?: string) => {
      const ensuredAfterSpaceId = findClosestPrevFavorite(spaceNodes, afterSpaceId);
      const ensuredBeforeSpaceId = findClosestNextFavorite(spaceNodes, beforeSpaceId);
      orderSpaceFavorite({
        variables: {
          spaceId,
          afterSpaceId: ensuredAfterSpaceId,
          beforeSpaceId: ensuredBeforeSpaceId
        }
      });
      const { client } = orderSpaceFavoriteMutation;
      if (!client) return;
      client.writeQuery({
        query: FETCH_SPACES,
        data: reorderFavoritesData(spacesQuery.data!, spaceId, afterSpaceId),
        variables
      });
    }
  };
}

function findClosestFavorite(
  spaceNodes: SpaceTileNode[],
  targetId: string | undefined,
  direction: -1 | 1
) {
  if (targetId === undefined) return undefined;
  const targetIndex = spaceNodes.findIndex(sn => sn.id === targetId);
  function _findClosest(index: number): string | undefined {
    const node = spaceNodes[index];
    if (!node) return undefined;
    return node.isFavorite ? node.id : _findClosest(index + direction);
  }
  return _findClosest(targetIndex);
}

export function findClosestPrevFavorite(
  spaceNodes: SpaceTileNode[],
  targetId: string | undefined
) {
  return findClosestFavorite(spaceNodes, targetId, -1);
}

export function findClosestNextFavorite(
  spaceNodes: SpaceTileNode[],
  targetId: string | undefined
) {
  return findClosestFavorite(spaceNodes, targetId, 1);
}

export function reorderFavoritesData(
  data: FetchSpacesData,
  spaceId: string,
  afterSpaceId: string | null = null
) {
  let { edges } = data!.allSpaces;
  edges = edges.concat([]);
  const sourceIndex = edges.findIndex(e => e.node.id === spaceId);
  const sourceNode = edges[sourceIndex];
  edges.splice(sourceIndex, 1);
  const targetIndex = edges.findIndex(e => e.node.id === afterSpaceId);
  edges.splice(targetIndex + 1, 0, sourceNode);
  return {
    allSpaces: {
      edges,
      pageInfo: data!.allSpaces.pageInfo,
      __typename: "SpaceNodeConnection"
    }
  };
}

export type SpaceType = "space" | "sub_space";

export type FetchSpaceVariables = Partial<{
  environmentId: string;
  isFeatured: boolean;
  isFavorite: boolean;
  isPublished: boolean;
  nameContains: string;
  orderBy: string;
  first: number;
  after: string;
  spaceType?: SpaceType;
}>;

export interface SpaceTileNode extends RelayNode {
  name: string;
  slug: string;
  icon: string;
  color: string;
  description: string;
  isFavorite: boolean;
  scmStatus: null | ScmStatus;
  touchedAt: string;
}

export interface FetchSpacesData {
  allSpaces: Connection<SpaceTileNode>;
}

const FETCH_SPACES = gql`
  query FetchSpaces(
    $environmentId: ID
    $isFeatured: Boolean
    $isFavorite: Boolean
    $isPublished: Boolean
    $nameContains: String
    $orderBy: String
    $first: Int
    $after: String
    $spaceType: String
  ) {
    allSpaces(
      environmentId: $environmentId
      isFeatured: $isFeatured
      isFavorite: $isFavorite
      isPublished: $isPublished
      nameContains: $nameContains
      orderBy: $orderBy
      first: $first
      after: $after
      spaceType: $spaceType
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          name
          slug
          icon
          color
          description
          isFavorite
          scmStatus
          touchedAt
        }
      }
    }
  }
`;

type OrderSpaceFavoriteVariables = {
  spaceId: string;
  beforeSpaceId?: string;
  afterSpaceId?: string;
};
const ORDER_SPACE_FAVORITE = gql`
  mutation OrderSpaceFavorite($spaceId: ID!, $beforeSpaceId: ID, $afterSpaceId: ID) {
    orderSpaceFavorite(
      spaceId: $spaceId
      beforeSpaceId: $beforeSpaceId
      afterSpaceId: $afterSpaceId
    ) {
      space {
        id
        isFavorite
      }
    }
  }
`;

type DeleteSpaceFavoriteVariables = { spaceId: string };
const DELETE_SPACE_FAVORITE = gql`
  mutation DeleteSpaceFavorite($spaceId: ID!) {
    deleteSpaceFavorite(spaceId: $spaceId) {
      space {
        id
        isFavorite
      }
    }
  }
`;

export function useHasFavorites() {
  const { data, loading } = useQuery(FETCH_FAVORITE_SPACES_EXIST);
  return loading ? undefined : (data?.allSpaces.edges || []).length > 0;
}

const FETCH_FAVORITE_SPACES_EXIST = gql`
  query FetchFavoriteSpacesExist {
    allSpaces(isFavorite: true, first: 1) {
      edges {
        node {
          id
        }
      }
    }
  }
`;

export function useHasFeaturedSpaces() {
  const { getCurrentEnvironment } = useEnvironmentContext();
  const { data, loading } = useQuery(FETCH_FEATURED_SPACES_EXIST, {
    variables: { environmentId: getCurrentEnvironment().id }
  });

  return loading ? undefined : (data?.allSpaces.edges || []).length > 0;
}

const FETCH_FEATURED_SPACES_EXIST = gql`
  query FetchFeaturedSpacesExist($environmentId: ID) {
    allSpaces(
      environmentId: $environmentId
      isPublished: true
      isFeatured: true
      first: 1
    ) {
      edges {
        node {
          id
        }
      }
    }
  }
`;

export const SpaceListContext = React.createContext<ReturnType<typeof useSpaceManager>>(
  {
    loading: false,
    refetching: false,
    fetchingMore: false,
    orderable: false,
    isEmpty: true,
    hasLoaded: false,
    hasFavorites: false,
    hasFeatured: false,
    activeQuery: null,
    queryVariables: {},
    spaceNodes: [],
    pageInfo: undefined,
    fetchMore: () => {},
    updateSpaceListQuery: () => {},
    favorite: () => {},
    unfavorite: () => {},
    orderFavorite: () => {}
  }
);

export const useSpacesContext = () => React.useContext(SpaceListContext);

function selectActiveQuery(
  variables: FetchSpaceVariables
): "favorites" | "shared" | "all" | "drafts" | null {
  if (variables.isFavorite) {
    return "favorites";
  } else if (variables.isFeatured) {
    return "shared";
  } else if (!variables.isPublished) {
    return "drafts";
  } else {
    return "all";
  }
}
