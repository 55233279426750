import { Icon } from "antd";
import styled from "styled-components";

import { colors, colorTokens, zIndex } from "../../../../../../cssConstants";
import BaseActionIcon from "../../../../../automations/Config/ActionsAndEnvironments/ActionPicker/ActionIcon";
import { B3, C2, H6 } from "../../../../StyledComponents";

export const Container = styled.div`
  padding: 16px;
  background-color: ${colorTokens.grey1837};
  border: 1px solid ${colorTokens.grey1740};
  border-radius: ${props => props.theme.containerPrimaryBorderRadius};

  display: flex;
  align-items: center;
`;

export const TopText = styled(B3)`
  color: ${props => props.theme.textSurfaceSecondary};
  padding-top: 2px;
  line-height: 21px;
  flex-grow: 1;
`;

export const BottomText = styled(H6)`
  color: ${colorTokens.white};
  margin-bottom: 0;
  line-height: 21px;
`;

export const IconTitle = styled(H6)`
  color: ${colorTokens.white};
  margin-bottom: 0;
  line-height: 21px;
`;

export const Spacer = styled.div`
  flex-grow: 1;
`;

export const BigIcon = styled(Icon)<{ disabled?: boolean }>`
  width: 20px;
  height: 20px;

  // Boost precendence so cursor property is set
  ${props => props.disabled && " && { opacity: 0.6; cursor: not-allowed; }"}

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const ActionIcon = styled(BaseActionIcon)`
  align-self: start;

  &.empty {
    background-color: ${colorTokens.grey2100};
  }
`;

export const StepNameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.spacermd};
  align-self: start;
`;

export const InputErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacersm};
`;

export const ErrorText = styled(C2)`
  color: ${props => props.theme.errorColor};
`;

export const Cover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;

export const PopperContainer = styled.div`
  background-color: ${colors.darkBackgroundColor};
  padding: 4px;
  z-index: ${zIndex.hoverItem};
  border-radius: ${props => props.theme.containerPrimaryBorderRadius};
`;

export const LinearCenterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacerxs};
`;

export const IconWrapper = styled.div`
  width: 48px;
  height: 48px;
  border-radius: ${props => props.theme.containerPrimaryBorderRadius};
  overflow: hidden;
`;
