import React from "react";

import styled from "styled-components";

import { Block, BlockWrapper } from "./styledComponents";

export function ShortBlock({ className }: { className?: string }) {
  return (
    <BlockWrapper className={className}>
      <Block />{" "}
    </BlockWrapper>
  );
}

export const ShortThinBlock = styled(ShortBlock)`
  width: 116px;
`;
