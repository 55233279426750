import React from "react";

import { TinyColor } from "@ctrl/tinycolor";
import styled from "styled-components";

import { ErrorValues } from "../../../../../../constants";
import { DataValue } from "../../../../../../types";
import BaseAttributeValue, {
  AttributeValueProps
} from "../../../../../common/AttributeValue";
import PresetIcon from "../../../../../common/PresetIcon";
import { Column, isAttributeColumn } from "../ColumnListManager";

import AutoLinkRenderer from "./AutoLinkRenderer";
import DateTemplateRenderer from "./DateTemplateRenderer";
import { RenderType } from "./options";

interface Props extends AttributeValueProps {
  format: Column;
  rowValues: Record<string, DataValue>;
}

const Root = styled.div`
  &.iconOnly {
    margin-left: auto;
    margin-right: auto;
  }
`;

const IconWrap = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
`;

const LabelWithIcon = styled.div`
  padding-left: ${props => props.theme.spacersm};
`;

const Pillbox = styled.div<{
  color?: string;
  borderColor?: string;
  backgroundColor?: string;
}>`
  display: inline;
  border-radius: 4px;
  padding: ${props => `${props.theme.spacerxs} ${props.theme.spacersm}`};

  &.green {
    background-color: rgba(191, 241, 153, 0.25);
    border: 1px solid #7ac70c;
    color: #7ac70c;
  }

  &.red {
    background-color: rgba(255, 151, 151, 0.25);
    border: 1px solid #d33131;
    color: #d33131;
  }

  &.pink {
    background-color: rgba(239, 77, 179, 0.25);
    border: 1px solid #ef4db3;
    color: #ef4db3;
  }

  &.orange {
    background-color: rgba(255, 207, 122, 0.25);
    border: 1px solid #fa811b;
    color: #fa811b;
  }

  &.blue {
    background-color: rgba(188, 233, 255, 0.25);
    border: 1px solid #1cb0f6;
    color: #1cb0f6;
  }

  &.purple {
    background-color: rgba(202, 148, 255, 0.25);
    border: 1px solid #8549ba;
    color: #8549ba;
  }

  &.yellow {
    background-color: rgba(255, 242, 119, 0.25);
    border: 1px solid #ffb020;
    color: #ffb020;
  }

  &.white {
    background-color: #ffffff;
    border: 1px solid #1b1b1a;
    color: #1b1b1a;
  }

  &.grey {
    background-color: rgba(242, 242, 242, 0.25);
    border: 1px solid #a09d9d;
    color: #a09d9d;
  }

  ${props => props.color && "color:" + props.color + ";"}
  ${props => props.backgroundColor && "background-color:" + props.backgroundColor + ";"}
  ${props => props.borderColor && "border: 1px solid" + props.borderColor + ";"}
`;
Pillbox.displayName = "Pillbox";

export default function AttributeValue({
  attribute,
  value,
  format,
  rowValues,
  style = {},
  ...optionalProps
}: Props) {
  if (!isAttributeColumn(format)) return null;
  const option = format?.render_options?.find(o => o.value === value);
  const renderDefault = () => (
    <BaseAttributeValue
      style={style}
      attribute={attribute}
      value={value}
      {...optionalProps}
    />
  );

  // When the value is a permission denied error value, return a placeholder.
  if (value === ErrorValues.permissionDenied) {
    return <Root>no access</Root>;
  }

  switch (format.render_type) {
    case RenderType.ICON:
      return option ? (
        <Root style={style} className={!!option.label ? "" : "iconOnly"}>
          <IconWrap>
            <PresetIcon inConfig={false} type={option.icon!} />
            {!!option.label && <LabelWithIcon>{option.label}</LabelWithIcon>}
          </IconWrap>
        </Root>
      ) : (
        renderDefault()
      );

    case RenderType.PILLBOX:
      const color = option?.schema || "#FF0000";
      const backgroundColor = new TinyColor(color).lighten(45).toHex8String();

      return option ? (
        <Root style={style}>
          <Pillbox
            className={option.schema || ""}
            color={color}
            borderColor={color}
            backgroundColor={backgroundColor}
          >
            {option.label}
          </Pillbox>
        </Root>
      ) : (
        renderDefault()
      );

    case RenderType.LINK:
      return (
        <a
          style={style}
          href={value as string}
          target="_blank"
          rel="noopener noreferrer"
        >
          <BaseAttributeValue attribute={attribute} value={value} {...optionalProps} />
        </a>
      );

    case RenderType.DATE_TEMPLATE:
      return (
        <DateTemplateRenderer
          style={style}
          attribute={attribute}
          value={value}
          template={format.date_template}
        />
      );

    case RenderType.AUTOLINK:
      return (
        <AutoLinkRenderer
          style={style}
          attribute={attribute}
          value={value}
          rowValues={rowValues}
          {...optionalProps}
        />
      );

    case "default":
    default:
      return renderDefault();
  }
}
