import React from "react";

import { Icon } from "antd";
import classNames from "classnames";
import { ColumnInstance } from "react-table";
import styled from "styled-components";

interface Props {
  column: ColumnInstance;
  hoverColor?: string;
  iconColor?: string;
  fontSize?: string;
  className?: string;
}

const Root = styled.div<{ hoverColor?: string; fontSize?: string }>`
  ${props => (props.fontSize ? `font-size: ${props.fontSize};` : "")}

  &.sortable:hover {
    background-color: ${props => props.hoverColor || "rgb(231, 231, 231)"};
    cursor: pointer;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const IconContainer = styled.span`
  display: flex;
  flex-direction: column;
  margin-top: 1px;
  margin-left: ${props => props.theme.spacersm};

  & > :first-child {
    margin-bottom: -4px;
  }

  &.asc > :first-child {
    color: ${props => props.theme.primaryColor};
  }

  &.desc > :last-child {
    color: ${props => props.theme.primaryColor};
  }
`;

const SortIcon = styled(Icon)<{ color?: string }>`
  font-size: ${props => props.theme.tinyFontSize};
  color: ${props => props.color || props.theme.greyBackgroundColor};
`;

export default function TableHeader({
  column,
  hoverColor,
  iconColor,
  fontSize,
  className
}: Props) {
  return (
    <Root
      hoverColor={hoverColor}
      fontSize={fontSize}
      className={classNames(column.canSort ? "sortable" : "", className)}
    >
      <Container>
        <span>{column.render("Header")}</span>
        {column.canSort && (
          <IconContainer
            className={column.isSorted ? (column.isSortedDesc ? "desc" : "asc") : ""}
          >
            <SortIcon type="caret-up" color={iconColor} />
            <SortIcon type="caret-down" color={iconColor} />
          </IconContainer>
        )}
      </Container>
    </Root>
  );
}
