import React, { useCallback, useState } from "react";

import { matchPath } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { Routes, Urls } from "../../../constants";
import useAuthUser from "../../common/hooks/useAuthUser";
import usePaths from "../../common/hooks/usePaths";
import Spacer from "../../common/Spacer";
import { B2, B3, H2, H6 } from "../../common/StyledComponents";
import Modal, { FooterLink } from "../common/Modal";
import ContactModal from "../ContactModal/ContactModal";

import automations_thumbnail from "./automations_thumb.png";
import queues_thumbnail from "./queues_thumb.png";
import spaces_thunmbnail from "./spaces_thumb.png";

const DISMISSAL_KEY = "onboardingModalDismissed";
const EXCLUDED_ROUTES = [
  Routes.LOGIN,
  Routes.LOGIN_TWO_FACTOR,
  Routes.LOGIN_TWO_FACTOR,
  Routes.SIGNUP,
  Routes.SIGNUP_CONFIRM_EMAIL,
  Routes.SIGNUP_GOOGLE_AUTH,
  Routes.ACCEPT_INVITE,
  Routes.SETUP_TWO_FACTOR,
  Routes.CONFIRM_TWO_FACTOR,
  Routes.RESET_PASSWORD,
  Routes.RESET_PASSWORD_SENT,
  Routes.RESET_PASSWORD_CONFIRM,
  Routes.SETUP_DATA_SOURCE,
  Routes.SELECT_DATA_SOURCE
];

const _dismiss = () =>
  window.localStorage.setItem(DISMISSAL_KEY, new Date().toISOString());

function useIsModalVisible() {
  let visible = true;
  const { isAdmin } = useAuthUser();
  const dismissedAt = window.localStorage.getItem(DISMISSAL_KEY);
  if (!isAdmin) {
    visible = false;
  } else if (dismissedAt) {
    visible = false;
  } else if (EXCLUDED_ROUTES.some(r => matchPath(r, window.location.pathname))) {
    visible = false;
  }
  return visible;
}

export default function OnboardingModal() {
  const [_, setForceRerender] = useState(0);
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const paths = usePaths();
  const visible = useIsModalVisible();
  const dismiss = useCallback(() => {
    _dismiss();
    setForceRerender(1);
  }, []);
  return (
    <>
      <Modal
        visible={!isContactModalOpen && visible}
        footer={
          <>
            Need help getting started?&nbsp;
            <FooterLink href={Urls.DOCUMENTATION} target="_blank">
              View our documentation
            </FooterLink>
            &nbsp;or&nbsp;
            <FooterLink onClick={() => setIsContactModalOpen(true)}>
              Get in touch
            </FooterLink>
          </>
        }
        onClose={dismiss}
      >
        <H2>Welcome! Let's start building</H2>
        <B2>Where would you like to start?</B2>
        <Spacer size="xl" />
        <StyledGrid>
          <AppSection>
            <Thumbnail
              src={spaces_thunmbnail}
              alt="Spaces app with a refund form and order items"
            />
            <Spacer size="md" />
            <StyledH6>Powerful workspace with a custom app builder</StyledH6>
            <B3>
              <Link onClick={() => dismiss()} to={paths.getNewSpace()}>
                Create Space
              </Link>
            </B3>
          </AppSection>
          <AppSection>
            <Thumbnail src={queues_thumbnail} alt="Queue for processing new users" />
            <Spacer size="md" />
            <StyledH6>Flexible task system to get work done across your team</StyledH6>
            <B3>
              <Link onClick={() => dismiss()} to={paths.queuesapp_getNewQueue()}>
                Create Queue
              </Link>
            </B3>
          </AppSection>
          <AppSection>
            <Thumbnail
              src={automations_thumbnail}
              alt="Automation to send an onboarding email and a queue task"
            />
            <Spacer size="md" />
            <StyledH6>Automate work across your databases and APIs</StyledH6>
            <B3>
              <Link
                onClick={() => dismiss()}
                to={paths.automationsapp_getNewAutomation()}
              >
                Create Automation
              </Link>
            </B3>
          </AppSection>
        </StyledGrid>
      </Modal>
      {isContactModalOpen && (
        <ContactModal onClose={() => setIsContactModalOpen(false)} />
      )}
    </>
  );
}

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr)); minmax(0, 1fr);
  grid-gap: ${props => props.theme.spacerlg};
  align-items: stretch;
`;

const AppSection = styled.section`
  background: rgba(41, 41, 45, 0.04);
  border: solid 1px rgba(41, 41, 45, 0.13);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 0 ${props => props.theme.spacerlg} 0;
  text-align: center;
`;

const Thumbnail = styled.img``;

const StyledH6 = styled(H6)`
  max-width: 85%;
  margin: 0 auto;
  height: 57px; /* 3 lines */
`;
