import styled from "styled-components";

import { ButtonTypes as AntButtonTypes } from "../../../../../common/Button/Button";

export const EditModeContainer = styled.div<{ buttonType?: AntButtonTypes }>`
  pointer-events: none;
  cursor: default;

  ${props =>
    props.buttonType === "link"
      ? ""
      : `button: disabled {
      color: white;
      background-color: ${props.theme.primaryColor};
    }`}
`;
EditModeContainer.displayName = "EditModeContainer";

export const Container = styled.div`
  .custom-file-input::-webkit-file-upload-button {
    display: none;
  }
  .custom-file-input {
    display: none;
  }

  .visualUploader {
    display: flex;
    align-items: center;
    margin-bottom: ${props => props.theme.spacermd};

    button {
      &:hover,
      &:focus,
      &:active {
        border-color: ${props => props.theme.primaryColor};
        color: ${props => props.theme.primaryColor};
      }
    }

    p {
      margin-left: ${props => props.theme.spacersm};
      margin-bottom: 0px;
      color: ${props => props.theme.textColorMid};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
