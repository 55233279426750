import { Timeline as ATimeline } from "antd";
import styled from "styled-components";

import { colors } from "../../../cssConstants";
import { ErrorIcon as BaseErrorIcon } from "../Icons";
import { B3 } from "../StyledComponents";

export const Timeline = styled(ATimeline)``;

export const TimelineItem = styled(ATimeline.Item)`
  padding-bottom: ${props => props.theme.spacerxl};
  min-height: 72px;

  &.ant-timeline-item-last {
    padding-bottom: ${props => props.theme.spacermd};
  }
  .ant-timeline-item-head {
    background: none;
  }
  .ant-timeline-item-tail {
    margin-top: 15px;
    height: calc(100% - 40px);
    border-left: 1px solid ${props => props.theme.containerBorder};
  }
  .ant-timeline-item-content {
    margin: 0 0 0 34px;
  }
  .ant-timeline-item-head-blue {
    color: ${props => props.theme.surfaceTertiary};
  }
`;

export const TimelineTitle = styled.div<{ selected?: boolean }>`
  font-size: 14px;
  font-weight: ${props => (props.selected ? "600" : "500")};
  color: ${props =>
    props.selected ? props.theme.surfacePrimary : props.theme.surfaceSecondary};
`;

export const TimelineDescription = styled(B3)`
  color: ${props => props.theme.surfaceSecondary};
`;

export const TitleWithErrorContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme.spacermd};
`;

export const ErrorIcon = styled(BaseErrorIcon)`
  margin-right: ${props => props.theme.spacersm};
`;

export const IconContainer = styled.div<{ isActive: boolean }>`
  color: ${props => (props.isActive ? colors.primaryColor : colors.surfaceSecondary)};
`;
