import { useQuery } from "@apollo/react-hooks";
import { QueryHookOptions } from "@apollo/react-hooks/lib/types";
import gql from "graphql-tag";

import { RelayNode, Connection } from "../../../../types";

import { AUTOMATION_BASIC_FRAGMENT } from "./common";

interface AutomationEnvironmentNode extends RelayNode {
  id: string;
  environment: RelayNode;
  lastRunAt: string;
  nextRunAt: string;
}

export interface AutomationNode extends RelayNode {
  __typename?: "AutomationNode";
  name: string;
  description: string;
  slug: string;
  icon: string;
  color: string;
  startAt: string | null;
  endAt: string | null;
  isActive: boolean;
  automationEnvironments?: Connection<AutomationEnvironmentNode>;
}

export interface Data {
  allAutomations: Connection<AutomationNode>;
}

export const ALL_AUTOMATIONS_QUERY = gql`
  query AllAutomations {
    allAutomations {
      edges {
        node {
          ...AutomationBasicFragment
        }
      }
    }
  }
  ${AUTOMATION_BASIC_FRAGMENT}
`;

export const useAllAutomations = (options?: QueryHookOptions<Data>) => {
  return useQuery<Data>(ALL_AUTOMATIONS_QUERY, options);
};
