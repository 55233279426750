import { useRef } from "react";

import { BigNumber } from "bignumber.js";
import { get } from "lodash";

import { AttributeTypes } from "../../../../../constants";
import {
  SpaceComponentObject,
  ObjectBinding,
  Binding,
  BindingShape,
  SpaceComponentType
} from "../../../../../types";
import { createPath, parsePath } from "../../../../util/binding";
import { SpaceDefinition } from "../../../types";
import { COMPONENTS_WITHOUT_BLANK_SUPPORT, BlankValueType } from "../../constants";
import { ComponentNode } from "../../RenderTreeContext";
import {
  findSpaceComponentPackage as findPackage,
  getSpaceComponentDisplayName as getDisplayName
} from "../../SpaceContext/StableSpaceContext";
import { toProperty } from "../common/util";
import { TEMPLATE_BINDING_EXTRACTER } from "../constants";
import { StateTree } from "../SpaceComponent";

function bindingNodeExists(spaceState: StateTree, path: string) {
  const pathParts = parsePath(path);
  const leafKey = pathParts.pop();
  const parentPath = createPath(pathParts);
  return Object.keys(get(spaceState, parentPath) || {}).includes(leafKey as string);
}

export function useInputState(spaceState: StateTree, bindings: Set<string>) {
  const inputStateRef = useRef<Record<string, any>>({});
  let hasChanges = false;
  const nextInputState: Map<string, any> = new Map();
  for (const b of bindings) {
    const input = get(spaceState, b);
    if (
      !hasChanges &&
      (input !== inputStateRef.current[b] ||
        // Binding value is undefined, its key doesn't exist yet in the cached inputState, and
        // its key does exist in spaceState. This check is required because the above condition
        // does not handle undefined values.
        (input === undefined &&
          b in inputStateRef.current === false &&
          bindingNodeExists(spaceState, b)))
    ) {
      hasChanges = true;
    }
    if (input !== undefined || bindingNodeExists(spaceState, b)) {
      nextInputState.set(b, input);
    }
  }

  // Binding was removed from the set, so we need to update the cached inputState
  for (const b of Object.keys(inputStateRef.current)) {
    if (!bindings.has(b)) {
      hasChanges = true;
    }
  }

  // Only update the cached inputState if there are changes
  if (hasChanges) {
    inputStateRef.current = Object.fromEntries(nextInputState);
  }

  return Object.keys(inputStateRef.current).length > 0 ? inputStateRef.current : null;
}

export const areTemplateBindingsFulfilled = (
  template: string | null,
  input: Record<string, any> | null
): boolean => {
  if (!template) return true;
  const bindings = template
    .match(TEMPLATE_BINDING_EXTRACTER)
    ?.map(re => re.replace(TEMPLATE_BINDING_EXTRACTER, `$1`));
  return bindings ? bindings!.every(b => input && !!input[b]) : true;
};

export const getDisplayValue = (p: any) => {
  const asNumber = new BigNumber(p);
  if (!isNaN(asNumber.toNumber())) return asNumber.dp(4).toString();
  return p;
};

export const missingBindingPlaceHolder = "_______";

function findChildNodeBySlug(
  slug: string,
  node: ComponentNode
): ComponentNode | undefined {
  if (slug === node.component.slug) {
    return node;
  }
  for (let i = 0; i < node.children.length; ++i) {
    const found = findChildNodeBySlug(slug, node.children[i]);
    if (found) {
      return found;
    }
  }
}

export function getChildrenSchema(
  node: ComponentNode,
  componentSlugs: string[],
  spaces: Map<string, SpaceDefinition>
) {
  return componentSlugs.reduce<ObjectBinding[]>((acc, slug) => {
    const child = findChildNodeBySlug(slug, node);
    if (child) {
      const pkg = findPackage(child.component.type);

      // Do not call getSchema on pseudo components. Doing so will cause
      // infinite loops if getSchema is called by a pseudo component from
      // it's own package.
      if (pkg && !pkg.isPseudoComponent) {
        const bindings = pkg.getSchema(child, spaces);
        acc.push({
          name: child.component.slug,
          title: getDisplayName(child.component),
          attributes: bindings,
          shape: BindingShape.OBJECT
        });
        if (child.component.type === "SUB_SPACE") {
          acc.push({
            name: "params",
            title: `${child.component.name} params`,
            attributes: (bindings.find(b => b.name === "params") as ObjectBinding)
              .attributes,
            shape: BindingShape.OBJECT
          });
        }
      }
    }
    return acc;
  }, []);
}

export const getBindingFromPath = (
  node: ComponentNode,
  path: string,
  spaces: Map<string, SpaceDefinition>
): Binding | null => {
  const parts = parsePath(path || "");
  if (!parts.length) return null;

  let root: ComponentNode = node;
  while (root.parent) {
    root = root.parent as ComponentNode;
  }

  // Form a componentMap by iterating the component tree from root
  const nodeMap = new Map();
  const queue = [root];
  while (queue && queue.length) {
    const next = queue.pop() as ComponentNode;
    if (next && next.component) nodeMap.set(next.component.slug, next);
    if (next && next.children) queue.push(...next.children);
  }

  // Start at the tail of the binding path and walk up looking for a path
  // part that is the slug of a component in the space.
  let partsCursor = parts.length - 1;
  let bindingNode;
  while (bindingNode === undefined) {
    bindingNode = nodeMap.get(parts[partsCursor]);
    if (bindingNode === undefined) {
      partsCursor = partsCursor - 1;
    }
    if (partsCursor === -1) {
      return null;
    }
  }

  // Take the tail of the path up to the component which was found
  // and use as the output property.
  const outputProperty = createPath(parts.slice(partsCursor + 1, parts.length));

  if (!outputProperty) return null;

  const pkg = findPackage(bindingNode.component.type);
  if (pkg?.getOutputBinding) {
    return pkg.getOutputBinding(bindingNode.component, outputProperty) || null;
  }

  if (pkg?.getSchema) {
    const bindings = pkg.getSchema(bindingNode, spaces);
    return bindings.find(binding => binding.name === outputProperty) || null;
  }
  return null;
};

export const hasInputComponentProperties = (component: SpaceComponentObject) => {
  const supportsBlank = !COMPONENTS_WITHOUT_BLANK_SUPPORT.includes(component.type);
  if (
    typeof component.properties.default_value_type === "string" &&
    typeof component.properties.validation_type === "string" &&
    (!supportsBlank ||
      (typeof component.properties.allow_blank === "boolean" &&
        typeof component.properties.blank_value_type === "string"))
  ) {
    return true;
  }
  return false;
};

// binding shapes that should be available in binding cascader
// to populate input fields based on attribute type
export const BINDING_SHAPES_BY_TYPE: Record<AttributeTypes, BindingShape[]> = {
  [AttributeTypes.BOOL]: [BindingShape.SCALAR],
  [AttributeTypes.DECIMAL]: [BindingShape.SCALAR],
  [AttributeTypes.FLOAT]: [BindingShape.SCALAR],
  [AttributeTypes.INT]: [BindingShape.SCALAR],
  [AttributeTypes.STRING]: [BindingShape.SCALAR],
  [AttributeTypes.DATE]: [BindingShape.SCALAR],
  [AttributeTypes.TIME]: [BindingShape.SCALAR],
  [AttributeTypes.DATETIME]: [BindingShape.SCALAR],
  [AttributeTypes.TIMESTAMP]: [BindingShape.SCALAR],
  [AttributeTypes.JSON]: [
    BindingShape.SCALAR,
    BindingShape.OBJECT_ARRAY,
    BindingShape.OBJECT,
    BindingShape.UNKNOWN
  ],
  [AttributeTypes.BINARY]: [BindingShape.SCALAR],
  [AttributeTypes.FILE]: [BindingShape.OBJECT]
};

export const PARAMETER_BLANK_VALUE_TYPES = [
  BlankValueType.NULL_VALUE,
  BlankValueType.EMPTY_STRING,
  BlankValueType.UNDEFINED
];

export const getSupportedBlankValueTypes = (
  type: SpaceComponentType,
  validation_type: string
) => {
  switch (type) {
    case "CHECKBOX":
      return [];
    case "TAG_SELECTOR":
      if (validation_type === toProperty(AttributeTypes.STRING)) {
        return [
          BlankValueType.NULL_VALUE,
          BlankValueType.EMPTY_STRING,
          BlankValueType.UNDEFINED
        ];
      }
      return [
        BlankValueType.NULL_VALUE,
        BlankValueType.EMPTY_ARRAY,
        BlankValueType.UNDEFINED
      ];
    case "DROPDOWN":
    case "RADIO_BUTTON":
      if (validation_type === toProperty(AttributeTypes.BOOL)) {
        return [];
      }
      return [
        BlankValueType.NULL_VALUE,
        BlankValueType.EMPTY_STRING,
        BlankValueType.UNDEFINED
      ];
    case "FILE_PICKER":
      return [BlankValueType.NULL_VALUE, BlankValueType.UNDEFINED];
    case "CUSTOM_FIELD":
    case "DATE_TIME_PICKER":
    case "JSON_INPUT":
    case "TEXT_AREA":
      return [
        BlankValueType.NULL_VALUE,
        BlankValueType.EMPTY_STRING,
        BlankValueType.UNDEFINED
      ];
    default:
      return [];
  }
};

export const BlankValueTypesDisplayNames: Record<BlankValueType, string> = {
  [BlankValueType.NULL_VALUE]: "Null",
  [BlankValueType.EMPTY_STRING]: "Empty String",
  [BlankValueType.UNDEFINED]: "Ignored (don't pass value)",
  [BlankValueType.EMPTY_ARRAY]: "Empty Array"
};
