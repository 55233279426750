import {
  DISPLAY_MESSAGE,
  REFRESH_COMPONENT,
  RESET_FORM_INPUTS,
  AUTO_UPDATE_BY_KEY,
  SET_VARIABLE
} from "../effects/Effects";
import { MessageType } from "../effects/Effects/DisplayMessage";
import { PointerEventType } from "../effects/usePointerEffects/usePointerEffects";
import { SubmittableEventType } from "../effects/useSubmittableEffects/useSubmittableEffects";

const defaultSuccessEffect = {
  type: "refresh_component" as const,
  options: {
    component: undefined
  }
};

const defaultFailureEffect = {
  type: "display_message" as const,
  options: {
    type: MessageType.ERROR,
    message: "Something went wrong. Please try again."
  }
};

const defaultClickEffect = {
  type: "set_variable" as const,
  options: {
    variable_name: "variable_name",
    expression: "true"
  }
};

export const FORM_EVENTS = [
  {
    name: "On successful submit",
    type: SubmittableEventType.SUBMIT_SUCCESS,
    effects: [
      DISPLAY_MESSAGE,
      REFRESH_COMPONENT,
      RESET_FORM_INPUTS,
      AUTO_UPDATE_BY_KEY
    ],
    defaultEffect: defaultSuccessEffect
  },
  {
    name: "On failed submit",
    type: SubmittableEventType.SUBMIT_FAILURE,
    effects: [DISPLAY_MESSAGE, RESET_FORM_INPUTS],
    defaultEffect: defaultFailureEffect
  }
];

export const MODAL_FORM_EVENTS = [
  {
    name: "On successful submit",
    type: SubmittableEventType.SUBMIT_SUCCESS,
    effects: [DISPLAY_MESSAGE, REFRESH_COMPONENT, AUTO_UPDATE_BY_KEY],
    defaultEffect: defaultSuccessEffect
  },
  {
    name: "On failed submit",
    type: SubmittableEventType.SUBMIT_FAILURE,
    effects: [DISPLAY_MESSAGE],
    defaultEffect: defaultFailureEffect
  }
];

export const BUTTON_EVENTS = [
  {
    name: "On successful submit",
    type: SubmittableEventType.SUBMIT_SUCCESS,
    effects: [DISPLAY_MESSAGE, REFRESH_COMPONENT, AUTO_UPDATE_BY_KEY, SET_VARIABLE],
    defaultEffect: defaultSuccessEffect
  },
  {
    name: "On failed submit",
    type: SubmittableEventType.SUBMIT_FAILURE,
    effects: [DISPLAY_MESSAGE, SET_VARIABLE],
    defaultEffect: defaultFailureEffect
  }
];

document.addEventListener("DOMContentLoaded", function () {
  if (window.FEATURE_FLAGS.includes("MODAL")) {
    BUTTON_EVENTS.push({
      name: "On click",
      type: PointerEventType.CLICK,
      effects: [SET_VARIABLE],
      defaultEffect: defaultClickEffect
    } as any);
  }
});

export const BULK_IMPORT_EVENTS = MODAL_FORM_EVENTS;
