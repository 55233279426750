import React from "react";

import { throttle } from "lodash";
import styled from "styled-components";

import { SpacingUnitValue } from "../../../../cssConstants";
import useObservedRect from "../../../common/hooks/useObservedRect";
import { assertNever } from "../../../util/assertNever";
import mouseCoords from "../../../util/mouseCoords";
import { useResizeContext } from "../../SpaceRoot/ResizeContext";
import { useStableSpaceContext } from "../../SpaceRoot/SpaceContext";
import {
  BOUNDARY_PROXIMITY_AUTOSCROLL_THRESHOLD,
  RELATIVE_GRID_SIZE
} from "../constants";
import { useLayoutContextDispatcher } from "../LayoutContext/LayoutContext";
import { useTransformationStateContext } from "../TransformationContext/TransformationContext";
import {
  subtractPoints,
  direction,
  DOMRectsEqual,
  DOMPointsEqual,
  TransformationType,
  LayoutUnit,
  ElementLayout,
  parseCssUnit
} from "../util";

const CanvasViewportContext = React.createContext({
  viewportRect: new DOMRect(),
  viewportOffset: new DOMPoint(),
  canvasRect: new DOMRect(),
  lockScroll: () => {},
  unlockScroll: () => {},
  getCanvasPointForPagePoint: (_pagePoint: DOMPoint) => new DOMPoint(),
  registerComponentDepth: (
    _slug: string,
    _elementLayout: ElementLayout,
    _height: number
  ) => {}
});
export default CanvasViewportContext;

export const useCanvasViewportContext = () => React.useContext(CanvasViewportContext);

export const VolatileCanvasViewportContext = React.createContext({
  scrollTop: 0,
  getCursorDistanceFromTop: () => -1 as number,
  getCursorDistanceFromBottom: () => -1 as number
});
export const useVolatileCanvasViewportContext = () =>
  React.useContext(VolatileCanvasViewportContext);

type CanvasViewportReducerAction =
  | {
      type: "REGISTER_COMPONENT_DEPTH";
      payload: {
        height: number;
        elementLayout: ElementLayout;
        slug: string;
        canvasHeight: number;
      };
    }
  | {
      type: "LOCK_SCROLL";
      payload: {};
    }
  | {
      type: "UNLOCK_SCROLL";
      payload: {};
    };

const initialState = {
  componentDepths: new Map<string, number>(),
  scrollLocked: false
};

function canvasViewportReducer(
  state: typeof initialState,
  action: CanvasViewportReducerAction
) {
  switch (action.type) {
    case "REGISTER_COMPONENT_DEPTH": {
      const { slug, height, elementLayout, canvasHeight } = action.payload;
      const unit = parseCssUnit(elementLayout.top);
      let top = 0;
      const parsed = parseInt(elementLayout.top, 10);
      if (unit === LayoutUnit.PIXEL) {
        top = parsed;
      } else {
        top = Math.ceil((parsed / 100) * canvasHeight);
      }

      const depth = top + height;
      const nextComponentDepths = new Map(state.componentDepths);
      nextComponentDepths.set(slug, depth);
      return {
        ...state,
        componentDepths: nextComponentDepths
      };
    }
    case "LOCK_SCROLL": {
      return {
        ...state,
        scrollLocked: true
      };
    }
    case "UNLOCK_SCROLL": {
      return {
        ...state,
        scrollLocked: false
      };
    }
    default:
      return assertNever(action);
  }
}

export function CanvasViewportProvider({ children }: { children: React.ReactNode }) {
  // HACK: just tracking this to get a re-render after canvas scroll
  const [_lastScrollTimestamp, setLastScrollTimestamp] = React.useState(0);
  const [mouseLeaveDirection, setMouseLeaveDirection] =
    React.useState<direction | null>(null);
  const [transformationOffset, setTransformationOffset] = React.useState(
    new DOMPoint()
  );
  const [state, dispatch] = React.useReducer(canvasViewportReducer, initialState);
  const adjusterRef = React.useRef<HTMLDivElement>(null);
  const viewportRef = React.useRef<HTMLDivElement>(null);
  const canvasRef = React.useRef<HTMLDivElement>(null);
  const scrollTop = viewportRef.current?.scrollTop || 0;

  const { editMode } = useStableSpaceContext();
  const { transformations } = useTransformationStateContext();
  const { hasResizedRecently } = useResizeContext();
  const layoutDispatch = useLayoutContextDispatcher();

  const adjusterRect = useObservedRect(adjusterRef);
  const viewportRect = useObservedRect(viewportRef);
  const canvasRect = useObservedRect(canvasRef);

  // When the mouse leaves the page track the direction it exited so
  // autoscroll can continue
  React.useEffect(() => {
    function onMouseLeave(e: MouseEvent) {
      if (e.clientY <= 0) {
        setMouseLeaveDirection(direction.TOP);
      } else if (e.clientY >= window.innerHeight) {
        setMouseLeaveDirection(direction.BOTTOM);
      } else if (e.clientX <= 0) {
        setMouseLeaveDirection(direction.LEFT);
      } else if (e.clientX >= window.innerWidth) {
        setMouseLeaveDirection(direction.RIGHT);
      }
    }
    function onMouseEnter() {
      setMouseLeaveDirection(null);
    }
    window.document.body.addEventListener("mouseleave", onMouseLeave);
    window.document.body.addEventListener("mouseenter", onMouseEnter);
    return () => {
      if (window.document.body === null) return;
      window.document.body.removeEventListener("mouseleave", onMouseLeave);
      window.document.body.removeEventListener("mouseenter", onMouseEnter);
    };
  }, []);

  // If the canvas rect doesn't evenly divide into the grid adjust, calculate amount to
  // adjust it by so that it does
  const gridAdjustment = React.useMemo(() => {
    if (hasResizedRecently || adjusterRect.width === 0) {
      return -1;
    } else if (!editMode) {
      return 0;
    }

    const divisor = 1 / RELATIVE_GRID_SIZE;
    const remainder = adjusterRect.width % divisor;

    return remainder / 2;
  }, [editMode, hasResizedRecently, adjusterRect]);

  const viewportOffset = React.useMemo(() => {
    return new DOMPoint(viewportRect?.left || 0, viewportRect?.top || 0);
  }, [viewportRect]);

  const getCanvasPointForPagePoint = React.useCallback(
    function getCanvasPointPointForPagePoint(pagePoint: DOMPoint) {
      const offsetAddjustedPoint = subtractPoints(pagePoint, viewportOffset);
      return new DOMPoint(
        offsetAddjustedPoint.x,
        offsetAddjustedPoint.y + (viewportRef.current?.scrollTop || 0)
      );
    },
    [viewportOffset, viewportRef]
  );

  const throttledHandleScroll = React.useRef(
    throttle(() => {
      setLastScrollTimestamp(Date.now());
      layoutDispatch({
        type: "UPDATE_CANVAS_SCROLL_TOP",
        payload: { canvasScrollTop: viewportRef.current?.scrollTop || 0 }
      });
    }, 25)
  );

  const transformationBounds = React.useMemo(() => {
    if (transformations.size === 0) return new DOMRect();
    let minX = Number.MAX_SAFE_INTEGER;
    let minY = Number.MAX_SAFE_INTEGER;
    let maxX = Number.MIN_SAFE_INTEGER;
    let maxY = Number.MIN_SAFE_INTEGER;

    transformations.forEach(({ currentLayout }) => {
      const rect = currentLayout.toRect();
      minX = Math.min(minX, rect.left);
      minY = Math.min(minY, rect.top);
      maxX = Math.max(maxX, rect.right);
      maxY = Math.max(maxY, rect.bottom);
    });

    return new DOMRect(minX, minY, maxX - minX, maxY - minY);
  }, [transformations]);

  const transformationType =
    transformations.size > 0
      ? transformations.entries().next().value[1].transformationType
      : TransformationType.NONE;

  // Calculate the offset from the tranformationBounds to the current cursor position
  React.useEffect(() => {
    const emptyPt = new DOMPoint();
    const emptyRect = new DOMRect();
    const boundsEmpty = DOMRectsEqual(transformationBounds, emptyRect);
    const offsetEmpty = DOMPointsEqual(transformationOffset, emptyPt);
    if (boundsEmpty) {
      if (!offsetEmpty) {
        setTransformationOffset(emptyPt);
      }
      return;
    }

    // Only track the offset at the start of a transform, not on each mouse move
    if (!offsetEmpty) return;
    const offset = subtractPoints(
      getCanvasPointForPagePoint(mouseCoords.page),
      new DOMPoint(transformationBounds.x, transformationBounds.y)
    );

    if (DOMPointsEqual(offset, transformationOffset)) return;
    setTransformationOffset(offset);
  }, [
    transformationBounds,
    transformationOffset,
    setTransformationOffset,
    getCanvasPointForPagePoint
  ]);

  React.useEffect(() => {
    layoutDispatch({ type: "UPDATE_CANVAS_DOM_RECT", payload: { rect: canvasRect } });
  }, [canvasRect, layoutDispatch]);

  const deepestCurrentTransformPoint = transformationBounds.bottom;

  const getCursorDistanceFromTop = React.useCallback(() => {
    if (mouseCoords.page.y === -1) return Number.MAX_SAFE_INTEGER;
    return Math.max(0, mouseCoords.page.y - viewportOffset.y);
  }, [viewportOffset]);

  const getCursorDistanceFromBottom = React.useCallback(() => {
    if (mouseLeaveDirection === direction.BOTTOM) return 0;
    return Math.max(0, viewportRect.height - (mouseCoords.page.y - viewportOffset.y));
  }, [viewportOffset, mouseLeaveDirection, viewportRect.height]);

  const widthAdjustment = editMode ? gridAdjustment : SpacingUnitValue.xl;
  const heightAdjustment = editMode ? 0 : SpacingUnitValue.xl;
  const height = editMode ? "100%" : undefined;
  const defaultHeight = adjusterRect.height - heightAdjustment;

  const deepestComponentY = React.useMemo(
    () => Math.max(...state.componentDepths.values()),
    [state.componentDepths]
  );

  // Calculate how "tall" the canvas is by finding the bottom edge of the
  // deepest component, including those currently undergoing transformation.
  // Further ensure visible portions of canvas are not removed if the deepest component
  // is moved up. If that retained portion is scrolled out of view it is collected.
  // This effect is also responsible for autoscrolling to keep transforming elements
  // in view.
  const withinAutoScrollTopBoundary =
    getCursorDistanceFromTop() < BOUNDARY_PROXIMITY_AUTOSCROLL_THRESHOLD &&
    !state.scrollLocked;
  const withinAutoScrollBottomBoundary =
    getCursorDistanceFromBottom() < BOUNDARY_PROXIMITY_AUTOSCROLL_THRESHOLD &&
    !state.scrollLocked;
  React.useLayoutEffect(() => {
    const viewportEl = viewportRef.current;
    const canvasEl = canvasRef.current;

    if (viewportEl === null || canvasEl === null) return;

    let nextScrollTop = viewportEl.scrollTop;
    const hasActiveTransform = deepestCurrentTransformPoint > 0;
    const scrollTopToDeepestCurrentTransform =
      deepestCurrentTransformPoint - viewportRect.height;
    if (withinAutoScrollBottomBoundary && hasActiveTransform) {
      // Adjust by distance between transformation bottom edge and cursor
      nextScrollTop = scrollTopToDeepestCurrentTransform;
      if (transformationType === TransformationType.MOVE) {
        nextScrollTop =
          nextScrollTop - (transformationBounds.height - transformationOffset.y);
      }
    } else if (withinAutoScrollTopBoundary && hasActiveTransform) {
      nextScrollTop = transformationBounds.top;
      if (transformationType === TransformationType.MOVE) {
        nextScrollTop = nextScrollTop + transformationOffset.y;
      }
    }
    let nextHeight = Math.max(
      viewportRect.height,
      defaultHeight,
      deepestComponentY,
      deepestCurrentTransformPoint,
      nextScrollTop + viewportRect.height - 1
    );
    if (Number.isNaN(nextHeight)) {
      nextHeight = viewportRect.height;
    }
    let currentHeight = Math.max(
      parseFloat(canvasEl.style.height),
      viewportRect.height
    );
    if (Number.isNaN(currentHeight)) {
      currentHeight = viewportRect.height;
    }

    const preventShrinkThreshold = 4; // Unit is %
    if (
      currentHeight > nextHeight &&
      currentHeight - nextHeight < preventShrinkThreshold
    ) {
      nextHeight = currentHeight;
    }
    // If the bottom edge is within the gutter's height of the bottom of the viewport
    // and there is not already a gutter added and accounted for add in a gutter.
    const bottomGutter =
      nextHeight - SpacingUnitValue.md > viewportRect.height &&
      deepestComponentY + SpacingUnitValue.md >= nextHeight
        ? SpacingUnitValue.md
        : 0;

    canvasEl.style.height = Math.floor(nextHeight) + bottomGutter + LayoutUnit.PIXEL;
    viewportEl.scrollTop = nextScrollTop;
  }, [
    _lastScrollTimestamp,
    withinAutoScrollTopBoundary,
    withinAutoScrollBottomBoundary,
    defaultHeight,
    deepestComponentY,
    deepestCurrentTransformPoint,
    viewportRect,
    transformationBounds,
    transformationType,
    transformationOffset
  ]);

  const volatileCanvasContextValue = React.useMemo(() => {
    return {
      scrollTop,
      getCursorDistanceFromTop,
      getCursorDistanceFromBottom
    };
  }, [scrollTop, getCursorDistanceFromTop, getCursorDistanceFromBottom]);

  const registerComponentDepth = React.useCallback(
    (slug: string, elementLayout: ElementLayout, height: number) =>
      dispatch({
        type: "REGISTER_COMPONENT_DEPTH",
        payload: { height, elementLayout, slug, canvasHeight: canvasRect.height }
      }),
    [canvasRect.height]
  );

  const lockScroll = React.useCallback(() => {
    dispatch({ type: "LOCK_SCROLL", payload: {} });
  }, []);

  const unlockScroll = React.useCallback(() => {
    dispatch({ type: "UNLOCK_SCROLL", payload: {} });
  }, []);

  const value = React.useMemo(() => {
    return {
      viewportRect,
      viewportOffset,
      canvasRect,
      getCanvasPointForPagePoint,
      registerComponentDepth,
      lockScroll,
      unlockScroll
    };
  }, [
    viewportRect,
    viewportOffset,
    canvasRect,
    registerComponentDepth,
    getCanvasPointForPagePoint,
    lockScroll,
    unlockScroll
  ]);

  const ScrollContainer = editMode ? EditModeScrollContainer : ViewModeScrollContainer;

  const hasVertScrolls =
    editMode ||
    parseFloat(canvasRef.current?.style.height || "0") >
      Math.floor(viewportRect.height || 0);

  return (
    <CanvasViewportContext.Provider value={value}>
      <VolatileCanvasViewportContext.Provider value={volatileCanvasContextValue}>
        <DimensionsAdjuster
          ref={adjusterRef}
          style={{
            height,
            paddingTop: `${heightAdjustment}px`,
            paddingLeft: `${widthAdjustment}px`,
            paddingRight: `${widthAdjustment}px`
          }}
        >
          <ScrollContainer
            ref={viewportRef}
            className="scrollContainer"
            style={{
              opacity: hasResizedRecently ? 0.5 : 1.0,
              overflowY: hasVertScrolls ? "scroll" : "hidden"
            }}
            onScroll={() => {
              // Need to trigger re-renders on scroll :O
              throttledHandleScroll.current();
            }}
          >
            <CanvasContainer ref={canvasRef} className="canvasContainer layoutContext">
              {children}
            </CanvasContainer>
          </ScrollContainer>
        </DimensionsAdjuster>
      </VolatileCanvasViewportContext.Provider>
    </CanvasViewportContext.Provider>
  );
}

const DimensionsAdjuster = styled.div`
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
`;

const BaseScrollContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: hidden;
`;

const EditModeScrollContainer = styled(BaseScrollContainer)`
  margin-top: 1px;
  outline: solid 1px ${props => props.theme.borderGrey};
`;

const ViewModeScrollContainer = React.forwardRef(function ViewModeScrollContainer(
  props: {
    children: React.ReactNode;
    style: React.CSSProperties;
    onScroll: () => void;
  },
  ref: React.Ref<HTMLDivElement>
) {
  return (
    <BaseScrollContainer>
      <ViewModeChildContainer ref={ref}>{props.children}</ViewModeChildContainer>
    </BaseScrollContainer>
  );
});

const CanvasContainer = styled.div`
  .layoutContext {
    height: 100%;
  }
`;
const ViewModeChildContainer = styled.div`
  height: 100%;
  width: 100%;
`;
