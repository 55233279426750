import React from "react";

import { useEvaluaterContext } from "../../../../common/CodeSandbox/EvaluaterContext";
import { EVALUATION_ERROR_PREFIX } from "../../../../common/CodeSandbox/useCodeSandbox";

export default function useEvaluate(template: string, input: any, editMode: boolean) {
  const { evaluate, getConsoleError } = useEvaluaterContext();
  const [evaluated, setEvaluated] = React.useState("");

  React.useEffect(() => {
    const evalExpression = async () => {
      try {
        const text = await evaluate(template, input);
        setEvaluated(text as string);
      } catch (e) {
        // clear template if it cannot be evaluated (likely due to bindings not fulfilled)
        if (typeof e === "string" && e.indexOf(EVALUATION_ERROR_PREFIX) > -1) {
          setEvaluated("");
        }
        if (editMode) {
          console.warn(getConsoleError(e));
        }
      }
    };
    evalExpression();
  }, [template, input, evaluate, editMode, getConsoleError]);

  return evaluated;
}
