import { Binding, BindingShape, SpaceComponentObject } from "../../../../../../types";
import {
  createSpaceFunction,
  SpaceFunction
} from "../../../../FunctionExecutor/FunctionExecutor";
import { SpaceDefinition } from "../../../../types";
import { ComponentNode } from "../../../RenderTreeContext";
import { getParametersSchema } from "../ParametersManager";

export function getOutputBinding(
  component: SpaceComponentObject,
  property: string
): Binding {
  const func = createSpaceFunction(component);
  const binding = getFunctionSchema(func).find(b => b.name === property);
  if (!binding) {
    throw new Error(`unknown property: ${property}`);
  }
  return binding;
}

export function getSchema(
  node: ComponentNode,
  spaces: Map<string, SpaceDefinition>
): Binding[] {
  const func = createSpaceFunction(node.component);
  return getFunctionSchema(func).concat({
    name: "fieldset",
    shape: BindingShape.OBJECT,
    attributes: getParametersSchema(node, spaces)
  });
}

export const getFunctionSchema = (func: SpaceFunction | undefined): Binding[] => {
  if (!func) return [];
  return [func.getSchema(true)];
};
