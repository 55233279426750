import { useCallback } from "react";

import { useMutation } from "@apollo/react-hooks";
import { Severity } from "@sentry/browser";
import { MutationResult } from "react-apollo";

import { SpaceNode, SpaceComponentInputType } from "../../../../../../types";
import Message from "../../../../../common/Message";
import { reportException } from "../../../../../util/exceptionReporting";
import { useStableSpaceContext } from "../../../../SpaceRoot/SpaceContext";
import { ENVIRONMENT_VERSION_QUERY } from "../../../ConfigHeader/PublishButton/queries";
import { getComponentsForMutation } from "../../../util/util";
import {
  SpaceConfigState,
  SpaceConfigDispatch,
  selectSpaceComponentTree
} from "../reducer";
import { BaseSpaceConfig } from "../reducer/reducer";

import { SPACE_UPDATE } from "./queries";

interface Result {
  save: (environmentsToPublish?: string[]) => void;
  loading: boolean;
}

export interface MutationOptions {
  id?: string;
  onNewSpaceCreated?: (data: UseConfigMutationData) => void;
}

type ValidationError = string | { [key: string]: string | ValidationError };

export type UseConfigMutationData = {
  spaceUpdate: {
    ok: boolean;
    message?: string;
    space: SpaceNode;
    source: {
      slugs: Record<string, ValidationError>;
      space: string;
    };
  };
};
export interface SpaceInput {
  id?: string;
  name: string;
  componentTreeNodes: SpaceComponentInputType[];
}
export type UseConfigMutationVars = {
  spaceInput: SpaceInput;
  environmentsToPublish: string[];
};

export default function useConfigMutation(
  state: SpaceConfigState,
  dispatch: SpaceConfigDispatch,
  options: MutationOptions
): Result {
  const { findSpaceComponentPackage } = useStableSpaceContext();
  const handleError = useCallback(
    (result: MutationResult<UseConfigMutationData>) => {
      if (result.data) {
        const { spaceUpdate } = result.data;
        const sourceKeys = Object.keys(spaceUpdate.source);
        if (sourceKeys.includes("slugs")) {
          reportException(new Error("Invalid space submission allowed by client."), {
            extra: { result: JSON.stringify(spaceUpdate) },
            level: Severity.Warning
          });
          console.error(
            "Space failed to save. Contact support with the following information for more help.",
            spaceUpdate.source
          );
          Message.error(
            "Space failed to save. More information is available in your browser's development console."
          );
          dispatch({
            type: "SET_API_ERRORS",
            payload: {
              errors: spaceUpdate.source.slugs
            }
          });
          return;
        }

        if (sourceKeys.includes("space")) {
          Message.error(spaceUpdate.source.space);
          return;
        }
      }

      Message.error(
        "Something went wrong saving this space. Our team has been notified. Please try again later."
      );
    },
    [dispatch]
  );

  const [saveConfig, { loading }] = useMutation<
    UseConfigMutationData,
    UseConfigMutationVars
  >(SPACE_UPDATE, {
    refetchQueries: options.id
      ? [{ query: ENVIRONMENT_VERSION_QUERY, variables: { space: options.id } }]
      : []
  });

  const save = useCallback(
    async (environmentsToPublish: string[] | undefined) => {
      function toComponentInputs(space: BaseSpaceConfig) {
        return getComponentsForMutation(
          selectSpaceComponentTree(space),
          space.elementLayouts,
          findSpaceComponentPackage
        );
      }

      function toSpaceInput(space: BaseSpaceConfig) {
        return {
          id: space.id || undefined,
          name: space.name,
          slug: space.slug,
          parameters: space.parameters.map(({ id, __typename, ...p }) => p),
          componentTreeNodes: toComponentInputs(space)
        };
      }

      // Spaces are saved "bottom up" so that parent spaces can include
      // the correct slugs and version ids of their sub spaces. If a space
      // save fails, subsequent spaces will not be saved. Any previously saved
      // spaces are orphaned.
      try {
        const spaceInput = {
          ...toSpaceInput(state.spaces.get(state.rootSpaceSlug)!),
          subSpaces: [...state.spaces.values()]
            .filter(s => s.slug !== state.rootSpaceSlug)
            .map(s => toSpaceInput(s))
        };

        const result = await saveConfig({
          variables: {
            spaceInput,
            environmentsToPublish: environmentsToPublish || []
          }
        });

        if (!result?.data || !result.data?.spaceUpdate.ok) {
          throw result;
        } else {
          dispatch({
            type: "INIT_EXISTING_SPACE",
            payload: {
              space: result.data.spaceUpdate.space,
              force: true
            }
          });
        }

        dispatch({ type: "HANDLE_SAVE" });
        if (options.onNewSpaceCreated && result.data.spaceUpdate) {
          options.onNewSpaceCreated(result.data);
        }
      } catch (result) {
        handleError(result as MutationResult<UseConfigMutationData>);
      }
    },
    [state, options, dispatch, findSpaceComponentPackage, saveConfig, handleError]
  );

  return {
    save,
    loading
  };
}
