import { AttributeColumn, ensureAttributeColumn } from "..";
import {
  AttributeTypes,
  DATE_DISPLAY_FORMAT,
  DATE_TIME_DISPLAY_FORMAT,
  TIMESTAMP_DISPLAY_FORMAT,
  TIME_DISPLAY_FORMAT
} from "../../../../../../../constants";
import { AttributeNode, FunctionAttributeNode } from "../../../../../../../types";
import {
  RenderType,
  ValueRenderOption,
  RENDER_TYPES_WITH_VALUE_RENDER_OPTIONS
} from "../../AttributeValue/options";

const BOOLEAN_BASE_OPTIONS = [
  { value: true, label: "True", icon: "none" },
  { value: false, label: "False", icon: "none" }
] as ValueRenderOption[];
const NULLABLE_BOOLEAN_BASE_OPTIONS = [
  { value: true, label: "True", icon: "none" },
  { value: false, label: "False", icon: "none" },
  { value: null, label: "", icon: "none" }
] as ValueRenderOption[];

const DATE_TEMPLATE_DEFAULTS: Record<string, string | undefined> = {
  [AttributeTypes.TIMESTAMP]: TIMESTAMP_DISPLAY_FORMAT,
  [AttributeTypes.DATETIME]: DATE_TIME_DISPLAY_FORMAT,
  [AttributeTypes.DATE]: DATE_DISPLAY_FORMAT,
  [AttributeTypes.TIME]: TIME_DISPLAY_FORMAT
};

export const ensureValidAttributeColumn = (
  attribute: AttributeNode | FunctionAttributeNode,
  newRenderType: RenderType,
  oldConfig: AttributeColumn
) => {
  const newConfig = ensureAttributeColumn({
    ...oldConfig,
    render_type: newRenderType
  });
  // add defaults if necessary
  if (
    attribute.sourceType === AttributeTypes.BOOL &&
    RENDER_TYPES_WITH_VALUE_RENDER_OPTIONS.includes(newRenderType)
  ) {
    newConfig.render_options =
      newConfig.render_options ||
      ("sourceNullable" in attribute && attribute.sourceNullable
        ? NULLABLE_BOOLEAN_BASE_OPTIONS
        : BOOLEAN_BASE_OPTIONS);
  }

  if (
    attribute.sourceType !== AttributeTypes.BOOL &&
    RENDER_TYPES_WITH_VALUE_RENDER_OPTIONS.includes(newRenderType)
  ) {
    newConfig.render_options = newConfig.render_options || [
      {
        label: "",
        value: ""
      }
    ];
  }

  if (newRenderType === RenderType.DATE_TEMPLATE && !newConfig.date_template) {
    newConfig.date_template = DATE_TEMPLATE_DEFAULTS[attribute.sourceType];
  }

  // remove superflous fields if necessary
  if (!RENDER_TYPES_WITH_VALUE_RENDER_OPTIONS.includes(newRenderType)) {
    newConfig.render_options = undefined;
  }

  if (newRenderType !== RenderType.DATE_TEMPLATE) {
    newConfig.date_template = undefined;
  }

  if (newRenderType === RenderType.PILLBOX) {
    newConfig.render_options = newConfig.render_options!.map(o => ({
      ...o,
      icon: undefined
    }));
  }

  if (newRenderType === RenderType.ICON) {
    newConfig.render_options = newConfig.render_options!.map(o => ({
      ...o,
      schema: undefined
    }));
  }

  return newConfig;
};
