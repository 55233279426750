import styled from "styled-components";

import { ErrorMessageField as CommonErrorMessageField } from "../../common/ErrorMessageField";

export const Container = styled.div`
  .jsonViewer {
    padding: 0px;

    .react-json-view {
      border-radius: ${props => props.theme.containerPrimaryBorderRadius};

      .pretty-json-container {
        padding: ${props => props.theme.spacermd};
      }
    }
  }
`;

export const Section = styled.section`
  margin-bottom: ${props => props.theme.spacerxl};
`;

export const ErrorMessageField = styled(CommonErrorMessageField)`
  margin-bottom: ${props => props.theme.spacermd};
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme.spacerxs};
`;
