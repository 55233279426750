import styled from "styled-components";

import { colorTokens } from "../../../../../../cssConstants";

export const Container = styled.div`
  display: flex;
  gap: 32px;
`;

export const CircleLineContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Circle = styled.div<{ selected?: boolean }>`
  height: 24px;
  width: 24px;
  text-align: center;
  line-height: 21px;
  border-radius: 12px;
  font-size: 13px;
  font-family: var(--body-font-family);
  background-color: ${props =>
    props.selected ? props.theme.primaryColor : colorTokens.grey1837};
  border: 1px solid;
  border-color: ${props =>
    props.selected ? props.theme.primaryColor : colorTokens.grey1740};
`;

export const Line = styled.div<{ visible?: boolean; length?: number }>`
  width: 1px;
  height: ${props => (props.length ? props.length : 30)}px;
  background-color: ${colorTokens.grey1740};
  visibility: ${props => (props.visible ? "visible" : "hidden")};
  align-self: center;
`;

export const FullHeightLine = styled(Line)`
  flex-grow: 1;
`;
