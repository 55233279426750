import React, { useCallback, useMemo } from "react";

import { colors } from "../../../../../cssConstants";
import ColorPicker from "../../../../common/ColorPicker";
import { useComponentConfigContext } from "../common/ComponentConfigContext";
import { ConfigSection, Field } from "../common/ConfigPanel";

import { ProgressComponentConfigState } from "./types";

export default function DesignConfig() {
  const context = useComponentConfigContext();
  const { dispatch } = context;
  const state = context.state as ProgressComponentConfigState;

  const themeProperties = useMemo(() => {
    return (
      state.draftComponent.properties.theme_properties || {
        color: colors.primaryColor
      }
    );
  }, [state.draftComponent.properties.theme_properties]);

  const updateThemeProperties = useCallback(
    change => {
      dispatch({
        type: "UPDATE_COMPONENT_THEME_PROPERTIES",
        payload: {
          properties: {
            ...themeProperties,
            ...change
          }
        }
      });
    },
    [dispatch, themeProperties]
  );

  return (
    <ConfigSection title="Style">
      <Field>
        <label>Fill</label>
        <ColorPicker
          value={themeProperties["color"]}
          size="small"
          onChange={value => {
            updateThemeProperties({ color: value });
          }}
        />
      </Field>
    </ConfigSection>
  );
}
