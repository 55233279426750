import React from "react";

import { AutoComplete, Button, Checkbox, Modal } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { SelectValue } from "antd/lib/select";
import { ApolloError } from "apollo-client";
import _ from "lodash";
import pluralize from "pluralize";
import { ExecutionResult } from "react-apollo";
import styled from "styled-components";

import { H6, B3, ErrorField } from "../../../components/common/StyledComponents";
import { useConfigContext } from "../../../ConfigContext";
import { Size, SpacingUnit } from "../../../cssConstants";
import { UserNode, OrganizationInviteNode } from "../../../types";
import useAuthUser from "../../common/hooks/useAuthUser";
import Message from "../../common/Message";
import LicenseNoSeatsWarning from "../../license/LicenseNoSeatsWarning";
import { validateEmail } from "../../util/ClientValidator";
import { fromGlobalId } from "../../util/graphql";
import { isInternalEmail } from "../../util/users";
import { AdditionalSeatsSummary } from "../Billing/checkout/AdditionalSeatsSummary";
import RolesDropdown, { RoleDropdownTypes } from "../roles/RolesDropdown";

import ErrorPanel, { InviteError } from "./ErrorPanel";
import { Data, useInviteUsers } from "./queries/InviteUsers";

const StyledAutoComplete = styled(AutoComplete)`
  width: 100%;
`;

const Results = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: ${props => props.theme.borderRadiusmd};
  border: solid 1px #d9d9d9;
  margin-top: 24px;
`;

const ResultSummary = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 18px;
  padding-bottom: 18px;
  width: 100%;

  ${(props: { isSelected?: boolean }) =>
    props.isSelected && `background-color: rgba(91,85,184,0.26);`}
`;

const ResultRecordContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 53px;
  padding-left: 20px;
  padding-right: 40px;
  border-bottom: solid 1px #d9d9d9;

  @media (max-width: ${Size.md}) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: auto;
    padding-top: ${SpacingUnit.md};
    padding-bottom: ${SpacingUnit.md};

    .rolesDropdown {
      margin-top: ${SpacingUnit.sm};
    }
  }
`;

interface InvitedUser {
  email: string;
  roleId?: string;
  isSelected: boolean;
}

interface ResultRecordProps {
  user: InvitedUser;
  roleChangeHandler: (roleId: string) => void;
  onSelectChange: (e: CheckboxChangeEvent) => void;
}

const ResultRecord = (props: ResultRecordProps) => {
  const user = props.user;
  return (
    <ResultRecordContainer>
      <Checkbox
        className="inviteModalUserCheckbox"
        checked={user.isSelected}
        onChange={props.onSelectChange}
      >
        {user.email}
      </Checkbox>
      <RolesDropdown
        handleRoleSelected={props.roleChangeHandler}
        type={RoleDropdownTypes.Link}
        selectItemByDefault={true}
        autoClearSelection={false}
      />
    </ResultRecordContainer>
  );
};

// exporting constants for tests
export const INVITE_PREFIX = "Invite: ";
export const AUTOCOMPLETE_ERROR = "To invite someone new, please enter an email.";
export const EXISTING_USER_ERROR =
  "You cannot invite someone who's already been added.";

interface InviteModalContentsProps {
  dataLoading: boolean;
  users: UserNode[];
  invites: OrganizationInviteNode[];
  handleCancel: () => void;
  handleOk: () => void;
  visible: boolean;
  defaultValue?: string;
  subscription?: {
    quantity: number;
    plan: {
      amount: number;
      interval: string;
      product: {
        name: string;
      };
    };
  };
}

function userToAutoCompleteOption(user: UserNode) {
  return (
    <AutoComplete.Option disabled key={user.email} value={user.email}>
      {user.firstName} {user.lastName} - {user.email} (already added)
    </AutoComplete.Option>
  );
}

function organizationInviteToAutoCompleteOption(invite: OrganizationInviteNode) {
  return (
    <AutoComplete.Option disabled key={invite.email} value={invite.email}>
      {invite.email} (already added)
    </AutoComplete.Option>
  );
}

const InviteModalContents = (props: InviteModalContentsProps) => {
  const { users, invites, subscription } = props;

  const { isOnPrem, license } = useConfigContext();
  const [filteredDataSource, setFilteredDataSource] = React.useState<any[]>([]);
  const [selectedUsers, setSelectedUsers] = React.useState<InvitedUser[]>(
    props.defaultValue ? [{ email: props.defaultValue, isSelected: true }] : []
  );

  const [searchText, setSearchText] = React.useState<string>("");
  const [needsAdditionalSeats, setNeedsAdditionalSeats] =
    React.useState<boolean>(false);
  const [autocompleteErrorMessage, setAutocompleteErrorMessage] =
    React.useState<string>("");
  const [errorMessage, setErrorMessage] = React.useState<string>("");

  const { authUser } = useAuthUser();

  const handleAutoCompleteChange = (value: any) => {
    const valueStr = value.toString();
    if (valueStr.indexOf(INVITE_PREFIX) > -1) {
      setSearchText("");
    } else {
      setSearchText(value);
    }
  };

  const dataSourceOptions = [...users, ...invites];

  const handleAutoCompleteSearch = (value: any) => {
    const similarItems = dataSourceOptions.filter(dataSourceItem => {
      return dataSourceItem.email.toLowerCase().indexOf(value.toLowerCase()) > -1;
    });

    const emailExists = dataSourceOptions.some(
      item => item.email.toLowerCase() === value.toLowerCase()
    );
    if (validateEmail(value) && !emailExists) {
      setFilteredDataSource([`${INVITE_PREFIX}${value}`]);
    } else if (similarItems.length === 1 && validateEmail(value) && emailExists) {
      setAutocompleteErrorMessage(EXISTING_USER_ERROR);
      setFilteredDataSource([]);
    } else if (!value || similarItems.length === 0) {
      setFilteredDataSource([]);
      setAutocompleteErrorMessage(AUTOCOMPLETE_ERROR);
    } else {
      const autocompleteOptions = similarItems.map(ds => {
        const nodeType = fromGlobalId(ds.id)[0];

        if (nodeType === "OrganizationInviteNode") {
          return organizationInviteToAutoCompleteOption(ds as OrganizationInviteNode);
        } else {
          return userToAutoCompleteOption(ds as UserNode);
        }
      });
      setFilteredDataSource(autocompleteOptions);
    }
  };

  const onAutoCompleteSelect = (value: SelectValue) => {
    const users = _.clone(selectedUsers);
    const valueStr = value.toString();
    const email =
      valueStr.indexOf(INVITE_PREFIX) > -1
        ? valueStr.substring(INVITE_PREFIX.length)
        : valueStr;
    const alreadyAdded =
      users.findIndex(user => {
        return user.email === email;
      }) > -1;
    if (!alreadyAdded) {
      users.push({
        email,
        isSelected: true
      });
      setSelectedUsers(users);
    }
  };

  const handleCancel = () => {
    setSelectedUsers([]);
    props.handleCancel();
  };

  const handleRoleChange = (selectedUserEmail: string, roleId: string) => {
    const users = _.clone(selectedUsers);
    const selectedUserIndex = users.findIndex(user => {
      return user.email === selectedUserEmail;
    });
    users[selectedUserIndex].roleId = roleId;
    setSelectedUsers(users);
  };

  const handleSelectChange = (selectedUserEmail: string, e: CheckboxChangeEvent) => {
    const users = _.clone(selectedUsers);
    const selectedUserIndex = users.findIndex(user => {
      return user.email === selectedUserEmail;
    });
    users[selectedUserIndex].isSelected = e.target.checked;
    setSelectedUsers(users);
  };

  const handleCompleted = () => {
    setSelectedUsers([]);
    setFilteredDataSource([]);
    setErrorMessage("");
    props.handleOk();
  };

  const handleError = (error: ApolloError) => {
    const text = error.graphQLErrors ? error.graphQLErrors[0].message : "";
    setErrorMessage(text);
  };

  const [inviteUsers, { loading }] = useInviteUsers({
    refetchQueries: ["AllInvitesQuery"],
    onCompleted: handleCompleted,
    onError: handleError
  });

  if (props.dataLoading) {
    return null;
  }

  const handleSubmit = () => {
    setNeedsAdditionalSeats(false);

    const users = selectedUsers.filter(user => user.isSelected);
    const missingRoleId = users.find(user => !user.roleId);

    if (missingRoleId) {
      setErrorMessage("You must assign a role to each user you are inviting.");
    } else {
      inviteUsers({
        variables: {
          users: users.map(u => ({
            email: u.email,
            roleId: u.roleId!
          }))
        }
      })
        .then((result: ExecutionResult<Data>) => {
          const userInviteErrors = (
            result.data?.inviteUsers.inviteResults || []
          ).filter(inviteResult => !!inviteResult.error) as InviteError[];

          if (result.errors || result.data?.inviteUsers?.error) {
            const msg = result.errors
              ? result.errors.map((e: any) => e.message).join(" ")
              : result.data?.inviteUsers.error;

            Message.error(`An error occurred while inviting users: ${msg}`, 10);
          } else if (userInviteErrors.length) {
            Message.error(<ErrorPanel errors={userInviteErrors} />, 10);
          }
        })
        .catch(() => {
          Message.error("An error occurred while inviting. Please, try again.");
        });
    }
  };

  const selectedUsersCount = selectedUsers.filter(user => user.isSelected).length;
  const activeUsers = users.filter(
    user => user.status === "active" && !isInternalEmail(user.email)
  );
  const activeInvites = invites.filter(invite => !isInternalEmail(invite.email));

  const totalUserCount = activeUsers.length + activeInvites.length;
  const saasSeatCount = subscription?.quantity;
  const saasSeatsAvailable = saasSeatCount
    ? saasSeatCount - totalUserCount - selectedUsersCount
    : null;

  const inviteBtnText =
    saasSeatsAvailable && saasSeatsAvailable < 0
      ? needsAdditionalSeats
        ? "Confirm purchase"
        : "Continue"
      : "Invite";

  const footer = !isOnPrem
    ? [
        <Button
          key="cancel"
          onClick={() => {
            setNeedsAdditionalSeats(false);
            handleCancel();
          }}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          disabled={!selectedUsersCount || (isOnPrem && !license?.hasSeats)}
          loading={loading}
          onClick={() => {
            if (
              typeof saasSeatsAvailable !== "undefined" &&
              saasSeatsAvailable !== null &&
              saasSeatsAvailable < 0
            ) {
              if (needsAdditionalSeats) {
                setNeedsAdditionalSeats(false);
                handleSubmit();
              } else {
                setNeedsAdditionalSeats(true);
              }
            } else {
              handleSubmit();
            }
          }}
        >
          {inviteBtnText}
        </Button>
      ]
    : [
        <Button
          key="cancel"
          onClick={() => {
            handleCancel();
          }}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          disabled={!selectedUsersCount || (isOnPrem && !license?.hasSeats)}
          loading={loading}
          onClick={() => {
            handleSubmit();
          }}
        >
          Invite
        </Button>
      ];

  return (
    <Modal
      title={
        !isOnPrem && needsAdditionalSeats ? "Add additional seats" : "Invite Employees"
      }
      visible={props.visible}
      onCancel={() => handleCancel()}
      footer={footer}
    >
      <>
        {!isOnPrem && needsAdditionalSeats ? (
          <AdditionalSeatsSummary
            subscription={subscription}
            seatsRequired={Math.abs(saasSeatsAvailable || 0)}
            currentSeatCount={saasSeatCount}
          />
        ) : (
          <div>
            {isOnPrem && license && <LicenseNoSeatsWarning license={license} />}

            {!isOnPrem && authUser?.organization.licenseData?.userLimit && (
              <LicenseNoSeatsWarning
                license={{
                  hasSeats:
                    authUser?.organization.licenseData.userLimit - activeUsers.length >
                    0,
                  usedSeats: activeUsers.length,
                  seats: authUser?.organization.licenseData.userLimit
                }}
              />
            )}
            <p>
              We’ll send them an email with a link to set up their account. By default,
              users will only have access to your default environment.
            </p>
            <StyledAutoComplete
              dropdownClassName="inviteModalSearchDropdown"
              placeholder="Enter an email address."
              dataSource={filteredDataSource}
              onSelect={value => onAutoCompleteSelect(value)}
              onSearch={value => handleAutoCompleteSearch(value)}
              onChange={value => handleAutoCompleteChange(value)}
              value={searchText}
            />
            {!filteredDataSource.length && searchText && (
              <ErrorField>{autocompleteErrorMessage}</ErrorField>
            )}

            <Results>
              {selectedUsers.map((selectedUser: InvitedUser) => {
                return (
                  <ResultRecord
                    user={selectedUser}
                    key={selectedUser.email}
                    roleChangeHandler={(roleId: string) => {
                      handleRoleChange(selectedUser.email, roleId);
                    }}
                    onSelectChange={(e: CheckboxChangeEvent) => {
                      handleSelectChange(selectedUser.email, e);
                    }}
                  />
                );
              })}
              <ResultSummary
                className="inviteModalResultsSummary"
                isSelected={!!selectedUsersCount}
              >
                {selectedUsersCount} employee(s) selected.
              </ResultSummary>
            </Results>
            <ErrorField>{errorMessage}</ErrorField>
            {!isOnPrem && saasSeatsAvailable !== null && (
              <>
                <H6>
                  {saasSeatsAvailable && saasSeatsAvailable >= 0
                    ? saasSeatsAvailable
                    : 0}{" "}
                  {pluralize("seat", saasSeatsAvailable || 0)} available
                </H6>

                {typeof saasSeatsAvailable !== "undefined" &&
                  saasSeatsAvailable !== null &&
                  saasSeatsAvailable < 0 && <B3>Additional seats required</B3>}
              </>
            )}
          </div>
        )}
      </>
    </Modal>
  );
};

export default InviteModalContents;
