import { AttributeTypes } from "../../../../../constants";
import { SpaceComponentObject } from "../../../../../types";
import { DefaultValueType } from "../../constants";
import { getCommonDefaultProperties } from "../common/SelectBackedComponent/Config/reducer";
import { getCommonInputProperties, toProperty } from "../common/util";
import { hasInputComponentProperties } from "../util/util";

import { SpaceDropdownComponent, SpaceDropdownProperties } from "./types";

export const getComponentProperties = (
  properties: Partial<SpaceDropdownProperties> = {}
): SpaceDropdownProperties => {
  const isBoolean = properties.validation_type === toProperty(AttributeTypes.BOOL);
  return {
    ...properties,
    ...{
      columns: properties.columns || [],
      filters: properties.filters || [],
      order: properties.order || [],
      input_parameters: properties.input_parameters || []
    },
    ...getCommonDefaultProperties(properties),
    ...getCommonInputProperties(
      properties,
      isBoolean ? DefaultValueType.BOOLEAN_VALUE : DefaultValueType.TEXT_VALUE,
      isBoolean ? false : "",
      AttributeTypes.STRING,
      true
    )
  };
};

export function ensureDropdownComponent(
  component: SpaceComponentObject
): SpaceDropdownComponent {
  if (
    Array.isArray(component.properties.columns) &&
    Array.isArray(component.properties.filters) &&
    Array.isArray(component.properties.order) &&
    Array.isArray(component.properties.input_parameters) &&
    hasInputComponentProperties(component)
  ) {
    return component;
  }

  return {
    ...component,
    properties: getComponentProperties(component.properties)
  };
}
