import { AttributeTypes } from "../../../constants";
import { InputParameter } from "../../../types";

export enum Event {
  BEFORE_EXECUTE = "BEFORE_EXECUTE",
  BEFORE_ENTER = "BEFORE_ENTER"
}

export enum ActionType {
  CHANGE_ASSIGNEE = "CHANGE_ASSIGNEE",
  EXECUTE_FUNCTION = "EXECUTE_FUNCTION",
  OPEN_URL = "OPEN_URL",
  UPDATE_TASK = "UPDATE_TASK"
}

export enum AssigneeType {
  USER = "user",
  ROLE = "role",
  UNASSIGN = "unassign"
}

// FieldInputParameter has a fieldKey property that may be a string or null.
// null means that this input parameter is not linked to a field
// This can happen if a corresponding field does not exist. For
// example, "title" is a special input parameter that does not have
// a field.  It can also mean that the field no longer exists because
// it was removed outside of this UI which represents an error
// state.
export type FieldInputParameter = InputParameter & { fieldKey: string | null };

export interface ChangeAssigneeOptions {
  assigneeId: string | null | undefined;
  assigneeType: AssigneeType | undefined;
  useCurrent: boolean;
}

export interface ExecuteFunctionOptions {
  functionId: string | null;
  inputParameters: InputParameter[] | undefined;
}

export interface OpenUrlOptions {
  template: string;
}

export interface UpdateTaskOptions {
  inputParameters: InputParameter[] | undefined;
}

export type ActionOptions =
  | ChangeAssigneeOptions
  | ExecuteFunctionOptions
  | OpenUrlOptions
  | UpdateTaskOptions;

export interface Action<T extends ActionOptions = ActionOptions> {
  key: string;
  id?: string;
  type: ActionType | undefined;
  options: T | undefined;
}

export interface Field {
  key: string;
  id?: string;
  name: string;
  type: AttributeTypes;
  required: boolean;
}

export type EventActionIndexMap = Record<string, number[]>;

interface StateSubscriptions extends EventActionIndexMap {
  beforeEnter: number[];
}

export enum DispatcherType {
  STATE = "STATE",
  TRANSITION = "TRANSITION"
}

export interface Dispatcher<T extends EventActionIndexMap = EventActionIndexMap> {
  subscriptions: T;
}

export interface State extends Dispatcher<StateSubscriptions> {
  id?: string;
  key: string;
  name: string;
  color: string;
  isArchive: boolean;
  assignmentExpanded?: boolean; // If true, assignment is expanded in UI
}

interface TransitionSubscriptions extends EventActionIndexMap {
  beforeExecute: number[];
}

export interface Transition extends Dispatcher<TransitionSubscriptions> {
  key: string;
  id?: string;
  fromStateKey: string;
  toStateKey: string | undefined;
  name: string;
  isPrimary: boolean;
  expanded?: boolean; // If true, expanded in UI
}

export enum Tab {
  GENERAL = "general",
  DATA = "data",
  STATES = "states"
}
