import React from "react";

import styled from "styled-components";

import { Urls } from "../../../constants";
import intercom from "../../../integrations/intercom/intercom";
import Spacer from "../../common/Spacer";
import { B3, H2, H6 } from "../../common/StyledComponents";
import Modal, { FooterLink } from "../common/Modal";

import ChatIcon from "./chat_icon.svg";
import DemoIcon from "./demo_icon.svg";
import OfficeHoursIcon from "./office_hours_icon.svg";

interface ContactModalProps {
  onClose: () => void;
}

export default function ContactModal({ onClose }: ContactModalProps) {
  return (
    <Modal
      visible
      footer={
        <>
          Need help getting started?&nbsp;
          <FooterLink href={Urls.DOCUMENTATION} target="_blank">
            View our documentation
          </FooterLink>
        </>
      }
      onClose={onClose}
    >
      <H2>Get in touch</H2>
      <Grid>
        <Section>
          <Spacer size="md" />
          <IconWrapper>
            <ChatIcon />
          </IconWrapper>
          <Spacer size="xl" />
          <H6>Send a message</H6>
          <Spacer size="xs" />
          <StyledB3>For general and technical questions about Internal</StyledB3>
          <Spacer size="md" />
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            onClick={() => {
              intercom.show();
            }}
          >
            Send a message
          </a>
        </Section>
        <Section>
          <Spacer size="md" />
          <IconWrapper>
            <OfficeHoursIcon />
          </IconWrapper>
          <Spacer size="xl" />
          <H6>Join office hours</H6>
          <Spacer size="xs" />
          <StyledB3>
            Sign up for weekly office hours for in-depth product support
          </StyledB3>
          <Spacer size="md" />
          <a href="https://calendly.com/meet-internal" target="_blank" rel="noreferrer">
            Sign up
          </a>
        </Section>
        <Section>
          <Spacer size="md" />
          <IconWrapper>
            <DemoIcon />
          </IconWrapper>
          <Spacer size="xl" />
          <H6>Schedule a call</H6>
          <Spacer size="xs" />
          <StyledB3>Chat live 1:1 for a demo, or help with a specific issue</StyledB3>
          <Spacer size="md" />
          <a href="https://calendly.com/d/hxz-fhf-5ky" target="_blank" rel="noreferrer">
            Schedule call
          </a>
        </Section>
      </Grid>
    </Modal>
  );
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr)); minmax(0, 1fr);
  grid-gap: ${props => props.theme.spacerlg};
  align-items: stretch;
`;

const Section = styled.section`
  padding: ${props => props.theme.spacerlg} 7.5%;
  background: rgba(41, 41, 45, 0.04);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
`;

const IconWrapper = styled.div`
  height: 48px;
  & > svg {
    height: 100%;
  }
`;

const StyledB3 = styled(B3)`
  color: ${props => props.theme.surfaceSecondary};
  min-height: 57px; /* 3 lines */
`;
