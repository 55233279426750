import React from "react";

import styled from "styled-components";

import { ComponentConfigProps } from "../../../../../../types";
import { ComponentConfigState } from "../../../../types";
import BaseComponentConfigSection from "../../common/BaseComponentConfigSection";
import {
  ComponentConfigContextContainer,
  useComponentConfigContext
} from "../../common/ComponentConfigContext";
import ValidationError from "../../common/ComponentConfigContext/ValidationError";
import { Field } from "../../common/ConfigPanel/styledComponents";
import NameFields from "../../common/NameFields";
import { DebouncedTemplateEditor, Height } from "../../common/TemplateEditor";
import CloudStorageConfig from "../../common/useCloudStorage";
import VisibilityRulesManagerSection from "../../common/VisibilityRulesManager";
import { DocumentViewerConfigState } from "../types";

export const ConfigPopoverField = styled.div`
  max-width: 60%;
  margin-bottom: ${props => props.theme.spacerxl};
`;

export const Label = styled.label`
  margin-bottom: ${props => props.theme.spacerlg};
  font-size: ${props => props.theme.largeFontSize};
`;

function isDocumentViewerConfigState(
  state: ComponentConfigState
): state is DocumentViewerConfigState {
  return state.type === "DOCUMENT_VIEWER";
}

export function ensureDocumentViewerConfigState(
  state: ComponentConfigState
): DocumentViewerConfigState {
  if (isDocumentViewerConfigState(state)) return state;

  throw new Error("Expected DocumentViewerConfigState");
}

export default function Config({ slug }: ComponentConfigProps) {
  return (
    <ComponentConfigContextContainer
      dataConfig={<DocumentViewerConfig />}
      slug={slug}
    />
  );
}

export function DocumentViewerConfig() {
  const context = useComponentConfigContext();
  const state = ensureDocumentViewerConfigState(context.state);
  const dispatch = context.dispatch;
  const { draftComponent } = state;

  return (
    <>
      <BaseComponentConfigSection>
        <NameFields shouldRenderTitle />
        <Field>
          <Label>URL Template</Label>
          <DebouncedTemplateEditor
            data-test="template"
            value={draftComponent.properties.template}
            placeholder="https://example.com/${row.data.filename}" // eslint-disable-line
            minHeight={Height.Medium}
            onChange={template =>
              dispatch({
                type: "MERGE_DRAFT_COMPONENT",
                payload: {
                  change: {
                    properties: { template }
                  }
                }
              })
            }
          />
          <ValidationError field="TEMPLATE" />
        </Field>
      </BaseComponentConfigSection>
      <CloudStorageConfig state={state} dispatch={dispatch} errors={context.errors} />
      <VisibilityRulesManagerSection />
    </>
  );
}
