import React from "react";

import { ComponentConfigProps } from "../../../../../../types";
import BaseComponentConfigSection from "../../common/BaseComponentConfigSection";
import { ComponentConfigContextContainer } from "../../common/ComponentConfigContext";
import { TerseComponentConfigContextContainer } from "../../common/ComponentConfigContext/ComponentConfigContext";
import { ConfigSection } from "../../common/ConfigPanel";
import DefaultValueField from "../../common/DefaultValueField";
import HardcodedOptionFields from "../../common/HardcodedOptionFields";
import InputComponentNameFields from "../../common/InputComponentNameFields";
import ValidationField from "../../common/ValidationField";
import { ValidationRulesSection } from "../../common/ValidationRulesConfigSection/ValidationRulesConfigSection";
import VisibilityRulesManagerSection from "../../common/VisibilityRulesManager";

import DesignConfig from "./DesignConfig";

export default function SpaceRadioButtonConfig({ slug }: ComponentConfigProps) {
  return (
    <ComponentConfigContextContainer
      dataConfig={React.useMemo(
        () => (
          <SpaceRadioButtonConfigContent />
        ),
        []
      )}
      designConfig={<DesignConfig />}
      slug={slug}
    />
  );
}

export function TerseConfig({ slug }: ComponentConfigProps) {
  return (
    <TerseComponentConfigContextContainer slug={slug}>
      <HardcodedOptionFields showAllowNull />
      <DefaultValueField placeholder="${table1.selectedRow.data.id}" />
      <ValidationField isTerse />
    </TerseComponentConfigContextContainer>
  );
}

export function InlineConfig({ slug }: ComponentConfigProps) {
  return (
    <TerseComponentConfigContextContainer slug={slug}>
      <InputComponentNameFields isInline />
      <HardcodedOptionFields showAllowNull />
      <DefaultValueField placeholder="${table1.selectedRow.data.id}" />
      <ValidationField isTerse />
    </TerseComponentConfigContextContainer>
  );
}

function SpaceRadioButtonConfigContent() {
  return (
    <>
      <BaseComponentConfigSection>
        <InputComponentNameFields />
      </BaseComponentConfigSection>
      <ConfigSection title="Radio Button Options">
        <HardcodedOptionFields showAllowNull />
        <DefaultValueField placeholder="${table1.selectedRow.data.id}" />
        <ValidationField />
      </ConfigSection>
      <ValidationRulesSection />
      <VisibilityRulesManagerSection />
    </>
  );
}
