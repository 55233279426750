import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef
} from "react";

import { unflatten } from "flat";
import { uniqueId, isObjectLike } from "lodash";

import useCodeSandbox from "./useCodeSandbox";

const DEBOUNCE = 5;

type EvalJob = [string, any, (val: any) => void, (val: any) => void];

export interface EvaluaterContextValue {
  evaluate: (expression: string, input: any, key: string) => Promise<any>;
  getConsoleError: (e: unknown) => unknown;
}

export const EvaluaterContext = createContext<EvaluaterContextValue>({
  evaluate: (_expression: string, _input: any, _key: string) =>
    new Promise(res => res(null as any)),
  getConsoleError: (_e: unknown) => null as unknown
});

const initialState = {
  batchId: ""
};
function reducer(
  state: typeof initialState,
  action: {
    type: "ENQUEUE_EVAL";
    payload: {
      batchId: string;
    };
  }
) {
  switch (action.type) {
    case "ENQUEUE_EVAL": {
      return {
        batchId: action.payload.batchId
      };
    }
    default:
      return state;
  }
}

// Some inputs are provided in a flattened format, but in most cases now
// the full object is provided. The full objects can be very large, so
// only unflatten if necessary.
function maybeUnflatten(obj: Record<string, any>) {
  if (isObjectLike(obj) && Object.keys(obj).some(key => key.includes(".")))
    return unflatten(obj);
  return obj;
}

export default function EvaluaterContextContainer({
  debounce = DEBOUNCE,
  children
}: {
  debounce?: number;
  children: React.ReactNode;
}) {
  const { evaluateExpressions, getConsoleError } = useCodeSandbox();
  const [state, dispatch] = useReducer(reducer, initialState);
  const batch = useRef<Map<string, EvalJob>>(new Map());

  const evaluate = useCallback((expression: string, input: any, evalKey: string) => {
    let resolver = (_result: any) => {};
    let rejecter = (_err: any) => {};
    const promise = new Promise((res, rej) => {
      resolver = res;
      rejecter = rej;
    });
    if (expression === null) {
      setImmediate(() => {
        resolver(null);
      });
    } else {
      batch.current.set(evalKey, [expression, input, resolver, rejecter]);
      dispatch({
        type: "ENQUEUE_EVAL",
        payload: { batchId: uniqueId("eval_batch") }
      });
    }
    return promise;
  }, []);

  useEffect(() => {
    let timeoutHandle: number | null = null;
    async function runBatch() {
      const expressions: string[] = [];
      const inputs: any[] = [];
      const resolvers: Array<(val: any) => void> = [];
      const rejecters: Array<(val: any) => void> = [];
      batch.current.forEach(([expression, input, resolver, rejecter]) => {
        expressions.push(expression);
        inputs.push(maybeUnflatten(input));
        resolvers.push(resolver);
        rejecters.push(rejecter);
      });
      batch.current.clear();
      const result = (await evaluateExpressions(expressions, inputs, true)) as any[];
      result.forEach((val, i) => {
        if (val !== null && typeof val === "object" && "error" in val) {
          rejecters[i](val.error);
        } else {
          resolvers[i](val);
        }
      });
    }
    if (batch.current.size) {
      timeoutHandle = window.setTimeout(async () => {
        runBatch();
      }, debounce);
    }
    return () => {
      timeoutHandle && clearTimeout(timeoutHandle);
    };
  }, [state.batchId, debounce, evaluateExpressions]);

  const value = useMemo(
    () => ({ evaluate, getConsoleError }),
    [evaluate, getConsoleError]
  );

  return (
    <EvaluaterContext.Provider value={value}>{children}</EvaluaterContext.Provider>
  );
}

export const useEvaluaterContext = () => {
  const evalKey = useRef(uniqueId("evalKey"));
  const { evaluate: _evaluate, ...rest } = useContext(EvaluaterContext);
  const evaluate = useCallback(
    (expression: string, input: any, postFix = "") =>
      _evaluate(expression, input, evalKey.current + postFix),
    [_evaluate]
  );
  return useMemo(
    () => ({
      ...rest,
      evaluate
    }),
    [evaluate, rest]
  );
};
