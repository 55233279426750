import React from "react";

import { Select } from "antd";
import produce from "immer";

import { AttributeTypes } from "../../../../../../../constants";
import { Filter, ValueFilter, FiltersOption } from "../../../../../../../types";
import { ConfigSection } from "../../ConfigPanel";

import FilterOperator from "./FilterOperator";
import FilterValue from "./FilterValue";
import * as styled from "./StyledComponents";

export interface Props {
  header: string;
  idx: number;
  filtersOptions: FiltersOption[];
  filter: Filter;
  onChange: (filter: Filter) => void;
  setFilterValueValidity: (idx: number, valid: boolean) => void;
}

export default function FilterDetails({
  idx,
  header,
  filtersOptions,
  filter,
  onChange,
  setFilterValueValidity
}: Props) {
  const filtersOption = filtersOptions.find(
    attr => attr.sourceName === filter.attribute
  );

  const sortedFiltersOptions = React.useMemo(() => {
    return [...filtersOptions].sort((a, b) => (a.name > b.name ? 1 : -1));
  }, [filtersOptions]);

  const onAttributeSelect = (value: string) => {
    const viewAttribute = filtersOptions.find(attr => attr.sourceName === value)!;
    onChange(
      produce(filter, (nextFilter: ValueFilter) => {
        nextFilter.attribute = value;
        nextFilter.value =
          viewAttribute.sourceType === AttributeTypes.BOOL ? false : undefined;
      })
    );
  };

  return (
    <ConfigSection title={header}>
      <styled.Content>
        <Select
          getPopupContainer={trigger => trigger.parentNode as HTMLElement}
          className="attributeSelect"
          value={filtersOption ? filtersOption.sourceName : ""}
          onChange={onAttributeSelect}
          data-test="attributeSelect"
        >
          {sortedFiltersOptions.map(attr => (
            <Select.Option
              key={attr.sourceName}
              disabled={(attr.operators || []).length === 0}
              value={attr.sourceName}
            >
              {attr.sourceName}
            </Select.Option>
          ))}
        </Select>
        <FilterOperator
          filter={filter}
          onChange={onChange}
          supportedOperators={filtersOption?.operators || []}
        />
        <FilterValue
          setFilterValueValidity={(valid: boolean) =>
            setFilterValueValidity(idx, valid)
          }
          valueType={filtersOption?.sourceType}
          onChange={onChange}
          filter={filter}
        />
      </styled.Content>
    </ConfigSection>
  );
}
