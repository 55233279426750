import React from "react";

import { useSpaceConfigContext } from "../../SpaceConfig/SpaceConfigContext";
import { useComponentContext } from "../../SpaceRoot/SpaceComponent/contexts/ComponentContext";
import { ElementLayout, LayoutUnit, parseCssUnit } from "../util";

function getOwnRect(slug: string) {
  return (
    document.querySelector(`#element-${slug}`)?.getBoundingClientRect() || new DOMRect()
  );
}

function getLayoutContextRect(slug: string) {
  let closestLayoutContext = document
    .querySelector(`#element-${slug}`)
    ?.closest(".layoutContext");

  if (closestLayoutContext === undefined) {
    closestLayoutContext = document.querySelector(`.layoutContext`);
  }

  return closestLayoutContext?.getBoundingClientRect() || new DOMRect();
}

export default function useElementTransformer() {
  const {
    component: { slug }
  } = useComponentContext();
  const { getComponentSpaceConfig, dispatch: spaceConfigDispatch } =
    useSpaceConfigContext();
  const elementLayout = React.useMemo(
    () => getComponentSpaceConfig(slug).elementLayouts.get(slug),
    [slug, getComponentSpaceConfig]
  );

  const toRelativePoint = React.useCallback(
    function toRelativePoint(pixelPoint: DOMPoint) {
      const contextRect = getLayoutContextRect(slug);
      return new DOMPoint(
        (pixelPoint.x / contextRect.width) * 100,
        (pixelPoint.y / contextRect.height) * 100
      );
    },
    [slug]
  );

  const toAbsoluteWidth = React.useCallback(
    function toAbsoluteWidth(relativeWidth) {
      const contextRect = getLayoutContextRect(slug);
      return (relativeWidth * contextRect.width) / 100;
    },
    [slug]
  );

  const toAbsoluteHeight = React.useCallback(
    function toAbsoluteHeight(relativeHeight) {
      const contextRect = getLayoutContextRect(slug);
      return (relativeHeight * contextRect.height) / 100;
    },
    [slug]
  );

  const toAbsolutePoint = React.useCallback(
    function toAbsolutePoint(relativePoint: DOMPoint) {
      return new DOMPoint(
        toAbsoluteWidth(relativePoint.x),
        toAbsoluteHeight(relativePoint.y)
      );
    },
    [toAbsoluteHeight, toAbsoluteWidth]
  );

  // Given an absolute layout, generates a layout converting to target units
  const convertLayout = React.useCallback(
    (
      sourceLayout,
      targetUnits = {
        width: LayoutUnit.PIXEL,
        height: LayoutUnit.PIXEL,
        top: LayoutUnit.PIXEL,
        left: LayoutUnit.PIXEL
      }
    ) => {
      const toConvert = {
        width: sourceLayout.width,
        height: sourceLayout.height,
        top: sourceLayout.top,
        left: sourceLayout.left
      };
      ["width", "height", "top", "left"].forEach(d => {
        const sourceUnit = parseCssUnit(sourceLayout[d] || "0" + LayoutUnit.PIXEL);
        const targetUnit = targetUnits[d] || LayoutUnit.PIXEL;
        if (sourceUnit !== targetUnit) {
          let convert: (val: DOMPoint) => DOMPoint = val => val;
          switch (targetUnit) {
            case LayoutUnit.PIXEL:
              convert = toAbsolutePoint;
              break;
            case LayoutUnit.PERCENTAGE:
              convert = toRelativePoint;
              break;
            case LayoutUnit.AUTO:
              break;
            default:
              throw new Error("Unexpected unit conversion");
          }
          if (toConvert[d as keyof typeof toConvert] === LayoutUnit.AUTO) {
            return;
          }
          if (d === "width" || d === "left") {
            toConvert[d] =
              targetUnit === LayoutUnit.AUTO
                ? LayoutUnit.AUTO
                : `${
                    convert(new DOMPoint(parseFloat(toConvert[d]), 0)).x
                  }${targetUnit}`;
          } else if (d === "height" || d === "top") {
            toConvert[d] =
              targetUnit === LayoutUnit.AUTO
                ? LayoutUnit.AUTO
                : `${
                    convert(new DOMPoint(0, parseFloat(toConvert[d]))).y
                  }${targetUnit}`;
          }
        }
      });
      const merged = {
        ...sourceLayout,
        ...toConvert
      };

      return new ElementLayout(merged);
    },
    [toRelativePoint, toAbsolutePoint]
  );

  const layout = React.useMemo(() => {
    return new ElementLayout(elementLayout);
  }, [elementLayout]);

  const transform = React.useCallback(
    (layoutChanges: Partial<ElementLayout>) => {
      spaceConfigDispatch({
        type: "UPDATE_COMPONENT_LAYOUT",
        payload: { slug, layout: layoutChanges }
      });
    },
    [slug, spaceConfigDispatch]
  );

  const changeLayoutUnit = React.useCallback(
    (field: "width" | "height" | "top" | "left", unit: LayoutUnit) => {
      // Construct a Layout in px, converting AUTO if needed
      let pxLayout = new ElementLayout(elementLayout);
      const selectionDOMRect = getOwnRect(slug);
      if (pxLayout.width === LayoutUnit.AUTO) {
        pxLayout.width = selectionDOMRect.width + LayoutUnit.PIXEL;
      }
      if (pxLayout.height === LayoutUnit.AUTO) {
        pxLayout.height = selectionDOMRect.height + LayoutUnit.PIXEL;
      }
      pxLayout = convertLayout(pxLayout);

      // Convert the field whose unit changed
      const targetUnits = {
        width: parseCssUnit(elementLayout?.width),
        height: parseCssUnit(elementLayout?.height),
        top: parseCssUnit(elementLayout?.top || "0" + LayoutUnit.PIXEL),
        left: parseCssUnit(elementLayout?.left || "0" + LayoutUnit.PIXEL),
        [field]: unit
      };
      const convertedLayout = convertLayout(pxLayout, targetUnits);
      transform(convertedLayout);
    },
    [elementLayout, slug, convertLayout, transform]
  );

  const result = React.useMemo(() => {
    return {
      layout,
      toRelativePoint,
      convertLayout,
      transform,
      changeLayoutUnit
    };
  }, [layout, toRelativePoint, convertLayout, transform, changeLayoutUnit]);

  return result;
}
