import { Binding, BindingShape, ObjectBinding } from "../../../../../../types";
import { getOption, Option } from "../../../../../common/BindingCascader";
import { BaseSpaceConfig } from "../../../../SpaceConfig/SpaceConfigContext/useSpaceConfig/reducer/reducer";
import { SpaceDefinition } from "../../../../types";
import { ComponentNode, RootNode } from "../../../RenderTreeContext";
import { getVariablesSchema } from "../../SubSpace/getSchema";

interface PackageLike {
  allowSelfBinding: boolean;
  allowAncestorBinding: boolean;
  isPseudoComponent?: boolean;
  getSchema: (node: ComponentNode, spaces: Map<string, SpaceDefinition>) => Binding[];
  getSelfSchemaShape?: (
    node: ComponentNode,
    spaces: Map<string, SpaceDefinition>
  ) => BindingShape;
}

export type SchemaGenerator = (node: ComponentNode) => Binding[];
export type PackageLocator = (node: ComponentNode) => PackageLike;
export type LabelProvider = (node: ComponentNode, isAncestor: boolean) => string;
export type ValueProvider = (node: ComponentNode, isAncestor: boolean) => string;

export const getOptions = (
  node: ComponentNode | undefined,
  findPackage: PackageLocator,
  spaces: Map<string, BaseSpaceConfig>,
  rootSpaceSlug: string,
  label: LabelProvider,
  value: ValueProvider = c => c.component.slug
): Option[] => {
  if (!node) return [];

  const root = getRootNode(node);
  const ancestorPath = hasParentComponent(node) ? getAncestors(node, root) : [];

  const pkg = findPackage(node);
  const excludes: ComponentNode[] = pkg.allowSelfBinding ? [] : [node];

  function _getSchema(n: ComponentNode) {
    const pkg = findPackage(n);
    if (pkg === undefined) return [];
    return pkg.getSchema(n, spaces);
  }

  function _getComponentNodeShape(n: ComponentNode) {
    const pkg = findPackage(n);
    if (pkg === undefined) return BindingShape.OBJECT;
    return pkg.getSelfSchemaShape
      ? pkg.getSelfSchemaShape(n, spaces)
      : BindingShape.OBJECT;
  }

  const ancestorOptions: Option[] = [];
  ancestorPath
    .filter(c => {
      const ancestorPkg = findPackage(c);
      return !excludes.includes(c) && ancestorPkg.allowAncestorBinding;
    })
    .forEach(c => {
      if (c.component.type === "SUB_SPACE") {
        ancestorOptions.push({
          label: `${c.component.name} params`,
          value: "params",
          bindingShape: BindingShape.OBJECT,
          children:
            (
              _getSchema(c).find(b => b.name === "params") as ObjectBinding
            )?.attributes?.map(getOption) || []
        });
      } else {
        // Paths from the subSpace component should not be accesible from the
        // subSpace members, as they include the subSpace's slug assigned by
        // the containing space, which break the encapsulation of the subSpace.
        ancestorOptions.push({
          label: label(c, true),
          value: value(c, true),
          bindingShape: _getComponentNodeShape(c),
          children: _getSchema(c).map(getOption)
        });
      }
    });

  const rootOptions = root.children
    .filter(c => !excludes.includes(c) && !ancestorPath.includes(c))
    .map<Option>(child => ({
      label: label(child, false),
      value: value(child, false),
      bindingShape: _getComponentNodeShape(child),
      children: _getSchema(child).map(getOption)
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const rootVariables = getVariablesSchema(spaces.get(rootSpaceSlug)!);
  const rootVariablesOption = {
    label: "Variables",
    value: "variables",
    bindingShape: BindingShape.OBJECT,
    children: rootVariables.attributes.map(getOption)
  };

  const opts = [...ancestorOptions, ...rootOptions];
  if (rootVariablesOption.children.length > 0) {
    opts.push(rootVariablesOption);
  }

  return opts;
};

const hasParentComponent = (node: ComponentNode | RootNode): boolean =>
  !!(node.parent && (node.parent as ComponentNode).component);

const getRootNode = (node: ComponentNode | RootNode | undefined): RootNode => {
  let n = node;
  while (n?.parent) {
    n = n.parent as ComponentNode | RootNode;
  }
  return n!;
};

// Returns ancestor path array inclusive of node and exclusive of stopNode: [node...stopNode)
const getAncestors = (
  node: ComponentNode | RootNode,
  stopNode: ComponentNode | RootNode
): ComponentNode[] => {
  const path = [];
  for (let n = node; n && n !== stopNode; n = n.parent as ComponentNode) {
    path.push(n as ComponentNode);
  }
  return path;
};
