import { WretchError } from "wretch";

import { ClientErrorResult } from "../../../types";

export const GENERIC_ERROR_MESSAGE =
  "Your changes could not be made. Please try again later.";

interface CustomErrorOptions {
  friendlyMessage?: string;
  cause?: Error;
}

export class CustomError extends Error {
  public friendlyMessage?: string;
  public cause?: Error;

  constructor(message: string, { friendlyMessage, cause }: CustomErrorOptions = {}) {
    super(message);
    this.name = this.constructor.name;
    this.friendlyMessage = friendlyMessage;
    this.cause = cause;
    Object.setPrototypeOf(this, CustomError.prototype);
  }
}

export class UnknownError extends CustomError {
  constructor(message: string, options?: CustomErrorOptions) {
    super(message, options);
    Object.setPrototypeOf(this, UnknownError.prototype);
  }
}

export class RemoteFunctionError extends CustomError {
  constructor(message: string, options?: CustomErrorOptions) {
    super(message, options);
    Object.setPrototypeOf(this, RemoteFunctionError.prototype);
  }
}

export class ClientError extends RemoteFunctionError {
  public details: ClientErrorResult;

  constructor(
    message: string,
    details: ClientErrorResult,
    options?: CustomErrorOptions
  ) {
    super(message, options);
    this.details = details;
    Object.setPrototypeOf(this, ClientError.prototype);
  }
}

export class PermissionError extends RemoteFunctionError {
  constructor(message: string, options?: CustomErrorOptions) {
    super(message, options);
    Object.setPrototypeOf(this, PermissionError.prototype);
  }
}

export class ExecutorClientError extends CustomError {
  public details: { message: string };
  public code: number;

  constructor(wretchError: WretchError, options?: CustomErrorOptions) {
    super(wretchError.text || "Something went wrong", {
      ...options,
      cause: wretchError
    });
    Object.setPrototypeOf(this, ExecutorClientError.prototype);
    this.message = wretchError.text
      ? JSON.parse(wretchError.text).error.detail
      : GENERIC_ERROR_MESSAGE;
    this.details = { message: this.message };
    this.code = wretchError.status;
  }
}
