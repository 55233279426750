import React from "react";

import { useStableSpaceContext } from "../../SpaceContext";
import EditModeInteractionBlocker from "../common/EditModeInteractionBlocker";
import Panel from "../common/Panel";
import { useComponentStateContext } from "../contexts/ComponentStateContext";
import { Props } from "../SpaceComponent";

import { SpaceIFrameComponent } from "./types";
import useEvaluate from "./useEvaluate";

export default function SpaceIFrame({ spaceComponent }: Props) {
  const customComponent = spaceComponent as SpaceIFrameComponent;
  const { template, title } = customComponent.properties;

  const { input } = useComponentStateContext();
  const { editMode } = useStableSpaceContext();
  const evaluated = useEvaluate(template, input, editMode);

  return (
    <EditModeInteractionBlocker>
      <Panel title={title}>
        <iframe
          frameBorder="0"
          title={title}
          width="100%"
          height="100%"
          src={evaluated}
        />
      </Panel>
    </EditModeInteractionBlocker>
  );
}
