import React from "react";

import { SortableItemCompact } from "../../../../../../common/SortableList";
import Spacer from "../../../../../../common/Spacer";
import {
  EventOption,
  EffectOption,
  EventType,
  EffectType,
  EventHandler
} from "../../../../../types";
import { useComponentConfigContext } from "../../ComponentConfigContext";
import { ConfigSection, ConfigPanelPopper } from "../../ConfigPanel";
import {
  useSpaceConfigPanelContext,
  ConfigPanelActionTypes
} from "../../ConfigPanel/ConfigPanelContext";
import { Field, Select, SelectOption } from "../../ConfigPanel/styledComponents";
import { OptionsComponent } from "../Effects/types";

interface EffectsManagerProps {
  eventOptions: Readonly<EventOption[]>;
}

type IndexedEventHandler = EventHandler & { originalIndex: number };
export default function EffectsManager({ eventOptions }: EffectsManagerProps) {
  const context = useComponentConfigContext();
  const eventHandlers: EventHandler[] = context.state.draftComponent.properties.effects;
  const state = context.state;

  const getEventHandlers = (type: EventType): IndexedEventHandler[] =>
    eventHandlers
      .filter(e => e.type === type)
      .map(e => ({
        ...e,
        originalIndex: eventHandlers.indexOf(e)
      }));

  return (
    <div>
      {eventOptions.map(eo => (
        <EventHandlersSection
          key={eo.type}
          eventOption={eo}
          eventHandlers={getEventHandlers(eo.type)}
          onAddEventHandler={newEffect => {
            context.dispatch({
              type: "MERGE_DRAFT_COMPONENT",
              payload: {
                change: {
                  properties: {
                    effects: [...state.draftComponent.properties.effects, newEffect]
                  }
                }
              }
            });
          }}
        />
      ))}
    </div>
  );
}

const getPopperKey = (eventHandlers: IndexedEventHandler[], idx: number) => {
  const effect = eventHandlers[idx];
  return `addEffect-${effect.type}-${idx}`;
};

function EventHandlersSection({
  eventOption,
  eventHandlers,
  onAddEventHandler
}: {
  eventOption: EventOption;
  eventHandlers: IndexedEventHandler[];
  onAddEventHandler: (newEventHandler: EventHandler) => void;
}) {
  const [activeIdx, setActiveIdx] = React.useState(-1);
  const { dispatch: configPanelDispatch } = useSpaceConfigPanelContext();

  React.useEffect(() => {
    if (activeIdx > -1 && activeIdx < eventHandlers.length) {
      const id = getPopperKey(eventHandlers, activeIdx);
      configPanelDispatch({
        type: ConfigPanelActionTypes.OPEN_POPPER,
        payload: {
          popperIdentifier: id
        }
      });
    }
  }, [activeIdx, eventHandlers, configPanelDispatch]);

  return (
    <div id={`${eventOption.type}-eventHandlersSection`}>
      <ConfigSection
        title={eventOption.name}
        onAdd={() => {
          onAddEventHandler({
            type: eventOption.type,
            effect: eventOption.defaultEffect
          });
          setActiveIdx(eventHandlers.length);
        }}
      >
        {eventHandlers.length === 0 ? (
          "No effects for this event"
        ) : (
          <EventHandlerList
            eventHandlers={eventHandlers}
            eventOption={eventOption}
            activeIdx={activeIdx}
            setActiveIdx={setActiveIdx}
          />
        )}
      </ConfigSection>
    </div>
  );
}

function EventHandlerList({
  eventHandlers,
  eventOption,
  activeIdx,
  setActiveIdx
}: {
  eventHandlers: IndexedEventHandler[];
  eventOption: EventOption;
  activeIdx: number;
  setActiveIdx: (idx: number) => void;
}) {
  const configPanelContext = useSpaceConfigPanelContext();
  // https://github.com/microsoft/TypeScript/issues/33591
  const effectOptions: Readonly<Array<EffectOption>> = eventOption.effects;

  const popperKey = activeIdx > -1 ? getPopperKey(eventHandlers, activeIdx) : undefined;
  const popperOpen =
    popperKey && configPanelContext.state.activePopperIdentifier === popperKey;

  return (
    <Field>
      {eventHandlers.map((handler, i) => (
        <EffectHandlerListItem
          key={`${handler.effect.type}-${i}`}
          handler={handler}
          effectOptions={effectOptions}
          popperKey={getPopperKey(eventHandlers, i)}
          idx={i}
          activeIdx={activeIdx}
          setActiveIdx={setActiveIdx}
        />
      ))}

      {popperOpen && popperKey && (
        <ConfigPanelPopper
          popperId={popperKey}
          popperReferenceElement={
            document.getElementById(`${popperKey}-section`) || undefined
          }
          onCancel={() => {
            setActiveIdx(-1);
            configPanelContext.dispatch({
              type: ConfigPanelActionTypes.CLOSE_POPPER
            });
          }}
        >
          <EffectForm
            eventHandler={eventHandlers[activeIdx]}
            eventOption={eventOption}
          />
        </ConfigPanelPopper>
      )}
    </Field>
  );
}

function EffectHandlerListItem({
  handler,
  effectOptions,
  popperKey,
  idx,
  activeIdx,
  setActiveIdx
}: {
  handler: IndexedEventHandler;
  effectOptions: Readonly<Array<EffectOption>>;
  popperKey: string;
  idx: number;
  activeIdx: number;
  setActiveIdx: (idx: number) => void;
}) {
  const configContext = useComponentConfigContext();
  const effect = effectOptions.find(eo => eo.type === handler.effect.type);
  if (!effect) {
    throw new Error(`Expected to find effect option of type: ${handler.effect.type}`);
  }

  const label =
    "useEffectLabel" in effect && typeof effect.useEffectLabel === "function"
      ? effect.useEffectLabel(handler.effect.options as any)
      : effect.name;

  const { originalIndex: _originalIndex, ...handlerCopy } = handler;
  const key = btoa(JSON.stringify(handlerCopy));
  const error = configContext.errors.find(err => err.key === key)?.message || null;

  return (
    <SortableItemCompact
      id={`${popperKey}-section`}
      key={`${popperKey}-section`}
      sortKey={popperKey}
      isSelected={activeIdx === idx}
      errorMessage={error}
      onClick={() => setActiveIdx(idx)}
      onRemove={
        "permanent" in effect && effect.permanent
          ? undefined
          : () => {
              const handlersCopy = [
                ...configContext.state.draftComponent.properties.effects
              ];
              handlersCopy.splice(handler.originalIndex, 1);
              configContext.dispatch({
                type: "MERGE_DRAFT_COMPONENT",
                payload: {
                  change: {
                    properties: {
                      effects: handlersCopy
                    }
                  }
                }
              });
            }
      }
    >
      {label}
    </SortableItemCompact>
  );
}

function EffectForm({
  eventHandler,
  eventOption
}: {
  eventHandler: IndexedEventHandler;
  eventOption: EventOption;
}) {
  const configContext = useComponentConfigContext();
  // https://github.com/microsoft/TypeScript/issues/33591
  const effects: Readonly<Array<EffectOption>> = eventOption.effects;
  if (!effects) return null;
  const selectedEffect = effects.find(a => a.type === eventHandler.effect.type)!;
  if (selectedEffect === undefined) {
    throw new Error(
      `Expected to find selectedEffect of type: ${eventHandler.effect.type}`
    );
  }
  const currHandler =
    configContext.state.draftComponent.properties.effects[eventHandler.originalIndex];
  function handleChange(changes: Partial<EventHandler["effect"]>) {
    const handlersCopy = [...configContext.state.draftComponent.properties.effects];
    if (!handlersCopy[eventHandler.originalIndex])
      throw new Error("Expected to find handler");
    handlersCopy[eventHandler.originalIndex] = {
      ...currHandler,
      effect: {
        ...currHandler.effect,
        ...changes
      }
    };
    // Reset effect options when changing effects type
    if (changes.type && typeof eventHandler.originalIndex === "number") {
      handlersCopy[eventHandler.originalIndex].effect.options = {};
    }
    configContext.dispatch({
      type: "MERGE_DRAFT_COMPONENT",
      payload: {
        change: {
          properties: {
            effects: handlersCopy
          }
        }
      }
    });
  }

  type OptionsValue = Parameters<typeof selectedEffect.Options>[0]["value"];
  function handleEffectOptionsChange(changes: Partial<OptionsValue>) {
    handleChange({
      options: {
        ...eventHandler.effect.options,
        ...changes
      }
    });
  }

  const Options = selectedEffect.Options as OptionsComponent<OptionsValue>;

  return (
    <ConfigSection>
      <h1>{eventOption.name}</h1>
      <Select
        value={selectedEffect.type}
        disabled={"permanent" in selectedEffect && selectedEffect.permanent === true}
        onChange={value => {
          handleChange({ type: value as EffectType });
        }}
        getPopupContainer={trigger => trigger.parentNode as HTMLElement}
      >
        {effects.map(eff =>
          "permanent" in eff && eff.permanent ? null : (
            <SelectOption key={eff.type}>{eff.name}</SelectOption>
          )
        )}
      </Select>
      <Spacer size="sm" />
      <Options
        value={eventHandler.effect.options}
        onChange={handleEffectOptionsChange}
      />
    </ConfigSection>
  );
}
