import { useEvaluaterContext } from "../../../../../../common/CodeSandbox/EvaluaterContext";
import { EventHandler } from "../../../../../types";
import { useComponentContext } from "../../../contexts/ComponentContext";
import { useComponentStateContext } from "../../../contexts/ComponentStateContext";
import { setVariable } from "../Effects/SetVariable";

export enum PointerEventType {
  CLICK = "click"
}

type PointerEvent = { type: PointerEventType.CLICK };

export default function usePointerEffects() {
  const { component } = useComponentContext();
  const componentStateContext = useComponentStateContext();
  const { evaluate } = useEvaluaterContext();
  const effects: EventHandler[] = component.properties.effects;

  function triggerEffects(ev: PointerEvent) {
    // TODO: `any` removeable after MODAL feature flag is removed.
    effects
      .filter(e => e.type === (ev.type as any))
      .forEach(({ effect }, idx) => {
        switch (effect.type) {
          case "set_variable": {
            const setVarEffect = effect;
            setVariable(
              setVarEffect,
              evaluate,
              componentStateContext.input,
              componentStateContext.setVariable,
              idx
            );
            break;
          }
        }
      });
  }

  return {
    triggerEffects
  };
}
