import { GlobalStyleVariables } from "../../../cssConstants";

export const RELATIVE_GRID_SIZE = 0.02;
export const BOUNDARY_PROXIMITY_AUTOSCROLL_THRESHOLD = 20;
export const EDGE_DRAG_ADJUST = 10;
// Default sizes are in % for widths and px for height
// widths assume a 2% gutter
export const DEFAULT_SIZES = {
  STANDARD: { width: "31%", height: "200px" },
  WIDE: { width: "50%", height: "400px" },
  TALL: { width: "31%", height: "400px" },
  INPUT: { width: "auto", height: "auto" },
  BUTTON: { width: "auto", height: "32px" },
  TEXT: { width: "auto", height: "21px" }
};
export const INPUT_MIN_WIDTH = 120;
export const MAX_NESTED_DIMENSIONS = {
  width: 240,
  height: GlobalStyleVariables.listItemMaxHeight
};
export const CONFIG_ICON_SIZE = { width: "80px", height: "32px" };
