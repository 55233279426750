import React, { useEffect } from "react";

import { get } from "lodash";

import { useComponentStateContext } from "../contexts/ComponentStateContext";
import SpaceComponent, { Props } from "../SpaceComponent";

import { SubSpaceComponent } from "./types";

export default function SubSpace({ spaceComponent, ...props }: Props) {
  const { input_parameters } = (spaceComponent as SubSpaceComponent).properties;
  const { input, variables, updateOutput } = useComponentStateContext();

  // Get input bindings and set to state as own output bindings
  useEffect(() => {
    updateOutput({
      params: Object.fromEntries(
        input_parameters.map(ip => [ip.name, get(input, ip.binding)])
      ),
      variables
    });
  }, [input, input_parameters, variables, updateOutput]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {spaceComponent.componentTreeNodes.map((ctn, idx) => (
        <SpaceComponent key={idx} spaceComponent={ctn} {...props} />
      ))}
    </div>
  );
}
