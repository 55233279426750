import moment from "moment";
import pluralize from "pluralize";

import { AutomationInput } from "../queries/SaveAutomation";
import { IntervalPeriod } from "../types";

import { ConfigState } from ".";

export const INTERVAL_PERIODS_WITH_START_END_TIMES = [
  IntervalPeriod.Minutes,
  IntervalPeriod.Hours
];

export const DEFAULT_TRIGGER_TIME = "12:00pm";
export const TIME_DISPLAY_FORMAT = "hh:mm a";
export const DATE_DISPLAY_FORMAT = "M/D/YYYY";
export const DATE_TIME_DISPLAY_FORMAT = "M/D/YYYY h:mm a";
const TIMESTAMP_SAVE_FORMAT = "YYYY-MM-DDTHH:mm:ssZ";

export type IntervalPeriodOption = {
  label: string;
  value: IntervalPeriod;
};

export const PERIOD_OPTIONS: IntervalPeriodOption[] = [
  {
    label: "Minutes",
    value: IntervalPeriod.Minutes
  },
  {
    label: "Hours",
    value: IntervalPeriod.Hours
  },
  {
    label: "Days",
    value: IntervalPeriod.Days
  },
  {
    label: "Weeks",
    value: IntervalPeriod.Weeks
  },
  {
    label: "Months",
    value: IntervalPeriod.Months
  }
];

const FREQUENCY_OPTIONS = {
  [IntervalPeriod.Minutes]: [5, 10, 15, 20, 30],
  [IntervalPeriod.Hours]: [1, 2, 4, 6, 8, 12],
  [IntervalPeriod.Days]: [1, 2, 3, 4, 5, 6],
  [IntervalPeriod.Weeks]: [1, 2, 3, 4],
  [IntervalPeriod.Months]: [1, 2, 3, 4, 6]
};

const PERIOD_UNIT = {
  [IntervalPeriod.Minutes]: "minute",
  [IntervalPeriod.Hours]: "hour",
  [IntervalPeriod.Days]: "day",
  [IntervalPeriod.Weeks]: "week",
  [IntervalPeriod.Months]: "month"
};

export const getFrequencyOptions = (frequency: IntervalPeriod) => {
  const options = FREQUENCY_OPTIONS[frequency];
  const unit = PERIOD_UNIT[frequency];
  return options.map(value => ({
    label: `${value} ${pluralize(unit, value)}`,
    value
  }));
};

export const getDaysOfWeek = () => {
  const options = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ];
  return options.map((label, index) => ({ label, value: index }));
};

export const getDaysOfMonth = () => {
  return [...Array(31).keys()].map(val => ({ label: val + 1, value: val + 1 }));
};

export const convertMomentToString = (datetime: moment.Moment | undefined) => {
  return datetime?.format(TIMESTAMP_SAVE_FORMAT);
};

export const getUtcTime = (time: moment.Moment) => {
  return moment.utc(time);
};

// TODO revisit this once we know how we plan to save the trigger time (in UTC or with timezone);
// might make sense to just have users enter UTC to avoid this conversion.
export const getLocalTime = (time: string | undefined) => {
  if (!time) {
    return undefined;
  }
  const [hour, minute] = time.split(":").map(val => parseInt(val));
  const utcTime = moment.utc({ hour, minute });
  const local = moment(utcTime).local();
  return convertMomentToString(local);
};

export const setTimeToMidnight = (datetime: moment.Moment) => {
  const newDateTime = datetime.clone();
  newDateTime.startOf("day");
  return newDateTime;
};

export const getSaveAutomationInput = (state: ConfigState): AutomationInput => {
  const utcTriggerTime = state.triggerTime
    ? getUtcTime(moment(state.triggerTime)).format("HH:mm")
    : undefined;
  return {
    ...state,
    triggerTime: utcTriggerTime,
    functionId: state.functionId || "",
    environmentIds: Array.from(state.environmentIds)
  };
};
