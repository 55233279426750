import React from "react";

import { isEqual } from "lodash";
import { unstable_batchedUpdates } from "react-dom";

import { SpaceComponentObject } from "../../../../types";
import { assertNever } from "../../../util/assertNever";
import { useSpaceConfigContext } from "../../SpaceConfig/SpaceConfigContext";
import { useSpaceContext } from "../../SpaceRoot/SpaceContext";
import { useLayoutContext } from "../LayoutContext/LayoutContext";
import {
  TransformationType,
  ElementLayout,
  PositionOption,
  parseCssUnit,
  LayoutUnit
} from "../util";

import { getSelectionTarget } from "./util";

type TransformationData = {
  transformationType: TransformationType;
  currentLayout: ElementLayout;
  originalLayout: ElementLayout;
};

type TransformationMap = Map<string, TransformationData>;
type DropTarget = {
  path: string;
  index: number;
};
type TransferringEvent = {
  slug: string;
  transformationType: TransformationType;
  data: { pagePosition: DOMPoint };
};
const SelectionStateContext = React.createContext({
  selected: null as null | string,
  hovered: null as null | string,
  locked: [] as string[],
  activeDropTarget: null as null | DropTarget
});

export const useSelectionStateContext = () => React.useContext(SelectionStateContext);

const maxRect = new DOMRect(0, 0, Number.MAX_VALUE, Number.MAX_VALUE);
const TransformationStateContext = React.createContext({
  transformations: new Map() as TransformationMap,
  transferringEvent: null as null | TransferringEvent,
  dragBoundary: maxRect
});

export const useTransformationStateContext = () =>
  React.useContext(TransformationStateContext);

// This context provides only the slug of the actively transforming
// and selected component so that components that need to know about
// it don't need to update on every selection / transformation change.
const CurrentTransformingComponentContext = React.createContext({
  transformingSlug: null as string | null,
  selectedSlug: null as string | null
});

export const useCurrentTransformingComponentContext = () =>
  React.useContext(CurrentTransformingComponentContext);

const TransformationActionContext = React.createContext({
  updateTransformations: (_payload: UpdateTransformationPayload) => {},
  startMove: (
    _path: string,
    _position: DOMPoint,
    _elementLayout: ElementLayout,
    _domRect: DOMRect
  ) => {},
  endMove: (_sourcePath: string, _layout: ElementLayout) => {},
  endResize: (_sourcePath: string) => {},
  resolveEventTransfer: (_slug: string) => {},
  select: (_slug: string) => {},
  deselect: (_slug: string) => {},
  clearSelection: () => {},
  lock: (_slug: string) => {},
  unlock: (_slug: string) => {},
  hover: (_slug: string) => {},
  unhover: (_slug: string) => {},
  registerDropTarget: (_dropTarget: DropTarget) => {},
  deregisterDropTarget: (_path: string) => {},
  registerDragBoundary: (_dragBoundary: DOMRect | null) => {}
});

export const useTransformationActionContext = () =>
  React.useContext(TransformationActionContext);

export const initialTransformationsState = {
  transformations: new Map() as TransformationMap,
  transferringEvent: null as null | TransferringEvent,
  dropTargetStack: [] as DropTarget[],
  hoverStack: [] as string[],
  locked: [] as string[],
  selected: null as null | string,
  resolveMoveFor: null as null | { sourcePath: string; layout: ElementLayout },
  dragBoundary: maxRect
};

export type TransformationState = typeof initialTransformationsState;

type UpdateTransformationPayload = {
  slug: string;
  transformationType: TransformationType;
  layout: ElementLayout | null;
  originalLayout?: ElementLayout;
};

interface UpdateTransformationAction {
  type: "UPDATE_TRANSFORMATION";
  payload: UpdateTransformationPayload;
}

interface ClearTransformationsAction {
  type: "CLEAR_TRANSFORMATIONS";
}

interface RegisterDropTargetAction {
  type: "REGISTER_DROP_TARGET";
  payload: { dropTarget: DropTarget; ignoreActiveDragCheck?: boolean };
}

interface DeregisterDropTargetAction {
  type: "DEREGISTER_DROP_TARGET";
  payload: { path: String };
}

interface DropAction {
  type: "DROP";
  payload: {};
}

interface InitiateEventTransferAction {
  type: "TRANSFER_EVENT";
  payload: {
    slug: string;
    transformationType: TransformationType;
    data: { pagePosition: DOMPoint };
  };
}

interface ResolveEventTransferAction {
  type: "RESOLVE_EVENT_TRANSFER";
  payload: {
    slug: string;
  };
}

interface LockAction {
  type: "LOCK";
  payload: { slug: string };
}

interface UnlockAction {
  type: "UNLOCK";
  payload: { slug: string };
}

interface HoverAction {
  type: "HOVER";
  payload: { slug: string };
}

interface UnhoverAction {
  type: "UNHOVER";
  payload: { slug: string };
}

interface SelectAction {
  type: "SELECT";
  payload: { slug: string; components: SpaceComponentObject[] };
}

interface DeselectAction {
  type: "DESELECT";
  payload: { slug: string };
}

interface ClearSelectionAction {
  type: "CLEAR_SELECTION";
  payload: {};
}

interface EnqueueResolveMove {
  type: "ENQUEUE_RESOLVE_MOVE";
  payload: { sourcePath: string; layout: ElementLayout };
}

interface DequeueResolveMove {
  type: "DEQUEUE_RESOLVE_MOVE";
}

interface RegisterDragBoundary {
  type: "REGISTER_DRAG_BOUNDARY";
  payload: { dragBoundary: DOMRect };
}

type TransformationsAction =
  | UpdateTransformationAction
  | ClearTransformationsAction
  | InitiateEventTransferAction
  | ResolveEventTransferAction
  | RegisterDropTargetAction
  | DeregisterDropTargetAction
  | DropAction
  | LockAction
  | UnlockAction
  | HoverAction
  | UnhoverAction
  | SelectAction
  | DeselectAction
  | ClearSelectionAction
  | EnqueueResolveMove
  | DequeueResolveMove
  | RegisterDragBoundary;

function getActiveDragSlug(transformations: TransformationMap) {
  const asArray = Array.from(transformations);
  for (let i = 0; i < asArray.length; i++) {
    const [slug, { transformationType }] = asArray[i];
    if (transformationType === TransformationType.MOVE) {
      return slug;
    }
  }
}

function transformationsReducer(
  state: typeof initialTransformationsState,
  action: TransformationsAction
) {
  switch (action.type) {
    case "UPDATE_TRANSFORMATION": {
      const { slug, transformationType, layout, originalLayout } = action.payload;
      if (transformationType === TransformationType.NONE) {
        // NO-OP if not currently tracked as an active transformation
        if (!state.transformations.has(slug)) {
          return state;
        }
        const transformations = new Map(state.transformations);
        transformations.delete(slug);
        return {
          ...state,
          dropTargetStack: !!getActiveDragSlug(state.transformations)
            ? state.dropTargetStack
            : [],
          transformations
        };
      } else {
        if (layout === null) {
          throw new Error(
            "Expected layout to be present if not TransformationType.NONE"
          );
        }
        const prevTransformation = state.transformations.get(slug);
        // NO-OP if currently tracked transformation is equal
        if (
          prevTransformation &&
          transformationType === prevTransformation.transformationType &&
          layout.isEqual(prevTransformation.currentLayout)
        ) {
          return state;
        }
        const _originalLayout =
          (prevTransformation && prevTransformation.originalLayout) ||
          originalLayout ||
          layout;
        const transformations = new Map(state.transformations);
        transformations.set(slug, {
          transformationType,
          currentLayout: layout,
          originalLayout: _originalLayout as ElementLayout
        });
        let hoverStack = state.hoverStack;
        if (state.hoverStack.includes(slug)) {
          hoverStack = hoverStack.filter(s => s !== slug);
        }
        return {
          ...state,
          dropTargetStack: !!getActiveDragSlug(state.transformations)
            ? state.dropTargetStack
            : [],
          transformations,
          hoverStack
        };
      }
    }

    case "CLEAR_TRANSFORMATIONS": {
      if (state.transformations.size === 0) return state;
      return {
        ...state,
        transformations: new Map()
      };
    }

    case "TRANSFER_EVENT": {
      const { slug, transformationType, data } = action.payload;
      return {
        ...state,
        transferringEvent: { slug, transformationType, data }
      };
    }

    case "RESOLVE_EVENT_TRANSFER": {
      const { slug } = action.payload;
      if (slug !== state.transferringEvent?.slug) {
        return state;
      }
      return {
        ...state,
        transferringEvent: null
      };
    }

    case "DROP": {
      return {
        ...state,
        dropTargetStack: []
      };
    }

    case "HOVER": {
      const {
        payload: { slug }
      } = action;
      if (state.hoverStack.includes(slug)) return state;

      return {
        ...state,
        hoverStack: [slug, ...state.hoverStack]
      };
    }

    case "UNHOVER": {
      const {
        payload: { slug }
      } = action;

      if (!state.hoverStack.includes(slug)) {
        return state;
      }

      const hoverStack = state.hoverStack.filter(s => s !== slug);

      return { ...state, hoverStack };
    }

    case "LOCK": {
      const {
        payload: { slug }
      } = action;
      if (state.locked.includes(slug)) return state;
      return {
        ...state,
        locked: [...state.locked, slug]
      };
    }

    case "UNLOCK": {
      const {
        payload: { slug }
      } = action;
      const idx = state.locked.indexOf(slug);
      if (idx === -1) return state;
      let locked: string[] = [];
      if (state.locked.length > 1) {
        locked = [
          ...state.locked.slice(0, idx),
          ...state.locked.slice(idx + 1, state.locked.length)
        ];
      }
      return { ...state, locked };
    }

    case "SELECT": {
      const {
        payload: { slug, components }
      } = action;
      const ensuredSlug = getSelectionTarget(slug, state.selected, components);

      if (state.selected === ensuredSlug) return state;
      return { ...state, selected: ensuredSlug };
    }

    case "DESELECT": {
      const {
        payload: { slug }
      } = action;
      if (state.selected !== slug) return state;
      return { ...state, selected: null };
    }

    case "CLEAR_SELECTION": {
      if (state.selected === null) return state;

      return {
        ...state,
        selected: null,
        hoverStack: []
      };
    }

    case "REGISTER_DROP_TARGET": {
      const { dropTarget, ignoreActiveDragCheck } = action.payload;
      const activeDragSlug = getActiveDragSlug(state.transformations);
      const pathParts = dropTarget.path.split(".");

      if (
        state.dropTargetStack[0]?.path === dropTarget.path &&
        state.dropTargetStack[0]?.index === dropTarget.index
      ) {
        return state;
      }

      if (
        (!activeDragSlug && !ignoreActiveDragCheck) ||
        pathParts.some(pp => pp === activeDragSlug) // disallow recursive drops
      ) {
        return state;
      }

      // If the current drop target is a descendant of the one registering NOOP
      if (
        state.dropTargetStack[0] &&
        state.dropTargetStack[0].path !== dropTarget.path &&
        state.dropTargetStack[0].path.includes(dropTarget.path)
      ) {
        return state;
      }

      const idx = state.dropTargetStack.findIndex(
        t => t.path === dropTarget.path && t.index === dropTarget.index
      );
      if (idx === 0) {
        return state;
      } else if (idx > 1) {
        return {
          ...state,
          dropTargetStack: [
            dropTarget,
            ...state.dropTargetStack.slice(0, idx),
            ...state.dropTargetStack.slice(idx + 1, state.dropTargetStack.length)
          ]
        };
      } else {
        const filteredDropTargetStack = state.dropTargetStack.filter(
          dts => dts.path !== dropTarget.path
        );
        return {
          ...state,
          dropTargetStack: [dropTarget, ...filteredDropTargetStack]
        };
      }
    }

    case "DEREGISTER_DROP_TARGET": {
      const { path } = action.payload;
      const dropTargetStack = state.dropTargetStack.filter(dts => dts.path !== path);
      if (dropTargetStack.length === state.dropTargetStack.length) {
        return state;
      }
      return {
        ...state,
        dropTargetStack
      };
    }

    case "ENQUEUE_RESOLVE_MOVE": {
      const { sourcePath, layout } = action.payload;
      if (state.resolveMoveFor?.sourcePath === sourcePath) return state;
      return {
        ...state,
        resolveMoveFor: { sourcePath, layout }
      };
    }

    case "DEQUEUE_RESOLVE_MOVE": {
      if (state.resolveMoveFor === null) return state;
      return {
        ...state,
        resolveMoveFor: null
      };
    }

    case "REGISTER_DRAG_BOUNDARY": {
      const { dragBoundary } = action.payload;
      return {
        ...state,
        dragBoundary
      };
    }

    default:
      return assertNever(action);
  }
}

export function TransformationContextContainer({
  children
}: {
  children: React.ReactNode;
}) {
  const { canvasDOMRect } = useLayoutContext();

  const [state, dispatch] = React.useReducer(
    transformationsReducer,
    initialTransformationsState
  );

  const transformationStateValue = React.useMemo(
    () => ({
      transformations: state.transformations,
      transferringEvent: state.transferringEvent,
      dragBoundary: state.dragBoundary
    }),
    [state.transformations, state.transferringEvent, state.dragBoundary]
  );

  const transformingSlug =
    state.transformations.size > 0 ? [...state.transformations][0][0] : null;
  const currentTransformingComponentValue = React.useMemo(
    () => ({
      transformingSlug,
      selectedSlug: state.selected
    }),
    [transformingSlug, state.selected]
  );

  const { components, componentTree, getAncestorComponents } = useSpaceContext();
  const selectionStateValue = React.useMemo(
    () => ({
      selected: state.selected,
      hovered:
        getSelectionTarget(state.hoverStack[0], state.selected, components) || null, // HERE
      locked: state.locked,
      activeDropTarget: state.dropTargetStack[0] || null
    }),
    [state.selected, state.hoverStack, state.locked, state.dropTargetStack, components]
  );

  const {
    state: { uiStateOverrides },
    dispatch: spaceConfigDispatch
  } = useSpaceConfigContext();

  React.useEffect(() => {
    if (state.resolveMoveFor === null) return;
    const dropTarget = state.dropTargetStack[0] || null;
    const pathParts = state.resolveMoveFor.sourcePath.split(".");
    const slug = pathParts[pathParts.length - 1];
    const transformation = state.transformations.get(slug) as TransformationData;
    if (!transformation) throw new Error("Expected active transformation.");

    dispatch({ type: "DROP", payload: {} });
    const layoutOpts = dropTarget
      ? { position: PositionOption.STATIC, left: "0%", top: "0px" }
      : ({ position: PositionOption.ABSOLUTE } as Partial<ElementLayout>);
    const { left, top, width, height, ...retainedLayout } =
      transformation.originalLayout || {};

    const toXPercent = (val: string) =>
      `${(parseFloat(val) / canvasDOMRect.width) * 100}%`;
    const toYPercent = (val: string) =>
      `${(parseFloat(val) / canvasDOMRect.height) * 100}%`;

    const currentPxLayout = state.resolveMoveFor.layout;

    // MOVEing elements have px layouts. Convert as needed when move resolves
    if (
      transformation.originalLayout?.position === PositionOption.STATIC &&
      !dropTarget
    ) {
      // static -> abs: x layout fields to %
      layoutOpts.left = toXPercent(currentPxLayout.left);
      layoutOpts.width = toXPercent(currentPxLayout.width);
    } else if (
      transformation.originalLayout?.position === PositionOption.ABSOLUTE &&
      dropTarget
    ) {
      // abs -> static: px for all dimensions
      layoutOpts.width = currentPxLayout.width;
      layoutOpts.height = currentPxLayout.height;
    } else {
      // abs -> abs || static -> static: convert all units back to original values and units
      ["width", "height"].forEach(k => {
        layoutOpts[k] = transformation.originalLayout[k];
      });
      if (!dropTarget) {
        if (parseCssUnit(transformation.originalLayout.top) === LayoutUnit.PERCENTAGE) {
          layoutOpts.top = toYPercent(currentPxLayout.top);
        }
        if (
          parseCssUnit(transformation.originalLayout.left) === LayoutUnit.PERCENTAGE
        ) {
          layoutOpts.left = toXPercent(currentPxLayout.left);
        }
      }
    }
    spaceConfigDispatch({
      type: "UPDATE_COMPONENT_LAYOUT",
      payload: {
        slug,
        layout: {
          ...retainedLayout,
          ...layoutOpts
        }
      }
    });
    if (dropTarget) {
      dispatch({ type: "CLEAR_SELECTION", payload: {} });
      spaceConfigDispatch({
        type: "MOVE_COMPONENT",
        payload: {
          sourcePath: state.resolveMoveFor.sourcePath,
          destinationPath: dropTarget.path,
          index: dropTarget.index
        }
      });
    }
    dispatch({ type: "CLEAR_TRANSFORMATIONS" });
    dispatch({ type: "DEQUEUE_RESOLVE_MOVE" });
  }, [
    state.resolveMoveFor,
    state.dropTargetStack,
    state.transformations,
    canvasDOMRect,
    spaceConfigDispatch
  ]);

  const updateTransformations = React.useCallback(function updateTranformations(
    payload: UpdateTransformationPayload
  ) {
    dispatch({
      type: "UPDATE_TRANSFORMATION",
      payload
    });
  },
  []);

  const select = React.useCallback(
    (slug: string) => {
      dispatch({ type: "SELECT", payload: { slug, components } });
      // Check to see if the component or one of its ancestors is a closed modal
      // and if so, open it. If there are any open modals, that are not an
      // ancestor, close them.
      const ancestorComponents = getAncestorComponents(slug);
      const nextOpenModals = new Set<string>(uiStateOverrides.openModals);
      ancestorComponents.forEach(c => {
        if (c.type === "MODAL" && !uiStateOverrides.openModals.has(c.slug)) {
          nextOpenModals.add(c.slug);
        }
      });
      if (isEqual(nextOpenModals, uiStateOverrides.openModals)) {
        return;
      }
      spaceConfigDispatch({
        type: "UPDATE_UI_STATE_OVERRIDES",
        payload: {
          uiStateOverrides: {
            ...uiStateOverrides,
            openModals: nextOpenModals
          }
        }
      });
    },
    [components, uiStateOverrides, getAncestorComponents, spaceConfigDispatch]
  );

  const startMove = React.useCallback(
    function startMove(
      path: string,
      pagePosition: DOMPoint,
      elementLayout: ElementLayout,
      domRect: DOMRect
    ) {
      const pathParts = path.split(".");
      const slug = pathParts[pathParts.length - 1];
      const parentPath = pathParts.slice(0, pathParts.length - 1).join(".");

      unstable_batchedUpdates(() => {
        select(slug);

        // If was a root component no need to move in tree, otherwise move to top of root.
        if (pathParts.length >= 1) {
          spaceConfigDispatch({
            type: "MOVE_COMPONENT",
            payload: {
              sourcePath: path,
              destinationPath: "",
              index: componentTree.length
            }
          });
        }

        dispatch({
          type: "UPDATE_TRANSFORMATION",
          payload: {
            slug,
            transformationType: TransformationType.MOVE,
            layout: elementLayout,
            originalLayout: elementLayout
          }
        });
        dispatch({
          type: "TRANSFER_EVENT",
          payload: {
            slug,
            transformationType: TransformationType.MOVE,
            data: { pagePosition }
          }
        });
        // Convert to a px layout for duration of MOVE
        spaceConfigDispatch({
          type: "UPDATE_COMPONENT_LAYOUT",
          payload: {
            slug,
            layout: {
              ...elementLayout,
              left: `${domRect.left}px`,
              top: `${domRect.top}px`,
              width: `${domRect.width}px`,
              height: `${domRect.height}px`
            }
          }
        });
        // If being dragged from a container, that container must immediately be
        // considered a drop target so that siblings within may be re-ordered without
        // dragging out first.
        if (parentPath) {
          dispatch({
            type: "REGISTER_DROP_TARGET",
            payload: {
              dropTarget: { path: parentPath, index: 0 },
              ignoreActiveDragCheck: true
            }
          });
        }
      });
    },
    [spaceConfigDispatch, componentTree.length, select]
  );

  const endMove = React.useCallback(function endMove(
    sourcePath: string,
    layout: ElementLayout
  ) {
    dispatch({ type: "ENQUEUE_RESOLVE_MOVE", payload: { sourcePath, layout } });
  },
  []);

  const endResize = React.useCallback(function endResize(_sourcePath: string) {
    dispatch({ type: "CLEAR_TRANSFORMATIONS" });
  }, []);

  const resolveEventTransfer = React.useCallback(function resolveEventTransfer(
    slug: string
  ) {
    dispatch({ type: "RESOLVE_EVENT_TRANSFER", payload: { slug } });
  },
  []);

  const hover = React.useCallback((slug: string) => {
    dispatch({ type: "HOVER", payload: { slug } });
  }, []);

  const unhover = React.useCallback((slug: string) => {
    dispatch({ type: "UNHOVER", payload: { slug } });
  }, []);

  const deselect = React.useCallback((slug: string) => {
    dispatch({ type: "DESELECT", payload: { slug } });
  }, []);

  const clearSelection = React.useCallback(() => {
    dispatch({ type: "CLEAR_SELECTION", payload: {} });
  }, []);

  const lock = React.useCallback((slug: string) => {
    dispatch({ type: "LOCK", payload: { slug } });
  }, []);

  const unlock = React.useCallback((slug: string) => {
    dispatch({ type: "UNLOCK", payload: { slug } });
  }, []);

  const registerDropTarget = React.useCallback(function registerDropTarget(
    dropTarget: DropTarget
  ) {
    dispatch({
      type: "REGISTER_DROP_TARGET",
      payload: { dropTarget }
    });
  },
  []);

  const deregisterDropTarget = React.useCallback(function deregisterDropTarget(
    path: string
  ) {
    dispatch({
      type: "DEREGISTER_DROP_TARGET",
      payload: { path }
    });
  },
  []);

  const registerDragBoundary = React.useCallback(function registerDragBoundary(
    dragBoundary: DOMRect | null
  ) {
    dispatch({
      type: "REGISTER_DRAG_BOUNDARY",
      payload: { dragBoundary: dragBoundary || maxRect }
    });
  },
  []);

  const actionContextValue = React.useMemo(
    () => ({
      updateTransformations,
      startMove,
      endMove,
      endResize,
      resolveEventTransfer,
      select,
      deselect,
      clearSelection,
      lock,
      unlock,
      hover,
      unhover,
      registerDropTarget,
      deregisterDropTarget,
      registerDragBoundary
    }),
    [
      updateTransformations,
      startMove,
      endMove,
      endResize,
      resolveEventTransfer,
      select,
      deselect,
      clearSelection,
      lock,
      unlock,
      hover,
      unhover,
      registerDropTarget,
      deregisterDropTarget,
      registerDragBoundary
    ]
  );

  return (
    <TransformationStateContext.Provider value={transformationStateValue}>
      <SelectionStateContext.Provider value={selectionStateValue}>
        <CurrentTransformingComponentContext.Provider
          value={currentTransformingComponentValue}
        >
          <TransformationActionContext.Provider value={actionContextValue}>
            {children}
          </TransformationActionContext.Provider>
        </CurrentTransformingComponentContext.Provider>
      </SelectionStateContext.Provider>
    </TransformationStateContext.Provider>
  );
}
