import React from "react";

import AnalyticCard, { DEFAULT_ERROR_MESSAGE } from "../AnalyticCard/AnalyticCard";
import { Headline, PremiumBlockHeader } from "../common/styledComponents";
import { TimeRange } from "../DurationSelect/DurationSelect";

import { useNewTasksCreated } from "./queries";

export default function NewTasksCreatedStatistics({
  queueId,
  timeRange,
  locked,
  className
}: {
  queueId?: string;
  timeRange: TimeRange;
  locked: boolean;
  className?: string;
}) {
  const { data, loading, error } = useNewTasksCreated({
    variables: {
      queueId: queueId!,
      fromTimestamp: timeRange.fromTimestamp,
      toTimestamp: timeRange.toTimestamp
    },
    skip: !queueId,
    fetchPolicy: "cache-and-network"
  });

  return (
    <AnalyticCard
      title="New tasks created"
      loading={loading}
      errorMessage={error ? DEFAULT_ERROR_MESSAGE : undefined}
      className={className}
      showUpsell={locked}
    >
      {locked && <PremiumBlockHeader />}
      {!locked && <Headline>{data?.newTasksCreated}</Headline>}
    </AnalyticCard>
  );
}
