import React from "react";

import AttributeValue from "../../common/AttributeValue";
import { SpaceTableAttributeColumn, SpaceTableColumn } from "../useColumns";

export interface SpaceCell<T extends SpaceTableColumn> {
  column: T;
  value: any;
}

interface Props {
  row: any;
  cell: SpaceCell<SpaceTableAttributeColumn>;
}

const MAX_MIN_WIDTH = 30;

export default function AttributeCell({ row, cell }: Props) {
  const charCount = String(cell.value).trim().length;
  return (
    <AttributeValue
      style={{ minWidth: `${Math.min(charCount + 2, MAX_MIN_WIDTH)}ch` }}
      format={cell.column.options!}
      attribute={cell.column.attribute}
      value={cell.value}
      rowValues={row.values}
    />
  );
}
