import React from "react";

import { Navigate } from "react-router";

import Layout from "../components/auth/Layout";
import { Subtitle, Title } from "../components/auth/styledComponents";
import {
  AuthStatusProps,
  withAuthStatus
} from "../components/auth/WithAuthStatus/withAuthStatus";
import usePaths from "../components/common/hooks/usePaths";
import AcceptInviteForm from "../components/setup_flow/AcceptInviteForm";
import CompleteSignupForm from "../components/setup_flow/CompleteSignupForm";
import CreateOrganization from "../components/setup_flow/CreateOrganization";
import { NoHeaderLayout } from "../layouts";
import { AuthStatus } from "../types";

function SignUp({ status }: AuthStatusProps) {
  const { getDashboard } = usePaths();
  if (status === AuthStatus.Authenticated) {
    return <Navigate to={getDashboard()} />;
  }

  return (
    <NoHeaderLayout backgroundColor="black">
      <CreateOrganization />
    </NoHeaderLayout>
  );
}

export const New = withAuthStatus(SignUp);

export const AcceptInvite = () => (
  <NoHeaderLayout backgroundColor="black">
    <AcceptInviteForm />
  </NoHeaderLayout>
);

export const CompleteSignup = () => (
  <NoHeaderLayout backgroundColor="black">
    <Layout
      left={
        <>
          <Title>Let's set up your account</Title>
          <Subtitle>Enter your information to create an account</Subtitle>
        </>
      }
      right={<CompleteSignupForm />}
    />
  </NoHeaderLayout>
);
