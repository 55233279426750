import React from "react";

import { ScmStatus } from "../../../../types";
import { useSpaceContext } from "../../SpaceRoot/SpaceContext";

import ExitButton from "./ExitButton";
import MoreOptions from "./MoreOptions";
import PublishButton from "./PublishButton";
import SaveButton from "./SaveButton";
import ScmActions from "./ScmActions";

export default function HeaderButtons() {
  const { space } = useSpaceContext();
  return (
    <>
      <SaveButton />
      <PublishButton />
      {space.scmStatus === ScmStatus.CONNECTED && <ScmActions />}
      <MoreOptions />
      <ExitButton />
    </>
  );
}
