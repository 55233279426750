import Icon from "../../../../../assets/spaceicons/accordion.svg";
import {
  SpaceComponentHostType,
  SpaceComponentPackage,
  SpaceComponentTags
} from "../../../../../types";
import { DEFAULT_SIZES } from "../../../layout/constants";
import {
  DEFAULT_ELEMENT_LAYOUT_OPTIONS,
  ElementLayout,
  LayoutUnit,
  OverflowOption
} from "../../../layout/util";

import Config, { errorSelector } from "./Config";
import componentConfigReducer, {
  ensureSpaceAccordionComponent,
  makeInitialState
} from "./Config/reducer";
import SpaceAccordion from "./SpaceAccordion";
import {
  AccordionConfigState,
  SpaceAccordionComponent,
  SpaceAccordionProperties
} from "./types";

const pkg: SpaceComponentPackage<
  SpaceAccordionProperties,
  SpaceAccordionComponent,
  AccordionConfigState
> = {
  type: "ACCORDION",
  displayName: "Accordion",
  Icon,
  allowedHosts: new Set<SpaceComponentHostType>([
    "CARD_LIST",
    "DETAIL",
    "TABLE",
    "FLEX_BOX"
  ]),
  Component: SpaceAccordion,
  ConfigurationComponent: Config,
  getInitialDraftState: makeInitialState,
  ensureComponent: ensureSpaceAccordionComponent,
  componentConfigReducer,
  elementLayoutOptions: {
    ...DEFAULT_ELEMENT_LAYOUT_OPTIONS,
    width: [LayoutUnit.PERCENTAGE, LayoutUnit.PIXEL],
    height: [LayoutUnit.PERCENTAGE, LayoutUnit.PIXEL]
  },
  defaultElementLayout: new ElementLayout({
    snapToGrid: true,
    width: DEFAULT_SIZES.STANDARD.width,
    height: DEFAULT_SIZES.STANDARD.height,
    overflow: OverflowOption.VISIBLE
  }),
  getSchema: () => [],
  allowSelfBinding: false,
  allowAncestorBinding: true,
  isHeadless: false,
  hasInlineChildren: true,
  errorSelector,
  isContainer: false,
  tags: [SpaceComponentTags.Base]
};

export default pkg;
