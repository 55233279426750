import { cloneDeep, flow, partial } from "lodash";

import { SpaceComponentObject } from "../../../../../../types";
import { commonComponentReducer } from "../../../../SpaceConfig/SpaceConfigContext/useSpaceConfig/reducer";
import {
  BaseComponentConfigState,
  ComponentConfigState,
  SpaceConfigAction
} from "../../../../types";
import { SpaceAccordionComponent } from "../types";

export interface AccordionConfigState
  extends BaseComponentConfigState<SpaceAccordionComponent> {
  type: "ACCORDION";
  initialized: boolean;
}

export const INITIAL_STATE = {
  type: "ACCORDION" as const,
  initialized: false,
  draftComponent: {} as SpaceAccordionComponent
};

function isAccordionConfigState(
  state: ComponentConfigState
): state is AccordionConfigState {
  return state.type === "ACCORDION";
}

export function ensureAccordionConfigState(
  state: ComponentConfigState
): AccordionConfigState {
  if (isAccordionConfigState(state)) return state;

  throw new Error("Expected Accordion config state.");
}

export function makeInitialState(
  draftComponent: SpaceComponentObject
): AccordionConfigState {
  return {
    ...INITIAL_STATE,
    initialized: draftComponent.properties.sections.length > 0,
    draftComponent: ensureSpaceAccordionComponent(cloneDeep(draftComponent))
  };
}

export function ensureSpaceAccordionComponent(
  component: SpaceComponentObject
): SpaceAccordionComponent {
  if (Array.isArray(component.properties.sections)) return component;

  return {
    ...component,
    properties: {
      sections: [],
      allows_multiple_open_sections: false,
      ...component.properties
    }
  };
}

function reducer(
  state: AccordionConfigState,
  action: SpaceConfigAction
): AccordionConfigState {
  switch (action.type) {
    case "SET_DRAFT_COMPONENT": {
      const { path, value } = action.payload;
      if (path === "properties.sections" && value.length && !state.initialized) {
        return {
          ...state,
          initialized: true
        };
      }

      return state;
    }

    default:
      return state;
  }
}

export default (state: AccordionConfigState, action: SpaceConfigAction) =>
  flow([commonComponentReducer, partial(reducer, partial.placeholder, action)])(
    state,
    action
  );
