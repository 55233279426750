import styled from "styled-components";

import { LinkButtonNew } from "../../../../common/StyledComponents";

export const BannerContainer = styled.div<{ isError?: boolean }>`
  background-color: ${props =>
    props.isError ? props.theme.errorColor : props.theme.bannerBackgroundColor};
  color: ${props =>
    props.isError ? props.theme.bannerErrorLinkColor : props.theme.bannerLinkColor};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;

  & a,
  & a:hover,
  & a:focus {
    color: ${props =>
      props.isError ? props.theme.bannerErrorLinkColor : props.theme.bannerLinkColor};
    font-weight: 600;
  }

  & a:hover {
    text-decoration: underline;
  }
`;

export const BannerContent = styled.div``;

export const SyncLink = styled(LinkButtonNew)`
  margin-left: 12px;
  color: ${props => props.theme.bannerLinkColor};
  font-weight: 600;
  font-size: 13px;

  &.ant-btn::before {
    background: none;
  }

  span {
    white-space: nowrap;
    padding-right: 12px;
  }

  &:active,
  &:focus {
    color: ${props => props.theme.bannerLinkColor};
  }
  &:hover {
    color: ${props => props.theme.linkColor};
  }
`;
