import React, { useState } from "react";

import { CheckCircleFilled } from "@ant-design/icons";
import gql from "graphql-tag";
import { useMutation } from "react-apollo";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { brandColors } from "../../../cssConstants";
import ButtonNew from "../../common/ButtonNew/ButtonNew";
import { H3, B3, Pill, A } from "../../common/StyledComponents";

import { DowngradeModal } from "./DowngradeModal";

export const CANCEL_SUBSCRIPTION_MUTATION = gql`
  mutation CancelSubscription {
    cancelSubscription {
      ok
    }
  }
`;

const PlanContainer = styled.div`
  height: 100%;
  padding: 10px;
`;

const TermsB3 = styled(B3)`
  color: ${props => props.theme.surfaceSecondary}
  display: block;
`;

const PlanDetails = styled.div`
  background-color: ${props => props.theme.backgroundColor};
  border: 1px solid ${props => props.theme.borderGrey};
  border-radius: 10px;
  padding: 20px;
  text-align: left;
  min-height: 540px;
  height: 100%;

  .price {
    color: ${props => props.theme.primaryAccent};
    font-size: 40px;
    font-weight: 300;
    margin: 0;
    padding: 0;
  }

  .contact-sales {
    color: ${props => props.theme.primaryColor};
    font-size: 16px;
    margin: 22px 0;
  }

  &.active {
    border: 1px solid ${props => props.theme.primaryColor};
  }
`;

const PlanDetailH3 = styled(H3)`
  display: inline;
`;

const UpgradeButton = styled(ButtonNew)`
  margin: 20px 0;
  width: 100%;
`;

const FeatureList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin: 10px 0;
  }
`;

const Price = styled(A)`
  cursor: default;
`;

interface PricingProductDetailProps {
  name: string;
  description: string;
  monthlyPrice?: string;
  annualPrice?: string;
  billingTerm: string;
  billingUnit?: string;
  includesDescription: string;
  mostPopular?: boolean;
  tier: number;
  currentPlan:
    | {
        slug?: string;
        tier?: number;
      }
    | null
    | undefined;
  productSlug: string;
  currentPeriodEnd: number;
  children: React.ReactNode;
}

const SelectedPlanCheck = styled<any>(CheckCircleFilled)`
  color: ${props => props.theme.primaryColor};
  font-size: 20px;
  margin-right: 10px;
  display: inline-flex;
`;

const MostPopularPill = styled(Pill)`
  display: inline-flex;
  margin-bottom: 4px;
  margin-left: 20px;
  line-height: 16px;
  vertical-align: middle;

  @media (max-width: 1800px) {
    display: none;
  }
`;

const PricingProductDetail = ({
  name,
  description,
  monthlyPrice,
  annualPrice,
  billingTerm,
  billingUnit,
  includesDescription,
  mostPopular,
  tier,
  currentPlan,
  productSlug,
  currentPeriodEnd,
  children
}: PricingProductDetailProps) => {
  const [showDowngradeModal, setShowDowngradeModal] = useState(false);
  const navigate = useNavigate();
  const [cancelSubscription] = useMutation(CANCEL_SUBSCRIPTION_MUTATION);

  const price = billingTerm === "monthly" ? monthlyPrice : annualPrice;
  const active = currentPlan?.slug === productSlug;
  const isDowngrade = currentPlan?.tier ? currentPlan.tier > tier : false;

  const handleDowngradeModal = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    setShowDowngradeModal(true);
  };

  const handleDowngrade = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    if (productSlug === "basic") {
      // Cancellation affects the state of the entire app, so we need to do a full refresh here.
      cancelSubscription().then(() => (window.location.href = "/settings/billing"));
    } else {
      navigate(`/settings/change-plan/${productSlug}/${billingTerm}`);
    }
  };

  const upgradePlanButton =
    productSlug === "enterprise" ? (
      <UpgradeButton
        type="brand"
        onClick={() => window.open("https://www.internal.io/contact", "_blank")}
        target="_blank"
      >
        Contact Support
      </UpgradeButton>
    ) : (
      <UpgradeButton
        type="brand"
        to={`/settings/change-plan/${productSlug}/${billingTerm}`}
      >
        Upgrade
      </UpgradeButton>
    );

  return (
    <PlanContainer>
      <PlanDetails className={active ? "active" : ""}>
        {active && <SelectedPlanCheck />}

        <PlanDetailH3>
          {name}
          {mostPopular && (
            <MostPopularPill color={`${brandColors.accentGreen}`}>
              Most popular
            </MostPopularPill>
          )}
        </PlanDetailH3>
        <p>{active ? "Currently active" : description}</p>

        {price ? (
          <>
            <Price className="price">{price}</Price>
            {billingUnit && (
              <TermsB3 className="terms">
                {billingUnit === "forever" ? "Forever" : ""}
                {billingUnit === "user" ? `per user / month` : ""}
              </TermsB3>
            )}
          </>
        ) : (
          <p className="contact-sales">
            Contact sales
            <br /> for pricing
          </p>
        )}

        {active ? (
          <UpgradeButton to={`/settings/billing/manage`} type="primary">
            Manage account
          </UpgradeButton>
        ) : isDowngrade ? (
          <UpgradeButton
            type="secondary"
            to={`/settings/change-plan/${productSlug}/${billingTerm}`}
            onClick={handleDowngradeModal}
          >
            Downgrade
          </UpgradeButton>
        ) : (
          upgradePlanButton
        )}

        <B3>{includesDescription}</B3>

        <FeatureList>{children}</FeatureList>
      </PlanDetails>

      {isDowngrade && (
        <DowngradeModal
          name={name}
          showDowngradeModal={showDowngradeModal}
          onCancel={() => setShowDowngradeModal(false)}
          currentPlanSlug={currentPlan?.slug}
          onOk={handleDowngrade}
          currentPeriodEnd={currentPeriodEnd}
        />
      )}
    </PlanContainer>
  );
};

export default PricingProductDetail;
