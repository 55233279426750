import { Binding, BindingShape } from "../../../../../../types";
import { SpaceDefinition } from "../../../../types";
import { ComponentNode } from "../../../RenderTreeContext";
import { getColumnsSchema } from "../ColumnListManager/getColumnsOutputShape";

export function getSchema(
  node: ComponentNode,
  spaces: Map<string, SpaceDefinition>
): Binding[] {
  return [
    {
      name: "value",
      shape: BindingShape.SCALAR
    },
    {
      name: "error",
      shape: BindingShape.UNKNOWN
    },
    {
      name: "options",
      shape: BindingShape.OBJECT_ARRAY,
      attributes: getColumnsSchema(node, spaces)
    }
  ];
}
