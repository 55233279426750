import React, { useEffect, useMemo, useRef, useState } from "react";

import Input from "antd/lib/input";
import { isEqual } from "lodash";

import {
  BindingShape,
  ComponentConfigProps,
  SpaceComponentObject
} from "../../../../../types";
import SortableList, { SortableItemCompact } from "../../../../common/SortableList";
import { useSpaceConfigContext } from "../../../SpaceConfig/SpaceConfigContext";
import { SpaceParameter } from "../../../types";
import { SchemaTree } from "../../../util/tree";
import useComponentNode from "../../../util/useComponentNode";
import { useStableSpaceContext } from "../../SpaceContext";
import BaseComponentConfigSection from "../common/BaseComponentConfigSection";
import { BindingCascader } from "../common/BindingCascader";
import ChildComponentsConfigSection from "../common/ChildComponentsConfigSection";
import {
  useComponentConfigContext,
  ComponentConfigContextContainer
} from "../common/ComponentConfigContext";
import { ConfigPanelPopper, ConfigSection } from "../common/ConfigPanel";
import {
  ConfigPanelActionTypes,
  useSpaceConfigPanelContext
} from "../common/ConfigPanel/ConfigPanelContext";
import { Field } from "../common/ConfigPanel/styledComponents";
import NameFields from "../common/NameFields";
import VisibilityRulesManagerSection from "../common/VisibilityRulesManager";

import { SubSpaceComponent, SubSpaceInputParameter } from "./types";

const MemoDataConfig = React.memo(DataConfig);
export default function SpaceViewConfig({ slug }: ComponentConfigProps) {
  return (
    <ComponentConfigContextContainer dataConfig={<MemoDataConfig />} slug={slug} />
  );
}

function DataConfig() {
  return (
    <>
      <BaseComponentConfigSection title="Component">
        <NameFields />
      </BaseComponentConfigSection>

      <ParametersSection />

      <ChildComponentsConfigSection />

      <VisibilityRulesManagerSection />
    </>
  );
}

function ParametersSection() {
  const [selectedName, setSelectedName] = React.useState<null | string>(null);
  const [selectedIndex, setSelectedIndex] = React.useState<number>(-1);
  const { dispatch: configPanelDispatch } = useSpaceConfigPanelContext();
  const rootConfigContext = useSpaceConfigContext();
  const context = useComponentConfigContext();
  const {
    state: { spaces }
  } = useSpaceConfigContext();
  const state = context.state;
  const draftComponent: SubSpaceComponent =
    state.draftComponent as unknown as SubSpaceComponent;

  const subSpace = spaces.get(draftComponent.properties.sub_space);

  if (!subSpace) throw new Error("Expected sub space.");

  const { input_parameters } = draftComponent.properties;
  return (
    <ConfigSection
      title="Parameters"
      onAdd={() => {
        const param: SpaceParameter = {
          name: `param${input_parameters.length + 1}`,
          bindingType: { name: "unknown", shape: BindingShape.UNKNOWN }
        };
        rootConfigContext.dispatch({
          type: "ADD_SPACE_PARAM",
          payload: {
            spaceSlug: subSpace.slug,
            param
          }
        });
      }}
    >
      <SortableList isCompact onSort={() => {}}>
        {subSpace.parameters.map((p, index) => {
          const ip = input_parameters[index];
          const errMessage =
            context.errors.find(
              err => err.field === "INPUT_PARAMETERS" && err.index === index
            )?.message || null;
          return (
            <SortableItemCompact
              id={`param-${p.name}`}
              key={`${p.name}-${index}`}
              sortKey={p.name}
              errorMessage={errMessage}
              isSelected={selectedName === p.name && selectedIndex === index}
              onClick={() => {
                setSelectedName(p.name);
                setSelectedIndex(index);
                configPanelDispatch({
                  type: ConfigPanelActionTypes.OPEN_POPPER,
                  payload: {
                    popperIdentifier: `activeParam-${p.name}`
                  }
                });
              }}
              onRemove={() => {
                rootConfigContext.dispatch({
                  type: "REMOVE_SPACE_PARAM",
                  payload: {
                    spaceSlug: subSpace.slug,
                    index
                  }
                });
              }}
            >
              <span>{p.name}</span>
              {selectedName === p.name && selectedIndex === index && (
                <ConfigPanelPopper
                  popperId={`activeParam-${p.name}`}
                  popperReferenceElement={
                    document.getElementById(`param-${p.name}`) || undefined
                  }
                  onCancel={() => {
                    setSelectedName(null);
                    setSelectedIndex(-1);
                    configPanelDispatch({
                      type: ConfigPanelActionTypes.CLOSE_POPPER
                    });
                  }}
                >
                  <ParamEditor
                    spaceParam={p}
                    inputParam={ip}
                    spaceParams={subSpace.parameters}
                    inputParams={input_parameters}
                    spaceSlug={subSpace.slug}
                  />
                </ConfigPanelPopper>
              )}
            </SortableItemCompact>
          );
        })}
      </SortableList>
    </ConfigSection>
  );
}

const validVarCharsRegex = /^[^a-zA-Z_]+|[^a-zA-Z_0-9]+/g;
function ParamEditor({
  spaceParam,
  inputParam,
  spaceParams,
  inputParams,
  spaceSlug
}: {
  spaceParam: SpaceParameter;
  inputParam?: SubSpaceInputParameter;
  spaceParams: SpaceParameter[];
  inputParams: SubSpaceInputParameter[];
  spaceSlug: string;
}) {
  const nameRef = useRef(spaceParam.name);
  const [name, setName] = useState(spaceParam.name);
  const { componentTree, state, dispatch } = useSpaceConfigContext();
  const context = useComponentConfigContext();
  const paramIdx = spaceParams.indexOf(spaceParam);
  const { getSpaceComponentPackages } = useStableSpaceContext();
  const componentNode = useComponentNode();

  const schemaTree = useMemo(() => {
    return new SchemaTree(
      { componentTreeNodes: componentTree } as SpaceComponentObject,
      state.spaces,
      new Map(getSpaceComponentPackages().map(p => [p.type, p]))
    );
  }, [componentTree, state.spaces, getSpaceComponentPackages]);

  useEffect(() => {
    return () => {
      // Wait until the popper closes to set the name
      // as it's used as the key for the config pannel popper
      // which will close if the name changes,
      dispatch({
        type: "UPDATE_SPACE_PARAM",
        payload: {
          spaceSlug,
          index: paramIdx,
          param: {
            name: nameRef.current
          }
        }
      });
    };
  }, [paramIdx, spaceSlug, dispatch]);

  // If the binding type of the bind changes, update the space param
  // to the new type.
  useEffect(() => {
    const binding = inputParams[paramIdx].binding;
    const bindingType = spaceParams[paramIdx].bindingType;
    if (binding && componentNode) {
      const expandedPath = schemaTree.expandPath(componentNode, binding);
      const nextBindingType = schemaTree.getPathSchema(expandedPath);
      if (!isEqual(nextBindingType, bindingType)) {
        dispatch({
          type: "UPDATE_SPACE_PARAM",
          payload: {
            spaceSlug,
            index: paramIdx,
            param: {
              bindingType: nextBindingType
            }
          }
        });
      }
    }
  }, [
    spaceSlug,
    paramIdx,
    inputParams,
    spaceParams,
    componentNode,
    schemaTree,
    dispatch
  ]);

  return (
    <ConfigSection title="Parameter">
      <Field>
        <label>Name</label>
        <Input
          value={name}
          onChange={evt => {
            const ensuredName = evt.target.value.replaceAll(validVarCharsRegex, "");
            setName(ensuredName);
            nameRef.current = ensuredName;
          }}
        />
        <small>
          <em>Only letters, numbers, and underscores are allowed.</em>
        </small>
      </Field>
      <Field>
        <label>Binding</label>
        <BindingCascader
          value={inputParam?.binding || ""}
          selectable={Object.values(BindingShape)}
          onChange={binding => {
            const copy = [...inputParams];
            const updatedParam = {
              name: inputParam?.name || spaceParam.name,
              binding
            };
            copy.splice(paramIdx, inputParam ? 1 : 0, updatedParam);
            context.dispatch({
              type: "SET_DRAFT_COMPONENT",
              payload: {
                path: "properties.input_parameters",
                value: copy
              }
            });
          }}
        />
      </Field>
    </ConfigSection>
  );
}
