import React from "react";

import { Grid } from "../GridContext";

import CanvasEventMask from "./CanvasEventMask";
import { CanvasViewportProvider } from "./CanvasViewportContext";

export default function Canvas({ children }: { children: React.ReactNode }) {
  return (
    <CanvasViewportProvider>
      <CanvasEventMask>
        <Grid>{children}</Grid>
      </CanvasEventMask>
    </CanvasViewportProvider>
  );
}
