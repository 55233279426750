import React from "react";

import classNames from "classnames";
import { isEqual } from "lodash";
import { Row as RowType, Cell } from "react-table";

interface Props {
  RowContainer: React.ComponentType<any>;
  row: RowType;
  isSelected: boolean;
  fontSize?: string;
  onClick: (row: RowType) => void;
}

function Row({ RowContainer, fontSize, row, isSelected, onClick, ...rest }: Props) {
  return (
    <RowContainer row={row} index={row.index}>
      <tr
        {...rest}
        className={classNames(`row${row.id}`, {
          selectedRow: isSelected,
          isLastSelectedRow: (row.state as any).isLastSelectedRow
        })}
        data-row-id={row.id}
        onClick={() => onClick(row)}
      >
        {row.cells.map((cell: Cell) => {
          const column: any = cell.column;
          return column.hidden ? null : (
            <td
              {...cell.getCellProps()}
              className={classNames({
                noWrap: column.noWrap,
                [column.columnType!]: column.id !== "selection"
              })}
              style={{
                minWidth: column.id === "selection" ? "50px" : column.minWidth,
                maxWidth: column.id === "selection" ? "50px" : undefined,
                fontSize: fontSize || "14px"
              }}
            >
              {(cell as any).render(cell.column.Cell)}
            </td>
          );
        })}
      </tr>
    </RowContainer>
  );
}

const MemoRow = React.memo(
  Row,
  (prevProps, nextProps) =>
    prevProps.row.cells.length === nextProps.row.cells.length &&
    prevProps.row.original === nextProps.row.original &&
    prevProps.row.cells.every((prevCell, i) => {
      const nextCell = nextProps.row.cells[i];
      const nextCol = nextCell.column as any;
      const prevCol = prevCell.column as any;
      return (
        isEqual(prevCell.value, nextCell.value) &&
        isEqual(prevCol.options, nextCol.options) &&
        prevCell.column.minWidth === nextCell.column.minWidth &&
        prevCol.components?.length === nextCol.components?.length &&
        isEqual(prevCol.input, nextCol.input) &&
        isEqual(prevCol.component, nextCol.component)
      );
    }) &&
    isEqual(
      prevProps.row.cells.map(cell => cell.column.id),
      nextProps.row.cells.map(cell => cell.column.id)
    ) &&
    isEqual(prevProps.row.state, nextProps.row.state) &&
    isEqual(prevProps.row.original, nextProps.row.original) &&
    prevProps.isSelected === nextProps.isSelected
);

export default MemoRow;
