import React from "react";

import { Skeleton } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";

import PageHeader from "../../../../layouts/Header/Header";
import Nav from "../../../../layouts/Nav";
import CardList from "../../../common/CardList";
import useAuthUser from "../../../common/hooks/useAuthUser";
import usePaths from "../../../common/hooks/usePaths";
import useSearchParamsUpdater from "../../../common/hooks/useSearchParamsUpdater";
import { Cols, LeftRail, Main } from "../../../common/layout/styledComponents";
import { Header, Hr, MainTitle, NewButton } from "../../../common/StyledComponents";
import OrgDetail from "../../../spaces/Home/OrgDetail/OrgDetail";
import { AutomationNode, useAllAutomations } from "../queries/AllAutomations";

import AutomationDetails from "./AutomationDetails";
import * as styled from "./styledComponents";

export const Home = () => {
  const navigate = useNavigate();
  const { isAdmin } = useAuthUser();
  const { automationsapp_getNewAutomation, automationsapp_getEditAutomation } =
    usePaths();
  const [searchParams] = useSearchParams();
  const updateParams = useSearchParamsUpdater();
  const selectedAutomationSlug = searchParams.get("selectedAutomation") || undefined;

  const { loading, data, refetch } = useAllAutomations({
    fetchPolicy: "no-cache"
  });

  const items = React.useMemo(() => {
    return data?.allAutomations.edges.length
      ? data?.allAutomations.edges.map(edge => ({
          ...edge.node,
          href: automationsapp_getEditAutomation(edge.node.slug)
        }))
      : [];
  }, [data, automationsapp_getEditAutomation]);

  const selectedItem: AutomationNode | null = React.useMemo(() => {
    return items.find(item => item.slug === selectedAutomationSlug) || null;
  }, [items, selectedAutomationSlug]);

  const onSelect = React.useCallback(
    (slug: string | null) => {
      const found = items.find(item => item.slug === slug) || null;
      if (slug !== null && !found) {
        throw new Error("illegal state");
      }

      updateParams({ selectedAutomation: slug });
    },
    [items, updateParams]
  );

  const deselectAutomation = React.useCallback(() => {
    updateParams({ selectedAutomation: undefined });
  }, [updateParams]);

  return (
    <>
      <PageHeader key="automations-home" title="Home" />
      <Cols>
        <LeftRail open>
          <Nav />
        </LeftRail>
        {loading ? (
          <Main>
            {" "}
            <Skeleton />{" "}
          </Main>
        ) : !isAdmin || !items.length ? (
          <styled.GettingStartedContainer>
            <styled.GettingStarted />
          </styled.GettingStartedContainer>
        ) : (
          <styled.Main>
            <>
              <Header>
                <MainTitle>Your automations</MainTitle>
                {isAdmin && (
                  <NewButton
                    icon="plus"
                    type="link"
                    onClick={() => {
                      navigate(automationsapp_getNewAutomation());
                    }}
                  >
                    New automation
                  </NewButton>
                )}
              </Header>
              <Hr />
              <CardList
                items={items}
                moreButton={undefined}
                selectedSlug={selectedItem && selectedItem.slug}
                loading={loading}
                onSelect={onSelect}
                emptyState="You have no automations"
              />
            </>
          </styled.Main>
        )}
        {isAdmin && !!items.length && (
          <styled.RightRail isActive={!!selectedItem} onBack={() => onSelect(null)}>
            {selectedItem ? (
              <AutomationDetails
                id={selectedItem.id}
                onAutomationChange={refetch}
                onAutomationDeletion={deselectAutomation}
              />
            ) : (
              <OrgDetail />
            )}
          </styled.RightRail>
        )}
      </Cols>
    </>
  );
};
