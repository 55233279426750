import RWSelect, { components } from "react-windowed-select";
import styled from "styled-components";

export const BranchOption = styled(components.Option)`
  &.react-select__option,
  &.react-select__option:active {
    background: none;
  }
`;

export const IconContainer = styled.div`
  width: 13px;
  height: 13px;
  margin-right: 12px;
`;

export const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 20px;
  .openMenuButton {
    display: none;
  }
  &.open .openMenuButton {
    display: block;
  }
  &:hover {
    .openMenuButton {
      display: block;
    }
  }
`;

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Select = styled(RWSelect)`
  min-width: 200px;
  max-width: 200px;

  .react-select__value-container {
    flex-wrap: nowrap;
  }

  .react-select__input {
    color: white;
    font-size: 12px;
    input {
      font-weight: 500;
    }
  }
`;

export const SelectedValue = styled.span`
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 18px;
  font-size: ${props => props.theme.smallFontSize};
  & > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const MoreOptionsMenu = styled.div`
  width: 215px;
  background: #202124;
  border-radius: 4px;
`;

export const MoreOption = styled.a`
  display: block;
  padding: ${props => props.theme.spacersm};
  padding-left: ${props => props.theme.spacerlg};
  cursor: pointer;
  color: white;

  &:hover,
  &:active {
    color: white;
  }
`;
