import React from "react";

import { Button, Modal, Icon } from "antd";
import { HotKeys } from "react-hotkeys";
import styled from "styled-components";

import { SpaceComponentObject } from "../../../../types";
import { useTransformationActionContext } from "../../layout/TransformationContext/TransformationContext";
import { useSpaceConfigContext } from "../../SpaceConfig/SpaceConfigContext";
import useNestedStatus from "../../SpaceRoot/SpaceComponent/common/useNestedStatus";
import {
  useComponentPathContext,
  getIsFirstInstance
} from "../../SpaceRoot/SpaceComponent/contexts/ComponentPathContext";
import { useSpaceContext, useStableSpaceContext } from "../../SpaceRoot/SpaceContext";
import { useVolatileCanvasViewportContext } from "../Canvas/CanvasViewportContext";

import { getSpaceComponentDeleteCopy } from "./util";

export type TagMode = "HIDDEN" | "HOVERED" | "SELECTED" | "ACTIVE_DROP_TARGET";
interface TagProps {
  component: SpaceComponentObject;
  mode: TagMode;
}

export default function MaybeTag(props: TagProps) {
  if (props.mode === "HIDDEN") return null;
  return <TagImpl {...props} />;
}

export function TagImpl({ component, mode }: TagProps) {
  const [ancestorMenuOpen, setAncestorMenuOpen] = React.useState(false);
  const [confirmRemoveModalOpen, setConfirmRemoveModalOpen] = React.useState(false);
  const { select, deselect } = useTransformationActionContext();
  const { getSpaceComponentDisplayName } = useStableSpaceContext();
  const { getAncestorComponents } = useSpaceContext();
  const { dispatch, shouldDisplayError } = useSpaceConfigContext();
  const { scrollTop } = useVolatileCanvasViewportContext();
  const path = useComponentPathContext();

  const isFirstInstance = React.useMemo(() => getIsFirstInstance(path), [path]);

  const { isManaged, isNested, isHeaderChild } = useNestedStatus();

  const confirmRemoveComponent = React.useCallback(() => {
    if (confirmRemoveModalOpen) {
      return;
    }

    setConfirmRemoveModalOpen(true);
    Modal.confirm({
      title: "Are you sure?",
      content: getSpaceComponentDeleteCopy(component),
      mask: false,
      onOk: () => {
        deselect(component.slug);
        dispatch({
          type: "REMOVE_COMPONENT",
          payload: { slug: component.slug }
        });
        setConfirmRemoveModalOpen(false);
      },
      onCancel: () => {
        setConfirmRemoveModalOpen(false);
      }
    });
  }, [
    component,
    confirmRemoveModalOpen,
    deselect,
    dispatch,
    setConfirmRemoveModalOpen
  ]);

  const isRemovable =
    component.isRemovable &&
    (isHeaderChild || !isManaged) &&
    component.type !== "HEADER";

  const handlers = React.useMemo(() => {
    return { DELETE_COMPONENT: isRemovable ? confirmRemoveComponent : () => null };
  }, [confirmRemoveComponent, isRemovable]);

  // Only render the tag for first instance if repeated
  if (!isFirstInstance) return null;

  const componentName = getSpaceComponentDisplayName(component);

  let ancestors = getAncestorComponents(component.slug);
  ancestors = ancestors.slice(0, ancestors.length - 1);
  const ComponentName =
    mode === "HOVERED"
      ? HoveredComponentName
      : mode === "ACTIVE_DROP_TARGET"
      ? ActiveDropTargetComponentName
      : isNested
      ? NestedSelectedComponentName
      : SelectedComponentName;

  let style = {};
  if (ComponentName !== SelectedComponentName) {
    style = { transform: `translate3d(0, ${-1 * scrollTop}px, 0)` };
  }

  return (
    <Root
      style={style}
      attach={window}
      focused={true}
      handlers={handlers}
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <ComponentName
        onClick={() => {
          setAncestorMenuOpen(!ancestorMenuOpen);
        }}
      >
        {shouldDisplayError(component.slug) && <ErrorIcon type="warning" />}
        <span title={componentName}>{componentName}</span>
        {isNested && mode === "SELECTED" && (
          <>
            &nbsp;
            <Icon type={ancestorMenuOpen ? "up" : "down"} />
          </>
        )}
      </ComponentName>
      {ancestorMenuOpen && (
        <AncestorMenu>
          {ancestors.map(a => (
            <AncestorMenuItem
              key={a.slug}
              title={getSpaceComponentDisplayName(a)}
              onClick={() => {
                select(a.slug);
              }}
            >
              {getSpaceComponentDisplayName(a)}
            </AncestorMenuItem>
          ))}
        </AncestorMenu>
      )}
      {mode === "SELECTED" && (
        <ActionContainer>
          {isRemovable && (
            <ActionButton
              type="link"
              icon="delete"
              title="Remove component"
              onClick={() => confirmRemoveComponent()}
            />
          )}
        </ActionContainer>
      )}
    </Root>
  );
}

const ICON_SIZE = "12px";

const Root = styled(HotKeys)`
  position: fixed;
  pointer-events: auto;
  display: flex;
  margin-top: -18px;
  margin-left: 0px;
  width: fit-content;
  user-select: none;
  -webkit-touch-callout: none;
`;

const ErrorIcon = styled(Icon)`
  margin: 0 2px;
  color: white;
  font-size: ${props => props.theme.smallFontSize};
  line-height: ${ICON_SIZE};
`;

const BaseComponentName = styled.div`
  padding: 0 2px;
  max-width: 14em;
  font-size: ${props => props.theme.smallFontSize};
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const SelectedComponentName = styled(BaseComponentName)`
  background: ${props => props.theme.primaryColor};
  color: white;
`;

const NestedSelectedComponentName = styled(SelectedComponentName)`
  cursor: pointer;
`;

const HoveredComponentName = styled(BaseComponentName)`
  background: transparent;
  color: ${props => props.theme.midGrey};
`;

const ActiveDropTargetComponentName = styled(BaseComponentName)`
  background: ${props => props.theme.primaryColor};
  color: white;
  margin: 0 auto;
`;

const ActionContainer = styled.div`
  display: flex;
`;

const ActionButton = styled(Button)`
  margin: 1px 3px 0 3px;
  width: ${ICON_SIZE};
  height: ${ICON_SIZE};
  font-size: ${ICON_SIZE};
  line-height: ${ICON_SIZE};
`;

const AncestorMenu = styled.ul`
  position: absolute;
  bottom: 18px;
  margin: 0;
  padding: 0;
  background-color: ${props => props.theme.selectMenuBackgroundColor};
  border: solid 1px ${props => props.theme.primaryColor};
  border-bottom: none;
  width: max-content;
  cursor: pointer;
`;

const AncestorMenuItem = styled.li`
  font-size: ${props => props.theme.smallFontSize};
  line-height: ${props => props.theme.defaultFontSize};
  list-style: none;
  padding: 0;
  border-bottom: solid 1px ${props => props.theme.primaryColor};
  color: ${props => props.theme.primaryColor};
  width: 100%;
  cursor: pointer;

  &:hover {
    background: ${props => props.theme.rowHoverColor};
  }
`;
