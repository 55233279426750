import gql from "graphql-tag";

import { RelayNode } from "../../../../types";
import { Provider } from "../index";

export const SCM_CONFIG_FRAGMENT = gql`
  fragment ScmConfigFragment on ScmConfigNode {
    id
    provider
    githubAppAppId
    githubAppBranch
    githubAppEnterpriseUrl
    githubAppEnterpriseApiUrl
    githubAppInstallationId
    githubAppOwner
    githubAppRepo
  }
`;

export interface ScmConfigNode extends RelayNode {
  __typename: "ScmConfigNode";
  id: string;
  provider: Provider;
  githubAppAppId: number;
  githubAppBranch: string;
  githubAppEnterpriseUrl: string;
  githubAppEnterpriseApiUrl: string;
  githubAppInstallationId: number;
  githubAppOwner: string;
  githubAppRepo: string;
}
