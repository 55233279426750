import React from "react";

import styled from "styled-components";

import GitHubLogo from "../../../../../assets/github-mark.svg";
import ButtonNew from "../../../../common/ButtonNew/ButtonNew";

const StyledGitHubLogo = styled(GitHubLogo)`
  width: 17.5px;
  margin-right: 9.25px;
  flex-shrink: 0;
`;

const StyledButtonNew = styled(ButtonNew)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface Props {
  className?: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
}

export default function ManageButton({ className, onClick = _ => null }: Props) {
  return (
    <StyledButtonNew className={className} block onClick={onClick}>
      <StyledGitHubLogo />
      Manage with GitHub
    </StyledButtonNew>
  );
}
