import { ApolloClient } from "apollo-client";
import deepmerge from "deepmerge";
import { uniqueId, get } from "lodash";

import { ReturnSchema } from "../../../constants";
import {
  FunctionParameterNode,
  FunctionAttributeNode,
  FunctionNode,
  DataValue,
  ClientErrorResult,
  PermissionErrorResult,
  ExecuteFunctionResultSuccess,
  SpaceComponentObject,
  Binding,
  BindingShape,
  attributeToScalar,
  SpaceFunctionType
} from "../../../types";
import executeGatewayFunction, {
  encodeParameters
} from "../../common/executeGatewayFunction";
import {
  CustomError,
  ClientError,
  RemoteFunctionError,
  PermissionError,
  GENERIC_ERROR_MESSAGE
} from "../../common/executeGatewayFunction/errors";
import { fromGlobalId } from "../../util/graphql";
import { SubmittableComponentProps } from "../SpaceRoot/SpaceComponent/common/FunctionBackedPopover/reducer/reducer";

import { EXECUTE_FUNCTION } from "./queries";

type FunctionExecutionRecord = Record<string, FunctionExecutionState>;
export type FunctionExecutionState = {
  status: FunctionExecutionStatus;
  value: FunctionResult;
  metadata: unknown;
};
export type FunctionResult =
  | Record<string, DataValue | FunctionExecutionState | undefined>
  | DataValue
  | undefined;
export enum FunctionExecutionStatus {
  PENDING,
  IN_PROGRESS,
  COMPLETED,
  FAILED
}

export enum ExecutionParamType {
  BINDING,
  VALUE
}
interface ValueParam {
  type: ExecutionParamType.VALUE;
  value: DataValue;
}
interface BindingParam {
  type: ExecutionParamType.BINDING;
  value: string;
}
type ExecutionParam = ValueParam | BindingParam;

export type ExecutionParams = Record<string, ExecutionParam>;

class ExecutionContext {
  private store: Record<string, FunctionExecutionRecord>;
  public rootFunction: SpaceFunction;
  public executionId: string;
  public spaceId: string | undefined;
  public apolloClient: ApolloClient<any>;

  constructor(
    fn: SpaceFunction,
    executionId: string,
    spaceId: string | undefined,
    apolloClient: ApolloClient<any>,
    store: Record<string, FunctionExecutionRecord>
  ) {
    this.rootFunction = fn;
    this.executionId = executionId;
    this.spaceId = spaceId;
    this.apolloClient = apolloClient;
    this.store = store;
  }

  get result() {
    return this.store[this.executionId];
  }
}

export interface FunctionExecutionResult {
  executionState: FunctionExecutionState;
  metadata: unknown;
  value: FunctionResult;
}
type ProgressCallback = (update: FunctionExecutionResult) => void;

export class FunctionExecutor {
  private store: Record<string, FunctionExecutionRecord>;
  private executions: Map<string, FunctionExecution> = new Map();
  private subscriptions: Map<string, ProgressCallback[]> = new Map();
  private apolloClient: ApolloClient<any>;
  private spaceId: string | undefined;

  constructor(apolloClient: ApolloClient<any>, spaceId?: string) {
    this.store = {};
    this.apolloClient = apolloClient;
    this.spaceId = spaceId;
  }

  private getFunctionExecutionRecord({ executionId, rootFunction }: ExecutionContext) {
    return this.store[executionId][rootFunction.name];
  }

  private getFunctionExecutionResult(
    context: ExecutionContext
  ): FunctionExecutionResult {
    const execution = this.executions.get(context.executionId);
    return {
      executionState: this.getFunctionExecutionRecord(context),
      value: execution
        ? execution.getResolvedResult(context.result[context.rootFunction.name])
        : undefined,
      metadata: execution
        ? context.result[context.rootFunction.name].metadata
        : undefined
    };
  }

  public updateProgress(context: ExecutionContext, update: FunctionExecutionRecord) {
    const { executionId } = context;
    this.store[executionId] = deepmerge(this.store[executionId], update);
    const subs = this.subscriptions.get(executionId);
    if (subs === undefined)
      throw new Error("Expected subscriptions for executing function.");
    subs.forEach(c => c(this.getFunctionExecutionResult(context)));
  }

  public async execute(
    fn: SpaceFunction,
    params: ExecutionParams,
    onProgress: ProgressCallback,
    environmentId?: string
  ) {
    const executionId = uniqueId("functionExecution");
    this.store[executionId] = {
      [fn.name]: {
        status: FunctionExecutionStatus.PENDING,
        value: {},
        metadata: {}
      }
    };
    this.subscriptions.set(executionId, [onProgress]);
    const executionContext = new ExecutionContext(
      fn,
      executionId,
      this.spaceId,
      this.apolloClient,
      this.store
    );
    const execution = createFunctionExecution(
      fn,
      executionContext,
      (update: FunctionExecutionRecord) => {
        this.updateProgress(executionContext, update);
      }
    );
    this.executions.set(executionId, execution);
    await execution.execute(params, environmentId);
    return this.getFunctionExecutionResult(execution.context);
  }
}

function createFunctionExecution(
  fn: SpaceFunction,
  executionContext: ExecutionContext,
  onProgress: (state: FunctionExecutionRecord) => void
) {
  switch (fn.type) {
    case SpaceFunctionType.REMOTE:
      return new RemoteFunctionExecution(
        fn as RemoteFunction,
        executionContext,
        onProgress
      );
    case SpaceFunctionType.PIPELINE:
      return new PipelineFunctionExecution(
        fn as PipelineFunction,
        executionContext,
        onProgress
      );
    case SpaceFunctionType.REMOTE_PIPELINE:
      return new RemotePipelineFunctionExecution(
        fn as RemotePipelineFunction,
        executionContext,
        onProgress
      );
    default:
      throw new Error("Unexpected function type.");
  }
}

class FunctionExecution<T extends SpaceFunction = SpaceFunction> {
  protected params?: ExecutionParams;
  protected environmentId?: string;
  protected _handleProgress: (state: FunctionExecutionRecord) => void;
  public status: FunctionExecutionStatus;
  public fn: T;
  public context: ExecutionContext;

  constructor(
    fn: T,
    executionContext: ExecutionContext,
    onProgress: (state: FunctionExecutionRecord) => void
  ) {
    this.status = FunctionExecutionStatus.PENDING;
    this.fn = fn;
    this.context = executionContext;
    this._handleProgress = onProgress;
  }

  public getResolvedResult(
    _executionState: FunctionExecutionState
  ): Record<string, DataValue> | DataValue | undefined {
    return {};
  }

  protected handleProgress(result?: FunctionResult | null, metadata?: unknown) {
    this._handleProgress({
      [this.fn.name]: {
        value: result,
        status: this.status,
        metadata: metadata
      }
    });
  }

  public async execute(params: ExecutionParams, environmentId?: string) {
    this.status = FunctionExecutionStatus.IN_PROGRESS;
    this.params = params;
    this.environmentId = environmentId;
  }
}

class PipelineFunctionExecution<T extends PipelineFunction> extends FunctionExecution {
  private step = 0;
  private functionExecutions: FunctionExecution[];
  public fn: PipelineFunction;

  constructor(
    fn: T,
    executionContext: ExecutionContext,
    onProgress: (state: FunctionExecutionRecord) => void
  ) {
    super(fn, executionContext, onProgress);
    this.fn = fn;
    this._handleProgress = onProgress;
    this.handleProgress();
    this.functionExecutions = fn.spaceFunctions.map(sf =>
      createFunctionExecution(
        sf,
        executionContext,
        (progressUpdate: FunctionExecutionRecord) => this.handleProgress(progressUpdate)
      )
    );
  }

  get type() {
    return SpaceFunctionType.PIPELINE;
  }

  public getResolvedResult(executionState: FunctionExecutionState) {
    const ownState = executionState.value;
    if (ownState === null || ownState === undefined) return undefined;
    const resolvedState: Record<string, DataValue | undefined> = {};
    this.functionExecutions.forEach(fe => {
      const childState = (ownState as any)[fe.fn.name];
      resolvedState[fe.fn.name] = childState
        ? fe.getResolvedResult(childState)
        : undefined;
    });
    return resolvedState as Record<string, DataValue>;
  }

  private resolveParamValue(param: ExecutionParam): ValueParam {
    if (param.type === ExecutionParamType.VALUE) {
      return param;
    } else if (param.type === ExecutionParamType.BINDING) {
      const resolvedParamValue = get(
        {
          lastExecutionResult: this.getResolvedResult(
            this.context.result[this.context.rootFunction.name]
          )
        },
        param.value
      );
      if (resolvedParamValue === undefined) {
        throw new CustomError(
          "Expected to find binding param value in resolved context.",
          {
            friendlyMessage:
              "A function in your pipeline failed to return a required value. There may be a problem with your data source."
          }
        );
      }
      return { type: ExecutionParamType.VALUE, value: resolvedParamValue };
    }
    throw new Error("Unexpected param type.");
  }

  public async execute(params: ExecutionParams) {
    this.status = FunctionExecutionStatus.IN_PROGRESS;
    const parameterMappings = this.fn.functionParameterMapping;
    while (this.step < this.functionExecutions.length) {
      const nextStep = this.functionExecutions[this.step];
      const parameterMapping = parameterMappings.get(nextStep.fn);
      const nextParamNames = nextStep.fn.functionParameters.map(ip => {
        const mappedName = parameterMapping?.[ip.name];
        if (mappedName === undefined) {
          throw new Error("Expected to find parameter in mapping.");
        }
        return mappedName;
      });
      const nextParams = Object.entries(params).reduce<Record<string, ExecutionParam>>(
        (acc, [key, param]) => {
          if (nextParamNames.includes(key)) {
            // Reverse disambiguation of parameter names before executing func
            const originalParamName = Object.entries(parameterMapping!).find(
              ([_, disambiguated]) => key === disambiguated
            )![0];
            acc[originalParamName] = this.resolveParamValue(param);
          }
          return acc;
        },
        {}
      );
      await nextStep.execute(nextParams);
      this.step++;
    }
    this.status = FunctionExecutionStatus.COMPLETED;
    this.handleProgress({}, {}); // Final progress update with COMPLETED status
  }
}

export interface RemoteFunctionCallResult {
  executeFunction:
    | ExecuteFunctionResultSuccess
    | ClientErrorResult
    | PermissionErrorResult;
}

export interface FunctionCallResultSuccess {
  executeFunction: ExecuteFunctionResultSuccess;
}

class RemoteFunctionExecution extends FunctionExecution {
  public fn: RemoteFunction;

  constructor(
    fn: RemoteFunction,
    executionContext: ExecutionContext,
    onProgress: (state: FunctionExecutionRecord) => void
  ) {
    super(fn, executionContext, onProgress);
    this.fn = fn;
    this.handleProgress();
  }

  get type() {
    return SpaceFunctionType.REMOTE;
  }

  public getResolvedResult(executionState: FunctionExecutionState) {
    return executionState.value as DataValue | undefined;
  }

  private handleResult(data: RemoteFunctionCallResult) {
    const { executeFunction } = data;
    switch (executeFunction.__typename) {
      case "ExecuteFunctionResultSuccess":
        this.status = FunctionExecutionStatus.COMPLETED;
        this.handleProgress(executeFunction.value, executeFunction.metadata);
        break;
      case "PermissionErrorResult":
        this.status = FunctionExecutionStatus.FAILED;
        throw new PermissionError(executeFunction.message || GENERIC_ERROR_MESSAGE);
      case "ClientErrorResult":
        this.status = FunctionExecutionStatus.FAILED;
        throw new ClientError(executeFunction.message, executeFunction);
      default:
        throw new RemoteFunctionError(GENERIC_ERROR_MESSAGE);
    }
  }

  public async execute(params: Record<string, ExecutionParam>) {
    this.status = FunctionExecutionStatus.IN_PROGRESS;
    this.handleProgress(undefined, undefined);
    try {
      const result = await this.context.apolloClient.mutate({
        mutation: EXECUTE_FUNCTION,
        variables: {
          functionId: this.fn.id,
          spaceId: this.context.spaceId,
          parameters: Object.entries(params).reduce<Record<string, DataValue>>(
            (acc, [key, param]) => {
              acc[key] = param.value;
              return acc;
            },
            {}
          )
        }
      });
      this.handleResult(result.data);
    } catch (err) {
      const error = err as Error;
      if (error instanceof CustomError) throw err;
      throw new RemoteFunctionError(GENERIC_ERROR_MESSAGE, { cause: error });
    }
  }
}

class RemotePipelineFunctionExecution extends FunctionExecution {
  public fn: RemotePipelineFunction;

  constructor(
    fn: RemotePipelineFunction,
    executionContext: ExecutionContext,
    onProgress: (state: FunctionExecutionRecord) => void
  ) {
    super(fn, executionContext, onProgress);
    this.fn = fn;
    this.handleProgress();
  }

  get type() {
    return SpaceFunctionType.REMOTE_PIPELINE;
  }

  public getResolvedResult(executionState: FunctionExecutionState) {
    return executionState.value as DataValue | undefined;
  }

  private handleResult(data: DataValue) {
    this.status = FunctionExecutionStatus.COMPLETED;
    // TODO: What should we do about function metadata?
    this.handleProgress(data);
  }

  public async execute(
    executionParams: Record<string, ExecutionParam>,
    environmentId?: string
  ) {
    this.status = FunctionExecutionStatus.IN_PROGRESS;
    this.handleProgress(undefined, undefined);
    try {
      const { spaceId } = this.context;
      const params = encodeParameters(
        Object.entries(executionParams).reduce<Record<string, DataValue>>(
          (acc, [key, param]) => {
            acc[key] = param.value;
            return acc;
          },
          {}
        ),
        this.fn.functionParameters
      );
      const result = await executeGatewayFunction(
        fromGlobalId(this.fn.id)[1],
        environmentId ? fromGlobalId(environmentId)[1] : undefined,
        spaceId ? fromGlobalId(spaceId)[1] : undefined,
        params
      );
      this.handleResult(result);
    } catch (err) {
      const error = err as Error;
      this.status = FunctionExecutionStatus.FAILED;
      this.handleProgress();
      if (error instanceof CustomError) throw err;
      throw new CustomError("Your changes could not be made. Please try again later.", {
        cause: error
      });
    }
  }
}

export function disambiguateName(
  name: string,
  fn: FunctionNode,
  qualify = false,
  index?: number
) {
  if (!qualify) return name;
  return `${fn.dataSource?.name}__${fn.name}__${name}${index || ""}`;
}

interface MemberDescriptor {
  fn: FunctionNode;
  fnIndex: number;
}
export interface FunctionParameterDescriptor extends MemberDescriptor {
  param: FunctionParameterNode;
}

export interface FunctionAttributeDescriptor extends MemberDescriptor {
  attr: FunctionAttributeNode;
}

abstract class BaseSpaceFunction implements FunctionNode {
  protected _functionConfig: FunctionNode;
  abstract readonly type: SpaceFunctionType;

  constructor(func: SpaceFunctionConfig) {
    this._functionConfig = func;
  }

  get id() {
    return this._functionConfig.id;
  }

  get config() {
    return this._functionConfig;
  }

  get name() {
    return this._functionConfig.name;
  }

  get returnSchema() {
    return this._functionConfig.returnSchema;
  }

  get title() {
    return this._functionConfig.title;
  }

  get metadata() {
    return this._functionConfig.metadata;
  }

  public getSchema(_isRoot?: boolean): Binding {
    return {
      shape: BindingShape.UNKNOWN,
      name: "unknown"
    };
  }

  public describeFunctionParameter(
    _parameterName: string
  ): FunctionParameterDescriptor | undefined {
    throw new Error("Abstract base method called.");
  }

  public describeFunctionAttribute(
    _attributeName: string
  ): FunctionAttributeDescriptor | undefined {
    throw new Error("Abstract base method called.");
  }
}

abstract class EmptyFieldsBaseSpaceFunction extends BaseSpaceFunction {
  readonly functionParameters: FunctionParameterNode[] = [];
  readonly functionAttributes: FunctionAttributeNode[] = [];
}

function ensureUniqueString(str: string, usedStrings: Set<string>) {
  let nextStr = str;
  let idx = 1;
  while (usedStrings.has(nextStr)) {
    nextStr = str + idx++;
  }
  usedStrings.add(nextStr);
  return nextStr;
}

class PipelineFunction extends BaseSpaceFunction {
  private _spaceFunctions: null | SpaceFunction[] = null;
  protected _functionConfig: PipelineFunctionConfig;
  readonly type = SpaceFunctionType.PIPELINE;

  constructor(func: PipelineFunctionConfig) {
    super(func);
    this._functionConfig = func;
  }

  private getMemberNameMapping(memberKey: "functionAttributes" | "functionParameters") {
    const usedNames = new Set<string>();
    return this.spaceFunctions.reduce<Map<SpaceFunction, Record<string, string>>>(
      (acc, fn) => {
        const members = fn[memberKey] as FunctionParameterNode[];
        acc.set(
          fn,
          members.reduce<Record<string, string>>((acc, attr) => {
            acc[attr.name] = ensureUniqueString(
              disambiguateName(attr.name, fn.config, true),
              usedNames
            );
            return acc;
          }, {})
        );
        return acc;
      },
      new Map()
    );
  }

  get spaceFunctions(): SpaceFunction[] {
    if (this._spaceFunctions !== null) {
      return this._spaceFunctions;
    }
    // NOTE: For now assume all child functions of a pipeline
    //       are remote functions. Will need to change once
    //       other function types are introduced or we allow for
    //       nested pipelines.
    const spaceFunctions = this._functionConfig.functions.map(fn =>
      _createSpaceFunction({ ...fn, type: SpaceFunctionType.REMOTE })
    );
    this._spaceFunctions = spaceFunctions;
    return spaceFunctions;
  }

  get functionParameters(): FunctionParameterNode[] {
    const parameterNameMapping = this.functionParameterMapping;
    return this.spaceFunctions.flatMap(sf => {
      const funcMapping = parameterNameMapping.get(sf);
      if (funcMapping === undefined) {
        throw new Error("Expected to find function parameter mapping.");
      }
      return sf.functionParameters.map(fp => ({
        ...fp,
        name: funcMapping[fp.name]
      }));
    });
  }

  get functionAttributes(): FunctionAttributeNode[] {
    const attributeNameMapping = this.functionAttributeMapping;
    return this.spaceFunctions.flatMap(sf => {
      const funcMapping = attributeNameMapping.get(sf);
      if (funcMapping === undefined) {
        throw new Error("Expected to find function attribute mapping.");
      }
      return sf.functionAttributes.map(fa => ({
        ...fa,
        name: funcMapping[fa.name]
      }));
    });
  }

  get returnSchema() {
    return ReturnSchema.OBJECT;
  }

  get functionParameterMapping() {
    return this.getMemberNameMapping("functionParameters");
  }

  get functionAttributeMapping() {
    return this.getMemberNameMapping("functionAttributes");
  }

  getSchema(isRoot = false): Binding {
    return {
      shape: BindingShape.OBJECT,
      title: isRoot ? "Last execution result" : this.title,
      name: isRoot ? "lastExecutionResult" : this.name,
      attributes: this.spaceFunctions.map(sf => sf.getSchema())
    };
  }

  private describeMember(
    name: string,
    memberType: "functionParameter" | "functionAttribute"
  ) {
    let fn: SpaceFunction | null = null;
    let originalMemberName: string | null = null;
    let memberFound = false;
    const mapping =
      memberType === "functionParameter"
        ? this.functionParameterMapping
        : this.functionAttributeMapping;
    const fnEntries: [SpaceFunction, Record<string, string>][] =
      this.spaceFunctions.map(sf => [sf, mapping.get(sf)!]);
    let fnIndex = -1;
    for (let i = 0; i < fnEntries.length; i++) {
      if (memberFound) break;
      const memberEntries = Object.entries(fnEntries[i][1]);
      for (let j = 0; j < memberEntries.length; j++) {
        if (memberEntries[j][1] === name) {
          originalMemberName = memberEntries[j][0];
          fn = fnEntries[i][0];
          memberFound = true;
          fnIndex = i;
          break;
        }
      }
    }
    if (fn === null || originalMemberName === null) {
      return undefined;
    }
    const members: { name: string }[] =
      memberType === "functionParameter"
        ? fn.functionParameters
        : fn.functionAttributes;
    const member = members.find(m => m.name === originalMemberName)!;
    return {
      fn: fn.config,
      fnIndex,
      member
    };
  }

  public describeFunctionParameter(
    paramName: string
  ): FunctionParameterDescriptor | undefined {
    const desc = this.describeMember(paramName, "functionParameter");
    if (!desc) return undefined;
    const { member, ...others } = desc;
    return {
      ...others,
      param: member as FunctionParameterNode
    };
  }

  public describeFunctionAttribute(
    attrName: string
  ): FunctionAttributeDescriptor | undefined {
    const desc = this.describeMember(attrName, "functionAttribute");
    if (!desc) return undefined;
    const { member, ...others } = desc;
    return {
      ...others,
      attr: member as FunctionAttributeNode
    };
  }
}

const returnSchemaToBindingShape: Record<ReturnSchema, BindingShape> = {
  [ReturnSchema.UNKNOWN]: BindingShape.UNKNOWN,
  [ReturnSchema.OBJECT]: BindingShape.OBJECT,
  [ReturnSchema.OBJECT_ARRAY]: BindingShape.OBJECT_ARRAY
};

abstract class BaseRemoteFunction extends BaseSpaceFunction {
  get functionParameters(): FunctionParameterNode[] {
    return this._functionConfig.functionParameters?.edges.map(({ node }) => node) || [];
  }

  get functionAttributes(): FunctionAttributeNode[] {
    return this._functionConfig.functionAttributes?.edges.map(e => e.node) || [];
  }

  public getSchema(isRoot = false) {
    return {
      shape: returnSchemaToBindingShape[this.returnSchema],
      title: isRoot ? "Last execution result" : this.title,
      name: isRoot ? "lastExecutionResult" : this.name,
      attributes: this.functionAttributes.map(a =>
        attributeToScalar(a, this._functionConfig.access?.onlyAttributes || null)
      )
    };
  }

  public describeFunctionParameter(paramName: string) {
    const param = this.functionParameters.find(fp => fp.name === paramName);
    if (!param) return undefined;
    return {
      fn: this.config,
      param,
      fnIndex: 0
    };
  }

  public describeFunctionAttribute(attrName: string) {
    const attr = this.functionAttributes.find(fa => fa.name === attrName);
    if (!attr) return undefined;
    return {
      fn: this.config,
      attr,
      fnIndex: 0
    };
  }
}

class RemoteFunction extends BaseRemoteFunction {
  readonly type = SpaceFunctionType.REMOTE;
  protected _functionConfig: RemoteFunctionConfig;

  constructor(fn: RemoteFunctionConfig) {
    super(fn);
    this._functionConfig = fn;
  }
}

class RemotePipelineFunction extends BaseRemoteFunction {
  readonly type = SpaceFunctionType.REMOTE_PIPELINE;

  protected _functionConfig: RemotePipelineFunctionConfig;

  constructor(fn: RemotePipelineFunctionConfig) {
    super(fn);
    this._functionConfig = fn;
  }
}

export class VoidFunction extends EmptyFieldsBaseSpaceFunction {
  readonly type = SpaceFunctionType.VOID;
}

class InvalidFunction extends EmptyFieldsBaseSpaceFunction {
  readonly type = SpaceFunctionType.INVALID;
}

class NotVisibleFunction extends EmptyFieldsBaseSpaceFunction {
  readonly type = SpaceFunctionType.NOT_VISIBLE;
}

export { PipelineFunction, RemoteFunction, InvalidFunction };

export type SpaceFunction =
  | VoidFunction
  | PipelineFunction
  | RemoteFunction
  | RemotePipelineFunction
  | InvalidFunction
  | NotVisibleFunction;

interface PipelineFunctionConfig extends FunctionNode {
  type: SpaceFunctionType.PIPELINE;
  functions: SpaceFunctionConfig[];
}

export interface RemoteFunctionConfig extends FunctionNode {
  type: SpaceFunctionType.REMOTE;
}

export interface RemotePipelineFunctionConfig extends FunctionNode {
  type: SpaceFunctionType.REMOTE_PIPELINE;
}

export interface VoidFunctionConfig extends FunctionNode {
  type: SpaceFunctionType.VOID;
}

interface InvalidFunctionConfig extends FunctionNode {
  type: SpaceFunctionType.INVALID;
}

interface NotVisibleFunctionConfig extends FunctionNode {
  type: SpaceFunctionType.NOT_VISIBLE;
}

type SpaceFunctionConfig =
  | VoidFunctionConfig
  | PipelineFunctionConfig
  | RemoteFunctionConfig
  | RemotePipelineFunctionConfig
  | InvalidFunctionConfig
  | NotVisibleFunctionConfig;

function deriveSpaceFunctionConfig(
  component: SpaceComponentObject
): SpaceFunctionConfig {
  const functions = component.functions.edges.map(e => e.node);
  const notVisibleFunctions =
    component.notVisibleFunctions?.edges.map(e => e.node) || [];

  if (!!component.properties.presign_function_id) {
    return {
      id: component.properties.presign_function_id,
      name: "presign_function",
      type: SpaceFunctionType.REMOTE,
      returnSchema: ReturnSchema.UNKNOWN
    };
  }
  if (functions.length === 1 && functions[0]?.isPipeline) {
    return {
      ...functions[0],
      type: SpaceFunctionType.REMOTE_PIPELINE
    };
  }

  const properties = component.properties as SubmittableComponentProps;
  if (functions[0] && !properties.function_pipeline) {
    // as unknown, otherwise typescript complains of no overlap between types.
    const typedFunction = functions[0] as unknown as { type: SpaceFunctionType };
    if (typedFunction.type && typedFunction.type === SpaceFunctionType.NOT_VISIBLE) {
      return { ...functions[0], type: SpaceFunctionType.NOT_VISIBLE };
    }
    return { ...functions[0], type: SpaceFunctionType.REMOTE };
  } else if (notVisibleFunctions[0] && !properties.function_pipeline) {
    return {
      ...notVisibleFunctions[0],
      name: "unknown",
      returnSchema: ReturnSchema.UNKNOWN,
      type: SpaceFunctionType.NOT_VISIBLE
    };
  } else if (Array.isArray(properties.function_pipeline)) {
    const functionMap = functions.reduce<Record<string, FunctionNode>>((acc, curr) => {
      acc[curr.id] = curr;
      return acc;
    }, {});
    const hasMissingFunctions = properties.function_pipeline.some(
      (fp: string) => !functionMap[fp]
    );
    if (hasMissingFunctions) {
      return {
        type: SpaceFunctionType.INVALID,
        id: "invalidFunction",
        name: "invalid_function",
        returnSchema: ReturnSchema.UNKNOWN
      };
    }
    const pipelineFunctions = properties.function_pipeline.map((fp: string) => ({
      ...functionMap[fp],
      type: SpaceFunctionType.REMOTE as const
    }));
    return {
      id: `pipeline${uniqueId()}`,
      name: "pipeline_function",
      type: SpaceFunctionType.PIPELINE,
      returnSchema: ReturnSchema.OBJECT,
      functions: pipelineFunctions
    };
  }
  return {
    type: SpaceFunctionType.VOID,
    id: "voidFunction",
    name: "empty_function",
    returnSchema: ReturnSchema.UNKNOWN
  };
}

function _createSpaceFunction(config: SpaceFunctionConfig) {
  switch (config.type) {
    case SpaceFunctionType.VOID: {
      return new VoidFunction(config);
    }
    case SpaceFunctionType.PIPELINE: {
      return new PipelineFunction(config);
    }
    case SpaceFunctionType.REMOTE: {
      return new RemoteFunction(config);
    }
    case SpaceFunctionType.REMOTE_PIPELINE: {
      return new RemotePipelineFunction(config);
    }
    case SpaceFunctionType.INVALID: {
      return new InvalidFunction(config);
    }
    case SpaceFunctionType.NOT_VISIBLE: {
      return new NotVisibleFunction(config);
    }
    default:
      throw new Error("Unexpected SpaceFunctionType.");
  }
}

export function createSpaceFunction(
  component: SpaceComponentObject,
  options: {
    overrideFunction?: FunctionNode;
  } = {}
): SpaceFunction {
  const config = options.overrideFunction
    ? { ...options.overrideFunction, type: SpaceFunctionType.REMOTE as const }
    : deriveSpaceFunctionConfig(component);
  return _createSpaceFunction(config);
}
