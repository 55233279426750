import gql from "graphql-tag";
import { QueryHookOptions, useQuery } from "react-apollo";

import { Connection, EnvironmentNode } from "../../../types";

export const QUERY = gql`
  query AllEnvironments {
    allEnvironments {
      __typename
      edges {
        __typename
        node {
          __typename
          id
          name
          isDefault
        }
      }
    }
  }
`;

export interface Data {
  allEnvironments: Connection<EnvironmentNode>;
}

export default function useGetAllEnvironments(options: QueryHookOptions<Data>) {
  return useQuery<Data>(QUERY, options);
}
