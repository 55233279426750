import React, { Dispatch } from "react";

import DataIcon from "../../../../assets/icons/data-icon.svg";
import SettingsIcon from "../../../../assets/icons/settings-icon.svg";
import StateIcon from "../../../../assets/icons/state-icon.svg";
import Navigation, { TabItem } from "../../../common/Navigation";
import { State } from "../reducer";
import { ReducerAction } from "../reducer/actions";
import { Tab } from "../types";

import * as styled from "./styledComponents";

export interface Item {
  tab: Tab;
  title: string;
  description: string;
}

interface Props {
  dispatch: Dispatch<ReducerAction>;
  state: State;
  tab: Tab;
  className?: string;
}

const tabs: TabItem[] = [
  {
    tab: Tab.GENERAL,
    title: "General settings",
    description: "Manage name, description, and who can see and access this queue.",
    icon: SettingsIcon
  },
  {
    tab: Tab.DATA,
    title: "Data",
    description: "Manage data shown in your queue.",
    icon: DataIcon
  },
  {
    tab: Tab.STATES,
    title: "States and actions",
    description:
      "Manage states and call-to-actions for your queue. Each state can have its own set of unique actions.",
    icon: StateIcon
  }
];

const QueuesNavigation = ({ dispatch, tab, state, className = "" }: Props) => {
  return (
    <div className={className}>
      <Navigation tabs={tabs} activeTab={tab} errors={{}} />
      {tab === Tab.STATES && (
        <>
          <styled.Divider />
          <styled.StateList state={state} dispatch={dispatch} />
        </>
      )}
    </div>
  );
};

export default QueuesNavigation;
