import React, { ChangeEvent } from "react";

import { Input } from "antd";
import { useFormContext, Controller } from "react-hook-form";

import ColorPicker from "../ColorPicker";
import { MaterialIconType } from "../Icons/MaterialIcons";
import { ErrorField } from "../StyledComponents";
import { ensureHexValue } from "../utils";

import * as styled from "./styledComponents";

export interface GeneralSettingsProps {
  name: string;
  description?: string;
  icon?: MaterialIconType;
  color?: string;
}

interface Props {
  title: string;
  state: GeneralSettingsProps;
  onNameChange: (evt: ChangeEvent<HTMLInputElement>) => void;
  onDescriptionChange: (evt: ChangeEvent<HTMLInputElement>) => void;
  onIconChange: (icon: string) => void;
  onColorChange: (color: string) => void;
}

export const GeneralSettings = (props: Props) => {
  const formContext = useFormContext();
  const {
    title,
    state,
    onNameChange,
    onDescriptionChange,
    onIconChange,
    onColorChange
  } = props;
  return (
    <div>
      <styled.Title>General Settings</styled.Title>
      <styled.SubTitle>Name and description</styled.SubTitle>

      <styled.Fieldset>
        <styled.Label>{title} name</styled.Label>
        {formContext ? (
          <>
            <Controller
              rules={{ required: "This is a required field." }}
              render={({ field }) => (
                <Input
                  {...field}
                  type="text"
                  placeholder="Enter name"
                  value={state.name}
                  onChange={value => {
                    field.onChange(value);
                    onNameChange(value);
                  }}
                />
              )}
              control={formContext.control}
              name="name"
              defaultValue={state.name}
            />
            {formContext.formState.errors?.name && (
              <ErrorField>{formContext.formState.errors.name.message}</ErrorField>
            )}
          </>
        ) : (
          <Input
            type="text"
            placeholder="Enter name"
            value={state.name}
            onChange={value => {
              onNameChange(value);
            }}
          />
        )}
      </styled.Fieldset>
      <styled.Fieldset>
        <styled.Label>Description (optional)</styled.Label>
        {formContext ? (
          <>
            <Controller
              rules={{
                maxLength: { value: 1000, message: "Must be 1000 characters or less" }
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  type="text"
                  placeholder="Add description"
                  value={state.description}
                  onChange={e => {
                    field.onChange(e);
                    onDescriptionChange(e);
                  }}
                />
              )}
              control={formContext.control}
              name="description"
              defaultValue={state.description}
            />
            {formContext.formState.errors?.description && (
              <ErrorField>
                {formContext.formState.errors.description.message}
              </ErrorField>
            )}
          </>
        ) : (
          <Input
            type="text"
            placeholder="Add description"
            value={state.description}
            onChange={onDescriptionChange}
          />
        )}
      </styled.Fieldset>
      <styled.IconColor>
        <styled.SelectContainer>
          <styled.Label>Icon (optional)</styled.Label>
          {formContext ? (
            <Controller
              render={({ field }) => (
                <styled.IconSelect
                  {...field}
                  value={state.icon}
                  onChange={icon => {
                    field.onChange(icon);
                    onIconChange(icon as string);
                  }}
                  color={state.color}
                />
              )}
              control={formContext.control}
              name="icon"
              defaultValue={state.icon}
            />
          ) : (
            <styled.IconSelect
              value={state.icon}
              onChange={icon => onIconChange(icon as string)}
              color={state.color}
            />
          )}
        </styled.SelectContainer>
        <div>
          <styled.Label>Color</styled.Label>
          {formContext ? (
            <Controller
              render={({ field }) => (
                <ColorPicker
                  {...field}
                  value={ensureHexValue(state.color || "")}
                  placement="bottom-end"
                  border={false}
                  onChange={e => {
                    field.onChange(e);
                    onColorChange(e);
                  }}
                />
              )}
              control={formContext.control}
              name="color"
              defaultValue={state.color}
            />
          ) : (
            <ColorPicker
              value={ensureHexValue(state.color || "")}
              border={false}
              placement="bottom-end"
              onChange={onColorChange}
            />
          )}
        </div>
        {formContext && formContext.formState.errors?.icon && (
          <ErrorField>{formContext.formState.errors.icon.message}</ErrorField>
        )}
        {formContext && formContext.formState.errors?.color && (
          <ErrorField>{formContext.formState.errors.color.message}</ErrorField>
        )}
      </styled.IconColor>
    </div>
  );
};

export default GeneralSettings;
