import React from "react";

import { Button, Form, Icon, Table as ATable } from "antd";
import { ButtonProps } from "antd/lib/button";
import styled, { createGlobalStyle } from "styled-components";

import { GoogleLogoPng } from "../../constants";
import { colors, Size, SpacingUnit } from "../../cssConstants";

import ButtonNew from "./ButtonNew/ButtonNew";

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const FormItem = styled(Form.Item)`
  margin-bottom: 12px;
  label {
    color: ${props => props.theme.labelColor};
  }
`;

export const FormItemNoBottomMargin = styled(Form.Item)`
  margin-bottom: 0px;
`;

export const ErrorField = styled.p`
  color: ${props => props.theme.errorColor};
  margin-top: 12px;
`;

export const FormErrorField = styled(ErrorField)`
  margin-top: ${props => props.theme.spacerxs};
  margin-bottom: 0;
`;
FormErrorField.displayName = "FormErrorField";

// same styles as config panel errors
export const ConfigErrorField = styled.p`
  color: ${props => props.theme.errorColor};
  margin-top: ${props => props.theme.spacerxs};
  font-size: ${props => props.theme.smallFontSize};
`;

export const FieldButton = styled.button`
  width: calc(50% - ${props => props.theme.spacerxs} * 2);
  min-width: 140px;
  padding: ${props => props.theme.spacermd};
  margin: ${props => props.theme.spacerxs};
  border: ${props => props.theme.greyBorder};
  border-radius: ${props => props.theme.borderRadiuslg};
  background-color: ${props => props.theme.backgroundColor};
  cursor: pointer;
  outline: none;
  text-align: left;
  transition: border-color 0.5s ease-out;

  &:hover {
    border-color: ${props => props.theme.primaryColor};
  }

  &:active {
    border-color: ${props => props.theme.primaryColor};
  }
`;
FieldButton.displayName = "FieldButton";

export const StyledTableHeader = styled.div`
  padding: ${props => props.theme.spacers.md};
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${Size.sm}) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    div + div {
      margin-top: ${SpacingUnit.sm};
    }
  }
`;

export const Table = styled(ATable)`
  overflow-x: auto;
  white-space: nowrap;
`;

export const DarkTheme = createGlobalStyle`
  html > body,
  body .ant-layout,
  body .ant-layout-footer {
    background-color: #1b1b1a;
    color: rgba(255, 255, 255, 0.87);
  }

  body .ant-form,
  h1, h2, h3, h4, h5, h6 {
    color: rgba(255, 255, 255, 0.87);
  }
`;

export const MainHeader = styled.header`
  padding: 48px 36px 20px 36px;
`;

export const MainTitle = styled.h1.attrs(() => ({ "data-test": "page-title" }))`
  font-size: 24px;
  font-style: normal;
  letter-spacing: -1.1px;
  color: ${props => props.theme.textColor};
  font-weight: 600;

  /* Ensure empty component has height and isn't collapsed */
  &:empty::after {
    content: "&nbsp;";
    visibility: hidden;
  }

  &.noMargin {
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
`;

export const SectionTitle = styled.h2`
  margin-bottom: ${props => props.theme.spacers.lg};
  color: ${props => props.theme.textColor};
  font-family: var(--body-font-family);
  font-size: 20px;
  font-weight: 600;
`;

export const Warning = styled.div`
  padding: ${props => props.theme.spacers.sm};
  text-align: center;
  background: ${props => props.theme.warningBackgroundColor};
  color: #ffffff;
  font-weight: 500;

  a,
  .ant-btn-link {
    display: inline-flex;
    padding: 0;
    color: #ffffff;
    text-decoration: underline;

    &:hover {
      color: #ffffff;
      text-decoration: none;
    }
  }
`;
Warning.displayName = "Warning";

export const GoogleLoginButton = styled(Button)`
  width: 100%;
  height: 40px;
  border-radius: ${props => props.theme.borderRadiussm};
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.24), 0 0 1px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;

  span:before {
    content: "";
    background-image: url(${GoogleLogoPng});
    background-size: 18px 18px;
    background-repeat: no-repeat;
    background-position-y: center;
    padding-right: 18px;
    margin-right: 17px;
  }
`;

export const BasePanel = styled.section`
  display: flex;
  flex-direction: column;
  margin-bottom: ${props => props.theme.spacermd};
  border: solid 1px ${props => props.theme.borderGrey};
  border-radius: ${props => props.theme.borderRadiuslg};
  background-color: white;

  @media (min-width: ${props => props.theme.breakpointmd}) {
    margin-bottom: ${props => props.theme.spacerlg};
  }

  @media (min-width: ${props => props.theme.breakpointlg}) {
    margin-bottom: ${props => props.theme.spacerxl};
  }
`;

export const LinkButton = styled(Button)`
  padding: 0;
  height: auto;
`;
LinkButton.displayName = "LinkButton";

export const Label = styled.div`
  font-size: 11px;
  color: ${props => props.theme.textColor};
  text-transform: uppercase;
`;

export const CodeExample = styled.pre`
  color: ${props => props.theme.primaryColor};
  font-size: ${props => props.theme.smallFontSize};
  display: inline;
`;

// Popper styles
export const PopperOverlay = styled.div`
  pointer-events: all;
  width: 100%;
  height: 100%;
`;
PopperOverlay.displayName = "PopperOverlay";

export const PopperContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: ${props => props.theme.buttonBorder};
  border-radius: 3px;
  background: ${props => props.theme.popperBackgroundColor};
  padding: ${props => props.theme.spacermd};
  margin: 16px;
  z-index: ${props => props.theme.zIndex.popoverOverDrawer};
  filter: ${props => `drop-shadow(${props.theme.dropShadow});`};
  color: ${props => props.theme.textColor};
`;

// styled adapted from https://github.com/popperjs/react-popper/blob/8994933c430e48ab62e71495be71e4f440b48a5a/demo/styles.js#L100
// linked from https://github.com/popperjs/react-popper#children
export const PopperArrow = styled.div`
  position: absolute;
  width: 3em;
  height: 3em;

  &[data-placement*="bottom"] {
    top: 0;
    left: 0;
    margin-top: -0.9em;
    width: 3em;
    height: 1em;
    &::before {
      border-width: 0 1.5em 1em 1.5em;
      border-color: transparent transparent
        ${props => props.theme.popperBackgroundColor} transparent;
    }
  }
  &[data-placement*="top"] {
    bottom: 0;
    left: 0;
    margin-bottom: -0.9em;
    width: 3em;
    height: 1em;
    &::before {
      border-width: 1em 1.5em 0 1.5em;
      border-color: ${props => props.theme.popperBackgroundColor} transparent
        transparent transparent;
    }
  }
  &[data-placement*="right"] {
    left: 0;
    margin-left: -0.9em;
    height: 3em;
    width: 1em;
    &::before {
      border-width: 1.5em 1em 1.5em 0;
      border-color: transparent ${props => props.theme.popperBackgroundColor}
        transparent transparent;
    }
  }
  &[data-placement*="left"] {
    right: 0;
    margin-right: -0.9em;
    height: 3em;
    width: 1em;
    &::before {
      border-width: 1.5em 0 1.5em 1em;
      border-color: transparent transparent transparent
        ${props => props.theme.popperBackgroundColor};
    }
  }
  &::before {
    content: "";
    margin: auto;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
  }
`;

export const Hr = styled.hr`
  height: 1px;
  margin: ${props => props.theme.spacermd} 0;
  background: ${props => props.theme.borderColor};
`;

export const H1 = styled.h1`
  font-size: 32px;
  line-height: 40px;
  font-family: var(--header-font-family);
`;

export const H2 = styled.h2`
  font-size: 28px;
  line-height: 36px;
  font-family: var(--header-font-family);
`;

export const H3 = styled.h3`
  font-size: 24px;
  line-height: 32px;
  font-family: var(--header-font-family);
  font-weight: 600;
  margin-bottom: ${props => props.theme.spacermd};
`;

export const H4 = styled.h4`
  font-size: 18px;
  line-height: 23px;
  font-family: var(--header-font-family);
  font-weight: 600;
  margin-bottom: ${props => props.theme.spacersm};
`;

export const H5 = styled.h5`
  font-size: 15px;
  line-height: 21px;
  font-family: var(--header-font-family);
  font-weight: 600;
`;

export const H6 = styled.h6`
  font-size: 13px;
  line-height: 19px;
  font-family: var(--header-font-family);
  font-weight: 600;
`;

export const B1 = styled.span`
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
`;

export const B2 = styled.span`
  font-size: 15px;
  line-height: 24px;
  font-weight: 500;
`;

export const B3 = styled.span`
  font-size: 13px;
  line-height: 19px;
  font-weight: 500;
  color: ${props => props.color || colors.surfacePrimary};
`;

export const C1 = styled.span`
  font-size: 12px;
  line-height: 16px;
  font-family: var(--body-font-family);
  font-weight: 600;
`;

export const C2 = styled.span`
  font-size: 12px;
  line-height: 16px;
  font-family: var(--body-font-family);
  font-weight: 500;
`;

export const D2 = styled.h2`
  font-size: 32px;
  line-height: 36px;
  font-family: var(--display-font-family);
  font-weight: 500;
`;

export const P = styled.p`
  font-size: 12px;
  color: ${props => props.theme.textColorMid};
`;

export const BigP = styled.p`
  font-size: 15px;
  line-height: 24px;
  color: ${props => props.theme.textColor};
`;

export const A = styled.a`
  font-size: 12px;
`;

export const Kbd = styled.kbd`
  margin: 0 0.2em;
  padding: 0.15em 0.4em 0.1em;
  background: rgba(150, 150, 150, 0.06);
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-bottom-width: 2px;
  border-radius: 3px;
`;

export const SmallLightText = styled.span`
  color: ${props => props.theme.textColorLight};
  font-size: 12px;
  line-height: 18px;
`;

export const TinyLightText = styled.span`
  color: ${props => props.theme.textColorLight};
  font-size: 11px;
  line-height: 13px;
`;

interface StyledLinkButtonProps {
  // number is used so we don't pass a boolean to DOM, which is invalid.
  // Styled 5.1 has transient props for this: https://styled-components.com/docs/api#transient-props
  inline?: number;
}

const StyledLinkButton = styled(Button)<StyledLinkButtonProps>`
  font-size: 12px;
  font-weight: 500;

  padding: ${props => (props.inline ? "0" : "6px 0")};

  // the latter styling copies antd
  border: ${props => (props.inline ? "none" : "1px solid transparent")};

  height: unset;
  span {
    white-space: break-spaces;
    text-align: left;
  }
`;

export const BTN1 = styled(Button)`
  border: none;
  font-size: 15px;
  line-height: 24px;
  font-family: var(--body-font-family);
  font-weight: 500;
`;

export const BTN2 = styled(Button)`
  border: none;
  font-size: 13px;
  line-height: 20px;
  font-family: var(--body-font-family);
  font-weight: 500;
`;

export interface LinkButtonProps extends ButtonProps {
  inline?: boolean;
}

export const LinkButtonNew = ({ children, inline, ...props }: LinkButtonProps) => {
  return (
    <StyledLinkButton type="link" inline={inline ? 1 : 0} {...props}>
      {children}
    </StyledLinkButton>
  );
};

export const Tabs = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.spacermd};
  flex-wrap: wrap;
  padding-bottom: ${props => props.theme.spacerlg};
`;

export const Tab = styled.span`
  padding: ${props => `${props.theme.spacerxs} 12px`};
  line-height: 16px;
  border-radius: 16px;
  white-space: nowrap;
  transition: 0.3s;
  max-width: 200px;
  overflow: hidden;
  cursor: pointer;
  user-select: none;
  &:hover {
    background: ${props => props.theme.borderColor};
  }
  &.active {
    color: white;
    background: ${props => props.theme.primaryColorDark};
    cursor: default;
    &:hover {
      background: ${props => props.theme.primaryColorDark};
    }
  }
  &.searchActive {
    opacity: 0.5;
    max-width: 0;
    padding: 0;
  }
`;

export const Pill = styled.span`
  background-color: ${props => props.color || props.theme.primaryColor};
  color: white;
  display: inline;
  padding: 2px 8px;
  font-size: 12px;
  border-radius: 4px;
`;

export const SurfaceSecondary = styled.span`
  color: ${props => props.theme.surfaceSecondary};
`;

export const SurfaceTertiary = styled.span`
  color: ${props => props.theme.surfaceTertiary};
`;

export const White = styled.span`
  color: #fff;
`;

export const WideButton = styled(ButtonNew)`
  width: 100%;
`;

export const StyledSelect = styled.div`
  select {
      appearance: none;
      background-color: transparent;
      border: none;
      padding: 14px;
      margin: 0;
      width: 100%;
      font-family: inherit;
      font-size: inherit;
      cursor: inherit;
      line-height: inherit;
      outline: none;
      grid-area: select;
    }

    .select {
      width: 100%;
      border: 1px solid ${props => props.theme.borderGrey};
      border-radius: 5px;
      cursor: pointer;
      line-height: 1.1;
      background-color: #fff;
      background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
      display: grid;
      grid-template-areas: "select";
      position: relative
      margin-bottom: 20px;

      &.error {
        border: 1px solid red;
      }

      &::after {
        content: "";
        width: 16px;
        height: 15px;
        background-color: ${props => props.theme.textColorMid};
        clip-path: polygon(20% 0, 12% 8%, 50% 46%, 88% 8%, 80% 0, 50% 30%);
        grid-area: select;
        position: absolute;
        right: 10px;
        top: 18px;
        pointer-events: none;
      }
    }
`;

export const Card = styled.section`
  border-radius: 6px;
  background: rgba(41, 41, 45, 0.04);
  border: 1px solid rgba(41, 41, 45, 0.13);
  padding: ${props => props.theme.spacermd};
`;

//// styles for application home pages

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: ${props => props.theme.spacersm};
`;

export const NewButton = styled(Button)`
  color: ${props => props.theme.textColor};
  font-size: 12px;
  font-weight: 500;
  padding-right: 0;
`;

export const IconButton = styled(Button)`
  font-size: 20px;
  color: ${props => props.theme.textColor};
`;

export const SecondaryButtonList = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: ${props => props.theme.spacerlg} 0;
  align-items: center;
`;

//// end of styles for application home pages

//// styles for application config pages

export const ConfigContainer = styled.div`
  display: flex;
  gap: 77px;
  /* TODO probably switch to a mobile UX before this width */
  min-width: 600px;

  font-size: 13px;
  color: ${props => props.theme.surfacePrimary};

  // Antd overrides. These should be global but don't want to bite that off yet.
  // We should also consolidate our antd overrides that are currently present
  // index.less and ThemeContainer
  .ant-input,
  .ant-select-selection-selected-value {
    color: ${props => props.theme.surfacePrimary};
  }

  .ant-radio-wrapper {
    font-size: 13px;
  }
`;

export const Content = styled.div`
  flex-basis: 680px;
`;

//// end of styles for application config pages

// styles for getting started pages

export const Container = styled.div`
  overflow: auto;
`;

export const GettingStartedHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin: 0 0 42px;
`;

export const Title = styled.h1`
  font-weight: 600;
  font-size: 32px;
  line-height: 32px;
  margin-bottom: 0;
`;

export const PillContainer = styled.div`
  vertical-align: middle;
  margin: 3px 0 0 12px;
`;

export const Tagline = styled.div`
  margin-top: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
`;

export const IconContainer = styled.div`
  height: 35px;
  margin-right: 12px;
`;

export const TitleHeaderContainer = styled.div``;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Hero = styled.img`
  width: 100%;
  border-radius: 4px;
  margin-bottom: ${props => props.theme.spacerxl};
  box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.25);
`;

export const DescriptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const AppLinkContainer = styled.div``;

export const AppLinks = styled.ul`
  list-style-type: none;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #5e5b62;

  li {
    display: flex;
    align-items: center;
  }
`;

export const AppLinkItem = styled.li`
  margin-bottom: 20px;
`;

export const AppLinkIcon = styled(Icon)`
  color: ${props => props.theme.textColor};
  margin-right: 15px;
  font-size: 22px;
`;

export const AppLink = styled.a`
  display: flex;
  align-items: center;
`;

export const InnerContainer = styled.div`
  max-width: 900px;
`;

// end of styles for getting started pages
