import { ElementLayout } from "../../../../../layout/util";
import { ComponentConfigState } from "../../../../../types";
import { BaseSpaceConfig } from "../reducer";

import findTreeNode from "./findTreeNode";
import removeTreeNode from "./removeTreeNode";

export default function removeComponent(space: BaseSpaceConfig, slug: string) {
  const configState = space.components.get(slug);
  if (!configState?.draftComponent) throw new Error(`Component ${slug} not found`);

  const removedTreeNode = findTreeNode(space.tree!, slug)!;
  const removedIndex = removedTreeNode.container!.treeNodes.indexOf(removedTreeNode);
  const removedParent = removedTreeNode.container?.slug || null;
  removedTreeNode.container = null;

  const { treeNode, removedSlugs } = removeTreeNode(
    space.tree!,
    configState.draftComponent.slug
  );

  const componentConfigs: ComponentConfigState[] = [];
  const elementLayouts: Map<string, Partial<ElementLayout>> = new Map();
  removedSlugs.forEach(s => {
    componentConfigs.push(space.components.get(s)!);
    elementLayouts.set(s, space.elementLayouts.get(s)!);
    space.components.delete(s);
    space.elementLayouts.delete(s);
  });

  return {
    componentConfigs,
    treeNode,
    removedTreeNode,
    removedParent,
    removedIndex,
    elementLayouts,
    space
  };
}
