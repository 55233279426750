import React, { useMemo } from "react";

import moment from "moment";

import { AttributeValueBaseProps } from "..";
import { DATE_TIME_DISPLAY_FORMAT } from "../../../../constants";

export default function DateTimeValue({
  style = {},
  className,
  value
}: AttributeValueBaseProps) {
  const memoVal = useMemo(() => {
    let displayedValue = value || null;
    const momentObj = moment(value);
    if (momentObj.isValid()) {
      displayedValue = momentObj.format(DATE_TIME_DISPLAY_FORMAT);
    }
    return displayedValue;
  }, [value]);
  return (
    <div style={style} className={className} title={memoVal}>
      {memoVal}
    </div>
  );
}
