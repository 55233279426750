import { ConfigValidationError } from "../../../../../types";
import { ComponentConfigState } from "../../../types";

export const errorSelector = (state: ComponentConfigState): ConfigValidationError[] => {
  const errors = [];
  const func = state.draftComponent.functions.edges[0]?.node;
  if (!func?.id.length) {
    errors.push({
      field: "FUNCTION",
      message: "A function is required."
    });
  }
  return errors;
};
