import moment from "moment";
import pluralize from "pluralize";

const MS_PER_HOUR = 1000 * 60 * 60;
const MS_PER_MINUTE = 1000 * 60;
const getTimeFromNow = (delta: number) => {
  const hours = Math.floor(delta / MS_PER_HOUR);
  const minutes = Math.floor((delta - hours * MS_PER_HOUR) / MS_PER_MINUTE);
  let value = "in";
  if (hours > 0) {
    value += ` ${hours} ${pluralize("hour", hours)}`;
  }
  if (minutes > 0) {
    value += `${hours > 0 ? "," : ""} ${minutes} ${pluralize("minute", minutes)}`;
  }
  return value;
};

export const getFormattedDate = (value: string) => {
  const momentDate = moment(value);
  const delta = momentDate.valueOf() - Date.now();
  const isFuture = delta > 0;
  const formattedDate = momentDate.format("M/D/YY h:mm a z");
  if (isFuture) {
    const hours = Math.floor(delta / MS_PER_HOUR);
    return hours >= 24 ? `on ${formattedDate}` : getTimeFromNow(delta);
  }
  return formattedDate;
};
