import pluralize from "pluralize";

const SECONDS_IN_DAY = 24 * 60 * 60;
const SECONDS_IN_HOUR = 60 * 60;

export function formatDuration(duration: number): string {
  let result = "";
  const days = Math.floor(duration / SECONDS_IN_DAY);
  const hours = Math.floor(duration / SECONDS_IN_HOUR) % 24;
  const minutes = Math.floor(duration / 60) % 60;
  const seconds = duration % 60;

  if (days > 0) {
    result += `${days} ${pluralize("day", days)} `;
  }

  if (hours > 0 || days > 0) {
    result += `${hours} ${pluralize("hour", hours)} `;
  }

  if (minutes !== 0) {
    result += `${minutes} ${pluralize("minute", minutes)}`;
  } else {
    result += ` ${seconds} ${pluralize("second", seconds)}`;
  }

  return result;
}
