import { omit } from "lodash";

import {
  SpaceComponentObject,
  SpaceComponentInputType,
  SpaceComponentPackage,
  SpaceComponentType
} from "../../../../types";
import { ElementLayout } from "../../layout/util";

const OMITTED_COMPONENT_KEYS = [
  "id",
  "container",
  "__typename",
  "isRemovable",
  "notVisibleFunctions"
];

const filterComponentFields = (component: SpaceComponentObject) => {
  const scInput: Partial<SpaceComponentInputType> = omit(
    {
      ...component,
      container: null,
      componentTreeNodes: component.componentTreeNodes as any[],
      functions: component.functions.edges.map(({ node: { id } }) => ({
        id
      }))
    },
    OMITTED_COMPONENT_KEYS
  );

  if (scInput.view) {
    scInput.view = { id: scInput.view.id };
  }
  return scInput as SpaceComponentInputType;
};

function isComponentInput(
  input: SpaceComponentInputType | null
): input is SpaceComponentInputType {
  return input !== null;
}
export const getComponentsForMutation = (
  nodes: SpaceComponentObject[],
  elementLayouts: Map<string, Partial<ElementLayout>>,
  findSpaceComponentPackage: (
    componentType: SpaceComponentType
  ) => SpaceComponentPackage | undefined
) => {
  return nodes
    .map((node: SpaceComponentObject) => {
      const pkg = findSpaceComponentPackage(node.type);
      if (pkg?.isClientProvidedComponent) return null;
      const newNode: SpaceComponentInputType = filterComponentFields(node);
      newNode.componentTreeNodes = getComponentsForMutation(
        node.componentTreeNodes,
        elementLayouts,
        findSpaceComponentPackage
      );
      newNode.layout = elementLayouts.get(newNode.slug);
      return newNode;
    })
    .filter(isComponentInput);
};
