import { Input, Table } from "antd";
import styled from "styled-components";

export const ControlContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SearchInput = styled(Input)`
  flex-basis: 50%;
`;

export const ButtonBar = styled.div`
  flex-basis: 50%;
`;

export const FunctionTable = styled(Table)`
  height: 100%;
  margin-top: ${props => props.theme.spacermd};
`;

export const FullScreen = styled.div`
  position: fixed;
  inset: 0;
  z-index: 1000;
  background-color: #2a2b2e;
  color: #d9d9d9;
`;
