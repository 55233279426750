import { ConfigValidationError } from "../../../../../../types";
import { ComponentConfigState } from "../../../../types";
import selectVisibilityRuleErrors from "../../common/VisibilityRulesManager/selectErrors";

export default function errorSelector(
  state: ComponentConfigState
): ConfigValidationError[] {
  const { draftComponent } = state;
  let errors: ConfigValidationError[] = [];

  if (!draftComponent.properties.template) {
    errors.push({
      field: "URL_TEMPLATE",
      message: "Please provide a template for this url field."
    });
  }
  errors = errors.concat(selectVisibilityRuleErrors(draftComponent));
  return errors;
}
