import React from "react";

import { useMutation } from "@apollo/react-hooks";
import { Button, Tooltip } from "antd";
import styled from "styled-components";

import { REMOVE_INVITE_MUTATION } from "./queries/RemoveInvite";

const StyledButton = styled(Button)`
  color: ${props => props.theme.textColorMid};
`;

interface Props {
  email: string;
}

const InviteRemoveButton = (props: Props) => {
  const [removeInvite, { loading }] = useMutation(REMOVE_INVITE_MUTATION, {
    variables: { email: props.email },
    refetchQueries: ["AllInvitesQuery"]
  });

  return (
    <Tooltip
      title="Click to remove invite"
      getPopupContainer={trigger => trigger.parentNode as HTMLElement}
    >
      <StyledButton
        type="link"
        icon="delete"
        loading={loading}
        onClick={e => {
          removeInvite();
          e.stopPropagation();
        }}
      />
    </Tooltip>
  );
};

export default InviteRemoveButton;
