import React from "react";

import { Icon, Skeleton } from "antd";
import { matchPath, useLocation, useNavigate, useParams } from "react-router";
import styled from "styled-components";

import SVGRocketShipIcon from "../../../assets/rocket_ship_icon.svg";
import { Size } from "../../../cssConstants";
import PageHeader, {
  HeaderBanner,
  HeaderControls,
  HeaderTitle
} from "../../../layouts/Header/Header";
import { StatusCode } from "../../../types";
import EvaluaterContextContainer from "../../common/CodeSandbox/EvaluaterContext";
import { Empty } from "../../common/Empty";
import usePaths from "../../common/hooks/usePaths";
import Portal from "../../common/Portal/Portal";
import debug from "../../util/debug";
import Canvas from "../layout/Canvas";
import LayoutContainer from "../layout/LayoutContext";
import SpaceApi from "../SpaceApi";
import SpaceConfig from "../SpaceConfig/SpaceConfigRoot";

import { AuthorizationFlowWrapper } from "./AuthorizationContext";
import BranchSelector from "./Header/BranchSelector";
import { Actions, SpaceTitle } from "./Header/Header";
import ScmBanner from "./Header/ScmBanner";
import LeftNav from "./LeftNav/LeftNav";
import { ParamGenerationContextContainer } from "./ParamGenerationContext/ParamGenerationContext";
import { RenderTreeContainer } from "./RenderTreeContext";
import { ResizeContextProvider } from "./ResizeContext";
import SpaceAuditLogDrawer from "./SpaceAuditLogDrawer";
import SpaceComponent from "./SpaceComponent";
import { RootComponentStateContainer } from "./SpaceComponent/contexts/ComponentStateContext";
import { SpaceConsoleContextProvider } from "./SpaceConsoleContext";
import {
  SpaceContextProvider,
  StableSpaceContextContainer,
  useSpaceContext,
  useStableSpaceContext
} from "./SpaceContext";
import { BranchContextContainer, useBranchContext } from "./SpaceContext/BranchContext";
import useSetParameterEffect from "./useSetParameterEffect";

const RocketIcon = styled(SVGRocketShipIcon)`
  height: 86px;
  width: 86px;
  color: ${props => props.theme.textColor};
  opacity: 75%;
`;

const CoffeeIcon = styled(Icon)`
  height: 86px;
  width: 86px;
  font-size: 84px;
  color: ${props => props.theme.textColor};
  opacity: 75%;
`;

const PaddedContainer = styled.div`
  padding: ${props => props.theme.spacerxl};
`;

const StyledContainer = styled.div`
  height: 100%;
  &.editMode {
    overflow-x: hidden;
    overflow-y: hidden;
  }

  @media (max-width: ${Size.lg}) {
    width: 100vw;
  }
`;

function NoopComponent({ children }: { children: React.ReactNode }) {
  return <>{children}</>;
}

function SpaceComponentTree() {
  debug("Start render of SpaceComponentTree");
  const { components, queryStatus, loading } = useSpaceContext();
  const { editMode } = useStableSpaceContext();
  const spaceApi = React.useMemo(() => {
    return new SpaceApi();
  }, []);

  const componentTreeRoot = React.useMemo(() => {
    return (
      <>
        {components
          .filter(c => c.container === null)
          .map(ctn => (
            <SpaceComponent key={ctn.slug} spaceApi={spaceApi} spaceComponent={ctn} />
          ))}
      </>
    );
  }, [spaceApi, components]);

  if (queryStatus === StatusCode.PERMISSION_DENIED && !editMode) {
    return (
      <Empty
        title="Waiting to be published!"
        instructions="Go to Edit Mode to publish your space to this environment."
        icon={<RocketIcon />}
      />
    );
  }

  if (queryStatus === StatusCode.NOT_FOUND && !editMode) {
    return (
      <Empty
        title="Looks like there’s nothing to see here"
        instructions="The space you were looking for does not exist."
        icon={<CoffeeIcon type="coffee" />}
      />
    );
  }

  if (loading || !components.length) {
    return (
      <PaddedContainer>
        <Skeleton active />
      </PaddedContainer>
    );
  }
  return componentTreeRoot;
}

export default function Space({
  editing = false,
  slug,
  children = null
}: {
  editing?: boolean;
  slug?: string;
  children?: React.ReactNode;
}) {
  const { resourceCursor = undefined } = useParams<{
    spaceSlug: string;
    resourceCursor: string;
  }>();

  const Config = editing ? SpaceConfig : NoopComponent;

  useSetParameterEffect(editing);

  return (
    <BranchContextContainer>
      <SpaceContextProvider slug={slug} editing={editing}>
        <StableSpaceContextContainer
          encodedResourceQueryDescriptor={resourceCursor}
          editing={editing}
        >
          <AuthorizationFlowWrapper>
            <EvaluaterContextContainer>
              <ParamGenerationContextContainer>
                <LayoutContainer>
                  <SpaceConsoleContextProvider>
                    <Config>
                      <RenderTreeContainer>
                        <StyledContainer className={editing ? "editMode" : ""}>
                          <ResizeContextProvider>
                            <Canvas>
                              {children}
                              <RootComponentStateContainer>
                                <SpaceComponentTree />
                              </RootComponentStateContainer>
                            </Canvas>
                          </ResizeContextProvider>
                        </StyledContainer>
                      </RenderTreeContainer>
                    </Config>
                  </SpaceConsoleContextProvider>
                </LayoutContainer>
              </ParamGenerationContextContainer>
            </EvaluaterContextContainer>
          </AuthorizationFlowWrapper>
        </StableSpaceContextContainer>
      </SpaceContextProvider>
    </BranchContextContainer>
  );
}

export function SpacePage() {
  const [navOpen, setNavOpen] = React.useState(false);
  const { spaceSlug } = useParams<{
    spaceSlug: string;
  }>();
  const { getAuditSpace, getSpace } = usePaths();
  const { branch } = useBranchContext();
  const navigate = useNavigate();
  const location = useLocation();
  const isAuditDrawerVisible = matchPath(
    location && location.pathname,
    getAuditSpace(spaceSlug!)
  );

  if (!spaceSlug) {
    throw new Error("Expected spaceSlug.");
  }

  return (
    <>
      <PageHeader
        onToggleNav={() => {
          setNavOpen(!navOpen);
        }}
      />
      <LeftNav open={navOpen} />
      <Space key={spaceSlug} slug={spaceSlug} editing={false}>
        <Portal id="branch-selector-portal">
          <BranchSelector />
        </Portal>
        <HeaderControls>
          <Actions />
        </HeaderControls>
        <HeaderTitle>
          <SpaceTitle />
        </HeaderTitle>
        <HeaderBanner>
          <ScmBanner />
        </HeaderBanner>
        {isAuditDrawerVisible && (
          <SpaceAuditLogDrawer
            isVisible
            onClose={() => navigate(getSpace(spaceSlug!, { branch }))}
          />
        )}
      </Space>
    </>
  );
}
