import React from "react";

import styled from "styled-components";

import { ExecutionStatus, UserNode } from "../../../queries/common";
import ActivityFunctionInfo from "../../ActivityFunctionInfo";
import ActivityRunInfo from "../../ActivityRunInfo";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${props => props.theme.spacermd};
  background-color: ${props => props.theme.innerContainerBackground};
  border: 1px solid ${props => props.theme.innerContainerBorderColor};
  border-radius: ${props => props.theme.containerPrimaryBorderRadius};
  cursor: pointer;

  & + & {
    margin-top: ${props => props.theme.spacermd};
  }
`;

interface Props {
  title: string;
  dataSourceTitle: string;
  integration: string | undefined;
  status: ExecutionStatus;
  user: UserNode;
  createdAt: string;
  onClick?: () => void;
}
export const ActivityItem = (props: Props) => {
  return (
    <Container onClick={props.onClick}>
      <ActivityFunctionInfo
        title={props.title}
        dataSourceTitle={props.dataSourceTitle}
        integration={props.integration}
      />
      <ActivityRunInfo
        status={props.status}
        user={props.user}
        createdAt={props.createdAt}
      />
    </Container>
  );
};

export default ActivityItem;
