import { Select } from "antd";
import styled from "styled-components";

import ButtonNew from "../../common/ButtonNew/ButtonNew";
import { B2, Card } from "../../common/StyledComponents";

import GitHubAsset from "./assets/github-mark.svg";
import GitlabAsset from "./assets/gitlab-logo.svg";

export const Button = styled(ButtonNew)`
  margin-left: ${props => props.theme.spacersmd};
`;

export const Container = styled.div``;

export const FormSection = styled.div`
  margin-top: 24px;
  margin-bottom: 40px;

  label {
    display: block;
    margin-bottom: ${props => props.theme.spacersm};
  }
`;

export const ProviderContainer = styled.div`
  display: flex;
  gap: ${props => props.theme.spacerlg};
`;

export const ProviderSelect = styled(Select)`
  min-width: 250px;
`;

export const StyledB2 = styled(B2)`
  max-width: 800px;
`;

export const GithubIcon = styled(GitHubAsset)`
  scale: 0.6;
`;

export const GitlabIcon = styled(GitlabAsset)`
  scale: 0.9;
  height: 84px;
  margin-top: 12px;
`;

export const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 230px;
`;

export const StyledModalActions = styled.footer`
  display: flex;
  justify-content: space-between;
  gap: ${props => props.theme.spacermd};
`;

export const StyledMainActions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${props => props.theme.spacermd};
`;
