import { ConfigValidationError } from "../../../../../../types";
import { ComponentConfigState } from "../../../../types";

export default function errorSelector(
  state: ComponentConfigState
): ConfigValidationError[] {
  const errors: ConfigValidationError[] = [];
  if (!String(state.draftComponent.properties.is_open_template).length) {
    errors.push({
      field: "IS_OPEN_TEMPLATE",
      message: "Open template is required."
    });
  }
  return errors;
}
