import { Icon } from "antd";
import styled from "styled-components";

import { colorTokens, Size } from "../../../cssConstants";
import { primaryStyle, smStyle } from "../../common/ButtonNew/ButtonNew";
import { B2 } from "../../common/StyledComponents";

export const StyledAnchor = styled.a`
  z-index: inherit;
`;

export const Logo = styled.img`
  height: 32px;
  margin-right: 48px;

  @media (max-width: ${Size.xl}) {
    height: 24px;
    margin-right: 28px;
  }

  @media (max-width: ${Size.lg}) {
    height: 32px;
  }
`;

export const Container = styled.div<{ overlay: boolean }>`
  ${props => (props.overlay ? "padding: 44px 40px;" : "padding: 44px 0;")}

  display: flex;
  justify-content: space-between;
  gap: 12px;
`;

export const HeaderActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
`;

export const LinksContainer = styled.div`
  display: flex;
  gap: 28px;
  align-items: center;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 12px;
`;

export const LinkText = styled(B2)`
  color: ${colorTokens.white};
  &:hover {
    text-decoration: underline;
  }
`;

export const AnchorButton = styled.a`
  // Copied from ant button
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  z-index: 1;

  ${smStyle}
  ${primaryStyle}
`;

export const ToggleButton = styled.button`
  z-index: 1;
  background-color: inherit;
  border: none;
  color: ${colorTokens.white};
  width: 48px;
  height: 48px;
`;

export const ToggleIcon = styled(Icon)`
  svg {
    width: 28px;
    height: 28px;
  }
`;

export const MenuContainer = styled.div`
  padding: 0 40px;
  align-items: baseline;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const HeaderWrapper = styled.div<{ overlay: boolean }>`
  ${props =>
    props.overlay &&
    `
    position: fixed;
    display: flex;
    flex-direction: column;
    inset: 0;
    z-index: ${props.theme.zIndex.popoverOverDrawer};
    background-color: ${colorTokens.grey2490};
  `}
`;

export const VerticalSpace = styled.div`
  height: 12px;
`;
