import moment from "moment";

import {
  Filter as FilterConfig,
  FilterTypes,
  ViewFilterOperator
} from "../../../../../types";
import { assertNever } from "../../../../util/assertNever";
import { SpaceFunction } from "../../../FunctionExecutor/FunctionExecutor";
import { BlankValueType } from "../../constants";
import { InputParameterTemplate, ParameterType } from "../common/useFuncParams/types";

const toFiltersParameterTemplate = (config: FilterConfig): string => {
  switch (config.type) {
    case FilterTypes.Binding:
      return (
        `{"attribute": ${JSON.stringify(config.attribute)}, ` +
        `"operator": ${JSON.stringify(config.operator)}, ` +
        `"value": ${config.binding}}`
      );
    case FilterTypes.Value:
      return (
        `{"attribute": ${JSON.stringify(config.attribute)}, ` +
        `"operator": ${JSON.stringify(config.operator)}, ` +
        `"value": ${JSON.stringify(config.value)}}`
      );
    case FilterTypes.Duration:
      // This case is not as simple as handing evaluation off to our eval sandbox
      // because the eval sandbox does not include moment.
      const duration = moment.duration(config.duration_iso);
      const roundingUnit = config.duration_iso?.includes("T") ? "minute" : "hour";
      const value = moment().subtract(duration).startOf(roundingUnit).format();
      return (
        `{"attribute": ${JSON.stringify(config.attribute)}, ` +
        `"operator": ${JSON.stringify(config.operator)}, ` +
        `"value": ${JSON.stringify(value)}}`
      );
    default:
      return assertNever(config);
  }
};

const toKeyValueTemplate = (config: FilterConfig): string => {
  switch (config.type) {
    case FilterTypes.Binding:
      return `${JSON.stringify(config.attribute)}: ${config.binding}`;
    case FilterTypes.Value:
      return `${JSON.stringify(config.attribute)}: ${JSON.stringify(config.value)}`;
    case FilterTypes.Duration:
      // Duration filters should never be configured for equality.
      // If this statement is reached then there is a configuration error upstream.
      throw new Error("invalid runtime configuration");
    default:
      return assertNever(config);
  }
};

export const generateFilterParameters = (
  func: SpaceFunction,
  filtersConfig: FilterConfig[],
  required: boolean
): InputParameterTemplate[] => {
  if (!filtersConfig.length) return [];

  const inputParameters: InputParameterTemplate[] = [
    {
      blank_value_type: BlankValueType.UNDEFINED,
      hidden: false,
      name: "filters",
      required,
      template: "[" + filtersConfig.map(toFiltersParameterTemplate).join(",") + "]",
      type: ParameterType.TEMPLATE
    }
  ];

  // Generate "filter" parameter (not to be confused with "filters") if such
  // a function parameter exists. The "filter" parameter is key-value object
  // with filter name as the key and the value of the filter as the value.
  // Key-value pairs are only generated for equality filters.
  if (func.functionParameters.some(p => p.name === "filter")) {
    inputParameters.push({
      blank_value_type: BlankValueType.UNDEFINED,
      hidden: false,
      name: "filter",
      required,
      template:
        "{" +
        filtersConfig
          .filter(c => c.operator === ViewFilterOperator.EQUALS)
          .map(toKeyValueTemplate)
          .join(",") +
        "}",
      type: ParameterType.TEMPLATE
    });
  }

  return inputParameters;
};
