import React from "react";

import { Radio, Select } from "antd";

import { fromGlobalId } from "../../../../../../util/graphql";
import { ReducerActions, UpdateAction } from "../../../../reducer/actions";
import { AssigneeType, ChangeAssigneeOptions } from "../../../../types";
import * as common from "../styledComponents";
import { ActionProps } from "../types";

import useAllRoles from "./queries/AllRoles";
import useAllUsers from "./queries/AllUsers";
import * as styled from "./styledComponents";

const USE_CURRENT_KEY = "use-current";

const ChangeAssigneeAction = ({
  action,
  index,
  dispatch
}: ActionProps<ChangeAssigneeOptions>) => {
  const { loading: usersLoading, data: usersData } = useAllUsers();
  const { loading: rolesLoading, data: rolesData } = useAllRoles();

  const onChangeOptions = (options: ChangeAssigneeOptions) => {
    const action: UpdateAction<ChangeAssigneeOptions> = {
      type: ReducerActions.UPDATE_ACTION,
      payload: {
        action: { options },
        index
      }
    };
    dispatch(action);
  };

  const onChangeAssigneeType = (assigneeType: AssigneeType) =>
    onChangeOptions({
      assigneeType,
      assigneeId: undefined,
      useCurrent:
        (assigneeType !== AssigneeType.ROLE && action.options?.useCurrent) || false
    });

  const onChangeAssigneeId = (assigneeId: string) =>
    onChangeOptions({
      assigneeType: action.options?.assigneeType,
      assigneeId: assigneeId !== USE_CURRENT_KEY ? assigneeId : null,
      useCurrent: assigneeId === USE_CURRENT_KEY
    });

  const assigneeKey = action.options?.useCurrent
    ? USE_CURRENT_KEY
    : action.options?.assigneeId;

  return (
    <common.Container>
      <Radio.Group
        onChange={e => onChangeAssigneeType(e.target.value)}
        value={action.options?.assigneeType}
      >
        <Radio value={AssigneeType.USER}>Assign to user</Radio>
        <Radio value={AssigneeType.ROLE}>Assign to role</Radio>
        <Radio value={AssigneeType.UNASSIGN}>Unassign</Radio>
      </Radio.Group>
      {action.options?.assigneeType === AssigneeType.USER && (
        <styled.AssigneeSelect
          loading={usersLoading}
          placeholder="Select a user"
          value={assigneeKey}
          onChange={id => onChangeAssigneeId(id as string)}
        >
          <Select.Option key={USE_CURRENT_KEY}>
            Assign to the logged-in user
          </Select.Option>
          {usersData?.allUsers.edges.map(({ node }) => (
            <Select.Option key={fromGlobalId(node.id)[1]}>
              {node.firstName} {node.lastName}
            </Select.Option>
          ))}
        </styled.AssigneeSelect>
      )}
      {action.options?.assigneeType === AssigneeType.ROLE && (
        <styled.AssigneeSelect
          loading={rolesLoading}
          placeholder="Select a role"
          value={assigneeKey}
          onChange={id => onChangeAssigneeId(id as string)}
        >
          {rolesData?.allRoles.edges.map(({ node }) => (
            <Select.Option key={fromGlobalId(node.id)[1]}>{node.name}</Select.Option>
          ))}
        </styled.AssigneeSelect>
      )}
    </common.Container>
  );
};

export default ChangeAssigneeAction;
