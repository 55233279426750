import styled from "styled-components";

import ButtonNew from "../../../common/ButtonNew/ButtonNew";
import { B2, D2 } from "../../../common/StyledComponents";

export const Banner = styled.div`
  width: 100%;
  background-color: ${props => props.theme.surfacePrimaryTint};
  padding: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
`;

export const Title = styled(D2)`
  color: white;
  z-index: 1;
`;

export const Body = styled(B2)`
  color: ${props => props.theme.textColorLight};
  padding-top: 16px;
  z-index: 1;
`;

export const UpgradeButton = styled(ButtonNew)`
  margin-top: 24px;
  z-index: 2;
`;

export const BottomGradient = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: -26%;
  z-index: 1;
  display: block;
  width: 100%;
  height: 150px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 200%;
  background-color: ${props => props.theme.primaryAccent};
  opacity: 0.25;
  -webkit-filter: blur(100px);
  filter: blur(100px);
  text-align: center;
`;

export const BackgroundContainer = styled.div`
  position: absolute;
  inset: 0;
  z-index: 0;
`;
