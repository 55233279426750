import React from "react";

import moment from "moment";
import { GroupProps } from "react-select";
import Select from "react-windowed-select";

import { colors, colorTokens } from "../../../../cssConstants";

import * as styled from "./styledComponents";

export const DAY_IN_SECONDS = 1 * 24 * 60 * 60;

interface Option {
  label: string;
  value: {
    fromTimestamp: number;
    toTimestamp?: number;
  };
}

interface OptionGroup {
  label: string;
  options: Option[];
}

// Returns the last n months as ranges of { start, end }
// The end of the month is actually the first day of the next month
// because the code expects that the end part of the range is excluded, that is
// range = start <= X < end
function getLastMonthsAsRanges(when: Date, months: number) {
  const result = [];

  let current = moment(when).date(1).hours(0).minutes(0).seconds(0);
  for (let i = 0; i < months; i++) {
    const end = moment(current);
    const start = moment(current).subtract(1, "month");

    result.push({
      start: start.toDate(),
      end: end.toDate()
    });

    current = moment(current).subtract(1, "month");
  }

  return result;
}

function createStaticOptions(when: Date): Option[] {
  return [
    {
      label: "1 day",
      value: {
        fromTimestamp: moment(when)
          .subtract(1, "day")
          .hours(0)
          .minutes(0)
          .seconds(0)
          .unix()
      }
    },
    {
      label: "7 days",
      value: {
        fromTimestamp: moment(when)
          .subtract(7, "day")
          .hours(0)
          .minutes(0)
          .seconds(0)
          .unix()
      }
    },
    {
      label: "14 days",
      value: {
        fromTimestamp: moment(when)
          .subtract(14, "day")
          .hours(0)
          .minutes(0)
          .seconds(0)
          .unix()
      }
    },
    {
      label: "30 days",
      value: {
        fromTimestamp: moment(when)
          .subtract(30, "day")
          .hours(0)
          .minutes(0)
          .seconds(0)
          .unix()
      }
    },
    {
      label: "60 days",
      value: {
        fromTimestamp: moment(when)
          .subtract(60, "day")
          .hours(0)
          .minutes(0)
          .seconds(0)
          .unix()
      }
    },
    {
      label: "90 days",
      value: {
        fromTimestamp: moment(when)
          .subtract(90, "day")
          .hours(0)
          .minutes(0)
          .seconds(0)
          .unix()
      }
    }
  ];
}

function createOptions(when: Date): OptionGroup[] {
  const formatter = new Intl.DateTimeFormat(undefined, { month: "short" });

  const lastMonths = getLastMonthsAsRanges(when, 6).map(month => {
    return {
      label: `${formatter.format(month.start)} ${month.start.getFullYear()}`,
      value: {
        fromTimestamp: moment(month.start).unix(),
        toTimestamp: moment(month.end).unix()
      }
    };
  });

  const result = [
    {
      label: "static",
      options: createStaticOptions(when)
    },
    {
      label: "dynamic",
      options: lastMonths
    }
  ];

  return result;
}

export interface TimeRange {
  fromTimestamp: number;
  toTimestamp?: number;
}

interface Props {
  value: TimeRange;
  when: Date;
  className?: string;
  onChange: (value: TimeRange) => void;
}

const Group = ({ children }: GroupProps<Option, false>) => (
  <styled.Group>{children}</styled.Group>
);

export default function DurationSelect({
  value,
  when,
  className,
  onChange = () => null
}: Props) {
  const options = React.useMemo(() => {
    return createOptions(when);
  }, [when]);

  const flatOptions = React.useMemo(() => {
    return options.flatMap(o => o.options);
  }, [options]);

  const selectedOption = flatOptions.find(option => {
    return (
      option.value.fromTimestamp === value.fromTimestamp &&
      option.value.toTimestamp === value.toTimestamp
    );
  });

  if (!selectedOption) {
    throw new Error(`Unsupported value ${value}`);
  }

  return (
    <Select
      className={className}
      components={{ Group }}
      value={selectedOption}
      options={options}
      isSearchable={false}
      onChange={(option: Option) => {
        onChange({
          fromTimestamp: option.value.fromTimestamp,
          toTimestamp: option.value.toTimestamp
        });
      }}
      styles={{
        dropdownIndicator: (provided: Record<string, any>) => ({
          ...provided,
          color: colors.dropdownIndicator
        }),
        indicatorSeparator: (provided: Record<string, any>) => ({
          ...provided,
          display: "none"
        })
      }}
      theme={(provided: Record<string, any>) => ({
        ...provided,
        colors: {
          ...provided.colors,
          primary25: colorTokens.purpleTransparent400,
          primary50: colorTokens.purpleTransparent400,
          primary75: colorTokens.purpleTransparent400,
          primary: colorTokens.purple700,
          danger: colorTokens.red300
        }
      })}
    />
  );
}
