import { Skeleton as AntSkeleton, Tabs } from "antd";
import styled from "styled-components";

import Drawer from "../../common/Drawer";
import InlineInput from "../../common/InlineInput";
import { BasePanel } from "../../common/StyledComponents";

export const Panel = styled(BasePanel)`
  padding: ${props => props.theme.spacermd};
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h3 {
    margin-bottom: 0;
    margin-right: ${props => props.theme.spacersm};
    font-size: ${props => props.theme.h3FontSize};
    font-weight: ${props => props.theme.boldFontWeight};
    color: ${props => props.theme.textColorMid};
  }

  span {
    color: ${props => props.theme.textColorMid};
  }
`;

export const RightSide = styled.div`
  button {
    margin: ${props => props.theme.spacerxs};
  }
`;

export const DrawerNoPadding = styled(Drawer)`
  .ant-drawer-body {
    padding: 0px;
  }
`;

export const DrawerSidePadding = styled.div`
  padding: ${props => props.theme.spacermd} ${props => props.theme.spacerxl} 0px
    ${props => props.theme.spacerxl};
`;

export const FunctionTabPadding = styled.div`
  padding: 0 ${props => props.theme.spacermd};
  height: 100%;
`;

export const HeaderWithDrawerPadding = styled.div`
  padding: ${props => props.theme.spacerxl};
  border-bottom: 1px solid ${props => props.theme.tableBorderColor};
  margin-bottom: ${props => props.theme.spacermd};
`;

export const StyledTabs = styled(Tabs)`
  & > .ant-tabs-bar .ant-tabs-nav-wrap .ant-tabs-nav-scroll .ant-tabs-nav {
    width: 100%;
  }

  &
    > .ant-tabs-bar
    .ant-tabs-nav-wrap
    .ant-tabs-nav-scroll
    .ant-tabs-nav
    div
    .ant-tabs-tab {
    width: 50%;
    margin: 0;
    text-align: center;
  }
`;

export const HeaderInlineInput = styled(InlineInput)`
  font-size: ${props => props.theme.largeFontSize};
  font-weight: bold;
  margin-top: ${props => props.theme.spacerxs};
  color: ${props => props.theme.secondaryButtonOnDark};
`;

export const Skeleton = styled(AntSkeleton)`
  padding: ${props => props.theme.spacerxl};
`;
