import styled from "styled-components";

import { colorTokens, Size } from "../../../cssConstants";
import Header from "../Header";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${colorTokens.grey2500};
  padding: 0 240px;
  overflow: hidden;

  @media (max-width: ${Size.lg}) {
    padding: 0 180px;
  }

  @media (max-width: ${Size.md}) {
    padding: 0 100px;
  }

  @media (max-width: ${Size.sm}) {
    padding: 0 40px;
  }
`;

export const CenterContent = styled.div`
  display: flex;
  flex-grow: 1;
  min-height: 580px;
  align-items: start;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding-bottom: 80px;
`;

export const ChildContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  z-index: 1;
`;

export const LeftChildContainer = styled(ChildContainer)`
  padding-right: 60px;

  @media (max-width: ${Size.xl}) {
    padding-right: 0;
  }
`;

export const RightChildContainer = styled(ChildContainer)`
  padding-left: 60px;

  @media (max-width: ${Size.xl}) {
    padding-left: 0;
  }
`;

export const ContentAlign = styled.div<{ align: "start" | "center" }>`
  display: flex;
  align-items: ${props => props.align};
  width: 100%;

  @media (max-width: ${Size.xl}) {
    flex-direction: column;
    gap: ${props => props.theme.spacerlg};
  }
`;

export const BottomGradient = styled.div`
  position: fixed;
  left: -25%;
  top: auto;
  right: 0%;
  bottom: -26%;
  z-index: 0;
  display: block;
  width: 150%;
  width: 100%;
  height: 300px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 200%;
  background-color: ${props => props.theme.primaryAccent};
  opacity: 0.25;
  -webkit-filter: blur(100px);
  filter: blur(100px);
  text-align: center;
`;

export const BackgroundBitsContainer = styled.div`
  position: fixed;
  height: 100%;
  inset: 0;
  display: block;
  background-color: ${colorTokens.grey2500};
`;

export const StyledHeader = styled(Header)`
  z-index: 1;
`;

export const Wrapper = styled.div`
  flex-grow: 1;
`;

export const Main = styled.div`
  width: 100%;
  max-width: ${Size.xl};
  display: flex;
  flex-direction: column;
`;
