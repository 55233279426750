import Icon from "../../../../../assets/spaceicons/text-area.svg";
import {
  SpaceComponentPackage,
  SpaceComponentHostType,
  SpaceComponentTags
} from "../../../../../types";
import { DEFAULT_SIZES, INPUT_MIN_WIDTH } from "../../../layout/constants";
import { ElementLayout } from "../../../layout/util";

import Config, { InlineConfig, TerseConfig } from "./Config";
import errorSelector from "./Config/errorSelector";
import componentConfigReducer, {
  ensureSpaceTextAreaComponent,
  getComponentProperties,
  makeInitialState,
  TextAreaConfigState
} from "./Config/reducer";
import { getSchema } from "./getSchema";
import SpaceTextArea from "./SpaceTextArea";
import { SpaceTextAreaProperties, SpaceTextAreaComponent } from "./types";

const pkg: SpaceComponentPackage<
  SpaceTextAreaProperties,
  SpaceTextAreaComponent,
  TextAreaConfigState
> = {
  type: "TEXT_AREA",
  displayName: "Text Area",
  Icon,
  allowedHosts: new Set<SpaceComponentHostType>([
    "SPACE",
    "TABLE",
    "DETAIL",
    "CARD_LIST",
    "FUNCTION_FORM",
    "FUNCTION_MODAL_FORM",
    "FLEX_BOX",
    "CLOUD_UPLOADER"
  ]),
  Component: SpaceTextArea,
  ConfigurationComponent: Config,
  TerseConfigurationComponent: TerseConfig,
  InlineConfigurationComponent: InlineConfig,
  componentConfigReducer,
  getInitialDraftState: makeInitialState,
  ensureComponent: ensureSpaceTextAreaComponent,
  getComponentProperties,
  getSchema,
  defaultElementLayout: new ElementLayout({
    width: DEFAULT_SIZES.INPUT.width,
    height: DEFAULT_SIZES.INPUT.height,
    minWidth: INPUT_MIN_WIDTH,
    snapToGrid: false
  }),
  fallbackWidth: 172,
  fallbackHeight: 52,
  allowSelfBinding: true,
  allowAncestorBinding: true,
  tags: [SpaceComponentTags.Input],
  isHeadless: false,
  errorSelector,
  isContainer: false
};

export default pkg;
