import React from "react";

import { useStableSpaceContext } from "../../../SpaceContext";
import { useComponentContext } from "../../contexts/ComponentContext";
import { ViewResult } from "../useView";
import useViewBlockingStates, {
  OverrideElements
} from "../useViewBlockingStates/useViewBlockingStates";

interface ViewEnsurerProps {
  viewResult: ViewResult;
  children: React.ReactNode;
  overrides?: OverrideElements;
}

export default function ViewEnsurer({
  viewResult,
  overrides,
  children
}: ViewEnsurerProps) {
  const { editMode } = useStableSpaceContext();
  const { component } = useComponentContext();
  const { viewBlockingStateNode } = useViewBlockingStates({
    component,
    viewResult,
    editMode,
    overrides
  });
  return <>{viewBlockingStateNode !== null ? viewBlockingStateNode : children}</>;
}
