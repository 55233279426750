import React from "react";

import { Checkbox } from "antd";
import styled from "styled-components";

import { Edge, EnvironmentNode } from "../../../types";
import { LinkButtonNew } from "../StyledComponents";

import useGetAllEnvironments from "./queries";

const LinkButton = styled(LinkButtonNew)`
  margin-bottom: ${props => props.theme.spacersm};
`;

const EnvironmentsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CheckboxWrapper = styled.div`
  margin-bottom: 12px;

  .ant-checkbox-wrapper {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
  }
`;

interface Props {
  value: Set<string>;
  onChange: (environmentIds: Set<string>) => void;
}

export const EnvironmentSelector = React.forwardRef<Checkbox, Props>(
  (props: Props, ref) => {
    const { value, onChange } = props;
    const { data } = useGetAllEnvironments({
      fetchPolicy: "cache-and-network"
    });

    const allEnvironments: EnvironmentNode[] = React.useMemo(() => {
      return (
        data?.allEnvironments.edges.map((e: Edge<EnvironmentNode>) => e.node) || []
      );
    }, [data]);

    const isAllSelected = React.useMemo(() => {
      return allEnvironments.length === value.size;
    }, [allEnvironments, value]);

    const selectAll = React.useCallback(() => {
      const updatedEnvs = new Set<string>();
      if (!isAllSelected) {
        allEnvironments.forEach(env => {
          updatedEnvs.add(env.id);
        });
      }
      onChange(updatedEnvs);
    }, [isAllSelected, allEnvironments, onChange]);

    return (
      <div>
        <LinkButton onClick={selectAll}>
          {isAllSelected ? "Deselect all" : "Select all"}
        </LinkButton>
        <EnvironmentsContainer>
          {allEnvironments.map((node: EnvironmentNode) => {
            return (
              <CheckboxWrapper key={node.id}>
                <Checkbox
                  ref={ref}
                  value={node.id}
                  data-test={`environmentCheckbox-${node.id}`}
                  checked={value.has(node.id)}
                  onChange={e => {
                    const updatedEnvs = new Set(value);
                    if (e.target.checked) {
                      updatedEnvs.add(node.id);
                    } else {
                      updatedEnvs.delete(node.id);
                    }
                    onChange(updatedEnvs);
                  }}
                >
                  {node.name}
                </Checkbox>
              </CheckboxWrapper>
            );
          })}
        </EnvironmentsContainer>
      </div>
    );
  }
);

export default EnvironmentSelector;
