import styled from "styled-components";

import { colorTokens } from "../../../cssConstants";

export const Container = styled.div`
  line-height: 24px;
  margin: 24px 0px;
`;

export const DashWrapper = styled.div`
  display: flex;
  flex-direction: row;
  font-family: var(--display-font-family);
  font-size: ${props => props.theme.largeFontSize};

  &:before,
  &:after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid ${props => props.theme.surfaceTertiary};
    opacity: 50%;
    margin: auto;
  }
`;

export const Text = styled.div`
  color: ${colorTokens.white};
  margin-left: ${props => props.theme.spacersm};
  margin-right: ${props => props.theme.spacersm};
`;
