import styled from "styled-components";

import { colors } from "../../../../../cssConstants";
import { B3, C2, H6 } from "../../../../common/StyledComponents";

export const BadgeWrapper = styled(C2)`
  background-color: ${props => props.theme.primaryColor};
  color: white;
  display: block;
  padding: 0 6px;
  border-radius: 2px;
  line-height: 18px;

  &:hover {
    cursor: default;
  }
`;

export const PopupWrapper = styled.div`
  max-width: 280px;
`;

export const PopupTitle = styled(H6)`
  color: ${colors.surfacePrimary};
`;

export const PopupContent = styled(B3)`
  color: ${colors.surfacePrimary};
  margin-top: 16px;
`;
