import React, { useCallback, useMemo } from "react";

import { colors } from "../../../../../../cssConstants";
import ColorPicker from "../../../../../common/ColorPicker";
import { useComponentConfigContext } from "../../common/ComponentConfigContext";
import { ConfigSection, Field } from "../../common/ConfigPanel";
import { TabsConfigState } from "../types";

export default function DesignConfig() {
  const context = useComponentConfigContext();
  const { dispatch } = context;
  const state = context.state as TabsConfigState;

  const tabs = state.draftComponent.properties.tabs;

  const themeProperties = useMemo(() => {
    return state.draftComponent.properties.theme_properties || {};
  }, [state.draftComponent.properties.theme_properties]);

  const updateTabColor = useCallback(
    change => {
      dispatch({
        type: "UPDATE_COMPONENT_THEME_PROPERTIES",
        payload: {
          properties: {
            ...themeProperties,
            ...change
          }
        }
      });
    },
    [dispatch, themeProperties]
  );

  return (
    <ConfigSection title="Style">
      {tabs.map((tab, index) => (
        <Field key={index}>
          <label>{tab.name} color</label>
          <ColorPicker
            value={themeProperties[`tab-${index}`] || colors.primaryColor}
            size="small"
            onChange={value => {
              updateTabColor({ [`tab-${index}`]: value });
            }}
          />
        </Field>
      ))}
    </ConfigSection>
  );
}
