import { AttributeTypes } from "../../../constants";
import {
  BindingInputParameter,
  ComponentInputParameter,
  InputParameter,
  InputParameterBase,
  ParameterType,
  SpaceComponentPackage,
  SpaceComponentType,
  StaticInputParameter,
  TemplateInputParameter
} from "../../../types";
import { makeComponent } from "../../spaces/SpaceConfig/SpaceConfigContext/useSpaceConfig";
import { BlankValueType } from "../../spaces/SpaceRoot/constants";
import { toProperty } from "../../spaces/SpaceRoot/SpaceComponent/common/util";
import { assertNever } from "../../util/assertNever";
import { encodeAttributeValue } from "../utils";

import { FormState, Parameter, ParameterTypeOption } from "./reducer";
import { FunctionParameterNode } from "./types";

const COMMON_PARAMETER_TYPES: ParameterTypeOption[] = [
  ParameterType.COMPONENT,
  ParameterType.BINDING,
  ParameterType.STATIC,
  ParameterType.UNRESOLVED,
  "null"
];

export const PARAMETER_TYPE_OPTIONS: Record<AttributeTypes, ParameterTypeOption[]> = {
  [AttributeTypes.BINARY]: COMMON_PARAMETER_TYPES,
  [AttributeTypes.BOOL]: COMMON_PARAMETER_TYPES,
  [AttributeTypes.DATE]: [...COMMON_PARAMETER_TYPES, ParameterType.DATE_TODAY],
  [AttributeTypes.DATETIME]: [...COMMON_PARAMETER_TYPES, ParameterType.DATETIME_NOW],
  [AttributeTypes.DECIMAL]: COMMON_PARAMETER_TYPES,
  [AttributeTypes.FILE]: COMMON_PARAMETER_TYPES,
  [AttributeTypes.FLOAT]: COMMON_PARAMETER_TYPES,
  [AttributeTypes.INT]: COMMON_PARAMETER_TYPES,
  [AttributeTypes.JSON]: COMMON_PARAMETER_TYPES,
  [AttributeTypes.STRING]: [
    ...COMMON_PARAMETER_TYPES,
    ParameterType.TEMPLATE,
    ParameterType.UUID,
    ParameterType.DATE_TODAY,
    ParameterType.DATETIME_NOW,
    ParameterType.TIME_NOW
  ],
  [AttributeTypes.TIME]: [...COMMON_PARAMETER_TYPES, ParameterType.TIME_NOW],
  [AttributeTypes.TIMESTAMP]: [...COMMON_PARAMETER_TYPES, ParameterType.DATETIME_NOW]
};

export const PARAMETER_TYPE_OPTIONS_DISPLAY_NAMES: Record<ParameterTypeOption, string> =
  {
    [ParameterType.COMPONENT]: "User completes field (opens form)",
    [ParameterType.STATIC]: "Value",
    [ParameterType.BINDING]: "Data binding",
    [ParameterType.DATETIME_NOW]: "Current date and time",
    [ParameterType.DATE_TODAY]: "Current date",
    [ParameterType.TIME_NOW]: "Current time",
    [ParameterType.UUID]: "Generate UUID",
    [ParameterType.PENDING]: "unsupported",
    [ParameterType.FILE]: "unsupported",
    [ParameterType.TEMPLATE]: "Template",
    [ParameterType.UNRESOLVED]: "Leave unresolved (complete in app)",
    null: "NULL value"
  };

export const ALL_SUPPORTED_PARAMETER_TYPES = [
  ParameterType.COMPONENT,
  ParameterType.BINDING,
  ParameterType.TEMPLATE,
  ParameterType.STATIC,
  ParameterType.DATE_TODAY,
  ParameterType.DATETIME_NOW,
  ParameterType.TIME_NOW,
  ParameterType.UUID
];

export const getDefaultParameterType = (
  attributeType: AttributeTypes,
  allowedParameterTypes: ParameterType[]
): ParameterType => {
  return allowedParameterTypes.filter(type =>
    PARAMETER_TYPE_OPTIONS[attributeType].includes(type)
  )[0];
};

// Available component types per attribute types can be seen in
// ATTRIBUTE_TYPES_WITH_COMPONENT_SUPPORT in src/components/spaces/SpaceRoot/constants.ts
export const ComponentTypeOptions: Record<AttributeTypes, SpaceComponentType[]> = {
  [AttributeTypes.BINARY]: ["FILE_PICKER"],
  [AttributeTypes.BOOL]: ["CHECKBOX", "DROPDOWN", "RADIO_BUTTON"],
  [AttributeTypes.DATE]: ["DATE_TIME_PICKER"],
  [AttributeTypes.DATETIME]: ["DATE_TIME_PICKER"],
  [AttributeTypes.DECIMAL]: ["CUSTOM_FIELD", "DROPDOWN", "RADIO_BUTTON"],
  [AttributeTypes.FILE]: ["FILE_PICKER"],
  [AttributeTypes.FLOAT]: ["CUSTOM_FIELD", "DROPDOWN", "RADIO_BUTTON"],
  [AttributeTypes.INT]: ["CUSTOM_FIELD", "DROPDOWN", "RADIO_BUTTON"],
  [AttributeTypes.JSON]: ["JSON_INPUT", "TAG_SELECTOR"],
  [AttributeTypes.STRING]: [
    "CUSTOM_FIELD",
    "TEXT_AREA",
    "DROPDOWN",
    "RADIO_BUTTON",
    "TAG_SELECTOR"
  ],
  [AttributeTypes.TIME]: ["DATE_TIME_PICKER"],
  [AttributeTypes.TIMESTAMP]: ["DATE_TIME_PICKER"]
};

export const getDefaultComponentType = (
  type: AttributeTypes
): SpaceComponentType | undefined => {
  return ComponentTypeOptions[type].length ? ComponentTypeOptions[type][0] : undefined;
};

export const getComponentProperties = (
  componentType: SpaceComponentType,
  attributeType: AttributeTypes,
  pkg: SpaceComponentPackage
) => {
  return pkg.ensureComponent(
    makeComponent(new Set(), {
      type: componentType,
      properties: {
        allow_blank: false, // require user to manually enable
        blank_value_type: BlankValueType.NULL_VALUE,
        validation_type: toProperty(attributeType)
      }
    })
  ).properties;
};

export function encodeStaticParameterValue(type: AttributeTypes, value: any) {
  // The baseEncodeValue uses "string" for JSON, but we want an object
  if (type === AttributeTypes.JSON && typeof value === "string") {
    return JSON.parse(value);
  }

  return encodeAttributeValue(type, value);
}

export function decodeStaticParameterValue(type: AttributeTypes, value: any) {
  if (type === AttributeTypes.JSON) {
    // Pretty formatting by setting 2 spaces
    return JSON.stringify(value, null, 2);
  }

  return value;
}

export const toInputParameters = (state: FormState): InputParameter[] =>
  state.parameters
    .filter(p => p.included)
    .map(p => {
      switch (p.type) {
        case ParameterType.COMPONENT: {
          const ip: ComponentInputParameter = {
            name: p.name,
            type: p.type,
            componentType: p.componentType || "CUSTOM_FIELD",
            componentProperties: p.componentProperties || {}
          };
          return ip;
        }
        case ParameterType.BINDING: {
          if (!p.resolver) throw new Error("expected resolver");

          const ip: BindingInputParameter = {
            name: p.name,
            type: p.type,
            binding: p.binding || "",
            resolver: p.resolver
          };
          return ip;
        }
        case ParameterType.TEMPLATE: {
          if (!p.resolver) throw new Error("expected resolver");

          const ip: TemplateInputParameter = {
            name: p.name,
            type: p.type,
            template: p.template || "``",
            resolver: p.resolver
          };
          return ip;
        }
        case ParameterType.STATIC: {
          const ip: StaticInputParameter = {
            name: p.name,
            type: p.type,
            value: encodeStaticParameterValue(p.attributeType, p.value)
          };
          return ip;
        }
        case ParameterType.DATE_TODAY: {
          const ip: InputParameterBase<ParameterType.DATE_TODAY> = {
            name: p.name,
            type: p.type
          };
          return ip;
        }
        case ParameterType.DATETIME_NOW: {
          const ip: InputParameterBase<ParameterType.DATETIME_NOW> = {
            name: p.name,
            type: p.type
          };
          return ip;
        }
        case ParameterType.TIME_NOW: {
          const ip: InputParameterBase<ParameterType.TIME_NOW> = {
            name: p.name,
            type: p.type
          };
          return ip;
        }
        case ParameterType.UUID: {
          const ip: InputParameterBase<ParameterType.UUID> = {
            name: p.name,
            type: p.type
          };
          return ip;
        }
        case ParameterType.UNRESOLVED: {
          const ip: InputParameterBase<ParameterType.UNRESOLVED> = {
            name: p.name,
            type: p.type
          };
          return ip;
        }
        case ParameterType.FILE:
        case ParameterType.PENDING:
          throw new Error("not supported");
        default:
          return assertNever(p.type);
      }
    });

export const getComponentTypes = (type: AttributeTypes): SpaceComponentType[] => {
  return ComponentTypeOptions[type];
};

export const ensureComponentProperties = (
  name: string,
  attributeType: AttributeTypes,
  parameters: Parameter[],
  initialInputParameters: InputParameter[],
  findSpaceComponentPackage: (
    type: SpaceComponentType
  ) => SpaceComponentPackage | undefined,
  componentType?: SpaceComponentType,
  componentProperties?: Record<string, any>
) => {
  if (!componentType) return {};

  const lastInputParam =
    parameters.find(p => p.name === name) ||
    initialInputParameters.find(ip => ip.name === name);

  if (
    lastInputParam?.type === ParameterType.COMPONENT &&
    lastInputParam?.componentType === componentType
  ) {
    return componentProperties || {};
  }

  const pkg = findSpaceComponentPackage(componentType);
  if (!pkg) {
    throw new Error(`Could not find component package for ${componentType}`);
  }
  return getComponentProperties(componentType, attributeType, pkg);
};

export const getParameter = (
  node: FunctionParameterNode,
  ip: InputParameter | undefined,
  allowedParameterTypes: ParameterType[],
  parameters: Parameter[],
  initialInputParameters: InputParameter[],
  findSpaceComponentPackage: (
    type: SpaceComponentType
  ) => SpaceComponentPackage | undefined
): Parameter => {
  let componentType: SpaceComponentType | undefined = undefined;
  if (ip && ip.type === ParameterType.COMPONENT) {
    componentType = ip.componentType;
  } else if (ip === undefined) {
    componentType = getDefaultComponentType(node.type);
  }

  const isNullParam = ip?.type === ParameterType.STATIC && ip.value === null;
  return {
    name: node.name,
    componentType,
    componentProperties: ensureComponentProperties(
      node.name,
      node.type,
      parameters,
      initialInputParameters,
      findSpaceComponentPackage,
      componentType,
      ip?.type === ParameterType.COMPONENT ? ip.componentProperties : undefined
    ),
    attributeType: node.type,
    type: ip?.type || getDefaultParameterType(node.type, allowedParameterTypes),
    required: isNullParam ? false : node.required,
    included: node.required || !!ip,
    binding: ip?.type === ParameterType.BINDING ? ip.binding : undefined,
    resolver:
      ip?.type === ParameterType.BINDING || ip?.type === ParameterType.TEMPLATE
        ? ip.resolver
        : undefined,
    template: ip?.type === ParameterType.TEMPLATE ? ip.template : undefined,
    value:
      ip?.type === ParameterType.STATIC
        ? decodeStaticParameterValue(node.type, ip.value)
        : undefined
  };
};

export const getFunctionParameter = (
  name: string,
  functionParameters: {
    edges: {
      node: FunctionParameterNode;
    }[];
  }
) => {
  return functionParameters.edges
    .map(({ node }) => node)
    .find(node => {
      return node.name === name;
    });
};
