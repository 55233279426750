import React from "react";

import { useMutation, useQuery } from "@apollo/react-hooks";
import { Form } from "antd";
import { FormComponentProps } from "antd/lib/form";
import FormItem from "antd/lib/form/FormItem";
import gql from "graphql-tag";
import _ from "lodash";
import { generatePath, Navigate } from "react-router";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { Routes } from "../../../constants";
import { partialAuthClient, publicClient } from "../../../graphql";
import { AuthStatus } from "../../../types";
import Button from "../../common/Button";
import { AUTH_STATUS } from "../../common/hooks/useAuthStatus";
import Input from "../../common/Input";
import Message from "../../common/Message";
import TitleContainer, { ContainerSize } from "../../common/TitleContainer";
import { TwoFactorTokenContainer } from "../../settings/TwoFactor/ConfirmTwoFactor";
import { withAuthStatus, AuthStatusProps } from "../WithAuthStatus/withAuthStatus";

export const SUBMIT_TWO_FACTOR = gql`
  mutation SubmitTwoFactor($token: String!) {
    submitTwoFactor(token: $token) {
      user {
        id
        email
        organization {
          id
        }
        roles {
          edges {
            node {
              id
              isManaged
              name
              slug
            }
          }
        }
      }
    }
  }
`;

export const HAS_DEVICE_QUERY = gql`
  query hasDevice {
    hasDevice
  }
`;

export const TwoFactorForm = (props: FormComponentProps & AuthStatusProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [params] = useSearchParams();
  const next = params.get("next");
  const { refetch } = useQuery(AUTH_STATUS, { client: publicClient });
  const [submitTwoFactor] = useMutation(SUBMIT_TWO_FACTOR, {
    client: partialAuthClient,
    onCompleted: res => {
      const user = _.get(res, "submitTwoFactor.user");
      if (!user) {
        Message.error("Invalid token. Please try again");
      } else {
        refetch().then(() => {
          if (next) {
            const url = new URL(next);
            navigate(url.pathname + url.search);
          } else {
            navigate(generatePath(Routes.DASHBOARD));
          }
        });
      }
    }
  });

  const { data } = useQuery(HAS_DEVICE_QUERY, {
    client: partialAuthClient
  });

  const { getFieldDecorator } = props.form;

  const handleSubmit = (e: any) => {
    e.preventDefault();

    props.form.validateFields((errors, values) => {
      if (errors) {
        return;
      }
      submitTwoFactor({
        variables: { token: values.Token }
      });
    });
  };

  if (props.status === AuthStatus.Authenticated) {
    if (next) {
      const url = new URL(next);
      return <Navigate to={{ pathname: url.pathname, search: url.search }} />;
    } else {
      return <Navigate to={generatePath(Routes.DASHBOARD)} />;
    }
  }

  if (data && data.hasDevice === false) {
    return (
      <Navigate
        to={{
          pathname: Routes.SETUP_TWO_FACTOR,
          search: location.search
        }}
      />
    );
  }

  return (
    <React.Fragment>
      <TitleContainer title="Two-Step Verification" size={ContainerSize.Large}>
        <TwoFactorTokenContainer>
          <p>
            Open your authenticator app and enter your passcode below to verify you’re
            you.
          </p>
          <div className="confirm">
            <Form onSubmit={handleSubmit}>
              <FormItem>
                {getFieldDecorator("Token", {
                  rules: [{ required: true }]
                })(
                  <Input
                    autoFocus
                    autoComplete="off"
                    customSize="medium"
                    placeholder={"Enter your code."}
                  />
                )}
              </FormItem>
              <Button size="medium" htmlType="submit" type="primary">
                Verify
              </Button>
            </Form>
          </div>
        </TwoFactorTokenContainer>
      </TitleContainer>
    </React.Fragment>
  );
};

export default Form.create({ name: "use2fa" })(withAuthStatus(TwoFactorForm));
