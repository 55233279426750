import { AttributeTypes } from "../../../../../constants";
import {
  FiltersOption,
  SortByOption,
  SpaceComponentObject,
  FunctionParameterNode,
  FunctionNode,
  APIFiltersOption,
  APISortByOption
} from "../../../../../types";
import { humanize } from "../../../../util";
import { BlankValueType, DefaultValueType } from "../../constants";

import { InputComponentProperties } from "./types";
import { InputParameter } from "./useFuncParams/types";

interface _Metadata {
  filters?: { options: APIFiltersOption[] };
  sortBy?: { options: APISortByOption[] };
}

export const extractFiltersOptions = (metadata: _Metadata): FiltersOption[] =>
  metadata.filters?.options.map(f => ({
    ...f,
    name: humanize(f.sourceName)
  })) || [];

export const extractSortByOptions = (metadata: _Metadata): SortByOption[] =>
  metadata.sortBy?.options.map(sourceName => ({
    name: humanize(sourceName),
    sourceName: sourceName
  })) || [];

const DEFAULT_LIST_FUNCTION_REGEX = /^List ([^\s]*?) records$/;

export const functionTitleToComponentName = (title: string) => {
  const match = title.match(DEFAULT_LIST_FUNCTION_REGEX);
  if (match) {
    const split = match[1].split(".", 2);
    return humanize(split.join("_"));
  }
  return title;
};

export const hasSource = (component: SpaceComponentObject): boolean => {
  return component.view?.function?.id || component.properties.binding;
};

// client expects values to be uppercase
export const toAttributeType = (validationType: string | undefined): AttributeTypes => {
  return validationType?.toUpperCase() as AttributeTypes;
};

// server expects values to be lowercase
export const toProperty = (
  validationType: AttributeTypes | undefined
): string | undefined => {
  return validationType?.toLowerCase();
};

// helper for finding descendant component node with matching slug
export const findDescendentNodeBySlug = (
  slug: string,
  ancestorComponent: SpaceComponentObject
): SpaceComponentObject | undefined => {
  const childNode = ancestorComponent.componentTreeNodes.find(sc => sc.slug === slug);
  if (childNode) return childNode;

  for (const node of ancestorComponent.componentTreeNodes) {
    const descendentChildNode = findDescendentNodeBySlug(slug, node);
    if (descendentChildNode) {
      return descendentChildNode;
    }
  }
};

export const getUsedFunctionParameters = (
  fn: FunctionNode,
  inputParameters: InputParameter[]
): FunctionParameterNode[] => {
  const paramMap = Object.fromEntries(
    fn.functionParameters?.edges.map(e => [e.node.name, e.node]) || []
  );
  return inputParameters.map(ip => paramMap[ip.name]).filter(p => !!p);
};

export const getCommonInputProperties = (
  properties: Partial<InputComponentProperties>,
  defaultValueType: DefaultValueType,
  defaultValue: string | boolean,
  attributeType: AttributeTypes,
  hasPlaceholder: boolean
) => {
  const props = {
    ...properties,
    placeholder: properties.placeholder || "",
    default_value_type: properties.default_value_type || defaultValueType,
    default_value: properties.default_value || defaultValue,
    validation_type:
      properties.validation_type || (toProperty(attributeType) as string),
    allow_blank: properties.allow_blank ?? true,
    blank_value_type: properties.blank_value_type || BlankValueType.UNDEFINED,
    validation_rules: properties.validation_rules || []
  };
  if (hasPlaceholder) {
    props.placeholder = properties.placeholder || "";
  }
  return props;
};
