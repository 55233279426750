import React, { Dispatch } from "react";

import { Checkbox, Select } from "antd";
import { Draggable, DraggableProvided } from "react-beautiful-dnd";

import { AttributeTypes } from "../../../../constants";
import {
  BindingShape,
  ParameterType,
  Resolver,
  SpaceComponentType
} from "../../../../types";
import { BindingCascader, getOption, Option } from "../../../common/BindingCascader";
import { SpaceContextParams } from "../../../spaces/SpaceRoot/SpaceContext/SpaceContext";
import {
  useSpaceComponentPackage,
  useStableSpaceContext
} from "../../../spaces/SpaceRoot/SpaceContext/StableSpaceContext";
import { DebouncedAttributeInput } from "../../AttributeInput";
import { BooleanSelect } from "../../AttributeInput/BooleanInput";
import DragHandle from "../../SortableList/dragHandleCompact.svg";
import TemplateEditor from "../../TemplateEditor";
import {
  Action,
  ActionType,
  InternalField,
  Parameter,
  ParameterTypeOption
} from "../reducer";
import { FunctionParameterNode } from "../types";
import {
  getComponentTypes,
  PARAMETER_TYPE_OPTIONS,
  PARAMETER_TYPE_OPTIONS_DISPLAY_NAMES
} from "../utils";

import * as styled from "./styledComponents";

const createBindingOptions = (contextParams: SpaceContextParams): Option[] => {
  return Object.entries(contextParams).map(([k, v]) => ({
    label: v.title,
    value: k,
    bindingShape: BindingShape.OBJECT,
    children: v.schema.map(getOption)
  }));
};

const ParameterRow = ({
  parameter,
  internalFields,
  dispatch,
  draggableProvided,
  functionParameter,
  contextParams,
  allowedParameterTypes,
  allowedBindingShapes = [BindingShape.SCALAR]
}: {
  parameter: Parameter;
  internalFields: InternalField[];
  dispatch: Dispatch<Action>;
  contextParams: SpaceContextParams;
  draggableProvided?: DraggableProvided;
  functionParameter: FunctionParameterNode | undefined;
  allowedParameterTypes: ParameterTypeOption[];
  allowedBindingShapes: BindingShape[];
}) => {
  const [collapsed, setCollapsed] = React.useState(false);
  const parameterTypes = allowedParameterTypes
    .filter(type => PARAMETER_TYPE_OPTIONS[parameter.attributeType].includes(type))
    .concat("null");
  const componentTypes = getComponentTypes(parameter.attributeType);
  const { findSpaceComponentPackage } = useStableSpaceContext();
  const getComponentDisplayName = (type: SpaceComponentType) => {
    return findSpaceComponentPackage(type)?.displayName || type;
  };
  const isAutoConfigured = !!internalFields.find(field => field.name === parameter.name)
    ?.isAutoConfigured;

  const isCollapseable =
    parameter.included && !isAutoConfigured && !!parameterTypes.length;

  const dragHandleCell = draggableProvided ? (
    <styled.DragHandleCell>
      <div {...draggableProvided.dragHandleProps}>
        <DragHandle />
      </div>
    </styled.DragHandleCell>
  ) : (
    <styled.DragHandleCell></styled.DragHandleCell>
  );

  const includeCell = (
    <styled.IncludedCell>
      <Checkbox
        checked={parameter.included}
        disabled={functionParameter?.required}
        onChange={e =>
          dispatch({
            type: ActionType.UPDATE_PARAMETER,
            payload: { name: parameter.name, included: e.target.checked }
          })
        }
      />
    </styled.IncludedCell>
  );

  const nameCell = (
    <styled.NameCell disabled={!parameter.included}>{parameter.name}</styled.NameCell>
  );

  const bindingOptions = React.useMemo(
    () => createBindingOptions(contextParams),
    [contextParams]
  );

  const changeBindingOnSelect = allowedBindingShapes.includes(BindingShape.OBJECT);

  return (
    <styled.Row
      isClickTarget={isCollapseable && collapsed}
      {...draggableProvided?.draggableProps}
      ref={draggableProvided?.innerRef}
      onClick={() => {
        // only toggle when collapsed
        if (!collapsed) return;
        setCollapsed(false);
      }}
    >
      {isCollapseable && collapsed ? (
        <>
          {dragHandleCell}
          {includeCell}
          {nameCell}
          <styled.ValueCell>
            <styled.ValueText active>
              {parameter.type === ParameterType.STATIC && parameter.value === null
                ? "NULL value"
                : PARAMETER_TYPE_OPTIONS_DISPLAY_NAMES[parameter.type]}
            </styled.ValueText>
          </styled.ValueCell>
        </>
      ) : (
        <>
          {dragHandleCell}
          {includeCell}
          {nameCell}
          <styled.ValueCell>
            {!parameter.included ? (
              <styled.ValueText>Disabled</styled.ValueText>
            ) : isAutoConfigured ? (
              <styled.ValueText>Auto-configured</styled.ValueText>
            ) : parameterTypes.length ? (
              <>
                <styled.Field>
                  <label>Type</label>
                  <styled.Select
                    value={parameter.value === null ? "null" : parameter.type}
                    onChange={type => {
                      dispatch({
                        type: ActionType.UPDATE_PARAMETER_TYPE,
                        payload: {
                          name: parameter.name,
                          type: type as ParameterTypeOption
                        }
                      });
                    }}
                    getPopupContainer={trigger => trigger.parentNode as HTMLElement}
                  >
                    {parameterTypes.map(type => (
                      <Select.Option key={type}>
                        {PARAMETER_TYPE_OPTIONS_DISPLAY_NAMES[type]}
                      </Select.Option>
                    ))}
                  </styled.Select>
                </styled.Field>

                {parameter.type === ParameterType.COMPONENT && (
                  <styled.Field>
                    <label>Component</label>
                    <styled.Select
                      value={parameter.componentType}
                      placeholder="Select form field type"
                      onChange={type =>
                        dispatch({
                          type: ActionType.UPDATE_PARAMETER,
                          payload: {
                            name: parameter.name,
                            componentType: type as SpaceComponentType
                          }
                        })
                      }
                      getPopupContainer={trigger => trigger.parentNode as HTMLElement}
                    >
                      {componentTypes.map(type => (
                        <Select.Option key={type}>
                          {getComponentDisplayName(type)}
                        </Select.Option>
                      ))}
                    </styled.Select>
                  </styled.Field>
                )}
                {parameter.type === ParameterType.BINDING && (
                  <styled.Field>
                    <label>Binding</label>
                    <BindingCascader
                      options={bindingOptions}
                      defaultValue={parameter.binding}
                      value={parameter.binding}
                      selectable={allowedBindingShapes}
                      changeOnSelect={changeBindingOnSelect}
                      onChange={path => {
                        dispatch({
                          type: ActionType.UPDATE_PARAMETER,
                          payload: {
                            name: parameter.name,
                            binding: path,
                            resolver: Resolver.PIPELINE
                          }
                        });
                      }}
                    />
                  </styled.Field>
                )}
                {parameter.type === ParameterType.TEMPLATE && (
                  <styled.Field>
                    <label>Template</label>
                    <TemplateEditor
                      bindingOptions={bindingOptions}
                      value={parameter.template || "``"}
                      placeholder="${user.email}"
                      onChange={(template: string) =>
                        dispatch({
                          type: ActionType.UPDATE_PARAMETER,
                          payload: {
                            name: parameter.name,
                            template,
                            resolver: Resolver.PIPELINE
                          }
                        })
                      }
                    />
                  </styled.Field>
                )}
                {parameter.type === ParameterType.STATIC && parameter.value !== null && (
                  <styled.Field>
                    <label>Value</label>
                    <DebouncedAttributeInput
                      sourceName={parameter.name}
                      onChange={(newValue: any) => {
                        let value = newValue;
                        if (parameter.attributeType === AttributeTypes.BOOL) {
                          value = newValue === BooleanSelect.TRUE ? true : false;
                        }
                        dispatch({
                          type: ActionType.UPDATE_PARAMETER,
                          payload: {
                            name: parameter.name,
                            value
                          }
                        });
                      }}
                      value={parameter.value}
                      sourceNullable={false}
                      required
                      verbose
                      useSimpleJsonInput
                      sourceType={parameter.attributeType}
                      onValidate={valid => {
                        dispatch({
                          type: ActionType.SET_PARAMETER_VALIDITY,
                          payload: {
                            name: parameter.name,
                            isValid: valid
                          }
                        });
                      }}
                    />
                  </styled.Field>
                )}
                {parameter.type === ParameterType.COMPONENT &&
                  !!parameter.componentType && (
                    <ComponentConfigField
                      componentType={parameter.componentType}
                      slug={`ephemeral__${parameter.name}`}
                    />
                  )}
              </>
            ) : (
              <styled.ValueText>Not supported</styled.ValueText>
            )}
          </styled.ValueCell>
        </>
      )}
      {isCollapseable ? (
        <styled.ChevronWrapper
          onClick={() => {
            setCollapsed(!collapsed);
          }}
        >
          <styled.Chevron expanded={!collapsed} />
        </styled.ChevronWrapper>
      ) : (
        <styled.ChevronWrapper />
      )}
    </styled.Row>
  );
};

const DraggableParameterRow = ({
  index,
  parameter,
  internalFields,
  dispatch,
  functionParameter,
  contextParams,
  allowedParameterTypes,
  allowedBindingShapes
}: {
  index: number;
  parameter: Parameter;
  internalFields: InternalField[];
  dispatch: Dispatch<Action>;
  contextParams: SpaceContextParams;
  functionParameter: FunctionParameterNode | undefined;
  allowedParameterTypes: ParameterType[];
  allowedBindingShapes: BindingShape[];
}) => {
  // internal fields are not draggable
  if (internalFields.map(field => field.name).includes(parameter.name)) {
    return (
      <ParameterRow
        parameter={parameter}
        contextParams={contextParams}
        internalFields={internalFields}
        dispatch={dispatch}
        functionParameter={functionParameter}
        allowedParameterTypes={allowedParameterTypes}
        allowedBindingShapes={allowedBindingShapes}
      />
    );
  }
  return (
    <Draggable
      key={parameter.name}
      draggableId={
        parameter.name || `${index}` /* use index if param name hasn't been set */
      }
      index={index}
    >
      {(provided, _) => (
        <ParameterRow
          parameter={parameter}
          contextParams={contextParams}
          internalFields={internalFields}
          dispatch={dispatch}
          draggableProvided={provided}
          functionParameter={functionParameter}
          allowedParameterTypes={allowedParameterTypes}
          allowedBindingShapes={allowedBindingShapes}
        />
      )}
    </Draggable>
  );
};

function ComponentConfigField({
  componentType,
  slug
}: {
  componentType: SpaceComponentType;
  slug: string;
}) {
  const pkg = useSpaceComponentPackage(componentType);
  if (!pkg.InlineConfigurationComponent) return null;
  return (
    <styled.Field>
      <pkg.InlineConfigurationComponent slug={slug} />
    </styled.Field>
  );
}

export default DraggableParameterRow;
