import { useQuery } from "@apollo/react-hooks";
import { QueryHookOptions } from "@apollo/react-hooks/lib/types";
import gql from "graphql-tag";

import { RelayNode } from "../../../../types";

import { AutomationNode, FunctionNode, ExecutionStatus, UserNode } from "./common";

interface AuditLogNode extends RelayNode {
  id: string;
  user: UserNode;
  automation: AutomationNode;
  function: FunctionNode;
  createdAt: string;
  action: string;
  payload: any;
  userError: string;
  executionStatus: ExecutionStatus;
}

export interface Data {
  node: AuditLogNode;
}

export const GET_AUDIT_LOG_BY_ID = gql`
  query GetAuditLog($id: ID!) {
    node(id: $id) {
      ... on AuditLogNode {
        id
        user {
          id
          firstName
          lastName
        }
        automation {
          id
          name
        }
        function {
          id
          name
          title
          dataSource {
            id
            integration
            name
          }
        }
        createdAt
        action
        payload
        userError
        executionStatus
      }
    }
  }
`;

export const useActivityLogById = (options?: QueryHookOptions<Data>) => {
  return useQuery<Data>(GET_AUDIT_LOG_BY_ID, options);
};
