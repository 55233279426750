import styled from "styled-components";

import { colorTokens } from "../../../../../../cssConstants";
import { C2, H6 } from "../../../../StyledComponents";

export const LIGHTEN_BACKGROUND_COLOR = "rgba(255, 255, 255, 0.05)";

export const ActionLinks = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: ${props => props.theme.spacersm};
`;

export const SelectLabel = styled(C2)`
  display: block;
  line-height: 18px;
  color: ${props => props.theme.textSurfaceSecondary};
  padding-bottom: 4px;
`;

export const SubsectionHeader = styled(H6)`
  color: ${colorTokens.white};
`;

export const SubstepContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${LIGHTEN_BACKGROUND_COLOR};
  border-radius: ${props => props.theme.containerPrimaryBorderRadius};
  border: 1px solid ${LIGHTEN_BACKGROUND_COLOR};
`;

export const Hr = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${colorTokens.grey1600};
`;

export const PaddedContainer = styled.div`
  padding: 16px;
`;
