import React, { ReactNode, MouseEvent } from "react";

import { Button as AntButton, Icon } from "antd";
import styled, { CSSProperties } from "styled-components";

import { ElementStyle } from "../../spaces/layout/util";

export type ButtonTypes = "primary" | "link" | "dashed" | "danger" | "default";
export type TextAlignType = "left" | "start" | "center" | "right" | "end";
export type JustifyContentType =
  | "flex-start"
  | "flex-end"
  | "center"
  | "space-between"
  | "space-around"
  | "stretch";

const StyledButton = styled(AntButton)`
  &.medium {
    font-size: ${props => props.theme.mediumFontSize};
    height: 40px;
  }
`;

const FlexContainer = styled.div<{ justifyContent?: JustifyContentType }>`
  display: flex;
  align-items: center;
  justify-content: ${props => (props.justifyContent ? props.justifyContent : "center")};

  > *:not(:first-child) {
    margin-left: ${props => props.theme.spacersm};
  }
`;

function textAlignToAlignContentType(
  textAlign?: TextAlignType
): JustifyContentType | undefined {
  switch (textAlign) {
    case "start":
    case "left":
      return "flex-start";
    case "center":
      return "center";
    case "end":
    case "right":
      return "flex-end";
  }

  return undefined;
}

export interface Props {
  size?: "medium";
  children?: ReactNode;
  type?: ButtonTypes;
  htmlType?: "submit";
  loading?: boolean;
  disabled?: boolean;
  className?: string;
  style?: {};
  fixedWidthLoadingState?: boolean;
  icon?: string;
  form?: string;
  textStyle?: ElementStyle;
  onClick?: (e?: MouseEvent) => void;
}

const Button = ({
  size,
  children,
  className,
  fixedWidthLoadingState = false,
  loading,
  icon,
  textStyle,
  onClick,
  type,
  ...rest
}: Props) => {
  const [width, setWidth] = React.useState(null);
  const [wasLoading, setWasLoading] = React.useState(false);
  const sizerRef = React.useRef<any>(null);
  React.useLayoutEffect(() => {
    if (!fixedWidthLoadingState || wasLoading === loading) {
      return;
    }
    if (wasLoading && !loading) {
      setWidth(null);
      setWasLoading(false);
      return;
    }
    if (!wasLoading && loading) {
      setWidth(sizerRef.current.getBoundingClientRect().width);
      setWasLoading(true);
      return;
    }
  }, [loading, wasLoading, fixedWidthLoadingState]);

  let buttonClass = `${className} ` || "";
  if (size) {
    buttonClass += `${size} `;
  }
  const disabled = rest.disabled || loading;
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (disabled || typeof onClick !== "function") return;
    onClick(e);
  };

  const isLink = type === "link";
  const alignContent = isLink
    ? textAlignToAlignContentType(textStyle?.textAlign as TextAlignType | undefined)
    : "center";

  // Don't use color from the style config, let the system use
  // properties.button_background_color instead, which also colors the text
  const { color: _, ...linkStyle } = textStyle || { color: null };

  return (
    <StyledButton
      type={type}
      className={buttonClass}
      loading={loading && !fixedWidthLoadingState}
      disabled={disabled}
      onClick={handleClick}
      style={isLink ? (linkStyle as CSSProperties) : undefined}
      {...rest}
    >
      {fixedWidthLoadingState ? (
        loading && width ? (
          <FlexContainer style={width ? { width: `${width}px` } : {}}>
            <Icon type="loading" />
          </FlexContainer>
        ) : (
          <FlexContainer
            ref={sizerRef as any}
            style={width ? { width: `${width}px` } : {}}
            justifyContent={alignContent}
          >
            {icon && <Icon type={icon} />}
            {children && <span>{children}</span>}
          </FlexContainer>
        )
      ) : loading ? (
        children
      ) : (
        <FlexContainer justifyContent={alignContent}>
          {icon && <Icon type={icon} />}
          {children && <span>{children}</span>}
        </FlexContainer>
      )}
    </StyledButton>
  );
};

export default Button;
