import styled from "styled-components";

import { LinkButtonNew } from "../../../common/StyledComponents";

export const TaskList = styled.div``;

export const TaskRow = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const TaskLink = styled(LinkButtonNew)`
  text-align: left;
  // This is the parent Ant button default. It removes extra content spacing in the span inside.
  height: 32px;
  > span {
    width: 180px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
  }
`;
