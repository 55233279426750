import React, { useEffect } from "react";

import { Select } from "antd";

import { FunctionValidationStatus, ParameterValues } from "..";
import {
  APIFiltersOption,
  APISortByOption,
  FunctionParameterInput
} from "../../../../types";
import { useEnvironmentContext } from "../../contexts/EnvironmentContext";

import { ParameterValuesForm } from "./ParameterValuesForm/ParameterValuesForm";
import * as styled from "./styledComponents";
import useFunctionEnvironments from "./useFunctionEnvironments";

export interface Config {
  environmentId?: string;
  values: ParameterValues;
}

export interface Props {
  environmentId: string | undefined;
  baseFunctionId: string;
  validationStatus: FunctionValidationStatus;
  functionExecuting: boolean;
  parameters: FunctionParameterInput[];
  parameterValues: ParameterValues;
  filtersOptions: APIFiltersOption[];
  sortByOptions: APISortByOption[];
  setEnvironmentId: (id?: string) => void;
  setParameterValues: (values: ParameterValues) => void;
  executeFunction: () => void;
}

export default function FunctionExecuteConfig({
  environmentId,
  functionExecuting,
  baseFunctionId,
  parameters,
  validationStatus,
  parameterValues,
  filtersOptions,
  sortByOptions,
  executeFunction,
  setEnvironmentId,
  setParameterValues
}: Props) {
  const { environments } = useFunctionEnvironments(baseFunctionId);
  const { getCurrentEnvironment } = useEnvironmentContext();

  useEffect(() => {
    // If no environment is selected, select the current environment if it is
    // supported, otherwise falling back to the default environment.
    if (environmentId) {
      return;
    }

    const id = getCurrentEnvironment().id;

    if (environments.some(env => env.id === id)) {
      setEnvironmentId(id);
    } else {
      setEnvironmentId(environments.find(e => e.isDefault)?.id);
    }
  }, [environments, environmentId, getCurrentEnvironment, setEnvironmentId]);

  return (
    <styled.PreviewFormPane>
      <styled.Title>Run Function</styled.Title>
      <Select
        value={environmentId}
        onChange={(id: string) => setEnvironmentId(id)}
        getPopupContainer={trigger => trigger.parentNode as HTMLElement}
        placeholder="Select an environment"
        disabled={baseFunctionId === ""}
      >
        {environments.map(env => (
          <Select.Option key={env.id}>{env.name} Environment</Select.Option>
        ))}
      </Select>
      <span>
        <styled.RunButton
          block
          type="primary"
          loading={functionExecuting}
          disabled={
            !environmentId || validationStatus !== FunctionValidationStatus.VALID
          }
          data-test="runButton"
          onClick={() => executeFunction()}
        >
          Run Function
        </styled.RunButton>
      </span>
      {!!parameters.length && (
        <styled.Instructions>Specify inputs for your query:</styled.Instructions>
      )}
      <ParameterValuesForm
        filtersOptions={filtersOptions}
        parameters={parameters}
        sortByOptions={sortByOptions}
        parameterValues={parameterValues}
        onParameterValuesChange={setParameterValues}
      />
    </styled.PreviewFormPane>
  );
}
