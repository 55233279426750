import { AttributeTypes } from "../../../../../constants";
import { InputParameter, ParameterType } from "../../../../../types";
import { FunctionNode } from "../../../FormBuilderModal/types";

export const inputParametersToCreateFunctionNode = (
  parameters: InputParameter[]
): FunctionNode => {
  return {
    functionParameters: {
      edges: parameters.map(p => ({
        node: {
          name: p.name,
          required: !!p.required,
          type: AttributeTypes.STRING
        }
      }))
    }
  };
};

export const SUPPORTED_PARAM_TYPES = [
  ParameterType.UNRESOLVED,
  ParameterType.STATIC,
  ParameterType.BINDING,
  ParameterType.TEMPLATE
];
