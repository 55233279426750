import React from "react";

import { Dot } from "../../../common/Dot/dot";
import { H6 } from "../../../common/StyledComponents";
import { ensureHexValue } from "../../../common/utils";
import AnalyticCard, { DEFAULT_ERROR_MESSAGE } from "../AnalyticCard/AnalyticCard";
import { ShortBlock, ShortThinBlock } from "../common/common";
import {
  StyledProgress,
  Column,
  Main,
  StateLabel,
  Dash,
  DashContainer,
  StatValue
} from "../common/styledComponents";
import { TimeRange } from "../DurationSelect/DurationSelect";
import { formatDuration } from "../util";

import { StateActivity, useStateActivity } from "./queries";
import {
  AverageTimeContainer,
  AverageTimeInStateDuration,
  AverageTimeInStateHeader,
  DurationColumn,
  HalfContainer,
  LockedAverageTimeInStateHeader
} from "./styledComponents";

const PROGRESS_COLOR = "#FFB83D";

export function StateActivityStatisticsView({
  statistics,
  className
}: {
  statistics: StateActivity[];
  className?: string;
}) {
  const totalTimeInStates = statistics.reduce((prev, current) => {
    return prev + (current.averageTimeInState || 0);
  }, 0);

  return (
    <Main className={className}>
      <HalfContainer>
        <Column grow={1}>
          <H6>State</H6>
          {statistics.map(stat => (
            <StateLabel key={`${stat.state.id}-label`}>
              <Dot color={ensureHexValue(stat.state.color)} />
              {stat.state.name}
            </StateLabel>
          ))}
        </Column>
        <Column grow={1}>
          <H6>Entered State</H6>
          {statistics.map(stat => (
            <StatValue key={`${stat.state.id}-entered`}>{stat.enterCount}</StatValue>
          ))}
        </Column>
        <Column grow={1}>
          <H6>Exited State</H6>
          {statistics.map(stat => (
            <StatValue key={`${stat.state.id}-entered`}>{stat.exitCount}</StatValue>
          ))}
        </Column>
      </HalfContainer>
      <HalfContainer>
        <DurationColumn grow={1}>
          <AverageTimeInStateHeader>Average time in state</AverageTimeInStateHeader>
          {statistics.map(stat => (
            <AverageTimeInStateDuration key={`${stat.state.id}-duration`}>
              {stat.averageTimeInState ? (
                formatDuration(stat.averageTimeInState)
              ) : (
                <DashContainer>
                  <Dash />
                </DashContainer>
              )}
            </AverageTimeInStateDuration>
          ))}
        </DurationColumn>
        <Column grow={5}>
          <H6>&nbsp;</H6>
          {statistics.map(stat => (
            <AverageTimeContainer key={`${stat.state.id}-duration-progress`}>
              {stat.averageTimeInState && (
                <StyledProgress
                  strokeWidth={10}
                  strokeColor={PROGRESS_COLOR}
                  percent={(stat.averageTimeInState / totalTimeInStates) * 100}
                  showInfo={false}
                />
              )}
            </AverageTimeContainer>
          ))}
        </Column>
      </HalfContainer>
    </Main>
  );
}

export function LockedStateActivityStatisticsView({
  statistics,
  className
}: {
  statistics: StateActivity[];
  className?: string;
}) {
  return (
    <Main className={className}>
      <HalfContainer>
        <Column grow={1}>
          <H6>State</H6>
          {statistics.map(stat => (
            <StateLabel key={`${stat.state.id}-label`}>
              <Dot color={ensureHexValue(stat.state.color)} />
              {stat.state.name}
            </StateLabel>
          ))}
        </Column>
        <Column grow={1}>
          <H6>Entered State</H6>
          {statistics.map(stat => (
            <ShortThinBlock key={`${stat.state.id}-entered`} />
          ))}
        </Column>
        <Column grow={1}>
          <H6>Exited State</H6>
          {statistics.map(stat => (
            <ShortThinBlock key={`${stat.state.id}-entered`} />
          ))}
        </Column>
      </HalfContainer>
      <Column grow={6}>
        <LockedAverageTimeInStateHeader>
          Average time in state
        </LockedAverageTimeInStateHeader>
        {statistics.map(stat => (
          <ShortBlock key={`${stat.state.id}-duration-progress`} />
        ))}
      </Column>
    </Main>
  );
}

export default function StateActivityStatistics({
  queueId,
  timeRange,
  locked,
  className
}: {
  queueId?: string;
  timeRange: TimeRange;
  locked?: boolean;
  className?: string;
}) {
  const { data, loading, error } = useStateActivity({
    variables: {
      queueId: queueId!,
      fromTimestamp: timeRange.fromTimestamp,
      toTimestamp: timeRange.toTimestamp
    },
    skip: !queueId,
    fetchPolicy: "cache-and-network"
  });

  return (
    <AnalyticCard
      title="Activity by state"
      loading={loading}
      errorMessage={error ? DEFAULT_ERROR_MESSAGE : undefined}
      className={className}
      showUpsell={locked}
    >
      {locked && (
        <LockedStateActivityStatisticsView statistics={data?.stateActivity || []} />
      )}
      {!locked && (
        <StateActivityStatisticsView statistics={data?.stateActivity || []} />
      )}
    </AnalyticCard>
  );
}
