import { useQuery } from "@apollo/react-hooks";
import { QueryHookOptions } from "@apollo/react-hooks/lib/types";
import gql from "graphql-tag";

import { RelayNode, ScmSyncStatus } from "../../../../../types";

const QUERY = gql`
  query GetScmSyncStatus($spaceId: ID!) {
    node(id: $spaceId) {
      ... on SpaceNode {
        id
        scmSyncStatus
      }
    }
  }
`;

interface Data extends RelayNode {
  node: {
    id: string;
    scmSyncStatus: ScmSyncStatus;
  };
}

interface Variables {
  spaceId: string;
}

export default function useScmSyncStatus(options?: QueryHookOptions<Data, Variables>) {
  return useQuery<Data, Variables>(QUERY, options);
}
