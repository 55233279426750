import { PipelineParameterInput } from "../../useFunctionEditor/queries";

export function getErrors(parameters: PipelineParameterInput[]) {
  const nameCount = new Map<string, number>();

  // Check if any names are repeated
  for (const parameter of parameters) {
    if (parameter.pipelineName) {
      const currentCount = nameCount.get(parameter.pipelineName) || 0;
      nameCount.set(parameter.pipelineName, currentCount + 1);
    }
  }

  const idToError = new Map<string, string>();

  for (const parameter of parameters) {
    const paramNameCount = nameCount.get(parameter.pipelineName) || 0;
    if (paramNameCount > 1) {
      idToError.set(parameter.id, "Duplicate name");
    } else if (parameter.pipelineName.length === 0) {
      idToError.set(parameter.id, "Can not be blank");
    }
  }

  return idToError;
}
