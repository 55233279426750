import gql from "graphql-tag";

import useAsyncMutation, {
  AsyncMutationHookOptions
} from "../../../hooks/useAsyncMutation";

const QUERY = gql`
  mutation TrackSpaceBranch($spaceId: ID!, $branch: String!) {
    trackSpaceBranch(spaceId: $spaceId, branch: $branch) {
      branch
      taskId
    }
  }
`;

export interface Data {
  trackSpaceBranch: {
    branch: string;
    taskId: string;
  };
}

interface Variables {
  spaceId: string;
  branch: string;
}

const useTrackSpaceBranch = (options?: AsyncMutationHookOptions<Data, Variables>) =>
  useAsyncMutation<Data, Variables>(QUERY, d => d.trackSpaceBranch.taskId, options);

export default useTrackSpaceBranch;
