import { useMutation } from "@apollo/react-hooks";
import { MutationHookOptions } from "@apollo/react-hooks/lib/types";
import gql from "graphql-tag";

const QUERY = gql`
  mutation DeleteScmConfig {
    deleteScmConfig {
      ok
    }
  }
`;

interface Data {
  deleteScmConfig: { ok: boolean };
}

const useDeleteScmConfig = (options?: MutationHookOptions<Data>) =>
  useMutation<Data>(QUERY, options);

export default useDeleteScmConfig;
