import { ApolloClient } from "apollo-client";
import gql from "graphql-tag";

import { ScmStatus } from "../../../../../types";

export const updateScmStatus = (
  client: ApolloClient<object>,
  spaceId: string,
  state: ScmStatus
) => {
  client.writeFragment({
    id: `SpaceNode:${spaceId}`,
    fragment: gql`
      fragment ScmSpaceNode on SpaceNode {
        id
        scmStatus
        __typename
      }
    `,
    data: {
      id: spaceId,
      scmStatus: state,
      __typename: "SpaceNode"
    }
  });
};
