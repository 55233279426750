import React from "react";

import { Skeleton, Tabs } from "antd";
import styled from "styled-components";

import { ErrorField, Hr } from "../../../../../common/StyledComponents";
import { useActivityLogById } from "../../../queries/ActivityLogById";
import ActivityFunctionInfo from "../../ActivityFunctionInfo";
import ActivityRunInfo from "../../ActivityRunInfo";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 400px;

  .ant-tabs-nav .ant-tabs-tab-active {
    color: ${props => props.theme.textColor};
    font-size: 13px;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const RowContainer = styled.div`
  display: flex;
  align-items: flex-start;

  & + & {
    margin-top: ${props => props.theme.spacersm};
  }
`;

const Label = styled.div`
  min-width: 80px;
  font-weight: 600;
  color: ${props => props.theme.textColor};
`;

const Value = styled.div`
  color: ${props => props.theme.textColorMid};
`;

const Row = ({ label, value }: { label: string; value: any }) => {
  return (
    <RowContainer>
      <Label>{label}</Label>
      <Value>{JSON.stringify(value)}</Value>
    </RowContainer>
  );
};
interface Props {
  selectedId: string | undefined;
}
export const ActivityDetail = ({ selectedId }: Props) => {
  const { data, loading } = useActivityLogById({
    variables: {
      id: selectedId
    },
    skip: !selectedId
  });

  if (loading) {
    return <Skeleton active />;
  }
  const auditLog = data?.node;
  if (!auditLog) {
    return null;
  }
  const parameters = auditLog.payload["parameters"]
    ? Object.entries(auditLog.payload["parameters"])
    : [];
  return (
    <Root>
      <Container>
        <ActivityFunctionInfo
          title={auditLog.function.title}
          dataSourceTitle={auditLog.function.dataSource.name}
          integration={auditLog.function.dataSource.integration}
        />
        <ActivityRunInfo
          status={auditLog.executionStatus}
          user={auditLog.user}
          createdAt={auditLog.createdAt}
        />
      </Container>
      <Hr />
      {auditLog.userError && <ErrorField>{auditLog.userError}</ErrorField>}
      <Tabs defaultActiveKey="dataIn">
        <Tabs.TabPane tab="Data in" key="dataIn">
          {parameters.length > 0 ? (
            parameters.map(([label, value]) => (
              <Row key={label} label={label} value={value} />
            ))
          ) : (
            <div>None</div>
          )}
        </Tabs.TabPane>
      </Tabs>
    </Root>
  );
};

export default ActivityDetail;
