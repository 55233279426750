import gql from "graphql-tag";

import useAsyncMutation, {
  AsyncMutationHookOptions
} from "../../../hooks/useAsyncMutation";

const QUERY = gql`
  mutation CreateSpaceBranch($spaceId: ID!, $versionId: ID!, $branch: String!) {
    createSpaceBranch(spaceId: $spaceId, versionId: $versionId, branch: $branch) {
      branch
      taskId
    }
  }
`;

export interface Data {
  createSpaceBranch: {
    branch: string;
    taskId: string;
  };
}

interface Variables {
  spaceId: string;
  versionId: string;
  branch: string;
}

const useCreateSpaceBranch = (options?: AsyncMutationHookOptions<Data, Variables>) =>
  useAsyncMutation<Data, Variables>(QUERY, d => d.createSpaceBranch.taskId, options);

export default useCreateSpaceBranch;
