import gql from "graphql-tag";
import { QueryHookOptions, useQuery } from "react-apollo";

import { FunctionNode } from "../../../../types";
import {
  FUNCTION_ATTRIBUTE_FRAGMENT,
  FUNCTION_PARAMETER_FRAGMENT
} from "../useFunctionEditor/queries";

export const GET_FUNCTION_BY_ID_WITH_DATA_SOURCE = gql`
  query GetFunctionById($id: ID!) {
    node(id: $id) {
      ... on FunctionNode {
        id
        name
        returnSchema
        title
        dataSource {
          __typename
          id
          name
          integration
        }
        functionParameters {
          edges {
            node {
              ...FunctionParameterFragment
            }
          }
        }
        functionAttributes {
          edges {
            node {
              ...FunctionAttributeFragment
            }
          }
        }
      }
    }
  }
  ${FUNCTION_PARAMETER_FRAGMENT}
  ${FUNCTION_ATTRIBUTE_FRAGMENT}
`;

export interface GetFunctionByIdWithDataSourceVariables {
  id: string;
}

export interface GetFunctionByIdWithDataSourceData {
  node: FunctionNode;
}

export const useGetFunctionByIdWithDataSource = (
  options?: QueryHookOptions<
    GetFunctionByIdWithDataSourceData,
    GetFunctionByIdWithDataSourceVariables
  >
) => {
  return useQuery<
    GetFunctionByIdWithDataSourceData,
    GetFunctionByIdWithDataSourceVariables
  >(GET_FUNCTION_BY_ID_WITH_DATA_SOURCE, options);
};
