import Icon from "../../../../../assets/spaceicons/JSONViewer.svg";
import {
  SpaceComponentPackage,
  SpaceComponentHostType,
  SpaceComponentTags
} from "../../../../../types";
import { DEFAULT_SIZES } from "../../../layout/constants";
import {
  ElementLayout,
  DEFAULT_ELEMENT_LAYOUT_OPTIONS,
  OverflowOption
} from "../../../layout/util";
import { commonComponentReducer } from "../../../SpaceConfig/SpaceConfigContext/useSpaceConfig/reducer";

import Config from "./Config";
import { ensureSpaceDataViewer } from "./Config/Config";
import errorSelector from "./errorSelector";
import SpaceDataViewer from "./SpaceDataViewer";
import {
  SpaceDataViewerComponent,
  DataViewerConfigState,
  SpaceDataViewerProperties
} from "./types";

const pkg: SpaceComponentPackage<
  SpaceDataViewerProperties,
  SpaceDataViewerComponent,
  DataViewerConfigState
> = {
  type: "DATA_VIEWER",
  displayName: "JSON Viewer",
  Icon,
  allowedHosts: new Set<SpaceComponentHostType>(["SPACE", "FLEX_BOX"]),
  Component: SpaceDataViewer,
  ConfigurationComponent: Config,
  ensureComponent: ensureSpaceDataViewer,
  componentConfigReducer: commonComponentReducer,
  getSchema: () => [],
  defaultElementLayout: new ElementLayout({
    width: DEFAULT_SIZES.TALL.width,
    height: DEFAULT_SIZES.TALL.height,
    snapToGrid: true
  }),
  elementLayoutOptions: {
    ...DEFAULT_ELEMENT_LAYOUT_OPTIONS,
    overflow: [OverflowOption.HIDDEN]
  },
  allowSelfBinding: false,
  allowAncestorBinding: true,
  tags: [SpaceComponentTags.Content],
  isHeadless: false,
  errorSelector,
  isContainer: false
};

export default pkg;
