import React from "react";

import { Button, Icon } from "antd";
import { UploadChangeParam, UploadFile } from "antd/lib/upload/interface";

import { FileObject } from "../../../types";

import * as styled from "./styledComponents";

const toBase64 = (file: Blob) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });

export interface Props {
  onChange?: (result: FileObject | null) => void;
  onFocus?: (evt: Event) => void;
  onBlur?: (evt: Event) => void;
  value?: FileObject | null;
  children?: React.ReactNode;
}

export default function FileReaderInput({
  onChange,
  onFocus = () => {},
  onBlur = () => {},
  value,
  children
}: Props) {
  const [shouldBlur, setShouldBlur] = React.useState(false);

  React.useEffect(() => {
    if (shouldBlur) {
      onBlur(new Event("focus"));
      setShouldBlur(false);
    }
  }, [shouldBlur, onBlur]);

  const _onChange = async (info: UploadChangeParam) => {
    // Workaround since multiple=false seems to be broken on Upload
    // https://github.com/ant-design/ant-design/issues/17397
    const list = info.fileList.length ? [info.fileList[info.fileList.length - 1]] : [];

    if (list.length) {
      onFocus(new Event("focus"));
      const newFile = list[0];
      try {
        const base64Data = await toBase64(newFile.originFileObj!);

        onChange &&
          onChange({
            name: newFile.name,
            type: newFile.type,
            data: base64Data,
            size: newFile.size
          });
      } catch (e) {
        console.error(e); // TODO
      } finally {
        setShouldBlur(true);
      }
    }
  };

  const _onRemove = () => {
    onChange && onChange(null);
  };

  const fileList: UploadFile[] =
    value && value.name && value.data
      ? [
          {
            name: value.name,
            size: value.size,
            type: value.type,
            uid: value.name
          }
        ]
      : [];

  const content = children ? (
    children
  ) : (
    <Button>
      <Icon type="upload" /> Select File
    </Button>
  );

  return (
    <styled.Upload
      beforeUpload={_ => false}
      fileList={fileList}
      multiple={false}
      onChange={_onChange}
      onRemove={_onRemove}
    >
      {content}
    </styled.Upload>
  );
}
