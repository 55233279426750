import React from "react";

import { useApolloClient } from "@apollo/react-hooks";

import { SPACE_QUERY } from "../../queries";
import { useStableSpaceContext } from "../../SpaceContext/StableSpaceContext";
import BranchOperationModal from "../BranchOperationModal";
import { BranchModalProps } from "../types";

import useCreateSpaceBranch from "./useCreateSpaceBranch";

interface Props extends BranchModalProps {
  versionId: string;
}

export default function CreateBranchModal({
  spaceId,
  versionId,
  onCancel = () => null,
  onError = () => null,
  onSuccess = () => null,
  visible
}: Props) {
  const client = useApolloClient();
  const { spaceSlug } = useStableSpaceContext();
  const [createBranch, { loading }] = useCreateSpaceBranch({
    onCompleted: data => {
      onSuccess(data.mutation!.createSpaceBranch.branch);
      // refetchQueries doesn't work with useAsyncMutation as the polling
      // on task progress uses queries not mutations. Manually querying
      // for the refetch has the same effect.
      client.query({
        query: SPACE_QUERY,
        variables: { slug: spaceSlug },
        fetchPolicy: "network-only"
      });
    },
    onError
  });

  const operationFn = React.useCallback(
    (branch: string) => {
      createBranch({ variables: { spaceId, versionId, branch } });
    },
    [createBranch, spaceId, versionId]
  );

  return (
    <BranchOperationModal
      loading={loading}
      okText="Create new branch"
      operationFn={operationFn}
      title="Create new branch"
      onCancel={onCancel}
      visible={visible}
    />
  );
}
