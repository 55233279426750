import React from "react";

import { AttributeValueBaseProps, AttributeValueProps } from "..";

export function StringValueBase({
  className = "",
  style = {},
  value
}: AttributeValueBaseProps) {
  return (
    <div style={style} className={className} title={String(value)}>
      {String(value)}
    </div>
  );
}

export default function StringValue({
  className = "",
  attribute,
  value,
  style = {}
}: AttributeValueProps) {
  // kind of a hack for Zendesk, remove when URL templating is done
  if (String(value).includes(".zendesk.com") && attribute.sourceName === "url") {
    const formattedLink = String(value).replace("/api/v2", "").replace(".json", "");
    return (
      <a style={style} target="_blank" href={formattedLink} rel="noopener noreferrer">
        {formattedLink}
      </a>
    );
  } else {
    return <StringValueBase style={style} className={className} value={value} />;
  }
}
