import * as querystring from "querystring";

import React, { useCallback, useState } from "react";

import { useMutation, useQuery } from "@apollo/react-hooks";
import { Button, Icon, Modal } from "antd";
import { SortOrder } from "antd/es/table";
import { PaginationConfig } from "antd/lib/pagination";
import { snakeCase } from "lodash";
import moment from "moment-timezone";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import _styled from "styled-components";
import { useDebounce } from "use-debounce";
import { v4 as uuid } from "uuid";

import { DARK_THEME_POPPER_PORTAL_ID } from "../../../../constants";
import {
  CloneFunctionProps,
  FunctionEditorModal
} from "../../../common/FunctionEditor/FunctionEditorModal/Modal";
import { isSupported } from "../../../common/FunctionEditor/support";
import {
  DeleteFunctionData,
  DeleteFunctionVariables,
  DELETE_FUNCTION
} from "../../../common/FunctionEditor/useFunctionEditor/queries";
import useCloneFunction from "../../../common/hooks/useCloneFunction";
import Message from "../../../common/Message";
import ThemeContainer, { Theme } from "../../../common/ThemeContainer";
import { DataSource } from "../../DataSourceTable";

import { Data, FETCH_FUNCTIONS, Vars } from "./queries";
import * as styled from "./styledComponents";

export const PAGE_SIZE = 10;
export const DEFAULT_SORT_BY = "-updated_at";

const DeleteLink = _styled.a`
  color: ${props => props.theme.errorColor};
  &:hover {
    color: ${props => props.theme.darkErrorColor};
  }
`;

export interface Props {
  dataSource: DataSource;
}

export const FunctionsPanel = ({ dataSource }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [title, setTitle] = useState("");
  const [page, setPage] = useState(1);
  const [orderBy, setOrderBy] = useState<string>(DEFAULT_SORT_BY);
  const [searchTitle] = useDebounce(title, 100);
  const [cloneFunction] = useCloneFunction({
    refetchQueries: ["FetchFunctions"],
    onCompleted: data => {
      onEditClick(data.cloneFunction.function.id);
    }
  });

  const [deleteFunction] = useMutation<DeleteFunctionData, DeleteFunctionVariables>(
    DELETE_FUNCTION,
    {
      refetchQueries: ["FetchFunctions"]
    }
  );

  const params = querystring.parse(
    location.hash.substring(location.hash.indexOf("#") + 1)
  );

  const functionId = !Array.isArray(params.functionId) ? params.functionId : undefined;

  const editorKey = functionId || (params.key as string) || uuid();

  const variables: Vars = {
    dataSourceId: dataSource.id,
    first: PAGE_SIZE,
    offset: (page - 1) * PAGE_SIZE,
    searchTitle: searchTitle || null,
    orderBy: orderBy
  };

  const { data, loading, refetch } = useQuery<Data, Vars>(FETCH_FUNCTIONS, {
    variables
  });

  const pagination: PaginationConfig = {
    pageSize: PAGE_SIZE,
    current: page,
    total: data?.allFunctions?.totalCount || 0,
    onChange: p => setPage(p)
  };

  const onClose = useCallback(() => {
    navigate(location.pathname);
  }, [location, navigate]);

  const onAddClick = () => {
    navigate(`#action=new&key=${uuid()}`);
  };

  const onEditClick = (functionId: string) => {
    navigate(`#action=edit&functionId=${functionId}`);
  };

  const onClone = ({ functionId }: CloneFunctionProps) => onEditClick(functionId);

  const onDelete = useCallback(
    (functionId: string) => {
      Modal.confirm({
        getContainer:
          location.hash && document.getElementById(DARK_THEME_POPPER_PORTAL_ID),
        title: "Are you sure?",
        content:
          "Your function will be deleted.  Spaces that use this function will need to be updated.",
        okText: "Delete",
        cancelText: "Cancel",
        mask: false,
        maskClosable: true,
        onOk: async () => {
          await deleteFunction({
            variables: { functionId }
          });
          Message.success("Deleted");
          onClose();
        }
      });
    },
    [location, deleteFunction, onClose]
  );

  const columns = [
    {
      title: "Function Name",
      dataIndex: "title",
      key: "name",
      sorter: true,
      render: (title: string, node: any) =>
        !node.isUserGenerated ? (
          title
        ) : (
          <a
            data-test={`edit-function-${node.id}`}
            href="/"
            onClick={e => {
              e.preventDefault();
              onEditClick(node.id);
            }}
          >
            {title}
          </a>
        )
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: true,
      defaultSortOrder: "descend" as SortOrder,
      render: (updatedAt: string) => moment(updatedAt).format("M/D/YY h:mma")
    },
    {
      title: "",
      dataIndex: "duplicate",
      key: "duplicate",
      sorter: false,
      render: (_: string, node: any) =>
        node.isEditorCompatible && (
          <a
            data-test={`clone-function-${node.id}`}
            href="/"
            onClick={e => {
              e.preventDefault();
              cloneFunction({
                variables: { functionId: node.id }
              });
            }}
          >
            Duplicate
          </a>
        )
    },
    {
      title: "",
      dataIndex: "delete",
      key: "delete",
      sorter: false,
      render: (_: string, node: any) =>
        node.isUserGenerated && (
          <DeleteLink
            data-test={`delete-function-${node.id}`}
            href="/"
            onClick={e => {
              e.preventDefault();
              onDelete(node.id);
            }}
          >
            Delete
          </DeleteLink>
        )
    }
  ];

  const rows =
    data?.allFunctions?.edges.map(e => ({ ...e.node, key: e.node.id })) || [];

  return (
    <>
      <h4>Manage Functions</h4>
      <styled.ControlContainer>
        {isSupported(dataSource.integration) && (
          <styled.ButtonBar>
            <Button onClick={onAddClick} type="primary">
              Add Function
            </Button>
          </styled.ButtonBar>
        )}
        <styled.SearchInput
          placeholder="Search by function name"
          prefix={<Icon type="search" />}
          value={title}
          onChange={evt => {
            setTitle(evt.target.value);
            setPage(1);
          }}
        />
      </styled.ControlContainer>
      <styled.FunctionTable
        loading={loading}
        dataSource={rows}
        columns={columns}
        pagination={pagination}
        onChange={(_pagination, _filters, sorter, _extra) => {
          if (sorter.field) {
            const operator = sorter.order === "descend" ? "-" : "";
            const orderBy = `${operator}${snakeCase(sorter.field)}`;
            setOrderBy(orderBy);
          } else {
            setOrderBy(DEFAULT_SORT_BY);
          }
        }}
      />
      {params.action && (
        // TODO: Double wrapped ThemeContainer
        <ThemeContainer theme={Theme.Default}>
          <ThemeContainer theme={Theme.Dark}>
            <styled.FullScreen>
              <FunctionEditorModal
                key={editorKey}
                cacheUpdater={() => refetch(variables)}
                cacheEvictor={() => refetch(variables)}
                onClone={onClone}
                onClose={onClose}
                dataSourceId={dataSource.id}
                functionId={functionId}
              />
            </styled.FullScreen>
          </ThemeContainer>
        </ThemeContainer>
      )}
    </>
  );
};
