import React from "react";

import { isEqual } from "lodash";
import styled from "styled-components";

import { Props } from "..";
import { useEvaluaterContext } from "../../../../common/CodeSandbox/EvaluaterContext";
import { EVALUATION_ERROR_PREFIX } from "../../../../common/CodeSandbox/useCodeSandbox";
import { ChildLayoutContext } from "../../../layout/LayoutContext";
import SpaceApi from "../../../SpaceApi";
import { useStableSpaceContext } from "../../SpaceContext";
import CollectionItemContainer from "../common/CollectionItemContainer";
import Panel from "../common/Panel";
import Row from "../common/Row";
import useRefreshComponent from "../common/useRefreshComponent";
import {
  QueryExecutionRequirement,
  Row as RowType,
  useViewWithComponentColumns,
  viewRowToStateRow
} from "../common/useView";
import ViewComponentTitle from "../common/ViewComponentTitle/ViewComponentTitle";
import ViewEnsurer from "../common/ViewEnsurer/ViewEnsurer";
import { useComponentStateContext } from "../contexts/ComponentStateContext";

import { ensureSpaceCardListComponent } from "./Config/reducer";
import * as CardList from "./styledComponents";
import { SpaceCardListComponent } from "./types";

export const RECORDS_PER_PAGE = 12;

const ListContainer = styled.div`
  height: 100%;
  padding: ${props => props.theme.spacerlg};
`;

export default function SpaceCardList(props: Props) {
  const cardListComponent = ensureSpaceCardListComponent(props.spaceComponent);
  const { spaceId } = useStableSpaceContext();
  const { input, recursivelyClearOutput } = useComponentStateContext();
  const viewResult = useViewWithComponentColumns(
    spaceId,
    cardListComponent,
    input || null,
    {
      limit: RECORDS_PER_PAGE,
      queryExecutionRequirement: !!cardListComponent?.properties?.is_filter_required
        ? QueryExecutionRequirement.ANY_FILTER
        : QueryExecutionRequirement.NONE
    }
  );
  const { loading, hasNextPage, refresh, fetchMore } = viewResult;
  const rows = viewResult.rows || [];

  const refreshAndClear = React.useCallback(() => {
    refresh();
    recursivelyClearOutput();
  }, [refresh, recursivelyClearOutput]);

  useRefreshComponent(cardListComponent, props.spaceApi, refreshAndClear);

  return (
    <Panel
      title={
        cardListComponent.properties.is_header_enabled && (
          <ViewComponentTitle component={cardListComponent} viewResult={viewResult} />
        )
      }
      hasError={props.hasConfigError}
    >
      <ViewEnsurer viewResult={viewResult}>
        <ListContainer>
          <CardList.List>
            {rows.map((row, i) => {
              return (
                <CollectionItemContainer key={row.id} index={i} collectionKey="cards">
                  <MemoCard
                    row={row}
                    cardListComponent={cardListComponent}
                    spaceApi={props.spaceApi}
                  />
                </CollectionItemContainer>
              );
            })}
          </CardList.List>
          {hasNextPage && (
            <CardList.MoreButton block loading={loading} onClick={fetchMore}>
              Load more
            </CardList.MoreButton>
          )}
        </ListContainer>
      </ViewEnsurer>
    </Panel>
  );
}

export function Card({
  row,
  cardListComponent,
  spaceApi
}: {
  row: RowType;
  cardListComponent: SpaceCardListComponent;
  spaceApi: SpaceApi;
}) {
  const { evaluate, getConsoleError } = useEvaluaterContext();
  const componentStateContext = useComponentStateContext();
  const { editMode } = useStableSpaceContext();
  const [headerText, setHeaderText] = React.useState<string>();

  React.useEffect(() => {
    componentStateContext.updateOutput(viewRowToStateRow(row));
  }, [componentStateContext, componentStateContext.updateOutput, row]);

  React.useEffect(() => {
    const evalExpression = async () => {
      try {
        const text = await evaluate(
          cardListComponent.properties.card_name.template,
          componentStateContext.input
        );
        setHeaderText(text as string);
      } catch (e) {
        if (typeof e === "string" && e.indexOf(EVALUATION_ERROR_PREFIX) > -1) {
          setHeaderText("");
        }
        if (editMode) {
          console.warn(getConsoleError(e));
        }
      }
    };
    evalExpression();
  }, [
    cardListComponent.properties.card_name.template,
    componentStateContext.input,
    evaluate,
    editMode,
    getConsoleError
  ]);

  return (
    // TODO: Temporarily remove onClick handler because it wasn't working
    //       for function views anyway.  Issue filed:
    //        https://www.pivotaltracker.com/story/show/176729452
    <CardList.Card key={row.id}>
      <ChildLayoutContext>
        <CardList.Header>{headerText}</CardList.Header>
        <Row
          row={row}
          columnComponent={CardList.Attribute}
          labelComponent={CardList.AttributeName}
          componentLabelComponent={CardList.AttributeNameWithMargin}
          spaceApi={spaceApi}
        />
      </ChildLayoutContext>
    </CardList.Card>
  );
}

const MemoCard = React.memo(
  Card,
  (prevProps, nextProps) =>
    isEqual(prevProps.row, nextProps.row) &&
    prevProps.cardListComponent === nextProps.cardListComponent
);
