import { assertNever } from "../../util/assertNever";

import { ScmConfigNode } from "./queries/ScmConfigFragment";

import { Provider } from "./index";

export enum ActionType {
  LOAD_SCM_CONFIG_NODE = "LOAD_SCM_CONFIG_NODE",
  RESET = "RESET",
  SET_FIELD = "SET_FIELD"
}

export interface State {
  id: string | undefined;
  provider: Provider | undefined;
  githubAppAppId: number | undefined;
  githubAppBranch: string | undefined;
  githubAppEnterpriseUrl: string | undefined;
  githubAppEnterpriseApiUrl: string | undefined;
  githubAppInstallationId: number | undefined;
  githubAppOwner: string | undefined;
  githubAppPrivateKey: string | undefined;
  githubAppRepo: string | undefined;
}

export type Field =
  | "provider"
  | "githubAppAppId"
  | "githubAppBranch"
  | "githubAppEnterpriseUrl"
  | "githubAppEnterpriseApiUrl"
  | "githubAppInstallationId"
  | "githubAppOwner"
  | "githubAppPrivateKey"
  | "githubAppRepo";

interface BaseAction<T extends ActionType, P = unknown> {
  type: T;
  payload: P;
}

interface LoadScmConfigNodeAction extends BaseAction<ActionType.LOAD_SCM_CONFIG_NODE> {
  payload: ScmConfigNode;
}

interface ResetAction extends BaseAction<ActionType.RESET> {
  payload: void;
}

interface SetFieldAction extends BaseAction<ActionType.SET_FIELD> {
  payload: {
    name: Field;
    value: unknown;
  };
}

export type Action = LoadScmConfigNodeAction | SetFieldAction | ResetAction;

export function getDefaultState(): State {
  return {
    id: undefined,
    provider: undefined,
    githubAppAppId: undefined,
    githubAppBranch: undefined,
    githubAppEnterpriseUrl: undefined,
    githubAppEnterpriseApiUrl: undefined,
    githubAppInstallationId: undefined,
    githubAppOwner: undefined,
    githubAppPrivateKey: undefined,
    githubAppRepo: undefined
  };
}

export default function reducer(state: State, action: Action): State {
  switch (action.type) {
    case ActionType.LOAD_SCM_CONFIG_NODE:
      return { ...action.payload, githubAppPrivateKey: undefined };
    case ActionType.RESET:
      return getDefaultState();
    case ActionType.SET_FIELD:
      return {
        ...state,
        [action.payload.name]: action.payload.value
      };
    default:
      return assertNever(action);
  }
}
