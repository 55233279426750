import React from "react";

import styled from "styled-components";

import { zIndex } from "../../../../../cssConstants";

export const Mask = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  pointer-events: all;
  width: 100vw;
  height: 100vh;
  z-index: ${zIndex.mask};
`;

export const HeaderPanelMenu = styled.div`
  display: flex;
  flex-direction: column;
  width: 142px;

  position: absolute;
  top: calc(${props => props.theme.headerHeight} + 10px);
  right: 2px;

  border-radius: 8px;
  background-color: ${props => props.theme.backgroundColor};
  box-shadow: ${props => props.theme.popperBoxShadow};
`;
HeaderPanelMenu.displayName = "HeaderPanelMenu";

export const HeaderPanelMenuButton = styled.a<{ disabled?: boolean }>`
  display: block;
  text-align: left;
  color: ${props => props.theme.inputColor};
  padding: ${props => props.theme.spacersm};
  cursor: pointer;

  &:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  &:last-child {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: ${props => props.theme.selectHoverBackgroundColor};
    color: ${props => props.theme.textColor};
  }

  &[disabled],
  &[disabled]:hover {
    color: ${props => props.theme.disabledTextColor};
    background: none;
    cursor: not-allowed;
    pointer-events: auto;
  }
`;

interface Props {
  children: React.ReactNode;
  maskClosable: boolean;
  onCancel: (evt: React.MouseEvent) => void;
}

export default function HeaderPanel(props: Props) {
  const handleCancel = (evt: React.MouseEvent) => {
    evt.stopPropagation();
    if (props.maskClosable) {
      props.onCancel(evt);
    }
  };

  const handleClick = (evt: React.MouseEvent<HTMLElement, MouseEvent>) => {
    evt.stopPropagation();
  };

  return (
    <Mask data-test="popperMask" onClick={handleCancel}>
      <div onClick={handleClick}>{props.children}</div>
    </Mask>
  );
}
