import styled from "styled-components";

import { ErrorField as BaseErrorField } from "../../common/StyledComponents";

export const FormContainer = styled.div`
  button[type="submit"] {
    margin-bottom: ${props => props.theme.spacersm};
  }
`;

export const FormFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${props => props.theme.spacerlg};
`;

export const ForgotPasswordDiv = styled.div`
  height: ${props => props.theme.spacerxl};
  text-align: right;
`;

export const AlertContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  margin: 0 auto;

  .ant-alert {
    margin: ${props => props.theme.spacerxl} auto;
    width: 100%;
    min-width: 300px;
    max-width: 600px;
  }
`;

export const ErrorField = styled(BaseErrorField)`
  margin-top: 0;
`;
