import React from "react";

import { client } from "../../../../../../graphql";
import { useEnvironmentContext } from "../../../../../common/contexts/EnvironmentContext";
import {
  ClientError,
  CustomError,
  ExecutorClientError
} from "../../../../../common/executeGatewayFunction/errors";
import { tryError } from "../../../../../util";
import { createPath, parsePath } from "../../../../../util/binding";
import {
  FunctionExecutor,
  ExecutionParamType,
  ExecutionParams,
  FunctionExecutionStatus,
  FunctionResult as _FunctionResult,
  FunctionExecutionResult as _FunctionExecutionResult,
  SpaceFunction
} from "../../../../FunctionExecutor/FunctionExecutor";
import { useSpaceConsoleContext } from "../../../SpaceConsoleContext";
import { useComponentContext } from "../../contexts/ComponentContext";
import {
  InputParameter,
  InputParameterBinding,
  ParameterType,
  InputParameterComponent
} from "../useFuncParams/types";
import { PIPELINE_FULFILLED_VALUE } from "../useFuncParams/useFuncParams";

export type FunctionResult = _FunctionResult;
export type FunctionExecutionResult = _FunctionExecutionResult;

export interface Result {
  executeFunction: (
    parameters: Record<string, any>
  ) => Promise<FunctionExecutionResult | Error>;
  status: FunctionExecutionStatus;
}

export default function useSpaceFunction(
  fn: SpaceFunction | undefined,
  inputParameters: InputParameter[],
  spaceId: string | undefined,
  onCompleted: (data: FunctionResult, metadata: unknown) => void = () => {},
  onError: (err: Error) => void = _data => {},
  onProgress: (data: FunctionExecutionResult) => void = _data => {}
): Result {
  const [status, setStatus] = React.useState(FunctionExecutionStatus.PENDING);
  const { addClientError } = useSpaceConsoleContext();
  const { component } = useComponentContext();
  const [executor] = React.useState(() => new FunctionExecutor(client, spaceId));
  const { getCurrentEnvironment } = useEnvironmentContext();

  const environmentId = getCurrentEnvironment().id;

  function handleExecutionProgress(result: FunctionExecutionResult) {
    setStatus(result.executionState.status);
    switch (result.executionState.status) {
      case FunctionExecutionStatus.IN_PROGRESS:
        onProgress(result);
        break;
      case FunctionExecutionStatus.COMPLETED:
        onCompleted(result.value, result.metadata);
        break;
      default:
      // no-op
    }
  }

  function createExecutionParameter(name: string, value: any) {
    const type =
      value === PIPELINE_FULFILLED_VALUE
        ? ExecutionParamType.BINDING
        : ExecutionParamType.VALUE;
    let val = value;
    if (type === ExecutionParamType.BINDING) {
      const ip = inputParameters.find(ip => ip.name === name) as
        | InputParameterBinding
        | InputParameterComponent;
      const path = [];
      let binding = "";
      if (ip.type === ParameterType.BINDING) {
        binding = (ip as InputParameterBinding).binding;
      } else if (ip.type === ParameterType.COMPONENT) {
        const sc = component.componentTreeNodes.find(
          sc => sc.slug === (ip as InputParameterComponent).component_slug
        );
        binding = sc?.properties.default_value_binding?.binding || "";
      }
      const parts = parsePath(binding);
      for (let i = parts.length - 1; i >= 0; i--) {
        path.push(parts[i]);
        if (parts[i] === "lastExecutionResult") break;
      }
      val = createPath(path.reverse());
    }
    return { type, value: val };
  }

  async function executeFunction(parameters: Record<string, any>) {
    if (fn === undefined) throw new Error("Expected function to be defined.");
    const executionParams = Object.entries(parameters).reduce<ExecutionParams>(
      (acc, [key, value]) => {
        acc[key] = createExecutionParameter(key, value);
        return acc;
      },
      {}
    );
    let result;
    try {
      result = await executor.execute(
        fn,
        executionParams,
        handleExecutionProgress,
        environmentId
      );
    } catch (err) {
      setStatus(FunctionExecutionStatus.FAILED);
      const e = tryError(err);
      onError(e);

      const error = e as CustomError;
      if (error instanceof ClientError) {
        addClientError(error.details);
      } else if (error instanceof ExecutorClientError) {
        addClientError({
          message: error.message,
          code: error.code,
          errorInfo: { domain: "executor", reason: "UNKNOWN", metadata: {} },
          __typename: "ClientErrorResult"
        });
      } else {
        console.error(error);
      }
      result = error;
    }
    return result;
  }

  return { executeFunction, status };
}
