import React from "react";

import classNames from "classnames";
import styled from "styled-components";

import { assertNever } from "../../../util/assertNever";
import { DOMRectsEqual } from "../util";

/*
  LayoutContext

  LayoutContext provides the current canvas DOMRect, selection DOMRect
  and scroll position.
  
*/

export const LayoutContext = React.createContext({
  canvasDOMRect: new DOMRect(),
  canvasScrollTop: 0
});

const LayoutContextDispatcher = React.createContext(
  (_action: LayoutContainerAction) => {}
);

interface UpdateCanvasDOMRect {
  type: "UPDATE_CANVAS_DOM_RECT";
  payload: {
    rect: DOMRect;
  };
}

interface UpdateCanvasScrollTop {
  type: "UPDATE_CANVAS_SCROLL_TOP";
  payload: { canvasScrollTop: number };
}

type LayoutContainerAction = UpdateCanvasDOMRect | UpdateCanvasScrollTop;

const layoutContainerInitialState = {
  canvasDOMRect: new DOMRect(),
  canvasScrollTop: 0
};

function layoutContainerReducer(
  state: typeof layoutContainerInitialState,
  action: LayoutContainerAction
) {
  switch (action.type) {
    case "UPDATE_CANVAS_DOM_RECT": {
      const { rect } = action.payload;
      if (DOMRectsEqual(rect, state.canvasDOMRect)) return state;
      return {
        ...state,
        canvasDOMRect: rect
      };
    }

    case "UPDATE_CANVAS_SCROLL_TOP": {
      const { canvasScrollTop } = action.payload;
      if (canvasScrollTop === state.canvasScrollTop) return state;
      return {
        ...state,
        canvasScrollTop
      };
    }

    default:
      return assertNever(action);
  }
}

export default function LayoutContainer({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = React.useReducer(
    layoutContainerReducer,
    layoutContainerInitialState
  );

  const scrollContextValue = React.useMemo(
    () => ({ canvasScrollTop: state.canvasScrollTop }),
    [state.canvasScrollTop]
  );

  return (
    <LayoutContext.Provider value={state}>
      <LayoutContextDispatcher.Provider value={dispatch}>
        <ScrollContext.Provider value={scrollContextValue}>
          {children}
        </ScrollContext.Provider>
      </LayoutContextDispatcher.Provider>
    </LayoutContext.Provider>
  );
}

export const useLayoutContext = () => React.useContext(LayoutContext);
export const useLayoutContextDispatcher = () =>
  React.useContext(LayoutContextDispatcher);

const ScrollContext = React.createContext({ canvasScrollTop: 0 });

export const useScrollContext = () => React.useContext(ScrollContext);

const ChildLayoutContextRoot = styled.div`
  height: 100%;
`;
export function ChildLayoutContext({
  className = "",
  children
}: {
  className?: string;
  children: React.ReactNode;
}) {
  return (
    <ChildLayoutContextRoot
      className={classNames("layoutContext", { [className]: !!className })}
    >
      {children}
    </ChildLayoutContextRoot>
  );
}
