import { flow, partial } from "lodash";

import { SpaceComponentObject } from "../../../../../../../types";
import commonComponentReducer from "../../../../../SpaceConfig/SpaceConfigContext/useSpaceConfig/reducer/componentReducer";
import {
  SpaceConfigAction,
  BaseComponentConfigState,
  ComponentConfigState
} from "../../../../../types";
import { findSpaceComponentPackage } from "../../../../SpaceContext/StableSpaceContext";
import { hasInputComponentProperties } from "../../../util/util";
import filterListManagerReducer, {
  INITIAL_STATE as FILTER_LIST_MANAGER_INITIAL_STATE,
  FilterListManagerState,
  getInitialFilterState
} from "../../FilterListManager/reducer/reducer";
import { getInitialHardcodedOptions } from "../../HardcodedOptionFields/utils";
import inputReducer from "../../inputReducer";
import parameterConfigReducer, {
  INITIAL_STATE as PARAMETER_CONFIG_INITIAL_STATE,
  getInitialParameterState,
  ParameterConfigState
} from "../../ParametersManager/reducer/reducer";
import viewDataConfigReducer, {
  INITIAL_STATE as VIEW_DATA_CONFIG_INITIAL_STATE,
  ViewConfigState
} from "../../useViewConfig/reducer";
import { SelectComponent, SelectComponentProperties } from "../types";

export interface SelectConfigState
  extends BaseComponentConfigState<SelectComponent>,
    ViewConfigState,
    FilterListManagerState,
    ParameterConfigState {
  type: "DROPDOWN" | "TAG_SELECTOR";
}

export const INITIAL_STATE = {
  draftComponent: {} as SelectComponent,
  ...VIEW_DATA_CONFIG_INITIAL_STATE,
  ...FILTER_LIST_MANAGER_INITIAL_STATE,
  ...PARAMETER_CONFIG_INITIAL_STATE
};

function isSelectConfigState(state: ComponentConfigState): state is SelectConfigState {
  return ["DROPDOWN", "TAG_SELECTOR"].includes(state.type);
}

export function ensureSelectConfigState(
  state: ComponentConfigState
): SelectConfigState {
  if (isSelectConfigState(state)) return state;

  throw new Error("Expected select config state.");
}

export const getCommonDefaultProperties = (
  properties: Partial<SelectComponentProperties>
): SelectComponentProperties => {
  return {
    ...properties,
    display_template: properties.display_template || "",
    value_template: properties.value_template || "",
    hardcoded_options: getInitialHardcodedOptions(properties),
    columns: properties.columns || [],
    filters: properties.filters || [],
    is_filter_required: properties.is_filter_required ?? false,
    order: properties.order || [],
    input_parameters: properties.input_parameters || []
  };
};

export function ensureSelectComponent(
  component: SpaceComponentObject
): SelectComponent {
  if (
    Array.isArray(component.properties.columns) &&
    Array.isArray(component.properties.filters) &&
    Array.isArray(component.properties.order) &&
    Array.isArray(component.properties.input_parameters) &&
    hasInputComponentProperties(component)
  ) {
    return component;
  }

  return {
    ...component,
    properties: getCommonDefaultProperties(component.properties)
  };
}

export function getMakeInitialState(
  type: "DROPDOWN" | "TAG_SELECTOR"
): (draftComponent: SpaceComponentObject) => SelectConfigState {
  return function makeInitialState(
    draftComponent: SpaceComponentObject
  ): SelectConfigState {
    return {
      ...INITIAL_STATE,
      type,
      draftComponent: ensureSelectComponent(draftComponent),
      ...getInitialFilterState(draftComponent),
      ...getInitialParameterState(draftComponent)
    };
  };
}

function reducer(
  state: SelectConfigState,
  action: SpaceConfigAction
): SelectConfigState {
  switch (action.type) {
    case "LOAD_FUNCTION": {
      const { name } = action.payload;
      if (state.draftComponent.name) return state;
      const pkg = findSpaceComponentPackage(state.draftComponent.type);
      return {
        ...state,
        draftComponent: {
          ...state.draftComponent,
          name: `${name} ${pkg.displayName.toLowerCase()}`,
          properties: {
            ...state.draftComponent.properties,
            placeholder: `Select ${name}`
          }
        }
      };
    }
    case "RESET_SOURCE": {
      return {
        ...state,
        draftComponent: {
          ...state.draftComponent,
          properties: {
            ...state.draftComponent.properties,
            display_template: "",
            value_template: ""
          }
        }
      };
    }
    default:
      return state;
  }
}

export default (state: SelectConfigState, action: SpaceConfigAction) =>
  flow([
    commonComponentReducer,
    partial(viewDataConfigReducer, partial.placeholder, action),
    partial(parameterConfigReducer, partial.placeholder, action),
    partial(inputReducer, partial.placeholder, action),
    partial(filterListManagerReducer, partial.placeholder, action),
    partial(reducer, partial.placeholder, action)
  ])(state, action);
