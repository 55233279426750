import Icon from "../../../../../assets/spaceicons/progress.svg";
import { AttributeTypes } from "../../../../../constants";
import {
  BindingShape,
  SpaceComponentHostType,
  SpaceComponentPackage,
  SpaceComponentTags
} from "../../../../../types";
import { DEFAULT_SIZES } from "../../../layout/constants";
import {
  DEFAULT_ELEMENT_LAYOUT_OPTIONS,
  ElementLayout,
  LayoutUnit
} from "../../../layout/util";
import { commonComponentReducer } from "../../../SpaceConfig/SpaceConfigContext/useSpaceConfig/reducer";

import Config from "./Config";
import SpaceProgress from "./SpaceProgress";
import {
  SpaceProgressComponent,
  SpaceProgressProperties,
  ProgressComponentConfigState
} from "./types";

const pkg: SpaceComponentPackage<
  SpaceProgressProperties,
  SpaceProgressComponent,
  ProgressComponentConfigState
> = {
  type: "PROGRESS",
  displayName: "Progress",
  Icon,
  allowedHosts: new Set<SpaceComponentHostType>([
    "CARD_LIST",
    "DETAIL",
    "TABLE",
    "FLEX_BOX"
  ]),
  Component: SpaceProgress,
  ConfigurationComponent: Config,
  ensureComponent: c => c,
  componentConfigReducer: commonComponentReducer,
  elementLayoutOptions: {
    ...DEFAULT_ELEMENT_LAYOUT_OPTIONS,
    width: [LayoutUnit.PERCENTAGE, LayoutUnit.PIXEL],
    height: [LayoutUnit.PERCENTAGE, LayoutUnit.PIXEL]
  },
  defaultElementLayout: new ElementLayout({
    snapToGrid: true,
    width: DEFAULT_SIZES.STANDARD.width,
    height: DEFAULT_SIZES.TEXT.height
  }),
  getSchema: () => [
    { name: "value", shape: BindingShape.SCALAR, type: AttributeTypes.FLOAT }
  ],
  errorSelector: () => [],
  allowSelfBinding: false,
  allowAncestorBinding: true,
  isContainer: false,
  isHeadless: false,
  tags: [SpaceComponentTags.Content]
};

export default pkg;
