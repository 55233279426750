import React from "react";

import { FunctionParameterInput } from "../../../../../../types";
import AttributeInput from "../../../../AttributeInput";
import {
  ParameterDataValue,
  ParameterValues,
  ReservedListParameter,
  ReservedListParameterDefault
} from "../../../index";

import * as styled from "./styledComponents";

export interface Props {
  parameters: FunctionParameterInput[];
  values: ParameterValues;
  onParameterValueChange?: (name: string, value: ParameterDataValue) => void;
}

const sortByRequiredFirst = (p1: FunctionParameterInput, p2: FunctionParameterInput) =>
  Number(p2.required) - Number(p1.required);

export const ParametersForm = ({
  parameters,
  values,
  onParameterValueChange = () => {}
}: Props) => (
  <styled.Container>
    {parameters.sort(sortByRequiredFirst).map(p => {
      const defaultValue =
        ReservedListParameterDefault[p.name as ReservedListParameter];
      return (
        <styled.Parameter key={p.key ? p.key : p.name}>
          <styled.Label data-test="paramLabel">
            {p.name}
            {p.required && " (required)"}
          </styled.Label>
          <AttributeInput
            data-test="paramInput"
            sourceNullable={true}
            placeholder={String(defaultValue)}
            required={p.required}
            sourceType={p.type}
            sourceName={p.name}
            value={values[p.name]}
            onChange={value => onParameterValueChange(p.name, value)}
          />
        </styled.Parameter>
      );
    })}
  </styled.Container>
);
