import React from "react";

import { components, ControlProps } from "react-select";

import * as contextSelectorStyles from "../../../../common/contextSelectorStyles";
import useScmConfig from "../../../../common/hooks/useScmConfig";
import Message from "../../../../common/Message";
import ThemeContainer, { Theme } from "../../../../common/ThemeContainer";
import { assertNever } from "../../../../util/assertNever";
import { SyncModal } from "../../../SpaceRoot/modals";
import CommitModal from "../../../SpaceRoot/modals/CommitModal";
import { useSpaceContext } from "../../../SpaceRoot/SpaceContext";
import { useBranchContext } from "../../../SpaceRoot/SpaceContext/BranchContext";
import { useSpaceConfigContext } from "../../SpaceConfigContext";

import * as styled from "./styledComponents";

type Actions = "commit-and-push" | "open-pull-request" | "sync-newer-version";

interface Option {
  label: string;
  value: Actions;
  isDisabled?: boolean;
}

const styles = {
  ...contextSelectorStyles.styles,
  menu: (provided: Record<string, any>) => ({
    ...provided,
    ...contextSelectorStyles.styles.menu,
    minWidth: "188px"
  })
};

const Control = ({ children, ...props }: ControlProps<Option, false>) => {
  return (
    <components.Control {...props}>
      <styled.GitHubLogoWhite />
      {children}
    </components.Control>
  );
};

export default function ScmActions() {
  const [showCommitModal, setShowCommitModal] = React.useState(false);
  const [showSyncModal, setShowSyncModal] = React.useState(false);
  const config = useScmConfig();
  const { branch } = useBranchContext();
  const { space } = useSpaceContext();
  const {
    dispatch,
    state: { dirty }
  } = useSpaceConfigContext();

  const options: Option[] = [
    {
      label: "Open pull request",
      value: "open-pull-request"
    },
    { label: "Commit and push", value: "commit-and-push", isDisabled: dirty },
    { label: "Sync newer version", value: "sync-newer-version" }
  ];

  const onChange = React.useCallback(
    (e: Option) => {
      switch (e.value) {
        case "commit-and-push":
          setShowCommitModal(true);
          break;
        case "open-pull-request":
          window.open(`${config?.repositoryUrl}/compare/${branch}?expand=1`);
          break;
        case "sync-newer-version":
          setShowSyncModal(true);
          break;
        default:
          assertNever(e.value);
      }
    },
    [config?.repositoryUrl, branch]
  );

  return (
    <ThemeContainer theme={Theme.Default}>
      <styled.Select
        menuPortalTarget={document.getElementById("select-portal")}
        isSearchable={false}
        options={options}
        value={{ label: "Actions", value: null }}
        classNamePrefix="react-select"
        components={{ Control }}
        styles={styles}
        theme={contextSelectorStyles.theme}
        onChange={onChange}
      />
      <CommitModal
        onCancel={() => setShowCommitModal(false)}
        onSuccess={version => {
          setShowCommitModal(false);
          Message.success("Commit was successful");
          dispatch({
            type: "UPDATE_SCM_VERSION",
            payload: { spaceSlug: version.space.slug, scmVersion: version.scmVersion }
          });
        }}
        visible={showCommitModal}
      />
      {showSyncModal && (
        <SyncModal
          spaceId={space.id}
          branch={branch!}
          onCancel={() => setShowSyncModal(false)}
          onSuccess={() => {
            setShowSyncModal(false);
            Message.success("Sync successful! This page will automatically refresh.");
            setTimeout(() => window.location.reload(), 500);
          }}
        />
      )}
    </ThemeContainer>
  );
}
