import {
  DatePicker as AntDatePicker,
  Select as AntSelect,
  TimePicker as AntTimePicker
} from "antd";
import styled from "styled-components";

import { Icon as BaseIcon } from "../../common/Icons/MaterialIcons";
import { B3, C2, H4, H5 } from "../../common/StyledComponents";

export const Title = styled(H4)`
  margin-bottom: ${props => props.theme.spacerlg};
`;

export const SectionTitle = styled(H5)`
  margin-bottom: ${props => props.theme.spacersm};
`;

export const SectionDescription = styled(B3)`
  display: block;
  margin-bottom: ${props => props.theme.spacermd};
  color: ${props => props.theme.surfaceSecondary};
`;

export const Section = styled.div`
  padding-top: ${props => props.theme.spacersm};
  padding-bottom: ${props => props.theme.spacersm};
`;

export const Label = styled(C2)`
  display: block;
  color: ${props => props.theme.surfaceSecondary};
  padding-bottom: ${props => props.theme.spacersm};
`;

export const Fieldset = styled.fieldset`
  & + & {
    margin-top: 26px;
  }
`;

export const IconColor = styled(Fieldset)`
  display: flex;
  gap: 8px;
`;

export const Icon = styled(BaseIcon)<{ color: string }>`
  font-size: 30px;
  color: ${props => props.color};
`;

export const IconSelectOptionsWrapper = styled.div`
  .ant-select-dropdown-menu-item {
    display: inline-block;
  }
`;

export const Select = styled(AntSelect)`
  display: block;
  min-width: 200px;
`;

export const SelectContainer = styled.div`
  flex-grow: 1;
`;

export const TimePicker = styled(AntTimePicker)`
  width: 100%;
`;

export const DatePicker = styled(AntDatePicker)`
  width: 100%;
`;

export const Info = styled.p`
  margin-top: ${props => props.theme.spacersm};
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${props => props.theme.spacermd};
`;

export const FullScreen = styled.div`
  position: fixed;
  inset: 0;
  z-index: 1000;
  background-color: #2a2b2e;
  color: #d9d9d9;
`;
