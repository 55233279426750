import React from "react";

import { MutationFunctionOptions, MutationResult } from "@apollo/react-common";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { MutationHookOptions, QueryLazyOptions } from "@apollo/react-hooks/lib/types";
import { ApolloError } from "apollo-client";
import { DocumentNode } from "apollo-link";
import gql from "graphql-tag";
import { ExecutionResult } from "react-apollo";

type TaskData = {
  node?: {
    id: string;
    started: string;
    stopped: string;
    success: boolean;
  };
};

type PollVariables = { id: string };

const TASK_QUERY = gql`
  query GetTaskByNode($id: ID!) {
    node(id: $id) {
      ... on AsyncTaskNode {
        id
        started
        stopped
        success
      }
    }
  }
`;

interface AsyncMutationData<T> {
  mutation?: T;
  task?: TaskData;
}

export interface AsyncMutationHookOptions<TData, TVariables>
  extends MutationHookOptions<AsyncMutationData<TData>, TVariables> {
  pollInterval?: number;
  onError?: (e: Error | ApolloError) => void;
}

export type AsyncMutationTuple<TData, TVariables> = [
  (
    options?: MutationFunctionOptions<TData, TVariables>
  ) => Promise<ExecutionResult<TData>>,
  MutationResult<AsyncMutationData<TData>>
];

export type TaskIdFunc<TData> = (data: TData) => string;

const DEFAULT_POLL_INTERVAL = 2000;

export default function useAsyncMutation<TData, TVariables>(
  query: DocumentNode,
  taskIdFunc: TaskIdFunc<TData>,
  options?: AsyncMutationHookOptions<TData, TVariables>
): AsyncMutationTuple<TData, TVariables> {
  const {
    onError = () => {},
    onCompleted = () => {},
    pollInterval = DEFAULT_POLL_INTERVAL
  } = options || {};

  const [mutationData, setMutationData] = React.useState<TData>();
  const [taskId, setTaskId] = React.useState<string>();
  const interval = React.useRef<number | undefined>();

  React.useEffect(() => () => window.clearInterval(interval.current), []);

  // Note: We are intentionally passing query variables on every tick
  // as a workaround for bugs in useLazyQuery.  Ideally we don't have
  // to do this and we can set the variables in useLazyQuery.
  const tick = (options: QueryLazyOptions<PollVariables>) => {
    interval.current = window.setTimeout(() => {
      getTask(options);
    }, pollInterval);
  };

  const startPolling = (id: string) => {
    setTaskId(id);
    tick({ variables: { id } });
  };

  const stopPolling = () => {
    window.clearInterval(interval.current);
    setTaskId(undefined);
  };

  const [func, result] = useMutation<TData, TVariables>(query, {
    variables: options?.variables,
    onCompleted: data => {
      setMutationData(data);
      taskIdFunc(data);
      startPolling(taskIdFunc(data));
    },
    onError
  });

  const [getTask] = useLazyQuery<TaskData, PollVariables>(TASK_QUERY, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (!data.node) {
        tick({ variables: { id: taskId! } });
      } else {
        stopPolling();
        if (data.node.success) {
          onCompleted({ mutation: mutationData!, task: data });
        } else {
          onError(new Error("Task failed"));
        }
      }
    },
    onError: e => {
      stopPolling();
      onError(e);
    }
  });

  return [
    func,
    {
      ...result,
      data: { task: result.data, mutation: mutationData },
      loading: !!taskId
    }
  ];
}
