import { useReducer, useEffect, useCallback, useMemo } from "react";

import { cloneDeep } from "lodash";

import {
  SpaceComponentType,
  SpaceComponentObject,
  StatusCode,
  ConfigValidationError,
  StableSpace
} from "../../../../../types";
import Message from "../../../../common/Message";
import { EMPTY_SPACE } from "../../../constants";
import useSpacesManager from "../../../hooks/useSpacesManager/useSpacesManager";
import { useStableSpaceContext, getStableSpace } from "../../../SpaceRoot/SpaceContext";
import { OPAQUE_EDIT_MODE_KEY } from "../../../SpaceRoot/SpaceContext/StableSpaceContext";
import useClientProvidedComponents from "../../../SpaceRoot/SpaceContext/useClientProvidedComponents";
import useSpace from "../../../SpaceRoot/SpaceContext/useSpace";
import { ComponentConfigState } from "../../../types";
import TreeTraverser from "../../../util/TreeTraverser";

import reducer, {
  INITIAL_STATE,
  SpaceConfigState,
  SpaceConfigDispatch,
  ensureSlug,
  getSpaceErrors,
  selectAllSlugs,
  getFirstSpaceError
} from "./reducer";
import { BaseSpaceConfig, selectComponentToSpaceMap } from "./reducer/reducer";
import { SpaceError } from "./reducer/util/getSpaceErrors";
import useConfigErrors from "./useConfigErrors/useConfigErrors";
import useConfigMutation from "./useConfigMutation";
import {
  MutationOptions,
  UseConfigMutationData
} from "./useConfigMutation/useConfigMutation";
import useDestroyConfigMutation from "./useDestroyConfigMutation";
import { DestroyConfigMutationData } from "./useDestroyConfigMutation/useDestroyConfigMutation";
import { selectSpaceFromConfig } from "./util";

export enum MutationType {
  SAVE = "save",
  DESTROY = "destroy"
}

export interface Result {
  [OPAQUE_EDIT_MODE_KEY]: boolean;
  dispatch: SpaceConfigDispatch;
  state: SpaceConfigState;
  loading: boolean;
  space: StableSpace;
  mutationLoading: MutationType | boolean;
  shouldDisplayError: (p: string) => boolean;
  predictSlug: (componentType: SpaceComponentType) => string;
  destroy: () => void;
  save: (environmentsToPublish?: string[]) => void;
  getComponentConfig: (slug: string) => ComponentConfigState;
  getComponentSpaceConfig: (slug: string) => BaseSpaceConfig;
  getChildren: (slug: string) => SpaceComponentObject[];
  componentTree: SpaceComponentObject[];
  queryStatus: StatusCode;
  componentsWithErrors: Set<string>;
  configErrors: Record<string, ConfigValidationError[]>;
}

export default function useSpaceConfig(
  onDestroy: (result: DestroyConfigMutationData) => void,
  onNewSpaceCreated: (result: UseConfigMutationData) => void,
  spaceSlug?: string
): Result {
  const { findSpaceComponentPackage, getSpaceComponentPackages } =
    useStableSpaceContext();
  const packageMap = new Map(getSpaceComponentPackages().map(p => [p.type, p]));
  const [state, dispatch] = useReducer(
    reducer,
    cloneDeep({ ...INITIAL_STATE, packages: packageMap })
  );
  const { loading, space, componentTree, queryStatus } = useSpace(spaceSlug, {
    editMode: true
  });
  const { favorite } = useSpacesManager();

  const options: MutationOptions = space.id
    ? { id: space.id }
    : {
        onNewSpaceCreated: result => {
          onNewSpaceCreated(result);
          favorite(result.spaceUpdate.space.id, true);
        }
      };

  const { save: saveMutation, loading: saveLoading } = useConfigMutation(
    state,
    dispatch,
    options
  );

  const { destroy: destroyMutation, loading: destroyLoading } =
    useDestroyConfigMutation(state, dispatch, {
      id: space.id,
      onCompleted: onDestroy
    });

  const clientProvidedComponents = useClientProvidedComponents();

  useEffect(() => {
    if (loading) return;
    dispatch(
      !!space.id
        ? {
            type: "INIT_EXISTING_SPACE",
            payload: {
              space
            }
          }
        : { type: "INIT_NEW_SPACE", payload: {} }
    );
  }, [space, componentTree, loading]);

  const componentTreeNodes = useMemo(() => {
    return selectSpaceFromConfig(state);
  }, [state]);

  const configErrors = useConfigErrors(state.spaces, componentTreeNodes);

  const slugsWithErrors = useMemo(() => {
    return new Set([
      ...Object.entries(configErrors)
        .filter(([_, v]) => v.length)
        .map(([k]) => k),
      ...Object.keys(state.apiErrors)
    ]);
  }, [configErrors, state.apiErrors]);

  const mutationLoading =
    (saveLoading && MutationType.SAVE) || (destroyLoading && MutationType.DESTROY);

  const save = useCallback(
    (environmentsToPublish: string[] | undefined = []) => {
      if (mutationLoading) return;
      if (state.spaces.size === 0) {
        Message.error("Could not save. Please try again in a moment.");
        return;
      }

      dispatch({
        type: "SUBMIT"
      });
      const errorsPreventingSubmit = Array.from(
        state.spaces.values()
      ).reduce<SpaceError>((agg, curr) => {
        return {
          ...agg,
          ...getSpaceErrors(
            curr,
            findSpaceComponentPackage,
            Array.from(slugsWithErrors),
            state.apiErrors
          )
        };
      }, {} as SpaceError);
      if (errorsPreventingSubmit.NAME.length > 0) {
        dispatch({ type: "SET_NAME_ERROR" });
      }
      const message = getFirstSpaceError(errorsPreventingSubmit);
      if (message) {
        Message.error(message);
        return;
      }
      return saveMutation(environmentsToPublish);
    },
    [slugsWithErrors, state, mutationLoading, findSpaceComponentPackage, saveMutation]
  );

  const destroy = useCallback(() => {
    if (mutationLoading) return;
    return destroyMutation();
  }, [mutationLoading, destroyMutation]);

  const shouldDisplayError = useCallback(
    (slug: string) => {
      if (!state.submitted) return false;
      const node = findComponentTreeNode(componentTreeNodes, slug);
      if (!node) return false;
      if (slugsWithErrors.has(node.slug)) return true;
      let match;
      for (const slugWithError of slugsWithErrors) {
        match = findComponentTreeNode(node.componentTreeNodes, slugWithError);
        if (match) return true;
      }
      return false;
    },
    [slugsWithErrors, componentTreeNodes, state.submitted]
  );

  const predictSlug = useCallback(
    componentType => {
      return ensureSlug(componentType, selectAllSlugs(state));
    },
    [state]
  );

  const getComponentSpaceConfig = useCallback(
    (slug: string) => {
      const space = selectComponentToSpaceMap(state).get(slug);
      if (!space && clientProvidedComponents.find(c => c.slug === slug)) {
        return state.spaces.get(state.rootSpaceSlug)!;
      }
      if (!space) throw new Error(`Space for ${slug} not found`);
      return space;
    },
    [state, clientProvidedComponents]
  );

  const getComponentConfig = useCallback(
    (slug: string) => {
      const space = getComponentSpaceConfig(slug);
      const component = space.components.get(slug);
      const clientProvidedComponent = clientProvidedComponents.find(
        c => c.slug === slug
      );
      if (!component && clientProvidedComponent) {
        return {
          type: clientProvidedComponent.type,
          draftComponent: clientProvidedComponent
        };
      }
      if (!component) throw new Error(`Component ${slug} not found`);
      return component;
    },
    [getComponentSpaceConfig, clientProvidedComponents]
  );

  const getChildren = useCallback(
    (slug: string) => {
      const space = getComponentSpaceConfig(slug);
      const tree = new TreeTraverser(space.tree, {
        getChildren: node => node?.treeNodes || [],
        getParent: node => node?.container || null
      });
      const node = tree.find(node => node?.slug === slug);

      const parentComponent = space.components.get(node?.slug || "")?.draftComponent;
      if (!parentComponent) {
        return [];
      } else if (parentComponent.type === "SUB_SPACE") {
        // Find the sub space and return its root nodes
        const subSpace = state.spaces.get(parentComponent.properties.sub_space);
        if (!subSpace) return [];
        return (subSpace.tree?.treeNodes || []).map(
          n => subSpace.components.get(n.slug!)!.draftComponent
        );
      } else {
        return (node?.treeNodes || []).map(
          n => space.components.get(n.slug!)!.draftComponent
        );
      }
    },
    [state.spaces, getComponentSpaceConfig]
  );

  const memoSpace = useMemo(() => {
    return space ? getStableSpace(space) : EMPTY_SPACE;
  }, [space]);

  return useMemo(
    () => ({
      [OPAQUE_EDIT_MODE_KEY]: true,
      space: memoSpace,
      queryStatus,
      componentsWithErrors: slugsWithErrors,
      dispatch,
      componentTree: componentTreeNodes,
      spaces: state.spaces,
      state,
      save,
      destroy,
      loading: !state.isStateLoaded,
      mutationLoading,
      shouldDisplayError,
      predictSlug,
      getComponentSpaceConfig,
      getComponentConfig,
      getChildren,
      configErrors
    }),
    [
      memoSpace,
      queryStatus,
      componentTreeNodes,
      state,
      save,
      destroy,
      mutationLoading,
      shouldDisplayError,
      predictSlug,
      getComponentSpaceConfig,
      getComponentConfig,
      getChildren,
      slugsWithErrors,
      configErrors
    ]
  );
}

function findComponentTreeNode(
  nodes: SpaceComponentObject[],
  slug: string
): SpaceComponentObject | undefined {
  let match = nodes.find(c => c.slug === slug);
  if (match) {
    return match;
  }

  for (let i = 0; i < nodes.length; i++) {
    match = findComponentTreeNode(nodes[i].componentTreeNodes, slug);
    if (match) {
      return match;
    }
  }
}
