import React from "react";

import { Icon } from "antd";

import { EnvironmentNode, RoleNode } from "../../../../types";
import { EnvironmentContextProvider } from "../../../common/contexts/EnvironmentContext";
import { AntdEnvironmentSelect } from "../../../common/EnvironmentSelect/AntdEnvironmentSelect";
import { PermissionsPaginator } from "../../../common/Permissions/permissions";
import {
  PolicySpaceFunctions,
  PolicySpaceReducerAction
} from "../../../common/Permissions/reducer";
import { FunctionH5 } from "../../../common/Permissions/styledComponents";
import { C2 } from "../../../common/StyledComponents";

import PermissionsTableWithEnvironmentContext from "./PermissionsTableWithEnvironmentContext";
import { Space } from "./queries";
import {
  SpaceBetweenLayout,
  SpaceRowPermissionsContent,
  SpaceRowPermissionTop,
  StyledInput
} from "./styledComponents";
import { EnvironmentOptionType, EnvironmentSelectOption } from "./useSpaceRolePolicies";

const LAST_SAVED_VERSION_KEY = "last-saved-version";

function SpaceRolePermissionsFilter({
  selectedEnvironment,
  setSelectedEnvironment,
  setSearchText
}: {
  selectedEnvironment: EnvironmentSelectOption;
  setSelectedEnvironment: (environment: EnvironmentSelectOption) => void;
  setSearchText: (query: string) => void;
}) {
  const rootEleRef = React.useRef(null);
  const extraOptions = [
    {
      key: LAST_SAVED_VERSION_KEY,
      display: "Last saved version"
    }
  ];

  const selectedEnvironmentValue =
    selectedEnvironment.type === EnvironmentOptionType.Environment
      ? selectedEnvironment.payload
      : LAST_SAVED_VERSION_KEY;

  return (
    <SpaceBetweenLayout ref={rootEleRef}>
      <AntdEnvironmentSelect
        selectedEnvironment={selectedEnvironmentValue}
        setSelectedEnvironment={env => {
          const envNode = env as EnvironmentNode;
          if (envNode.slug) {
            setSelectedEnvironment({
              type: EnvironmentOptionType.Environment,
              payload: envNode
            });
          } else {
            setSelectedEnvironment({ type: EnvironmentOptionType.LastSavedVersion });
          }
        }}
        popupContainer={rootEleRef.current!}
        extraOptions={extraOptions}
      />
      <StyledInput
        placeholder="Search functions"
        prefix={<Icon type="search" />}
        onChange={e => setSearchText(e.currentTarget.value)}
      />
    </SpaceBetweenLayout>
  );
}

interface SpaceRolePermissionsProps {
  space: Space;
  role: RoleNode;
  page: number;
  pageSize: number;
  selectedEnvironment: EnvironmentSelectOption;
  state: PolicySpaceFunctions;
  className?: string;
  dispatch: (action: PolicySpaceReducerAction) => void;
  searchText: string | undefined;
  setSearchText: (query: string) => void;
  setPage: (page: number) => void;
  setSelectedEnvironment: (environment: EnvironmentSelectOption) => void;
  spaceId: string;
}

export function SpaceRolePermissions({
  space,
  role,
  page,
  pageSize,
  selectedEnvironment,
  spaceId,
  state,
  className,
  dispatch,
  searchText,
  setSearchText,
  setPage,
  setSelectedEnvironment
}: SpaceRolePermissionsProps) {
  const environment =
    selectedEnvironment.type === EnvironmentOptionType.Environment
      ? selectedEnvironment.payload
      : undefined;

  return (
    <SpaceRowPermissionsContent className={className} data-test="expandedRowCell">
      <SpaceRowPermissionTop>
        <FunctionH5>Functions for {space.name}</FunctionH5>
        <C2>Changes to function permissions are global</C2>
        <SpaceRolePermissionsFilter
          selectedEnvironment={selectedEnvironment}
          setSelectedEnvironment={setSelectedEnvironment}
          setSearchText={setSearchText}
        />
      </SpaceRowPermissionTop>
      <EnvironmentContextProvider environment={environment}>
        <PermissionsTableWithEnvironmentContext
          environmentOptionType={selectedEnvironment.type}
          role={role}
          searchText={searchText}
          state={state}
          dispatch={dispatch}
          page={page}
          pageSize={pageSize}
          spaceId={spaceId}
        />
      </EnvironmentContextProvider>
      {state.totalCount !== 0 && (
        <PermissionsPaginator
          page={page}
          pageSize={pageSize}
          total={state.totalCount}
          onPageChange={page => setPage(page)}
        />
      )}
    </SpaceRowPermissionsContent>
  );
}
