import React, { useCallback } from "react";

import styled from "styled-components";

import { SpaceFunctionType } from "../../../../../types";
import { ButtonTypes as AntButtonTypes } from "../../../../common/Button/Button";
import { ErrorIcon } from "../../../../common/Icons";
import { WarningTooltip } from "../../../../common/ToolTips";
import { processEffects as processQueueEffects } from "../../../../queues/App/Queue/utils";
import {
  createSpaceFunction,
  FunctionExecutionStatus
} from "../../../FunctionExecutor/FunctionExecutor";
import { EventHandler } from "../../../types";
import { useStableSpaceContext } from "../../SpaceContext";
import Button from "../common/Button";
import { ButtonTypes } from "../common/Button/constants";
import usePointerEffects, {
  PointerEventType
} from "../common/effects/usePointerEffects/usePointerEffects";
import useSubmittableEffects, {
  SubmittableEventType
} from "../common/effects/useSubmittableEffects/useSubmittableEffects";
import PermissionFeedback, {
  isBoundParameterDenied,
  isRequiredParameterDenied
} from "../common/PermissionFeedback";
import useFuncParams, { InputParameter } from "../common/useFuncParams";
import useFunctionAccess from "../common/useFunctionAccess";
import useSpaceFunction, {
  FunctionResult
} from "../common/useSpaceFunction/useSpaceFunction";
import { useComponentContext } from "../contexts/ComponentContext";
import { useComponentStateContext } from "../contexts/ComponentStateContext";
import { Props } from "../SpaceComponent";

const Root = styled.div``;

const Container = styled.div`
  display: flex;
  width: 100%;
  text-align: left;
  align-items: center;
`;

const ConfigContainer = styled.div<{ buttonType: AntButtonTypes }>`
  position: relative;
  cursor: default;

  ${props =>
    props.buttonType === "link"
      ? ""
      : `button: disabled {
      color: white;
      background - color: ${props.theme.primaryColor};
    }`}
`;

const ConfigEventMask = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const StyledErrorIcon = styled(ErrorIcon)`
  position: absolute;
  z-index: 1;
  top: 4px;
  left: 4px;
`;

export default function SpaceFunctionButton({
  spaceComponent: {
    properties: { input_parameters = [], button_text = "Submit", effects = [] }
  },
  spaceApi,
  spaceComponent,
  hasConfigError
}: Props) {
  const func = React.useMemo(
    () => createSpaceFunction(spaceComponent),
    [spaceComponent]
  );

  const { spaceId, editMode } = useStableSpaceContext();
  const { input, updateOutput } = useComponentStateContext();

  const { triggerEffects: triggerSubmittableEffects } = useSubmittableEffects(spaceApi);
  const { triggerEffects: triggerPointerEffects } = usePointerEffects();

  const onCompleted = useCallback(
    (result: FunctionResult, metadata: unknown) => {
      updateOutput({ lastExecutionResult: result });
      triggerSubmittableEffects({ type: SubmittableEventType.SUBMIT_SUCCESS });
      processQueueEffects(metadata);
    },
    [updateOutput, triggerSubmittableEffects]
  );

  const onError = useCallback(
    (error: Error) => {
      triggerSubmittableEffects({ type: SubmittableEventType.SUBMIT_FAILURE, error });
    },
    [triggerSubmittableEffects]
  );

  // input_parameters should be treated as hidden since no way to view / edit
  const inputParameters = React.useMemo(
    () =>
      input_parameters.map((p: InputParameter) => ({
        ...p,
        hidden: true
      })),
    [input_parameters]
  );

  const { status, executeFunction } = useSpaceFunction(
    func,
    input_parameters,
    spaceId,
    onCompleted,
    onError
  );
  const {
    funcParams,
    hasRequiredValues,
    hasRequiredBindings,
    hasValidValues,
    getCurrentFuncParams
  } = useFuncParams(func, inputParameters, input);

  const access = useFunctionAccess(func);

  const paramDenied = isRequiredParameterDenied(input_parameters, func, access);
  const bindingDenied = isBoundParameterDenied(
    input_parameters,
    func.functionParameters,
    funcParams
  );

  const onSubmit = React.useCallback(() => {
    executeFunction(getCurrentFuncParams());
  }, [executeFunction, getCurrentFuncParams]);

  const {
    component: { layout }
  } = useComponentContext();

  const buttonType =
    spaceComponent.properties.button_type === ButtonTypes.Link ? "link" : "primary";

  if (editMode) {
    const maybeErrorIcon = hasConfigError ? <StyledErrorIcon /> : null;
    return (
      <Root className="spaceButtonRoot">
        <ConfigContainer buttonType={buttonType}>
          {maybeErrorIcon}
          <Button
            type={buttonType}
            layout={layout}
            onClick={() =>
              !editMode && triggerPointerEffects({ type: PointerEventType.CLICK })
            }
            disabled
          >
            {button_text}
          </Button>
          <ConfigEventMask />
        </ConfigContainer>
      </Root>
    );
  }

  // TODO: Any can be removed once feature flag is removed.
  if (
    !effects.find(
      (eff: EventHandler) => eff.type === (PointerEventType.CLICK as any)
    ) &&
    (!func || [SpaceFunctionType.INVALID, SpaceFunctionType.VOID].includes(func.type))
  ) {
    return (
      <WarningTooltip
        message="This button needs to be updated"
        description="The function associated with this button cannot be found or is invalid. It's
possible that the name of the function was changed or that the
function was removed from your system."
        placement="left"
      >
        <span>
          <StyledErrorIcon />
          <Button
            type={buttonType}
            layout={layout}
            disabled
            onClick={() =>
              !editMode && triggerPointerEffects({ type: PointerEventType.CLICK })
            }
          >
            {button_text}
          </Button>
        </span>
      </WarningTooltip>
    );
  }

  if (paramDenied || bindingDenied || func.type === SpaceFunctionType.NOT_VISIBLE) {
    return (
      <WarningTooltip
        message="This button is disabled."
        description={
          paramDenied
            ? "You don't have access to one or more fields required to complete this action. Please contact your admin to update your permissions."
            : "At least one value required for this action could not be retrieved because you do not have permissions to access the data. Please contact your admin to update your permissions."
        }
        placement="left"
      >
        <span>
          <StyledErrorIcon />
          <Button
            type="primary"
            layout={layout}
            disabled
            onClick={() =>
              !editMode && triggerPointerEffects({ type: PointerEventType.CLICK })
            }
          >
            {button_text}
          </Button>
        </span>
      </WarningTooltip>
    );
  }

  return (
    <Root className="spaceButtonRoot">
      <Container>
        <Button
          type={buttonType}
          loading={status === FunctionExecutionStatus.IN_PROGRESS}
          disabled={!hasRequiredValues || !hasRequiredBindings || !hasValidValues}
          fixedWidthLoadingState
          layout={layout}
          onClick={() => {
            !editMode && triggerPointerEffects({ type: PointerEventType.CLICK });
            if (!func || func.type === SpaceFunctionType.VOID) return;
            onSubmit();
          }}
        >
          {button_text}
        </Button>
        <PermissionFeedback
          attributes={func.functionAttributes}
          parameters={func.functionParameters.filter(fp =>
            input_parameters.some((ip: InputParameter) => ip.name === fp.name)
          )}
          access={access}
        />
      </Container>
    </Root>
  );
}
