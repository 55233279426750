import { Button } from "antd";
import styled from "styled-components";

export const Title = styled.h2`
  display: flex;
  align-items: center;
`;

export const StyledCancelButton = styled(Button)<{ outlineColor: string }>`
  &.ant-btn:hover,
  &.ant-btn:hover,
  &.ant-btn:active,
  &.ant-btn:focus {
    border-color: ${props => props.outlineColor};
    color: ${props => props.outlineColor || props.theme.primaryButtonColor};

    &:disabled {
      border-color: ${props => props.theme.buttonDisabledBorderColor};
      color: ${props => props.theme.buttonDisabledColor};
    }
  }
`;

export const StyledSubmitButton = styled(Button)<{
  backgroundColor: string;
  textColor: string;
}>`
  color: ${props => props.textColor};

  &.ant-btn.ant-btn-primary,
  &.ant-btn.ant-btn-primary:hover,
  &.ant-btn.ant-btn-primary:active,
  &.ant-btn.ant-btn-primary:focus {
    background-color: ${props => props.backgroundColor};
    border-color: ${props => props.backgroundColor};
    color: ${props => props.textColor || props.theme.primaryButtonColor};

    &:disabled {
      background-color: ${props => props.theme.buttonDisabledBackground};
      border-color: ${props => props.theme.buttonDisabledBorderColor};
      color: ${props => props.theme.buttonDisabledColor};
    }
  }

  &:hover {
    filter: brightness(0.9);
  }
  &:active,
  &:focus {
    filter: brightness(0.8);
  }
`;
