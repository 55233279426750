import { ConfigValidationError } from "../../../../../types";
import { ComponentConfigState } from "../../../types";
import selectVisibilityRuleErrors from "../common/VisibilityRulesManager/selectErrors";

import { SubSpaceComponent } from "./types";

export default function errorSelector(
  state: ComponentConfigState
): ConfigValidationError[] {
  const errors: ConfigValidationError[] = [];
  const { draftComponent } = state;
  const { input_parameters } = (draftComponent as SubSpaceComponent).properties;
  const paramNames = new Set<string>();
  input_parameters.forEach((param, index) => {
    if (!param.name) {
      errors.push({
        field: "INPUT_PARAMETERS",
        message: "Parameter name is required.",
        index
      });
      return;
    }
    if (paramNames.has(param.name)) {
      errors.push({
        field: "INPUT_PARAMETERS",
        message: "Parameter name must be unique.",
        index
      });
    }
    paramNames.add(param.name);
  });

  return errors.concat(selectVisibilityRuleErrors(draftComponent));
}
