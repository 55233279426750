import React from "react";

import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import styled from "styled-components";

import { publicClient } from "../../graphql";
import Button from "../common/Button";
import Message from "../common/Message";
import { DarkTheme } from "../common/StyledComponents";

const REQUEST_INVITE_MUTATION = gql`
  mutation RequestInvite($email: String!) {
    requestInvite(email: $email) {
      ok
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  button:first-child {
    margin-bottom: 12px;
  }
`;

interface Props {
  email: string;
  onComplete: () => void;
}

const RequestInvite = ({ email, onComplete }: Props) => {
  const [requestInvite, { loading: submitting }] = useMutation(
    REQUEST_INVITE_MUTATION,
    {
      client: publicClient,
      variables: { email },
      onCompleted: () => {
        onComplete();
        Message.success(
          "You'll get an invite to Internal once your company admin approves your request."
        );
      }
    }
  );

  const handleClick = () => {
    requestInvite();
  };

  return (
    <React.Fragment>
      <DarkTheme />
      <ButtonWrapper>
        <Button onClick={handleClick} type="primary" size="medium" loading={submitting}>
          Request Access
        </Button>
        <Button type="link" onClick={onComplete}>
          Go Back
        </Button>
      </ButtonWrapper>
    </React.Fragment>
  );
};

export default RequestInvite;
