export enum Conjunction {
  AND = "&&",
  OR = "||"
}

export enum Operator {
  EQUALS = "==",
  NOT_EQUALS = "!=",
  LESS_THAN = "<",
  LESS_THAN_OR_EQUAL = "<=",
  GREATER_THAN = ">",
  GREATER_THAN_OR_EQUAL = ">="
}

export const OperatorHumanizeDisplayNames: Record<Operator, string> = {
  [Operator.EQUALS]: "is equal to",
  [Operator.NOT_EQUALS]: "is not equal to",
  [Operator.LESS_THAN]: "is less than",
  [Operator.LESS_THAN_OR_EQUAL]: "is less than or equal to",
  [Operator.GREATER_THAN]: "is greater than",
  [Operator.GREATER_THAN_OR_EQUAL]: "is greater than or equal to"
};

export interface Condition {
  property: string;
  operator?: Operator;
  value: string;
}
