import styled from "styled-components";

import { H6, LinkButtonNew } from "../../../common/StyledComponents";

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 18px 25px 14px;
  background-color: ${props => props.theme.newContainerPrimary};
  border: 1px solid ${props => props.theme.borderGrey};
  border-radius: 8px;
  gap: 18px;
`;

export const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const ErrorBody = styled(H6)`
  color: ${props => props.theme.errorColor};
`;

export const ErrorIconWrapper = styled.div`
  color: ${props => props.theme.errorColor};
`;

export const LinkButton = styled(LinkButtonNew)`
  font-size: 13px;
`;
