import { useQuery } from "@apollo/react-hooks";
import { QueryHookOptions } from "@apollo/react-hooks/lib/types";
import gql from "graphql-tag";

import { FunctionNode } from "../../../../../../types";
import {
  FUNCTION_ATTRIBUTE_FRAGMENT,
  FUNCTION_PARAMETER_FRAGMENT
} from "../../../useFunctionEditor/queries";

export const PIPELINE_FUNCTIONS_QUERY = gql`
  query PipelineFunctionPickerFunctions(
    $categories: [String!]
    $functionScope: String!
    $dataSourceId: ID
    $searchText: String
  ) {
    allFunctions(
      categories: $categories
      scope: $functionScope
      dataSourceId: $dataSourceId
      searchText: $searchText
    ) {
      edges {
        __typename
        node {
          __typename
          id
          name
          title
          isUserGenerated
          returnSchema
        }
      }
    }
  }
`;

interface Data {
  node: FunctionNode;
}

interface Variables {
  id: string;
}

const QUERY = gql`
  query GetFunctionById($id: ID!) {
    node(id: $id) {
      ... on FunctionNode {
        id
        name
        returnSchema
        title
        dataSource {
          id
          integration
        }
        functionParameters {
          edges {
            node {
              ...FunctionParameterFragment
            }
          }
        }
        functionAttributes {
          edges {
            node {
              ...FunctionAttributeFragment
            }
          }
        }
      }
    }
  }
  ${FUNCTION_PARAMETER_FRAGMENT}
  ${FUNCTION_ATTRIBUTE_FRAGMENT}
`;

export const useGetFunctionById = (options?: QueryHookOptions<Data, Variables>) => {
  return useQuery<Data, Variables>(QUERY, options);
};
