import React, { useCallback, useEffect } from "react";

import { Input } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import styled from "styled-components";
import { v4 as uuid } from "uuid";

import { ComponentConfigProps } from "../../../../../../types";
import SortableList, { SortableItemCompact } from "../../../../../common/SortableList";
import Spacer from "../../../../../common/Spacer";
import { useTransformationActionContext } from "../../../../layout/TransformationContext/TransformationContext";
import { useSpaceConfigContext } from "../../../../SpaceConfig/SpaceConfigContext";
import BaseComponentConfigSection from "../../common/BaseComponentConfigSection";
import Button from "../../common/Button";
import {
  ComponentConfigContextContainer,
  useComponentConfigContext
} from "../../common/ComponentConfigContext";
import { ConfigPanelPopper, ConfigSection, Field } from "../../common/ConfigPanel";
import {
  ConfigPanelActionTypes,
  useSpaceConfigPanelContext
} from "../../common/ConfigPanel/ConfigPanelContext";
import { Checkbox } from "../../common/ConfigPanel/styledComponents";
import useCreateSubSpace from "../../common/useCreateSubSpace/useCreateSubSpace";
import VisibilityRulesManagerSection from "../../common/VisibilityRulesManager";
import { AccordionSection } from "../types";

import { ensureAccordionConfigState } from "./reducer";

export default function Config({ slug }: ComponentConfigProps) {
  return (
    <ComponentConfigContextContainer dataConfig={<AccordionConfig />} slug={slug} />
  );
}

function AccordionConfig() {
  const { dispatch, state } = useComponentConfigContext();
  const createSubSpace = useCreateSubSpace();
  const accordionConfigState = ensureAccordionConfigState(state);
  const { draftComponent } = accordionConfigState;

  const addSection = useCallback(() => {
    const subSpaceSlug = createSubSpace(
      draftComponent.slug,
      `Section ${draftComponent.properties.sections.length + 1}`
    );

    dispatch({
      type: "SET_DRAFT_COMPONENT",
      payload: {
        path: "properties.sections",
        value: [
          ...draftComponent.properties.sections,
          {
            id: uuid(),
            title: "Untitled section",
            component_slug: subSpaceSlug
          }
        ]
      }
    });
  }, [draftComponent, createSubSpace, dispatch]);

  // If sections have not been initialized, create the first one
  useEffect(() => {
    if (!accordionConfigState.initialized) {
      addSection();
    }
  }, [accordionConfigState.initialized, addSection]);

  return (
    <>
      <BaseComponentConfigSection>
        <Field>
          <Checkbox
            checked={draftComponent.properties.allows_multiple_open_sections}
            onChange={(e: CheckboxChangeEvent) => {
              dispatch({
                type: "SET_DRAFT_COMPONENT",
                payload: {
                  path: "properties.allows_multiple_open_sections",
                  value: e.target.checked
                }
              });
            }}
          >
            Allows multiple open sections
          </Checkbox>
        </Field>
      </BaseComponentConfigSection>
      <ConfigSection id="accordion-config-section" title="Sections" onAdd={addSection}>
        <SectionsList sections={draftComponent.properties.sections} />
      </ConfigSection>
      <VisibilityRulesManagerSection />
    </>
  );
}

function SectionsList({ sections }: { sections: AccordionSection[] }) {
  const { errors, dispatch } = useComponentConfigContext();
  const configPanelContext = useSpaceConfigPanelContext();
  const { select } = useTransformationActionContext();
  const rootConfigContext = useSpaceConfigContext();

  if (sections.length === 0)
    return (
      <EmptyState>
        <EmptyStateMessage>No sections present.</EmptyStateMessage>
        <FinePrint>Add a section with the + button.</FinePrint>
      </EmptyState>
    );

  return (
    <SortableList
      isCompact
      onSort={(currentIndex: number, nextIndex: number) => {
        const copy = [...sections];
        const section = copy.splice(currentIndex, 1)[0];
        copy.splice(nextIndex, 0, section);

        dispatch({
          type: "SET_DRAFT_COMPONENT",
          payload: { path: "properties.sections", value: copy }
        });
      }}
    >
      {sections.map((section, index) => {
        const key = section.id;
        const err =
          errors.find(err => err.field === "ACCORDION_SECTION" && err.index === index)
            ?.message || null;

        const isPopperOpen = configPanelContext.state.activePopperIdentifier === key;

        return (
          <SortableItemCompact
            id={key}
            key={key}
            sortKey={key}
            errorMessage={err}
            isSelected={false}
            onClick={() => {
              configPanelContext.dispatch({
                type: ConfigPanelActionTypes.OPEN_POPPER,
                payload: {
                  popperIdentifier: key
                }
              });
            }}
            onRemove={sortKey => {
              const idx = sections.findIndex(t => t.id === sortKey);
              const copy = sections.slice();
              const removed = copy.splice(idx, 1);

              dispatch({
                type: "SET_DRAFT_COMPONENT",
                payload: { path: "properties.sections", value: copy }
              });

              rootConfigContext.dispatch({
                type: "REMOVE_COMPONENT",
                payload: { slug: removed[0].component_slug }
              });
            }}
          >
            {section.title}
            {isPopperOpen && (
              <ConfigPanelPopper
                popperId={key}
                popperReferenceElement={document.getElementById(key) || undefined}
                onCancel={() => {
                  configPanelContext.dispatch({
                    type: ConfigPanelActionTypes.CLOSE_POPPER
                  });
                }}
              >
                <ConfigSection title="Section">
                  <label>Title</label>
                  <Input
                    value={section.title}
                    onChange={evt => {
                      dispatch({
                        type: "SET_DRAFT_COMPONENT",
                        payload: {
                          path: `properties.sections[${index}].title`,
                          value: evt.target.value
                        }
                      });
                    }}
                  />
                  <Spacer size="md" />
                  <Button
                    type="link"
                    onClick={() => {
                      select(section.component_slug);
                    }}
                  >
                    Go to this component
                  </Button>
                </ConfigSection>
              </ConfigPanelPopper>
            )}
          </SortableItemCompact>
        );
      })}
    </SortableList>
  );
}

const EmptyState = styled.div`
  text-align: center;
`;

const EmptyStateMessage = styled.div`
  font-size: ${props => props.theme.defaultFontSize};
  color: ${props => props.theme.textColor};
  padding: ${props => props.theme.spacersm} 0;
  font-weight: 500;
`;

const FinePrint = styled.div`
  font-size: ${props => props.theme.smallFontSize};
  color: ${props => props.theme.textColorMid};
`;
