import React, { useCallback, useMemo } from "react";

import { Icon, Select as AntSelect } from "antd";
import styled from "styled-components";

import { GlobalStyleVariables } from "../../../../../../cssConstants";
import ColorPicker from "../../../../../common/ColorPicker/ColorPicker";
import useElementTransformer from "../../../../layout/Element/useElementTransformer";
import { ElementLayout } from "../../../../layout/util";
import TypographyConfig from "../../SpaceCalculatedField/Config/TypographyConfig";
import {
  ButtonTypes,
  DEFAULT_BUTTON_BACKGROUND_COLOR,
  DEFAULT_BUTTON_TEXT_COLOR
} from "../Button/constants";
import { useComponentConfigContext } from "../ComponentConfigContext";
import { ConfigSection } from "../ConfigPanel";
import { Field, Select } from "../ConfigPanel/styledComponents";
import { StyleField, StyleOption } from "../DesignConfig/styledComponents";
import useNestedConfig from "../useNestedStatus";

const { Option } = AntSelect;

const Container = styled.div`
  display: flex;

  ${Field} + ${Field} {
    margin-top: 0;
    margin-left: ${props => props.theme.spacersm};

    .ant-select-selection__rendered {
      display: flex;
      align-items: center;
    }
  }
`;

const PaddedColorPicker = styled(ColorPicker)`
  margin-top: 6px;
`;

const ShapeField = styled(StyleField)`
  min-width: 90px;
  flex-grow: 1;
`;

const Rectangle = styled(StyleOption)`
  border-radius: ${props => props.theme.borderRadiusmd};
`;

const Circle = styled(StyleOption)`
  border-radius: 50%;
`;

const LinkIcon = styled(Icon)`
  width: 21px;
`;

const ButtonTypeWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: ${props => props.theme.spacersm};
  margin-left: 7px;
  margin-right: 7px;
`;

const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacermd};
`;

export default function DesignConfig() {
  const context = useComponentConfigContext();
  const { transform } = useElementTransformer();
  const { isNested } = useNestedConfig();
  const { draftComponent } = context.state;
  const { dispatch } = context;

  const color =
    draftComponent.properties.button_background_color ||
    DEFAULT_BUTTON_BACKGROUND_COLOR;

  const themeProperties = useMemo(() => {
    return draftComponent.properties.theme_properties || {};
  }, [draftComponent.properties.theme_properties]);

  const updateThemeProperties = useCallback(
    change => {
      dispatch({
        type: "UPDATE_COMPONENT_THEME_PROPERTIES",
        payload: {
          properties: {
            ...themeProperties,
            ...change
          }
        }
      });
    },
    [dispatch, themeProperties]
  );

  return (
    <>
      <ConfigSection title="Style">
        <VerticalContainer>
          <Container>
            <StyleField>
              <label>Fill</label>
              <PaddedColorPicker
                value={color}
                size="small"
                onChange={value => {
                  dispatch({
                    type: "SET_DRAFT_COMPONENT",
                    payload: {
                      path: "properties.button_background_color",
                      value
                    }
                  });
                }}
              />
            </StyleField>
            <ShapeField>
              <label>Type</label>
              <Select
                getPopupContainer={trigger => trigger.parentNode as HTMLElement}
                value={draftComponent.properties.button_type || ButtonTypes.Rectangle}
                onChange={value => {
                  dispatch({
                    type: "SET_DRAFT_COMPONENT",
                    payload: {
                      path: "properties.button_type",
                      value
                    }
                  });

                  // update minWidth to create circle-shaped button; for non-form components, default width as well (user can resize width after)
                  const changes: Partial<ElementLayout> = {
                    minWidth:
                      value === ButtonTypes.Circle
                        ? GlobalStyleVariables.buttonHeight
                        : undefined
                  };
                  if (
                    value === ButtonTypes.Circle &&
                    draftComponent.type !== "FUNCTION_FORM" &&
                    !isNested
                  ) {
                    changes["width"] = GlobalStyleVariables.buttonHeight;
                  }
                  transform(changes);

                  if (value !== "link") {
                    // Clear styles on switching to non-link
                    dispatch({
                      type: "UPDATE_COMPONENT_STYLE",
                      payload: {
                        styles: {}
                      }
                    });
                  }
                }}
              >
                <Option key={ButtonTypes.Rectangle}>
                  <ButtonTypeWrapper>
                    <Rectangle /> Rectangle
                  </ButtonTypeWrapper>
                </Option>
                <Option key={ButtonTypes.Circle}>
                  <ButtonTypeWrapper>
                    <Circle /> Circle
                  </ButtonTypeWrapper>
                </Option>
                <Option key={ButtonTypes.Link}>
                  <ButtonTypeWrapper>
                    <LinkIcon type="link" /> Link
                  </ButtonTypeWrapper>
                </Option>
              </Select>
            </ShapeField>
          </Container>
          <Field>
            <label>Text color</label>
            <ColorPicker
              value={themeProperties["text-color"] || DEFAULT_BUTTON_TEXT_COLOR}
              size="small"
              onChange={value => {
                updateThemeProperties({ "text-color": value });
              }}
            />
          </Field>
        </VerticalContainer>
      </ConfigSection>
      {draftComponent.properties.button_type === ButtonTypes.Link && (
        <TypographyConfig
          title="Link typography"
          hideColorPicker={true}
          defaultTextAlign="center"
        />
      )}
    </>
  );
}
