import React, { ReactNode, useMemo } from "react";

import { useQuery } from "@apollo/react-hooks";
import { ButtonProps } from "antd/lib/button";
import { isEqual } from "lodash";

import { isSupported } from "../../../../../common/FunctionEditor/support";
import useAuthUser from "../../../../../common/hooks/useAuthUser";
import { Empty } from "../../../../../common/Menu/Section";
import {
  BaseSection,
  IconButton,
  IconWithMargin
} from "../../../../../common/Menu/Section/Section";

import {
  FETCH_DATA_PROVIDERS_AND_USER_DEFINED_FUNCTIONS,
  FetchDataProvidersAndUserDefinedFunctionsData
} from "./queries";
import * as styled from "./styledComponents";
import { selectDataSources } from "./util";

export interface Props {
  onAdd: (dataSourceId: string) => void;
  onSelect: (dataSourceId: string, functionId: string) => void;
  selected: string[];
}

export const FunctionList = (props: Props) => {
  const { isAdmin } = useAuthUser();
  const { data } = useQuery<FetchDataProvidersAndUserDefinedFunctionsData>(
    FETCH_DATA_PROVIDERS_AND_USER_DEFINED_FUNCTIONS
  );

  const dataSources = useMemo(() => {
    return selectDataSources(data)
      .filter(ds => isSupported(ds.integration))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [data]);

  return (
    <>
      {dataSources.map(ds => (
        <FunctionSection
          key={ds.id}
          title={ds.name}
          onTitleClick={isAdmin ? () => props.onAdd(ds.id) : undefined}
        >
          {!ds.functions.edges.length ? (
            <Empty>No custom functions</Empty>
          ) : (
            <styled.List>
              {ds.functions.edges.map(f => (
                <styled.ListItem
                  title={f.node.title}
                  data-test="functionListItem"
                  key={`${ds.id}-${f.node.id}`}
                  selected={isEqual(props.selected, [ds.id, f.node.id])}
                  onClick={() => props.onSelect(ds.id, f.node.id)}
                >
                  <styled.ListItemCopy>{f.node.title}</styled.ListItemCopy>
                  {isAdmin && <styled.EditButton type="edit" />}
                </styled.ListItem>
              ))}
            </styled.List>
          )}
        </FunctionSection>
      ))}
    </>
  );
};

interface FunctionSectionProps {
  className?: string;
  children: ReactNode;
  title: string;
  titleButtonProps?: ButtonProps;
  onTitleClick?: () => void;
}

export default function FunctionSection(props: FunctionSectionProps) {
  const [open, setOpen] = React.useState(false);
  const buttonProps = props.titleButtonProps || {};
  return (
    <BaseSection className={props.className}>
      <styled.FunctionSectionHeader data-test="section" onClick={() => setOpen(!open)}>
        <span>
          <IconWithMargin type={open ? "caret-down" : "caret-right"} />
          {props.title}
        </span>
        {!!props.onTitleClick && (
          <IconButton
            data-test="iconButton"
            icon="plus"
            type="link"
            onClick={_ => {
              props.onTitleClick!();
              setOpen(true);
            }}
            {...buttonProps}
          />
        )}
      </styled.FunctionSectionHeader>
      {open && props.children}
    </BaseSection>
  );
}
