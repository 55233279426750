import React, { ChangeEvent } from "react";

import { ApolloError } from "apollo-client";
import styled from "styled-components";

import { GlobalStyleVariables } from "../../../../../cssConstants";
import DarkThemeModal from "../../../../common/DarkThemeModal";

import { ImporterSpaceNode } from "./types";
import useSpaceImport from "./useSpaceImport";

interface Props {
  visible: boolean;
  onCancel?: () => void;
  onImportError?: (error: ApolloError) => void;
  onImportSuccess?: (space: ImporterSpaceNode) => void;
  spaceId?: string;
}

const getFileAsText = (file: Blob) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
    reader.readAsText(file);
  });

const HiddenInput = styled.input`
  display: none;
`;

export default function ImportModal({
  visible,
  spaceId,
  onCancel = () => null,
  onImportError = () => null,
  onImportSuccess = () => null
}: Props) {
  const [spaceImport, { loading }] = useSpaceImport({
    onCompleted: data => onImportSuccess(data.spaceImport.space),
    onError: error => onImportError(error)
  });

  const onImportChange = React.useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files) return;
      if (e.target.files.length !== 1) {
        throw new Error("unexpected file list");
      }
      const contents = await getFileAsText(e.target.files[0]);
      spaceImport({ variables: { spaceInput: JSON.parse(contents), spaceId } });
    },
    [spaceId, spaceImport]
  );

  const openFileSelector = React.useCallback(() => {
    const input = document.getElementById("import-modal-file-input");
    if (input) input.click();
  }, []);

  return (
    <>
      <HiddenInput
        accept="application/json"
        onChange={onImportChange}
        type="file"
        id="import-modal-file-input"
      />
      <DarkThemeModal
        visible={visible}
        title="Are you sure?"
        onCancel={onCancel}
        width={GlobalStyleVariables.confirmModal}
        okText="Yes, import version"
        okButtonProps={{ loading, onClick: openFileSelector }}
        content="All unsaved changes will be lost. Would you like to proceed?"
      />
    </>
  );
}
