import React from "react";

import { isEqual } from "lodash";

import usePrevious from "../../../../common/hooks/usePrevious";
import { useStableSpaceContext } from "../../SpaceContext";
import { useComponentPathContext } from "../contexts/ComponentPathContext";
import { useComponentStateContext } from "../contexts/ComponentStateContext";

import { SpaceTableState } from "./types";
import { Result as RowsResult, SpaceStateTableRow, getSpaceStateRow } from "./useRows";

export type SelectedRowIndexes = { [key: string]: number };

const emptySelectedRows: SpaceTableState["selectedRows"] = [];

export default function useRowSelection(
  rowsResult: RowsResult,
  selectedRows?: SpaceStateTableRow[]
) {
  const { editMode } = useStableSpaceContext();
  const [selectedRowIndexes, setSelectedRowIndexes] =
    React.useState<SelectedRowIndexes>({});
  const [lastSelectedRowIndexes, setLastSelectedRowIndexes] =
    React.useState<SelectedRowIndexes>({});
  const { updateOutput, input, componentNode, registerBinding, unregisterBinding } =
    useComponentStateContext();
  const componentPath = useComponentPathContext();

  const selectedRowsHash = React.useMemo(
    () => Object.fromEntries(selectedRows?.map(row => [row.id, true]) || []),
    [selectedRows]
  );

  const stillHasSelectedRows = React.useMemo(
    () => rowsResult.rows.some(r => selectedRowsHash[getSpaceStateRow(r).id]),
    [rowsResult.rows, selectedRowsHash]
  );

  // Handle deselecting space rows no longer present in table (due to filter change etc...)
  React.useEffect(() => {
    if (
      (selectedRows?.length && stillHasSelectedRows) ||
      !selectedRows?.length ||
      editMode
    )
      return;

    updateOutput({
      selectedRow: undefined,
      selectedRows: emptySelectedRows
    });
  }, [editMode, selectedRows, stillHasSelectedRows, updateOutput]);

  const { findRowById, getRowIndex } = rowsResult;
  const onSelectRows = React.useCallback(
    ids => {
      if (editMode) return;
      const _selectedRowIndexes = ids.map((id: string) => [
        id,
        getRowIndex(findRowById(id))
      ]);
      const _nextSelectedRowIndexes = Object.fromEntries(
        _selectedRowIndexes.filter(([_, idx]: [string, number]) => idx !== -1)
      );
      if (isEqual(_nextSelectedRowIndexes, selectedRowIndexes)) return;
      const _lastSelectedRowIndexes = Object.fromEntries(
        _selectedRowIndexes
          .filter(([_, idx]: [string, number]) => idx === -1)
          .map(([rowId, _]: [string, number]) => [rowId, selectedRowIndexes[rowId]])
          .filter(([_, idx]: [string, number]) => idx !== -1)
      );
      setLastSelectedRowIndexes(_lastSelectedRowIndexes);
      setSelectedRowIndexes(_nextSelectedRowIndexes);
    },
    [editMode, findRowById, getRowIndex, selectedRowIndexes]
  );

  const selectedRowBindingPaths = React.useMemo(() => {
    return Object.values(selectedRowIndexes).map(
      idx => `${componentPath}.rows[${idx}]`
    );
  }, [componentPath, selectedRowIndexes]);
  const lastSelectedRowBindingPaths = usePrevious(selectedRowBindingPaths);

  // Register a binding for the selectedRow's output state
  React.useEffect(() => {
    selectedRowBindingPaths.forEach(path => registerBinding(path));
    return () => {
      selectedRowBindingPaths.forEach(path => unregisterBinding(path));
    };
  }, [selectedRowBindingPaths, registerBinding, unregisterBinding]);

  // Update table's output state for the selectedRow with bound input from the referenced row
  React.useEffect(() => {
    if (
      selectedRowBindingPaths.length === 0 &&
      (input?.selectedRows as SpaceTableState["selectedRows"])?.length
    ) {
      updateOutput({ selectedRow: undefined, selectedRows: emptySelectedRows });
      return;
    }

    const isMissingSelectedRowInput =
      !input || selectedRowBindingPaths.some(path => !input[path]);
    if (isMissingSelectedRowInput) return;

    const output = {
      selectedRow: input === null ? undefined : input[selectedRowBindingPaths[0]],
      selectedRows:
        input === null ? undefined : selectedRowBindingPaths.map(path => input[path])
    };
    const currOutput = componentNode?.output as SpaceTableState | undefined | null;
    if (
      isEqual(output.selectedRow, currOutput?.selectedRow) &&
      isEqual(output.selectedRows, currOutput?.selectedRows)
    )
      return;
    updateOutput(output);
  }, [
    selectedRowBindingPaths,
    lastSelectedRowBindingPaths,
    input,
    componentNode?.output,
    updateOutput
  ]);

  return {
    lastSelectedRowIndexes: React.useMemo(
      () => Object.values(lastSelectedRowIndexes),
      [lastSelectedRowIndexes]
    ),
    lastSelectedRowIds: React.useMemo(
      () => Object.keys(lastSelectedRowIndexes),
      [lastSelectedRowIndexes]
    ),
    onSelectRows
  };
}
