import React from "react";

import { FileObject } from "../../../../types";
import { AttributeValueBaseProps } from "../AttributeValue";

export default function FileValue({
  value,
  className = "",
  style = {}
}: AttributeValueBaseProps) {
  const file: FileObject = value;
  return (
    <div style={style} className={className}>
      {String(file.name)}
    </div>
  );
}
