import React from "react";

// Really shouldn't change over the course of a session so re-use across
// hook invocations to reduce re-renders
let defaultScrollBarWidth = -1;
export default function useScrollbarWidth() {
  return React.useMemo(() => {
    if (defaultScrollBarWidth !== -1) return defaultScrollBarWidth;
    const scrollDiv = document.createElement("div");
    scrollDiv.className = "scrollbar-measure";
    document.body.appendChild(scrollDiv);

    // Get the scrollbar width
    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    defaultScrollBarWidth = scrollbarWidth;

    // Delete the DIV
    document.body.removeChild(scrollDiv);
    return scrollbarWidth;
  }, []);
}
