import { SpaceComponentType, SpaceComponentPackage } from "../../../../types";

import {
  Card as _cardPackage,
  Fieldset as _fieldsetPackage,
  Option as _optionPackage,
  RepeatedItem as _repeatedItemPackage,
  Row as _rowPackage,
  PseudoSpaceComponentPackage
} from "./pseudo";
import _accordionPackage from "./SpaceAccordion/package";
import _caclulatedFieldPackage from "./SpaceCalculatedField/package";
import _cardListPackage from "./SpaceCardList/package";
import _chartPackage from "./SpaceChart/package";
import _checkboxPackage from "./SpaceCheckbox/package";
import _cloudeUploaderPackage from "./SpaceCloudUploader/package";
import _contextParamPackage from "./SpaceContextParam/package";
import _customFieldPackage from "./SpaceCustomField/package";
import _dataViewerPackage from "./SpaceDataViewer/package";
import _dateTimePickerPackage from "./SpaceDateTimePicker/package";
import _detailPackage from "./SpaceDetail/package";
import _documentViewerPackage from "./SpaceDocumentViewer/package";
import _dropdownPackage from "./SpaceDropdown/package";
import _dynamicFunction from "./SpaceDynamicFunction/package";
import _environmentPackage from "./SpaceEnvironment/package";
import _filePickerPackage from "./SpaceFilePicker/package";
import _flexBoxPackage from "./SpaceFlexBox/package";
import _functionBulkImportPackage from "./SpaceFunctionBulkImport/package";
import _functionButtonPackage from "./SpaceFunctionButton/package";
import _functionFormPackage from "./SpaceFunctionForm/package";
import _functionHeadlessPackage from "./SpaceFunctionHeadless/package";
import _functionModalFormPackage from "./SpaceFunctionModalForm/package";
import _headerPackage from "./SpaceHeader/package";
import _iframePackage from "./SpaceIFrame/package";
import _imagePackage from "./SpaceImage/package";
import _jsonInputPackage from "./SpaceJsonInput/package";
import _linkPackage from "./SpaceLink/package";
import _modalPackage from "./SpaceModal/package";
import _spaceParamsPackage from "./SpaceParams/package";
import _progressPackage from "./SpaceProgress/package";
import _radioButtonPackage from "./SpaceRadioButton/package";
import _s3UploaderPackage from "./SpaceS3Uploader/package";
import _statPackage from "./SpaceStat/package";
import _tablePackage from "./SpaceTable/package";
import _tabsPackage from "./SpaceTabs/package";
import _tagSelectorPackage from "./SpaceTagSelector/package";
import _textAreaPackage from "./SpaceTextArea/package";
import _userPackage from "./SpaceUser/package";
import _videoPackage from "./SpaceVideo/package";
import _viewlessImagePackage from "./SpaceViewlessImage/package";
import _viewPackage from "./SubSpace/package";

const packages = [
  _accordionPackage,
  _caclulatedFieldPackage,
  _cardListPackage,
  _chartPackage,
  _checkboxPackage,
  _cloudeUploaderPackage,
  _contextParamPackage,
  _customFieldPackage,
  _spaceParamsPackage,
  _dataViewerPackage,
  _dateTimePickerPackage,
  _detailPackage,
  _documentViewerPackage,
  _dropdownPackage,
  _dynamicFunction,
  _environmentPackage,
  _filePickerPackage,
  _flexBoxPackage,
  _functionButtonPackage,
  _functionBulkImportPackage,
  _functionFormPackage,
  _functionHeadlessPackage,
  _functionModalFormPackage,
  _headerPackage,
  _iframePackage,
  _imagePackage,
  _jsonInputPackage,
  _linkPackage,
  _modalPackage,
  _radioButtonPackage,
  _progressPackage,
  _s3UploaderPackage,
  _statPackage,
  _tablePackage,
  _tabsPackage,
  _tagSelectorPackage,
  _textAreaPackage,
  _userPackage,
  _videoPackage,
  _viewlessImagePackage,
  _viewPackage,

  // pseudo component packages
  _cardPackage,
  _fieldsetPackage,
  _optionPackage,
  _rowPackage,
  _repeatedItemPackage
] as Array<SpaceComponentPackage | PseudoSpaceComponentPackage>;

export const registerPackages = () => {
  window.__INTERNAL_SPACE_COMPONENT_PACKAGES = new Set(packages);
};

export const getTestPackages = () => {
  registerPackages();
  return window.__INTERNAL_SPACE_COMPONENT_PACKAGES;
};

export const getTestPackagesMap = () =>
  Array.from(getTestPackages()).reduce<Map<SpaceComponentType, SpaceComponentPackage>>(
    (acc, curr) => {
      acc.set(curr.type, curr);
      return acc;
    },
    new Map()
  );

registerPackages();
