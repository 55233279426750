import React from "react";

import { MAX_VIEW_ROWS } from "../../../../../../../constants";
import { SourceType, ComponentConfigProps } from "../../../../../../../types";
import { findSpaceComponentPackage } from "../../../../SpaceContext/StableSpaceContext";
import BaseComponentConfigSection from "../../BaseComponentConfigSection";
import CollectionItemContainer from "../../CollectionItemContainer";
import ColumnOrder from "../../ColumnOrder";
import {
  useComponentConfigContext,
  ComponentConfigContextContainer
} from "../../ComponentConfigContext";
import { TerseComponentConfigContextContainer } from "../../ComponentConfigContext/ComponentConfigContext";
import ValidationError from "../../ComponentConfigContext/ValidationError";
import { ConfigSection } from "../../ConfigPanel";
import { Field, Alert } from "../../ConfigPanel/styledComponents";
import DefaultValueField from "../../DefaultValueField";
import FilterListManagerSection from "../../FilterListManager";
import HardcodedOptionFields from "../../HardcodedOptionFields";
import InputComponentNameFields from "../../InputComponentNameFields";
import ParametersConfigSection from "../../ParametersManager";
import SourceSelector from "../../SourceSelector";
import { DebouncedTemplateEditor, Height } from "../../TemplateEditor";
import useViewConfig from "../../useViewConfig";
import { hasSource } from "../../util";
import ValidationField from "../../ValidationField";
import { ValidationRulesSection } from "../../ValidationRulesConfigSection/ValidationRulesConfigSection";
import VisibilityRulesManagerSection from "../../VisibilityRulesManager";

import ConvertToStringField from "./ConvertToStringField";
import { ensureSelectConfigState } from "./reducer";

interface Props {
  isMulti: boolean;
  designConfig?: React.ReactNode;
}

export default function SelectComponentConfig({
  designConfig,
  slug,
  isMulti
}: ComponentConfigProps & Props) {
  return (
    <ComponentConfigContextContainer
      dataConfig={React.useMemo(
        () => (
          <SelectComponentConfigContent isMulti={isMulti} />
        ),
        [isMulti]
      )}
      designConfig={designConfig}
      slug={slug}
    />
  );
}

export function TerseConfig({ slug, isMulti }: ComponentConfigProps & Props) {
  return (
    <TerseComponentConfigContextContainer slug={slug}>
      <HardcodedOptionConfig />
      <DefaultValueField placeholder="${table1.selectedRow.data.id}" />
      {isMulti && <ConvertToStringField />}
      <ValidationField isTerse />
    </TerseComponentConfigContextContainer>
  );
}

export function InlineConfig({ slug, isMulti }: ComponentConfigProps & Props) {
  return (
    <TerseComponentConfigContextContainer slug={slug}>
      <InputComponentNameFields isInline />
      <HardcodedOptionConfig />
      <DefaultValueField placeholder="${table1.selectedRow.data.id}" />
      {isMulti && <ConvertToStringField />}
      <ValidationField isTerse />
    </TerseComponentConfigContextContainer>
  );
}

function HardcodedOptionConfig() {
  const context = useComponentConfigContext();
  const state = ensureSelectConfigState(context.state);
  const usesSourceData =
    state.draftComponent.sourceType !== undefined &&
    [SourceType.VIEW, SourceType.BINDING].includes(state.draftComponent.sourceType);
  return usesSourceData ? null : <HardcodedOptionFields />;
}

export function SelectComponentConfigContent({ isMulti }: Props) {
  const context = useComponentConfigContext();
  const state = ensureSelectConfigState(context.state);
  useViewConfig({
    state,
    dispatch: context.dispatch
  });

  const { draftComponent, filtersOptions } = state;
  const pkg = findSpaceComponentPackage(state.draftComponent.type);

  const { columns } = draftComponent.properties;

  const usesSourceData =
    state.draftComponent.sourceType !== undefined &&
    [SourceType.VIEW, SourceType.BINDING].includes(state.draftComponent.sourceType);

  return (
    <>
      <ConfigSection title="Source">
        <Field>
          <SourceSelector requiresSource={false} />
        </Field>
      </ConfigSection>
      <BaseComponentConfigSection>
        <InputComponentNameFields shouldRenderPlaceholder />
      </BaseComponentConfigSection>
      <ConfigSection title={`${pkg.displayName} Options`}>
        {usesSourceData ? (
          <CollectionItemContainer collectionKey="options">
            <Field>
              <label>Display Name per Option</label>
              <DebouncedTemplateEditor
                data-test="optionDisplayNameInput"
                value={state.draftComponent.properties.display_template}
                placeholder="User ${option.data.id}"
                minHeight={Height.Medium}
                onChange={template =>
                  context.dispatch({
                    type: "SET_DRAFT_COMPONENT",
                    payload: {
                      path: "properties.display_template",
                      value: template
                    }
                  })
                }
              />
              <ValidationError field="DISPLAY_TEMPLATE" />
            </Field>
            <Field>
              <label>Value per Option</label>
              <DebouncedTemplateEditor
                data-test="optionValueInput"
                value={state.draftComponent.properties.value_template}
                placeholder="${option.data.id}"
                minHeight={Height.Medium}
                onChange={template =>
                  context.dispatch({
                    type: "SET_DRAFT_COMPONENT",
                    payload: {
                      path: "properties.value_template",
                      value: template
                    }
                  })
                }
              />
              <ValidationError field="VALUE_TEMPLATE" />
            </Field>
          </CollectionItemContainer>
        ) : (
          <HardcodedOptionFields />
        )}
        <DefaultValueField placeholder="${table1.selectedRow.data.id}" />
        {isMulti && <ConvertToStringField />}
        <ValidationField />
        {usesSourceData && (
          <Field>
            <Alert
              message={
                <>
                  Only the first {MAX_VIEW_ROWS} records will be rendered as options.
                  Use filters to narrow down your data set or consider{" "}
                  <a
                    href="https://www.internal.io/docs/functions"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    creating a custom function
                  </a>{" "}
                  .
                </>
              }
              type="info"
              showIcon
            />
          </Field>
        )}
      </ConfigSection>
      {hasSource(state.draftComponent) && (
        <>
          <ParametersConfigSection title="Parameters" />
          <ConfigSection title="Sorting">
            <ColumnOrder sortByOptions={state.sortByOptions} />
          </ConfigSection>
          {!!columns && (
            <FilterListManagerSection title="Filters" filtersOptions={filtersOptions} />
          )}
        </>
      )}
      <ValidationRulesSection />
      <VisibilityRulesManagerSection />
    </>
  );
}
