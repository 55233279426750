import React from "react";

import { isEqual, cloneDeep } from "lodash";

import { SpaceComponentObject } from "../../../../types";
import ErrorBoundary from "../../../common/ErrorBoundary";
import withErrorBoundary from "../../../hoc/withErrorBoundary";
import debug from "../../../util/debug";
import { reportException } from "../../../util/exceptionReporting";
import { ElementLayout } from "../../layout/util";
import SpaceApi from "../../SpaceApi";
import { useSpaceConfigContext } from "../../SpaceConfig/SpaceConfigContext";
import { useStableSpaceContext } from "../SpaceContext";

import { SpaceInputFieldState } from "./common/useOutputSyncing/useOutputSyncing";
import { ComponentContextContainer } from "./contexts/ComponentContext";
import {
  ComponentStateContainer,
  useComponentStateContext
} from "./contexts/ComponentStateContext";
import LayoutComponent from "./LayoutComponent";
import { SpaceAccordionState } from "./SpaceAccordion/types";
import { SpaceDetailState } from "./SpaceDetail/types";
import { SpaceFunctionState } from "./SpaceFunction";
import { SpaceParamsState } from "./SpaceParams";
import { SpaceTableState } from "./SpaceTable";
import { SpaceUserState } from "./SpaceUser/types";

export interface SpaceTableRowState {
  data: any;
}

// This defines all the different shapes of state that the various components can expose.
export type SpaceComponentState =
  | SpaceTableState
  | SpaceInputFieldState
  | SpaceTableRowState
  | SpaceFunctionState
  | SpaceDetailState
  | SpaceParamsState
  | SpaceUserState
  | SpaceAccordionState
  | null; // | FutureSpaceComponentState …

export type StateTree = Record<string, SpaceComponentState>;

export type SpaceStateInputs = { [bindingPath: string]: any };

export interface Props {
  spaceComponent: SpaceComponentObject;
  spaceApi: SpaceApi;
  hasConfigError?: boolean;
  layoutConstraints?: { width: number; height: number };
}

function areSubTreesEqual(a: SpaceComponentObject, b: SpaceComponentObject): boolean {
  if (
    a.componentTreeNodes.length !== b.componentTreeNodes.length ||
    !a.componentTreeNodes.every((ctn, i) =>
      areComponentsEqual(ctn, b.componentTreeNodes[i])
    )
  ) {
    return false;
  }
  return true;
}

// Compare space components recursively
type K = keyof SpaceComponentObject;
export function areComponentsEqual(a: SpaceComponentObject, b: SpaceComponentObject) {
  for (const key in a) {
    if (key === "container") {
      if (a.container?.slug !== b.container?.slug) {
        return false;
      }
    } else if (key === "componentTreeNodes") {
      if (!areSubTreesEqual(a, b)) {
        return false;
      }
    } else if (a[key as K] !== b[key as K] && !isEqual(a[key as K], b[key as K])) {
      return false;
    }
  }
  return true;
}

const comparisonFunc = (prevProps: Props, nextProps: Props) => {
  const propsToCheck = [
    "spaceApi",
    "spaceComponent",
    "hasConfigError",
    "layoutConstraints"
  ];
  return (propsToCheck as Array<keyof Props>).every(key => {
    let propsEqual = prevProps[key] === nextProps[key];
    if (!propsEqual && key !== "spaceComponent") {
      propsEqual = isEqual(prevProps[key], nextProps[key]);
    } else if (!propsEqual && key === "spaceComponent") {
      propsEqual = areComponentsEqual(
        prevProps.spaceComponent,
        nextProps.spaceComponent
      );
    }
    if (!propsEqual) {
      debug(
        `Component memoization invalidated by key: ${key}`,
        `\n - Last prop.${key}`,
        prevProps[key],
        `\n - Next prop.${key}`,
        nextProps[key]
      );
    }
    return propsEqual;
  });
};

type SpaceComponentHash = {
  [key: string]: React.FunctionComponent<Props>;
};

let memoizedComponents: SpaceComponentHash;
const getMemoizedComponents = (): SpaceComponentHash => {
  if (memoizedComponents) return memoizedComponents;

  memoizedComponents = Array.from(window.__INTERNAL_SPACE_COMPONENT_PACKAGES).reduce(
    (memo, { type, Component }) => ({
      ...memo,
      [type]: React.memo(Component as React.FunctionComponent<Props>, comparisonFunc)
    }),
    {}
  );
  return memoizedComponents;
};

function SpaceComponent(props: Props) {
  const { shouldDisplayError, state, getComponentSpaceConfig, getComponentConfig } =
    useSpaceConfigContext();
  const { editMode, packagesRegistered } = useStableSpaceContext();
  const spaceComponent: SpaceComponentObject | undefined = React.useMemo(() => {
    if (!editMode) return props.spaceComponent;
    if (!props.spaceComponent) return undefined;
    // HACK: Try / catch is to prevent a transient error when a component which is
    //       a child of a FLEX_BOX which is a child of a TABLE gets deleted.
    //       The issue is that the TABLE manages child component definitions
    //       through an effect, and their is a render where the definition has not
    //       been updated, but the component is already removed from config.
    try {
      const componentSpaceConfig = getComponentSpaceConfig(props.spaceComponent.slug);
      const component = getComponentConfig(props.spaceComponent.slug);
      const layout = componentSpaceConfig.elementLayouts.get(props.spaceComponent.slug);
      return {
        ...(component?.draftComponent as SpaceComponentObject),
        layout: new ElementLayout(layout),
        componentTreeNodes: props.spaceComponent.componentTreeNodes,
        container: props.spaceComponent.container
      };
    } catch (e) {
      return undefined;
    }
  }, [editMode, props.spaceComponent, getComponentSpaceConfig, getComponentConfig]);

  const hasConfigError =
    editMode && spaceComponent && shouldDisplayError(spaceComponent.slug);

  if (!spaceComponent) return null;
  if (!packagesRegistered) return null;

  // https://sentry.io/organizations/internal/issues/2792757161/?environment=secure.internal.io&project=1517743&query=is%3Aunresolved&statsPeriod=7d
  if (spaceComponent === undefined) {
    const configState = JSON.stringify(cloneDeep(state), (key, value) => {
      if (key === "container") return undefined;
      return value;
    });
    reportException(new Error("Expected spaceComponent to be present."), {
      extra: {
        configState
      }
    });
    return null;
  }

  return (
    <ErrorBoundary>
      <ComponentContextContainer component={spaceComponent}>
        <ComponentStateContainer component={spaceComponent}>
          <MemoComponent
            {...props}
            spaceComponent={spaceComponent}
            hasConfigError={hasConfigError}
          />
        </ComponentStateContainer>
      </ComponentContextContainer>
    </ErrorBoundary>
  );
}

export default withErrorBoundary(SpaceComponent);

function MemoComponent(props: Props) {
  const { input, componentNode } = useComponentStateContext();
  const propsWithContextState = {
    ...props,
    input,
    output: componentNode?.output
  };
  const MemoComponent = getMemoizedComponents()[props.spaceComponent.type];

  return (
    <LayoutComponent component={props.spaceComponent}>
      <MemoComponent {...propsWithContextState} />
    </LayoutComponent>
  );
}
