import gql from "graphql-tag";

import { ReturnSchema } from "../../../../../constants";
import {
  RelayNode,
  APIFiltersOption,
  ViewNode,
  APISortByOption,
  FunctionParameterNode,
  Connection,
  FunctionAttributeNode
} from "../../../../../types";
import {
  FUNCTION_ATTRIBUTE_FRAGMENT,
  FUNCTION_PARAMETER_FRAGMENT
} from "../../../../common/FunctionEditor/useFunctionEditor/queries";

const VIEW_FRAGMENT = gql`
  fragment ViewFragment on ViewNode {
    id
    function {
      id
      name
    }
  }
`;

interface _Metadata {
  filters: { options: APIFiltersOption[] };
  sortBy: { options: APISortByOption[] };
}

interface _DataSourceNode extends RelayNode {
  name: string;
  autoSyncEnabled: boolean;
}

export interface ConfigFunctionNode extends RelayNode {
  metadata: _Metadata;
  name: string;
  title: string;
  returnSchema: ReturnSchema;
  access?: {
    __typename: "FunctionAccess";
    onlyParameters: string[] | null;
    onlyAttributes: string[] | null;
  };
  dataSource?: _DataSourceNode;
  functionAttributes: Connection<FunctionAttributeNode>;
  functionParameters: Connection<FunctionParameterNode>;
}

export interface ConfigFunctionData {
  node: ConfigFunctionNode;
  view?: ViewNode;
}

export const CONFIG_FUNCTION_QUERY = gql`
  query FunctionForManageTableConfig($functionId: ID!, $viewId: ID!) {
    node(id: $functionId) {
      ... on FunctionNode {
        id
        metadata
        name
        title
        returnSchema
        access {
          onlyParameters
          onlyAttributes
        }
        dataSource {
          id
          name
          autoSyncEnabled
        }
        functionParameters {
          edges {
            node {
              ...FunctionParameterFragment
            }
          }
        }
        functionAttributes {
          edges {
            node {
              ...FunctionAttributeFragment
            }
          }
        }
      }
    }
    view(id: $viewId) {
      ...ViewFragment
    }
  }
  ${VIEW_FRAGMENT}
  ${FUNCTION_PARAMETER_FRAGMENT}
  ${FUNCTION_ATTRIBUTE_FRAGMENT}
`;

export const CREATE_FUNCTION_VIEW = gql`
  mutation CreateFunctionView($functionId: ID!, $functionAttributeIds: [ID]!) {
    createFunctionView(
      functionId: $functionId
      functionAttributeIds: $functionAttributeIds
    ) {
      ok
      view {
        ...ViewFragment
      }
    }
  }
  ${VIEW_FRAGMENT}
`;
