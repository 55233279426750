import { Button as AntButton } from "antd";
import styled from "styled-components";

export const Title = styled.div`
  font-size: 18px;
  margin-bottom: ${props => props.theme.spacermd};
`;

export const PreviewFormPane = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 300px;
  overflow-y: auto;
  height: 100%;
  padding: ${props => props.theme.spacermd};
  background-color: ${props => props.theme.headerBackgroundColor};
`;

export const RunButton = styled(AntButton)`
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const Instructions = styled.div`
  margin-bottom: ${props => props.theme.spacermd};
`;
