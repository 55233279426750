import { useQuery } from "@apollo/react-hooks";
import { QueryHookOptions } from "@apollo/react-hooks/lib/types";
import gql from "graphql-tag";

import { Connection, RelayNode } from "../../../../types";

import { AutomationNode, FunctionNode, ExecutionStatus, UserNode } from "./common";

interface AuditLogNode extends RelayNode {
  id: string;
  user: UserNode;
  automation: AutomationNode;
  function: FunctionNode;
  createdAt: string;
  action: string;
  executionStatus: ExecutionStatus;
}

export interface Data {
  allAuditLogs: Connection<AuditLogNode>;
}

export const GET_AUDIT_LOGS_QUERY = gql`
  query GetAutomationLogs(
    $user: ID
    $automation: ID
    $environment: ID
    $cursor: String
  ) {
    allAuditLogs(
      user: $user
      automation: $automation
      environment: $environment
      first: 10
      after: $cursor
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          user {
            id
            firstName
            lastName
          }
          automation {
            id
            name
          }
          function {
            id
            name
            title
            dataSource {
              id
              integration
              name
            }
          }
          createdAt
          action
          executionStatus
        }
      }
    }
  }
`;

export const useActivityLogs = (options?: QueryHookOptions<Data>) => {
  return useQuery<Data>(GET_AUDIT_LOGS_QUERY, options);
};

export const loadMore = (
  automationId: string,
  environmentId: string,
  fetchMore: (p: any) => any,
  cursor: string
) => {
  fetchMore({
    variables: {
      cursor: cursor,
      automation: automationId,
      environment: environmentId
    },
    updateQuery: (
      previousResult: any,
      { fetchMoreResult }: { fetchMoreResult: any }
    ) => {
      const newEdges = fetchMoreResult.allAuditLogs.edges;
      const pageInfo = fetchMoreResult.allAuditLogs.pageInfo;
      return newEdges.length
        ? {
            ...previousResult,
            allAuditLogs: {
              ...previousResult.allAuditLogs,
              edges: [...previousResult.allAuditLogs.edges, ...newEdges],
              pageInfo
            }
          }
        : previousResult;
    }
  });
};
