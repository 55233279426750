import React, { useRef, useState } from "react";

import { Button } from "antd";
import styled from "styled-components";

import Message from "../../../../common/Message";
import { useStableSpaceContext } from "../../SpaceContext";
import Panel from "../common/Panel";
import useSignedUrl from "../common/useSignedUrl";
import { useComponentStateContext } from "../contexts/ComponentStateContext";
import { Props } from "../SpaceComponent";

const DefaultScale = 1.0;

const Root = styled(Panel)`
  header {
    flex-wrap: wrap;
    padding: 15px;
  }

  h2 {
    flex-grow: 1;
    margin: 5px;
  }

  position: relative;
`;
Root.displayName = "Panel";

const HeaderRow = styled.div`
  display: flex;
`;

const HeaderRowButtons = styled.span`
  margin-right: 34px;
`;

const HeaderRowPageCounter = styled.span`
  margin-top: auto;
  margin-bottom: auto;
`;

const PDFRow = styled.div`
  width: 100%;
  height: calc(100% - 37px); // 37px was the min value to prevent overflow
`;

const PDFBody = styled.iframe`
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const PreviousPageButton = styled(Button)`
  "position": "absolute";
  "top": 0;
  "left": 0;
`;

const NextPageButton = styled(Button)`
  "position": "absolute";
  "top": 0;
  "left": 32px;
`;

const ZoomOutButton = styled(Button)`
  "position": "absolute";
  "top": 0;
  "left": 64px;
`;

const ZoomInButton = styled(Button)`
  "position": "absolute";
  "top": 0;
  "left": 96px;
`;

interface PDFProperties {
  pageLoaded: boolean;
  pageNumber: number;
  numPages: number;
  scale: number;
}

interface PostMessage {
  type: string;
  data: Record<string, any>;
}

const getInitialState = (): PDFProperties => ({
  pageLoaded: false,
  pageNumber: 1,
  numPages: 1,
  scale: DefaultScale
});

export default function SpaceDocumentViewer(props: Props) {
  const { spaceComponent } = props;
  const { input } = useComponentStateContext();

  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const channel = useRef(new MessageChannel());

  const [pdfProperties, setPDFProperties] = useState(getInitialState());
  const [isRendering, setIsRendering] = useState(false);

  const { editMode } = useStableSpaceContext();
  const { url } = useSignedUrl(spaceComponent, input, true);

  const previousPage = () =>
    setPDFProperties(pdfProperties => ({
      ...pdfProperties,
      pageNumber: Math.max(pdfProperties.pageNumber - 1, 1)
    }));
  const nextPage = () =>
    setPDFProperties(pdfProperties => ({
      ...pdfProperties,
      pageNumber: Math.min(pdfProperties.pageNumber + 1, pdfProperties.numPages)
    }));
  const zoomOut = () =>
    setPDFProperties(pdfProperties => ({
      ...pdfProperties,
      scale: Math.max(pdfProperties.scale - 0.1, 0.1)
    }));
  const zoomIn = () =>
    setPDFProperties(pdfProperties => ({
      ...pdfProperties,
      scale: pdfProperties.scale + 0.1
    }));

  const handleMessageError = (event: MessageEvent) => {
    throw new Error("channel error: " + event);
  };

  const handleMessage = React.useCallback((event: MessageEvent) => {
    const { type, data }: PostMessage = event.data;
    if (type === "exception") {
      Message.error("PDF failed to load: " + data.reason);
      return;
    }
    if (type === "document-loaded") {
      setPDFProperties(pdfProperties => ({
        ...pdfProperties,
        pageLoaded: true,
        numPages: data.numPages
      }));
    } else if (type === "page-rendered") {
      setIsRendering(false);
    }
  }, []);

  const sendMessage = React.useCallback(
    (message: PostMessage) => {
      const newChannel = new MessageChannel();
      newChannel.port1.onmessage = handleMessage;
      newChannel.port1.onmessageerror = handleMessageError;
      channel.current = newChannel;

      iframeRef.current?.contentWindow?.postMessage(message, window.location.origin, [
        channel.current.port2
      ]);
    },
    [handleMessage]
  );

  React.useEffect(() => {
    if (url === "" || url === "undefined") {
      return;
    }
    setPDFProperties(getInitialState());
    sendMessage({ type: "load-url", data: { url } });
  }, [url, sendMessage]);

  React.useEffect(() => {
    if (!pdfProperties.pageLoaded) {
      return;
    }
    setIsRendering(true);
    sendMessage({ type: "render-page", data: pdfProperties });
  }, [pdfProperties, sendMessage]);

  return (
    <Root title={spaceComponent.properties.title} hasError={props.hasConfigError}>
      <HeaderRow>
        <HeaderRowButtons>
          <PreviousPageButton
            onClick={previousPage}
            disabled={
              !pdfProperties.pageLoaded || isRendering || pdfProperties.pageNumber === 1
            }
            icon="caret-left"
          />
          <NextPageButton
            onClick={nextPage}
            disabled={
              !pdfProperties.pageLoaded ||
              isRendering ||
              pdfProperties.pageNumber === pdfProperties.numPages
            }
            icon="caret-right"
          />
          <ZoomOutButton
            onClick={zoomOut}
            disabled={
              !pdfProperties.pageLoaded || isRendering || pdfProperties.scale <= 0.1
            }
            icon="zoom-out"
          />
          <ZoomInButton
            onClick={zoomIn}
            disabled={!pdfProperties.pageLoaded || isRendering}
            icon="zoom-in"
          />
        </HeaderRowButtons>
        {pdfProperties.pageLoaded && (
          <HeaderRowPageCounter>
            Page {pdfProperties.pageNumber} of {pdfProperties.numPages}
          </HeaderRowPageCounter>
        )}
      </HeaderRow>
      <PDFRow>
        <PDFBody
          title="Document Viewer Sandbox"
          ref={iframeRef}
          sandbox="allow-scripts allow-same-origin"
          src="/documentViewer.html?cb=1"
          style={{ pointerEvents: editMode ? "none" : "auto" }}
        ></PDFBody>
      </PDFRow>
    </Root>
  );
}
