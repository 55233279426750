import React from "react";

import { Icon } from "antd";
import { ModalProps } from "antd/lib/modal/Modal";
import styled from "styled-components";

import { ModalNew } from "../../../../common/Modal";
import useSyncSpaceScm from "../../Header/ScmBanner/useSyncSpaceScm";

import reducer, { ActionType, getInitialState } from "./reducer";
import useScmSyncStatus from "./useScmSyncStatus";
import { getHelper } from "./util";

const SpinIcon = styled(Icon)`
  margin-left: 10px;
`;

interface Props {
  spaceId: string;
  branch: string;
  onCancel?: () => void;
  onError?: () => void;
  onSuccess?: () => void;
}

export default function SyncModal({
  spaceId,
  branch,
  onCancel = () => null,
  onError = () => null,
  onSuccess = () => null
}: Props) {
  const [state, dispatch] = React.useReducer(reducer, getInitialState());

  useScmSyncStatus({
    fetchPolicy: "network-only",
    variables: { spaceId },
    onCompleted: data =>
      dispatch({ type: ActionType.LOAD_SYNC_STATUS, payload: data.node.scmSyncStatus }),
    onError
  });

  const [_sync] = useSyncSpaceScm({
    variables: { spaceId, branch },
    onCompleted: () => {
      dispatch({ type: ActionType.SYNC_FINISHED });
      onSuccess();
    },
    onError: () => {
      dispatch({ type: ActionType.SYNC_FINISHED });
      onError();
    }
  });

  const sync = React.useCallback(() => {
    dispatch({ type: ActionType.BEGIN_SYNC });
    _sync();
  }, [_sync]);

  React.useEffect(() => {
    if (state.syncImmediately) sync();
  }, [state.syncImmediately, sync]);

  const helper = getHelper(state.syncStatus);

  const modalProps: Partial<ModalProps> = !state.initializing
    ? {
        confirmLoading: state.isSyncing,
        okText: state.isSyncing ? "Syncing" : helper.okText,
        onOk: () => {
          if (!helper.canSync) return onCancel();
          sync();
        },
        onCancel: () => onCancel(),
        title: "Sync versions"
      }
    : {};

  return (
    <ModalNew visible {...modalProps}>
      {state.initializing ? (
        <>
          {state.isChecking && "Checking remote"}
          {state.isSyncing && "Sync in progress"}
          <SpinIcon type="loading" spin />
        </>
      ) : (
        <>{helper.content}</>
      )}
    </ModalNew>
  );
}
