import React from "react";

import { ComponentConfigProps } from "../../../../../../types";
import { ButtonTextConfig } from "../../common/ButtonDesignConfig";
import ButtonDesignConfig from "../../common/ButtonDesignConfig/ButtonDesignConfig";
import { ItemContainer } from "../../common/CollectionItemContainer/CollectionItemContainer";
import {
  ComponentConfigContextContainer,
  useComponentConfigContext
} from "../../common/ComponentConfigContext";
import { ConfigSection } from "../../common/ConfigPanel";
import {
  AUTO_UPDATE_BY_KEY,
  DISPLAY_MESSAGE,
  REFRESH_COMPONENT
} from "../../common/effects/Effects";
import { MessageType } from "../../common/effects/Effects/DisplayMessage";
import EffectsManager from "../../common/effects/EffectsManager/EffectsManager";
import { SubmittableEventType } from "../../common/effects/useSubmittableEffects/useSubmittableEffects";
import FunctionConfig from "../../common/FunctionBackedPopover/FunctionConfig";
import {
  ensureSubmittableComponent,
  ensureSubmittableComponentConfigState
} from "../../common/FunctionBackedPopover/reducer/reducer";
import NameFields from "../../common/NameFields";
import ParametersConfigSection from "../../common/ParametersManager";
import VisibilityRulesManagerSection from "../../common/VisibilityRulesManager";
import { SpaceCloudUploaderComponent } from "../types";

export function ensureComponent(
  sc: SpaceCloudUploaderComponent
): SpaceCloudUploaderComponent {
  // ensureSubmittableComponent mutates sc and defaults effects
  ensureSubmittableComponent(sc);

  // initialize button text if neither text nor icon is provided
  if (!sc.properties.open_uploader_button_text && !sc.properties.button_icon) {
    sc.properties.open_uploader_button_text = "Upload";
  }
  return sc;
}

export default function Config({ slug }: ComponentConfigProps) {
  const dataConfig = React.useMemo(() => <CloudUploaderContent />, []);
  const effectsConfig = React.useMemo(() => <EffectsConfig />, []);
  const designConfig = React.useMemo(() => <ButtonDesignConfig />, []);
  return (
    <ComponentConfigContextContainer
      dataConfig={dataConfig}
      designConfig={designConfig}
      effectsConfig={effectsConfig}
      slug={slug}
    />
  );
}

export function CloudUploaderContent() {
  const context = useComponentConfigContext();
  const state = ensureSubmittableComponentConfigState(context.state);
  const { dispatch } = context;
  const {
    properties: { open_uploader_button_text, button_icon }
  } = state.draftComponent;

  return (
    <>
      <FunctionConfig
        categories={["signurl"]}
        title="Presign Function"
        integrations={["gcs"]}
        state={state}
        dispatch={dispatch}
      />
      <ConfigSection title="Cloud Uploader Details">
        <NameFields
          namePlaceholder="Cloud Uploader"
          labelPlaceholder="Actions"
          titleLabel="Form Name"
          titlePlaceholder="Upload File"
          shouldRenderTitle={true}
        />
        <ButtonTextConfig
          placeholder="Upload"
          button_icon={button_icon}
          button_text={open_uploader_button_text}
          onIconChange={value =>
            dispatch({
              type: "SET_DRAFT_COMPONENT",
              payload: {
                path: "properties.button_icon",
                value
              }
            })
          }
          onTextChange={value =>
            dispatch({
              type: "SET_DRAFT_COMPONENT",
              payload: {
                path: "properties.open_uploader_button_text",
                value
              }
            })
          }
        />
      </ConfigSection>
      {/* always render ParametersConfigSection so that component cleanup can occur when data source changes and functionId is undefined */}
      <ItemContainer itemKey="fieldset">
        <ParametersConfigSection title="Fields" />
      </ItemContainer>
      <VisibilityRulesManagerSection />
    </>
  );
}

export const CLOUD_UPLOADER_EVENTS = [
  {
    name: "On Successful Submit",
    type: SubmittableEventType.SUBMIT_SUCCESS,
    effects: [DISPLAY_MESSAGE, REFRESH_COMPONENT, AUTO_UPDATE_BY_KEY],
    defaultEffect: {
      type: "refresh_component" as const,
      options: {
        component: undefined
      }
    }
  },
  {
    name: "On Failed Submit",
    type: SubmittableEventType.SUBMIT_FAILURE,
    effects: [DISPLAY_MESSAGE],
    defaultEffect: {
      type: "display_message" as const,
      options: {
        type: MessageType.ERROR,
        message: "Something went wrong. Please try again."
      }
    }
  }
];

export const EffectsConfig = () => {
  return <EffectsManager eventOptions={CLOUD_UPLOADER_EVENTS} />;
};
