import React from "react";

import { Tooltip } from "antd";
import styled from "styled-components";

import HeartFilled from "../../../../assets/icons/heart-filled.svg";
import HeartOutline from "../../../../assets/icons/heart-outline.svg";
import ButtonNew from "../../../common/ButtonNew/ButtonNew";
import useAuthUser from "../../../common/hooks/useAuthUser";
import usePaths from "../../../common/hooks/usePaths";
import useScmConfig from "../../../common/hooks/useScmConfig";
import useSearchParamsUpdater from "../../../common/hooks/useSearchParamsUpdater";
import {
  H6,
  Hr,
  IconButton,
  P,
  SecondaryButtonList,
  TinyLightText
} from "../../../common/StyledComponents";
import withErrorBoundary from "../../../hoc/withErrorBoundary";
import copyToClipboard from "../../../util/copyToClipboard";
import {
  SpaceTileNode,
  useSpacesContext
} from "../../hooks/useSpacesManager/useSpacesManager";
import { useBranchContext } from "../../SpaceRoot/SpaceContext/BranchContext";
import { emptyFilter } from "../SpaceFilters";

import CloneSpaceModal from "./CloneSpaceModal";
import ConfigureSpaceModal from "./ConfigureSpaceModal";
import DeleteSpaceModal from "./DeleteSpaceModal";
import ManageScm from "./ManageScm";
import SpaceVisibility from "./SpaceVisibility";

const UpdatedAtNotice = styled(TinyLightText)`
  display: block;
  margin-bottom: 2em;
`;

const StyledButton = styled(ButtonNew)`
  margin: 6px 0;
`;

const StyledHeartFilled = styled(HeartFilled)`
  cursor: pointer;
  margin: 0 11px;
`;

const StyledHeartOutline = styled(HeartOutline)`
  cursor: pointer;
  margin: 0 11px;
`;

const StyledScmContainer = styled.div`
  margin: 8px 0 24px;
`;

export default function SpaceDetail({
  space,
  onFavorite,
  onUnfavorite
}: {
  space: SpaceTileNode;
  onFavorite: (spaceId: string, refetch?: boolean) => void;
  onUnfavorite: (spaceId: string) => void;
}) {
  const config = useScmConfig();

  return (
    <div>
      <SpaceInfo space={space} onFavorite={onFavorite} onUnfavorite={onUnfavorite} />
      {config?.id && (
        <StyledScmContainer>
          <ManageScm space={space} />
        </StyledScmContainer>
      )}
      <SpaceVisibility slug={space.slug} />
    </div>
  );
}

const TOOLTIP_DISPLAY_LENGTH = 1500;

const SpaceInfo = withErrorBoundary(
  ({
    space,
    onFavorite,
    onUnfavorite
  }: {
    space: SpaceTileNode;
    onFavorite: (spaceId: string, refetch?: boolean) => void;
    onUnfavorite: (spaceId: string) => void;
  }) => {
    const [openModal, setOpenModal] = React.useState<
      null | "CONFIGURE_SPACE" | "CLONE_SPACE" | "DELETE_SPACE"
    >(null);
    const { updateSpaceListQuery } = useSpacesContext();
    const { branch } = useBranchContext();
    const [favoriteTooltip, setFavoriteTooltip] = React.useState<null | string>(null);
    const [showCopiedTooltip, setShowCopiedTooltip] = React.useState(false);
    const { isAdmin, isSpaceAdmin } = useAuthUser();
    const { getSpace, getEditSpace } = usePaths();
    const viewSpaceUrl = getSpace(space.slug, { branch });
    const canManageSpaces = isAdmin || isSpaceAdmin;
    const updateSearchParams = useSearchParamsUpdater();
    const onClone = (spaceId: string, slug: string) => {
      onFavorite(spaceId, true);
      updateSpaceListQuery("REFETCH");
      updateSearchParams({
        ...emptyFilter,
        selectedSpace: slug,
        onlyUnpublished: true
      });
    };

    return (
      <div>
        <H6 data-test="metaDataSpaceName">{space.name}</H6>
        <UpdatedAtNotice>
          Last updated {new Date(space.touchedAt).toLocaleDateString()}
        </UpdatedAtNotice>
        {space.description && <P data-test="metaDataSpaceDesc">{space.description}</P>}
        <SecondaryButtonList>
          {canManageSpaces && (
            <IconButton
              title="Update space settings"
              type="link"
              shape="circle"
              icon="setting"
              onClick={() => {
                setOpenModal("CONFIGURE_SPACE");
              }}
            />
          )}
          <Tooltip trigger="click" title={favoriteTooltip} visible={!!favoriteTooltip}>
            {space.isFavorite ? (
              <StyledHeartFilled
                title="Remove from favorites"
                onClick={() => {
                  onUnfavorite(space.id);
                  setFavoriteTooltip("Removed favorite");
                  setTimeout(() => {
                    setFavoriteTooltip(null);
                  }, TOOLTIP_DISPLAY_LENGTH);
                }}
              />
            ) : (
              <StyledHeartOutline
                title="Add space to favorites"
                onClick={() => {
                  onFavorite(space.id);
                  setFavoriteTooltip("Added favorite");
                  setTimeout(() => {
                    setFavoriteTooltip(null);
                  }, TOOLTIP_DISPLAY_LENGTH);
                }}
              />
            )}
          </Tooltip>
          <Tooltip trigger="click" title="Link copied" visible={showCopiedTooltip}>
            <IconButton
              title="Copy link to space"
              type="link"
              shape="circle"
              icon="link"
              onClick={() => {
                copyToClipboard(window.location.origin + viewSpaceUrl);
                setShowCopiedTooltip(true);
                setTimeout(() => {
                  setShowCopiedTooltip(false);
                }, TOOLTIP_DISPLAY_LENGTH);
              }}
            />
          </Tooltip>
          {canManageSpaces && (
            <>
              <IconButton
                title="Duplicate space"
                type="link"
                shape="circle"
                icon="copy"
                onClick={() => {
                  setOpenModal("CLONE_SPACE");
                }}
              />
              <IconButton
                title="Delete space"
                type="link"
                shape="circle"
                icon="delete"
                onClick={() => {
                  setOpenModal("DELETE_SPACE");
                }}
              />
            </>
          )}
        </SecondaryButtonList>
        <StyledButton size="sm" type="primary" block to={viewSpaceUrl}>
          Open space
        </StyledButton>
        {canManageSpaces && (
          <StyledButton
            size="sm"
            type="secondary"
            block
            to={getEditSpace(space.slug, { branch })}
          >
            Edit space
          </StyledButton>
        )}
        <Hr />
        {openModal === "CONFIGURE_SPACE" && (
          <ConfigureSpaceModal space={space} onCancel={() => setOpenModal(null)} />
        )}
        {openModal === "CLONE_SPACE" && (
          <CloneSpaceModal
            space={space}
            onClone={onClone}
            onCancel={() => setOpenModal(null)}
          />
        )}
        {openModal === "DELETE_SPACE" && (
          <DeleteSpaceModal space={space} onCancel={() => setOpenModal(null)} />
        )}
      </div>
    );
  }
);
