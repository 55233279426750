import React from "react";

import { Spin } from "antd";

import { WarningTooltip } from "../../../common/ToolTips";
import { StyledIcon } from "../common/styledComponents";

import {
  AnalyticsAction,
  CenterCardContent,
  Header,
  Root,
  Space,
  Title,
  UpsellBadge
} from "./styledComponents";

export const DEFAULT_ERROR_MESSAGE = "Something went wrong. Please try again.";

interface Props {
  title: string;
  headerActionText?: string;
  loading?: boolean;
  className?: string;
  children: React.ReactNode;
  errorMessage?: string;
  showUpsell?: boolean;
  onHeaderActionClick?: () => void;
}

export default function AnalyticCard({
  title,
  headerActionText,
  children,
  showUpsell,
  loading,
  className,
  errorMessage,
  onHeaderActionClick = () => null
}: Props) {
  return (
    <Root className={className}>
      <Header>
        <Title>{title}</Title>
        {showUpsell && <UpsellBadge />}
        {headerActionText && (
          <>
            <Space />
            <AnalyticsAction onClick={() => onHeaderActionClick()}>
              {headerActionText}
            </AnalyticsAction>
          </>
        )}
      </Header>
      {!loading && errorMessage && (
        <CenterCardContent>
          <WarningTooltip
            message={""}
            description="Something went wrong. Please try again."
            placement="right"
          >
            <StyledIcon type="warning" />
          </WarningTooltip>
        </CenterCardContent>
      )}
      {!loading && !errorMessage && <>{children}</>}
      {loading && (
        <CenterCardContent>
          <Spin />
        </CenterCardContent>
      )}
    </Root>
  );
}
