import { SpaceComponentObject } from "../../../../../types";
import { SpaceDefinition } from "../../../types";
import { ComponentNode } from "../../RenderTreeContext";
import { getChildrenSchema } from "../util";

export const getSchema = (
  componentNode: ComponentNode,
  spaces: Map<string, SpaceDefinition>
) =>
  getChildrenSchema(
    componentNode,
    (componentNode.component.componentTreeNodes as SpaceComponentObject[]).map(
      ctn => ctn.slug
    ),
    spaces
  );
