import { useMemo } from "react";

import { useSpaceContext } from "./SpaceRoot/SpaceContext/SpaceContext";

export const KEY_MAP = {
  DELETE_COMPONENT: ["del", "backspace"],
  DEBUG_MODE: ["ctrl+i"]
};

export const useHotKeyHandlers = () => {
  const { toggleDebugMode } = useSpaceContext();

  const handlers = useMemo(() => {
    return {
      DEBUG_MODE: toggleDebugMode
    };
  }, [toggleDebugMode]);

  return handlers;
};
