import React from "react";

import { useMutation } from "@apollo/react-hooks";
import { Input } from "antd";
import styled from "styled-components";

import { GlobalStyleVariables } from "../../../../cssConstants";
import Message from "../../../common/Message";
import { ModalNew } from "../../../common/Modal";
import { SpaceTileNode } from "../../hooks/useSpacesManager/useSpacesManager";

import { CloneSpaceData, CloneSpaceVariables, CLONE_SPACE } from "./queries";

const Label = styled.label``;

const StyledInput = styled(Input)`
  margin-bottom: ${props => props.theme.spacermd};
`;

export default function CloneSpaceModal({
  space,
  onCancel,
  onClone
}: {
  space: SpaceTileNode;
  onCancel: () => void;
  onClone: (spaceId: string, slug: string) => void;
}) {
  const [name, setName] = React.useState(
    space.name.endsWith(" (Copy)") ? space.name : space.name + " (Copy)"
  );
  const [cloneSpace, { loading }] = useMutation<CloneSpaceData, CloneSpaceVariables>(
    CLONE_SPACE,
    {
      onCompleted: data => {
        const { id, slug } = data.spaceClone.space;
        onClone(id, slug);
        Message.success("The space has been copied.");
      },
      onError: _ => {
        Message.error("Something went wrong. Please try again.");
      }
    }
  );
  return (
    <ModalNew
      visible
      title="Duplicate Space"
      width={GlobalStyleVariables.confirmModal}
      okText="Save"
      confirmLoading={loading}
      onOk={() => {
        cloneSpace({ variables: { spaceId: space.id, name } });
      }}
      onCancel={onCancel}
    >
      <Label>
        Name
        <StyledInput
          data-test="spaceNameInput"
          value={name}
          maxLength={1000}
          onChange={ev => {
            setName(ev.target.value);
          }}
        />
      </Label>
    </ModalNew>
  );
}
