import { useMutation } from "@apollo/react-hooks";
import { MutationHookOptions } from "@apollo/react-hooks/lib/types";
import gql from "graphql-tag";

import { IntervalPeriod } from "../types";

import { AutomationNode, AUTOMATION_NODE_FRAGMENT } from "./common";

export interface ValidationErrorMessage {
  text: string;
  index: string[];
}

export interface ValidationErrorResult {
  __typename: "ValidationErrorResult";
  messages: ValidationErrorMessage[];
}

export type SaveAutomationResult = AutomationNode | ValidationErrorResult;

export type Data = { saveAutomation: SaveAutomationResult };

export interface AutomationInput {
  id?: string;
  name: string;
  description: string;
  icon?: string;
  color?: string;
  intervalPeriod: IntervalPeriod;
  frequency: number;
  daysOfWeek: number[];
  daysOfMonth: number[];
  triggerTime: string | undefined;
  startAt: string | undefined;
  endAt: string | undefined;
  functionId: string;
  environmentIds: string[];
}

interface Variables {
  automation: AutomationInput;
}

const SAVE_AUTOMATION_QUERY = gql`
  mutation SaveAutomation($automation: AutomationInput!) {
    saveAutomation(automation: $automation) {
      __typename
      ...AutomationNodeFragment
      ... on ValidationErrorResult {
        messages {
          text
          index
        }
      }
    }
  }
  ${AUTOMATION_NODE_FRAGMENT}
`;

export const useSaveAutomation = (options?: MutationHookOptions<Data, Variables>) => {
  return useMutation<Data, Variables>(SAVE_AUTOMATION_QUERY, options);
};
