import React from "react";

import { Radio, Tabs } from "antd";

import { RadioButton } from "../common/styledComponents";
import { FunctionPreviewState } from "../useFunctionPreviewExecuter/reducer";

import { FunctionResponse } from "./FunctionResponse/FunctionResponse";
import {
  EmptyFunctionPreview,
  Header,
  PreviewTabs,
  ResultsLabel
} from "./styledComponents";

export interface Props {
  className?: string;
  state: FunctionPreviewState;
  functionExecuting: boolean;
}

export function FunctionPreview({ className, state, functionExecuting }: Props) {
  const { error, isValidResponse, previewResults, previewExceptions, showResults } =
    state;

  const [activeTab, setActiveTab] = React.useState("transformed");

  return (
    <PreviewTabs
      activeKey={activeTab}
      animated={false}
      className={className}
      renderTabBar={() => {
        return (
          <Header>
            <ResultsLabel>Results</ResultsLabel>
            <Radio.Group
              value={activeTab}
              buttonStyle="solid"
              size="small"
              onChange={e => setActiveTab(e.target.value)}
            >
              <RadioButton value="transformed">Transformed</RadioButton>
              <RadioButton value="raw">Raw</RadioButton>
            </Radio.Group>
          </Header>
        );
      }}
    >
      <Tabs.TabPane tab="Transformed" key="transformed">
        {showResults ? (
          <FunctionResponse
            loading={functionExecuting}
            isValid={isValidResponse}
            error={error}
            data={previewResults.transformedData}
            dataException={previewExceptions.transformedData?.error}
            metadata={previewResults.transformedMetadata}
            metadataException={previewExceptions.transformedMetadata?.error}
          />
        ) : (
          <EmptyFunctionPreview />
        )}
      </Tabs.TabPane>
      <Tabs.TabPane tab="Raw" key="raw">
        {showResults ? (
          <FunctionResponse
            loading={functionExecuting}
            error={error}
            data={previewResults.data}
            metadata={previewResults.metadata}
          />
        ) : (
          <EmptyFunctionPreview />
        )}
      </Tabs.TabPane>
    </PreviewTabs>
  );
}
