import TreeTraverser from "../../../../../util/TreeTraverser";
import { TreeNode } from "../reducer";

export default function findTreeNode(
  tree: TreeNode,
  pathParts: string[] | string
): TreeNode | undefined {
  if (typeof pathParts === "string") return findTreeNodeBySlug(tree, pathParts);
  const slug = pathParts.shift();
  for (let i = 0; i < tree.treeNodes.length; i++) {
    const node = tree.treeNodes[i];
    if (node.slug === slug) {
      if (pathParts.length === 0) {
        return node;
      } else {
        return findTreeNode(node, pathParts);
      }
    }
  }
}

function findTreeNodeBySlug(tree: TreeNode, slug: string) {
  const traverser = new TreeTraverser(tree as any, {
    getParent: node => node.container,
    getChildren: node => node.treeNodes
  });
  const node = traverser.find(n => n.slug === slug);
  return node;
}
