import React, { MouseEvent } from "react";

import { Icon, message, Spin } from "antd";
import { useMutation } from "react-apollo";

import { Props } from "..";
import {
  EXECUTE_PRESIGN,
  ExecutePresignData,
  ExecutePresignVars
} from "../../../../../graphql/function";
import { useEvaluaterContext } from "../../../../common/CodeSandbox/EvaluaterContext";
import { EVALUATION_ERROR_PREFIX } from "../../../../common/CodeSandbox/useCodeSandbox";
import { AbsoluteErrorIcon } from "../../../../common/Icons";
import { useStableSpaceContext } from "../../SpaceContext";
import EditModeInteractionBlocker from "../common/EditModeInteractionBlocker";
import { useComponentStateContext } from "../contexts/ComponentStateContext";

import { ensureSpaceLink } from "./Config";
import * as styled from "./styledComponents";
import { LinkTarget } from "./types";
import { getFilenameFromUrl } from "./util";

const AUTH_ERROR_MESSAGE = "Failed to authenticate link";

export default function SpaceLink(props: Props) {
  const link = ensureSpaceLink(props.spaceComponent);
  const [evaluatedUrl, setEvaluatedUrl] = React.useState<string>();
  const [evaluatedText, setEvaluatedText] = React.useState<string>();
  const { evaluate, getConsoleError } = useEvaluaterContext();
  const { input } = useComponentStateContext();
  const { editMode } = useStableSpaceContext();

  const [presign, { loading: signing }] = useMutation<
    ExecutePresignData,
    ExecutePresignVars
  >(EXECUTE_PRESIGN);

  const presignFunctionId = link.properties.authentication?.function_id;

  React.useEffect(() => {
    const evalExpression = async () => {
      try {
        const text = await evaluate(link.properties.text_template, input, "_text");
        setEvaluatedText(text as string);
      } catch (e) {
        if (typeof e === "string" && e.indexOf(EVALUATION_ERROR_PREFIX) > -1) {
          if (e.indexOf(EVALUATION_ERROR_PREFIX) > -1) {
            setEvaluatedText("");
          }
        }

        if (editMode) {
          console.warn(getConsoleError(e));
        }
      }
      try {
        const url = await evaluate(link.properties.url_template, input, "_url");
        setEvaluatedUrl(url as string);
      } catch (e) {
        if (typeof e === "string" && e.indexOf(EVALUATION_ERROR_PREFIX) > -1) {
          setEvaluatedUrl("");
        }
        if (editMode) {
          console.warn(getConsoleError(e));
        }
      }
    };
    evalExpression();
  }, [
    evaluate,
    link.properties.url_template,
    link.properties.text_template,
    input,
    editMode,
    getConsoleError
  ]);

  const target: string | undefined =
    link.properties.target === LinkTarget.Blank ? "_blank" : "_self";

  let download: string | undefined;
  if (link.properties.target === LinkTarget.Download && evaluatedUrl) {
    download = getFilenameFromUrl(evaluatedUrl);
  }

  const handleClick = async (evt: MouseEvent<HTMLAnchorElement>) => {
    if (!presignFunctionId) return;

    evt.preventDefault();

    try {
      const variables: ExecutePresignVars = {
        path: evt.currentTarget.href,
        functionId: presignFunctionId
      };

      if (link.properties.target === LinkTarget.Download) {
        variables.responseContentDisposition = `attachment; filename="${download}"`;
      }

      const result = await presign({ variables });

      if (result.data?.executePresign.ok) {
        const { urlString } = result.data.executePresign;

        if (link.properties.target === LinkTarget.Self) {
          window.location.assign(urlString);
        } else if (link.properties.target === LinkTarget.Blank) {
          window.open(urlString);
        } else if (link.properties.target === LinkTarget.Download) {
          const anchor = document.createElement("a");
          anchor.href = urlString;
          anchor.download = download!;
          anchor.click();
        }
      } else {
        message.error(AUTH_ERROR_MESSAGE);
      }
    } catch (e) {
      message.error(AUTH_ERROR_MESSAGE);
    }
  };

  return (
    <EditModeInteractionBlocker>
      <styled.Container>
        {props.hasConfigError ? <AbsoluteErrorIcon /> : null}
        {evaluatedUrl ? (
          <>
            <a
              href={evaluatedUrl}
              target={target}
              download={download}
              rel="noopener noreferrer"
              onClick={handleClick}
            >
              {evaluatedText || evaluatedUrl}
            </a>
            {signing && <Spin size="small" indicator={<Icon type="loading" spin />} />}
          </>
        ) : (
          <span>Link not yet configured.</span>
        )}
      </styled.Container>
    </EditModeInteractionBlocker>
  );
}
