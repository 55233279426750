import React, { useEffect, useMemo, useState } from "react";

import { startCase } from "lodash";

import {
  BindingShape,
  DataSourceNode,
  FunctionNode,
  FunctionScope,
  InputParameter
} from "../../../../../../types";
import { SpaceContextParams } from "../../../../../spaces/SpaceRoot/SpaceContext/SpaceContext";
import { BindingCascader, Option, getOption } from "../../../../BindingCascader";
import FormBuilderModal from "../../../../FormBuilderModal";
import { FunctionNode as FormBuilderFunctionNode } from "../../../../FormBuilderModal/types";
import FunctionPicker from "../../../../FunctionPicker";
import Message from "../../../../Message";
import { BindingSchema, getUserEnvironmentBindingSchema } from "../../../../schema";
import { LinkButtonNew } from "../../../../StyledComponents";
import { PipelineStep } from "../../../useFunctionEditor/queries";
import { useGetFunctionById } from "../common/queries";
import {
  ActionLinks,
  Hr,
  PaddedContainer,
  SelectLabel,
  SubstepContainer
} from "../common/styledComponents";
import {
  ActionIcon,
  BottomText,
  IconTitle,
  LinearCenterContainer,
  TopText,
  VerticalContainer
} from "../StepSummary/styledComponents";
import { SUPPORTED_PARAM_TYPES } from "../utils";

import { LoopSectionHeader } from "./styledComponents";

const createBindingOptions = (contextParams: SpaceContextParams): Option[] => {
  return Object.entries(contextParams).map(([k, v]) => ({
    label: v.title,
    value: k,
    bindingShape: BindingShape.OBJECT,
    children: v.schema.map(getOption)
  }));
};

interface Props {
  className?: string;
  step: PipelineStep;
  steps: PipelineStep[];
  previousStepsSchema: Record<string, BindingSchema> | undefined;
  onSetPipelineStepIteratorBinding: (
    stepIndex: number,
    iteratorBinding: string
  ) => void;
  onSetPipelineStepIteratorItemName: (
    stepIndex: number,
    iteratorItemName: string
  ) => void;
  onSetPipelineStepFunction: (stepIndex: number, func: FunctionNode) => void;
  onSetPipelineStepDataSource: (stepIndex: number, dataSource: DataSourceNode) => void;
  onSetPipelineStepInputParameters: (
    stepIndex: number,
    parameters: InputParameter[]
  ) => void;
}

export default function ConfigLoopStep({
  className,
  step,
  steps,
  previousStepsSchema,
  onSetPipelineStepDataSource,
  onSetPipelineStepInputParameters,
  onSetPipelineStepFunction,
  onSetPipelineStepIteratorBinding
}: Props) {
  const previousSteps = useMemo(() => {
    return steps.slice(0, step.order + 1);
  }, [step, steps]);

  const [showFormBuilder, setShowFormBuilder] = useState<boolean>(false);

  const [pendingFunctionId, setPendingFunctionId] = useState<string | null>(
    step.function ? step.function.id : null
  );

  const func = step.function;

  const bindingOptions = useMemo(() => {
    if (!previousStepsSchema) {
      return [];
    }

    return createBindingOptions(previousStepsSchema);
  }, [previousStepsSchema]);

  const functionBindingSchema: Record<string, BindingSchema> | undefined =
    useMemo(() => {
      if (!previousStepsSchema) {
        return undefined;
      }

      if (!step.iteratorBinding) {
        return previousStepsSchema;
      }

      const boundStep = previousSteps.find(s => s.slug === step.iteratorBinding);
      if (!boundStep) {
        throw new Error(`No previous step has slug ${step.iteratorBinding}`);
      }

      if (step.iteratorItemName) {
        return {
          [step.iteratorItemName]: {
            title: startCase(step.iteratorItemName),
            schema: (boundStep.function?.functionAttributes?.edges || []).map(
              ({ node }) => ({
                name: node.name,
                title: node.name,
                shape: BindingShape.SCALAR,
                type: node.sourceType
              })
            )
          },
          ...previousStepsSchema,
          ...getUserEnvironmentBindingSchema()
        };
      }

      return {
        ...previousStepsSchema,
        ...getUserEnvironmentBindingSchema()
      };
    }, [
      previousSteps,
      step.iteratorBinding,
      step.iteratorItemName,
      previousStepsSchema
    ]);

  const formBuilderFunction: FormBuilderFunctionNode = useMemo(() => {
    return {
      functionParameters: {
        edges: step.function?.functionParameters?.edges || []
      }
    };
  }, [step.function]);

  const { error: functionError } = useGetFunctionById({
    variables: {
      id: pendingFunctionId!
    },
    skip: !pendingFunctionId,
    onCompleted: data => {
      // When the step is loaded, it might have a function already.
      // Don't trigger a set function step when the function hasn't changed.
      if (data.node.id !== step.function?.id) {
        onSetPipelineStepFunction(step.order, data.node);
      }
    }
  });

  useEffect(() => {
    if (functionError) {
      Message.error("Something went wrong. Please try again.");
    }
  }, [functionError]);

  // Keep pending funciton in sync with step
  // Otherwise changing Step Type (which wipes function data) will not show in UI
  useEffect(() => {
    setPendingFunctionId(step.function?.id || null);
  }, [step.function?.id]);

  return (
    <div className={className}>
      <SelectLabel>For each item in</SelectLabel>
      <BindingCascader
        value={step.iteratorBinding}
        options={bindingOptions}
        placeholder="Select a source"
        selectable={[BindingShape.OBJECT, BindingShape.OBJECT_ARRAY]}
        onChange={stepSlug => {
          onSetPipelineStepIteratorBinding(step.order, stepSlug);
        }}
      />
      <LoopSectionHeader>Do the following:</LoopSectionHeader>
      <SubstepContainer>
        <PaddedContainer>
          {!func?.dataSource?.integration ? (
            <LinearCenterContainer>
              <ActionIcon integration={undefined}></ActionIcon>
              <IconTitle>Choose function</IconTitle>
            </LinearCenterContainer>
          ) : (
            <LinearCenterContainer>
              <ActionIcon integration={func?.dataSource?.integration}></ActionIcon>
              <VerticalContainer>
                <TopText>Function</TopText>
                <BottomText>{func?.title}</BottomText>
              </VerticalContainer>
            </LinearCenterContainer>
          )}
        </PaddedContainer>
        <Hr />
        <PaddedContainer>
          <FunctionPicker
            functionId={pendingFunctionId}
            functionScope={FunctionScope.Submittable}
            onChange={setPendingFunctionId}
            onDataSourceChange={ds => onSetPipelineStepDataSource(step.order, ds)}
            showLabels
            allowPipelineFunctions
          />
          <ActionLinks>
            <LinkButtonNew
              disabled={
                !func?.functionParameters?.edges.length || !step.iteratorBinding
              }
              onClick={() => setShowFormBuilder(true)}
            >
              Configure
            </LinkButtonNew>
          </ActionLinks>
        </PaddedContainer>
      </SubstepContainer>
      {showFormBuilder && (
        <FormBuilderModal
          visible={showFormBuilder}
          func={formBuilderFunction}
          title={step.function?.title || ""}
          schema={functionBindingSchema}
          allowedParameterTypes={SUPPORTED_PARAM_TYPES}
          initialInputParameters={step.inputParameters}
          onCancel={() => setShowFormBuilder(false)}
          allowedBindingShapes={[BindingShape.SCALAR, BindingShape.OBJECT]}
          onSave={e => {
            setShowFormBuilder(false);
            onSetPipelineStepInputParameters(step.order, e);
          }}
        />
      )}
    </div>
  );
}
