import React from "react";

import { Spin } from "antd";
import styled from "styled-components";

import { chartColors, colorTokens, newShade } from "../../../../../../cssConstants";
import useDebouncedValue from "../../../../../common/hooks/useDebouncedValue";
import { Row } from "../../common/useView";
import { SpaceChartComponent, ChartType } from "../types";

import BarChart from "./BarChart";
import LineChart from "./LineChart";
import PieChart from "./PieChart";
import selectChartData, { ChartData } from "./selectChartData";

export interface Props {
  component: SpaceChartComponent;
  rows: Row[];
}

const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const ChartComponents = {
  [ChartType.COLUMN]: BarChart,
  [ChartType.LINE]: LineChart,
  [ChartType.PIE]: PieChart
};

const emptyChartData: ChartData = { data: [], dataKeys: [], xAxisFilter: () => ({}) };

const chartFillColor = (
  cellIdx: number,
  activeIdx: number,
  hoverIdx: number,
  colorIdx: number
) => {
  const colors = Object.values(chartColors);
  const baseColor = colors[colorIdx % colors.length];

  if (cellIdx === hoverIdx) {
    return newShade(baseColor, 0.7);
  }

  if (cellIdx === activeIdx) {
    return baseColor;
  }

  return activeIdx === -1 ? baseColor : colorTokens.grey600;
};
export { chartFillColor };

export default function Chart({ component, rows }: Props) {
  const [isDebounced, setIsDebounced] = React.useState(
    component.properties.chart_type === ChartType.PIE ? false : true
  );
  React.useEffect(() => setIsDebounced(true), []);
  const chartData = React.useMemo(
    () => selectChartData(component, rows),
    [component, rows]
  );
  const debouncedChartData = useDebouncedValue(
    isDebounced ? chartData : emptyChartData,
    1000
  );

  const ChartComponent = ChartComponents[component.properties.chart_type];

  if (debouncedChartData === emptyChartData) {
    return (
      <CenteredDiv>
        <Spin spinning size="large" />
      </CenteredDiv>
    );
  }
  return <ChartComponent value={debouncedChartData} />;
}
