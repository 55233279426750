import styled from "styled-components";

import { colors } from "../../../../cssConstants";
import { C2 } from "../../../common/StyledComponents";
import { Column, StatValue } from "../common/styledComponents";

export const TransitionActivityContainer = styled.div`
  display: flex;
`;

export const InputSection = styled.div`
  width: 50%;
`;

export const StatisticsSection = styled.div`
  width: 50%;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StatCount = styled(StatValue)`
  padding: 0 12px;
`;

export const SelectLabel = styled(C2)`
  display: block;
  line-height: 18px;
  color: ${colors.surfaceSecondary};
  margin-bottom: 5px;
`;

export const TransitionStatsColumn = styled(Column)`
  min-width: 140px;
  width: 20%;
  padding-right: 16px;
`;

export const TransitionLabel = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ProgressContainer = styled.div`
  min-height: 21px;
`;
