import produce from "immer";
import { cloneDeep, flow, partial, isEqual } from "lodash";

import { SpaceComponentObject } from "../../../../../../../types";
import commonComponentReducer from "../../../../../SpaceConfig/SpaceConfigContext/useSpaceConfig/reducer/componentReducer";
import {
  BaseComponentConfigState,
  ComponentConfigState,
  SpaceConfigAction
} from "../../../../../types";
import filterListManagerReducer, {
  INITIAL_STATE as FILTER_LIST_MANAGER_INITIAL_STATE,
  FilterListManagerState,
  getInitialFilterState
} from "../../../common/FilterListManager/reducer/reducer";
import parameterConfigReducer, {
  INITIAL_STATE as PARAMETER_CONFIG_INITIAL_STATE,
  getInitialParameterState,
  ParameterConfigState
} from "../../../common/ParametersManager/reducer/reducer";
import viewDataConfigReducer, {
  INITIAL_STATE as VIEW_DATA_CONFIG_INITIAL_STATE,
  ViewConfigState
} from "../../../common/useViewConfig/reducer";
import { SpaceImageComponent } from "../../types";

export interface ImageConfigState
  extends BaseComponentConfigState<SpaceImageComponent>,
    ViewConfigState,
    FilterListManagerState,
    ParameterConfigState {
  type: "IMAGE";
}

export const INITIAL_STATE = {
  type: "IMAGE" as const,
  draftComponent: {} as SpaceImageComponent,
  ...VIEW_DATA_CONFIG_INITIAL_STATE,
  ...FILTER_LIST_MANAGER_INITIAL_STATE,
  ...PARAMETER_CONFIG_INITIAL_STATE
};

export const ensureImageComponent = (
  component: SpaceComponentObject
): SpaceImageComponent => {
  const nextComponent = {
    ...component,
    properties: {
      ...component.properties,
      columns: component.properties.columns || [],
      filters: component.properties.filters || [],
      is_filter_required: component.properties.is_filter_required ?? false,
      input_parameters: component.properties.input_parameters || [],
      authentication: component.properties.authentication || null,
      template: component.properties.template || ""
    }
  };
  return isEqual(component, nextComponent) ? component : nextComponent;
};

function isImageConfigState(state: ComponentConfigState): state is ImageConfigState {
  return state.type === "IMAGE";
}

export function ensureImageConfigState(state: ComponentConfigState): ImageConfigState {
  if (isImageConfigState(state)) return state;

  throw new Error("Expected image config state.");
}

export function makeInitialState(
  draftComponent: SpaceComponentObject
): ImageConfigState {
  return {
    ...INITIAL_STATE,
    draftComponent: ensureImageComponent(cloneDeep(draftComponent)),
    ...getInitialFilterState(draftComponent),
    ...getInitialParameterState(draftComponent)
  };
}
function reducer(state: ImageConfigState, action: SpaceConfigAction): ImageConfigState {
  switch (action.type) {
    case "CHANGE_FUNCTION": {
      const { functionId } = action.payload;
      return produce(state, (draftState: ImageConfigState) => {
        if (!!functionId) return state;
        draftState.draftComponent.properties.columns = [];
      });
    }
  }

  return state;
}

export default (state: ImageConfigState, action: SpaceConfigAction) =>
  flow([
    commonComponentReducer,
    partial(viewDataConfigReducer, partial.placeholder, action),
    partial(parameterConfigReducer, partial.placeholder, action),
    partial(filterListManagerReducer, partial.placeholder, action),
    partial(reducer, partial.placeholder, action)
  ])(state, action);
