import React, { useCallback, useMemo } from "react";

import { colorTokens, colors } from "../../../../../../cssConstants";
import ColorPicker from "../../../../../common/ColorPicker";
import { useComponentConfigContext } from "../../common/ComponentConfigContext";
import { ConfigSection, Field } from "../../common/ConfigPanel";
import { CheckboxConfigState } from "../types";

export default function DesignConfig() {
  const context = useComponentConfigContext();
  const { dispatch } = context;
  const state = context.state as CheckboxConfigState;

  const themeProperties = useMemo(() => {
    const theme_properties = state.draftComponent.properties.theme_properties || {};

    if (!theme_properties["background-color"]) {
      theme_properties["background-color"] = colors.primaryAccent;
    }

    if (!theme_properties["checkmark-color"]) {
      theme_properties["checkmark-color"] = colorTokens.white;
    }

    return theme_properties;
  }, [state.draftComponent.properties.theme_properties]);

  const updateColor = useCallback(
    change => {
      dispatch({
        type: "UPDATE_COMPONENT_THEME_PROPERTIES",
        payload: {
          properties: {
            ...themeProperties,
            ...change
          }
        }
      });
    },
    [dispatch, themeProperties]
  );

  return (
    <ConfigSection title="Style">
      <Field>
        <label>Fill</label>
        <ColorPicker
          value={themeProperties["background-color"]}
          size="small"
          onChange={value => {
            updateColor({ "background-color": value });
          }}
        />
      </Field>
      <Field>
        <label>Checkmark</label>
        <ColorPicker
          value={themeProperties["checkmark-color"]}
          size="small"
          onChange={value => {
            updateColor({ "checkmark-color": value });
          }}
        />
      </Field>
    </ConfigSection>
  );
}
