import { useMutation } from "@apollo/react-hooks";
import { MutationHookOptions } from "@apollo/react-hooks/lib/types";
import gql from "graphql-tag";

import { ValidationErrorResult } from "../../../../types";

import { SCM_CONFIG_FRAGMENT, ScmConfigNode } from "./ScmConfigFragment";
import { Variables as CreateVariables } from "./useCreateScmConfig";

const QUERY = gql`
  mutation UpdateScmConfig(
    $id: ID!
    $provider: Provider!
    $githubAppAppId: Int!
    $githubAppBranch: String!
    $githubAppEnterpriseUrl: String!
    $githubAppEnterpriseApiUrl: String!
    $githubAppInstallationId: Int!
    $githubAppOwner: String!
    $githubAppPrivateKey: String
    $githubAppRepo: String!
  ) {
    updateScmConfig(
      id: $id
      provider: $provider
      githubAppAppId: $githubAppAppId
      githubAppBranch: $githubAppBranch
      githubAppEnterpriseUrl: $githubAppEnterpriseUrl
      githubAppEnterpriseApiUrl: $githubAppEnterpriseApiUrl
      githubAppInstallationId: $githubAppInstallationId
      githubAppOwner: $githubAppOwner
      githubAppPrivateKey: $githubAppPrivateKey
      githubAppRepo: $githubAppRepo
    ) {
      ... on ScmConfigNode {
        ...ScmConfigFragment
      }
    }
  }
  ${SCM_CONFIG_FRAGMENT}
`;

interface Data {
  updateScmConfig: ScmConfigNode | ValidationErrorResult;
}

export type Variables = Omit<CreateVariables, "githubAppPrivateKey"> & {
  id: string;
  githubAppPrivateKey?: string;
};

const useUpdateScmConfig = (options?: MutationHookOptions<Data, Variables>) =>
  useMutation<Data, Variables>(QUERY, options);

export default useUpdateScmConfig;
