import React from "react";

import { Button } from "antd";
import { v4 as uuid } from "uuid";

import { SpaceComponentType } from "../../../../../types";
import useAuthUser from "../../../../common/hooks/useAuthUser";
import Menu from "../../../../common/Menu";
import { ComponentStateContainer } from "../../../SpaceRoot/SpaceComponent/contexts/ComponentStateContext";
import { useStableSpaceContext } from "../../../SpaceRoot/SpaceContext";
import { useSpaceConfigContext } from "../../SpaceConfigContext";
import { MenuProps } from "../Sider";
import { FunctionConfigMenuItemProps } from "../types";

import { FunctionList } from "./FunctionList";
import * as styled from "./styledComponents";

const COMPONENTS_CONFIGURED_IN_APP_DATA: SpaceComponentType[] = ["PARAMS"];

export default function AppDataFlyout({
  menuPath,
  onOpen
}: MenuProps<FunctionConfigMenuItemProps>) {
  const { findSpaceComponentPackage } = useStableSpaceContext();
  const config = useSpaceConfigContext();
  const { isAdmin } = useAuthUser();

  const { dataSourceId, functionId } =
    menuPath.find(p => p.type === "FUNCTION_CONFIG")?.props || {};
  const selected = dataSourceId && functionId ? [dataSourceId, functionId] : [];

  return (
    <>
      <Menu title="Data">
        <FunctionList
          selected={selected}
          onAdd={(dataSourceId: string) => {
            onOpen({
              type: "FUNCTION_CONFIG",
              props: { dataSourceId, pendingFunctionId: uuid() }
            });
          }}
          onSelect={(dataSourceId: string, functionId: string) => {
            onOpen({
              type: "FUNCTION_CONFIG",
              props: {
                dataSourceId,
                functionId
              }
            });
          }}
        />
        {COMPONENTS_CONFIGURED_IN_APP_DATA.map(t => {
          const spacePackage = findSpaceComponentPackage(t);
          const ComponentConfiguration = spacePackage?.ConfigurationSection;
          if (!ComponentConfiguration) return null;
          const rootSpace = config.state.spaces.get(config.state.rootSpaceSlug)!;
          if (!rootSpace || rootSpace?.components.size === 0) return null;
          const sc = Array.from(rootSpace.components.values()).filter(
            c => c.draftComponent.type === t
          );

          return (
            <div key={t}>
              <ComponentStateContainer component={sc[0].draftComponent}>
                <ComponentConfiguration
                  spaceComponents={sc.map(sc => sc.draftComponent)}
                  displayName={spacePackage?.displayName || ""}
                />
              </ComponentStateContainer>
            </div>
          );
        })}
        {isAdmin && (
          <styled.LinkContainer>
            <Button
              type="link"
              onClick={() => {
                onOpen({
                  type: "FUNCTION_CONFIG",
                  props: { pendingFunctionId: uuid() }
                });
              }}
            >
              Create a new function
            </Button>
          </styled.LinkContainer>
        )}
      </Menu>
    </>
  );
}
