import { SpaceComponentType } from "../../../../types";

export const SUPERFICIAL_COMPONENT_TYPES: SpaceComponentType[] = ["HEADER"];

export const PERMANENT_COMPONENT_TYPES: SpaceComponentType[] = ["PARAMS", "USER"];

export const FORM_COMPONENT_TYPES: SpaceComponentType[] = [
  "FUNCTION_FORM",
  "FUNCTION_MODAL_FORM",
  "CLOUD_UPLOADER"
];

export const MAX_DATA_SIZE = 10485760; // 10mb

export const TEMPLATE_BINDING_EXTRACTER = /\${([\s\S]+?)}/g;
