import React from "react";

import { ComponentConfigProps } from "../../../../../../types";
import BaseComponentConfigSection from "../../common/BaseComponentConfigSection";
import {
  ComponentConfigContextContainer,
  useComponentConfigContext
} from "../../common/ComponentConfigContext";
import ValidationError from "../../common/ComponentConfigContext/ValidationError";
import { Field } from "../../common/ConfigPanel";
import NameFields from "../../common/NameFields";
import { DebouncedTemplateEditor, Height } from "../../common/TemplateEditor";
import CloudStorageConfig from "../../common/useCloudStorage";
import VisibilityRulesManagerSection from "../../common/VisibilityRulesManager";
import { SpaceVideoComponent } from "../types";

import * as styled from "./styledComponents";

export default function Config({ slug }: ComponentConfigProps) {
  return <ComponentConfigContextContainer dataConfig={<VideoConfig />} slug={slug} />;
}

function VideoConfig() {
  const { dispatch, state, errors } = useComponentConfigContext();
  const {
    properties: { template }
  } = state.draftComponent as SpaceVideoComponent;
  return (
    <>
      <BaseComponentConfigSection>
        <NameFields shouldRenderTitle />
        <Field>
          <styled.Label>URL Template</styled.Label>
          <DebouncedTemplateEditor
            value={template}
            placeholder="https://example.com/${myvideo}"
            minHeight={Height.Medium}
            onChange={(template: string) =>
              dispatch({
                type: "SET_DRAFT_COMPONENT",
                payload: {
                  path: "properties.template",
                  value: template
                }
              })
            }
          />
          <ValidationError field="URL_TEMPLATE" />
        </Field>
      </BaseComponentConfigSection>
      <CloudStorageConfig state={state} dispatch={dispatch} errors={errors} />
      <VisibilityRulesManagerSection />
    </>
  );
}
