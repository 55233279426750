import React, { useEffect } from "react";

import { Switch } from "antd";
import styled from "styled-components";

import { ComponentConfigProps } from "../../../../../../types";
import Label from "../../../../../common/Label";
import { useSpaceConfigContext } from "../../../../SpaceConfig/SpaceConfigContext/SpaceConfigContext";
import BaseComponentConfigSection from "../../common/BaseComponentConfigSection";
import ChildComponentsConfigSection from "../../common/ChildComponentsConfigSection";
import {
  ComponentConfigContextContainer,
  useComponentConfigContext
} from "../../common/ComponentConfigContext";
import ValidationError from "../../common/ComponentConfigContext/ValidationError";
import { ConfigSection, Field } from "../../common/ConfigPanel";
import EffectsManager from "../../common/effects/EffectsManager/EffectsManager";
import NameFields from "../../common/NameFields";
import { DebouncedTemplateEditor } from "../../common/TemplateEditor";
import useCreateSubSpace from "../../common/useCreateSubSpace/useCreateSubSpace";
import VisibilityRulesManagerSection from "../../common/VisibilityRulesManager";
import { useComponentContext } from "../../contexts/ComponentContext";
import { MODAL_EVENTS } from "../effects";

const MemoDataConfig = React.memo(SpaceModalConfigContent);
const MemoEffectsConfig = React.memo(EffectsManager);
const MemoModalDesignConfig = React.memo(ModalDesignConfig);
export default function SpaceTabsConfig({ slug }: ComponentConfigProps) {
  return (
    <ComponentConfigContextContainer
      dataConfig={<MemoDataConfig />}
      effectsConfig={<MemoEffectsConfig eventOptions={MODAL_EVENTS} />}
      designConfig={<MemoModalDesignConfig />}
      dataTabTitle="Main"
      slug={slug}
    />
  );
}

function SpaceModalConfigContent() {
  const { component } = useComponentContext();
  const { dispatch } = useComponentConfigContext();
  const {
    dispatch: rootConfigDispatch,
    state: { uiStateOverrides }
  } = useSpaceConfigContext();
  const createSubSpace = useCreateSubSpace();
  useEffect(() => {
    // If the modal does not have a child subspace initialize it
    const childSubspace = component.componentTreeNodes[0];
    if (!childSubspace || childSubspace.type !== "SUB_SPACE") {
      createSubSpace(component.slug, "Modal content");
    }
  }, [component, createSubSpace]);

  return (
    <>
      <BaseComponentConfigSection>
        <NameFields shouldRenderTitle />
        <Field>
          <Label
            showInfoIcon
            title="The modal will be open if this expression evaluates to true."
          >
            Open When
          </Label>
          <DebouncedTemplateEditor
            mode="javascript"
            value={component.properties.is_open_template}
            onChange={value =>
              dispatch({
                type: "SET_DRAFT_COMPONENT",
                payload: {
                  path: "properties.is_open_template",
                  value
                }
              })
            }
          />
          <ValidationError field="IS_OPEN_TEMPLATE" />
        </Field>
        <Field>
          <Label
            showInfoIcon
            title="This toggles whether the modal is open while editing your space."
          >
            Open
          </Label>
          <StyledSwitch
            checked={uiStateOverrides.openModals.has(component.slug)}
            onChange={value => {
              rootConfigDispatch({
                type: "UPDATE_UI_STATE_OVERRIDES",
                payload: {
                  uiStateOverrides: {
                    ...uiStateOverrides,
                    openModals: new Set<string>(
                      value
                        ? [...uiStateOverrides.openModals, component.slug]
                        : [
                            ...Array.from(uiStateOverrides.openModals).filter(
                              s => s !== component.slug
                            )
                          ]
                    )
                  }
                }
              });
              dispatch({
                type: "SET_DRAFT_COMPONENT",
                payload: {
                  path: "properties.invert_is_open",
                  value
                }
              });
            }}
          />
        </Field>
      </BaseComponentConfigSection>
      <ChildComponentsConfigSection />
      <VisibilityRulesManagerSection />
    </>
  );
}

const StyledSwitch = styled(Switch)`
  align-self: start;
`;

function ModalDesignConfig() {
  const {
    state: { draftComponent },
    dispatch
  } = useComponentConfigContext();
  return (
    <ConfigSection>
      <Field>
        <Label title="">Display a close button</Label>
        <StyledSwitch
          checked={draftComponent.properties.has_close_button}
          onChange={value => {
            dispatch({
              type: "SET_DRAFT_COMPONENT",
              payload: {
                path: "properties.has_close_button",
                value
              }
            });
          }}
        />
      </Field>
    </ConfigSection>
  );
}
