import { colorTokens } from "../../../../../../cssConstants";

export const DEFAULT_BUTTON_BACKGROUND_COLOR = colorTokens.purple400;
export const DEFAULT_BUTTON_TEXT_COLOR = colorTokens.white;

export const ButtonBackgroundToTextColorMap = {
  [DEFAULT_BUTTON_BACKGROUND_COLOR]: colorTokens.grey400,
  [colorTokens.red300]: colorTokens.grey400,
  [colorTokens.red200]: colorTokens.white,
  [colorTokens.orange300]: colorTokens.grey400,
  [colorTokens.yellow200]: colorTokens.grey400,
  [colorTokens.green400]: colorTokens.white,
  [colorTokens.blue200]: colorTokens.white,
  [colorTokens.white]: colorTokens.greyTransparent1200,
  [colorTokens.black]: colorTokens.grey400
};

export const ButtonBackgroundToBorderColorMap = {
  [colorTokens.white]: colorTokens.grey400
};

export enum ButtonTypes {
  Rectangle = "rectangle",
  Circle = "circle",
  Link = "link"
}
