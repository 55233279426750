import React, { useEffect, useState } from "react";

import useDebouncedValue from "../hooks/useDebouncedValue";
import usePrevious from "../hooks/usePrevious";

const DELAY = 400;

interface WithDebouncedValueProps {
  value: any;
  onChange: (value: any) => void;
  onBlur?: (evt: any) => void;
  onFocus?: (evt: any) => void;
}

interface RequiredInputProps {
  value?: any;
  onChange?: (value: any) => void;
  onBlur?: (evt: any, value?: any) => void;
  onFocus?: (evt: any) => void;
  onChangeImmediately?: (value: any) => void;
}

type WithDebouncedValueOptions = {
  serializeExternalValue?: (value: any) => any;
  serializeLocalValue?: (value: any) => any;
  selectOnChangeValue?: (evt: any) => any;
  isEqual?: (localValue: any, externalValue: any) => boolean;
  addOnChangeImmediately?: boolean;
};

export default function withDebouncedValue<T extends RequiredInputProps, L, E>(
  WrappedComponent: React.ComponentType<T>,
  {
    serializeExternalValue = (val: E) => val,
    serializeLocalValue = (val: L) => val,
    selectOnChangeValue = val => val,
    isEqual = (local, external) => String(local) === String(external),
    addOnChangeImmediately = false
  }: WithDebouncedValueOptions = {}
) {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  const ComponentWithDebouncedValue = ({
    value,
    onChange,
    ...props
  }: Omit<T, "onChange"> & WithDebouncedValueProps) => {
    const [localValue, setLocalValue] = useState(serializeExternalValue(value));
    const [valueAtFocus, setValueAtFocus] = useState(false);
    const [focused, setFocused] = useState(false);
    const [doFlush, setDoFlush] = useState(false);

    const debouncedValue = useDebouncedValue(localValue, DELAY, doFlush);
    const prevDebouncedValue = usePrevious(debouncedValue);
    useEffect(() => {
      if (
        focused &&
        prevDebouncedValue !== debouncedValue &&
        !isEqual(debouncedValue, value)
      ) {
        // Focused and debounced value is different from external value,
        // so sync to external value
        onChange(serializeLocalValue(debouncedValue));
      } else if (
        focused === false &&
        !isEqual(value, valueAtFocus) &&
        !isEqual(localValue, value)
      ) {
        // Blurred and external value changed, so sync local from external value
        setLocalValue(serializeExternalValue(value));
      }
    }, [
      value,
      debouncedValue,
      prevDebouncedValue,
      valueAtFocus,
      focused,
      localValue,
      onChange
    ]);

    useEffect(() => {
      if (doFlush) setDoFlush(false);
    }, [doFlush]);

    const prevFocused = usePrevious(focused);
    const justFocused = focused !== prevFocused && focused;
    useEffect(() => {
      if (justFocused) {
        setValueAtFocus(value);
      }
    }, [value, justFocused]);

    const conditionalProps: Partial<RequiredInputProps> = {};
    if (addOnChangeImmediately) {
      conditionalProps.onChangeImmediately = (val: any) => {
        setLocalValue(selectOnChangeValue(val));
        setDoFlush(true);
        onChange(serializeLocalValue(selectOnChangeValue(val)));
      };
    }

    return (
      <WrappedComponent
        {...(props as unknown as T)}
        value={localValue}
        onFocus={evt => {
          if (typeof props.onFocus === "function") {
            props.onFocus(evt);
          }
          setFocused(true);
        }}
        onBlur={evt => {
          if (typeof props.onBlur === "function") {
            props.onBlur(evt);
          }
          setFocused(false);
        }}
        onChange={val => {
          setLocalValue(selectOnChangeValue(val));
        }}
        {...conditionalProps}
      />
    );
  };

  ComponentWithDebouncedValue.displayName = `withDebouncedValue(${displayName})`;

  return ComponentWithDebouncedValue;
}
