import React from "react";

import { SpaceComponentObject, ConfigValidationError } from "../../../../../../types";
import { useSpaceConfigContext } from "../../../../SpaceConfig/SpaceConfigContext";
import {
  BaseSpaceConfig,
  createBaseComponentConfigState,
  createSpaceConfig,
  TreeNode
} from "../../../../SpaceConfig/SpaceConfigContext/useSpaceConfig/reducer/reducer";
import { SpaceConfigAction, ComponentConfigState } from "../../../../types";
import { useSpaceContext } from "../../../SpaceContext/SpaceContext";
import { ComponentContextContainer } from "../../contexts/ComponentContext";
import { ConfigPanelContent } from "../ConfigPanel";

export interface IComponentConfigContext {
  state: ComponentConfigState;
  space: BaseSpaceConfig;
  dispatch: React.Dispatch<SpaceConfigAction>;
  errors: ConfigValidationError[];
}

export interface Props {
  dataConfig: React.ReactNode;
  dataTabTitle?: React.ReactNode;
  effectsConfig?: React.ReactNode;
  designConfig?: React.ReactNode;
  slug: string;
  collectionKey?: string;
  itemKey?: string;
}

export function createComponentConfigContextValue(
  opts: Partial<IComponentConfigContext>
) {
  return {
    state: createBaseComponentConfigState(),
    space: createSpaceConfig(),
    errors: [],
    dispatch: () => null,
    ...opts
  };
}

export const ComponentConfigContext = React.createContext<IComponentConfigContext>({
  state: createBaseComponentConfigState(),
  space: {} as BaseSpaceConfig,
  errors: [],
  dispatch: () => null
});

export interface WrapperProps {
  slug: string;
  children: React.ReactNode;
}

function _ComponentConfigContextProvider(props: WrapperProps) {
  const {
    configErrors,
    getComponentSpaceConfig,
    getComponentConfig,
    dispatch: rootDispatch
  } = useSpaceConfigContext();
  const space = React.useMemo(
    () => getComponentSpaceConfig(props.slug),
    [getComponentSpaceConfig, props.slug]
  );
  const state = React.useMemo(
    () => getComponentConfig(props.slug),
    [getComponentConfig, props.slug]
  );
  const dispatch = React.useCallback(
    (action: SpaceConfigAction) => {
      rootDispatch({
        ...action,
        componentSlug: props.slug
      });
    },
    [rootDispatch, props.slug]
  );

  const treeNode = React.useMemo(() => {
    function findTreeNode(slug: string, node: TreeNode | null): TreeNode | undefined {
      if (node === null) return;
      if (node.slug === slug) return node;
      for (let i = 0; i < node.treeNodes.length; i++) {
        const result = findTreeNode(slug, node.treeNodes[i]);
        if (result) return result;
      }
    }
    return findTreeNode(props.slug, space.tree);
  }, [props.slug, space.tree]);

  const childComponents: SpaceComponentObject[] = React.useMemo(() => {
    if (!treeNode) throw new Error("Expected to find treeNode.");
    return treeNode.treeNodes.map(tn => {
      if (!tn.slug) throw new Error("Expected child TreeNodes to define slug.");
      return getComponentConfig(tn.slug).draftComponent;
    });
  }, [treeNode, getComponentConfig]);

  const container: SpaceComponentObject | null = React.useMemo(() => {
    if (!treeNode) throw new Error("Expected to find treeNode.");
    if (
      treeNode.container === null ||
      treeNode.container.slug === "root" ||
      treeNode.container.slug === null
    )
      return null;
    return getComponentConfig(treeNode.container.slug).draftComponent;
  }, [treeNode, getComponentConfig]);

  const errors = configErrors[props.slug];
  const value = React.useMemo(() => {
    return {
      state: {
        ...state,
        draftComponent: {
          ...state.draftComponent,
          componentTreeNodes: childComponents,
          container
        }
      },
      space,
      dispatch,
      errors
    };
  }, [state, space, childComponents, container, errors, dispatch]);

  return (
    <ComponentConfigContext.Provider value={value}>
      {props.children}
    </ComponentConfigContext.Provider>
  );
}

function ConfigContent(props: Props) {
  const { state, dispatch, errors } = useComponentConfigContext();
  return (
    <ConfigPanelContent
      spaceComponent={state.draftComponent}
      state={state}
      dispatch={dispatch}
      valid={errors.length === 0}
      dataConfig={props.dataConfig}
      dataTabTitle={props.dataTabTitle}
      designConfig={props.designConfig}
      effectsConfig={props.effectsConfig}
    />
  );
}

function _ComponentConfigContextContainer(props: Props) {
  const { components } = useSpaceContext();
  const component = components.find(c => c.slug === props.slug);
  if (!component) throw new Error("Expected to find component.");
  return (
    <ComponentContextContainer component={component}>
      <ComponentConfigContextProvider {...props}>
        <ConfigContent {...props} />
      </ComponentConfigContextProvider>
    </ComponentContextContainer>
  );
}

function _TerseComponentConfigContextContainer(props: WrapperProps) {
  const { components } = useSpaceContext();
  const component = components.find(c => c.slug === props.slug);
  if (!component) throw new Error("Expected to find component.");
  return (
    <ComponentContextContainer component={component}>
      <ComponentConfigContextProvider {...props}>
        {props.children}
      </ComponentConfigContextProvider>
    </ComponentContextContainer>
  );
}

// renders ConfigPanel and other related config components
export const ComponentConfigContextContainer = React.memo(
  _ComponentConfigContextContainer
);

// wraps children passed in
export const ComponentConfigContextProvider = React.memo(
  _ComponentConfigContextProvider
);

export const TerseComponentConfigContextContainer = React.memo(
  _TerseComponentConfigContextContainer
);

export const useComponentConfigContext = () => React.useContext(ComponentConfigContext);
