import React from "react";

import { ApolloConsumer, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { QueryHookOptions, QueryResult } from "react-apollo";
import { useParams, Navigate } from "react-router";

import { Routes } from "../../../../constants";
import { setClientEnvironmentId } from "../../../../graphql/environment";
import { Connection, EnvironmentNode, AuthStatus, Edge } from "../../../../types";
import useAuthStatus from "../../hooks/useAuthStatus";
import useAuthUser from "../../hooks/useAuthUser";

const SCOPES = ["space:admin"];

export type Result = {
  getCurrentEnvironment: () => EnvironmentNode;
  visibleEnvironments: EnvironmentNode[];
};

export const makeEnvironmentContextResult = (
  environmentOptions?: Partial<EnvironmentNode>,
  visibleEnvironments?: EnvironmentNode[]
): Result => ({
  visibleEnvironments: visibleEnvironments || [],
  getCurrentEnvironment: () =>
    ({
      id: "id",
      slug: "slug",
      ...environmentOptions
    } as EnvironmentNode)
});

export const EnvironmentContext = React.createContext<Result>(
  makeEnvironmentContextResult({
    id: "",
    slug: ""
  })
);

export const ALL_ENVIRONMENTS = gql`
  query AllEnvironments($searchText: String, $first: Int = 50, $offset: Int = 0) {
    allEnvironments(searchText: $searchText, first: $first, offset: $offset) {
      pageInfo {
        __typename
        endCursor
        hasNextPage
        hasPreviousPage
      }
      __typename
      edges {
        __typename
        node {
          __typename
          id
          name
          isDefault
          slug
        }
      }
    }
  }
`;

export const ENVIRONMENT = gql`
  query Environment($slug: String, $default: Boolean = false) {
    environment(slug: $slug, default: $default) {
      __typename
      id
      name
      isDefault
      slug
    }
  }
`;

export interface AllEnvironmentsData {
  allEnvironments: Connection<EnvironmentNode>;
}

export interface AllEnvironmentsVariables {
  searchText?: string;
  first?: number;
  offset?: number;
}

export interface EnvironmentData {
  environment?: EnvironmentNode;
}

export interface EnvironmentVariables {
  slug?: string;
  default?: boolean;
}

/**
 * useEnvironmentsQuery wraps the apollo query so that we cache the results for each environment.
 * That way, each call to get the individual environment is fast.
 */
export function useEnvironmentsQuery(
  options: QueryHookOptions<AllEnvironmentsData, AllEnvironmentsVariables>
): QueryResult<AllEnvironmentsData, AllEnvironmentsVariables> {
  const { onCompleted, ...data } = options;

  const onCompletedCallback = React.useCallback(
    (environmentData, client) => {
      environmentData?.allEnvironments.edges.forEach((edge: Edge<EnvironmentNode>) => {
        client.writeQuery({
          query: ENVIRONMENT,
          variables: {
            slug: edge.node.slug
          },
          data: {
            environment: edge.node
          }
        });
      });

      onCompleted?.(environmentData);
    },
    [onCompleted]
  );

  const result: QueryResult<AllEnvironmentsData, AllEnvironmentsVariables> = useQuery<
    AllEnvironmentsData,
    AllEnvironmentsVariables
  >(ALL_ENVIRONMENTS, {
    ...data,
    onCompleted: data => onCompletedCallback(data, result.client)
  });

  return result;
}

interface Props {
  children: React.ReactNode;
  environment?: EnvironmentNode;
}

export function EnvironmentContextProvider(props: Props) {
  const { status } = useAuthStatus();
  const params = useParams<{
    environment: string | undefined;
  }>();

  const { data, loading } = useEnvironmentsQuery({
    skip: status !== AuthStatus.Authenticated
  });

  const environmentSlug = props.environment?.slug || params.environment;

  const { data: environmentFromParams, loading: environmentLoading } = useQuery<
    EnvironmentData,
    EnvironmentVariables
  >(ENVIRONMENT, {
    skip: status !== AuthStatus.Authenticated || !environmentSlug,
    variables: { slug: environmentSlug! }
  });

  const { data: defaultEnvironment, loading: defaultEnvironmentLoading } = useQuery<
    EnvironmentData,
    EnvironmentVariables
  >(ENVIRONMENT, {
    skip: status !== AuthStatus.Authenticated,
    variables: { default: true }
  });

  const { loading: userLoading, authUser, isAdmin } = useAuthUser();

  const hasAccessToNonDefaultEnvironments = React.useMemo(() => {
    return isAdmin || SCOPES.every(scope => authUser?.scopes?.includes(scope));
  }, [isAdmin, authUser]);

  const visibleEnvironments = React.useMemo(() => {
    if (!data || !data.allEnvironments) return [];
    if (!hasAccessToNonDefaultEnvironments) return [];
    return data.allEnvironments.edges.map(e => e.node);
  }, [data, hasAccessToNonDefaultEnvironments]);

  const currentEnvironment = React.useMemo(() => {
    if (
      environmentFromParams?.environment &&
      (hasAccessToNonDefaultEnvironments || environmentFromParams.environment.isDefault)
    ) {
      return environmentFromParams.environment;
    }
    if (!environmentSlug && defaultEnvironment?.environment) {
      return defaultEnvironment.environment;
    }
    return null;
  }, [
    environmentSlug,
    environmentFromParams,
    defaultEnvironment,
    hasAccessToNonDefaultEnvironments
  ]);

  const getCurrentEnvironment = React.useCallback(() => {
    if (!currentEnvironment) throw new Error("Environment not available.");
    return currentEnvironment;
  }, [currentEnvironment]);

  const value = React.useMemo(
    () => ({
      visibleEnvironments,
      getCurrentEnvironment
    }),
    [visibleEnvironments, getCurrentEnvironment]
  );

  if (status === AuthStatus.Authenticated) {
    if (loading || userLoading || defaultEnvironmentLoading || environmentLoading)
      return null;

    if (environmentSlug && !currentEnvironment) {
      return <Navigate to={Routes.NOT_FOUND} />;
    }
  }

  return (
    <EnvironmentContext.Provider key={value.getCurrentEnvironment().slug} value={value}>
      <ApolloConsumer>
        {client => {
          setClientEnvironmentId(client, currentEnvironment?.id);
          return null;
        }}
      </ApolloConsumer>
      {currentEnvironment === null ? null : props.children}
    </EnvironmentContext.Provider>
  );
}

export function useAccessibleEnvironments(environments?: EnvironmentNode[]) {
  const { loading, authUser, isAdmin } = useAuthUser();

  const hasAccessToNonDefaultEnvironments = React.useMemo(() => {
    return isAdmin || SCOPES.every(scope => authUser?.scopes?.includes(scope));
  }, [isAdmin, authUser]);

  if (environments && hasAccessToNonDefaultEnvironments) {
    return {
      loading,
      accessibleEnvironments: environments
    };
  }

  return {
    loading,
    accessibleEnvironments: []
  };
}

export const useEnvironmentContext = () => React.useContext(EnvironmentContext);
