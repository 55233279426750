import { cloneDeep, flow, partial } from "lodash";

import { SpaceComponentObject } from "../../../../../../types";
import commonComponentReducer from "../../../../SpaceConfig/SpaceConfigContext/useSpaceConfig/reducer/componentReducer";
import {
  SpaceConfigAction,
  BaseComponentConfigState,
  ComponentConfigState
} from "../../../../types";
import { SpaceTabsComponent } from "../types";

export interface TabsConfigState extends BaseComponentConfigState<SpaceTabsComponent> {
  type: "TABS";
  initialized: boolean;
}

export const INITIAL_STATE: TabsConfigState = {
  type: "TABS" as const,
  initialized: false,
  draftComponent: {} as SpaceTabsComponent
};

function isTabsConfigState(state: ComponentConfigState): state is TabsConfigState {
  return state.type === "TABS";
}

export function ensureTabsConfigState(state: ComponentConfigState): TabsConfigState {
  if (isTabsConfigState(state)) return state;

  throw new Error("ExpectedTabs config state.");
}

export function ensureSpaceTabsComponent(
  component: SpaceComponentObject
): SpaceTabsComponent {
  if (Array.isArray(component.properties.tabs)) return component;
  return {
    ...component,
    properties: {
      tabs: [],
      ...component.properties
    }
  };
}

export function makeInitialState(
  draftComponent: SpaceComponentObject
): TabsConfigState {
  return {
    ...INITIAL_STATE,
    initialized: draftComponent.properties.tabs.length > 0,
    draftComponent: ensureSpaceTabsComponent(cloneDeep(draftComponent))
  };
}

function reducer(state: TabsConfigState, action: SpaceConfigAction): TabsConfigState {
  switch (action.type) {
    case "SET_DRAFT_COMPONENT": {
      const { path, value } = action.payload;
      if (path === "properties.tabs" && value.length && !state.initialized) {
        return {
          ...state,
          initialized: true
        };
      }

      return state;
    }

    default:
      return state;
  }
}

export default (state: TabsConfigState, action: SpaceConfigAction) =>
  flow([commonComponentReducer, partial(reducer, partial.placeholder, action)])(
    state,
    action
  );
