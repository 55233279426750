import React, { useCallback, useEffect } from "react";

import { Input } from "antd";
import styled from "styled-components";

import SortableList, { SortableItemCompact } from "../../../../../common/SortableList";
import Spacer from "../../../../../common/Spacer";
import { useTransformationActionContext } from "../../../../layout/TransformationContext/TransformationContext";
import { useSpaceConfigContext } from "../../../../SpaceConfig/SpaceConfigContext";
import Button from "../../common/Button";
import { useComponentConfigContext } from "../../common/ComponentConfigContext";
import { ConfigPanelPopper, ConfigSection } from "../../common/ConfigPanel";
import {
  ConfigPanelActionTypes,
  useSpaceConfigPanelContext
} from "../../common/ConfigPanel/ConfigPanelContext";
import useCreateSubSpace from "../../common/useCreateSubSpace/useCreateSubSpace";
import { Tab } from "../types";

import { ensureTabsConfigState } from "./reducer";

export default function TabsSection() {
  const { state, dispatch } = useComponentConfigContext();
  const tabConfigState = ensureTabsConfigState(state);
  const { draftComponent } = tabConfigState;
  const createSubSpace = useCreateSubSpace();

  const addTab = useCallback(() => {
    const subSpaceName = `Tab ${draftComponent.properties.tabs.length + 1}`;
    const subSpaceSlug = createSubSpace(draftComponent.slug, subSpaceName);
    dispatch({
      type: "SET_DRAFT_COMPONENT",
      payload: {
        path: "properties.tabs",
        value: [
          ...draftComponent.properties.tabs,
          {
            name: "Untitled tab",
            component_slug: subSpaceSlug
          }
        ]
      }
    });
  }, [draftComponent, dispatch, createSubSpace]);

  // If tabs have not been initialized, create the first one
  useEffect(() => {
    if (!tabConfigState.initialized) {
      addTab();
    }
  }, [tabConfigState.initialized, addTab]);

  return (
    <ConfigSection id="tabs-config-section" title="Tabs" onAdd={addTab}>
      <TabList tabs={draftComponent.properties.tabs} />
    </ConfigSection>
  );
}

function TabList({ tabs }: { tabs: Tab[] }) {
  const { errors, dispatch } = useComponentConfigContext();
  const configPanelContext = useSpaceConfigPanelContext();
  const rootConfigContext = useSpaceConfigContext();
  const { select } = useTransformationActionContext();

  if (tabs.length === 0)
    return (
      <EmptyState>
        <EmptyStateMessage>No tabs present.</EmptyStateMessage>
        <FinePrint>Add tabs with the + button.</FinePrint>
      </EmptyState>
    );

  return (
    <>
      <SortableList
        isCompact
        onSort={(currentIndex: number, nextIndex: number) => {
          const copy = [...tabs];
          const tab = copy.splice(currentIndex, 1)[0];
          copy.splice(nextIndex, 0, tab);
          dispatch({
            type: "SET_DRAFT_COMPONENT",
            payload: { path: "properties.tabs", value: copy }
          });
        }}
      >
        {tabs.map((t, i) => {
          const key = t.component_slug;
          const err =
            errors.find(err => err.field === "TABS" && err.index === i)?.message ||
            null;
          const isPopperOpen = configPanelContext.state.activePopperIdentifier === key;
          return (
            <SortableItemCompact
              id={key}
              key={key}
              sortKey={key}
              errorMessage={err}
              isSelected={false}
              onClick={() => {
                configPanelContext.dispatch({
                  type: ConfigPanelActionTypes.OPEN_POPPER,
                  payload: {
                    popperIdentifier: key
                  }
                });
              }}
              onRemove={sortKey => {
                const idx = tabs.findIndex(t => t.component_slug === sortKey);
                const copy = [...tabs];
                const removed = copy.splice(idx, 1);
                dispatch({
                  type: "SET_DRAFT_COMPONENT",
                  payload: { path: "properties.tabs", value: copy }
                });
                rootConfigContext.dispatch({
                  type: "REMOVE_COMPONENT",
                  payload: { slug: removed[0].component_slug }
                });
              }}
            >
              {t.name}
              {isPopperOpen && (
                <ConfigPanelPopper
                  popperId={key}
                  popperReferenceElement={document.getElementById(key) || undefined}
                  onCancel={() => {
                    configPanelContext.dispatch({
                      type: ConfigPanelActionTypes.CLOSE_POPPER
                    });
                  }}
                >
                  <ConfigSection title="Tab">
                    <label>Name</label>
                    <Input
                      value={t.name}
                      onChange={evt => {
                        dispatch({
                          type: "SET_DRAFT_COMPONENT",
                          payload: {
                            path: `properties.tabs[${i}].name`,
                            value: evt.target.value
                          }
                        });
                      }}
                    />
                    <Spacer size="md" />
                    <Button
                      type="link"
                      onClick={() => {
                        select(key);
                      }}
                    >
                      Go to this component
                    </Button>
                  </ConfigSection>
                </ConfigPanelPopper>
              )}
            </SortableItemCompact>
          );
        })}
      </SortableList>
    </>
  );
}

const EmptyState = styled.div`
  text-align: center;
`;

const EmptyStateMessage = styled.div`
  font-size: ${props => props.theme.defaultFontSize};
  color: ${props => props.theme.textColor};
  padding: ${props => props.theme.spacersm} 0;
  font-weight: 500;
`;

const FinePrint = styled.div`
  font-size: ${props => props.theme.smallFontSize};
  color: ${props => props.theme.textColorMid};
`;
