import React from "react";

import styled from "styled-components";

import { B3 } from "../../../../common/StyledComponents";
import ActionIcon from "../../../Config/ActionsAndEnvironments/ActionPicker/ActionIcon";
import { ExecutionStatus, UserNode } from "../../queries/common";
import RunDateAndUser from "../RunDateAndUser";
import RunStatusIcon from "../RunStatusIcon";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.spacermd};
  background-color: ${props => props.theme.innerContainerBackground};
  border: 1px solid ${props => props.theme.innerContainerBorderColor};
  border-radius: ${props => props.theme.containerPrimaryBorderRadius};
  cursor: pointer;

  & + & {
    margin-top: ${props => props.theme.spacermd};
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${props => props.theme.spacermd};
`;

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
`;

const StyledActionIcon = styled(ActionIcon)`
  width: 24px;
  height: 24px;
  background: none;
  margin-right: ${props => props.theme.spacersm};
`;

interface Props {
  title: string;
  integration: string | undefined;
  status: ExecutionStatus;
  user: UserNode;
  createdAt: string;
  onClick: () => void;
}
export const ActivityCard = (props: Props) => {
  return (
    <Container onClick={props.onClick}>
      <Header>
        <HeaderTitle>
          <StyledActionIcon integration={props.integration} />
          <B3>{props.title}</B3>
        </HeaderTitle>
        <RunStatusIcon status={props.status} />
      </Header>
      <RunDateAndUser user={props.user} createdAt={props.createdAt} />
    </Container>
  );
};

export default ActivityCard;
