import React from "react";

import { isEqual } from "lodash";

import {
  AttributeNode,
  FunctionAttributeNode,
  FunctionNode,
  SortByOption,
  SpaceComponentObject
} from "../../../../../types";
import { Column } from "../../../../common/Table";
import { assertNever } from "../../../../util/assertNever";
import SpaceApi from "../../../SpaceApi";
import { Column as ColumnProperty, ColumnType } from "../common/ColumnListManager";
import { useAccess } from "../common/useFunctionAccess";
import { areComponentsEqual } from "../SpaceComponent";

import { AttributeCell, ComponentCell } from "./Cell";
import { SpaceTableComponent } from "./types";

type BaseSpaceTableColumn = Column & {
  options?: ColumnProperty;
  hidden?: boolean;
  show?: boolean;
  columnType?: ColumnType;
  disableSortBy?: boolean;
};

export type SpaceTableAttributeColumn = BaseSpaceTableColumn & {
  attribute: AttributeNode;
};

export type SpaceTableComponentColumn = BaseSpaceTableColumn & {
  spaceApi: SpaceApi;
  component: SpaceComponentObject;
  attributes: AttributeNode[];
};

export type SpaceTableSpaceStateRowColumn = BaseSpaceTableColumn & {
  id: "spaceStateRow";
};

export type SpaceTableColumn =
  | SpaceTableAttributeColumn
  | SpaceTableComponentColumn
  | SpaceTableSpaceStateRowColumn;

interface _ViewRowsResult {
  attributes: (AttributeNode | FunctionAttributeNode)[];
  sortByOptions: SortByOption[];
  viewFunction: FunctionNode | null;
}

function areColsEqual(a: SpaceTableColumn[], b: SpaceTableColumn[]) {
  if (a === b) return true;
  if (a.length !== b.length) return false;
  return a.every((colA, i) => {
    if (colA.columnType === `${ColumnType.ATTRIBUTE}Column`) {
      return isEqual(colA, b[i]);
    } else if (colA.columnType === `${ColumnType.COMPONENT}Column`) {
      const { component: componentA, ...othersA } = colA as SpaceTableComponentColumn;
      const { component: componentB, ...othersB } = b[i] as SpaceTableComponentColumn;
      if (isEqual(othersA, othersB)) {
        // areComponentsEqual does not compare other nodes in the
        // compoonent graph, instead just checking that parents and
        // chidren have the same slug
        return areComponentsEqual(componentA, componentB);
      }
      return false;
    }
    return true;
  });
}

export default function useColumns(
  { properties: { columns }, componentTreeNodes }: SpaceTableComponent,
  { attributes, sortByOptions, viewFunction }: _ViewRowsResult,
  spaceApi: SpaceApi
) {
  const stableRef = React.useRef<SpaceTableColumn[]>([]);
  const access = useAccess(viewFunction?.access);
  const cols = React.useMemo(() => {
    const cols = (columns || [])
      .map(col => {
        switch (col.column_type) {
          case ColumnType.ATTRIBUTE: {
            const attr = attributes.find(a => a.sourceName === col.attribute);
            if (!attr) return null;
            const hasPermission =
              access.attributeAllowed(attr.sourceName) ||
              (attr as AttributeNode).canRead;
            const visible = hasPermission && !col?.hidden;

            return {
              id: attr.sourceName,
              accessor: attr.id,
              Header: col.label ? col.label : attr.name,
              disableSortBy: !sortByOptions.some(o => o.sourceName === attr.sourceName),
              columnType: `${ColumnType.ATTRIBUTE}Column`,
              attribute: attr,
              options: col,
              hidden: !visible,
              Cell: AttributeCell
            };
          }
          case ColumnType.COMPONENT: {
            const component = componentTreeNodes.find(
              c => c.slug === col.component_slug
            );
            if (component === undefined) return null;

            return {
              accessor: `component-column-${col.component_slug}`,
              Header: component.properties.label || "",
              disableSortBy: true,
              columnType: `${ColumnType.COMPONENT}Column`,
              spaceApi,
              component,
              attributes,
              options: col,
              Cell: ComponentCell
            };
          }
          default:
            return assertNever(col);
        }
      })
      .filter(c => c !== null && !!c.accessor) as SpaceTableColumn[];

    // Add a column to store the space state encoded version of a row that
    // does an empty render
    cols.push({
      id: "spaceStateRow",
      hidden: true,
      disableSortBy: true
    });
    return cols;
  }, [columns, attributes, access, componentTreeNodes, spaceApi, sortByOptions]);

  if (areColsEqual(cols, stableRef.current)) {
    return stableRef.current;
  }
  stableRef.current = cols;
  return cols;
}
