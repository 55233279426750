import gql from "graphql-tag";
import { QueryHookOptions, useQuery } from "react-apollo";

import { Connection, DataSourceNode, FunctionNode } from "../../../types";

export interface DataSourceData {
  allDataSources: Connection<DataSourceNode>;
}
export interface DataSourceVariables {
  integrations?: string[];
}
export const DATA_SOURCE_QUERY = gql`
  query FunctionPickerDataSources($integrations: [String]) {
    allDataSources(integrationIn: $integrations) {
      __typename
      edges {
        __typename
        node {
          __typename
          id
          name
          integration
          integrationTitle
        }
      }
    }
  }
`;

export const useGetDataSources = (
  options?: QueryHookOptions<DataSourceData, DataSourceVariables>
) => {
  return useQuery<DataSourceData, DataSourceVariables>(DATA_SOURCE_QUERY, options);
};

export interface FunctionData {
  allFunctions: Connection<FunctionNode>;
}
export interface FunctionVariables {
  categories?: string[];
  functionScope: string;
  dataSourceId?: string;
  searchText?: string;
}
export const FUNCTION_QUERY = gql`
  query FunctionPickerFunctions(
    $categories: [String!]
    $functionScope: String!
    $dataSourceId: ID
    $searchText: String
  ) {
    allFunctions(
      categories: $categories
      scope: $functionScope
      dataSourceId: $dataSourceId
      searchText: $searchText
    ) {
      edges {
        __typename
        node {
          __typename
          id
          name
          title
          isUserGenerated
          isPipeline
        }
      }
    }
  }
`;

export const useGetFunctions = (
  options?: QueryHookOptions<FunctionData, FunctionVariables>
) => {
  return useQuery<FunctionData, FunctionVariables>(FUNCTION_QUERY, options);
};
