import React from "react";

import {
  Circle,
  CircleLineContainer,
  Container,
  FullHeightLine,
  Line
} from "./styledComponents";

export interface Props {
  number: number;
  showBottomLine?: boolean;
  selected?: boolean;
  children?: React.ReactNode;
  className?: string;
}

export default function Step({
  number,
  selected,
  showBottomLine,
  children,
  className
}: Props) {
  return (
    <Container className={className}>
      <CircleLineContainer>
        <Line visible={number > 1} length={30} />
        <Circle selected={selected}>{number}</Circle>
        {showBottomLine && <FullHeightLine visible={true} />}
      </CircleLineContainer>
      {children}
    </Container>
  );
}
