import Icon from "../../../../../assets/spaceicons/tags.svg";
import {
  SpaceComponentPackage,
  SpaceComponentHostType,
  SpaceComponentTags,
  Binding,
  BindingShape
} from "../../../../../types";
import { DEFAULT_SIZES, INPUT_MIN_WIDTH } from "../../../layout/constants";
import { ElementLayout } from "../../../layout/util";
import { SpaceDefinition } from "../../../types";
import { ComponentNode } from "../../RenderTreeContext";
import { getColumnsSchema } from "../common/ColumnListManager/getColumnsOutputShape";
import errorSelector from "../common/SelectBackedComponent/Config/errorSelector";
import componentConfigReducer from "../common/SelectBackedComponent/Config/reducer";
import { SelectConfigState } from "../common/SelectBackedComponent/types";

import Config, { InlineConfig, TerseConfig } from "./Config";
import SpaceTagSelector from "./SpaceTagSelector";
import { SpaceTagSelectorComponent, SpaceTagSelectorProperties } from "./types";
import {
  ensureTagSelectorComponent,
  getComponentProperties,
  makeInitialState
} from "./utils";

const pkg: SpaceComponentPackage<
  SpaceTagSelectorProperties,
  SpaceTagSelectorComponent,
  SelectConfigState
> = {
  type: "TAG_SELECTOR",
  displayName: "Tag Selector",
  Icon,
  allowedHosts: new Set<SpaceComponentHostType>([
    "SPACE",
    "TABLE",
    "DETAIL",
    "CARD_LIST",
    "FUNCTION_FORM",
    "FUNCTION_MODAL_FORM",
    "FLEX_BOX",
    "CLOUD_UPLOADER"
  ]),
  Component: SpaceTagSelector,
  ConfigurationComponent: Config,
  TerseConfigurationComponent: TerseConfig,
  InlineConfigurationComponent: InlineConfig,
  componentConfigReducer,
  getInitialDraftState: makeInitialState,
  ensureComponent: ensureTagSelectorComponent,
  getComponentProperties,
  getSchema,
  allowSelfBinding: true,
  defaultElementLayout: new ElementLayout({
    width: DEFAULT_SIZES.INPUT.width,
    height: DEFAULT_SIZES.INPUT.height,
    snapToGrid: false,
    minWidth: INPUT_MIN_WIDTH
  }),
  allowAncestorBinding: false,
  tags: [SpaceComponentTags.Input],
  isHeadless: false,
  errorSelector,
  isContainer: false
};

export default pkg;

export function getSchema(
  node: ComponentNode,
  spaces: Map<string, SpaceDefinition>
): Binding[] {
  return [
    {
      name: "value",
      shape: node.component.properties.serialize_value_as_csv
        ? BindingShape.SCALAR
        : BindingShape.SCALAR_ARRAY
    },
    {
      name: "error",
      shape: BindingShape.UNKNOWN
    },
    {
      name: "options",
      shape: BindingShape.OBJECT_ARRAY,
      attributes: getColumnsSchema(node, spaces)
    }
  ];
}
