import React from "react";

import useAuthUser from "../../../../common/hooks/useAuthUser";
import { getCurrentUserValues } from "../../../../common/schema";
import { useComponentStateContext } from "../contexts/ComponentStateContext";

export default function SpaceUser() {
  const { updateOutput } = useComponentStateContext();
  const { authUser } = useAuthUser();

  React.useEffect(() => {
    updateOutput(getCurrentUserValues(authUser));
  }, [authUser, updateOutput]);

  return null;
}
