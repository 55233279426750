import React from "react";

import { Button, Popover } from "antd";
import { uniqueId } from "lodash";
import styled from "styled-components";

import {
  AttributeTypes,
  DEFAULT_THEME_POPPER_PORTAL_ID
} from "../../../../../../../../constants";
import { AttributeNode, FunctionAttributeNode } from "../../../../../../../../types";
import ColorPicker from "../../../../../../../common/ColorPicker/ColorPicker";
import DebouncedInput from "../../../../../../../common/DebouncedInput";
import IconButton from "../../../../../../../common/IconButton";
import PresetIcon from "../../../../../../../common/PresetIcon";
import {
  RenderType,
  ValueRenderOption,
  RenderIcon
} from "../../../AttributeValue/options";
import { useSpaceConfigPanelContext } from "../../../ConfigPanel/ConfigPanelContext";
import { Select } from "../../../ConfigPanel/styledComponents";

interface Props {
  type: RenderType;
  attribute: AttributeNode | FunctionAttributeNode;
  renderOptions: ValueRenderOption[];
  setRenderOptions: (renderOptions: ValueRenderOption[]) => void;
}

export const booleanValueToString = (value: any) => {
  if (value === true) return "True";
  if (value === false) return "False";
  return "Null";
};

export const getValue = (value: any, attribute: AttributeNode) => {
  return attribute.sourceType !== AttributeTypes.STRING && !isNaN(parseFloat(value))
    ? parseFloat(value)
    : value;
};

const Container = styled.div`
  min-width: 186px;
  max-width: 188px;
`;

// styles used for config panel popper
const ScrollableContainer = styled.div`
  max-height: 300px;
  overflow: auto;
  margin: 0 -${props => props.theme.spacermd}; // negative margin so scrollbars appear at edge of container
  padding: 0 ${props => props.theme.spacermd};
`;

const Root = styled.div`
  display: flex;
  margin-bottom: ${props => props.theme.spacermd};

  & > *:not(:first-child) {
    margin-left: ${props => props.theme.spacersm};
  }

  & + & {
    padding-top: ${props => props.theme.spacermd};
    border-top: 1px solid ${props => props.theme.tableBorderColor};
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledIconButton = styled(IconButton)`
  flex-shrink: 0;
`;

const ButtonForIconSelector = styled(Button)`
  min-width: 44px; // width when icon is selected
`;

const SelectContainer = styled.div`
  ${Select} {
    min-width: 60px;
    max-width: "10%";

    .ant-select-dropdown-menu-item {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  input + input {
    margin-top: ${props => props.theme.spacersm};
  }
`;

export default function ValueRenderOptions({
  attribute,
  type,
  setRenderOptions,
  renderOptions
}: Props) {
  const { generateGetPopupContainer } = useSpaceConfigPanelContext();
  const isBoolean = attribute.sourceType === AttributeTypes.BOOL;
  const boolRootId = uniqueId("boolroot");

  return (
    <>
      {!isBoolean && (
        <Header>
          <label>Rules</label>
          <StyledIconButton
            data-test="addAnotherOption"
            icon="plus"
            onClick={() => {
              setRenderOptions([...renderOptions, { value: "", label: "" }]);
            }}
          />
        </Header>
      )}
      <ScrollableContainer>
        {renderOptions.map((option: ValueRenderOption, i: number) => (
          <Root key={attribute.id + i} data-test="valueOptionRoot">
            {type === RenderType.ICON && (
              <Popover
                getPopupContainer={generateGetPopupContainer(
                  DEFAULT_THEME_POPPER_PORTAL_ID
                )}
                placement="left"
                arrowPointAtCenter
                content={
                  <Container>
                    {Object.values(RenderIcon).map(icon => (
                      <Button
                        key={`${icon}-button`}
                        onClick={() =>
                          setRenderOptions(
                            renderOptions.map((o: ValueRenderOption, j: number) => {
                              return i === j ? { ...o, icon } : o;
                            })
                          )
                        }
                        type="link"
                      >
                        <PresetIcon type={icon} inConfig />
                      </Button>
                    ))}
                  </Container>
                }
                trigger="click"
                style={{ pointerEvents: "all" }}
              >
                <ButtonForIconSelector id={boolRootId} data-test="iconSelect">
                  <PresetIcon type={option.icon || RenderIcon.NONE} inConfig />
                </ButtonForIconSelector>
              </Popover>
            )}
            {type === RenderType.PILLBOX && (
              // wrapping the Select so that the dropdown appears properly below the Select,
              // otherwise height of Select is too large due to flex row layout and dropdown appears too low
              <SelectContainer>
                <ColorPicker
                  data-test="pillboxColorPicker"
                  value={option.schema || "#FF0000"}
                  key={attribute.id + i + "pillbox"}
                  getPopupContainer={generateGetPopupContainer(
                    DEFAULT_THEME_POPPER_PORTAL_ID
                  )}
                  onChange={(color: string) => {
                    const nextOptions = [...renderOptions];
                    nextOptions.splice(i, 1, {
                      ...renderOptions[i],
                      schema: color
                    });
                    setRenderOptions(nextOptions);
                  }}
                />
              </SelectContainer>
            )}
            <InputContainer>
              <DebouncedInput
                data-test="labelInput"
                placeholder="Display Name"
                key={attribute.id + "label" + i}
                value={option!.label}
                onChange={value => {
                  const nextOptions = [...renderOptions];
                  nextOptions.splice(i, 1, {
                    ...renderOptions[i],
                    label: value
                  });
                  setRenderOptions(nextOptions);
                }}
              />
              <DebouncedInput
                data-test="valueInput"
                placeholder="Actual Value"
                onChange={value => {
                  const nextOptions = [...renderOptions];
                  nextOptions.splice(i, 1, {
                    ...renderOptions[i],
                    value: getValue(value, attribute as AttributeNode)
                  });
                  setRenderOptions(nextOptions);
                }}
                value={isBoolean ? booleanValueToString(option.value) : option.value}
                disabled={isBoolean}
              />
            </InputContainer>

            {!isBoolean && (
              <StyledIconButton
                className="deleteButton"
                data-test="deleteButton"
                icon="minus"
                title="Remove item from list"
                onClick={() => {
                  const newRenderOptions = [...renderOptions];
                  newRenderOptions.splice(i, 1);
                  setRenderOptions(newRenderOptions);
                }}
              />
            )}
          </Root>
        ))}
      </ScrollableContainer>
    </>
  );
}
