import { cloneDeep } from "lodash";

import { SpaceComponentObject } from "../../../../../../../types";
import { TreeNode } from "../reducer";

export default function insertComponentInTree(
  node: TreeNode | null,
  component: SpaceComponentObject,
  parentSlug: string | null,
  index?: number
) {
  return _insertComponentInTree(cloneDeep(node), component, parentSlug, index ?? null);
}

function _insertComponentInTree(
  node: TreeNode | null,
  component: SpaceComponentObject,
  parentSlug: string | null,
  index: number | null,
  existingNode?: TreeNode
): TreeNode | null {
  if (node === null) return null;
  if (parentSlug === null || node.slug === parentSlug) {
    // Just append new component to current parent's children
    const nodeToInsert: TreeNode = existingNode || {
      slug: component.slug,
      type: component.type,
      treeNodes: [],
      container: null
    };
    nodeToInsert.container = node;
    node.treeNodes.splice(index ?? node.treeNodes.length, 0, nodeToInsert);
    return node;
  } else {
    node.treeNodes = node.treeNodes.map(
      ltn =>
        _insertComponentInTree(
          ltn,
          component,
          parentSlug,
          index,
          existingNode
        ) as TreeNode
    );
  }

  return node;
}

export function insertNodeInTree(
  tree: TreeNode,
  node: TreeNode,
  parentSlug: string | null,
  index?: number
) {
  return _insertComponentInTree(
    cloneDeep(tree),
    { slug: node.slug, type: node.type } as SpaceComponentObject,
    parentSlug,
    index ?? null,
    node
  );
}
