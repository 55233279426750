import React, { useRef, useState } from "react";

import Moment from "moment";
import pluralize from "pluralize";

import { useInterval } from "../hooks/useInterval";
import { parseISODate } from "../utils";

interface Props {
  isoDate: string;
  className?: string;
}

function durationStringFromNow(when: Moment.Moment): string {
  const duration = Moment.duration(Moment().diff(when));
  const days = Math.floor(duration.asDays());
  const hours = duration.get("h");
  const minutes = duration.get("m");

  const daysString = days > 0 ? `${days} ${pluralize("day", days)} ` : "";
  const hoursString = `${hours} ${pluralize("hour", hours)} `;
  const minutesString = `${minutes} min`;

  return `${daysString}${hoursString}${minutesString}`;
}

export function TimeDurationMessage({ isoDate, className }: Props) {
  const momentDateRef = useRef(Moment(parseISODate(isoDate)));
  const [message, setMessage] = useState(durationStringFromNow(momentDateRef.current));

  useInterval(() => {
    const message = durationStringFromNow(momentDateRef.current);
    setMessage(message);
  }, 1000);

  return <div className={className}>{message}</div>;
}
