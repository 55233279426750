import React, { ComponentType } from "react";

import styled from "styled-components";

import { ErrorIcon } from "../../../../../common/Icons";
import { WarningTooltip } from "../../../../../common/ToolTips";

export function HeadlessComponentIcon({
  Icon,
  hasConfigError
}: {
  Icon: ComponentType;
  hasConfigError: boolean;
}) {
  return (
    <ConfigRoot>
      <Icon />
      {hasConfigError && <AbsErrorIcon />}
    </ConfigRoot>
  );
}

export function HeadlessComponentErrorIcon({
  message,
  tooltipMessage,
  tooltipDescription
}: {
  message: string;
  tooltipMessage: string;
  tooltipDescription: string;
}) {
  return (
    <WarningTooltip
      message={tooltipMessage}
      description={tooltipDescription}
      placement="left"
    >
      <Error>
        <ErrorIcon />
        <div>{message}</div>
      </Error>
    </WarningTooltip>
  );
}

export const ConfigRoot = styled.div`
  border: 1px dotted black;
  border-radius: 12px;
  display: flex;
  gap: ${props => props.theme.spacersm};
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 80px;
  & > svg {
    width: 100%;
    height: 100%;
  }
`;
ConfigRoot.displayName = "ConfigRoot";

export const Error = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.errorColor};
`;
Error.displayName = "Error";

export const AbsErrorIcon = styled(ErrorIcon)`
  position: absolute;
  right: 2px;
  top: 2px;
`;
