import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { setContext } from "apollo-link-context";
import gql from "graphql-tag";

export const CURRENT_BRANCH = gql`
  query GetCurrentBranch {
    currentBranch {
      __typename
      name
    }
  }
`;

export const setClientBranch = (
  client: ApolloClient<object>,
  branch: string | undefined
): void => {
  client.writeQuery({
    query: CURRENT_BRANCH,
    data: {
      currentBranch: {
        name: branch,
        __typename: "BranchNode"
      }
    }
  });
};

interface CurrentBranchData {
  currentBranch?: {
    name: string;
  };
}

export const createBranchLink = (cache: InMemoryCache) => {
  return setContext((_, { headers }: Record<string, any>) => {
    try {
      const data = cache.readQuery<CurrentBranchData>({
        query: CURRENT_BRANCH
      });
      headers["X-Branch"] = data?.currentBranch?.name;
    } catch (e) {
      // ignore error
    }
    return { headers };
  });
};
