import styled from "styled-components";

export const Container = styled.div<{ depth: number; mode: "dark" | "light" }>`
  ${props => `&`.repeat(props.depth)} {
    /* suggested technique for increasing specificity: https://styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity */

    color-scheme: ${props => props.mode};

    h1,
    h2,
    h3,
    h4,
    h5 {
      color: ${props => props.theme.textColor};
    }

    h6 {
      color: ${props => props.theme.h6Color};
    }

    /* buttons */
    .ant-btn.ant-btn-link {
      &[disabled] {
        color: ${props => props.theme.buttonDisabledColor};
      }
    }

    .ant-btn:not(.ant-btn-link):not(.new-btn) {
      color: ${props => props.theme.buttonColor};
      background: ${props => props.theme.buttonBackground};
      border: ${props => props.theme.buttonBorder};
      font-weight: ${props => props.theme.buttonFontWeight};
      min-height: ${props => props.theme.inputHeight};
      &.ant-btn-sm {
        min-height: ${props => props.theme.inputHeightSm};
      }

      &:hover {
        color: ${props => props.theme.buttonHoverColor};
        background: ${props => props.theme.buttonHoverBackground};
        border-color: ${props => props.theme.buttonHoverBorderColor};
      }
      &:focus {
        color: ${props => props.theme.buttonFocusColor};
        background: ${props => props.theme.buttonFocusBackground};
        border-color: ${props => props.theme.buttonHoverBorderColor};
      }
      &:active {
        color: ${props => props.theme.buttonFocusColor};
        background: ${props => props.theme.buttonActiveBackground};
      }
      &[disabled] {
        color: ${props => props.theme.buttonDisabledColor};
        background: ${props => props.theme.buttonDisabledBackground};
        border-color: ${props => props.theme.buttonDisabledBorderColor};
      }

      &.ant-btn-primary {
        color: ${props => props.theme.primaryButtonColor};
        background: ${props => props.theme.primaryButtonBackground};
        border: transparent;

        &:hover {
          color: ${props => props.theme.primaryButtonColor};
          background: ${props => props.theme.primaryButtonHoverBackground};
        }
        &:focus {
          color: ${props => props.theme.primaryButtonColor};
          background: ${props => props.theme.primaryButtonFocusBackground};
        }
        &:active {
          color: ${props => props.theme.primaryButtonColor};
          background: ${props => props.theme.primaryButtonActiveBackground};
        }
        &[disabled],
        &.disabled,
        &.disabled:hover,
        &.disabled:focus {
          color: ${props => props.theme.primaryButtonDisabledColor};
          background: ${props => props.theme.primaryButtonDisabledBackground};
          border: ${props => props.theme.buttonBorder};
          filter: brightness(1);
        }
      }
    }

    /* Drawer */
    .ant-drawer-content {
      background-color: ${props => props.theme.backgroundColor};
      color: ${props => props.theme.textColor};

      h2,
      h3 {
        color: ${props => props.theme.labelColor};
      }
    }

    /* text input */
    .ant-input-password-icon {
      color: ${props => props.theme.inputPlaceholderColor};
    }
    .ant-input {
      color: ${props => props.theme.inputColor};
      background-color: ${props => props.theme.inputBackgroundColor};
      border: ${props => props.theme.inputBorder};
      font-size: ${props => props.theme.inputFontSize};
      padding: ${props => props.theme.inputPadding};
      min-height: ${props => props.theme.inputHeight};
      &.ant-input-sm {
        min-height: ${props => props.theme.inputHeightSm};
      }

      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${props => props.theme.inputPlaceholderColor};
        opacity: 1; /* Firefox */
      }

      /* Internet Explorer 10-11 */
      :-ms-input-placeholder {
        color: ${props => props.theme.inputPlaceholderColor};
      }

      /* Microsoft Edge */
      ::-ms-input-placeholder {
        color: ${props => props.theme.inputPlaceholderColor};
      }

      &:focus {
        color: ${props => props.theme.inputColorActive};
        box-shadow: ${props => props.theme.inputHoverBoxShadow};
      }

      &:hover {
        color: ${props => props.theme.inputHoverColor};
        background: ${props => props.theme.inputHoverBackground};
        border: ${props => props.theme.inputHoverBorder};
        box-shadow: ${props => props.theme.inputHoverBoxShadow};
      }

      &.ant-input-disabled {
        color: ${props => props.theme.inputDisabledColor};
        background: ${props => props.theme.inputDisabledBackground};
        transition: none;

        &:hover {
          border: none;
        }

        &::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: ${props => props.theme.inputDisabledColor};
          opacity: 1; /* Firefox */
        }
      }
    }

    /* selects, dropdowns, menus */
    .ant-select {
      font-size: ${props => props.theme.selectFontSize};
    }

    .ant-select-dropdown {
      .ant-select-dropdown-menu-item {
        font-size: ${props => props.theme.selectFontSize};
      }
    }

    .ant-select-selection {
      background-color: ${props => props.theme.selectBackgroundColor};
      border: ${props => props.theme.selectBorder};
      min-height: ${props => props.theme.inputHeight};
    }

    .ant-select-selection__clear {
      border-radius: 7px;
    }

    .ant-select-sm {
      min-height: ${props => props.theme.inputHeightSm};
      .ant-select-selection {
        min-height: ${props => props.theme.inputHeightSm};
      }
      .ant-select-selection__rendered {
        min-height: ${props => props.theme.inputHeightSm};
        line-height: ${props => props.theme.inputHeightSm};
      }
    }

    .ant-select-selection__rendered {
      line-height: ${props => props.theme.inputHeight};
    }

    .ant-select-selection__placeholder {
      color: ${props => props.theme.selectPlaceholderColor};
    }

    .ant-select-selection-selected-value,
    .ant-select-search__field,
    .ant-select-arrow {
      color: ${props => props.theme.inputColor};
    }

    .ant-select-disabled {
      .ant-select-selection__placeholder,
      .ant-select-selection-selected-value,
      .ant-select-arrow {
        color: ${props => props.theme.inputDisabledColor};
      }
    }

    .ant-dropdown,
    .ant-dropdown-menu,
    .ant-dropdown-menu-item,
    .ant-select-dropdown,
    .ant-select-dropdown-menu-item {
      color: ${props => props.theme.selectColor};
      background: ${props => props.theme.selectMenuBackgroundColor};

      &.ant-select-dropdown-menu-item-selected {
        background: ${props => props.theme.selectMenuSelectedBackgroundColor};
      }

      &.ant-select-dropdown-menu-item-disabled {
        color: ${props => props.theme.inputDisabledColor};
      }
    }

    .ant-dropdown,
    .ant-dropdown-menu,
    .ant-select-dropdown {
      box-shadow: ${props => props.theme.boxShadow};
      border-top-left-radius: ${props => props.theme.selectActiveBorderBottomRadius};
      border-top-right-radius: ${props => props.theme.selectActiveBorderBottomRadius};
    }

    .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
    .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled),
    .ant-dropdown-menu-item:hover,
    .ant-dropdown-menu-submenu-title:hover {
      background-color: ${props => props.theme.selectHoverBackgroundColor};
    }

    .ant-select-selection:focus,
    .ant-select-selection:hover,
    .ant-select-selection:active {
      border-bottom-right-radius: ${props =>
        props.theme.selectActiveBorderBottomRadius};
      border-bottom-left-radius: ${props => props.theme.selectActiveBorderBottomRadius};
      box-shadow: none;

      &::after {
        content: "";
        position: absolute;
        background-color: ${props => props.theme.selectHoverAfterColor};
        width: 100%;
        height: 3px;
        bottom: -3px;
        transition: none;
      }
    }

    .ant-select-dropdown-menu-item:first-child {
      border-top-left-radius: ${props =>
        props.theme.selectActiveBorderBottomRadius}; /* match select border bottom */
      border-top-right-radius: ${props =>
        props.theme.selectActiveBorderBottomRadius}; /* match select border bottom */
    }

    .ant-select-dropdown-menu-item-group-title {
      color: ${props => props.theme.selectColor};
    }

    /* radio buttons */
    .ant-radio-button-wrapper {
      color: ${props => props.theme.buttonColor};
      background: ${props => props.theme.buttonBackground};
      border: ${props => props.theme.buttonBorder};

      &.ant-radio-button-wrapper-checked {
        color: ${props => props.theme.primaryButtonColor};
        background: ${props => props.theme.primaryButtonBackground};
      }
    }

    .ant-radio-wrapper {
      color: ${props => props.theme.inputColor};
      &:hover {
        .ant-radio::before {
          content: "";
          background-color: #f1f3f4;
          display: block;
          height: 40px;
          width: 40px;
          position: absolute;
          border-radius: 50%;
          opacity: 0.04;
          transform: translate(-12px, -12px);
        }

        .ant-radio-checked::before {
          background-color: #a560eb;
        }

        .ant-radio.ant-radio-disabled::before {
          opacity: 0;
        }
      }

      &:focus .ant-radio::before,
      &:focus-within .ant-radio::before,
      &:active .ant-radio::before {
        opacity: ${props => props.theme.checkboxActiveOpacity};
      }

      .ant-radio + span {
        padding-left: ${props => props.theme.checkboxRadioLabelPadding};
        padding-right: ${props => props.theme.checkboxRadioLabelPadding};
      }

      .ant-radio-inner {
        background: ${props => props.theme.radioBackground};
        border: ${props => props.theme.radioBorder};

        &::after {
          top: ${props => props.theme.radioInnerMargin};
          left: ${props => props.theme.radioInnerMargin};
        }
      }

      .ant-radio-checked .ant-radio-inner {
        border: ${props => props.theme.radioBorderHover};

        &::after {
          background-color: ${props => props.theme.radioBorderHover};
        }
      }

      /* disabled styles */
      .ant-radio-disabled {
        & + span {
          color: ${props => props.theme.disabledLabelColor};
        }
        &:disabled {
          border: ${props => props.theme.radioBorder};
        }
        &:hover::before,
        &:focus::before,
        &:active::before {
          opacity: 0;
        }
        .ant-radio-inner {
          border-color: ${props =>
            props.theme
              .radioBorderColor} !important; /* !important added to override ant's !important style (ugh) */

          &::after {
            background-color: ${props => props.theme.radioBorderColor};
          }
        }
      }
    }

    /* checkboxes */
    .ant-checkbox-wrapper {
      color: ${props => props.theme.inputColor};

      &:hover {
        .ant-checkbox::before {
          /* styles for circle around checkbox in hover/focus states */
          content: "";
          background-color: #f1f3f4;
          display: block;
          height: 40px;
          width: 40px;
          position: absolute;
          border-radius: 50%;
          opacity: ${props => props.theme.checkboxHoverOpacity};
          transform: translate(-12px, -12px);
        }

        .ant-checkbox-checked::before {
          background-color: #a560eb;
        }

        .ant-checkbox-inner {
          border: ${props => props.theme.checkboxHoverBorder};
        }

        .ant-checkbox-checked .ant-checkbox-inner {
          border-color: ${props => props.theme.primaryColor};
        }

        .ant-checkbox.ant-checkbox-disabled::before {
          opacity: 0;
        }
      }

      &:focus .ant-checkbox::before,
      &:focus-within .ant-checkbox::before,
      &:active .ant-checkbox::before {
        opacity: ${props => props.theme.checkboxActiveOpacity};
      }

      .ant-checkbox + span {
        padding-left: ${props => props.theme.checkboxRadioLabelPadding};
        padding-right: ${props => props.theme.checkboxRadioLabelPadding};
      }

      .ant-checkbox-inner {
        background-color: ${props => props.theme.checkboxBackgroundColor};
        border: ${props => props.theme.checkboxBorder};

        &::after {
          border-color: ${props => props.theme.backgroundColor}; /* checkmark color */
        }

        &:hover {
          border: ${props => props.theme.checkboxBorder};
        }
      }

      .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: ${props => props.theme.checkboxBorderColor};
      }

      .ant-checkbox-checked .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: ${props => props.theme.primaryColor};
      }

      /* disabled styles */
      .ant-checkbox-disabled {
        & + span {
          color: ${props => props.theme.disabledLabelColor};
        }
        &:disabled {
          border: ${props => props.theme.checkboxBorder};
        }
        &:hover::before,
        &:focus::before,
        &:active::before {
          opacity: 0;
        }
        .ant-checkbox-inner {
          border-color: ${props =>
            props.theme
              .checkboxBorderColor} !important; /* !important added to override ant's !important style (ugh) */
        }
      }

      /* checked styles */
      .ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner {
        border: ${props => props.theme.checkboxBorder};
        background-color: ${props => props.theme.checkboxBorderColor};

        &::after {
          border-color: ${props => props.theme.backgroundColor}; /* checkmark color */
        }
      }

      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: ${props => props.theme.primaryColor};
        border-color: ${props => props.theme.primaryColor};
      }
    }

    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin-left: initial; /* override 8px margin-left */
    }

    /* file picker */
    .ant-upload-list-item-name {
      color: ${props => props.theme.textColor};
    }

    .ant-upload-list-item-info .anticon-paper-clip {
      color: ${props => props.theme.textColor};
    }

    /* collapse component */
    .ant-collapse {
      background-color: ${props => props.theme.headerBackgroundColor};
      color: ${props => props.theme.inputColor};
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      color: ${props => props.theme.inputColor};
    }

    .ant-collapse-content {
      color: ${props => props.theme.inputColor};
    }

    .ant-collapse-borderless > .ant-collapse-item {
      border-color: ${props => props.theme.tableBorderColor};
      :first-child {
        border-top: 1px solid ${props => props.theme.tableBorderColor};
      }
    }

    /* tabs */
    .ant-tabs {
      color: ${props => props.theme.inputColor};
    }

    .ant-tabs-ink-bar {
      background-color: ${props => props.theme.tabInkBarColor};
    }

    .ant-tabs-bar {
      border-bottom: 1px solid ${props => props.theme.inputBackgroundColor};
    }

    .ant-tabs-nav .ant-tabs-tab {
      color: ${props => props.theme.tabColor};
    }

    .ant-tabs-nav .ant-tabs-tab:hover,
    .ant-tabs-nav .ant-tabs-tab-active,
    .ant-tabs-nav .ant-tabs-tab-active:active,
    .ant-tabs-nav .ant-tabs-tab-active:hover {
      color: ${props => props.theme.tabActiveColor};
    }

    .ant-tabs-tab-prev-icon,
    .ant-tabs-tab-next-icon {
      color: ${props => props.theme.tabColor};
    }

    /* skeleton */
    .ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
    .ant-skeleton.ant-skeleton-active
      .ant-skeleton-content
      .ant-skeleton-paragraph
      > li {
      background: linear-gradient(
        90deg,
        ${props => props.theme.inputBackgroundColor} 25%,
        ${props => props.theme.inputHoverBackgroundColor} 37%,
        ${props => props.theme.inputBackgroundColor} 63%
      );
      animation: ant-skeleton-loading 1.4s ease infinite;
    }

    /* tree */
    .ant-tree,
    .ant-tree li .ant-tree-node-content-wrapper {
      color: ${props => props.theme.inputColor};
    }

    .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected,
    .ant-tree li .ant-tree-node-content-wrapper:hover {
      background-color: unset;
    }

    /* cascader */
    .ant-cascader-picker {
      color: ${props => props.theme.selectColor};
      background: transparent;
    }

    .ant-cascader-menus {
      color: ${props => props.theme.selectColor};
      background: ${props => props.theme.popperBackground};
      box-shadow: ${props => props.theme.popperBoxShadow};
      z-index: ${props => props.theme.zIndex.popoverOverDrawer};
    }

    .ant-cascader-menu {
      border-color: ${props => props.theme.borderColor};
    }

    .ant-cascader-menu-item:hover {
      background: ${props => props.theme.selectHoverBackgroundColor};
    }

    .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
    .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
      font-weight: 600;
      background: ${props => props.theme.cascadeSelectedBackgroundColor};
    }

    .ant-cascader-menu-item-disabled {
      color: ${props => props.theme.inputDisabledColor};
    }

    .ant-cascader-input.ant-input {
      font-size: ${props => props.theme.selectFontSize};
      background-color: ${props =>
        props.theme.inputBackgroundColor} !important; // override antd important style
    }

    .ant-cascader-menu-item-expand-icon {
      color: ${props => props.theme.selectColor};
    }

    .ant-cascader-picker-clear,
    .ant-cascader-picker-arrow {
      background: transparent;
      color: ${props => props.theme.iconButtonColor};
    }

    /* modal */
    .ant-modal-header {
      .ant-modal-title {
        color: ${props => props.theme.textColor};
      }
      border-bottom: 1px solid ${props => props.theme.modalBorderColor};
      background: ${props => props.theme.modalBackgroundColor};
    }

    .ant-modal-close-x {
      color: ${props => props.theme.textColor};
    }
    .ant-modal-content {
      background: ${props => props.theme.modalBackgroundColor};
    }

    .ant-modal-body,
    .ant-modal-confirm-content,
    .ant-modal-confirm-title {
      background: ${props => props.theme.modalBackgroundColor};
      color: ${props => props.theme.textColor};
    }

    .ant-modal-footer {
      border-top: 1px solid ${props => props.theme.modalBorderColor};
      background: ${props => props.theme.modalBackgroundColor};
    }

    /* popover */
    .ant-popover-inner {
      background: ${props => props.theme.popperBackground};
      box-shadow: ${props => props.theme.popperBoxShadow};
    }

    .ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow,
    .ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow,
    .ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow {
      border-top-color: ${props => props.theme.popperBackgroundColor};
      border-right-color: ${props => props.theme.popperBackgroundColor};
    }

    /* tooltip */
    .ant-tooltip-inner,
    .ant-tooltip-arrow::before {
      background-color: ${props => props.theme.tooltipBackgroundColor};
    }

    /* form */
    .ant-form-explain {
      display: block;
      color: ${props => props.theme.errorColor};
      &:first-of-type {
        margin-top: ${props => props.theme.spacerxs};
      }
    }

    /* json viewer */
    .jsonViewer {
      border: 0;
    }

    /* split-pane */
    .Resizer {
      background: ${props => props.theme.inputBackgroundColor};
      opacity: 1;
      z-index: 1;
      box-sizing: border-box;
      background-clip: padding-box;
    }

    .Resizer:hover {
      transition: all 100ms ease;
    }

    .Resizer.horizontal {
      min-height: 9px;
      height: 9px;
      margin: -4px 0;
      border-top: 4px solid rgba(255, 255, 255, 0);
      border-bottom: 4px solid rgba(255, 255, 255, 0);
      cursor: row-resize;
      width: 100%;
    }

    .Resizer.horizontal:hover {
      border-top: 4px solid rgba(0, 0, 0, 0.1);
      border-bottom: 4px solid rgba(0, 0, 0, 0.1);
    }

    .Resizer.vertical {
      width: 9px;
      min-width: 9px;
      margin: 0 -4px;
      border-left: 4px solid rgba(255, 255, 255, 0);
      border-right: 4px solid rgba(255, 255, 255, 0);
      cursor: col-resize;
    }

    .Resizer.vertical:hover {
      border-left: 4px solid rgba(0, 0, 0, 0.1);
      border-right: 4px solid rgba(0, 0, 0, 0.1);
    }

    .Resizer.disabled {
      cursor: not-allowed;
    }

    .Resizer.disabled:hover {
      border-color: transparent;
    }
  }
`;
