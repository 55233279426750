import { ConfigValidationError } from "../../../../../../types";
import { ComponentConfigState } from "../../../../types";
import selectVisibilityRuleErrors from "../../common/VisibilityRulesManager/selectErrors";
import { SpaceAccordionComponent } from "../types";

export default function errorSelector(
  state: ComponentConfigState
): ConfigValidationError[] {
  const errors: ConfigValidationError[] = [];
  const { draftComponent } = state;

  const { sections } = (draftComponent as SpaceAccordionComponent).properties;
  sections.forEach((section, index) => {
    if (!section.title) {
      errors.push({
        field: "ACCORDION_SECTION",
        message: "Section title is required.",
        index
      });
    }
  });

  return errors.concat(selectVisibilityRuleErrors(draftComponent));
}
