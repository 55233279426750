import { useEffect, useRef } from "react";

// See https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export const useInterval = (func: () => void, delay: number | null) => {
  const savedFunc = useRef<() => void>();

  useEffect(() => {
    savedFunc.current = func;
  }, [func]);

  useEffect(() => {
    if (delay === null) {
      return;
    }

    const id = setInterval(() => {
      if (savedFunc.current) {
        savedFunc.current();
      }
    }, delay);
    return () => clearInterval(id);
  }, [delay]);
};
