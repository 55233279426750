import { MutationHookOptions, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

const QUERY = gql`
  mutation AbandonSpaceBranch($spaceId: ID!, $branch: String!) {
    abandonSpaceBranch(spaceId: $spaceId, branch: $branch) {
      ok
    }
  }
`;

export interface Data {
  abandonSpaceBranch: {
    branch: string;
    taskId: string;
  };
}

interface Variables {
  spaceId: string;
  branch: string;
}

const useAbandonSpaceBranch = (options?: MutationHookOptions<Data, Variables>) =>
  useMutation<Data, Variables>(QUERY, options);

export default useAbandonSpaceBranch;
