import React, { useCallback, useState } from "react";

import { Form, Progress } from "antd";
import * as AntForm from "antd/lib/form/Form";
import styled from "styled-components";

import ClickEventCapturer from "../../../../common/ClickEventCapturer";
import { AbsoluteErrorIcon } from "../../../../common/Icons";
import { tryError } from "../../../../util";
import {
  createSpaceFunction,
  FunctionExecutionStatus
} from "../../../FunctionExecutor/FunctionExecutor";
import { useStableSpaceContext } from "../../SpaceContext";
import Button from "../common/Button";
import { ButtonTypes } from "../common/Button/constants";
import { EditModeContainer } from "../common/CloudUploader/styledComponents";
import useSubmittableEffects, {
  SubmittableEventType
} from "../common/effects/useSubmittableEffects/useSubmittableEffects";
import useFuncParams from "../common/useFuncParams";
import useSpaceFunction from "../common/useSpaceFunction";
import { FunctionResult } from "../common/useSpaceFunction/useSpaceFunction";
import { useComponentContext } from "../contexts/ComponentContext";
import { useComponentStateContext } from "../contexts/ComponentStateContext";
import { Props as BaseProps } from "../SpaceComponent";
import FunctionModalForm from "../SpaceFunctionModalForm/FunctionModalForm";

import FileInput from "./FileInput";
import { SpaceCloudUploaderProperties } from "./types";
import useUpload from "./useUpload";

const Root = styled.div``;

const Container = styled.div`
  width: 100%;
  text-align: left;
`;

type Props = BaseProps & { form: AntForm.WrappedFormUtils<any> };

interface SignurlData {
  uri: string;
}

export function SpaceCloudUploaderComponent({
  form,
  hasConfigError,
  spaceComponent,
  spaceApi
}: Props) {
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const { spaceId, editMode } = useStableSpaceContext();
  const { input, updateOutput } = useComponentStateContext();
  const {
    component: { layout }
  } = useComponentContext();

  const spaceFunction = React.useMemo(
    () => createSpaceFunction(spaceComponent),
    [spaceComponent]
  );

  const { triggerEffects } = useSubmittableEffects(spaceApi);

  const properties = spaceComponent.properties as SpaceCloudUploaderProperties;
  const input_parameters = properties.input_parameters;

  const {
    prefillParams,
    funcParams,
    hasRequiredBindings,
    hasRequiredValues,
    hasValidValues,
    hasRequiredComponentValues,
    getCurrentFuncParams
  } = useFuncParams(spaceFunction, input_parameters, input, form, spaceComponent);

  const [upload, { progress, uploading }] = useUpload();

  const onError = useCallback(
    (error: Error) => {
      triggerEffects({
        type: SubmittableEventType.SUBMIT_FAILURE,
        error
      });
    },
    [triggerEffects]
  );

  const onUploadCompleted = useCallback(() => {
    updateOutput({ lastExecutionResult: {} });
    setShowModal(false);
    triggerEffects({ type: SubmittableEventType.SUBMIT_SUCCESS });
  }, [updateOutput, triggerEffects]);

  const onExecuteCompleted = useCallback(
    async (result: FunctionResult, _metadata: unknown) => {
      const uploadUri = result as unknown as SignurlData;
      try {
        await upload("PUT", uploadUri.uri, file);
        onUploadCompleted();
      } catch (e) {
        onError(tryError(e));
      }
    },
    [upload, file, onUploadCompleted, onError]
  );

  const { executeFunction, status } = useSpaceFunction(
    spaceFunction,
    input_parameters,
    spaceId,
    onExecuteCompleted,
    onError
  );

  const trySetShowModal = React.useCallback(
    (visible: boolean) => {
      if (!visible && status === FunctionExecutionStatus.IN_PROGRESS) return;
      setShowModal(visible);
    },
    [status, setShowModal]
  );

  const onSubmit = React.useCallback(() => {
    executeFunction(getCurrentFuncParams());
  }, [executeFunction, getCurrentFuncParams]);

  const buttonType =
    spaceComponent.properties.button_type === ButtonTypes.Link ? "link" : "primary";

  if (editMode) {
    return (
      <Root className="spaceButtonRoot">
        <EditModeContainer buttonType={buttonType}>
          {hasConfigError && <AbsoluteErrorIcon />}
          <Button type={buttonType} disabled layout={layout}>
            {properties.open_uploader_button_text}
          </Button>
        </EditModeContainer>
      </Root>
    );
  }

  return (
    <Root className="spaceButtonRoot" data-test="space-cloud-uploader">
      <Container>
        <Button
          type={buttonType}
          disabled={!hasRequiredBindings}
          fixedWidthLoadingState
          layout={layout}
          onClick={() => trySetShowModal(true)}
        >
          {properties.open_uploader_button_text}
        </Button>
      </Container>
      {showModal && (
        <ClickEventCapturer>
          <FunctionModalForm
            form={form}
            func={spaceFunction}
            prefillParams={prefillParams}
            funcParams={funcParams}
            spaceApi={spaceApi}
            spaceComponent={spaceComponent}
            inputParameters={input_parameters}
            disabled={!hasRequiredValues || !hasValidValues || !file}
            loading={status === FunctionExecutionStatus.IN_PROGRESS}
            functionExecutionStatus={status}
            isValid={hasRequiredComponentValues && !!file}
            onClose={() => trySetShowModal(false)}
            onSubmit={onSubmit}
          >
            <FileInput onChange={setFile} />
            {uploading && (
              <Progress percent={progress} showInfo={false} status="normal" />
            )}
          </FunctionModalForm>
        </ClickEventCapturer>
      )}
    </Root>
  );
}

export default Form.create<Props>({
  name: "space-cloud-uploader"
})(SpaceCloudUploaderComponent);
