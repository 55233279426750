import React, { useEffect, useReducer, useRef } from "react";

function reducer(
  state: { width: number; height: number; top: number; left: number },
  action: { width: number; height: number; top: number; left: number }
) {
  if (
    state.width === action.width &&
    state.height === action.height &&
    state.top === action.top &&
    state.left === action.left
  )
    return state;
  return action;
}

export default function useObservedRect(
  ref: React.RefObject<HTMLElement>,
  offset?: DOMPoint
) {
  const [state, dispatch] = useReducer(reducer, {
    width: -1,
    height: -1,
    top: -1,
    left: -1
  });
  const observer = useRef(
    new ResizeObserver(entries => {
      const observerEntry = entries[0];
      dispatch({
        width: observerEntry.contentRect.width,
        height: observerEntry.contentRect.height,
        top: observerEntry.contentRect.top,
        left: observerEntry.contentRect.left
      });
    })
  );
  useEffect(() => {
    if (!ref.current) return;
    const curr = observer.current;
    curr.observe(ref.current as HTMLElement);
    return () => {
      curr.disconnect();
    };
  }, [ref]);
  // TODO: try and use oberver dims here. getBoundingClientRect is misleading wrt to top / left
  //       as they are relative to the viewport and not the offset parent. Currently this
  //       can be worked around by providing optional offset but overall wonky
  return React.useMemo(
    () => {
      if (!ref) return new DOMRect(0, 0, 0, 0);
      let rect = ref.current?.getBoundingClientRect() || new DOMRect();
      if (offset) {
        rect = new DOMRect(offset.x, offset.y, rect.width, rect.height);
      }
      return rect;
    },
    // HACK: Use observer to detect a change occurred, but when one does
    //       just use the ref's `boundingClientRect` directly.
    // eslint-disable-next-line
    [state]
  );
}
