import React from "react";

import styled from "styled-components";

import { B3 } from "../../../../common/StyledComponents";
import ActionIcon from "../../../Config/ActionsAndEnvironments/ActionPicker/ActionIcon";

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 24px;
`;

const DataSourceTitle = styled.div`
  font-size: 13px;
  line-height: 21px;
  color: ${props => props.theme.textColorMid};
  margin-bottom: ${props => props.theme.spacerxs};
`;

interface Props {
  title: string;
  dataSourceTitle: string;
  integration: string | undefined;
}
export const ActivityFunctionInfo = (props: Props) => {
  return (
    <Header>
      <ActionIcon integration={props.integration} />
      <HeaderTitle>
        <DataSourceTitle>{props.dataSourceTitle}</DataSourceTitle>
        <B3>{props.title}</B3>
      </HeaderTitle>
    </Header>
  );
};

export default ActivityFunctionInfo;
