import React, { useMemo } from "react";

import { isEqual } from "lodash";
import WindowedSelect from "react-windowed-select";
import styled from "styled-components";

import { MAX_VIEW_ROWS, ErrorValue, ErrorValues } from "../../../../../constants";
import usePrevious from "../../../../common/hooks/usePrevious";
import { AbsoluteErrorIcon } from "../../../../common/Icons";
import { FormErrorField, FlexContainer } from "../../../../common/StyledComponents";
import WarningIcon from "../../../../common/WarningIcon";
import { useStableSpaceContext } from "../../SpaceContext";
import EditModeInteractionBlocker from "../common/EditModeInteractionBlocker";
import { Option } from "../common/HardcodedOptionFields/utils";
import PermissionWarningIcon from "../common/PermissionWarningIcon";
import { customTheme, getCustomStyles } from "../common/SelectBackedComponent/styles";
import useDefaultValue from "../common/useDefaultValue";
import useOutputSyncing from "../common/useOutputSyncing";
import useRefreshComponent from "../common/useRefreshComponent";
import useSelectOptions from "../common/useSelectOptions";
import { useComponentStateContext } from "../contexts/ComponentStateContext";
import { Props } from "../SpaceComponent";

import { ensureDropdownComponent } from "./utils";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const SelectWrapper = styled.div`
  width: 100%;
`;

const StyledWindowedSelect = styled(WindowedSelect)`
  width: 100%;
  overflow: hidden;
`;
StyledWindowedSelect.displayName = "StyledWindowedSelect";

const StyledWarningIcon = styled(WarningIcon)`
  margin-left: ${props => props.theme.spacerxs};
`;

export default function SpaceDropdown({
  spaceComponent,
  spaceApi,
  hasConfigError
}: Props) {
  const dropdownComponent = useMemo(
    () => ensureDropdownComponent(spaceComponent),
    [spaceComponent]
  );
  const { editMode } = useStableSpaceContext();
  const { output, recursivelyClearOutput } = useComponentStateContext();
  const { defaultValue } = useDefaultValue();
  const { options, hasNextPage, loading, minWidth, refresh } =
    useSelectOptions(spaceComponent);
  const [selectedOption, setSelectedOption] = React.useState<
    Option | null | ErrorValue
  >(null);
  const {
    properties: { placeholder }
  } = dropdownComponent;

  const refreshAndClear = React.useCallback(() => {
    refresh();
    recursivelyClearOutput();
  }, [refresh, recursivelyClearOutput]);

  useRefreshComponent(spaceComponent, spaceApi, refreshAndClear);

  const defaultOption = React.useMemo(() => {
    if (defaultValue === ErrorValues.permissionDenied) {
      return defaultValue;
    }
    return options.find(option => {
      if (defaultValue === null || defaultValue === undefined) {
        return option.value === defaultValue;
      }
      // option values are always strings or null, because they are derived
      // from templates (when there's a data source) or hardcoded values.
      // therefore, we must convert the defaultValue to string to compare.
      // Types supported: string, int, decimal, float, and boolean.
      return option.value === defaultValue.toString();
    });
  }, [defaultValue, options]);

  const prevOptions = usePrevious(options);
  const prevDefaultValue = usePrevious(defaultValue);
  React.useEffect(() => {
    if (isEqual(prevOptions, options) && prevDefaultValue === defaultValue) {
      return;
    }
    setSelectedOption(defaultOption || null);
  }, [defaultValue, options, prevDefaultValue, prevOptions, defaultOption]);

  React.useEffect(() => {
    if (output !== null) return;
    setSelectedOption(defaultOption || null);
  }, [defaultValue, output, defaultOption]);

  const { errorMessage } = useOutputSyncing(
    selectedOption === ErrorValues.permissionDenied
      ? selectedOption
      : (selectedOption as Option)?.value
  );

  const customStyles = React.useMemo(() => {
    return getCustomStyles(minWidth, !errorMessage);
  }, [minWidth, errorMessage]);

  return (
    <EditModeInteractionBlocker>
      <Root>
        <FlexContainer>
          {editMode && hasConfigError ? <AbsoluteErrorIcon /> : null}
          <SelectWrapper
            onClick={evt => {
              evt.stopPropagation();
            }}
          >
            <StyledWindowedSelect
              value={
                selectedOption === ErrorValues.permissionDenied ? null : selectedOption
              }
              placeholder={placeholder}
              isLoading={!editMode && loading}
              onChange={(option: Option) => {
                setSelectedOption(option);
              }}
              menuPortalTarget={document.body}
              options={options}
              styles={customStyles}
              classNamePrefix="react-select"
              theme={customTheme}
              isClearable
            />
          </SelectWrapper>
          {hasNextPage && (
            <StyledWarningIcon
              tooltip={`This dropdown is incomplete. It is displaying data from the first
              ${MAX_VIEW_ROWS} records, but more data is available.`}
            />
          )}
          {selectedOption === ErrorValues.permissionDenied && <PermissionWarningIcon />}
        </FlexContainer>
        {errorMessage && <FormErrorField>{errorMessage}</FormErrorField>}
      </Root>
    </EditModeInteractionBlocker>
  );
}
