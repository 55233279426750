import { assertNever } from "../../../../util/assertNever";

export enum ActionType {
  CLOSE = "close",
  COMPLETE = "complete",
  ERROR = "error",
  INIT = "init",
  OPEN = "open"
}

type InitState = "confirm" | "initializing" | "done" | "error";

interface State {
  approvalHref: string;
  initState: InitState;
  showModal: boolean;
  error: string;
}

interface BaseAction<T> {
  type: T;
  payload?: unknown;
}

type CloseAction = BaseAction<ActionType.CLOSE>;

interface CompleteAction extends BaseAction<ActionType.COMPLETE> {
  payload: string;
}

interface ErrorAction extends BaseAction<ActionType.ERROR> {
  payload: string;
}

type InitAction = BaseAction<ActionType.INIT>;

type OpenAction = BaseAction<ActionType.OPEN>;

type Action = CompleteAction | CloseAction | ErrorAction | InitAction | OpenAction;

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.CLOSE:
      return {
        approvalHref: "",
        error: "",
        initState: "confirm",
        showModal: false
      };
    case ActionType.COMPLETE:
      return {
        ...state,
        approvalHref: action.payload,
        error: "",
        initState: "done"
      };
    case ActionType.ERROR:
      return {
        ...state,
        approvalHref: "",
        error: action.payload,
        initState: "error"
      };
    case ActionType.INIT:
      return {
        ...state,
        approvalHref: "",
        error: "",
        initState: "initializing"
      };
    case ActionType.OPEN:
      return {
        approvalHref: "",
        error: "",
        initState: "confirm",
        showModal: true
      };
    default:
      return assertNever(action);
  }
};

export default reducer;
