import React from "react";

import { Skeleton } from "antd";

import { useStableSpaceContext } from "../../SpaceContext";
import EmptyState from "../common/EmptyState";
import Panel from "../common/Panel";
import ScaledImage, { useTrackImageErrors } from "../common/ScaledImage";
import useRefreshComponent from "../common/useRefreshComponent";
import useSignedUrl from "../common/useSignedUrl";
import useView, {
  viewRowToStateRow,
  QueryExecutionRequirement
} from "../common/useView";
import ViewEnsurer from "../common/ViewEnsurer/ViewEnsurer";
import { useComponentStateContext } from "../contexts/ComponentStateContext";
import { Props as BaseProps } from "../SpaceComponent";

import { ensureImageComponent } from "./ImageConfig/reducer/reducer";

export default function SpaceImage({
  spaceComponent,
  hasConfigError,
  spaceApi
}: BaseProps) {
  const imageComponent = ensureImageComponent(spaceComponent);
  const { spaceId, editMode } = useStableSpaceContext();
  const { input, updateOutput } = useComponentStateContext();
  const viewResult = useView(spaceId, imageComponent, input, {
    limit: 1,
    queryExecutionRequirement: !!imageComponent?.properties?.is_filter_required
      ? QueryExecutionRequirement.ANY_FILTER
      : QueryExecutionRequirement.NONE
  });
  const { rows, loading, refresh } = viewResult;
  const viewRow = (rows && rows[0]) || undefined;
  const stateRow = React.useMemo(() => viewRowToStateRow(viewRow), [viewRow]);
  React.useEffect(() => {
    updateOutput(stateRow);
  }, [stateRow, updateOutput]);
  const { imageErrored, handleImageError } = useTrackImageErrors(imageComponent);
  const { url, hasError } = useSignedUrl(imageComponent, input, imageErrored);

  useRefreshComponent(spaceComponent, spaceApi, refresh);

  const title = imageComponent.properties.title;

  if (!loading && hasError) {
    return (
      <Panel title={imageComponent.name || "Image"} hasError={hasConfigError}>
        <EmptyState message={"Your image could not be loaded"} />
      </Panel>
    );
  }

  if (!url) {
    return editMode ? (
      <Panel title={title} hasError={hasConfigError}>
        <EmptyState message="No image to display." />
      </Panel>
    ) : (
      <Skeleton active />
    );
  }

  const img = (
    <ScaledImage
      src={url}
      name={imageComponent.properties.title}
      onError={handleImageError}
    />
  );

  return imageComponent.view ? (
    <ViewEnsurer viewResult={viewResult}>{img}</ViewEnsurer>
  ) : (
    img
  );
}
