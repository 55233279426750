import React from "react";

import { useFormContext, Controller } from "react-hook-form";

import {
  BindingShape,
  FunctionScope,
  InputParameter,
  ParameterType,
  RelayNode
} from "../../../../../types";
import FormBuilderModal, { FunctionNode } from "../../../../common/FormBuilderModal";
import { SupportedIntegration } from "../../../../common/FunctionEditor/support";
import FunctionPicker from "../../../../common/FunctionPicker";
import usePrevious from "../../../../common/hooks/usePrevious";
import {
  BindingSchema,
  getUserEnvironmentBindingSchema
} from "../../../../common/schema";
import { ErrorField, LinkButtonNew } from "../../../../common/StyledComponents";

import ActionIcon from "./ActionIcon";
import * as styled from "./styledComponents";

const AUTOMATION_SCHEMA: Record<string, BindingSchema> = {
  automation: {
    title: "Automation",
    schema: [
      {
        title: "Last run",
        name: "last_run",
        shape: BindingShape.OBJECT,
        attributes: [
          {
            title: "start",
            name: "start",
            shape: BindingShape.SCALAR
          },
          {
            title: "end",
            name: "end",
            shape: BindingShape.SCALAR
          }
        ]
      },
      {
        title: "Last successful run",
        name: "last_successful_run",
        shape: BindingShape.OBJECT,
        attributes: [
          {
            title: "Start time",
            name: "start",
            shape: BindingShape.SCALAR
          },
          {
            title: "End time",
            name: "end",
            shape: BindingShape.SCALAR
          }
        ]
      }
    ]
  },
  ...getUserEnvironmentBindingSchema()
};

interface DataSourceNode extends RelayNode {
  name: string;
  integration: SupportedIntegration;
}
export interface FunctionNodeWithDataSource extends FunctionNode {
  id: string;
  title: string;
  isEditorCompatible: boolean;
  dataSource: DataSourceNode;
}

const SUPPORTED_PARAM_TYPES = [
  ParameterType.STATIC,
  ParameterType.BINDING,
  ParameterType.TEMPLATE
];

const isInputParameterValid = (param: InputParameter) => {
  switch (param.type) {
    case ParameterType.STATIC: {
      return param.value !== undefined;
    }
    case ParameterType.BINDING: {
      return !!param.binding;
    }
    case ParameterType.TEMPLATE: {
      return param.template !== undefined;
    }
    default:
      throw new Error("unsupported input parameter type");
  }
};
interface Props {
  selectedFunction: FunctionNodeWithDataSource | undefined;
  loading: boolean;
  inputParameters: InputParameter[];
  onChange: (functionId: string | null) => void;
  onParameterValuesChange: (inputParameters: InputParameter[]) => void;
  setShowEditFunction: (show: boolean) => void;
}

const ActionPicker = ({
  selectedFunction,
  loading,
  inputParameters,
  onChange,
  onParameterValuesChange,
  setShowEditFunction
}: Props) => {
  const { control, formState, trigger } = useFormContext();
  const [showFormBuilder, setShowFormBuilder] = React.useState(false);

  const previousFunction = usePrevious(selectedFunction);
  React.useEffect(() => {
    // trigger validation for inputParameters when selected function changes
    if (previousFunction?.id !== selectedFunction?.id && formState.isSubmitted) {
      trigger("inputParameters");
    }
  }, [previousFunction, selectedFunction, formState.isSubmitted, trigger]);

  const handleParameterValuesSave = (inputParameters: InputParameter[]) => {
    onParameterValuesChange(inputParameters);
    setShowFormBuilder(false);
  };

  const isPipelineFunction =
    selectedFunction?.dataSource.integration === SupportedIntegration.PIPELINES;

  return (
    <styled.Root>
      <styled.Header>
        <ActionIcon integration={selectedFunction?.dataSource.integration} />
        <styled.HeaderDetails>
          {selectedFunction ? (
            <>
              <styled.DataSourceTitle>
                {selectedFunction?.dataSource.name}
              </styled.DataSourceTitle>
              <styled.ActionTitle>{selectedFunction?.title}</styled.ActionTitle>
            </>
          ) : (
            <styled.ActionTitle>Add action</styled.ActionTitle>
          )}
        </styled.HeaderDetails>
      </styled.Header>
      <styled.HrNoMargin />
      {loading ? (
        <styled.Loading active />
      ) : (
        <styled.PickerContainer noTopPadding={isPipelineFunction}>
          {!isPipelineFunction && (
            <Controller
              rules={{
                required: "You must select a function."
              }}
              render={({ field }) => (
                <FunctionPicker
                  {...field}
                  functionId={selectedFunction?.id || null}
                  functionScope={FunctionScope.Submittable}
                  onChange={e => {
                    field.onChange(e);
                    onChange(e);
                  }}
                  showLabels
                  allowPipelineFunctions
                />
              )}
              control={control}
              name="functionId"
              defaultValue={selectedFunction?.id}
            />
          )}
          {formState.errors?.functionId && (
            <ErrorField>{formState.errors.functionId.message}</ErrorField>
          )}
          {formState.errors?.inputParameters && (
            <ErrorField>{formState.errors.inputParameters.message}</ErrorField>
          )}
          <styled.ActionLinks>
            <LinkButtonNew
              disabled={!selectedFunction?.isEditorCompatible}
              onClick={() => setShowEditFunction(true)}
            >
              Edit function
            </LinkButtonNew>
            <LinkButtonNew onClick={() => setShowFormBuilder(true)}>
              Configure
            </LinkButtonNew>
            {isPipelineFunction && (
              <LinkButtonNew onClick={() => onChange(null)}>Reset</LinkButtonNew>
            )}
          </styled.ActionLinks>
          <Controller
            rules={{
              validate: value => {
                const inputParamCache = value.reduce(
                  (agg: Record<string, InputParameter>, param: InputParameter) => {
                    agg[param.name] = param;
                    return agg;
                  },
                  {} as Record<string, InputParameter>
                );
                return (
                  !selectedFunction?.functionParameters.edges
                    .map(edge => edge.node)
                    .filter(param => param.required)
                    .find(param => {
                      const inputParam = inputParamCache[param.name];
                      return !inputParam || !isInputParameterValid(inputParam);
                    }) ||
                  "This function has at least one required parameter that needs to be configured. Click Configure to make changes."
                );
              }
            }}
            render={({ field }) => (
              <>
                {selectedFunction && (
                  <FormBuilderModal
                    {...field}
                    visible={showFormBuilder}
                    func={selectedFunction}
                    schema={AUTOMATION_SCHEMA}
                    title={selectedFunction?.title}
                    allowedParameterTypes={SUPPORTED_PARAM_TYPES}
                    initialInputParameters={inputParameters}
                    onSave={e => {
                      field.onChange(e);
                      handleParameterValuesSave(e);
                    }}
                  />
                )}
              </>
            )}
            control={control}
            name="inputParameters"
            defaultValue={inputParameters}
          />
        </styled.PickerContainer>
      )}
    </styled.Root>
  );
};

export default ActionPicker;
