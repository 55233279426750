import { useQuery } from "@apollo/react-hooks";
import { QueryHookOptions } from "@apollo/react-hooks/lib/types";
import gql from "graphql-tag";

import { ScmConfigNode, SCM_CONFIG_FRAGMENT } from "./ScmConfigFragment";

interface Data {
  authOrganization: { scmConfig?: ScmConfigNode };
}

const QUERY = gql`
  query GetScmConfig {
    authOrganization {
      id
      scmConfig {
        ...ScmConfigFragment
      }
    }
  }
  ${SCM_CONFIG_FRAGMENT}
`;

const useGetScmConfig = (options?: QueryHookOptions<Data>) =>
  useQuery<Data>(QUERY, options);

export default useGetScmConfig;
