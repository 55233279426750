import React from "react";

import { Icon } from "antd";
import { usePopper } from "react-popper";
import { OptionProps } from "react-select";
import { components } from "react-windowed-select";

import LocationSearchingIcon from "../../../../../assets/icons/location-searching-icon.svg";
import PlusIcon from "../../../../../assets/icons/plus-icon.svg";
import { ScmConfigNode, ScmStatus } from "../../../../../types";
import * as contextSelectorStyles from "../../../../common/contextSelectorStyles";
import useScmConfig from "../../../../common/hooks/useScmConfig";
import IconButton from "../../../../common/IconButton";
import Message from "../../../../common/Message";
import { assertNever } from "../../../../util/assertNever";
import { useSpaceConfigContext } from "../../../SpaceConfig/SpaceConfigContext";
import { CreateBranchModal, TrackBranchModal } from "../../modals";
import { useStableSpaceContext } from "../../SpaceContext";
import { useBranchContext } from "../../SpaceContext/BranchContext";

import * as styled from "./styledComponents";
import useAbandonSpaceBranch from "./useAbandonSpaceBranch";

type OptionValueType = "branch-count" | "create-branch" | "track-branch" | "option";

const navigateToBranch = (branch: string) => {
  const url = new URL(window.location.href);
  url.searchParams.set("__branch", branch);
  // TODO: It should be possible to useNavigate here, but there is an issue with
  //       setting the branch context which needs to be resolved first.  This will
  //       do a hard refresh but is much slower than using useNavigate.
  window.location.href = url.toString();
};

const clearBranch = () => {
  const url = new URL(window.location.href);
  url.searchParams.delete("__branch");
  window.location.href = url.toString();
};

interface BaseOption<O = unknown, T extends OptionValueType = OptionValueType> {
  label: string;
  value: O;
  type: T;
  icon?: React.ReactNode;
  isDisabled?: boolean;
}

type CreateBranchOption = BaseOption<null, "create-branch">;
type BranchCountOption = BaseOption<string, "branch-count">;
type BranchOption = BaseOption<string, "option"> & {
  isMainBranch: boolean;
  isCurrentBranch: boolean;
};
type TrackBranchOption = BaseOption<null, "track-branch">;
type Option = BranchOption | BranchCountOption | CreateBranchOption | TrackBranchOption;

interface GroupOption {
  label: string;
  options: BaseOption[];
}

const Group = (props: OptionProps<GroupOption, false>) => {
  return (
    <>
      {props.data.label !== "Controls" ? (
        <hr style={{ backgroundColor: "#444442", margin: 0 }} />
      ) : null}
      <components.Group {...props} />
    </>
  );
};

const OptionItem = ({
  data,
  children
}: {
  data: BaseOption;
  children?: React.ReactNode;
}) => {
  const { spaceId } = useStableSpaceContext();
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [referenceElement, setReferenceElement] = React.useState<HTMLElement | null>(
    null
  );
  const [popperElement, setPopperElement] = React.useState<HTMLElement | null>(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-end",
    strategy: "fixed",
    modifiers: []
  });

  const [abandonBranch, { loading }] = useAbandonSpaceBranch({
    refetchQueries: ["Space"],
    onCompleted: () => {
      setIsMenuOpen(false);
      Message.success(`Stopped tracking branch ${data.label}.`);
      if ((data as BranchOption).isCurrentBranch) {
        setTimeout(() => {
          clearBranch();
        }, 500);
      }
    },
    onError: err => {
      setIsMenuOpen(false);
      Message.error(err.message);
    }
  });
  return (
    <>
      <styled.ItemContainer
        className={isMenuOpen ? "open" : ""}
        ref={setReferenceElement}
      >
        <styled.LabelContainer>
          {data.icon && <styled.IconContainer>{data.icon}</styled.IconContainer>}
          <span>{data.label}</span>
        </styled.LabelContainer>
        {children}
        {data.type === "option" && !(data as BranchOption).isMainBranch && (
          <IconButton
            icon="more"
            className="openMenuButton"
            style={{ color: "white" }}
            size="small"
            onClick={() => setIsMenuOpen(true)}
          />
        )}
      </styled.ItemContainer>
      {isMenuOpen && (
        <>
          <div
            style={{ inset: 0, position: "fixed" }}
            onClick={evt => {
              setIsMenuOpen(false);
              evt.stopPropagation();
            }}
          />
          <div
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            onClick={evt => evt.stopPropagation()}
          >
            <styled.MoreOptionsMenu>
              <styled.MoreOption
                onClick={() => {
                  abandonBranch({
                    variables: {
                      spaceId,
                      branch: data.value as string
                    }
                  });
                }}
              >
                Stop tracking branch&nbsp;{loading && <Icon type="loading" spin />}
              </styled.MoreOption>
            </styled.MoreOptionsMenu>
          </div>
        </>
      )}
    </>
  );
};

const CustomOption = (props: OptionProps<Option, false>) => {
  const data = props.data as Option;
  switch (data.type) {
    case "create-branch":
    case "option":
    case "track-branch":
      return (
        <components.Option {...props}>
          <OptionItem data={data} />
        </components.Option>
      );
    case "branch-count":
      return (
        <styled.BranchOption {...props}>
          <OptionItem data={data}>
            <a href={data.value} target="_blank" rel="noreferrer">
              View on GitHub
            </a>
          </OptionItem>
        </styled.BranchOption>
      );
    default:
      return assertNever(data);
  }
};

const styles = {
  ...contextSelectorStyles.styles,
  menu: (provided: Record<string, any>) => ({
    ...provided,
    ...contextSelectorStyles.styles.menu,
    minWidth: "361px"
  })
};

export default function ConditionalBranchSelector() {
  const config = useScmConfig();
  if (!config) return null;
  return <BranchSelector config={config}></BranchSelector>;
}

interface Props {
  config: ScmConfigNode;
}

function BranchSelector({ config }: Props) {
  const {
    state: { spaces, dirty }
  } = useSpaceConfigContext();
  const {
    spaceId,
    spaceSlug,
    spaceScmStatus,
    spaceBranches,
    spaceBranchCount,
    editMode
  } = useStableSpaceContext();
  const { branch } = useBranchContext();
  const [createBranchModalVisible, setCreateBranchModalVisible] = React.useState(false);
  const [trackBranchModalVisible, setTrackBranchModalVisible] = React.useState(false);

  const versionId = spaces.get(spaceSlug)?.versionId;
  const scmVersion = spaces.get(spaceSlug)?.scmVersion;

  const onChange = React.useCallback((option: Option) => {
    switch (option.type) {
      case "create-branch":
        setCreateBranchModalVisible(true);
        break;
      case "branch-count":
        break;
      case "track-branch":
        setTrackBranchModalVisible(true);
        break;
      case "option":
        navigateToBranch(option.value);
        break;
      default:
        assertNever(option);
    }
  }, []);

  const { branchName: mainBranchName, repositoryBranchesUrl } = config;

  const options: GroupOption[] = React.useMemo(() => {
    const controlGroupOptions: BaseOption<null>[] = [
      {
        icon: <PlusIcon />,
        label: "Create new branch",
        value: null,
        type: "create-branch",
        isDisabled: dirty || !scmVersion
      },
      {
        icon: <LocationSearchingIcon />,
        label: "Track existing branch",
        value: null,
        type: "track-branch"
      }
    ];

    const branchOptions: BranchOption[] =
      spaceBranches?.edges.map(e => ({
        icon: <Icon type="branches" />,
        label: e.node.name,
        value: e.node.name,
        type: "option",
        isCurrentBranch: e.node.name === branch,
        isMainBranch: e.node.name === mainBranchName
      })) || [];

    const branchGroupOptions: (BranchCountOption | BranchOption)[] = [
      {
        label: `${spaceBranchCount} branches`,
        type: "branch-count",
        value: repositoryBranchesUrl
      },
      ...branchOptions
    ];
    return editMode
      ? [
          { label: "Controls", options: controlGroupOptions },
          { label: "Branches", options: branchGroupOptions }
        ]
      : [{ label: "Branches", options: branchGroupOptions }];
  }, [
    branch,
    mainBranchName,
    dirty,
    editMode,
    repositoryBranchesUrl,
    scmVersion,
    spaceBranchCount,
    spaceBranches?.edges
  ]);

  if (spaceScmStatus !== ScmStatus.CONNECTED) {
    return null;
  }

  return (
    <>
      <styled.Select
        menuPortalTarget={document.getElementById("select-portal")}
        isSearchable={false}
        options={options}
        value={{ value: branch, label: branch }}
        onChange={onChange}
        classNamePrefix="react-select"
        components={{
          Group,
          Option: CustomOption,
          SingleValue: (props: { data: { label: string } }) => (
            <styled.SelectedValue>
              <Icon type="branches" />
              &nbsp;
              <span title={props.data.label}>{props.data.label}</span>
            </styled.SelectedValue>
          )
        }}
        styles={styles}
        theme={contextSelectorStyles.theme}
      />
      {versionId && (
        <CreateBranchModal
          spaceId={spaceId}
          versionId={versionId}
          onError={() => {
            Message.error("Failed to create branch");
            setCreateBranchModalVisible(false);
          }}
          onSuccess={branch => {
            Message.success(`${branch} has been created`);
            setCreateBranchModalVisible(false);
          }}
          onCancel={() => setCreateBranchModalVisible(false)}
          visible={createBranchModalVisible}
        />
      )}
      <TrackBranchModal
        repositoryBranchesUrl={repositoryBranchesUrl}
        spaceId={spaceId}
        onError={() => {
          Message.error(
            "Failed to track branch. Ensure that a branch with that name exists."
          );
          setTrackBranchModalVisible(false);
        }}
        onSuccess={branch => {
          Message.success(
            `${branch} has been tracked! This page will automatically refresh.`
          );
          setTimeout(() => navigateToBranch(branch), 500);
          setTrackBranchModalVisible(false);
        }}
        onCancel={() => setTrackBranchModalVisible(false)}
        visible={trackBranchModalVisible}
      />
    </>
  );
}
