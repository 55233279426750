import React from "react";

import { Button, Icon } from "antd";
import styled from "styled-components";

import { ModalNew } from "../../../../common/Modal";
import { B3 } from "../../../../common/StyledComponents";
import { useActivityLogById } from "../../queries/ActivityLogById";
import { useActivityLogs, loadMore } from "../../queries/AllActivityLogs";

import ActivityDetail from "./ActivityDetail";
import ActivityItem from "./ActivityItem";

const Header = styled.div`
  margin-bottom: 24px;
`;

const AutomationName = styled.div`
  font-size: 24px;
`;

const Container = styled.div`
  margin-top: 40px;
  min-height: 400px;
`;

const ClickableTitle = styled.div`
  cursor: pointer;
`;

const BackIcon = styled(Icon)`
  margin-right: ${props => props.theme.spacerlg};
`;

const ScrollableContainer = styled.div`
  overflow-y: auto;
  max-height: 50vh;
  margin-right: -24px; /* offset modal padding so that scrollbar goes to edge of modal */
  padding-right: 24px; /* offset modal padding so that scrollbar goes to edge of modal */
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${props => props.theme.spacermd};
`;

interface Props {
  id: string;
  title: string;
  environmentId: string;
  selectedLogId: string | undefined;
  onCancel: () => void;
}
const ActivityLogModal = (props: Props) => {
  const [selectedId, setSelectedId] = React.useState<string | undefined>(
    props.selectedLogId
  );
  const { data, fetchMore } = useActivityLogs({
    variables: {
      automation: props.id,
      environment: props.environmentId
    },
    fetchPolicy: "network-only"
  });

  const { data: logData } = useActivityLogById({
    variables: {
      id: selectedId
    },
    skip: !selectedId
  });

  return (
    <ModalNew
      onCancel={props.onCancel}
      visible
      width={900}
      title={
        selectedId ? (
          <ClickableTitle onClick={() => setSelectedId(undefined)}>
            <BackIcon type="left" />
            {logData?.node.function.title}
          </ClickableTitle>
        ) : (
          <div>Activity log</div>
        )
      }
      closeIcon={<Icon type="close" />}
      hasFooter={false}
    >
      <Container>
        {selectedId ? (
          <ActivityDetail selectedId={selectedId} />
        ) : (
          <div>
            <Header>
              <B3>Automations</B3>
              <AutomationName>{props.title}</AutomationName>
            </Header>
            {data && (
              <ScrollableContainer>
                {data.allAuditLogs.edges.map(edge => {
                  const node = edge.node;
                  return (
                    <ActivityItem
                      key={node.id}
                      title={node.function.title}
                      dataSourceTitle={node.function.dataSource.name}
                      integration={node.function.dataSource.integration}
                      status={node.executionStatus}
                      user={node.user}
                      createdAt={node.createdAt}
                      onClick={() => setSelectedId(node.id)}
                    />
                  );
                })}
                {data.allAuditLogs.pageInfo?.hasNextPage && (
                  <ButtonContainer>
                    <Button
                      className="loadMore"
                      type="primary"
                      onClick={() =>
                        loadMore(
                          props.id,
                          props.environmentId,
                          fetchMore,
                          data.allAuditLogs.pageInfo!.endCursor
                        )
                      }
                    >
                      Load More
                    </Button>
                  </ButtonContainer>
                )}
              </ScrollableContainer>
            )}
          </div>
        )}
      </Container>
    </ModalNew>
  );
};

export default ActivityLogModal;
