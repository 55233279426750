import React from "react";

import { REGEX_URL_STRING } from "./url";

function parseHyperlinks(str: string): string {
  return str.replaceAll(
    new RegExp(REGEX_URL_STRING, "gim"),
    `<a target="_blank" rel="noreferrer" href="$1">$1</a>`
  );
}

interface Props {
  text: string;
  className?: string;
}

export default function TextWithLinks({ text, className }: Props) {
  const memoText = React.useMemo(() => {
    return parseHyperlinks(text);
  }, [text]);

  return <span className={className} dangerouslySetInnerHTML={{ __html: memoText }} />;
}
