import {
  Button as AntButton,
  Checkbox as AntCheckbox,
  Input as AntInput,
  Tabs as AntTabs,
  Radio
} from "antd";
import styled from "styled-components";

import { colors, colorTokens } from "../../../../cssConstants";
import CommonIconButton from "../../IconButton";

export const FieldColumnHeader = styled.div<{ center?: boolean }>`
  line-height: 30px;
  font-weight: ${props => props.theme.boldFontWeight};
  text-transform: uppercase;
  font-size: ${props => props.theme.smallFontSize};
  color: ${props => props.theme.columnHeaderColor};
  ${props => (props.center ? "justify-self: center;" : "")}
`;

export const LinkButton = styled(AntButton)`
  padding: 0;
`;

export const IconButton = styled(CommonIconButton)`
  margin-top: ${props => props.theme.spacersm}
  padding: 0;
`;

export const Checkbox = styled(AntCheckbox)`
  justify-self: center;
`;

export const Input = styled(AntInput)``;

export const Tabs = styled(AntTabs)`
  height: 100%;
  width: 100%;

  .ant-tabs-bar {
    margin: 0;
  }

  .ant-tabs-content {
    padding: ${props => props.theme.spacermd};
    overflow: auto;
  }

  .ant-tabs-content {
    height: calc(100% - 44px); // hack: 44px is the height of .ant-tabs-bar
  }

  .ant-tabs-tabpane {
    height: 100%;
  }
`;

export const RadioButton = styled(Radio.Button)`
  font-weight: ${props => props.theme.buttonFontWeight};
  font-size: 13px;

  && {
    &.ant-radio-button-wrapper {
      background-color: ${colorTokens.black};
      color: ${colorTokens.white};
      padding-left: 10px;
      padding-right: 10px;
    }

    &.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
      background-color: ${colors.linkColor};
      border-color: ${colors.linkColor};

      &:hover {
        background-color: ${props => props.theme.primaryColor};

        &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
          // Have to use important to override antd important
          background-color: ${props => props.theme.primaryColor} !important;
          opacity: 1;
        }
      }
    }

    &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
      // Have to use important to override antd important
      background-color: ${colors.linkColor} !important;
      opacity: 1;
    }
  }
`;
