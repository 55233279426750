import { Condition, Conjunction } from "./types";

export interface ConditionError {
  property?: string;
  operator?: string;
  value?: string;
  hasError: boolean;
}

export function getConditionErrors(condition: Condition): ConditionError {
  const error: ConditionError = { hasError: false };

  if (condition.property.length === 0) {
    error.property = "Cannot be blank";
  }

  if (!condition.operator) {
    error.operator = "Cannot be blank";
  }

  if (condition.value.length === 0) {
    error.value = "Cannot be blank";
  }

  error.hasError = !!error.property || !!error.operator || !!error.value;

  return error;
}

export function conditionsConjunctionsToString(
  conditions: Condition[],
  conjunctions: Conjunction[]
) {
  if (conditions.length && conjunctions.length !== conditions.length - 1) {
    throw new Error("There must be one less conjunction for conditons");
  }

  let result = "";

  for (let i = 0; i < conditions.length; i++) {
    const conjunction = i !== conditions.length - 1 ? conjunctions[i] : undefined;
    const { property, operator, value } = conditions[i];
    result += `${property} ${operator} (${value})`;
    if (conjunction) {
      result += ` ${conjunction} `;
    }
  }

  return result;
}

export const humanizedConjunctions: Record<Conjunction, string> = {
  [Conjunction.AND]: "and",
  [Conjunction.OR]: "or"
};
