import React from "react";

import * as moment from "moment";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { colorTokens } from "../../cssConstants";
import IntegrationLogo from "../common/IntegrationLogo";
import { Table } from "../common/StyledComponents";
import { displayURL } from "../util/http";

import { InternalDataSourceProviders } from "./common";

export interface DataSource {
  id: string;
  dataSourceProviderId: string;
  integration: string;
  integrationTitle: string;
  name: string;
  credentials?: any;
  functionCount: number;
  synchronizedAt: Date | null;
  autoSyncEnabled: boolean;
  supportsSync: boolean;
}

interface DataSourceTableProps {
  dataSources: any[];
  loading: boolean;
}

const MissingLogo = styled.div`
  height: 60px;
  width: 60px;
`;

const SourceCell = styled.div`
  display: flex;
`;

const SourceProperties = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const SourceImageWrapper = styled.div`
  margin-right: 20px;
  min-width: 60px;

  svg {
    max-height: 75px;
    max-width: 60px;
  }
`;

const LogoWrapper = styled.div`
  svg {
    border-radius: ${props => props.theme.spacerxs};
    overflow: hidden;
  }
`;

const Badge = styled.div`
  padding: 2px 6px;
  background-color: ${props => props.theme.containerPrimaryTint};
  color: ${colorTokens.black};
  font-size: 13px;
  border-radius: ${props => props.theme.spacerxs};
  align-self: start;
`;

const renderSource = (dataSource: DataSource): JSX.Element => {
  let description = "";
  const credentials = dataSource.credentials;
  if (dataSource.integration === "http") {
    description = displayURL(credentials);
  } else if (credentials?.host) {
    description = credentials.host;
    if (credentials.port) {
      description += ":" + credentials.port;
    }
  }

  return (
    <SourceCell data-test="dataSourceRow">
      <SourceImageWrapper>
        <LogoWrapper>
          <IntegrationLogo name={dataSource.integration} defaultLogo={MissingLogo} />
        </LogoWrapper>
      </SourceImageWrapper>
      <SourceProperties>
        <h3 data-test="dataSourceName">{dataSource.name}</h3>
        {InternalDataSourceProviders.has(dataSource.integration) ? (
          <Badge>System</Badge>
        ) : (
          <span>{description}</span>
        )}
      </SourceProperties>
    </SourceCell>
  );
};

const columns = [
  {
    title: "Source",
    key: "source",
    width: "45%",
    render: renderSource
  },
  {
    title: "Last Synced",
    dataIndex: "synchronizedAt",
    key: "",
    width: "20%",
    render: (text?: string) => (text ? moment.utc(text).local().fromNow() : "")
  },
  {
    title: "Environments",
    key: "environments",
    dataIndex: "environmentCount",
    width: "20%"
  },
  {
    title: "Functions",
    key: "functions",
    dataIndex: "functionCount",
    width: "15%"
  }
];

const DataSourceTable = (props: DataSourceTableProps) => {
  const navigate = useNavigate();

  const handleRowClick = (dataSource: DataSource) => {
    navigate(`/settings/data-sources/${dataSource.id}`);
  };

  return (
    <React.Fragment>
      <Table
        loading={props.loading}
        rowKey={record => (record as DataSource).id}
        dataSource={props.dataSources}
        columns={columns}
        pagination={false}
        onRow={record => ({
          onClick: () => handleRowClick(record as DataSource)
        })}
      />
    </React.Fragment>
  );
};

export default DataSourceTable;
