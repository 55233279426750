import gql from "graphql-tag";
import { QueryHookOptions, useQuery } from "react-apollo";

const QUERY = gql`
  query AverageTimeToClose($queueId: ID!, $fromTimestamp: Int!, $toTimestamp: Int) {
    averageTimeToClose(
      queueId: $queueId
      fromTimestamp: $fromTimestamp
      toTimestamp: $toTimestamp
    )
  }
`;

interface Variables {
  queueId: string;
  fromTimestamp: number;
  toTimestamp?: number;
}

interface Data {
  averageTimeToClose: number | null;
}

export const useAverageTimeToClose = (options?: QueryHookOptions<Data, Variables>) =>
  useQuery<Data, Variables>(QUERY, options);
