import { SpaceComponentType } from "../../../../../../types";
import { FieldType } from "../../../../../common/AttributeInput/constants";
import { BlankValueType } from "../../../constants";

export enum ParameterType {
  COMPONENT = "component",
  PENDING = "none",
  FILE = "file",
  BINDING = "binding",
  STATIC = "value",
  UUID = "uuid",
  DATE_TODAY = "date_today",
  DATETIME_NOW = "datetime_now",
  TIME_NOW = "time_now",
  TEMPLATE = "template",
  UNRESOLVED = "unresolved"
}

export interface InputParameterOptions {
  value: any;
  label: string;
}

interface BaseInputParameter<T extends ParameterType> {
  name: string;
  type: T;
  hidden: boolean;
  required: boolean;
  field_type?: FieldType; // can be undefined for component case
  blank_value_type: BlankValueType;
  render_options?: InputParameterOptions[];
  binding?: string;
  label?: string;
}

export interface InputParameterBinding
  extends BaseInputParameter<ParameterType.BINDING> {
  binding: string;
}

export interface InputParameterComponent
  extends BaseInputParameter<ParameterType.COMPONENT> {
  component_type: SpaceComponentType;
  component_slug?: string;
  error?: {
    binding?: string; // nested because key must be 'binding'
  };
}

export interface InputParameterStatic extends BaseInputParameter<ParameterType.STATIC> {
  value: any;
}

export interface InputParameterTemplate
  extends BaseInputParameter<ParameterType.TEMPLATE> {
  template: string;
}

type GeneratedInputParameterType =
  | ParameterType.UUID
  | ParameterType.TIME_NOW
  | ParameterType.DATE_TODAY
  | ParameterType.DATETIME_NOW;

export type InputParameter =
  | BaseInputParameter<ParameterType.FILE>
  | BaseInputParameter<ParameterType.PENDING>
  | InputParameterTemplate
  | InputParameterBinding
  | InputParameterComponent
  | InputParameterStatic
  | BaseInputParameter<GeneratedInputParameterType>;
