import React, { useCallback, useMemo } from "react";

import { TinyColor } from "@ctrl/tinycolor";
import { Select as AntSelect } from "antd";

import { colorTokens } from "../../../../../../cssConstants";
import ColorPicker from "../../../../../common/ColorPicker";
import { useComponentConfigContext } from "../../common/ComponentConfigContext";
import { ConfigSection, Field } from "../../common/ConfigPanel";
import { Select } from "../../common/ConfigPanel/styledComponents";
import { styledInputs } from "../../common/DesignConfig/Inputs";
import HeaderConfigSection from "../../common/HeaderConfig";

const { Option } = AntSelect;

export const DEFAULT = {
  name: "Default",
  value: "default",
  properties: {
    header: "18px",
    regular: "14px"
  }
};

export const CONDENSED = {
  name: "Condensed",
  value: "condensed",
  properties: {
    header: "16px",
    regular: "12px"
  }
};

const FONT_SIZES = [DEFAULT, CONDENSED];

export function fontPropertiesFromValue(value?: string): Record<string, string> {
  if (value === "condensed") {
    return CONDENSED.properties;
  }

  return DEFAULT.properties;
}

export default function DesignConfig() {
  const context = useComponentConfigContext();
  const { draftComponent } = context.state;
  const { dispatch } = context;

  const themeProperties = useMemo(() => {
    return (
      draftComponent.properties.theme_properties || {
        headerBackgroundColor: colorTokens.grey200,
        headerTextColor: colorTokens.greyTransparent1300
      }
    );
  }, [draftComponent.properties.theme_properties]);

  const updateThemeProperties = useCallback(
    change => {
      dispatch({
        type: "UPDATE_COMPONENT_THEME_PROPERTIES",
        payload: {
          properties: {
            ...themeProperties,
            ...change
          }
        }
      });
    },
    [dispatch, themeProperties]
  );

  return (
    <>
      <HeaderConfigSection searchBoxCheckboxEnabled={true} />
      <ConfigSection title="Typography">
        <styledInputs.Field>
          <styledInputs.Label>Size</styledInputs.Label>
          <Select
            size="small"
            placeholder={DEFAULT.name}
            value={themeProperties["fontSize"]}
            getPopupContainer={trigger => trigger.parentNode as HTMLElement}
            style={{ width: "145px" }}
            onChange={val => {
              updateThemeProperties({ fontSize: val });
            }}
          >
            {FONT_SIZES.map(({ name, value }) => (
              <Option value={value} key={value}>
                {name}
              </Option>
            ))}
          </Select>
        </styledInputs.Field>
      </ConfigSection>
      <ConfigSection title="Style">
        <Field>
          <label>Header fill</label>
          <ColorPicker
            value={themeProperties["headerBackgroundColor"]}
            size="small"
            onChange={value => {
              const hoverColor = new TinyColor(value).darken(10).toHex8String();
              updateThemeProperties({
                headerBackgroundColor: value,
                headerBackgroundHoverColor: hoverColor
              });
            }}
          />
        </Field>
        <Field>
          <label>Header text</label>
          <ColorPicker
            value={themeProperties["headerTextColor"]}
            size="small"
            onChange={value => {
              updateThemeProperties({ headerTextColor: value });
            }}
          />
        </Field>
      </ConfigSection>
    </>
  );
}
