import React, { useEffect, useMemo, useState } from "react";

import { TinyColor } from "@ctrl/tinycolor";
import { Tabs } from "antd";
import { get } from "lodash";
import styled from "styled-components";

import { createPath, parsePath } from "../../../../util/binding";
import { reportException } from "../../../../util/exceptionReporting";
import { useSelectionStateContext } from "../../../layout/TransformationContext/TransformationContext";
import Panel from "../common/Panel";
import { useComponentStateContext } from "../contexts/ComponentStateContext";
import SpaceComponent, { Props } from "../SpaceComponent";

import { SpaceTabsComponent } from "./types";

const TabBar = ({
  children,
  className
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={className}
      onClick={evt => {
        // If a tab or a navigation icon is clicked prevent the component from being selected
        if (
          (evt.target instanceof HTMLElement &&
            evt.target.getAttribute("role") === "tab") ||
          evt.target instanceof SVGElement
        ) {
          evt.stopPropagation();
        }
      }}
    >
      {children}
    </div>
  );
};

const StyledTabBar = styled(TabBar)<{ barColor?: string }>`
  ${props =>
    props.barColor &&
    `
    .ant-tabs-ink-bar {
      background-color: ${props.barColor};
    }
  `}
`;

const StyledTab = styled.span<{ color?: string; hoverColor?: string }>`
  ${props => props.color && `color: ${props.color};`}

  ${props =>
    props.hoverColor &&
    `
    // Mimic Antd's color transitions
    transition: color 0.3s cubic-bezier(0.645,0.045,0.355,1);

    &:hover {
      color: ${props.hoverColor};
    }
  `}
`;

export default function SpaceTabs({ spaceComponent, ...props }: Props) {
  const {
    componentTreeNodes,
    properties: { tabs, theme_properties: rawThemeProperties }
  } = spaceComponent as SpaceTabsComponent;
  const { componentNode, input, registerBinding, unregisterBinding } =
    useComponentStateContext();
  const [activeTab, setActiveTab] = useState(tabs[0] ? tabs[0].component_slug : "");
  const activeTabIndex = useMemo(() => {
    return tabs.findIndex(t => t.component_slug === activeTab);
  }, [tabs, activeTab]);

  const { selected } = useSelectionStateContext();

  const themeProperties = useMemo(() => {
    return rawThemeProperties || {};
  }, [rawThemeProperties]);

  // Watch for space config selection changes and switch to selected tab
  useEffect(() => {
    const selectedTab = tabs.find(t => t.component_slug === selected);
    if (!selectedTab || selectedTab.component_slug === activeTab) return;
    setActiveTab(selectedTab.component_slug);
  }, [tabs, selected, activeTab]);

  // Register bindings for the visibility of child tabs
  useEffect(() => {
    const childTabPaths: string[] = [];
    if (!componentNode) return;
    for (const tab of tabs) {
      const path = createPath([
        ...parsePath(componentNode.path),
        tab.component_slug,
        "visible"
      ]);
      childTabPaths.push(path);
      registerBinding(path);
    }
    return () => {
      for (const path of childTabPaths) unregisterBinding(path);
    };
  }, [tabs, componentNode, registerBinding, unregisterBinding]);

  const hiddenTabs = useMemo(() => {
    if (!componentNode) return new Set();
    return new Set(
      tabs
        .filter(
          t =>
            get(
              input,
              createPath([
                ...parsePath(componentNode.path),
                t.component_slug,
                "visible"
              ])
            ) === false
        )
        .map(t => t.component_slug)
    );
  }, [tabs, componentNode, input]);

  // Select a previous tab if the active tab is hidden
  useEffect(() => {
    if (activeTab && hiddenTabs.has(activeTab)) {
      // select the previous still visible tab
      let idx = tabs.findIndex(t => t.component_slug === activeTab);
      while (idx >= 0) {
        idx--;
        if (!hiddenTabs.has(tabs[idx].component_slug)) {
          setActiveTab(tabs[idx].component_slug);
          return;
        }
      }
      setActiveTab("");
    }
  }, [tabs, activeTab, hiddenTabs]);

  const tabColors = useMemo(() => {
    return tabs.map((_, index) => {
      const color = themeProperties[`tab-${index}`];
      return {
        color,
        hoverColor: color ? new TinyColor(color).lighten(20).toHex8String() : undefined
      };
    });
  }, [themeProperties, tabs]);

  return (
    <Root hasError={props.hasConfigError}>
      <Tabs
        animated
        destroyInactiveTabPane
        activeKey={activeTab}
        onChange={activeKey => {
          setActiveTab(activeKey);
        }}
        renderTabBar={(props, DefaultTabBar) => {
          return (
            <StyledTabBar barColor={tabColors[activeTabIndex].color}>
              <DefaultTabBar {...props} />
            </StyledTabBar>
          );
        }}
      >
        {tabs.map((t, index) => {
          const tabComponent = componentTreeNodes.find(
            ctn => ctn.slug === t.component_slug
          );
          if (!tabComponent) {
            reportException(new Error("Tab component not found."), {
              extra: { spaceComponent, tab: t }
            });
            return null;
          }
          if (hiddenTabs.has(t.component_slug)) {
            return null;
          }
          return (
            <Tabs.TabPane
              key={t.component_slug}
              tab={
                <StyledTab
                  color={tabColors[index].color}
                  hoverColor={tabColors[index].hoverColor}
                >
                  {t.name}
                </StyledTab>
              }
            >
              <SpaceComponent {...props} spaceComponent={tabComponent} />
            </Tabs.TabPane>
          );
        })}
      </Tabs>
      <>
        {tabs
          .filter(t => t.component_slug !== activeTab)
          .map(t => {
            // Mount non-active tabs so they can set their output
            const tabComponent = componentTreeNodes.find(
              ctn => ctn.slug === t.component_slug
            )!;
            return (
              <div key={tabComponent.slug} style={{ display: "none" }}>
                <SpaceComponent spaceComponent={tabComponent} {...props} />
              </div>
            );
          })}
      </>
    </Root>
  );
}

const TAB_BAR_HEIGHT = "44px";
const Root = styled(Panel)`
  height: 100%;
  .ant-tabs {
    height: 100%;
  }
  .ant-tabs-bar {
    height: ${TAB_BAR_HEIGHT};
    margin-bottom: 0;
  }
  .ant-tabs-content {
    height: calc(100% - ${TAB_BAR_HEIGHT});
  }
`;
