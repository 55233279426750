import { ConfigValidationError } from "../../../../../../types";
import { ComponentConfigState } from "../../../../types";
import selectVisibilityRuleErrors from "../../common/VisibilityRulesManager/selectErrors";
import { SpaceTabsComponent } from "../types";

export default function errorSelector(
  state: ComponentConfigState
): ConfigValidationError[] {
  const errors: ConfigValidationError[] = [];
  const { draftComponent } = state;

  const { tabs } = (draftComponent as SpaceTabsComponent).properties;
  const tabNames = new Set<string>();
  tabs.forEach((tab, index) => {
    if (tabNames.has(tab.name)) {
      errors.push({
        field: "TABS",
        message: "Tab name must be unique.",
        index
      });
    }
    tabNames.add(tab.name);
    if (!tab.name) {
      errors.push({
        field: "TABS",
        message: "Tab name is required.",
        index
      });
    }
  });

  return errors.concat(selectVisibilityRuleErrors(draftComponent));
}
