import React, { useRef, useState } from "react";

import { useSearchParams, useParams, useNavigate } from "react-router-dom";

import { TaskFilters } from "..";
import ButtonNew from "../../../common/ButtonNew/ButtonNew";
import EvaluaterContextContainer from "../../../common/CodeSandbox/EvaluaterContext";
import useAuthUser from "../../../common/hooks/useAuthUser";
import useDebouncedValue from "../../../common/hooks/useDebouncedValue";
import usePaths from "../../../common/hooks/usePaths";
import useSearchParamsUpdater from "../../../common/hooks/useSearchParamsUpdater";
import Message from "../../../common/Message";
import PerspectiveTimestamp from "../../../common/PerspectiveTimestamp/PerspectiveTimestamp";
import HighlightingTableRow from "../../../common/TableNew/HighlightingTableRow";
import { TableInstance } from "../../../common/TableNew/TableNew";
import AnalyticsButton from "../../common/AnalyticsButton";
import PageHeader from "../../common/PageHeader";
import StatePill from "../../common/StatePill";
import { DEFAULT_STATE_COLOR } from "../../Config/reducer/utils";
import DeleteTaskModal from "../Home/DeleteTaskModal";

import AssigneeField from "./AssigneeField";
import LeftNav from "./LeftNav";
import ModalTaskForm from "./ModalTaskForm";
import { PermissionsModal } from "./Permissions/PermissionsModal";
import QueueHeader from "./QueueHeader";
import RefreshBanner from "./RefreshBanner";
import {
  QueueRoot,
  QueueMain,
  TaskDrawer,
  TableContainer,
  ButtonContainer,
  TaskTable
} from "./styledComponents";
import { TabOption } from "./TaskDrawer/TaskTabs";
import UpdateTaskFormContainer from "./UpdateTaskForm/UpdateTaskForm";
import useQueueClient from "./useQueueClient/useQueueClient";

const columns = [
  {
    Header: "Title",
    accessor: "title",
    Cell: (data: any) => <span title={data.value}>{data.value}</span>
  },
  {
    Header: "State",
    accessor: "state",
    Cell: (data: any) => <StatePill state={data.cell.value} />
  },
  {
    Header: "Created at",
    accessor: "createdAt",
    Cell: (data: any) => <PerspectiveTimestamp isoDate={data.cell.value} />
  },
  {
    Header: "Updated at",
    accessor: "updatedAt",
    Cell: (data: any) => <PerspectiveTimestamp isoDate={data.cell.value} />
  },
  {
    Header: "Assigned to",
    accessor: "assignee",
    Cell: (data: any) => <AssigneeField assignee={data.cell.value} />
  }
];

interface Params extends Record<string, any> {
  slug: string;
}

export default function Queue() {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isNewTaskModalOpen, setIsTaskModalOpen] = useState(false);
  const [permissionsOpen, setPermissionsOpen] = useState(false);

  const navigate = useNavigate();
  const { queuesapp_getEditQueue } = usePaths();
  const params = useParams<Params>();

  if (!params.slug) throw new Error("Not found");

  const { isAdmin } = useAuthUser();
  const [searchParams] = useSearchParams();
  const updateParams = useSearchParamsUpdater();
  const selectedTaskId = searchParams.get("task_id") || undefined;
  const taskTab = searchParams.get("taskTab") || undefined;
  const tableRef = useRef<TableInstance>(null);

  const stateParam = searchParams.get("state") || undefined;
  // Use a separate selectedStateId so we can easily set a default for an undefined value
  // This also prevents us polluting the query params so back navigation is easier
  const [selectedStateId, setSelectedStateId] = React.useState<string | undefined>();

  const afterCursor = searchParams.get("afterCursor") || undefined;
  const beforeCursor = searchParams.get("beforeCursor") || undefined;
  const searchParam = searchParams.get("search") || undefined;

  const [deleteTaskId, setDeleteTaskId] = React.useState<string | undefined>();
  const [updateTaskId, setUpdateTaskId] = React.useState<string | undefined>();
  const [search, setSearch] = React.useState<string | undefined>(searchParam);
  const debouncedSearch = useDebouncedValue(search, 300);
  const [searchTaskFilters, setSearchTaskFilters] = React.useState<
    Partial<TaskFilters>
  >({});

  React.useEffect(() => {
    if (search !== debouncedSearch) {
      updateParams({ search: debouncedSearch });
    }
  }, [updateParams, debouncedSearch, search]);

  const taskFilters = debouncedSearch?.length
    ? {
        search: debouncedSearch,
        ...searchTaskFilters
      }
    : {
        stateIdIn:
          selectedStateId && selectedStateId !== "all" ? [selectedStateId] : undefined,
        isArchived: selectedStateId === "all" ? false : undefined // Don't show archived for ALL tab,
      };

  const {
    queue,
    tasks,
    taskChangeCount,
    beforeTaskCount,
    totalTaskCount,
    pageInfo,
    refetchTasks,
    onTaskUpdated: dispatchTaskUpdated,
    onTaskCreated: dispatchTaskCreated,
    onTaskDeleted: dispatchTaskDeleted,
    onTaskTransitioned: dispatchTaskTransitioned
  } = useQueueClient({
    queueSlug: params.slug,
    taskFilters,
    afterCursor,
    beforeCursor,
    setCursors: updateParams
  });

  React.useEffect(() => {
    if (stateParam) {
      if (stateParam !== selectedStateId) {
        setSelectedStateId(stateParam);
      }
    } else if (queue?.states?.length && selectedStateId !== queue?.states[0].id) {
      setSelectedStateId(queue?.states[0].id);
    }
  }, [stateParam, selectedStateId, queue?.states, setSelectedStateId]);

  const setSearchTaskFiltersRefetch = React.useCallback(
    (value: Partial<TaskFilters>) => {
      setSearchTaskFilters(value);
      refetchTasks();
    },
    [setSearchTaskFilters, refetchTasks]
  );

  React.useEffect(() => {
    if (queue?.states) {
      setSearchTaskFilters({
        stateIdIn: queue?.states?.map(s => s.id)
      });
    }
  }, [queue?.states]);

  const selectedTaskIndex = React.useMemo(() => {
    return tasks.findIndex(t => t.id === selectedTaskId);
  }, [tasks, selectedTaskId]);

  const selectedTask = selectedTaskIndex > -1 ? tasks[selectedTaskIndex] : undefined;

  // Keep table selection consistent with selectedTaskIndex
  React.useEffect(() => {
    if (selectedTaskIndex > -1) {
      tableRef.current?.selectRows([selectedTaskIndex]);
    } else {
      tableRef.current?.toggleAllRowsSelected(false);
    }
  }, [selectedTaskIndex]);

  const onSelectTasks = React.useCallback(
    (taskIndexes?: number[]) => {
      if (!taskIndexes?.length) {
        updateParams({ task_id: undefined, taskTab: undefined });
        return;
      }

      // At the moment we only support a single index
      const selectedIndex = taskIndexes[0];
      if (selectedIndex < 0 || selectedIndex >= tasks.length) {
        throw new Error("Index out of range");
      }

      const taskSelected = tasks[selectedIndex];
      if (taskSelected.id !== selectedTaskId) {
        updateParams({ task_id: taskSelected.id, taskTab: TabOption.Details });
      }
    },
    [tasks, selectedTaskId, updateParams]
  );

  const onTaskAdded = React.useCallback(
    async (taskId: string) => {
      await dispatchTaskCreated(taskId);
      setIsTaskModalOpen(false);
    },
    [setIsTaskModalOpen, dispatchTaskCreated]
  );

  const onTaskDeleted = React.useCallback(
    (taskId: string) => {
      setDeleteTaskId(undefined);
      dispatchTaskDeleted(taskId);
    },
    [setDeleteTaskId, dispatchTaskDeleted]
  );

  const onTaskUpdated = React.useCallback(
    (taskId: string) => {
      setUpdateTaskId(undefined);
      dispatchTaskUpdated(taskId);

      Message.success("Task updated");
    },
    [setUpdateTaskId, dispatchTaskUpdated]
  );

  const tabs = React.useMemo(() => {
    const queueStates = (queue?.states || []).map(state => {
      return {
        label: state.name,
        value: state.id,
        color: `#${state.color}` || DEFAULT_STATE_COLOR
      };
    });

    return [{ label: "All open", value: "all", color: DEFAULT_STATE_COLOR }].concat(
      queueStates
    );
  }, [queue?.states]);

  // If no provided value, default to the first user created state, otherwise "All open".
  const activeState =
    selectedStateId || (tabs.length > 1 ? tabs[1].value : tabs[0].value);

  return (
    <QueueRoot>
      <EvaluaterContextContainer>
        <PageHeader
          title="My tasks"
          controls={
            <ButtonContainer>
              {isAdmin && (
                <>
                  <ButtonNew
                    type="noFill"
                    onClick={() => navigate(queuesapp_getEditQueue(params.slug!))}
                  >
                    Edit queue
                  </ButtonNew>
                  <ButtonNew type="brand" onClick={() => setPermissionsOpen(true)}>
                    Share
                  </ButtonNew>
                  <AnalyticsButton queueSlug={params.slug!} />
                </>
              )}
            </ButtonContainer>
          }
          onToggleNav={() => setIsNavOpen(!isNavOpen)}
        />
        <LeftNav open={isNavOpen} selected={queue?.slug || ""} />
        <QueueMain>
          <RefreshBanner
            newTasksCount={taskChangeCount}
            onRefresh={() => refetchTasks()}
          />
          <QueueHeader
            title={queue?.name || "…"}
            tabs={tabs}
            activeTab={activeState}
            states={queue?.states || []}
            search={search}
            setSearch={setSearch}
            taskFilters={searchTaskFilters}
            setTaskFilters={setSearchTaskFiltersRefetch}
            visibleTaskCount={tasks.length}
            beforeTaskCount={beforeTaskCount}
            totalTaskCount={totalTaskCount}
            onTabSelected={tab =>
              updateParams({
                state: tab.value,
                task_id: undefined,
                beforeCursor: undefined,
                afterCursor: undefined,
                taskTab: undefined
              })
            }
            pageInfo={pageInfo}
            controls={
              <ButtonNew
                type="primary"
                icon="plus"
                onClick={() => {
                  setIsTaskModalOpen(true);
                }}
              >
                New task
              </ButtonNew>
            }
            onNextPage={() => {
              updateParams({
                task_id: undefined,
                taskTab: undefined,
                beforeCursor: undefined,
                afterCursor: pageInfo?.endCursor
              });
            }}
            onPreviousPage={() => {
              updateParams({
                task_id: undefined,
                taskTab: undefined,
                beforeCursor: pageInfo?.startCursor,
                afterCursor: undefined
              });
            }}
          />
          <TableContainer>
            <TaskTable
              ref={tableRef}
              data={tasks}
              columns={columns}
              Row={HighlightingTableRow}
              onSelectRows={onSelectTasks}
            />
          </TableContainer>
        </QueueMain>
        <TaskDrawer
          taskId={selectedTask?.id}
          tab={taskTab ? (taskTab as TabOption) : TabOption.Details}
          selectedTaskIndex={selectedTaskIndex || 0}
          taskCount={tasks.length}
          onSelectTask={onSelectTasks}
          onUpdateTask={setUpdateTaskId}
          onDeleteTask={setDeleteTaskId}
          onTransitionTask={dispatchTaskTransitioned}
          onChangeTab={newTab => updateParams({ taskTab: newTab })}
        />
        {isNewTaskModalOpen && !!queue && (
          <ModalTaskForm
            visible
            queue={queue}
            onCompleted={taskId => onTaskAdded(taskId)}
            onCancel={() => setIsTaskModalOpen(false)}
          />
        )}
        {permissionsOpen && !!queue && (
          <PermissionsModal
            queueName={queue.name}
            queueSlug={queue.slug}
            onCancel={() => setPermissionsOpen(false)}
          />
        )}
        {deleteTaskId && (
          <DeleteTaskModal
            taskId={deleteTaskId}
            onComplete={() => onTaskDeleted(deleteTaskId)}
            onCancel={() => setDeleteTaskId(undefined)}
          />
        )}

        {queue && selectedTask && updateTaskId && (
          <UpdateTaskFormContainer
            visible
            taskId={selectedTask.id}
            queueSlug={queue.slug}
            onCompleted={() => onTaskUpdated(selectedTask.id)}
            onCancel={() => setUpdateTaskId(undefined)}
          />
        )}
      </EvaluaterContextContainer>
    </QueueRoot>
  );
}
