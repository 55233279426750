import React from "react";

import styled from "styled-components";

import { SpaceComponentObject } from "../../../../types";
import useObservedRect from "../../../common/hooks/useObservedRect";
import {
  useSelectionStateContext,
  useTransformationActionContext
} from "../../layout/TransformationContext/TransformationContext";
import useIsComponentVisible from "../../SpaceRoot/SpaceComponent/common/useIsComponentVisible";
import {
  useComponentPathContext,
  useIsFirstInstance
} from "../../SpaceRoot/SpaceComponent/contexts/ComponentPathContext";
import { useStableSpaceContext } from "../../SpaceRoot/SpaceContext";
import { useSpaceComponentPackage } from "../../SpaceRoot/SpaceContext/StableSpaceContext";
import { useCanvasViewportContext } from "../Canvas/CanvasViewportContext";
import { CONFIG_ICON_SIZE } from "../constants";
import Draggable from "../Draggable";
import Selection from "../Selection/Selection";
import { ElementLayout } from "../util";

import { ELEMENT_Z_INDEX } from "./constants";

export interface ElementProps {
  component: SpaceComponentObject;
  layout: ElementLayout;
  children: React.ReactNode;
}

export default function AbsoluteElement({
  component: { slug },
  component,
  layout,
  children
}: ElementProps) {
  const rootEl = React.useRef<HTMLDivElement | null>(null);
  const { editMode } = useStableSpaceContext();
  const path = useComponentPathContext();
  const { select, hover, unhover, startMove } = useTransformationActionContext();
  const { locked } = useSelectionStateContext();
  const { getCanvasPointForPagePoint, registerComponentDepth } =
    useCanvasViewportContext();
  const isFirstInstance = useIsFirstInstance();
  const pkg = useSpaceComponentPackage(component.type);

  const rect = useObservedRect(rootEl);

  React.useLayoutEffect(() => {
    registerComponentDepth(slug, layout, rect.height);
  }, [slug, layout, rect.height, registerComponentDepth]);

  const handleDragStart = React.useCallback(
    function handleDragStart(coords: DOMPoint) {
      // Special case `HEADER`, which for historical reasons does not have a path.
      const ensuredPath = component.type === "HEADER" ? component.slug : path;
      let domRect = rootEl.current?.getBoundingClientRect() || new DOMRect();
      const offset = getCanvasPointForPagePoint(
        new DOMPoint(domRect.left, domRect.top)
      );
      domRect = new DOMRect(offset.x, offset.y, domRect.width, domRect.height);
      startMove(ensuredPath, coords, layout, domRect);
    },
    [
      component.type,
      component.slug,
      path,
      layout,
      startMove,
      getCanvasPointForPagePoint
    ]
  );

  const isVisible = useIsComponentVisible(component.properties);
  const componentStyle = component.properties.styles?.root || {};
  const style: Partial<ElementLayout> & {
    outline?: string;
  } = React.useMemo(() => {
    const { overflow, ..._layout } = layout;
    const style: Partial<ElementLayout> = ElementLayout.stripNonStyleProps(
      _layout as ElementLayout
    );

    style.display = isVisible ? "block" : "none";

    if (pkg.isHeadless && pkg.hasConfigIcon) {
      style.width = CONFIG_ICON_SIZE.width;
      style.height = CONFIG_ICON_SIZE.height;
    }

    return style;
  }, [layout, isVisible, pkg]);

  return (
    <Root
      ref={rootEl}
      id={isFirstInstance ? `element-${slug}` : undefined}
      data-test={`element-${slug}`}
      style={style}
      onClick={e => {
        if (!editMode) return;
        e.stopPropagation();
        select(slug);
      }}
      onMouseEnter={() => {
        if (!editMode) {
          return;
        }
        hover(slug);
      }}
      onMouseLeave={() => {
        if (!editMode) return;
        unhover(slug);
      }}
    >
      <Draggable
        disable={!editMode || locked.includes(slug)}
        onDragStart={handleDragStart}
      >
        <ChildrenWrapper
          style={{
            overflow: layout.overflow,
            ...componentStyle
          }}
        >
          {children}
        </ChildrenWrapper>
      </Draggable>
      <Selection elRef={rootEl} component={component} />
    </Root>
  );
}

const Root = styled.div`
  position: absolute;
  // HACK: Make sure components are on top of z-index positioned elements of other SpaceComponents
  //       Like the gutter in JSON Input or the thead of table.
  z-index: ${ELEMENT_Z_INDEX};
`;

const ChildrenWrapper = styled.div`
  width: 100%;
  height: 100%;
`;
