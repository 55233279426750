import React, { useMemo } from "react";

import { Select } from "antd";

import { ReturnSchema } from "../../../../../../constants";
import {
  BaseFunctionNodeBasic,
  Binding,
  BindingShape,
  DataSourceNode,
  FunctionNode,
  InputParameter
} from "../../../../../../types";
import { BindingSchema } from "../../../../schema";
import { ConditionActionType, PipelineStep } from "../../../useFunctionEditor/queries";
import { SetPipelineStepConditionArguments } from "../../../useFunctionEditor/useFunctionEditor";
import * as common from "../../styledComponents";
import { DataSourceNodeWithFunctions } from "../../types";
import { Hr, SelectLabel } from "../common/styledComponents";
import ConfigConditionalStep from "../ConfigConditionalStep";
import ConfigExpressionStep from "../ConfigExpressionStep";
import ConfigFunctionStep from "../ConfigFunctionStep";
import ConfigLoopStep from "../ConfigLoopStep";
import {
  PipelineActionDisplayNames,
  PipelineStepType,
  PipelineStepTypes
} from "../constants";
import { StepSummaryHeader } from "../styledComponents";

import * as styled from "./styledComponents";

function isValidSchemaStep(step: PipelineStep): boolean {
  if (!step.type) {
    return false;
  }

  if (step.type === PipelineStepType.FUNCTION && !step.function) {
    return false;
  }

  if (step.type === PipelineStepType.EXPRESSION && !step.expression) {
    return false;
  }

  return true;
}

function functionToSchema(func: FunctionNode): Binding[] {
  switch (func.returnSchema) {
    case ReturnSchema.OBJECT_ARRAY:
      return [];
    case ReturnSchema.OBJECT:
      return (func.functionAttributes?.edges || []).map(({ node }) => ({
        name: node.name,
        title: node.name,
        shape: BindingShape.SCALAR,
        type: node.sourceType
      }));
    case ReturnSchema.UNKNOWN:
      return [];
  }
}

function stepToSchema(step: PipelineStep): Binding[] {
  if (!step.type) {
    throw new Error(`Expected Step ${step.order} to have type defined`);
  }

  switch (step.type) {
    case PipelineStepType.FUNCTION:
      if (!step.function) {
        throw new Error(`Expected function to be defined for step ${step.order}`);
      }
      return functionToSchema(step.function);
    default:
      return [];
  }
}

export const getStepsSchema = (
  steps: PipelineStep[]
): Record<string, BindingSchema> | undefined => {
  return steps
    .filter(s => isValidSchemaStep(s))
    .reduce((prev, step) => {
      return {
        ...prev,
        [step.slug]: {
          title: step.title,
          schema: stepToSchema(step)
        }
      } as Record<string, BindingSchema>;
    }, {});
};

export type DataSource = DataSourceNodeWithFunctions<BaseFunctionNodeBasic>;

const { Option } = Select;

interface Props {
  steps: PipelineStep[];
  step: PipelineStep;
  className?: string;
  selectedStepConditionIndex?: number;
  showErrors: boolean;
  onRemovePipelineStep: (stepIndex: number) => void;
  onSetPipelineStepName: (stepIndex: number, newName: string) => void;
  onSetPipelineStepType: (stepIndex: number, type: PipelineStepType) => void;
  onSetPipelineStepDataSource: (stepIndex: number, dataSource: DataSourceNode) => void;
  onSetPipelineStepFunction: (stepIndex: number, func: FunctionNode) => void;
  onSetPipelineStepExpression: (stepIndex: number, expression: string) => void;
  onSetPipelineStepCondition: (
    stepIndex: number,
    payload: SetPipelineStepConditionArguments
  ) => void;
  onSetPipelineStepConditionActionType: (
    stepIndex: number,
    conditionIndex: number,
    actionType: ConditionActionType
  ) => void;
  onSetPipelineStepConditionFunction: (
    stepIndex: number,
    conditionIndex: number,
    functionId?: string
  ) => void;
  onSetPipelineStepInputParameters: (
    stepIndex: number,
    parameters: InputParameter[]
  ) => void;
  onSelectStepCondition: (conditionIndex: number) => void;
  onSetPipelineStepConditionInputParameters: (
    stepIndex: number,
    conditionIndex: number,
    parameters: InputParameter[]
  ) => void;
  onLoadPipelineStepConditionFunction: (
    stepIndex: number,
    conditionIndex: number,
    func: FunctionNode
  ) => void;
  onSetPipelineStepIteratorBinding: (
    stepIndex: number,
    iteratorBinding: string
  ) => void;
  onSetPipelineStepIteratorItemName: (
    stepIndex: number,
    iteratorItemName: string
  ) => void;
}

export default function ConfigPipelineStep({
  steps,
  step,
  className,
  selectedStepConditionIndex,
  showErrors,
  onRemovePipelineStep,
  onSetPipelineStepName,
  onSetPipelineStepType,
  onSetPipelineStepDataSource,
  onSetPipelineStepFunction,
  onSetPipelineStepExpression,
  onSetPipelineStepCondition,
  onSetPipelineStepConditionActionType,
  onSetPipelineStepConditionFunction,
  onSetPipelineStepInputParameters,
  onSelectStepCondition,
  onSetPipelineStepConditionInputParameters,
  onLoadPipelineStepConditionFunction,
  onSetPipelineStepIteratorBinding,
  onSetPipelineStepIteratorItemName
}: Props) {
  const previousSteps = useMemo(() => {
    return steps.slice(0, step.order);
  }, [steps, step]);

  const previousStepsSchema = getStepsSchema(previousSteps);

  // Disable delete if we are on the last remaining step, and it has no information set
  const deleteDisabled = steps.length === 1 && step.type === undefined;

  return (
    <styled.Container className={className}>
      <StepSummaryHeader
        step={step}
        deleteDisabled={deleteDisabled}
        onRemoveStep={onRemovePipelineStep}
        onSetStepName={onSetPipelineStepName}
      />
      <Hr />
      <styled.BodyContainer>
        <div>
          <SelectLabel>Type</SelectLabel>
          <common.Select
            getPopupContainer={trigger => trigger.parentNode as HTMLElement}
            data-test="actionTypeSelect"
            placeholder="Select an action type"
            value={step.type}
            onChange={value =>
              onSetPipelineStepType(step.order, value as PipelineStepType)
            }
          >
            {PipelineStepTypes.map(type => (
              <Option value={type} key={type}>
                {PipelineActionDisplayNames[type]}
              </Option>
            ))}
          </common.Select>
        </div>
        {step.type === PipelineStepType.FUNCTION && (
          <ConfigFunctionStep
            step={step}
            previousStepsSchema={previousStepsSchema}
            onSetPipelineStepDataSource={onSetPipelineStepDataSource}
            onSetPipelineStepFunction={onSetPipelineStepFunction}
            onSetPipelineStepInputParameters={onSetPipelineStepInputParameters}
          />
        )}
        {step.type === PipelineStepType.EXPRESSION && (
          <styled.StepContentContainer>
            <ConfigExpressionStep
              step={step}
              previousStepsSchema={previousStepsSchema}
              onSetPipelineStepExpression={onSetPipelineStepExpression}
              onSetPipelineStepInputParameters={onSetPipelineStepInputParameters}
            />
          </styled.StepContentContainer>
        )}
        {step.type === PipelineStepType.CONDITIONAL && (
          <styled.StepContentContainer>
            <ConfigConditionalStep
              step={step}
              previousStepsSchema={previousStepsSchema}
              selectedConditionIndex={selectedStepConditionIndex}
              showErrors={showErrors}
              onSetPipelineStepInputParameters={onSetPipelineStepInputParameters}
              onSelectCondition={onSelectStepCondition}
              onSetPipelineStepCondition={onSetPipelineStepCondition}
              onSetPipelineStepConditionActionType={
                onSetPipelineStepConditionActionType
              }
              onSetPipelineStepConditionFunction={onSetPipelineStepConditionFunction}
              onSetPipelineStepConditionInputParameters={
                onSetPipelineStepConditionInputParameters
              }
              onLoadPipelineStepConditionFunction={onLoadPipelineStepConditionFunction}
            />
          </styled.StepContentContainer>
        )}
        {step.type === PipelineStepType.LOOP && (
          <styled.StepContentContainer>
            <ConfigLoopStep
              step={step}
              steps={steps}
              previousStepsSchema={previousStepsSchema}
              onSetPipelineStepInputParameters={onSetPipelineStepInputParameters}
              onSetPipelineStepIteratorBinding={onSetPipelineStepIteratorBinding}
              onSetPipelineStepIteratorItemName={onSetPipelineStepIteratorItemName}
              onSetPipelineStepDataSource={onSetPipelineStepDataSource}
              onSetPipelineStepFunction={onSetPipelineStepFunction}
            />
          </styled.StepContentContainer>
        )}
      </styled.BodyContainer>
    </styled.Container>
  );
}
