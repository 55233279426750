import React from "react";

import { AttributeTypes } from "../../../../../../constants";
import {
  Filter,
  FilterTypes,
  FiltersOption,
  ViewFilterOperator
} from "../../../../../../types";
import { SortableItemCompact } from "../../../../../common/SortableList";
import Spacer from "../../../../../common/Spacer";
import { humanize } from "../../../../../util";
import { assertNever } from "../../../../../util/assertNever";
import { parsePath } from "../../../../../util/binding";
import { ComponentConfigState } from "../../../../types";
import { useComponentConfigContext } from "../ComponentConfigContext";
import { ConfigPanelPopper, ConfigSection } from "../ConfigPanel";
import {
  ConfigPanelActionTypes,
  useSpaceConfigPanelContext
} from "../ConfigPanel/ConfigPanelContext";
import { Checkbox } from "../ConfigPanel/styledComponents";

import FilterDetails from "./FilterDetails";
import { ensureFilterListManagerState } from "./reducer/reducer";

export interface Props {
  title: string;
  description?: string;
  filtersOptions: FiltersOption[];
  onChange?: (filters: Filter[]) => void;
}

const createEmptyFilter = (filtersOptions: FiltersOption[]): Filter => {
  const f = filtersOptions[0];

  return {
    type: FilterTypes.Value,
    operator: ViewFilterOperator.EQUALS,
    attribute: f.sourceName,
    value: f.sourceType === AttributeTypes.BOOL ? false : undefined
  };
};

export const getFilterValue = (
  filter: Filter,
  components: Map<string, ComponentConfigState>
) => {
  switch (filter.type) {
    case FilterTypes.Value:
      return filter.value;
    case FilterTypes.Binding:
      if (!filter.binding) return;
      const bindingParts = parsePath(filter.binding);
      const componentName = components.get(String(bindingParts[0]))?.draftComponent
        .name;
      bindingParts[0] = componentName || humanize(String(bindingParts[0])); // don't humanize name
      return bindingParts.map((v, i) => (i > 0 ? humanize(String(v)) : v)).join(" / ");
    case FilterTypes.Duration:
      return filter.duration_iso;
    default:
      assertNever(filter);
  }
};

const getFilterId = (idx: number) => `filter${idx}`;
const getFilterName = (idx: number, componentName: string) =>
  `${componentName} Filter ${idx + 1}`;

export default function FilterListManagerSection({
  title,
  description,
  filtersOptions,
  onChange
}: Props) {
  const [activeIdx, setActiveIdx] = React.useState(-1);
  const { state, errors, space, dispatch } = useComponentConfigContext();
  const { state: configPanelState, dispatch: configPanelDispatch } =
    useSpaceConfigPanelContext();
  const { draftComponent, filterValidityTrackers } =
    ensureFilterListManagerState(state);
  const filters = draftComponent.properties.filters as Filter[];
  const filtersErrors = errors.filter(e => e.field === "FILTERS");

  const closeConfigPopper = React.useCallback(() => {
    setActiveIdx(-1);
    configPanelDispatch({
      type: ConfigPanelActionTypes.CLOSE_POPPER
    });
  }, [configPanelDispatch]);

  const handleChange = React.useCallback(
    (filters: Filter[]) =>
      onChange
        ? onChange(filters)
        : dispatch({
            type: "MERGE_DRAFT_COMPONENT",
            payload: {
              change: {
                properties: {
                  filters
                }
              }
            }
          }),
    [onChange, dispatch]
  );

  const handleAdd = React.useCallback(() => {
    handleChange(filters.concat(createEmptyFilter(filtersOptions)));
    setActiveIdx(filters.length);
  }, [handleChange, filters, filtersOptions]);

  React.useEffect(() => {
    if (activeIdx > -1 && activeIdx < filters.length) {
      const id = getFilterId(activeIdx);
      configPanelDispatch({
        type: ConfigPanelActionTypes.OPEN_POPPER,
        payload: {
          popperIdentifier: id
        }
      });
    }
  }, [activeIdx, configPanelDispatch, filters]);

  if (filtersOptions.length === 0) return null;

  const activeFilterId = activeIdx > -1 ? getFilterId(activeIdx) : undefined;
  const isConfigOpen =
    activeFilterId && configPanelState.activePopperIdentifier === activeFilterId;

  return (
    <ConfigSection id="addFilterButton" title={title} onAdd={handleAdd}>
      {description && <p>{description}</p>}
      {filters.map((filter, idx) => {
        const filterId = getFilterId(idx);
        const filterName = getFilterName(idx, draftComponent.name);
        const filterValue = getFilterValue(filter, space.components);
        const errorMessage = filtersErrors.find(e => e.index === idx)?.message || "";
        const filterDescription =
          filter.attribute && filterValue !== undefined
            ? `${filter.attribute} ${filter.operator} ${filterValue}`
            : filterName;

        return (
          <SortableItemCompact
            id={filterId}
            key={idx}
            sortKey={`filter${idx}`}
            isSelected={activeIdx === idx}
            onClick={() => setActiveIdx(idx)}
            onRemove={() => {
              const nextFilters = [...filters];
              nextFilters.splice(idx, 1);
              handleChange(nextFilters);
            }}
            errorMessage={errorMessage}
            title={filterDescription}
          >
            {filterDescription}
          </SortableItemCompact>
        );
      })}
      {isConfigOpen && activeFilterId && (
        <ConfigPanelPopper
          popperId={activeFilterId}
          popperReferenceElement={document.getElementById(activeFilterId) || undefined}
          onCancel={closeConfigPopper}
        >
          <FilterDetails
            key={activeIdx}
            idx={activeIdx}
            header={getFilterName(activeIdx, draftComponent.name)}
            filter={filters[activeIdx]}
            filtersOptions={filtersOptions}
            onChange={(filter: Filter) => {
              const nextFilters = [...filters];
              nextFilters[activeIdx] = filter;
              handleChange(nextFilters);
            }}
            setFilterValueValidity={(idx, valid) => {
              const nextFilterValidityTrackers = [...filterValidityTrackers];
              nextFilterValidityTrackers.splice(idx, 1, valid);
              dispatch({
                type: "SET_FILTER_VALIDITITY_TRACKERS",
                payload: {
                  filterValidityTrackers: nextFilterValidityTrackers
                }
              });
            }}
          />
        </ConfigPanelPopper>
      )}
      <Spacer size="md" />
      <Checkbox
        data-test="filters_required"
        checked={draftComponent.properties.is_filter_required}
        onChange={({ target: { checked } }) => {
          dispatch({
            type: "MERGE_DRAFT_COMPONENT",
            payload: {
              change: { properties: { is_filter_required: checked } }
            }
          });
        }}
      >
        Require a filter to be set before loading data.
      </Checkbox>
    </ConfigSection>
  );
}
