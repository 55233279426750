export enum PipelineStepType {
  FUNCTION = "FUNCTION",
  EXPRESSION = "EXPRESSION",
  CONDITIONAL = "CONDITIONAL",
  LOOP = "LOOP"
}

export const PipelineStepTypes = [
  PipelineStepType.FUNCTION,
  PipelineStepType.EXPRESSION,
  PipelineStepType.CONDITIONAL,
  PipelineStepType.LOOP
];

export const PipelineActionDisplayNames: Record<PipelineStepType, string> = {
  [PipelineStepType.FUNCTION]: "Function",
  [PipelineStepType.EXPRESSION]: "Execute JavaScript",
  [PipelineStepType.CONDITIONAL]: "Condition",
  [PipelineStepType.LOOP]: "Loop"
};
