import React, { useCallback } from "react";

import { Collapse } from "antd";
import styled from "styled-components";

import { reportException } from "../../../../util/exceptionReporting";
import { useCurrentTransformingComponentContext } from "../../../layout/TransformationContext/TransformationContext";
import { useStableSpaceContext } from "../../SpaceContext";
import Panel from "../common/Panel";
import { useComponentStateContext } from "../contexts/ComponentStateContext";
import SpaceComponent, { Props } from "../SpaceComponent";

import { SpaceAccordionComponent, SpaceAccordionState } from "./types";

const { Panel: AntPanel } = Collapse;

const Root = styled(Panel)<{ editMode: boolean }>`
  background-color: transparent;

  ${props => !props.editMode && "border: none;"}

  .anticon-exclamation-circle {
    // without this, the error icon is hidden below the content
    z-index: 1;
  }
`;

const CollapseComponent = styled(Collapse)`
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }
`;

const CollapsePanel = styled(AntPanel)<{ dropTargetHeight?: string }>`
  ${props =>
    props.dropTargetHeight !== undefined &&
    `
    .dropTarget {
      height: ${props.dropTargetHeight};
    }
  `}
`;

export default function SpaceAccordion(props: Props) {
  const { spaceComponent, hasConfigError } = props;
  const accordionComponent = spaceComponent as SpaceAccordionComponent;
  const { sections, allows_multiple_open_sections } = accordionComponent.properties;
  const { editMode } = useStableSpaceContext();
  const { selectedSlug } = useCurrentTransformingComponentContext();
  const { componentNode, output, updateOutput } = useComponentStateContext();

  // The Panels don't expand in edit mode if we let the event propagate
  // So, first we select the component if it isn't already
  // Then we allow expand/collapse by not propagating the event
  const onClick = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      const currentComponentSelected = selectedSlug === accordionComponent.slug;

      if (!(event.target instanceof HTMLElement) || !currentComponentSelected) {
        return;
      }

      const { target } = event;
      if (
        target.classList.contains("ant-collapse-header") ||
        target.classList.contains("ant-collapse-content")
      ) {
        event.stopPropagation();
      }
    },
    [selectedSlug, accordionComponent]
  );

  const openSections = ((output as SpaceAccordionState) || {}).selectedSections || [];

  const setOpenSections = useCallback(
    (keys: string[]) => {
      updateOutput({ selectedSections: keys });
    },
    [updateOutput]
  );

  const onPanelChange = useCallback(
    (keys: string | string[]) => {
      if (!componentNode) {
        return;
      }

      if (!allows_multiple_open_sections) {
        setOpenSections(keys ? [keys as string] : []);
        return;
      }

      setOpenSections(keys as string[]);
    },
    [componentNode, allows_multiple_open_sections, setOpenSections]
  );

  return (
    <Root
      hasError={hasConfigError}
      onClick={event => editMode && onClick(event)}
      editMode={editMode}
    >
      <CollapseComponent
        accordion={!allows_multiple_open_sections}
        onChange={onPanelChange}
        activeKey={openSections}
      >
        {sections.map(section => {
          const sectionComponent = accordionComponent.componentTreeNodes.find(
            ctn => ctn.slug === section.component_slug
          );
          if (!sectionComponent) {
            reportException(new Error("Accordion component not found."), {
              extra: { spaceComponent, section }
            });
            return null;
          }

          // Accordion has a flexbox as the root subspace component
          const flexBox = sectionComponent?.componentTreeNodes[0];
          const hasContent = flexBox?.componentTreeNodes.length === 0;

          return (
            <CollapsePanel
              header={section.title}
              key={section.id}
              // Set a default drop target height, otherwise its too small to work with
              dropTargetHeight={hasContent ? "100px" : undefined}
            >
              <SpaceComponent
                {...props}
                spaceComponent={sectionComponent}
              ></SpaceComponent>
            </CollapsePanel>
          );
        })}
      </CollapseComponent>
    </Root>
  );
}
