import { Link } from "react-router-dom";
import styled from "styled-components";

import { colorTokens } from "../../cssConstants";
import ButtonNew from "../common/ButtonNew/ButtonNew";
import { B1 } from "../common/StyledComponents";

export const Title = styled.h1`
  font-family: var(--display-font-family);
  font-weight: ${props => props.theme.boldFontWeight};
  font-size: 62px;
  line-height: 62px;
  letter-spacing: -1px;

  color: ${colorTokens.white};
  margin-bottom: 24px;
`;

export const TitleTop = styled(Title)`
  margin-bottom: 0;
`;

export const TitleBottom = styled(Title)`
  color: ${props => props.theme.primaryAccent};
  margin-bottom: 16px;
`;

export const MediumTitle = styled(Title)`
  font-weight: 500;
  font-size: 36px;
  line-height: 36px;
  margin-bottom: 12px;
`;

export const Subtitle = styled(B1)`
  color: ${colorTokens.grey750};
`;

export const LoginLinkButton = styled(Link)`
  font-size: 18px;
  padding: 0;
`;

export const AuthCTAButton = styled(ButtonNew)`
  padding-top: 13px;
  padding-bottom: 13px;
`;

export const AuthLink = styled(Link)`
  color: ${props => props.theme.primaryAccent};
`;

export const AuthAnchor = styled.a`
  color: ${props => props.theme.primaryAccent};
`;
