import { Button, Select } from "antd";
import styled from "styled-components";

import { colorTokens } from "../../../../../../cssConstants";
import { BindingCascader } from "../../../../BindingCascader";
import { Tabs } from "../../../common/styledComponents";

export const ConditionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacersm};
`;

export const ConditionRow = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.spacermd};
`;

export const ConjunctionContainer = styled.div`
  margin-top: ${props => props.theme.spacersm};
  text-align: center;
  padding: 2px 4px;
  color: ${colorTokens.white};
`;

export const ConditionOperatorSelect = styled(Select)`
  width: 100%;
`;

export const ConditionBindingCascader = styled(BindingCascader)`
  width: 100%;
`;

export const ConditionErrorWrapper = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacerxs};
`;

export const ErrorField = styled.div`
  color: ${props => props.theme.errorColor};
`;

export const ActionButton = styled(Button)`
  padding-right: ${props => props.theme.spacermd};

  &.ant-btn-link {
    color: ${colorTokens.white};
  }

  &.ant-btn-link:hover {
    color: ${colorTokens.white};
  }

  &.ant-btn-link[disabled] {
    color: ${props => props.theme.buttonDisabledColor};
  }
`;

export const ConditionActions = styled.div`
  margin-top: ${props => props.theme.spacermd};
  display: flex;
  gap: ${props => props.theme.spacersm};
`;

export const ConditionTabs = styled(Tabs)`
  .ant-tabs-content {
    padding: 0;
  }

  // Show the hover state outline of the radio buttons
  overflow: visible;
`;

export const TabsHeader = styled.div`
  // Give the radio hover state outline enough room to be visible
  padding: 4px 0 14px 0;
`;
