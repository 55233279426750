import { Icon } from "antd";
import styled from "styled-components";

import { colorTokens } from "../../../cssConstants";

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.spacersmd};
  margin-bottom: ${props => props.theme.spacersmd};
`;

export const Title = styled.div`
  font-family: var(--light-header-font-family);
  font-weight: ${props => props.theme.boldFontWeight};
  font-size: ${props => props.theme.h3FontSize};
  color: ${colorTokens.white};
  line-height: 30px;
`;

export const CheckIcon = styled(Icon)`
  color: #8ee000;
  svg {
    width: 20px;
    height: 20px;
  }
`;
