import React from "react";

import { useMutation, useQuery } from "@apollo/react-hooks";
import { Spin, Switch, Tooltip } from "antd";
import classNames from "classnames";

import CloseIcon from "../../../../assets/icons/close-icon.svg";
import { colors } from "../../../../cssConstants";
import { RoleNode } from "../../../../types";
import { useEnvironmentContext } from "../../../common/contexts/EnvironmentContext";
import useAuthUser from "../../../common/hooks/useAuthUser";
import Message from "../../../common/Message";
import { useUpdateRolePermissions } from "../../../common/Permissions/queries";
import {
  createInitialState,
  FunctionPermission,
  functionPoliciesForSpaceReducer,
  FunctionPolicyActionTypes
} from "../../../common/Permissions/reducer";
import UnsavedChangesModal, {
  Action
} from "../../../common/Permissions/UnsavedChangesModal/UnsavedChangedModal";
import { B2, B3, H6, LinkButtonNew } from "../../../common/StyledComponents";
import {
  FUNCTIONS_FOR_SPACE_NODE_VERSION,
  UpdateRolePermissionsVariables
} from "../SpaceRolePermission/queries";
import {
  StyledButtonSecondary,
  StyledHeaderButton
} from "../SpaceRolePermission/styledComponents";
import {
  EnvironmentOptionType,
  EnvironmentSelectOption
} from "../SpaceRolePermission/useSpaceRolePolicies";

import {
  FETCH_SPACE_FEATURED_ROLES,
  FetchSpaceFeaturedRolesData,
  SET_SPACE_FEATURED_FOR_ROLE,
  SetSpaceFeaturedForRoleVariables,
  SpaceFeaturedRoleData
} from "./queries";
import {
  ButtonContainer,
  HeaderSpaceName,
  HelpIcon,
  Item,
  List,
  RoleHeader,
  RoleList,
  RoleListItem,
  ShareWithRoleName,
  ShareWithRowCell as ShareWithRole,
  SpinWrapper,
  StyledCloseIcon,
  StyledLinkButtonNew,
  StyledModal,
  StyledSpaceRolePermissions
} from "./styledComponents";

export function functionNodesToUpdateRolePermissions(
  roleId: string,
  roleName: string,
  functionPermissions: FunctionPermission[],
  scopes?: string[]
): UpdateRolePermissionsVariables {
  return {
    id: roleId,
    name: roleName,
    scopes: scopes || [],
    applyPermissions: functionPermissions.map(functionPermission => {
      return {
        functionId: functionPermission.functionId,
        permission: {
          allowsAll: functionPermission.allowsAll,
          parameterIds: functionPermission.parameters
            .filter(param => param.permitted)
            .map(param => param.id),
          attributeIds: functionPermission.attributes
            .filter(attr => attr.permitted)
            .map(attr => attr.id)
        }
      };
    })
  };
}

export default function SpaceVisibility({ slug }: { slug: string }) {
  const [visibilityModalOpen, setVisibilityModalOpen] = React.useState(false);
  const { data, loading } = useQuery<FetchSpaceFeaturedRolesData>(
    FETCH_SPACE_FEATURED_ROLES,
    {
      variables: { slug }
    }
  );

  const authUser = useAuthUser();
  if (!authUser.isAdmin) return null;

  const featuredRoles = data?.space.featuredRoles.edges.map(e => e.node) || [];

  return (
    <div>
      <H6>Shared with these roles</H6>
      {loading ? (
        <SpinWrapper>
          <Spin spinning />
        </SpinWrapper>
      ) : (
        <List>
          {featuredRoles.map(({ id, name }) => (
            <Item key={id}>{name}</Item>
          ))}
          {featuredRoles.length === 0 && <span>No roles</span>}
        </List>
      )}
      <LinkButtonNew onClick={() => setVisibilityModalOpen(true)}>
        Manage access and permissions
      </LinkButtonNew>
      {visibilityModalOpen && data && (
        <SpaceVisibilityModal
          data={data}
          slug={slug}
          onCancel={() => setVisibilityModalOpen(false)}
        />
      )}
    </div>
  );
}

function RolesSharedMessage({ shared, total }: { shared: number; total: number }) {
  if (shared === total) {
    return <B3 color={colors.surfaceSecondary}>Sharing with all roles</B3>;
  }

  if (shared === 0) {
    return <B3 color={colors.surfaceSecondary}>Not shared</B3>;
  }

  return (
    <B3 color={colors.surfaceSecondary}>
      Sharing with {shared} of {total} roles
    </B3>
  );
}

function SpaceVisibilityModal({
  data,
  slug,
  onCancel
}: {
  data: FetchSpaceFeaturedRolesData;
  slug: string;
  onCancel: () => void;
}) {
  const { space, allRoles } = data;
  const [featuredRoleIds, setFeaturedRoleIds] = React.useState(
    space.featuredRoles.edges.map(({ node }) => node.id)
  );

  const [lastRoleModified, setLastRoleModified] = React.useState<{
    id?: string;
    shared: boolean;
  }>({
    id: undefined,
    shared: false
  });

  const { getCurrentEnvironment } = useEnvironmentContext();
  const [selectedEnvironment, setSelectedEnvironment] =
    React.useState<EnvironmentSelectOption>({
      type: EnvironmentOptionType.Environment,
      payload: getCurrentEnvironment()
    });

  const [expandedRole, setExpandedRole] = React.useState<RoleNode | undefined>();

  const handleExpandedRole = (newRole?: RoleNode) => {
    if (newRole?.id === expandedRole?.id) {
      return;
    }

    setExpandedRole(newRole);
    setSelectedEnvironment({
      type: EnvironmentOptionType.Environment,
      payload: getCurrentEnvironment()
    });
  };

  const [setSpaceFeaturedForRole, { loading, error }] = useMutation<
    SpaceFeaturedRoleData,
    SetSpaceFeaturedForRoleVariables
  >(SET_SPACE_FEATURED_FOR_ROLE, {
    refetchQueries: ["FetchFeaturedSpaces"],
    onError: () => {
      // onError, undo the last modification in the UI
      if (!lastRoleModified.id) {
        return;
      }

      if (lastRoleModified.shared) {
        setFeaturedRoleIds(
          featuredRoleIds.filter(
            featuredRoleId => featuredRoleId !== lastRoleModified.id
          )
        );
      } else {
        setFeaturedRoleIds(featuredRoleIds.concat(lastRoleModified.id));
      }

      setLastRoleModified({ shared: false });
    }
  });

  React.useEffect(() => {
    if (error) Message.error("Something went wrong. Please try again.");
  }, [error]);

  const roles = allRoles.edges.map(({ node }) => node);
  const [searchText, setSearchText] = React.useState<string | undefined>(undefined);
  const [page, setPage] = React.useState(1);
  const pageSize = 15;

  const [state, dispatch] = React.useReducer(
    functionPoliciesForSpaceReducer,
    createInitialState()
  );

  const [updateRolePermissions, updateRolePermissionStats] = useUpdateRolePermissions({
    onCompleted: () => {
      Message.success("Permissions updated");
      dispatch({ type: FunctionPolicyActionTypes.COMMIT });
    },
    refetchQueries: () => {
      return [
        {
          query: FUNCTIONS_FOR_SPACE_NODE_VERSION,
          variables: {
            spaceId: space.id,
            includeUnpublished:
              selectedEnvironment.type === EnvironmentOptionType.LastSavedVersion,
            roleId: expandedRole?.id,
            first: pageSize,
            offset: pageSize * (page - 1),
            searchText: searchText
          }
        }
      ];
    }
  });

  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = React.useState<{
    show: boolean;
    nextRole?: RoleNode;
    quit?: boolean;
  }>({ show: false });

  return (
    <StyledModal
      data-test="shareModal"
      visible
      width={"95vw"}
      confirmLoading={loading}
      closeIcon={<StyledCloseIcon component={CloseIcon} />}
      backgroundColor={colors.newBackgroundSecondaryColor}
      hasFooter={false}
      header={
        <>
          <B2>Spaces</B2>
          <HeaderSpaceName>{space.name}</HeaderSpaceName>
          <RolesSharedMessage
            shared={featuredRoleIds.length}
            total={allRoles.edges.length}
          />
        </>
      }
      onCancel={() => {
        if (state.modified) {
          setShowUnsavedChangesModal({ show: true, quit: true });
        } else {
          onCancel();
        }
      }}
    >
      <RoleList>
        {roles.map(role => {
          const expanded = expandedRole?.id === role.id;

          return (
            <RoleListItem
              data-test="sharedRoleRow"
              key={role.id}
              className={classNames({ expanded })}
            >
              <RoleHeader
                className={classNames({ expanded })}
                onClick={() => {
                  if (role.id === expandedRole?.id) {
                    return;
                  }

                  if (state.modified) {
                    setShowUnsavedChangesModal({ show: true, nextRole: role });
                  } else {
                    handleExpandedRole(role);
                  }
                }}
              >
                <ShareWithRoleName>
                  <B3>{role.name}</B3>
                </ShareWithRoleName>
                <ShareWithRole>
                  <B3 className="switchTitle">Share with role</B3>
                  <Tooltip title="If shared, this app will appear on this role's home screen">
                    <HelpIcon type="question-circle" />
                  </Tooltip>
                  <Switch
                    data-test="shareRoleSwitch"
                    className="switchToggle"
                    checked={featuredRoleIds.includes(role.id)}
                    onChange={(checked, event) => {
                      event.stopPropagation();
                      // Keep track of last modification in case of error so we can undo it
                      setLastRoleModified({ id: role.id, shared: checked });

                      // Optimistically update the UI
                      if (checked) {
                        setFeaturedRoleIds(featuredRoleIds.concat(role.id));
                      } else {
                        setFeaturedRoleIds(
                          featuredRoleIds.filter(
                            featuredRoleId => featuredRoleId !== role.id
                          )
                        );
                      }

                      setSpaceFeaturedForRole({
                        variables: {
                          spaceId: space.id,
                          roleId: role.id,
                          isFeatured: checked
                        },
                        update: (cache, { data: { setSpaceFeaturedForRole } }) => {
                          const { allRoles } =
                            cache.readQuery<FetchSpaceFeaturedRolesData>({
                              query: FETCH_SPACE_FEATURED_ROLES,
                              variables: { slug }
                            })!;

                          cache.writeQuery({
                            query: FETCH_SPACE_FEATURED_ROLES,
                            variables: { slug },
                            data: {
                              allRoles: allRoles,
                              space: setSpaceFeaturedForRole.space
                            }
                          });
                        }
                      });
                    }}
                  />
                </ShareWithRole>
                {!expanded && (
                  <StyledLinkButtonNew
                    className="permissionsLink"
                    onClick={() => {
                      if (state.modified) {
                        setShowUnsavedChangesModal({ show: true, nextRole: role });
                      } else {
                        handleExpandedRole(role);
                      }
                    }}
                  >
                    Function Permissions
                  </StyledLinkButtonNew>
                )}
                {expanded && (
                  <ButtonContainer>
                    <StyledButtonSecondary
                      type="secondary"
                      size="lg"
                      onClick={event => {
                        event.stopPropagation();
                        handleExpandedRole(undefined);
                      }}
                    >
                      {state.modified ? "Cancel" : "Close"}
                    </StyledButtonSecondary>
                    <Tooltip title={!state.modified ? "No changes to save" : undefined}>
                      {/* Add "fake" disabling styling because Tooltips don't play nicely with disabled buttons */}
                      <StyledHeaderButton
                        type="primary"
                        className={classNames({
                          fakeDisabled: !state.modified
                        })}
                        size="lg"
                        loading={updateRolePermissionStats.loading}
                        onClick={() => {
                          if (state.modified) {
                            const data = functionNodesToUpdateRolePermissions(
                              role.id,
                              role.name,
                              state.current.functions,
                              role.scopes
                            );
                            updateRolePermissions({ variables: data });
                          }
                        }}
                      >
                        Save
                      </StyledHeaderButton>
                    </Tooltip>
                  </ButtonContainer>
                )}
              </RoleHeader>
              {expanded && (
                <StyledSpaceRolePermissions
                  state={state.current}
                  dispatch={dispatch}
                  space={space}
                  role={role}
                  selectedEnvironment={selectedEnvironment}
                  setSelectedEnvironment={setSelectedEnvironment}
                  page={page}
                  pageSize={pageSize}
                  searchText={searchText}
                  setPage={page => setPage(page)}
                  setSearchText={query => setSearchText(query)}
                  spaceId={space.id}
                />
              )}
            </RoleListItem>
          );
        })}
      </RoleList>
      <UnsavedChangesModal
        visible={showUnsavedChangesModal.show}
        onClick={async (action: Action) => {
          switch (action) {
            case Action.Cancel: {
              setShowUnsavedChangesModal({ show: false });
              break;
            }
            case Action.Discard: {
              dispatch({ type: FunctionPolicyActionTypes.RESET });
              setShowUnsavedChangesModal({ show: false });

              if (showUnsavedChangesModal.nextRole) {
                setExpandedRole(showUnsavedChangesModal.nextRole);
              } else if (!showUnsavedChangesModal.quit) {
                setExpandedRole(undefined);
              } else if (showUnsavedChangesModal.quit) {
                onCancel();
              }
              break;
            }
            case Action.Save: {
              if (!expandedRole) {
                return;
              }

              const data = functionNodesToUpdateRolePermissions(
                expandedRole!.id,
                expandedRole!.name,
                state.current.functions,
                expandedRole!.scopes
              );

              await updateRolePermissions({ variables: data });
              setShowUnsavedChangesModal({ show: false });

              if (showUnsavedChangesModal.nextRole) {
                setExpandedRole(showUnsavedChangesModal.nextRole);
              } else if (showUnsavedChangesModal.quit) {
                onCancel();
              }
              break;
            }
          }
        }}
      />
    </StyledModal>
  );
}
