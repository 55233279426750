import React, { useState } from "react";

import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useMutation } from "react-apollo";

import { UPDATE_PAYMENT_METHOD_MUTATION } from "../../../graphql/queries";
import { PaymentMethod } from "../../../types";
import ButtonNew from "../../common/ButtonNew/ButtonNew";
import { ModalNew } from "../../common/Modal";

interface StripeError {
  message?: string | undefined | null;
}

export default function UpdatePaymentModal({
  visible,
  onCancel
}: {
  visible: boolean;
  onCancel: any;
}) {
  const [updating, setUpdating] = useState<boolean>(false);
  const [updatePaymentMethod] = useMutation(UPDATE_PAYMENT_METHOD_MUTATION, {
    onCompleted: () => {
      window.location.reload();
    }
  });
  const [cardUpdateError, setCardUpdateError] = useState<StripeError | null>(null);
  const stripe = useStripe();
  const elements = useElements();

  const handleOnUpdatePayment = async () => {
    setUpdating(true);
    if (stripe && elements) {
      const card = elements.getElement(CardElement);

      if (card) {
        const result: PaymentMethod = await stripe.createPaymentMethod({
          type: "card",
          card
        });
        if (result.error && result.error.message) {
          setCardUpdateError(result.error);
        } else {
          setCardUpdateError(null);
          updatePaymentMethod({
            variables: { paymentMethodId: result.paymentMethod?.id }
          });
        }
      }
    }
  };
  return (
    <ModalNew title="Update Payment Method" visible={visible} onCancel={onCancel}>
      <CardElement />
      <br />
      <br />
      {cardUpdateError?.message}
      <ButtonNew
        size="lg"
        block={true}
        type="brand"
        onClick={handleOnUpdatePayment}
        loading={updating}
        disabled={updating}
      >
        Update Payment Information
      </ButtonNew>
    </ModalNew>
  );
}
