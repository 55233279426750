import React, { useCallback, useEffect, useMemo, useReducer, useState } from "react";

import { Icon, Radio, Select } from "antd";
import { isEqual } from "lodash";

import { BindingShape, InputParameter } from "../../../../../../types";
import { SpaceContextParams } from "../../../../../spaces/SpaceRoot/SpaceContext/SpaceContext";
import { getOption, Option } from "../../../../BindingCascader";
import ButtonNew from "../../../../ButtonNew/ButtonNew";
import { DebouncedEditor } from "../../../../Editor/Editor";
import FormBuilderModal from "../../../../FormBuilderModal/FormBuilderModal";
import usePrevious from "../../../../hooks/usePrevious";
import { BindingSchema, getUserEnvironmentBindingSchema } from "../../../../schema";
import SingleLineEditor from "../../../../SingleLineEditor";
import { LinkButtonNew } from "../../../../StyledComponents";
import { Tabs } from "../../../common/styledComponents";
import { Conditional, ConditionParts } from "../../../useFunctionEditor/queries";
import { SetPipelineStepConditionArguments } from "../../../useFunctionEditor/useFunctionEditor";
import { ActionLinks } from "../common/styledComponents";
import { inputParametersToCreateFunctionNode, SUPPORTED_PARAM_TYPES } from "../utils";

import { ActionType, ConditionTypeTab, createInitialState, reducer } from "./reducer";
import {
  ActionButton,
  ConditionActions,
  ConditionBindingCascader,
  ConditionErrorWrapper,
  ConditionOperatorSelect,
  ConditionRow,
  ConditionsContainer,
  ConditionTabs,
  ConjunctionContainer,
  ErrorField,
  TabsHeader
} from "./styledComponents";
import { Conjunction, Operator, OperatorHumanizeDisplayNames } from "./types";
import { ConditionError, getConditionErrors, humanizedConjunctions } from "./utils";

const { Option: SelectOption } = Select;

const JS_EDITOR_HEIGHT = "140px";

const EMPTY_CONDITION: ConditionParts = {
  lhs: "",
  operator: "",
  rhs: ""
};

const createBindingOptions = (contextParams: SpaceContextParams): Option[] => {
  return Object.entries(contextParams).map(([k, v]) => ({
    label: v.title,
    value: k,
    bindingShape: BindingShape.OBJECT,
    children: v.schema.map(getOption)
  }));
};

interface Props {
  title: string;
  condition?: Conditional;
  showErrors: boolean;
  inputParameters: InputParameter[];
  previousStepsSchema: Record<string, BindingSchema> | undefined;
  onConditionChange: (payload: SetPipelineStepConditionArguments) => void;
  onSetInputParameters: (parameters: InputParameter[]) => void;
}

const OPERATORS = [
  Operator.EQUALS,
  Operator.NOT_EQUALS,
  Operator.LESS_THAN,
  Operator.LESS_THAN_OR_EQUAL,
  Operator.GREATER_THAN,
  Operator.GREATER_THAN_OR_EQUAL
];

function ConditionErrorRow({
  showErrors,
  error,
  property
}: {
  showErrors: boolean;
  error?: ConditionError;
  property: keyof ConditionError;
}) {
  if (!showErrors || !error) {
    return null;
  }

  const errorString = error[property];

  if (!errorString && error.hasError) {
    return <div>&nbsp;</div>;
  }

  return <ErrorField>{errorString}</ErrorField>;
}

export default function ConditionCreator({
  title,
  condition,
  previousStepsSchema,
  showErrors,
  inputParameters,
  onConditionChange,
  onSetInputParameters
}: Props) {
  const [state, dispatch] = useReducer(reducer, createInitialState());
  const previousState = usePrevious(state);
  const [showFormBuilder, setShowFormBuilder] = useState(false);

  const schema = useMemo(() => {
    return {
      ...previousStepsSchema,
      ...getUserEnvironmentBindingSchema()
    };
  }, [previousStepsSchema]);

  const bindingOptions = useMemo(() => {
    return createBindingOptions(schema);
  }, [schema]);

  const functionAdapter = useMemo(() => {
    return inputParametersToCreateFunctionNode(inputParameters);
  }, [inputParameters]);

  const { errors, hasErrors } = useMemo(() => {
    const indexToError = new Map<number, ConditionError>();

    let hasErrors = false;
    if (!state.expression) {
      state.conditions.forEach((condition, index) => {
        const error = getConditionErrors(condition);
        indexToError.set(index, error);

        hasErrors = hasErrors || error.hasError;
      });
    }

    return {
      errors: indexToError,
      hasErrors
    };
  }, [state.expression, state.conditions]);

  useEffect(() => {
    // Only load the condition if the state has not loaded one yet
    if (condition && !state.loaded) {
      dispatch({
        type: ActionType.LOAD_CONDITION,
        payload: {
          expression: condition.condition,
          conditions: (condition.parts?.length
            ? condition.parts
            : [EMPTY_CONDITION]
          ).map(c => ({
            property: c.lhs,
            operator: c.operator === "" ? undefined : (c.operator as Operator),
            // default rhs value to string mode
            value: c.rhs === "" ? "``" : c.rhs
          })),
          conjunctions: (condition.conjunctions || []) as Conjunction[]
        }
      });

      if (condition.condition && condition.condition !== "") {
        dispatch({
          type: ActionType.SET_ACTIVE_TAB,
          payload: {
            value: ConditionTypeTab.JAVASCRIPT
          }
        });
      } else {
        dispatch({
          type: ActionType.SET_ACTIVE_TAB,
          payload: {
            value: ConditionTypeTab.BUILDER
          }
        });
      }
    }
  }, [condition, hasErrors, state.loaded, dispatch]);

  const setActiveTab = useCallback(
    (activeTab: ConditionTypeTab) => {
      dispatch({
        type: ActionType.SET_ACTIVE_TAB,
        payload: {
          value: activeTab
        }
      });
    },
    [dispatch]
  );

  const addCondition = useCallback(
    (conjunction: Conjunction) => {
      dispatch({
        type: ActionType.ADD_CONDITION,
        payload: { conjunction }
      });
    },
    [dispatch]
  );

  const removeCondition = useCallback(
    (conditionIndex: number) => {
      dispatch({
        type: ActionType.REMOVE_CONDITION,
        payload: { conditionIndex }
      });
    },
    [dispatch]
  );

  const setConditionProperty = useCallback(
    (conditionIndex: number, property: string) => {
      dispatch({
        type: ActionType.SET_CONDITION_PROPERTY,
        payload: { conditionIndex, property }
      });
    },
    [dispatch]
  );

  const setConditionOperator = useCallback(
    (conditionIndex: number, operator: Operator) => {
      dispatch({
        type: ActionType.SET_CONDITION_OPERATOR,
        payload: { conditionIndex, operator }
      });
    },
    [dispatch]
  );

  const setConditionValue = useCallback(
    (conditionIndex: number, value: string) => {
      dispatch({
        type: ActionType.SET_CONDITION_VALUE,
        payload: { conditionIndex, value }
      });
    },
    [dispatch]
  );

  const setConditionExpression = useCallback(
    (expression: string) => {
      dispatch({
        type: ActionType.SET_CONDITION_EXPRESSION,
        payload: { expression }
      });
    },
    [dispatch]
  );

  // Whenever the state changes and the conditions are all valid, update the condition
  useEffect(() => {
    if (isEqual(state, previousState)) {
      return;
    }

    switch (state.activeTab) {
      case ConditionTypeTab.JAVASCRIPT: {
        onConditionChange({
          condition: state.expression,
          parts: undefined,
          conjunctions: undefined
        });

        break;
      }
      case ConditionTypeTab.BUILDER: {
        onConditionChange({
          condition: undefined,
          parts: state.conditions.map(c => ({
            lhs: c.property,
            operator: c.operator as string,
            rhs: c.value
          })),
          conjunctions: state.conjunctions
        });

        break;
      }
    }
  }, [previousState, state.activeTab, state, hasErrors, onConditionChange]);

  return (
    <>
      <ConditionTabs
        activeKey={state.activeTab}
        animated={false}
        renderTabBar={() => (
          <TabsHeader>
            <Radio.Group
              value={state.activeTab}
              buttonStyle="solid"
              size="small"
              onChange={e => setActiveTab(e.target.value)}
            >
              <Radio value={ConditionTypeTab.BUILDER}>Builder</Radio>
              <Radio value={ConditionTypeTab.JAVASCRIPT}>JavaScript</Radio>
            </Radio.Group>
          </TabsHeader>
        )}
      >
        <Tabs.TabPane tab="Builder" key={ConditionTypeTab.BUILDER}>
          <ConditionsContainer>
            {state.conditions.map((condition, index) => (
              <div key={index}>
                <ConditionRow>
                  <ConditionErrorWrapper>
                    <ConditionBindingCascader
                      value={condition.property}
                      options={bindingOptions}
                      placeholder="property"
                      selectable={[
                        BindingShape.SCALAR,
                        BindingShape.OBJECT,
                        BindingShape.OBJECT_ARRAY
                      ]}
                      onChange={path => {
                        setConditionProperty(index, path);
                      }}
                    />
                    <ConditionErrorRow
                      showErrors={showErrors}
                      error={errors.get(index)}
                      property="property"
                    />
                  </ConditionErrorWrapper>
                  <ConditionErrorWrapper>
                    <ConditionOperatorSelect
                      value={condition.operator}
                      onChange={value => setConditionOperator(index, value as Operator)}
                      placeholder="operator"
                      getPopupContainer={trigger => trigger.parentNode as HTMLElement}
                    >
                      {OPERATORS.map(op => (
                        <SelectOption value={op} key={op}>
                          {OperatorHumanizeDisplayNames[op]}
                        </SelectOption>
                      ))}
                    </ConditionOperatorSelect>
                    <ConditionErrorRow
                      showErrors={showErrors}
                      error={errors.get(index)}
                      property="operator"
                    />
                  </ConditionErrorWrapper>
                  <ConditionErrorWrapper>
                    <SingleLineEditor
                      value={condition.value}
                      placeholder="value"
                      onChange={value => setConditionValue(index, value)}
                      togglable
                    />
                    <ConditionErrorRow
                      showErrors={showErrors}
                      error={errors.get(index)}
                      property="value"
                    />
                  </ConditionErrorWrapper>
                  <div>
                    <ActionButton
                      type="link"
                      disabled={index === 0}
                      onClick={() => removeCondition(index)}
                      icon="delete"
                    />
                    {showErrors && errors.get(index)?.hasError && <div>&nbsp;</div>}
                  </div>
                </ConditionRow>
                {state.conjunctions.length > index && (
                  <ConjunctionContainer>
                    {humanizedConjunctions[state.conjunctions[index]]}
                  </ConjunctionContainer>
                )}
              </div>
            ))}
          </ConditionsContainer>
          <ConditionActions>
            <ButtonNew onClick={() => addCondition(Conjunction.AND)}>
              <Icon type="plus" />
              And
            </ButtonNew>
            <ButtonNew onClick={() => addCondition(Conjunction.OR)}>
              <Icon type="plus" />
              Or
            </ButtonNew>
          </ConditionActions>
          <ActionLinks>
            <LinkButtonNew
              disabled={!inputParameters.length}
              onClick={() => setShowFormBuilder(true)}
            >
              Configure inputs
            </LinkButtonNew>
          </ActionLinks>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Javascript" key={ConditionTypeTab.JAVASCRIPT}>
          <DebouncedEditor
            className="editor-transformer"
            mode="javascript"
            height={JS_EDITOR_HEIGHT}
            value={state.expression || ""}
            onChange={value => setConditionExpression(value)}
          />
          <ActionLinks>
            <LinkButtonNew
              disabled={!inputParameters.length}
              onClick={() => setShowFormBuilder(true)}
            >
              Configure inputs
            </LinkButtonNew>
          </ActionLinks>
        </Tabs.TabPane>
      </ConditionTabs>
      {showFormBuilder && functionAdapter && inputParameters && (
        <FormBuilderModal
          visible={showFormBuilder}
          func={functionAdapter}
          title={title || ""}
          schema={schema}
          allowedParameterTypes={SUPPORTED_PARAM_TYPES}
          initialInputParameters={inputParameters}
          onCancel={() => setShowFormBuilder(false)}
          allowedBindingShapes={[BindingShape.SCALAR, BindingShape.OBJECT]}
          onSave={e => {
            setShowFormBuilder(false);
            onSetInputParameters(e);
          }}
        />
      )}
    </>
  );
}
