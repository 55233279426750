import React from "react";

import { colors } from "../../../../cssConstants";
import { ensureHexValue } from "../../../common/utils";
import AnalyticCard, { DEFAULT_ERROR_MESSAGE } from "../AnalyticCard/AnalyticCard";
import {
  StyledProgress,
  Column,
  Main,
  StateLabel,
  StateProgress,
  TaskCount,
  CenterMessage
} from "../common/styledComponents";

import { UserStatistic, useOpenStateTaskUserStatistics } from "./queries";

export function OpenStateTaskUserStatisticsView({
  statistics,
  className
}: {
  statistics: UserStatistic[];
  className?: string;
}) {
  const totalTaskCount = statistics.reduce((prev, current) => {
    return prev + current.taskCount;
  }, 0);

  return (
    <Main className={className}>
      <Column>
        {statistics.map(stat => (
          <StateLabel key={`${stat.user.id}-label`}>{stat.user.name}</StateLabel>
        ))}
      </Column>
      <Column>
        {statistics.map(stat => (
          <TaskCount key={`${stat.user.id}-count`}>{stat.taskCount}</TaskCount>
        ))}
      </Column>
      <StateProgress>
        {statistics.map(stat => (
          <StyledProgress
            key={`${stat.user.id}-progress`}
            strokeWidth={10}
            strokeColor={ensureHexValue(colors.primaryColor)}
            percent={(stat.taskCount / totalTaskCount) * 100}
            showInfo={false}
          />
        ))}
      </StateProgress>
    </Main>
  );
}

export default function OpenStateTaskUserStatistics({
  queueId,
  actionText,
  className,
  onActionClick = () => null
}: {
  queueId?: string;
  actionText?: string;
  className?: string;
  onActionClick?: () => void;
}) {
  const { data, loading, error } = useOpenStateTaskUserStatistics({
    variables: { queueId: queueId! },
    skip: !queueId,
    fetchPolicy: "cache-and-network"
  });

  return (
    <AnalyticCard
      title="Open tasks assigned to user"
      loading={loading}
      errorMessage={error ? DEFAULT_ERROR_MESSAGE : undefined}
      className={className}
      headerActionText={actionText}
      onHeaderActionClick={() => onActionClick()}
    >
      {data?.openStateTasksAssignedUser.length === 0 && (
        <CenterMessage>No assigned tasks</CenterMessage>
      )}
      {data?.openStateTasksAssignedUser.length !== 0 && (
        <OpenStateTaskUserStatisticsView
          statistics={data?.openStateTasksAssignedUser || []}
        />
      )}
    </AnalyticCard>
  );
}
