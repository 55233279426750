import * as React from "react";

import { Checkbox, Select } from "antd";
import { DatePickerProps } from "antd/lib/date-picker/interface";
import moment from "moment";
import { useFormContext, Controller } from "react-hook-form";

import { ErrorField, Hr } from "../../../common/StyledComponents";
import { ConfigAction, ConfigState, ReducerActions } from "../reducer";
import {
  setTimeToMidnight,
  DATE_DISPLAY_FORMAT,
  DATE_TIME_DISPLAY_FORMAT,
  getDaysOfMonth,
  getDaysOfWeek,
  getFrequencyOptions,
  getUtcTime,
  PERIOD_OPTIONS,
  TIME_DISPLAY_FORMAT,
  INTERVAL_PERIODS_WITH_START_END_TIMES,
  convertMomentToString
} from "../reducer/utils";
import * as styled from "../styledComponents";
import { IntervalPeriod } from "../types";

interface DateTimePickerProps {
  value: moment.Moment | undefined;
  disableBefore: moment.Moment;
  onChange: (value: moment.Moment | null) => void;
  showTime?: boolean;
}
const DateTimePicker = React.forwardRef<
  React.Component<DatePickerProps, any, any>,
  DateTimePickerProps
>(({ value, disableBefore, onChange, showTime }: DateTimePickerProps, ref) => {
  return showTime ? (
    <styled.DatePicker
      ref={ref}
      data-test="datetime-picker"
      value={value}
      format={DATE_TIME_DISPLAY_FORMAT}
      disabledDate={(current: moment.Moment | undefined) => {
        // disable dates before the disableBefore date
        return !!current && current < disableBefore;
      }}
      showTime={{
        defaultValue: moment("00:00", TIME_DISPLAY_FORMAT),
        format: TIME_DISPLAY_FORMAT,
        minuteStep: 5,
        use12Hours: true
      }}
      onChange={onChange}
    />
  ) : (
    <styled.DatePicker
      ref={ref}
      data-test="dateonly-picker"
      value={value}
      format={DATE_DISPLAY_FORMAT}
      disabledDate={(current: moment.Moment | undefined) => {
        return !!current && current < disableBefore;
      }}
      onChange={onChange}
    />
  );
});

interface Props {
  state: ConfigState;
  dispatch: React.Dispatch<ConfigAction>;
}
export const Schedule = ({ state, dispatch }: Props) => {
  const { control, formState } = useFormContext();
  const [showStopAt, setShowStopAt] = React.useState(!!state.endAt);

  const allowStartEndTime = React.useMemo(() => {
    return INTERVAL_PERIODS_WITH_START_END_TIMES.includes(state.intervalPeriod);
  }, [state.intervalPeriod]);

  const handlePeriodChange = React.useCallback(
    (value, field) => {
      dispatch({
        type: ReducerActions.UPDATE_INTERVAL_PERIOD,
        payload: {
          intervalPeriod: value
        }
      });
      field.onChange(value);
    },
    [dispatch]
  );
  const handleFrequencyChange = React.useCallback(
    (value, field) => {
      dispatch({
        type: ReducerActions.UPDATE_SETTINGS,
        payload: {
          frequency: value
        }
      });
      field.onChange(value);
    },
    [dispatch]
  );

  const handleDaysOfWeekChange = React.useCallback(
    (value, field) => {
      dispatch({
        type: ReducerActions.UPDATE_SETTINGS,
        payload: {
          daysOfWeek: value
        }
      });
      field.onChange(value);
    },
    [dispatch]
  );

  const handleDaysOfMonthChange = React.useCallback(
    (value, field) => {
      dispatch({
        type: ReducerActions.UPDATE_SETTINGS,
        payload: {
          daysOfMonth: value
        }
      });
      field.onChange(value);
    },
    [dispatch]
  );

  const handleTriggerTimeChange = React.useCallback(
    (value, field) => {
      const stringValue = convertMomentToString(value);
      dispatch({
        type: ReducerActions.UPDATE_SETTINGS,
        payload: {
          triggerTime: stringValue
        }
      });
      field.onChange(stringValue);
    },
    [dispatch]
  );

  const handleDateTimeChange = React.useCallback(
    (fieldName, value, field) => {
      let val = value || undefined; // value may be null when clearing the field
      // if interval period does not allow specifying start/end times,
      // set time to midnight
      if (val && !allowStartEndTime) {
        val = setTimeToMidnight(val);
      }
      const stringValue = convertMomentToString(val);
      dispatch({
        type: ReducerActions.UPDATE_SETTINGS,
        payload: {
          [fieldName]: stringValue
        }
      });
      field.onChange(stringValue);
    },
    [dispatch, allowStartEndTime]
  );

  const toggleShowStopAt = React.useCallback(
    (showStopAt: boolean) => {
      if (!showStopAt) {
        // clear endAt
        dispatch({
          type: ReducerActions.UPDATE_SETTINGS,
          payload: {
            endAt: undefined
          }
        });
      }
      setShowStopAt(showStopAt);
    },
    [dispatch]
  );

  const utcTime = React.useMemo(() => {
    if (!state.triggerTime) return undefined;
    return getUtcTime(moment(state.triggerTime)).format("h:mm a");
  }, [state.triggerTime]);

  return (
    <div>
      <styled.Title>Schedule</styled.Title>

      <styled.Section>
        <styled.SectionTitle>Frequency</styled.SectionTitle>
        <styled.SectionDescription>
          How often this automation should run
        </styled.SectionDescription>

        <styled.Fieldset>
          <styled.Label>Time unit</styled.Label>
          <Controller
            rules={{ required: "This is a required field." }}
            render={({ field }) => (
              <styled.Select
                {...field}
                data-test="intervalPeriod-select"
                placeholder="Select time unit"
                value={state.intervalPeriod}
                onChange={e => {
                  handlePeriodChange(e, field);
                }}
              >
                {PERIOD_OPTIONS.map(option => (
                  <Select.Option
                    data-test="frequencyOption"
                    key={option.value}
                    title={option.label}
                    value={option.value}
                  >
                    {option.label}
                  </Select.Option>
                ))}
              </styled.Select>
            )}
            control={control}
            name="intervalPeriod"
            defaultValue={state.intervalPeriod}
          />
          {formState.errors?.intervalPeriod && (
            <ErrorField>{formState.errors.intervalPeriod.message}</ErrorField>
          )}
        </styled.Fieldset>
        {[IntervalPeriod.Minutes, IntervalPeriod.Hours, IntervalPeriod.Days].includes(
          state.intervalPeriod
        ) && (
          <styled.Fieldset>
            <styled.Label>Trigger every</styled.Label>
            <Controller
              rules={{ required: "This is a required field." }}
              render={({ field }) => (
                <styled.Select
                  {...field}
                  data-test="frequency-select"
                  placeholder="Select frequency"
                  value={state.frequency}
                  onChange={e => {
                    handleFrequencyChange(e, field);
                  }}
                >
                  {getFrequencyOptions(state.intervalPeriod).map(option => (
                    <Select.Option
                      data-test="frequency"
                      key={option.value}
                      title={option.label}
                      value={option.value}
                    >
                      {option.label}
                    </Select.Option>
                  ))}
                </styled.Select>
              )}
              control={control}
              name="frequency"
              defaultValue={state.frequency}
            />
            {formState.errors?.frequency && (
              <ErrorField>{formState.errors.frequency.message}</ErrorField>
            )}
          </styled.Fieldset>
        )}

        {state.intervalPeriod === IntervalPeriod.Weeks && (
          <styled.Fieldset>
            <styled.Label>Days of the week</styled.Label>
            <Controller
              rules={{ required: "This is a required field." }}
              render={({ field }) => (
                <styled.Select
                  {...field}
                  data-test="daysOfWeek-select"
                  mode="multiple"
                  placeholder="Select days of week"
                  className={formState.errors?.daysOfWeek ? "has-error" : ""}
                  value={state.daysOfWeek}
                  onChange={e => {
                    handleDaysOfWeekChange(e, field);
                  }}
                >
                  {getDaysOfWeek().map(option => (
                    <Select.Option
                      data-test="daysOfWeek"
                      key={option.value}
                      title={option.label}
                      value={option.value}
                    >
                      {option.label}
                    </Select.Option>
                  ))}
                </styled.Select>
              )}
              control={control}
              name="daysOfWeek"
              defaultValue={state.daysOfWeek}
            />
            {formState.errors?.daysOfWeek && (
              <ErrorField>{formState.errors.daysOfWeek.message}</ErrorField>
            )}
          </styled.Fieldset>
        )}

        {state.intervalPeriod === IntervalPeriod.Months && (
          <styled.Fieldset>
            <styled.Label>Days of the month</styled.Label>
            <Controller
              rules={{ required: "This is a required field." }}
              render={({ field }) => (
                <styled.Select
                  {...field}
                  data-test="daysOfMonth-select"
                  mode="multiple"
                  placeholder="Select days of month"
                  value={state.daysOfMonth}
                  onChange={e => {
                    handleDaysOfMonthChange(e, field);
                  }}
                >
                  {getDaysOfMonth().map(option => (
                    <Select.Option
                      data-test="daysOfMonth"
                      key={option.value}
                      title={`${option.label}`}
                      value={option.value}
                    >
                      {option.label}
                    </Select.Option>
                  ))}
                </styled.Select>
              )}
              control={control}
              name="daysOfMonth"
              defaultValue={state.daysOfMonth}
            />
            {formState.errors?.daysOfMonth && (
              <ErrorField>{formState.errors.daysOfMonth.message}</ErrorField>
            )}
          </styled.Fieldset>
        )}

        {[IntervalPeriod.Days, IntervalPeriod.Weeks, IntervalPeriod.Months].includes(
          state.intervalPeriod
        ) && (
          <styled.Fieldset>
            <styled.Label>Trigger at</styled.Label>
            <Controller
              rules={{ required: "This is a required field." }}
              render={({ field }) => (
                <styled.TimePicker
                  {...field}
                  data-test="triggerAt-select"
                  placeholder="Select a time"
                  value={moment(state.triggerTime)}
                  onChange={e => {
                    handleTriggerTimeChange(e, field);
                  }}
                  format={TIME_DISPLAY_FORMAT}
                  use12Hours
                  minuteStep={5}
                />
              )}
              control={control}
              name="triggerTime"
              defaultValue={state.triggerTime}
            />
            {formState.errors?.triggerTime && (
              <ErrorField>{formState.errors.triggerTime.message}</ErrorField>
            )}
            <styled.Info>
              Trigger will be saved as {utcTime} UTC (GMT+00:00).
            </styled.Info>
          </styled.Fieldset>
        )}
      </styled.Section>
      <Hr />
      <styled.Section>
        <styled.SectionTitle>Start and stop dates</styled.SectionTitle>
        <styled.SectionDescription>
          Set start and stop dates for this automation. If you do not set a start date,
          your automation will not be active until you do so. You can also activate it
          from the dashboard.
        </styled.SectionDescription>

        <styled.Fieldset>
          <styled.Label>Start at (optional)</styled.Label>
          <Controller
            render={({ field }) => (
              <DateTimePicker
                {...field}
                data-test="startAt-picker"
                value={state.startAt ? moment(state.startAt) : undefined}
                disableBefore={moment().startOf("day")}
                onChange={e => {
                  handleDateTimeChange("startAt", e, field);
                }}
                showTime={allowStartEndTime}
              />
            )}
            control={control}
            name="startAt"
            defaultValue={state.startAt}
          />
          {formState.errors?.startAt && (
            <ErrorField>{formState.errors.startAt.message}</ErrorField>
          )}
        </styled.Fieldset>

        <styled.Fieldset>
          <Checkbox
            value={showStopAt}
            checked={showStopAt}
            onChange={e => toggleShowStopAt(e.target.checked)}
          >
            Set stop at
          </Checkbox>
        </styled.Fieldset>
        {showStopAt && (
          <styled.Fieldset>
            <styled.Label>Stop at</styled.Label>
            <Controller
              rules={{
                validate: (value: string) => {
                  return !value || moment(value).isSameOrAfter(moment(state.startAt));
                }
              }}
              render={({ field }) => (
                <DateTimePicker
                  {...field}
                  data-test="endAt-picker"
                  value={state.endAt ? moment(state.endAt) : undefined}
                  disableBefore={(state.startAt
                    ? moment(state.startAt).clone()
                    : moment()
                  ).startOf("day")}
                  onChange={e => {
                    handleDateTimeChange("endAt", e, field);
                  }}
                  showTime={allowStartEndTime}
                />
              )}
              control={control}
              name="endAt"
              defaultValue={state.endAt}
            />
            {formState.errors?.endAt && formState.errors.endAt.type === "validate" && (
              <ErrorField>Stop date must be after start date.</ErrorField>
            )}
          </styled.Fieldset>
        )}
      </styled.Section>
    </div>
  );
};

export default Schedule;
