export default class TreeTraverser<T> {
  private _tree: T;
  private cursor: number[];
  private batches: T[][] = [];
  private _currentNode: T | undefined;
  private mode: "breadth" | "depth" = "depth";
  private getParent: (node: T) => T | null;
  private getChildren: (node: T) => T[];

  constructor(
    tree: T,
    opts: {
      getParent?: (node: T) => T | null;
      getChildren?: (node: T) => T[];
      mode?: "breadth" | "depth";
    } = {}
  ) {
    this._tree = tree;
    this.cursor = [];
    this.batches = [];
    this._currentNode = undefined;
    this.mode = opts.mode || "depth";
    this.getParent = opts.getParent || ((node: any) => node.parent);
    this.getChildren = opts.getChildren || ((node: any) => node.children);
    this.reset();
  }

  private reset() {
    this.cursor = [-1];
    this._currentNode = undefined;
    this.batches = [[this._tree]];
  }

  get currentCursor(): number {
    return this.cursor[this.currentDepth] ?? -1;
  }

  get currentDepth(): number {
    return this.cursor.length - 1;
  }

  get tree(): T {
    return this._tree;
  }

  get currentNode(): T | undefined {
    return this._currentNode;
  }

  private peekNode(cursor: number[]): T | undefined {
    return cursor.reduce<T | undefined>(
      (acc, curr) => {
        if (!acc) return undefined;
        if ((acc as any).children) return (acc as any).children[curr];
        return this.getChildren(acc)[curr];
      },
      { children: [this._tree] } as any
    );
  }

  private _depthNext(): T | undefined {
    // If we're at the root node, return it
    if (this.currentDepth === 0 && this.currentCursor === -1) {
      this._currentNode = this._tree;
      this.cursor[0] = 0;
      return this._currentNode;
    }

    // If there are children, go down
    if (this._currentNode && this.peekNode([...this.cursor, 0])) {
      this.cursor.push(0);
      this._currentNode = this.getChildren(this._currentNode)[0];
      return this._currentNode;
    }
    // If there are no children go to the next sibling
    const siblingIndex = this.currentCursor + 1;
    const peekCursor = [...this.cursor];
    peekCursor.pop();
    peekCursor.push(siblingIndex);
    if (this.peekNode(peekCursor)) {
      this.cursor[this.currentDepth]++;
      this._currentNode = this.peekNode(this.cursor);
      return this._currentNode;
    }
    // If there are no siblings, go up to an ancestor with a sibling
    while (true) {
      this.cursor.pop();
      if (this.cursor.length === 0) {
        this._currentNode = undefined;
        break;
      }
      const siblingIndex = this.currentCursor + 1;
      const peekCursor = [...this.cursor];
      peekCursor.pop();
      peekCursor.push(siblingIndex);
      if (this.peekNode(peekCursor)) {
        this.cursor[this.currentDepth]++;
        this._currentNode = this.peekNode(this.cursor);
        return this._currentNode;
      }
    }
  }

  private _breadthNext(): T | undefined {
    if (!this.batches.length) {
      this._currentNode = undefined;
    } else if (this.batches[0].length === this.currentCursor + 1) {
      this.batches.shift();
      this.cursor.push(0);
      if (this.batches.length) {
        this._currentNode = this.batches[0][this.currentCursor];
      } else {
        this._currentNode = undefined;
      }
    } else {
      this.cursor[this.currentDepth]++;
      this._currentNode = this.batches[0][this.currentCursor];
    }

    if (this._currentNode && this.getChildren(this._currentNode).length) {
      this.batches.push(this.getChildren(this._currentNode));
    }
    return this._currentNode;
  }

  public next(): T | undefined {
    return this.mode === "depth" ? this._depthNext() : this._breadthNext();
  }

  public updateNodes(updater: (node: T) => T) {
    this.reset();
    while (this.next()) {
      const updated = updater(this._currentNode!);
      // If the node instance was changed need to update the ref
      // in parent's children array
      if (this._currentNode !== updated && this._currentNode) {
        const parent = this.getParent(this._currentNode);
        if (parent) {
          const siblings = this.getChildren(parent);
          const idx = siblings.indexOf(this._currentNode!);
          siblings[idx] = updated;
        } else {
          this._tree = updated;
        }
      }
      this._currentNode = updated;
    }
    this.reset();
  }

  public find(predicate: (node: T) => boolean): T | undefined {
    this.reset();
    while (this.next()) {
      if (predicate(this._currentNode!)) {
        return this._currentNode;
      }
    }
    this.reset();
    return undefined;
  }

  public reduce<R>(
    callbackFn: (previousValue: R, currentValue: T, tree: TreeTraverser<T>) => R,
    initialValue: R
  ) {
    let acc: R = initialValue;
    while (this.next()) {
      acc = callbackFn(acc, this._currentNode!, this);
    }
    return acc;
  }
}

export function linkTree(
  node: any,
  parent: any,
  parentAlias = "parent",
  childrenAlias = "children"
) {
  node[parentAlias] = parent;
  node[childrenAlias].forEach((child: any) =>
    linkTree(child, node, parentAlias, childrenAlias)
  );
}
