import React, { useLayoutEffect, useEffect, useState } from "react";

import ModalIcon from "../../../../../assets/spaceicons/modal.svg";
import { GlobalStyleVariables } from "../../../../../cssConstants";
import { useEvaluaterContext } from "../../../../common/CodeSandbox/EvaluaterContext";
import { useCanvasViewportContext } from "../../../layout/Canvas/CanvasViewportContext";
import DragBoundary from "../../../layout/Draggable/DragBoundary";
import { OVERLAY_COMPONENT_Z_INDEX } from "../../../layout/Element/constants";
import { useSpaceConfigContext } from "../../../SpaceConfig/SpaceConfigContext/SpaceConfigContext";
import { useStableSpaceContext } from "../../SpaceContext";
import useDialogEffects, {
  DialogEventType
} from "../common/effects/useDialogEffects/useDialogEffects";
import { HeadlessComponentIcon } from "../common/HeadlessComponentIcon/HeadlessComponentIcon";
import { useComponentStateContext } from "../contexts/ComponentStateContext";
import SpaceComponent, { Props } from "../SpaceComponent";

import * as styled from "./styledComponents";
import { SpaceModalComponent } from "./types";

// HACK: It's not possible to style .ant-modal-wrap using styled components
// as the styled node is the modal's content wrapper and props don't exist for
// overriding the wrap's styles. Instead we write a style tag and append
// it to document.head when needed.
// TODO: Can't manage to disable animation :O
const editModeStyleOverrides = document.createElement("style");
editModeStyleOverrides.appendChild(
  document.createTextNode(`
  .space-modal-edit-mode {
    top: ${GlobalStyleVariables.headerHeight} !important;
    right: ${GlobalStyleVariables.configPanelWidth} !important;
    left: ${GlobalStyleVariables.leftMenuWidth} !important;
  }
`)
);

export default function SpaceModal({
  spaceComponent,
  hasConfigError,
  ...props
}: Props) {
  const {
    state: { uiStateOverrides }
  } = useSpaceConfigContext();
  const [isOpen, setIsOpen] = useState(false);
  const modalComponent = spaceComponent as SpaceModalComponent;
  const { editMode } = useStableSpaceContext();
  const { lockScroll, unlockScroll } = useCanvasViewportContext();
  const { input } = useComponentStateContext();
  const { evaluate } = useEvaluaterContext();
  const { title, is_open_template, has_close_button } = modalComponent.properties;
  const modalConent = spaceComponent.componentTreeNodes[0];
  const { triggerEffects } = useDialogEffects();

  useEffect(() => {
    const evalTemplate = async () => {
      const isOpen = await evaluate(is_open_template, input);
      if (isOpen === "undefined") {
        setIsOpen(false);
      } else {
        setIsOpen(!!isOpen);
      }
    };
    evalTemplate();
  }, [is_open_template, input, evaluate]);

  const _isOpen = editMode
    ? uiStateOverrides.openModals.has(modalComponent.slug)
    : isOpen;
  useLayoutEffect(() => {
    if (_isOpen && editMode) {
      document.head.appendChild(editModeStyleOverrides);
    }
    return () => {
      if (editModeStyleOverrides.parentNode === document.head) {
        document.head.removeChild(editModeStyleOverrides);
      }
    };
  }, [_isOpen, editMode]);

  useLayoutEffect(() => {
    if (_isOpen) lockScroll();
    return () => {
      unlockScroll();
    };
  }, [_isOpen, lockScroll, unlockScroll]);

  const Modal = editMode ? styled.EditModeModal : styled.ViewModeModal;

  const { width, height } = modalComponent.layout!;

  return (
    <>
      {editMode && (
        <HeadlessComponentIcon Icon={ModalIcon} hasConfigError={!!hasConfigError} />
      )}
      <div onClick={evt => evt.stopPropagation()} key={String(_isOpen)}>
        <Modal
          visible={_isOpen}
          title={title || null}
          // Always render modal contents so its state is available to bind to
          maskStyle={
            editMode
              ? {
                  top: GlobalStyleVariables.headerHeight,
                  right: GlobalStyleVariables.configPanelWidth,
                  left: GlobalStyleVariables.leftMenuWidth
                }
              : {}
          }
          maskClosable={false}
          wrapClassName={editMode ? "space-modal-edit-mode" : ""}
          width={width}
          height={height}
          closable={has_close_button}
          zIndex={OVERLAY_COMPONENT_Z_INDEX}
          footer={null}
          onCancel={() => {
            triggerEffects({ type: DialogEventType.CLOSE });
          }}
        >
          <DragBoundary disable={!_isOpen}>
            <SpaceComponent spaceComponent={modalConent} {...props} />
          </DragBoundary>
        </Modal>
      </div>
      {!_isOpen && (
        <div style={{ display: "none" }}>
          <SpaceComponent spaceComponent={modalConent} {...props} />
        </div>
      )}
    </>
  );
}
