import { Select as AntSelect, Collapse } from "antd";
import styled from "styled-components";

export const Select = styled(AntSelect)`
  margin-bottom: ${props => props.theme.spacermd};
`;

export const LeftAlignCollapsePanel = styled(Collapse.Panel)`
  &.ant-collapse-item {
    > .ant-collapse-header {
      padding-left: 24px;

      .ant-collapse-arrow {
        left: 0;
      }

      .ant-collapse-content-box {
        padding-left: 0;
      }
    }

    .ant-collapse-content {
      > .ant-collapse-content-box {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
`;
