import React from "react";

import { message } from "antd";
import _ from "lodash";

import {
  Binding,
  SpaceComponentNode,
  SpaceComponentObject,
  StableSpace,
  StatusCode
} from "../../../../types";
import { BindingNode, createNodeFromArray } from "../../../util/binding";
import { reportException } from "../../../util/exceptionReporting";
import { EMPTY_SPACE } from "../../constants";
import { SpaceDefinition } from "../../types";
import { fromComponents } from "../../util/tree";
import { RootNode } from "../RenderTreeContext";
import { SpaceContextParamComponent } from "../SpaceComponent/SpaceContextParam/types";

import { findSpaceComponentPackage } from "./StableSpaceContext";
import useSpace from "./useSpace";
import {
  getAncestorComponents as getAncestorComponentsFromComponents,
  getStableSpace
} from "./util";

export type SpaceContextParams = Record<string, SpaceContextParamValue>;

export interface SpaceContextParamValue<T = any> {
  schema: Binding[];
  title: string;
  value?: T;
}

export type SpaceContextValue = Omit<ReturnType<typeof useSpace>, "space"> & {
  space: StableSpace;
  components: SpaceComponentObject[];
  contextParams: SpaceContextParams;
  debugMode: boolean;
  // returns array of components from root to leaf, inclusive of component for slug passed in
  getAncestorComponents: (slug: string | undefined) => SpaceComponentObject[];
  getBinding: (path: string | null | undefined) => Binding | undefined;
  toggleDebugMode: () => void;
};

export interface SpaceContextProviderProps {
  slug?: string;
  editing?: boolean;
  children: React.ReactNode;
}

export const initialSpaceContext: SpaceContextValue = {
  loading: true,
  space: EMPTY_SPACE,
  components: [],
  componentTree: [],
  contextParams: {},
  queryStatus: 0,
  slug: "",
  debugMode: false,
  getBinding: (_path: string | null | undefined): Binding | undefined => undefined,
  getAncestorComponents: () => [],
  toggleDebugMode: () => {}
};

export const SpaceContext = React.createContext<SpaceContextValue>(initialSpaceContext);

export function collectComponents(
  components: SpaceComponentObject[] = []
): SpaceComponentObject[] {
  const children = components.flatMap(c => {
    if (!c.componentTreeNodes) {
      reportException(
        new Error("Space query was not deep enough to include some components"),
        {
          extra: { component: c }
        }
      );
      return [];
    }
    return collectComponents(c.componentTreeNodes);
  });
  return components.concat(children);
}

export const createSpaceContextParamComponent = (
  key: string,
  value: SpaceContextParamValue
): SpaceContextParamComponent => ({
  id: `__clientProvided${key}`,
  slug: key,
  name: value.title,
  type: "CONTEXT_PARAM",
  functions: { edges: [] },
  container: null,
  componentTreeNodes: [],
  properties: {
    context_param: key,
    schema: value.schema
  }
});

export function EmbedSpaceContextProvider({
  children,
  componentTreeNodes,
  contextParams
}: {
  children: React.ReactNode;
  componentTreeNodes: SpaceComponentNode[];
  contextParams: Record<string, SpaceContextParamValue>;
}) {
  const [debugMode, setDebugMode] = React.useState(false);
  const components = React.useMemo(() => {
    const contextParamComponents = Object.entries(contextParams).map(([key, value]) =>
      createSpaceContextParamComponent(key, value)
    );
    const nodes = [...componentTreeNodes, ...contextParamComponents];
    return nodes.length > 0 ? collectComponents(nodes) : [];
  }, [componentTreeNodes, contextParams]);

  const getAncestorComponents = React.useCallback(
    (slug: string | undefined) => {
      return getAncestorComponentsFromComponents(slug, components);
    },
    [components]
  );

  const getBinding = React.useCallback(
    (path: string | null | undefined): Binding | undefined => {
      return _getBinding(componentTreeNodes, path, new Map<string, SpaceDefinition>());
    },
    [componentTreeNodes]
  );

  const toggleDebugMode = React.useCallback(() => {
    message.info(`Debug mode ${debugMode ? "off" : "on"}`);
    setDebugMode(!debugMode);
  }, [debugMode]);

  const spaceValue: SpaceContextValue = React.useMemo(
    () => ({
      loading: false,
      componentTree: componentTreeNodes,
      views: new Map(),
      contextParams: contextParams,
      queryStatus: StatusCode.OK,
      slug: undefined,
      space: {
        id: "single-component-embed",
        name: "single-component-embed",
        slug: "single-component-embed",
        branchCount: 0,
        scmIsCurrent: false,
        scmStatus: null,
        scmSyncStatus: null
      },
      components,
      debugMode,
      getAncestorComponents,
      getBinding,
      toggleDebugMode
    }),
    [
      components,
      componentTreeNodes,
      debugMode,
      getAncestorComponents,
      getBinding,
      contextParams,
      toggleDebugMode
    ]
  );

  return <SpaceContext.Provider value={spaceValue}>{children}</SpaceContext.Provider>;
}

export function SpaceContextProvider({
  slug,
  children,
  editing
}: SpaceContextProviderProps) {
  const result = useSpace(slug, { editMode: !!editing });
  const [debugMode, setDebugMode] = React.useState(false);

  const components = React.useMemo(() => {
    return !!result.componentTree && result.componentTree.length > 0
      ? collectComponents(result.componentTree)
      : [];
  }, [result.componentTree]);

  const getAncestorComponents = React.useCallback(
    (slug: string | undefined) => {
      return getAncestorComponentsFromComponents(slug, components);
    },
    [components]
  );

  const spaceDefs = React.useMemo(() => {
    const map = new Map<string, SpaceDefinition>();
    if (!result.space) return map;
    map.set(result.space.slug, {
      slug: result.space.slug,
      name: result.space.name,
      versionId: result.space.version?.id,
      parameters: result.space.version?.parameters || [],
      scmIsCurrent: result.space.scmIsCurrent,
      scmStatus: result.space.scmStatus,
      scmSyncStatus: result.space.scmSyncStatus,
      branchCount: result.space.branchCount
    });
    (result.space.version?.subSpaces || []).forEach(s =>
      map.set(s.slug, {
        slug: s.slug,
        name: s.name,
        versionId: s.version?.id,
        parameters: result.space.version?.parameters || [],
        scmIsCurrent: result.space.scmIsCurrent,
        scmStatus: result.space.scmStatus,
        scmSyncStatus: result.space.scmSyncStatus,
        branchCount: result.space.branchCount
      })
    );
    return map;
  }, [result.space]);

  const getBinding = React.useCallback(
    (path: string | null | undefined): Binding | undefined => {
      return _getBinding(result.componentTree, path, spaceDefs);
    },
    [result.componentTree, spaceDefs]
  );

  const toggleDebugMode = React.useCallback(() => {
    message.info(`Debug mode ${debugMode ? "off" : "on"}`);
    setDebugMode(!debugMode);
  }, [debugMode]);

  const spaceValue = React.useMemo(
    () => ({
      ...result,
      views: new Map(),
      space: getStableSpace(result.space),
      components,
      contextParams: {},
      debugMode,
      getAncestorComponents,
      getBinding,
      toggleDebugMode
    }),
    [result, components, getAncestorComponents, getBinding, debugMode, toggleDebugMode]
  );

  return <SpaceContext.Provider value={spaceValue}>{children}</SpaceContext.Provider>;
}

export const useSpaceContext = () => React.useContext(SpaceContext);

export function _getBinding(
  componentTree: SpaceComponentObject[],
  path: string | null | undefined,
  spaces: Map<string, SpaceDefinition>
) {
  if (!path) return undefined;

  const renderTree = fromComponents(componentTree);
  const bindingState = getBindingState(renderTree, spaces);
  return (_.get(bindingState, path) as BindingNode | undefined)?.__meta;
}

function getBindingState(renderTree: RootNode, spaces: Map<string, SpaceDefinition>) {
  return renderTree.children.reduce<{ [k: string]: any }>((acc, n) => {
    const pkg = findSpaceComponentPackage(n.component.type);
    if (!pkg.getSchema) return acc;
    acc[n.path] = createNodeFromArray(pkg.getSchema(n, spaces));
    return acc;
  }, {});
}
