import React from "react";

import { isEqual } from "lodash";
import { useNavigate, useLocation } from "react-router-dom";

import { encodeResourceQueryDescriptor } from "../../../../../constants";
import {
  AttributeNode,
  FunctionAttributeNode,
  CursorFilter,
  ViewFilter
} from "../../../../../types";
import usePaths from "../../../../common/hooks/usePaths";
import { useStableSpaceContext } from "../../SpaceContext";
import { useBranchContext } from "../../SpaceContext/BranchContext";

import { SpaceTableComponent } from "./types";

export default function useSyncLocationToFilter(
  filters: ViewFilter[],
  component: SpaceTableComponent,
  viewAttributes: (AttributeNode | FunctionAttributeNode)[]
) {
  const { getSpace } = usePaths();
  const navigate = useNavigate();
  const location = useLocation();
  const { spaceSlug, editMode } = useStableSpaceContext();
  const [lastFilters, setLastFilters] = React.useState<ViewFilter[]>([]);
  const { branch } = useBranchContext();
  const isResourceTable = !!component.properties.is_resource_table;

  React.useEffect(() => {
    if (!isResourceTable || !spaceSlug || editMode || !viewAttributes.length) return;

    if (isEqual(filters, lastFilters)) return;

    if (filters && filters.length) {
      const resourceFilters = filters
        .map((filter: ViewFilter) => {
          const attr = viewAttributes.find(
            attr => attr.sourceName === filter.sourceName
          );
          if (attr === undefined) return null;
          return {
            attribute: attr.sourceName,
            operator: filter.operator,
            value: filter.value,
            __clientId: filter.__clientId
          };
        })
        .filter((f: CursorFilter | null) => f !== null) as CursorFilter[];
      const encoded = encodeResourceQueryDescriptor(spaceSlug, resourceFilters);
      // NOTE: onFilter is called many times, replace is used
      // to avoid pushing the same path several times over
      // and polluting the history.
      navigate(
        {
          pathname: getSpace(spaceSlug, { resourceCursor: encoded, branch }),
          search: location.search
        },
        { replace: true }
      );
    } else {
      navigate(
        {
          pathname: getSpace(spaceSlug, { branch }),
          search: location.search
        },
        { replace: true }
      );
    }
    setLastFilters(filters);
  }, [
    filters,
    isResourceTable,
    editMode,
    getSpace,
    navigate,
    location.search,
    viewAttributes,
    spaceSlug,
    branch,
    setLastFilters,
    lastFilters
  ]);
}
