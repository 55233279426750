import { UserNode } from "../../types";

import installIntercom, { APP_ID } from "./installIntercom";

interface IntercomClient {
  install: () => void;
  boot: (user: UserNode) => void;
  show: () => void;
  hide: () => void;
  showLauncher: () => void;
  hideLauncher: () => void;
}

let BOOTED = false;

const ItercomClientImpl: IntercomClient = {
  install: installIntercom,
  boot: (user: UserNode) => {
    if (BOOTED) {
      return;
    }
    window.Intercom("boot", {
      app_id: APP_ID,
      email: user.email,
      name: `${user.firstName} ${user.lastName}`,
      user_id: user.id
    });
    BOOTED = true;
    const url = new URL(window.location.href);
    if (url.searchParams.get("__showIntercom") === "1") {
      window.Intercom("show");
      url.searchParams.delete("__showIntercom");
      window.history.replaceState({}, document.title, url.href);
    }
  },
  show: () => {
    window.Intercom("show");
  },
  hide: () => {
    window.Intercom("hide");
  },
  hideLauncher: () => {
    window.Intercom("update", {
      hide_default_launcher: true
    });
  },
  showLauncher: () => {
    window.Intercom("update", {
      hide_default_launcher: false
    });
  }
};

const ZeroIntercomClient: IntercomClient = {
  boot: () => {},
  hide: () => {},
  hideLauncher: () => {},
  install: () => {},
  show: () => {},
  showLauncher: () => {}
};

export default process.env.INTERNALIO_ONPREM !== "1"
  ? ItercomClientImpl
  : ZeroIntercomClient;
