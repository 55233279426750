import React from "react";

import { useQuery } from "@apollo/react-hooks";
import { isEqual } from "lodash";

import { SpaceComponentObject, SpaceNode, StatusCode } from "../../../../types";
import { EMPTY_SPACE } from "../../constants";
import { injectSpaces } from "../../util/injectSpaces";
import { SPACE_QUERY, SpaceData, SpaceVars } from "../queries";

import { useBranchContext } from "./BranchContext";
import useClientProvidedComponents from "./useClientProvidedComponents";
import { buildComponentTree } from "./util";

export interface Result {
  space: SpaceNode;
  loading: boolean;
  componentTree: SpaceComponentObject[];
  queryStatus: StatusCode;
  slug: string | undefined;
}

enum SpaceResultTypes {
  NotFound = "NotFoundErrorResult",
  NotPublished = "NoPublishedVersionErrorResult"
}

export default function useSpace(
  slug: string | undefined,
  options: { editMode: boolean }
): Result {
  const { editMode } = options;

  const { initBranch } = useBranchContext();

  const result = useQuery<SpaceData, SpaceVars>(SPACE_QUERY, {
    variables: { slug: slug || "", includeUnpublished: editMode },
    fetchPolicy: "cache-and-network",
    skip: !slug,
    onCompleted: data => {
      initBranch(data.space.scmStatus);
    }
  });
  const space = result.data?.space || EMPTY_SPACE;

  const apiComponentTree = React.useMemo(() => {
    return buildComponentTree(space.version?.components || []);
  }, [space]);

  const clientProvidedComponents = useClientProvidedComponents();

  const subSpaceTrees = React.useMemo(
    () =>
      space.version?.subSpaces
        ? new Map(
            space.version.subSpaces?.map(s => [
              s.slug,
              buildComponentTree(s.version?.components || [])
            ])
          )
        : new Map(),
    [space]
  );

  const componentTree = React.useMemo(() => {
    if (result.loading) return [];
    injectSpaces(apiComponentTree, subSpaceTrees);
    return apiComponentTree.concat(clientProvidedComponents);
  }, [apiComponentTree, result.loading, clientProvidedComponents, subSpaceTrees]);

  const queryStatus = React.useMemo(() => {
    if (result.data?.space.__typename === SpaceResultTypes.NotFound) {
      return StatusCode.NOT_FOUND;
    }
    if (result.data?.space.version?.__typename === SpaceResultTypes.NotPublished) {
      return StatusCode.PERMISSION_DENIED;
    }
    return StatusCode.OK;
  }, [result.data]);

  return React.useMemo(() => {
    return {
      queryStatus,
      loading: result.loading || (!!slug && isEqual(space, EMPTY_SPACE)),
      space,
      componentTree,
      slug
    };
  }, [result.loading, space, componentTree, slug, queryStatus]);
}
