import React from "react";

import OpenNewWindowIcon from "../../../../../assets/icons/open-new-window.svg";
import BranchOperationModal from "../BranchOperationModal";
import { BranchModalProps } from "../types";

import useTrackSpaceBranch from "./useTrackSpaceBranch";

interface Props extends BranchModalProps {
  repositoryBranchesUrl: string;
}

export default function TrackBranchModal({
  repositoryBranchesUrl,
  spaceId,
  onCancel = () => null,
  onError = () => null,
  onSuccess = () => null,
  visible
}: Props) {
  const [trackBranch, { loading }] = useTrackSpaceBranch({
    onCompleted: data => onSuccess(data.mutation!.trackSpaceBranch.branch),
    onError
  });

  const operationFn = React.useCallback(
    (branch: string) => {
      trackBranch({ variables: { branch, spaceId } });
    },
    [spaceId, trackBranch]
  );

  return (
    <BranchOperationModal
      loading={loading}
      okText="Track branch"
      operationFn={operationFn}
      title="Track existing branch"
      onCancel={onCancel}
      visible={visible}
    >
      <a href={repositoryBranchesUrl} target="_blank" rel="noreferrer">
        View branches on GitHub <OpenNewWindowIcon />
      </a>
    </BranchOperationModal>
  );
}
