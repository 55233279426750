import React, { useEffect } from "react";

import { setAutoFreeze } from "immer";
import { useLocation, useParams, useNavigate } from "react-router";

import { decode } from "../../../constants";
import intercom from "../../../integrations/intercom/intercom";
import PageHeader, {
  HeaderBanner,
  HeaderControls,
  HeaderTitle
} from "../../../layouts/Header/Header";
import usePaths from "../../common/hooks/usePaths";
import Message from "../../common/Message";
import Portal from "../../common/Portal/Portal";
import ThemeContainer, { Theme } from "../../common/ThemeContainer";
import { TransformationContextContainer } from "../layout/TransformationContext/TransformationContext";
import BranchSelector from "../SpaceRoot/Header/BranchSelector";
import ScmBanner from "../SpaceRoot/Header/ScmBanner";
import ConfigPanel from "../SpaceRoot/SpaceComponent/common/ConfigPanel";
import { ConfigPanelProvider } from "../SpaceRoot/SpaceComponent/common/ConfigPanel/ConfigPanelContext";
import ErrorIcon from "../SpaceRoot/SpaceConsoleContext/ErrorIcon";
import { useStableSpaceContext } from "../SpaceRoot/SpaceContext";
import Space from "../SpaceRoot/SpaceRoot";

import HeaderButtons from "./ConfigHeader/HeaderButtons";
import NameEditor from "./ConfigHeader/NameEditor";
import LeftMenu from "./LeftMenu/Sider";
import { SpaceConfigContextProvider } from "./SpaceConfigContext";
import { UseConfigMutationData } from "./SpaceConfigContext/useSpaceConfig/useConfigMutation/useConfigMutation";
import { DestroyConfigMutationData } from "./SpaceConfigContext/useSpaceConfig/useDestroyConfigMutation/useDestroyConfigMutation";
import * as styled from "./StyledComponents";

import { SpaceConfigMenuPathItem } from "./index";

export default function SpaceConfig({ children }: { children: React.ReactNode }) {
  const navigate = useNavigate();
  const { getSpacesHome } = usePaths();
  const { spaceSlug } = useStableSpaceContext();
  const params = useHashParams();
  const menuPath = useMenuPath();

  useEffect(() => {
    setAutoFreeze(false);
    return () => {
      setAutoFreeze(true);
    };
  }, []);

  const onNewSpaceCreated = (result: UseConfigMutationData) => {
    const slug = result.spaceUpdate.space.slug;
    if (!spaceSlug) {
      navigate(`#${new URLSearchParams({ ...params, slug }).toString()}`, {
        replace: true
      });
    }
  };

  const handleDestroyed = (result: DestroyConfigMutationData) => {
    if (result.spaceDelete.ok) {
      navigate(getSpacesHome());
      setTimeout(() => Message.success("The Space has been deleted"), 1000);
      return;
    }
    Message.error(
      "Something went wrong while deleting this space. Our team has been notified. Please try again later."
    );
    throw Error("Unexpected error while deleting space.");
  };

  return (
    <SpaceConfigContextProvider
      slug={spaceSlug}
      onNewSpaceCreated={onNewSpaceCreated}
      onDestroy={handleDestroyed}
    >
      <TransformationContextContainer>
        <ConfigPanelProvider>
          <styled.Cols>
            <LeftMenu menuPath={menuPath} />
            <styled.CanvasContainer>{children}</styled.CanvasContainer>
            <styled.RightMenu>
              <ThemeContainer theme={Theme.Dark}>
                <ConfigPanel />
              </ThemeContainer>
            </styled.RightMenu>
          </styled.Cols>
        </ConfigPanelProvider>
      </TransformationContextContainer>
    </SpaceConfigContextProvider>
  );
}

export function SpaceConfigPage() {
  const menuPath = useMenuPath();
  const deepMenuOpen = menuPath.length > 1;
  const params = useHashParams();
  const newSpaceSlug = params.get("slug") || undefined;
  const { spaceSlug } = useParams<{
    spaceSlug: string;
  }>();

  useEffect(() => {
    intercom.hideLauncher();
    return () => {
      intercom.showLauncher();
    };
  }, []);

  return (
    <>
      <PageHeader hideControls={deepMenuOpen} />
      {/*
        NOTE: Key purposely spaceSlug to prevent flickers on new space save.
              Upon creation the newly assigned slug is in a hash param assigned
              to `newSpaceSlug` here. If that is used as the key here the space
              will remount causing a flicker to a loading state.
      */}
      <Space key={spaceSlug} editing slug={spaceSlug || newSpaceSlug}>
        <Portal id="branch-selector-portal">
          <BranchSelector />
        </Portal>
        <HeaderTitle>
          <ErrorIcon />
          <NameEditor />
        </HeaderTitle>
        <HeaderControls>
          <HeaderButtons />
        </HeaderControls>
        <HeaderBanner>
          <ScmBanner />
        </HeaderBanner>
      </Space>
    </>
  );
}

function useHashParams() {
  const { hash } = useLocation();
  const params = new URLSearchParams(hash.substring(hash.indexOf("#") + 1));
  return params;
}

function useMenuPath() {
  const params = useHashParams();
  const menu = params.get("menu");
  const menuPath = menu ? decode<SpaceConfigMenuPathItem[]>(menu) : [];
  return menuPath;
}
