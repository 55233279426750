import React from "react";

import styled from "styled-components";

import { newColors } from "../../../cssConstants";

interface Props {
  state: { name: string; color: string };
}

const RootStatePill = styled.div`
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 6px;
  padding: 4px 8px;
  background-color: ${newColors.containerActive};
  border-radius: 500px;
`;

const StateColor = styled.div`
  display: inline-block;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: ${props => props.theme.primaryColor};
`;

export default function StatePill({ state }: Props) {
  return (
    <RootStatePill>
      <StateColor style={{ backgroundColor: `#${state.color}` }} /> {state.name}
    </RootStatePill>
  );
}
