import React from "react";

import { Select } from "antd";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { TableLayoutNoMargin } from "../../../../layouts/TableLayout";
import { FunctionNode } from "../../../../types";
import AuditLog from "../../../common/AuditLog";
import { useEnvironmentContext } from "../../../common/contexts/EnvironmentContext";
import Drawer from "../../../common/Drawer";
import usePaths from "../../../common/hooks/usePaths";
import { useStableSpaceContext } from "../SpaceContext";
import { useBranchContext } from "../SpaceContext/BranchContext";

interface SpaceAuditFilterOptions {
  functions: FunctionNode[];
}

interface SpaceAuditLogDrawerProps {
  onClose: () => void;
  isVisible: boolean;
}

interface SpaceAuditLogDrawerContentsProps {
  spaceId: string;
  filtersOptions: SpaceAuditFilterOptions;
}

const StyledDrawer = styled(Drawer)`
  h3 {
    margin-bottom: 0;
    margin-right: ${props => props.theme.spacersm};
    font-size: ${props => props.theme.h3FontSize};
    font-weight: ${props => props.theme.boldFontWeight};
    color: ${props => props.theme.textColorMid};
  }

  .ant-select {
    min-width: 225px;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const SpaceAuditLogDrawerContents = (props: SpaceAuditLogDrawerContentsProps) => {
  const { getSpace } = usePaths();
  const { spaceSlug } = useParams<{ spaceSlug: string }>();
  const { spaceId, filtersOptions } = props;
  const [filters, setFilters] = React.useState<{
    functionId?: string;
  }>({});
  const environmentId = useEnvironmentContext().getCurrentEnvironment().id;
  const { branch } = useBranchContext();

  const functions = filtersOptions.functions.filter(
    (f, i, a) => a.findIndex(f2 => f2.id === f.id) === i
  );

  const onChange = (value: string) => {
    const [filterType, filterValue] = value.split(".", 2);
    if (filterType === "function") {
      setFilters({ functionId: filterValue });
    } else {
      setFilters({});
    }
  };

  return (
    <TableLayoutNoMargin
      title=""
      breadcrumbRoutes={[
        {
          path: getSpace(spaceSlug!, { branch }),
          breadcrumbName: "Back",
          children: []
        }
      ]}
    >
      <Header>
        <h3>Activity</h3>
        <Select defaultValue="everything" onChange={onChange}>
          <Select.Option value="everything">Everything</Select.Option>
          {functions && functions.length && (
            <Select.OptGroup label="Functions">
              {functions.map(f => (
                <Select.Option key={f.id} value={`function.${f.id}`}>
                  {f.title}
                </Select.Option>
              ))}
            </Select.OptGroup>
          )}
        </Select>
      </Header>
      <AuditLog
        spaceId={spaceId}
        environmentId={environmentId}
        functionId={filters.functionId}
      />
    </TableLayoutNoMargin>
  );
};

const SpaceAuditLogDrawer = ({ onClose, isVisible }: SpaceAuditLogDrawerProps) => {
  const { spaceId, spaceVersion } = useStableSpaceContext();
  const auditFilterOptions = React.useMemo((): SpaceAuditFilterOptions => {
    return {
      functions: (spaceVersion?.components || [])
        .filter(c => !!c.functions.edges.length)
        .flatMap(c => c.functions.edges)
        .map(e => e.node)
    };
  }, [spaceVersion]);

  return (
    <StyledDrawer
      placement="right"
      closable={false}
      onClose={onClose}
      visible={isVisible}
    >
      <SpaceAuditLogDrawerContents
        spaceId={spaceId}
        filtersOptions={auditFilterOptions}
      />
    </StyledDrawer>
  );
};

export default SpaceAuditLogDrawer;
