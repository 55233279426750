import React, { useEffect, useState } from "react";

import { Button } from "antd";
import * as codemirror from "codemirror";
import { Controlled as CodeMirror } from "react-codemirror2";
import styled from "styled-components";

import { BindingShape } from "../../../types";
import { BindingCascader, Option } from "../BindingCascader";
import SingleLineEditor, { Mode } from "../SingleLineEditor";
import withDebouncedValue from "../withDebouncedValue";

export enum Height {
  Small = "30px",
  Medium = "60px",
  Large = "90px"
}

export interface TemplateEditorProps {
  value: string;
  bindingOptions: Option[];
  placeholder?: string;
  minHeight?: Height;
  dataTest?: string;
  mode?: Mode;
  onChange: (s: string) => void;
  onFocus?: (evt: React.FocusEvent<HTMLTextAreaElement>) => void;
  onBlur?: (evt: React.FocusEvent<HTMLTextAreaElement>) => void;
  onChangeImmediately?: (value: any) => void;
  popupContainerFactory?: (
    domId?: string | undefined
  ) => ((triggerNode: HTMLElement) => HTMLElement) | undefined;
}

export const TemplateBindings = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  button {
    padding: 0px;
  }
`;

const InsertButton = styled(Button)`
  max-width: 100px;
`;

const getStrippedValue = (value: string) => {
  if (value.startsWith("`") && value.endsWith("`")) {
    return value.substring(1, value.length - 1);
  }
  return value;
};

export default function TemplateEditor({
  value,
  bindingOptions,
  placeholder = "",
  minHeight = Height.Small,
  mode = "jstl",
  dataTest,
  onChange,
  popupContainerFactory,
  onFocus = () => {},
  onBlur = () => {},
  onChangeImmediately
}: TemplateEditorProps) {
  const editorRef = React.useRef<CodeMirror>(null);
  const [cursorPosition, setCursorPosition] = useState<number | null>(null);
  const [showCascader, setShowCascader] = useState(false);

  const setFocusOnInput = React.useCallback(
    cursorIdx => {
      const editor = (editorRef.current as any)?.editor as codemirror.Editor;
      if (cursorIdx === null || !editor) return;
      editor.setSelection({ line: 0, ch: cursorIdx });
      editor.focus();
    },
    [editorRef]
  );

  const onInsertVariable = React.useCallback(() => {
    onFocus(new Event("focus") as any);
    const editor = (editorRef.current as any)?.editor as codemirror.Editor;
    // when "Insert a variable" link is clicked, save cursor position
    setCursorPosition(
      typeof editor?.getCursor().ch === "number" ? editor?.getCursor().ch : value.length
    );
  }, [editorRef, value, onFocus]);

  // set focus after inserting variable
  useEffect(() => {
    setFocusOnInput(cursorPosition);
  }, [setFocusOnInput, cursorPosition]);

  // NOTE: Templates for nullable component properties may be null.
  // E.g. The `text_template` of a `SpaceLink` is optional and will
  // be set as null when persisted as an empty string.
  if (value === undefined || value === null) {
    value = mode === "jstl" ? "``" : "";
  }

  return (
    <div data-test={dataTest}>
      <SingleLineEditor
        ref={editorRef}
        value={value}
        mode={mode}
        minHeight={minHeight}
        placeholder={placeholder}
        hidePopover
        showError
        onChange={value => onChange(value)}
        onFocus={evt => {
          onFocus(evt);
        }}
        onBlur={onBlur}
      />
      <TemplateBindings>
        <BindingCascader
          options={bindingOptions}
          value=""
          popupVisible={showCascader}
          popupContainerFactory={popupContainerFactory}
          selectable={Object.values(BindingShape)}
          changeOnSelect
          onPopupVisibleChange={visible => {
            setShowCascader(visible);
          }}
          onClose={(path: string) => {
            let start = "";
            let val = "";
            setShowCascader(false);
            // When the popup is closed, insert the selected path into the template
            // If it is inserted onChange, each click in the cascader will insert path
            onFocus(new Event("focus") as any);
            if (mode === "jstl") {
              // get value without wrapping backticks so that cursorPosition is accurate
              // (the value without wrapping backticks is what's rendered in the editor)
              const strippedValue = getStrippedValue(value);
              start = `${strippedValue.slice(0, cursorPosition!)}$\{${path}}`;
              val = `\`${start}${strippedValue.slice(cursorPosition!)}\``;
            } else if (mode === "javascript") {
              start = `${value.slice(0, cursorPosition!)}${path}`;
              val = `${start}${value.slice(cursorPosition!)}`;
            }
            // Accomodate changes which are transformed by their external
            // consumer when this editor is being debounced.
            // 1. Do the change and force the debounce to flush
            // 2. Wait a render cycle for the changes to process
            // .  with the input blurred
            // 3. Once the changes have applied, focus and set the cursor
            // NOTE - This is way too coupled to withDebouncedValue
            if (onChangeImmediately) {
              onBlur(new Event("blur") as any);
              onChangeImmediately(val);
              setImmediate(() => {
                onFocus(new Event("focus") as any);
                setCursorPosition(start.length);
              });
            } else {
              onChange(val);
              onFocus(new Event("focus") as any);
              setCursorPosition(start.length);
            }
          }}
        >
          <InsertButton onClick={onInsertVariable} type="link">
            Insert a variable
          </InsertButton>
        </BindingCascader>
      </TemplateBindings>
    </div>
  );
}

export const DebouncedTemplateEditor = withDebouncedValue(TemplateEditor, {
  addOnChangeImmediately: true
});
