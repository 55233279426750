import React from "react";

import { useNavigate } from "react-router";

import InternalLogoImage from "../../../assets/logo_white.png";
import { Routes, Urls } from "../../../constants";
import { SizeNumeric } from "../../../cssConstants";
import ButtonNew from "../../common/ButtonNew/ButtonNew";
import useWindowDimensions from "../../common/hooks/useWindowDimensions";

import {
  AnchorButton,
  ButtonsContainer,
  Container,
  HeaderWrapper,
  Logo,
  MenuContainer,
  StyledAnchor,
  ToggleButton,
  ToggleIcon
} from "./styledComponents";

function Buttons() {
  const navigate = useNavigate();

  return (
    <>
      <AnchorButton type="button" href={Urls.DEMO} target="_blank" rel="noreferrer">
        Request Demo
      </AnchorButton>
      <ButtonNew type="primary" onClick={() => navigate(Routes.LOGIN)}>
        Sign in
      </ButtonNew>
    </>
  );
}

function ToggleMenu({ open, onClick }: { open: boolean; onClick: () => void }) {
  return (
    <ToggleButton onClick={onClick}>
      <ToggleIcon type={open ? "close" : "menu"} />
    </ToggleButton>
  );
}

function LogoLink() {
  return (
    <StyledAnchor href={Urls.HOME_PAGE} target="_blank" rel="noreferrer">
      <Logo src={InternalLogoImage} alt="Internal logo" />
    </StyledAnchor>
  );
}

export default function Header({
  className,
  showActions
}: {
  className?: string;
  showActions?: boolean;
}) {
  const { width } = useWindowDimensions();
  const [menuOpen, setMenuOpen] = React.useState(false);

  const smallScreen = width <= SizeNumeric.sm;
  const overlay = smallScreen && menuOpen;

  if (!showActions) {
    return (
      <HeaderWrapper overlay={false}>
        <Container className={className} overlay={false}>
          <LogoLink />
        </Container>
      </HeaderWrapper>
    );
  }

  return (
    <HeaderWrapper overlay={overlay}>
      <Container className={className} overlay={overlay}>
        <LogoLink />
        {!smallScreen ? (
          <ButtonsContainer>
            <Buttons />
          </ButtonsContainer>
        ) : (
          <ToggleMenu open={menuOpen} onClick={() => setMenuOpen(prev => !prev)} />
        )}
      </Container>
      {smallScreen && menuOpen && (
        <MenuContainer>
          <Buttons />
        </MenuContainer>
      )}
    </HeaderWrapper>
  );
}
