import React from "react";

import styled from "styled-components";

import { MAX_NESTED_DIMENSIONS } from "../../../../layout/constants";
import { ChildLayoutContext } from "../../../../layout/LayoutContext";
import SpaceComponent from "../../SpaceComponent";
import { SpaceTableComponentColumn } from "../useColumns";

import { SpaceCell } from "./AttributeCell";

interface Props {
  row: any;
  cell: SpaceCell<SpaceTableComponentColumn>;
}

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  max-width: ${MAX_NESTED_DIMENSIONS.width}px;
  max-height: ${MAX_NESTED_DIMENSIONS.height}px;
  /* !important required as the rule in the common table component that applies widths to the first child of tds has more specificity */
  width: 100% !important;
  height: 100%;

  & .spaceComponent {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: ${props => props.theme.spacerxs} 0;
    overflow: hidden;
    text-overflow: ellipsis;

    &:not(:last-child):not(:only-child) {
      margin-right: ${props => props.theme.spacerxs};
    }
  }
`;

export default function ComponentCell({
  cell: {
    column: { component, spaceApi }
  }
}: Props) {
  return (
    <Root>
      <ChildLayoutContext>
        <SpaceComponent
          spaceComponent={component}
          spaceApi={spaceApi}
          layoutConstraints={MAX_NESTED_DIMENSIONS}
        />
      </ChildLayoutContext>
    </Root>
  );
}
