import { useQuery } from "@apollo/react-hooks";
import { QueryHookOptions } from "@apollo/react-hooks/lib/types";
import gql from "graphql-tag";

import { AutomationNode } from "./AllAutomations";
import { AUTOMATION_FRAGMENT } from "./common";

export interface Data {
  node: AutomationNode;
}

export const GET_AUTOMATION_BY_ID = gql`
  query GetAutomation($id: ID!) {
    node(id: $id) {
      ... on AutomationNode {
        ...AutomationFragment
      }
    }
  }
  ${AUTOMATION_FRAGMENT}
`;

export const useAutomationById = (options?: QueryHookOptions<Data>) => {
  return useQuery<Data>(GET_AUTOMATION_BY_ID, options);
};
