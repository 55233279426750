import React from "react";

import { useMutation } from "@apollo/react-hooks";
import { Col, Row, Form, Icon } from "antd";
import { FormComponentProps } from "antd/lib/form";
import gql from "graphql-tag";
import TagManager from "react-gtm-module";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { useConfigContext } from "../../ConfigContext";
import { Routes } from "../../constants";
import { publicClient } from "../../graphql";
import { AuthLink } from "../auth/styledComponents";
import DashText from "../common/DashText";
import { GoogleLoginButton, FormItem } from "../common/StyledComponents";
import LicenseNoSeatsWarning from "../license/LicenseNoSeatsWarning";
import { VALIDATION_MESSAGES } from "../util/ClientValidator";

import CheckEmail from "./CheckEmail";
import RequestInvite from "./RequestInvite";
import { LinkPrompt, StyledSearch } from "./styledComponents";

const CREATE_ORGANIZATION_MUTATION = gql`
  mutation CreateOrganization($email: String!) {
    createOrganization(email: $email) {
      status
    }
  }
`;

const FormContainer = styled.div`
  .ant-input-search-enter-button
    input
    + .ant-input-group-addon
    .ant-input-search-button {
    width: 90px;
  }

  .ant-input-search-button[disabled],
  .ant-input-search-button[disabled]:hover {
    background-color: #a560eb;
    border-color: #a560eb;
    color: #fff;
  }
`;

const FormFooter = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: ${props => props.theme.spacerxs};
`;

interface Fields {
  email: string;
}
type Props = FormComponentProps<Fields>;

interface EmailProps {
  className?: string;
  confirmEmail: string;
  setConfirmEmail: (email: string) => void;
  requestEmail: string;
  setRequestEmail: (email: string) => void;
}

function CreateOrganizationForm(props: Props & EmailProps) {
  const { confirmEmail, setConfirmEmail, requestEmail, setRequestEmail } = props;
  const { isOnPrem, license } = useConfigContext();
  const { isGoogleOAuthEnabled } = useConfigContext();

  const [createOrganization, { loading: submitting }] = useMutation(
    CREATE_ORGANIZATION_MUTATION,
    {
      client: publicClient,
      onCompleted: data => {
        const email = props.form.getFieldValue("email");
        const { status } = data.createOrganization;
        switch (status) {
          case "SUCCESS":
            setConfirmEmail(email);
            TagManager.dataLayer({ dataLayer: { event: "emailSignup" } });
            break;
          case "ORGANIZATION_EXISTS":
            setRequestEmail(email);
            break;
          default:
            const messages: Record<string, string> = {
              INVALID_EMAIL: "Please provide a valid work email address.",
              USER_EXISTS: "A user with this email already exists, please log in.",
              NO_SEATS: "You have used all available license seats."
            };
            const error = new Error(
              messages[status] || "An unknown error occurred, please try again."
            );
            props.form.setFields({ email: { value: email, errors: [error] } });
        }
      }
    }
  );

  const location = useLocation();
  const navigate = useNavigate();

  const urlParams = new URLSearchParams(location.search || "");
  const ssoEmail = urlParams.get("ssoEmail");
  const ssoRequestInvite = urlParams.get("ssoRequestInvite") === "true";

  if (ssoRequestInvite && ssoEmail && ssoEmail !== requestEmail) {
    setRequestEmail(ssoEmail);
    navigate(Routes.SIGNUP);
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    props.form.validateFields((errors: any, values: Fields) => {
      if (errors) {
        return;
      }

      createOrganization({ variables: { email: values.email } });
    });
  };

  if (confirmEmail) {
    return <CheckEmail email={confirmEmail} />;
  }

  if (requestEmail) {
    return (
      <RequestInvite email={requestEmail} onComplete={() => setRequestEmail("")} />
    );
  }

  return (
    <FormContainer className={props.className}>
      <Col>
        {isOnPrem && license && <LicenseNoSeatsWarning license={license} />}
        <Row>
          <a href={Routes.SIGNUP_GOOGLE_AUTH}>
            <GoogleLoginButton
              disabled={!isGoogleOAuthEnabled || (isOnPrem && !license?.hasSeats)}
            >
              Sign up with Google
            </GoogleLoginButton>
          </a>
        </Row>
        <DashText>or</DashText>
        <Row>
          <Form onSubmit={handleSubmit}>
            <FormItem>
              {props.form.getFieldDecorator("email", {
                validateTrigger: "onSubmit",
                rules: [
                  {
                    type: "email",
                    message: VALIDATION_MESSAGES.email
                  },
                  {
                    required: true,
                    whitespace: true,
                    message: VALIDATION_MESSAGES.requiredField
                  }
                ]
              })(
                <StyledSearch
                  enterButton={submitting ? <Icon type="loading" /> : "Sign up"}
                  placeholder="Enter your work email"
                  disabled={submitting || (isOnPrem && !license?.hasSeats)}
                  onSearch={(value: string, e: any) => handleSubmit(e)}
                  size="large"
                />
              )}
            </FormItem>

            <FormFooter>
              <LinkPrompt>Have an account?</LinkPrompt>
              <AuthLink to={Routes.LOGIN}>Sign in</AuthLink>
            </FormFooter>
          </Form>
        </Row>
      </Col>
    </FormContainer>
  );
}

export default Form.create<Props & EmailProps>({
  name: "createOrganizationForm"
})(CreateOrganizationForm);
