import React, { useState } from "react";

import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import styled from "styled-components";

import { publicClient } from "../../graphql";
import { AuthCTAButton } from "../auth/styledComponents";
import Message from "../common/Message";

export const RESEND_EMAIL_CONFIRMATION_MUTATION = gql`
  mutation ResendEmailConfirmation($email: String!) {
    resendEmailConfirmation(email: $email) {
      ok
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const CheckEmailButton = styled(AuthCTAButton)`
  &:disabled {
    background-color: ${props => props.theme.surfaceTertiary};
    opacity: 1;

    &:hover {
      background-color: ${props => props.theme.surfaceTertiary};
      opacity: 1;
    }
  }
`;

interface Props {
  email: string;
}

const CheckEmail = ({ email }: Props) => {
  const [showResend, setShowResend] = useState<boolean>(true);

  const [resendEmailConfirmation] = useMutation(RESEND_EMAIL_CONFIRMATION_MUTATION, {
    client: publicClient,
    variables: { email },
    onCompleted: () => {
      setShowResend(false);
      Message.success("New verification link sent.");
    }
  });

  return (
    <ButtonWrapper>
      <CheckEmailButton
        type={"brand"}
        block
        disabled={!showResend}
        onClick={() => resendEmailConfirmation()}
      >
        {showResend ? "Send a new verification link" : "Sent!"}
      </CheckEmailButton>
    </ButtonWrapper>
  );
};

export default CheckEmail;
