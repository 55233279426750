import React from "react";

import { useQuery } from "@apollo/react-hooks";
import { Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { v4 as uuid } from "uuid";

import { FunctionScope } from "../../../../../../../types";
import FunctionPicker from "../../../../../../common/FunctionPicker";
import usePaths from "../../../../../../common/hooks/usePaths";
import { ErrorIcon } from "../../../../../../common/Icons";
import { SpaceConfigAction } from "../../../../../types";
import { useStableSpaceContext } from "../../../../SpaceContext";
import { useBranchContext } from "../../../../SpaceContext/BranchContext";
import ValidationError from "../../ComponentConfigContext/ValidationError";
import { ConfigSection, ConfigPanelPopper, Header } from "../../ConfigPanel";
import {
  useSpaceConfigPanelContext,
  ConfigPanelActionTypes
} from "../../ConfigPanel/ConfigPanelContext";
import { Field, IconButton } from "../../ConfigPanel/styledComponents";
import { FUNCTION_WITH_PARAMETERS, FunctionWithParametersQuery } from "../queries";
import {
  SubmittableComponentConfigState,
  selectMergedFunctions,
  MergedFunction
} from "../reducer/reducer";

interface FunctionConfigProps {
  categories?: string[];
  dispatch: React.Dispatch<SpaceConfigAction>;
  integrations?: string[];
  state: SubmittableComponentConfigState;
  title?: string;
  allowPipelineFunctions?: boolean;
}

const PopperContent = styled.div`
  padding: ${props => props.theme.spacerlg};
`;

const TerseFunctionItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${props => props.theme.spacermd};
  margin-bottom: ${props => props.theme.spacermd};
  border: solid 1px ${props => props.theme.tableBorderColor};
  border-radius: ${props => props.theme.borderRadiusmd};
`;

const TerseFunctionNumber = styled.div`
  font-size: ${props => props.theme.rowFontSize};
`;

const TerseFunctionTitle = styled.div`
  color: ${props => props.theme.textColorMid};
  font-style: italic;
  font-size: ${props => props.theme.defaultFontSize};
`;

const FunctionOptions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: ${props => props.theme.spacersm};
`;

const AppDataOption = styled.a`
  display: block;
  padding: ${props => props.theme.spacerxs} 0;
  color: ${props => props.theme.textColorMid};

  &:hover {
    color: ${props => props.theme.primaryColor};
    text-decoration: underline;
  }
`;

export default function FunctionConfig({
  categories,
  dispatch,
  integrations,
  state,
  title,
  allowPipelineFunctions = false
}: FunctionConfigProps) {
  const configPanelContext = useSpaceConfigPanelContext();
  const { spaceSlug } = useStableSpaceContext();
  const { branch } = useBranchContext();
  const paths = usePaths();
  const navigate = useNavigate();
  const { draftComponent, pendingFunctions } = state;
  const fnEdges = draftComponent.functions.edges;
  const mergedFunctions = React.useMemo(() => {
    return selectMergedFunctions(state);
  }, [state]);
  const { dispatch: configPanelDispatch } = configPanelContext;
  const showAddButton =
    (fnEdges.length > 0 || mergedFunctions.length > 1) &&
    !fnEdges[0].node.isPipeline &&
    state.draftComponent.type !== "FUNCTION_BULK_IMPORT";
  const addFunction = () => {
    dispatch({
      type: "ADD_PENDING_FUNCTION"
    });
    configPanelDispatch({
      type: ConfigPanelActionTypes.OPEN_POPPER,
      payload: { popperIdentifier: `fnPicker${mergedFunctions.length}` }
    });
  };

  const fnLoaders = pendingFunctions.map((pf, i) => (
    <PendingFunctionLoader fnId={pf.id} key={String(pf.id!) + i} dispatch={dispatch} />
  ));

  const fnWatchers = mergedFunctions
    .filter(mf => mf.type === "LOADED")
    .map((mf, i) => (
      <LoadedFunctionWatcher
        key={String(mf.fn!.id) + i}
        fnId={mf.fn!.id}
        dispatch={dispatch}
      />
    ));

  if (mergedFunctions.length <= 1) {
    const fn = mergedFunctions[0]?.fn;
    return (
      <ConfigSection
        title={title || "Function"}
        data-test="data"
        onAdd={showAddButton ? addFunction : undefined}
      >
        <Field>
          <FunctionPicker
            categories={categories}
            functionId={fn?.id || null}
            functionScope={FunctionScope.Submittable}
            integrations={integrations}
            allowPipelineFunctions={allowPipelineFunctions}
            onChange={functionId => {
              dispatch({
                type: "SET_FUNCTION_ID",
                payload: { functionId: functionId || "", index: 0 }
              });
            }}
            onEditFunctionClick={props =>
              paths.openSpaceFunctionConfig(spaceSlug, props)
            }
          />
          <ValidationError field="FUNCTION" />
        </Field>
        {fnLoaders}
        {fnWatchers}
      </ConfigSection>
    );
  }

  function getFnConfig(mf: MergedFunction) {
    if (mf.type === "PENDING") return undefined;
    return mf.fn;
  }

  function getEditFnPath(mf: MergedFunction) {
    if (mf.type === "PENDING" || mf.type === "MISSING")
      throw new Error("Expected loaded function.");
    return paths.getSpaceFunctionConfig(spaceSlug, {
      dataSourceId: mf.fn.dataSource?.id,
      functionId: mf.fn.id
    });
  }

  const appDataPath = paths.getEditSpace(spaceSlug, {
    menu: [{ type: "APP_DATA" }],
    branch
  });

  const newFnPath = paths.getSpaceFunctionConfig(spaceSlug, {
    pendingFunctionId: uuid()
  });

  return (
    <ConfigSection
      title="Function"
      data-test="data"
      id="functionConfigSection"
      onAdd={addFunction}
    >
      {mergedFunctions.map((mf, i) =>
        mf.type !== "MISSING" ? (
          <TerseFunctionItem id={`fn${i}`} key={`fn${i}`}>
            <div>
              <TerseFunctionNumber>Function {i + 1}</TerseFunctionNumber>
              <TerseFunctionTitle>
                {mf.fn !== null && "title" in mf.fn ? mf.fn.title : "Pending"}
              </TerseFunctionTitle>
            </div>
            <IconButton
              title="Edit function"
              icon="setting"
              type="link"
              onClick={() => {
                configPanelDispatch({
                  type: ConfigPanelActionTypes.OPEN_POPPER,
                  payload: { popperIdentifier: `fnPicker${i}` }
                });
              }}
            />
            <ConfigPanelPopper
              popperId={`fnPicker${i}`}
              popperReferenceElement={document.getElementById(`fn${i}`) || undefined}
              onCancel={() =>
                configPanelDispatch({
                  type: ConfigPanelActionTypes.CLOSE_POPPER
                })
              }
            >
              <PopperContent>
                <Header>
                  <h1>Select Function</h1>
                </Header>
                <Field>
                  <FunctionPicker
                    functionId={mf.fn.id || null}
                    functionScope={FunctionScope.Submittable}
                    onChange={functionId => {
                      dispatch({
                        type: "SET_FUNCTION_ID",
                        payload: { functionId: functionId || "", index: i }
                      });
                    }}
                  />
                </Field>
                <FunctionOptions>
                  {getFnConfig(mf)?.isUserGenerated && (
                    <IconButton
                      title="Edit this function's configuration."
                      icon="edit"
                      type="link"
                      href={getEditFnPath(mf)}
                    />
                  )}
                  <IconButton
                    title="Remove this function from pipeline."
                    icon="delete"
                    type="link"
                    onClick={() => {
                      dispatch({
                        type: "REMOVE_FUNCTION",
                        payload: { index: i }
                      });
                      configPanelDispatch({
                        type: ConfigPanelActionTypes.CLOSE_POPPER
                      });
                    }}
                  />
                </FunctionOptions>
                <hr />
                <AppDataOption
                  onClick={() => {
                    navigate(newFnPath);
                    configPanelDispatch({
                      type: ConfigPanelActionTypes.CLOSE_POPPER
                    });
                  }}
                >
                  Create a new function
                </AppDataOption>
                <AppDataOption
                  onClick={() => {
                    navigate(appDataPath);
                    configPanelDispatch({
                      type: ConfigPanelActionTypes.CLOSE_POPPER
                    });
                  }}
                >
                  Add a new URL parameter
                </AppDataOption>
              </PopperContent>
            </ConfigPanelPopper>
          </TerseFunctionItem>
        ) : (
          <Tooltip
            title="This function has been deleted from the system and must be removed."
            getPopupContainer={trigger => trigger.parentNode as HTMLElement}
          >
            <TerseFunctionItem id={`fn${i}`} key={`fn${i}`}>
              <div>
                <TerseFunctionNumber>Function {i + 1}</TerseFunctionNumber>
                <TerseFunctionTitle>Missing function</TerseFunctionTitle>
              </div>

              <ErrorIcon />

              <IconButton
                title="Remove function"
                icon="delete"
                type="link"
                onClick={() => {
                  dispatch({
                    type: "REMOVE_FUNCTION",
                    payload: { index: i }
                  });
                }}
              />
            </TerseFunctionItem>
          </Tooltip>
        )
      )}
      <ValidationError field="FUNCTION" />
      {fnLoaders}
      {fnWatchers}
    </ConfigSection>
  );
}

type PendingFunctionLoaderProps = {
  fnId: string | null;
  dispatch: React.Dispatch<SpaceConfigAction>;
};
function PendingFunctionLoader({ fnId, dispatch }: PendingFunctionLoaderProps) {
  const { data, error } = useQuery<FunctionWithParametersQuery>(
    FUNCTION_WITH_PARAMETERS,
    {
      variables: { functionId: fnId },
      skip: fnId === null
    }
  );
  if (error) throw error;

  React.useEffect(() => {
    if (!data?.node) return;
    dispatch({
      type: "LOAD_SUBMITTABLE_FUNCTION",
      payload: {
        functionNode: data.node
      }
    });
  }, [dispatch, data]);

  return null;
}

type LoadedFunctionWatcherProps = {
  fnId: string | null;
  dispatch: React.Dispatch<SpaceConfigAction>;
};
/*
  LoadedFunctionWatcher
  This component is responsible for dispatching "UPDATE_FUNCTION_DEFINITION" actions
  if a loaded function of this submittable component is updated in Apollo cache allowing
  the reducer to sync its copy.
*/
function LoadedFunctionWatcher({ fnId, dispatch }: LoadedFunctionWatcherProps) {
  const { data } = useQuery<FunctionWithParametersQuery>(FUNCTION_WITH_PARAMETERS, {
    variables: { functionId: fnId },
    fetchPolicy: "cache-first"
  });
  React.useEffect(() => {
    if (!data?.node) return;
    dispatch({
      type: "UPDATE_FUNCTION_DEFINITION",
      payload: {
        functionNode: data.node
      }
    });
  }, [data, dispatch]);
  return null;
}
