import { Modal } from "antd";
import styled, { css } from "styled-components";

const commonModalStyles = css`
  .ant-modal-content {
    max-height: 100vh;
    min-height: 200px;
    overflow: auto;
  }
  .ant-modal-close {
    top: 10px;
    right: 10px;
    .ant-modal-close-x {
      height: 16px;
      width: 16px;
      line-height: 16px;
    }
  }
`;

export const ViewModeModal = styled(Modal)<{ height: string }>`
  .ant-modal-content {
    height: ${props => props.height};
  }
  ${commonModalStyles}
`;

export const EditModeModal = styled(Modal)<{ height: string }>`
  .ant-modal-content {
    height: ${props => props.height};
  }
  ${commonModalStyles}

  .ant-modal-mask {
    top: ${props => props.theme.headerHeight};
    right: ${props => props.theme.configPanelWidth};
  }
`;
