import { ScmSyncStatus } from "../../../../../types";
import { assertNever } from "../../../../util/assertNever";

export enum ActionType {
  BEGIN_SYNC,
  LOAD_SYNC_STATUS,
  SYNC_FINISHED
}

interface BeginSync {
  type: ActionType.BEGIN_SYNC;
}

interface LoadSyncStatus {
  type: ActionType.LOAD_SYNC_STATUS;
  payload: ScmSyncStatus;
}

interface SyncFinished {
  type: ActionType.SYNC_FINISHED;
}

type Action = BeginSync | LoadSyncStatus | SyncFinished;

export interface State {
  initializing: boolean;
  isChecking: boolean;
  isSyncing: boolean;
  syncImmediately: boolean;
  syncStatus: null | ScmSyncStatus;
}

export const getInitialState = (): State => ({
  initializing: true,
  isChecking: true,
  isSyncing: false,
  syncImmediately: false,
  syncStatus: null
});

export default function reducer(state: State, action: Action): State {
  switch (action.type) {
    case ActionType.BEGIN_SYNC:
      return {
        ...state,
        isSyncing: true
      };
    case ActionType.LOAD_SYNC_STATUS:
      const shouldSync = action.payload === ScmSyncStatus.NOT_CURRENT;

      return {
        ...state,
        initializing: shouldSync,
        isChecking: false,
        isSyncing: shouldSync,
        syncImmediately: shouldSync,
        syncStatus: action.payload
      };
    case ActionType.SYNC_FINISHED:
      return {
        ...state,
        initializing: false,
        isSyncing: false
      };
    default:
      return assertNever(action);
  }
}
