import React from "react";

import { Icon } from "antd";

import { H6 } from "../../../common/StyledComponents";

import {
  Container,
  ErrorBody,
  ErrorIconWrapper,
  LinkButton,
  MessagesContainer
} from "./styledComponents";

interface Props {
  title: string;
  body: string;
  className?: string;
  actionText?: string;
  onActionClick?: () => void;
}

export default function BillingAlert({
  title,
  body,
  actionText,
  onActionClick,
  className
}: Props) {
  return (
    <Container className={className}>
      <ErrorIconWrapper>
        <Icon type="exclamation-circle" theme="filled" />
      </ErrorIconWrapper>
      <MessagesContainer>
        <H6>{title}</H6>
        <ErrorBody>{body}</ErrorBody>
      </MessagesContainer>
      {actionText && <LinkButton onClick={onActionClick}>{actionText}</LinkButton>}
    </Container>
  );
}
