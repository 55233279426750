import React, { Dispatch, useEffect, useReducer } from "react";

import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";

import { ReturnSchema } from "../../../../constants";
import {
  BindingShape,
  SpaceComponentNode,
  SpaceComponentObject,
  SpaceComponentPackage
} from "../../../../types";
import {
  defaultContextValue,
  SpaceConfigContext
} from "../../../spaces/SpaceConfig/SpaceConfigContext/SpaceConfigContext";
import { makeComponent } from "../../../spaces/SpaceConfig/SpaceConfigContext/useSpaceConfig";
import {
  SpaceConfigState,
  TreeNode
} from "../../../spaces/SpaceConfig/SpaceConfigContext/useSpaceConfig/reducer/reducer";
import { ParameterType } from "../../../spaces/SpaceRoot/SpaceComponent/common/useFuncParams";
import { ComponentContextContainer } from "../../../spaces/SpaceRoot/SpaceComponent/contexts/ComponentContext";
import { StableSpaceContextContainer } from "../../../spaces/SpaceRoot/SpaceContext";
import {
  collectComponents,
  createSpaceContextParamComponent,
  EmbedSpaceContextProvider,
  SpaceContextParams
} from "../../../spaces/SpaceRoot/SpaceContext/SpaceContext";
import { useSpaceComponentPackage } from "../../../spaces/SpaceRoot/SpaceContext/StableSpaceContext";
import { Action, ActionType, FormState, InternalField, Parameter } from "../reducer";
import { FunctionParameterNode } from "../types";

import ParameterRow from "./ParameterRow";
import * as styled from "./styledComponents";

interface Props {
  title: string;
  state: FormState;
  dispatch: Dispatch<Action>;
  allowedParameterTypes: ParameterType[];
  allowedBindingShapes: BindingShape[];
}

export default function FormBuilder({
  state,
  dispatch,
  title,
  allowedParameterTypes,
  allowedBindingShapes
}: Props) {
  const contextParams = state.schema;

  const formComponent = React.useMemo(() => {
    return makeComponent(new Set(), {
      id: "",
      type: "FUNCTION_MODAL_FORM",
      slug: "modalForm1",
      functions: {
        edges: [
          {
            node: {
              id: "",
              name: "client_generated",
              returnSchema: ReturnSchema.UNKNOWN,
              functionParameters: {
                edges: (state.func?.functionParameters.edges || []).map(e => ({
                  node: {
                    id: "",
                    ...e.node
                  }
                }))
              }
            }
          }
        ]
      },
      properties: {
        input_parameters: state.parameters.map(param => ({
          ...param,
          component_slug: `ephemeral__${param.name}`
        }))
      }
    });
  }, [state.func, state.parameters]);

  const formComponentNode: SpaceComponentNode = {
    ...formComponent,
    id: "", // This is here to keep compiler happy, it is also in memo above.
    container: null,
    componentTreeNodes: []
  };

  const inputComponentNodes: SpaceComponentNode[] = state.parameters.map(parameter => {
    return {
      id: parameter.name,
      name: parameter.name,
      slug: `ephemeral__${parameter.name}`,
      container: formComponentNode,
      componentTreeNodes: [],
      type: parameter.componentType || "CUSTOM_FIELD",
      functions: { edges: [] },
      properties: parameter.componentProperties
    };
  });

  formComponentNode.componentTreeNodes = inputComponentNodes;

  const getFunctionParameter = (name: string) => {
    return state.func?.functionParameters.edges
      .map(({ node }) => node)
      .find(node => {
        return node.name === name;
      });
  };

  const onDragEnd = (result: DropResult) => {
    if (result.reason !== "DROP" || !result.destination) {
      return;
    }
    dispatch({
      type: ActionType.UPDATE_PARAMETER_ORDER,
      payload: {
        sourceIndex: result.source.index,
        destinationIndex: result.destination.index
      }
    });
  };

  // partition fields because internal parameters cannot be reordered
  const [internalFields, customFields]: [Parameter[], Parameter[]] =
    React.useMemo(() => {
      if (!state.internalFields.length) {
        return [[], state.parameters];
      }
      return state.parameters.reduce(
        ([internal, custom], param) => {
          if (state.internalFields.map(field => field.name).includes(param.name)) {
            return [[...internal, param], custom];
          } else {
            return [internal, [...custom, param]];
          }
        },
        [[] as Parameter[], [] as Parameter[]]
      );
    }, [state.parameters, state.internalFields]);

  if (!state.parameters.length) {
    return (
      <>
        <styled.Title>{title}</styled.Title>
        <styled.EmptyContainer>No parameters to configure</styled.EmptyContainer>
      </>
    );
  }

  return (
    <EmbedSpaceContextProvider
      componentTreeNodes={[formComponentNode, ...inputComponentNodes]}
      contextParams={{}}
    >
      <StableSpaceContextContainer spaceSlug="ephemeral">
        <styled.Title>{title}</styled.Title>
        <styled.Container>
          <styled.Row hideBorder>
            <styled.DragHandleHeader></styled.DragHandleHeader>
            <styled.IncludedHeader>Enable</styled.IncludedHeader>
            <styled.NameHeader>Field</styled.NameHeader>
            <styled.ValueHeader>Value</styled.ValueHeader>
            <styled.ChevronHeader />
          </styled.Row>
          {internalFields.map((parameter, i) => {
            return (
              <WrappedParameterRow
                /* include componentType in key so shim remounts on change */
                key={`${parameter.name}__${parameter.componentType}`}
                parameter={parameter}
                index={i}
                contextParams={contextParams}
                formComponent={formComponent}
                dispatch={dispatch}
                internalFields={state.internalFields}
                functionParameter={getFunctionParameter(parameter.name)}
                allowedParameterTypes={allowedParameterTypes}
                allowedBindingShapes={allowedBindingShapes}
              />
            );
          })}
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="parameterList">
              {(provided, _) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {customFields.map((parameter, i) => {
                    return (
                      <WrappedParameterRow
                        /* include componentType in key so shim remounts on change */
                        key={`${parameter.name}__${parameter.componentType}`}
                        parameter={parameter}
                        index={i}
                        contextParams={contextParams}
                        formComponent={formComponent}
                        dispatch={dispatch}
                        internalFields={state.internalFields}
                        functionParameter={getFunctionParameter(parameter.name)}
                        allowedParameterTypes={allowedParameterTypes}
                        allowedBindingShapes={allowedBindingShapes}
                      />
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </styled.Container>
      </StableSpaceContextContainer>
    </EmbedSpaceContextProvider>
  );
}

const WrappedParameterRow = ({
  parameter,
  index,
  contextParams,
  formComponent,
  dispatch,
  internalFields,
  functionParameter,
  allowedParameterTypes,
  allowedBindingShapes
}: {
  parameter: Parameter;
  index: number;
  contextParams: SpaceContextParams;
  formComponent: SpaceComponentObject;
  dispatch: Dispatch<Action>;
  internalFields: InternalField[];
  functionParameter: FunctionParameterNode | undefined;
  allowedParameterTypes: ParameterType[];
  allowedBindingShapes: BindingShape[];
}) => {
  const MaybeComponentConfigContext = !!parameter.componentType
    ? ComponentConfigContextShim
    : NoopComponent;

  return (
    <MaybeComponentConfigContext
      parameter={parameter}
      contextParams={contextParams}
      formComponent={formComponent}
      formBuilderDispatch={dispatch}
    >
      <ParameterRow
        index={index}
        parameter={parameter}
        contextParams={contextParams}
        internalFields={internalFields}
        functionParameter={functionParameter}
        dispatch={dispatch}
        allowedParameterTypes={allowedParameterTypes}
        allowedBindingShapes={allowedBindingShapes}
      />
    </MaybeComponentConfigContext>
  );
};

const NoopComponent = ({ children }: { children: React.ReactNode }) => <>{children}</>;

function ComponentConfigContextShim({
  parameter,
  formBuilderDispatch,
  contextParams,
  formComponent,
  children
}: {
  parameter: Parameter;
  formBuilderDispatch: Dispatch<Action>;
  contextParams: SpaceContextParams;
  formComponent: SpaceComponentObject;
  children: React.ReactNode;
}) {
  const pkg: SpaceComponentPackage = useSpaceComponentPackage(parameter.componentType!);
  const configState = createComponentConfigState(parameter, pkg, formComponent);
  const rootSpace = configState.spaces.get(configState.rootSpaceSlug)!;
  const initialComponentConfigState = {
    ...rootSpace.components.get(`ephemeral__${parameter.name}`)!,
    type: parameter.componentType!
  };
  const [state, dispatch] = useReducer(
    pkg.componentConfigReducer,
    initialComponentConfigState
  );

  useEffect(() => {
    formBuilderDispatch({
      type: ActionType.UPDATE_PARAMETER,
      payload: {
        name: parameter.name,
        componentProperties: state.draftComponent.properties
      }
    });
  }, [state, parameter.name, formBuilderDispatch]);

  const componentTree = React.useMemo(() => {
    const contextParamComponents = Object.entries(contextParams).map(([key, value]) =>
      createSpaceContextParamComponent(key, value)
    );
    return collectComponents([
      ...contextParamComponents,
      formComponent,
      state.draftComponent
    ]);
  }, [contextParams, state.draftComponent, formComponent]);

  if (!pkg) {
    return <>{children}</>;
  }

  return (
    <SpaceConfigContext.Provider
      value={{
        ...defaultContextValue,
        componentTree,
        state: configState,
        dispatch,
        getComponentSpaceConfig: () => {
          return configState.spaces.get(configState.rootSpaceSlug)!;
        },
        getComponentConfig: (slug: string) => {
          return configState.spaces
            .get(configState.rootSpaceSlug)!
            .components.get(slug)!;
        }
      }}
    >
      <ComponentContextContainer component={state.draftComponent}>
        {children}
      </ComponentContextContainer>
    </SpaceConfigContext.Provider>
  );
}

function createComponentConfigState(
  parameter: Parameter,
  pkg: SpaceComponentPackage,
  formComponent: SpaceComponentObject
): SpaceConfigState {
  const component = makeComponent(new Set(), {
    type: parameter.componentType!,
    slug: `ephemeral__${parameter.name}`,
    properties: parameter.componentProperties
  });
  const treeRoot: TreeNode = {
    slug: null,
    container: null,
    type: null,
    treeNodes: []
  };
  const formNode: TreeNode = {
    slug: formComponent.slug,
    container: treeRoot,
    type: "FUNCTION_MODAL_FORM",
    treeNodes: []
  };
  const inputNode: TreeNode = {
    slug: component.slug,
    container: formNode,
    type: parameter.componentType!,
    treeNodes: []
  };
  formNode.treeNodes.push(inputNode);
  treeRoot.treeNodes.push(formNode);
  const initialState = pkg.getInitialDraftState
    ? pkg.getInitialDraftState(component)
    : { draftComponent: component };

  return {
    ...defaultContextValue.state,
    rootSpaceSlug: "ephemeral_space",
    spaces: new Map([
      [
        "ephemeral_space",
        {
          slug: "ephemeral_space",
          name: "Ephemeral Space",
          parameters: [],
          tree: treeRoot,
          elementLayouts: new Map(),
          versionId: "ephemeral_version",
          scmIsCurrent: false,
          scmStatus: null,
          scmSyncStatus: null,
          branchCount: 0,
          components: new Map([
            [
              `ephemeral__${parameter.name}`,
              {
                ...initialState,
                draftComponent: {
                  ...initialState.draftComponent,
                  container: formNode
                },
                type: parameter.componentType!
              }
            ],
            [
              formComponent.slug,
              {
                draftComponent: formComponent,
                type: formComponent.type
              }
            ]
          ])
        }
      ]
    ])
  };
}
