import { Form as AntForm } from "antd";
import styled from "styled-components";

export const Form = styled(AntForm)`
  margin-top: 19px;

  .ant-form-explain {
    color: ${props => props.theme.errorColor};
  }
`;
