import { camelCase } from "lodash";
import { v4 as uuid } from "uuid";

import { AttributeTypes, ReturnSchema } from "../../../../constants";
import {
  BaseFunctionName,
  BaseFunctionNodeBasic,
  FunctionNode,
  FunctionNodeBasic,
  FunctionParameterInput,
  FunctionParameterNode,
  InputParameter,
  Metadata,
  ParameterType
} from "../../../../types";
import { toGlobalId } from "../../../util/graphql";
import { HttpMethods, RequestBodyType } from "../forms/constants";
import { PipelineStepType } from "../forms/pipeline/constants";
import { DataSourceNodeWithFunctions } from "../forms/types";
import {
  HttpBaseFunctionParameterMapping,
  HttpFunctionMetadata,
  Identifier,
  IdentifierMap,
  ReservedListIdentifierMap,
  ReservedListParameter
} from "../index";
import { EditorSupport, SupportedIntegration } from "../support";

import {
  ApiCondition,
  Conditional,
  ConditionActionType,
  EditorFunctionNode,
  PipelineParameterInput,
  PipelineStep,
  PipelineStepInput
} from "./queries";
import { FunctionState, State } from "./reducer";

export const defaultBaseFunction = (
  dataSource: DataSourceNodeWithFunctions<FunctionNodeBasic>
) => {
  const integration = dataSource?.integration;
  if (!integration) return;

  const functionName =
    EditorSupport[integration as SupportedIntegration].defaultFunctionName;
  if (!functionName) return;

  const fn = findFunction(dataSource, functionName);
  if (!fn) {
    throw new Error(
      `useFunctionEditor: could not find default function for datasource: ${dataSource.id}`
    );
  }
  return fn;
};

export const defaultBaseFunctionParameterMapping = (
  name: BaseFunctionName
): object | string => {
  switch (name) {
    case BaseFunctionName.REQUEST:
      const m: HttpBaseFunctionParameterMapping = {
        method: `"${HttpMethods.Get}"`,
        path: "``",
        header: "{}",
        query: "{}"
      };
      return m;
    case BaseFunctionName.EXECUTE_PIPELINE:
      return {
        payload: {}
      };
    default:
      return {};
  }
};

export const defaultMetadata = (name: BaseFunctionName) => {
  switch (name) {
    case BaseFunctionName.INSERT:
    case BaseFunctionName.UPDATE:
    case BaseFunctionName.DELETE:
    case BaseFunctionName.EXECUTE_PIPELINE:
      return { categories: ["mutation"] };
    case BaseFunctionName.REQUEST:
      const metadata: HttpFunctionMetadata = {
        categories: ["mutation"],
        request_type: RequestBodyType.None
      };
      return metadata;
    default:
      return {};
  }
};

export const METADATA_REDUCER_HTTP_STATUS_CODE_ERROR = `const status = metadata.http.response.status;
if (status < 200 || status >= 300) {
    throw new Error("Server responded with " + status);
}`;

export const METADATA_REDUCER_GRAPHQL_ERROR = `if (data && (typeof data === "object") && data.errors && data.errors.length) {
  const errorMessage = data.errors.filter(err => !!err.message)
                                  .map(err => err.message)
                                  .join(",") || "Data source response error";
  throw new Error(errorMessage);
}`;

export const METADATA_REDUCER_SQL_QUERY = "{ page: { hasNext: false } }";
export const METADATA_REDUCER_DEFAULT = "metadata";

export const defaultMetadataReducer = (
  name: BaseFunctionName,
  bodyType?: RequestBodyType
) => {
  switch (name) {
    case BaseFunctionName.REQUEST:
      let reducer = METADATA_REDUCER_HTTP_STATUS_CODE_ERROR;

      // GraphQL should have errors on data when an error occurs
      // https://spec.graphql.org/October2021/#sec-Errors
      if (bodyType === RequestBodyType.GraphQL) {
        // Add two newlines for nicer formatting
        reducer += `\n\n${METADATA_REDUCER_GRAPHQL_ERROR}`;
      }

      return reducer;
    case BaseFunctionName.SQL_QUERY:
      return METADATA_REDUCER_SQL_QUERY;
    default:
      return METADATA_REDUCER_DEFAULT;
  }
};

export const defaultReturnSchema = (name: BaseFunctionName) => {
  switch (name) {
    case BaseFunctionName.QUERY:
    case BaseFunctionName.AGGREGATE:
      return ReturnSchema.OBJECT_ARRAY;
    default:
      return ReturnSchema.UNKNOWN;
  }
};

export const findFunction = (
  dataSource: DataSourceNodeWithFunctions<FunctionNodeBasic>,
  name: string
) => {
  if (!dataSource) return;
  const fn = dataSource.functions.edges.find(edge => edge.node.name === name);
  return fn?.node;
};

export const toParameter = (identifier: Identifier): FunctionParameterInput => {
  const reserved = ReservedListIdentifierMap[identifier.name as ReservedListParameter];
  return reserved
    ? {
        name: reserved.name,
        type: reserved.type || AttributeTypes.STRING,
        required: false
      }
    : {
        name: identifier.name,
        type: identifier.type || AttributeTypes.STRING,
        required: true
      };
};

export const getParameters = (existing: FunctionParameterInput[], map: IdentifierMap) =>
  Object.entries(map).reduce<FunctionParameterInput[]>(function (
    acc,
    [name, identifier]
  ) {
    const found = existing.find(p => p.name === name);
    if (found) {
      acc.push(found);
    } else {
      acc.push(toParameter(identifier));
    }
    return acc;
  },
  []);

export const populatePipelineParametersFromMetadata = (
  parameters: PipelineParameterInput[],
  metadata?: Record<string, string>
) => {
  if (!metadata) {
    return parameters.slice();
  }

  return parameters.map(p => {
    const key = keyPipelineParameter(p);
    return {
      ...p,
      name: metadata[key] ? metadata[key] : p.name
    };
  });
};

const ensureObject = (input: string | object): object =>
  typeof input === "string" ? JSON.parse(input) : input;

export const toFunctionState = <M extends Metadata>(
  func: EditorFunctionNode<M>
): FunctionState<M> => {
  const parameters = func.functionParameters.edges.map(e => ({
    name: e.node.name,
    required: e.node.required,
    type: e.node.type
  }));
  const attributes = func.functionAttributes.edges.map(e => ({
    name: e.node.sourceName,
    type: e.node.sourceType,
    key: e.node.sourceKey
  }));
  const authorizationFlows = func.functionAuthorizationFlows.edges.map(e => ({
    environmentId: e.node.environment.id,
    authorizationFlowId: e.node.authorizationFlow.id
  }));
  const environmentIdsWithCredentials = (
    func.environmentsWithCredentials?.edges || []
  ).reduce((acc, e) => ({ ...acc, [e.node.id]: true }), {});

  const pipelineSteps: PipelineStep[] | undefined = func.pipelineSteps?.edges.map(e => {
    const apiCases: ApiCondition[] | undefined = e.node.cases
      ? JSON.parse(e.node.cases)
      : undefined;
    let conditions: Conditional[] | undefined = apiCases
      ? apiCases.map(
          c =>
            ({
              condition: c.condition,
              parts: c.parts,
              conjunctions: c.conjunctions,
              functionId: c.function_id
                ? toGlobalId("FunctionNode", c.function_id)
                : undefined,
              // Data is stored as JSON, so the values come back lowercase, but we need uppercase
              actionType: c.action_type.toUpperCase(),
              function: undefined,
              inputParameters: c.input_parameters
            } as Conditional)
        )
      : undefined;

    if (e.node.type === PipelineStepType.CONDITIONAL) {
      if (!conditions) {
        conditions = [];
      }

      if (conditions.length !== 2) {
        for (let i = conditions.length || 0; i < 2; i++) {
          conditions.push({
            condition: undefined,
            conjunctions: undefined,
            parts: undefined,
            functionId: undefined,
            function: undefined,
            actionType: ConditionActionType.PENDING,
            inputParameters: []
          });
        }
      }
    }

    const { cases, ...stepParts } = e.node;

    return {
      ...stepParts,
      type: e.node.type!,
      dataSource: e.node.function?.dataSource,
      conditions: conditions,
      iteratorItemName: e.node.iteratorItemName,
      iteratorBinding: e.node.iteratorBinding,
      stopOnError: false,
      returnsData: false
    };
  });

  const namedParametersMap = new Map<string, FunctionParameterInput>(
    parameters.map(p => [p.name, p])
  );

  const pipelineParameters = pipelineStepsToParameters(
    pipelineSteps || [],
    func.metadata?.parameterMapping || {}
  ).map(pipelineParameter => {
    // parameters store the final type/required values. When we load a function,
    // we need to use those instead of the generated ones.
    const param = namedParametersMap.get(pipelineParameter.pipelineName);
    if (!param) {
      throw new Error("Parameter not found");
    }

    return {
      ...pipelineParameter,
      type: param.type,
      required: param.required
    };
  });

  return {
    integration: func.dataSource.integration,
    title: func.title,
    baseFunctionId: func.baseFunction.id,
    baseFunctionName: func.baseFunction.name as BaseFunctionName,
    baseFunctionParameterMapping: ensureObject(func.baseFunctionParameterMapping),
    functionId: func.id,
    metadata: func.metadata,
    reducer: func.reducer,
    metadataReducer: func.metadataReducer,
    returnSchema: func.returnSchema,
    parameters,
    attributes,
    authorizationFlows,
    environmentIdsWithCredentials,
    pipelineSteps,
    pipelineParameters
  };
};

export const getFunctionState = <M>(state: State<M>): FunctionState<M> => {
  return {
    integration: state.integration,
    authorizationFlows: state.authorizationFlows,
    attributes: state.attributes,
    baseFunctionId: state.baseFunctionId,
    baseFunctionName: state.baseFunctionName,
    baseFunctionParameterMapping: ensureObject(state.baseFunctionParameterMapping),
    functionId: state.functionId,
    metadata: state.metadata,
    metadataReducer: state.metadataReducer,
    parameters: state.parameters,
    reducer: state.reducer,
    returnSchema: state.returnSchema,
    title: state.title,
    environmentIdsWithCredentials: state.environmentIdsWithCredentials,
    pipelineSteps: state.pipelineSteps,
    pipelineParameters: state.pipelineParameters
  };
};

export const getDefaultFunctionState = (
  dataSource: DataSourceNodeWithFunctions<BaseFunctionNodeBasic>,
  baseFunctionName: BaseFunctionName
): FunctionState<Metadata<unknown>> => {
  const basicFunction = findFunction(dataSource, baseFunctionName);

  if (!basicFunction) {
    throw new Error(`could not find function with name: ${baseFunctionName}`);
  }

  return {
    integration: dataSource.integration,
    attributes: [],
    baseFunctionId: basicFunction.id,
    baseFunctionName: baseFunctionName,
    baseFunctionParameterMapping: defaultBaseFunctionParameterMapping(baseFunctionName),
    functionId: "",
    metadata: defaultMetadata(baseFunctionName),
    metadataReducer: defaultMetadataReducer(baseFunctionName),
    parameters: [],
    reducer: "data",
    returnSchema: undefined,
    title: "",
    authorizationFlows: [],
    environmentIdsWithCredentials: {}
  };
};

type StepUpdater = (step: PipelineStep) => PipelineStep;

export function updateStepField(
  steps: PipelineStep[],
  stepIndex: number,
  updater: StepUpdater
): PipelineStep[] {
  return steps.map((step, index) => {
    if (index === stepIndex) {
      return updater(step);
    }

    return step;
  });
}

export function stepNameToSlug(name: string, existingSlugs: string[]): string {
  let slug = camelCase(name);
  if (existingSlugs.includes(slug)) {
    // Add 8 random characters when have steps with the same name
    slug += uuid().slice(0, 8);
  }

  return slug;
}

/**
 * Plucks the slugs each input PipelineStep, skipping a specific one.
 */
export function pipelineStepsToSlugs(
  steps: PipelineStep[] | undefined,
  skipIndex: number
): string[] {
  return (steps || []).filter((_, index) => index !== skipIndex).map(s => s.slug);
}

export function pipelineStepToInput(step: PipelineStep): PipelineStepInput {
  if (!step.type) {
    throw new Error("Type is required");
  }

  return {
    id: step.id || undefined,
    title: step.title,
    slug: step.slug,
    type: step.type,
    inputParameters: step.inputParameters,
    functionId: step.function?.id,
    expression: step.expression,
    iteratorBinding: step.iteratorBinding,
    iteratorItemName: step.iteratorItemName,
    cases: step.conditions?.map(c => ({
      condition: c.condition,
      parts: c.parts,
      conjunctions: c.conjunctions,
      functionId: c.functionId,
      inputParameters: c.inputParameters,
      actionType: c.actionType
    }))
  };
}

export function keyPipelineParameter(parameter: PipelineParameterInput): string {
  return `${parameter.prefix}.${parameter.name}`;
}

/**
 * Plucks out the parameters from each step and converts them to a PipelineParameterInput
 * Uses the parameterMapping to rename any parameterInputs as needed.
 * By default, parameter type is String, unless more information can specify it.
 *
 * If the step is for a Function, the function's parameter fields are set,
 *  - parameter type
 *  - required or not
 */
export function pipelineStepsToParameters(
  steps: PipelineStep[],
  parameterMapping: Record<string, string>
): PipelineParameterInput[] {
  const unresolved: PipelineParameterInput[] = [];

  for (const step of steps) {
    const functionParametersMap = new Map<string, FunctionParameterNode>(
      step.function?.functionParameters?.edges.map(fp => [fp.node.name, fp.node]) || []
    );

    for (const param of step.inputParameters) {
      if (param.type === ParameterType.UNRESOLVED) {
        const key = `${step.slug}.${param.name}`;
        const renamedName = parameterMapping[key];
        const parts: PipelineParameterInput = {
          id: uuid(),
          required: !!param.required,
          type: AttributeTypes.STRING,
          name: param.name,
          pipelineName: renamedName || param.name,
          stepNumber: step.order,
          prefix: step.slug,
          stepSlug: step.slug
        };

        const fp = functionParametersMap.get(param.name) || { id: "" };

        // Pluck out the id because a pipeline could reuse the same function and the fp will have the same id
        const { id, ...fpParts } = fp;

        unresolved.push({
          ...parts,
          ...fpParts // this also overrides the name, but that shouldn't make a difference for functions
        });
      }
    }

    (step.conditions || []).forEach((condition, conditionIndex) => {
      const conditionFunctionParametersMap = new Map<string, FunctionParameterNode>(
        condition.function?.functionParameters?.edges.map(fp => [
          fp.node.name,
          fp.node
        ]) || []
      );

      for (const param of condition.inputParameters) {
        if (param.type === ParameterType.UNRESOLVED) {
          const key = `${step.slug}.condition.${conditionIndex}.${param.name}`;
          const renamedName = parameterMapping[key];
          const parts: PipelineParameterInput = {
            id: uuid(),
            required: !!param.required,
            type: AttributeTypes.STRING,
            name: param.name,
            pipelineName: renamedName || param.name,
            stepNumber: step.order,
            stepSlug: step.slug,
            prefix: `${step.slug}.condition.${conditionIndex}`
          };

          const fp = conditionFunctionParametersMap.get(param.name) || { id: "" };

          // Pluck out the id because a pipeline could reuse the same function and the fp will have the same id
          const { id, ...fpParts } = fp;

          unresolved.push({
            ...parts,
            ...fpParts // this also overrides the name, but that shouldn't make a difference for functions
          });
        }
      }
    });
  }

  return unresolved;
}

export function pipelineStepsToParameterMetadata(
  steps?: PipelineStep[]
): Record<string, string> | undefined {
  if (!steps) {
    return undefined;
  }

  const result: Record<string, string> = {};

  for (const step of steps) {
    for (const param of step.inputParameters) {
      if (param.type === ParameterType.UNRESOLVED) {
        const fp = step.function?.functionParameters?.edges
          .map(e => e.node)
          .find(p => p.name === param.name);

        if (fp) {
          const key = `${step.slug}.${param.name}`;
          result[key] = param.name;
        }
      }
    }
  }

  return result;
}

export function pipelineParametersToMetadata(
  params?: PipelineParameterInput[]
): Record<string, string> | undefined {
  if (!params?.length) {
    return undefined;
  }

  return params.reduce((prev, current) => {
    const key = keyPipelineParameter(current);
    return {
      ...prev,
      [key]: current.pipelineName
    };
  }, {});
}

export function pipelineParametersToParameters(
  params?: PipelineParameterInput[]
): FunctionParameterInput[] {
  return (params || []).map(p => ({
    key: p.id,
    name: p.pipelineName,
    type: p.type,
    required: p.required
  }));
}

export function defaultPipelineStepTitle(
  stepOrder: number,
  stepType: PipelineStepType
): string {
  switch (stepType) {
    case PipelineStepType.EXPRESSION:
      return `Step ${stepOrder + 1}`;
    case PipelineStepType.CONDITIONAL:
      return `Step ${stepOrder + 1} condition`;
    case PipelineStepType.LOOP:
      return `Step ${stepOrder + 1} loop`;
    case PipelineStepType.FUNCTION:
      // Function Step name is set when the function is selected
      return "";
  }
}

export function getReturnSchemaForPipelineStep(
  step: PipelineStep | undefined
): ReturnSchema | undefined {
  if (!step) {
    return undefined;
  }

  switch (step.type) {
    case undefined:
      return undefined;
    case PipelineStepType.EXPRESSION:
      return ReturnSchema.UNKNOWN;
    case PipelineStepType.CONDITIONAL:
      return ReturnSchema.UNKNOWN;
    case PipelineStepType.LOOP:
      return ReturnSchema.UNKNOWN;
    case PipelineStepType.FUNCTION:
      if (!step.function) {
        return undefined;
      }

      return step.function?.returnSchema;
  }
}

export function preservePipelineStepsCachedData(
  steps: PipelineStep[],
  newSteps: PipelineStep[]
): PipelineStep[] {
  // Conditions might have already loaded function data
  // if the state already has this data, we can reuse it
  const idToFunction = new Map<string, FunctionNode>();
  for (const step of steps) {
    for (const condition of step.conditions || []) {
      if (condition.function) {
        idToFunction.set(condition.function.id, condition.function);
      }
    }
  }

  return newSteps.map(step => ({
    ...step,
    conditions: step.conditions?.map(condition => ({
      ...condition,
      function: condition.functionId
        ? idToFunction.get(condition.functionId)
        : undefined
    }))
  }));
}

/**
 * Filter function that rejects any Binding input parameters that are bound to some prefix.
 * The binding is assumed to be text with parts broken by '.'.
 * If the first part has the prefix, it is rejected. Otherwise, it is accepted.
 */
export function filterOutInputParametersBoundToPrefix(
  prefix: string | undefined,
  inputParameter: InputParameter
) {
  if (inputParameter.type === ParameterType.BINDING) {
    const parts = inputParameter.binding.split(".");
    return parts[0] !== prefix;
  }

  return true;
}
