import React from "react";

import { AttributeValueBaseProps } from "../AttributeValue";

const PREVIEW_LENGTH = 10;

export default function BinaryValue({
  value,
  className = "",
  style = {}
}: AttributeValueBaseProps) {
  const out =
    value && value.length > 255
      ? `${value.substring(0, PREVIEW_LENGTH)}......${value.substring(
          value.length - PREVIEW_LENGTH
        )}`
      : value;
  return (
    <div style={style} className={className}>
      {String(out)}
    </div>
  );
}
