import { cloneDeep, isEqual, isObjectLike } from "lodash";

import {
  Binding,
  SpaceComponentObject,
  SpaceComponentPackage,
  SpaceComponentType
} from "../../../types";
import { SpaceConfigState } from "../SpaceConfig/SpaceConfigContext/useSpaceConfig/reducer";
import {
  BaseSpaceConfig,
  selectComponentToSpaceMap
} from "../SpaceConfig/SpaceConfigContext/useSpaceConfig/reducer/reducer";
import { selectSpaceFromConfig } from "../SpaceConfig/SpaceConfigContext/useSpaceConfig/util";
import { ComponentNode } from "../SpaceRoot/RenderTreeContext";
import { SubSpaceInputParameter } from "../SpaceRoot/SpaceComponent/SubSpace/types";

import findInputBindings from "./findInputBindings";
import { SchemaTree } from "./tree";

type Changes = {
  spaces: Map<string, BaseSpaceConfig>;
  components: Map<string, SpaceComponentObject>;
};

export default function rewriteSubSpacePaths(state: SpaceConfigState) {
  const componentToSpace = selectComponentToSpaceMap(state);
  const fullComponentTree = selectSpaceFromConfig(state);
  const changes = _rewriteSubSpacePaths(
    { componentTreeNodes: fullComponentTree } as SpaceComponentObject,
    state.spaces,
    state.packages
  );
  if (!changes.components.size && !changes.spaces.size) return state;
  const changedSpaces = new Set<BaseSpaceConfig>();
  changes.components.forEach(
    ({ layout, container, componentTreeNodes, ...c }, slug) => {
      const space = componentToSpace.get(slug)!;
      const componentConfigState = space.components.get(slug)!;
      if (!isEqual(c, componentConfigState?.draftComponent)) {
        space.components.set(slug, {
          ...componentConfigState,
          draftComponent: c as SpaceComponentObject
        });
        changedSpaces.add(space);
      }
    }
  );
  changes.spaces.forEach((s, slug) => {
    const space = state.spaces.get(slug)!;
    space.parameters = s.parameters;
    changedSpaces.add(space);
  });
  Array.from(changedSpaces).forEach(s => {
    state.spaces.set(s.slug, { ...s });
  });
  return { ...state };
}

export function _rewriteSubSpacePaths(
  rootComponent: SpaceComponentObject,
  spaces: Map<string, BaseSpaceConfig>,
  pkgs: Map<SpaceComponentType, SpaceComponentPackage>
) {
  const changes: Changes = { spaces: new Map(), components: new Map() };
  const schemaTree = new SchemaTree(rootComponent, spaces, pkgs);
  let spaceStack: [number, ComponentNode][] = [];
  let node: ComponentNode | undefined;
  while ((node = schemaTree.next()) !== undefined) {
    if (node.component.type === "SUB_SPACE") {
      // If we hit another sub space at the same depth, pop the previous sub space
      if (
        spaceStack.length &&
        spaceStack[spaceStack.length - 1][0] === schemaTree.currentDepth
      ) {
        spaceStack.pop();
      }
      spaceStack.push([schemaTree.currentDepth, node]);
    }
    spaceStack = spaceStack.filter(([depth]) => depth <= schemaTree.currentDepth);
    if (!spaceStack.length) continue;
    const closestSpace = spaceStack[spaceStack.length - 1][1];
    // Stop tracking any spaces deeper than the current depth
    const parent = node.parent;
    if (
      spaceStack[spaceStack.length - 1][0] === schemaTree.currentDepth &&
      parent?.children[parent.children.indexOf(node) - 1] ===
        spaceStack[spaceStack.length - 1][1]
    ) {
      spaceStack.pop();
    }

    const { component, subSpaceComponent, spaceDef } = getCurrentChanges(
      changes,
      spaces,
      node.component,
      closestSpace.component
    );

    let updated = rewriteExternalPaths(
      node,
      schemaTree,
      spaceStack,
      component,
      subSpaceComponent,
      spaceDef
    );

    if (!updated) continue;

    updateChanges(changes, updated);

    // If bindings got re-written, ancestor spaces may also need their
    // bindings re-written
    const spaceStackCopy = [...spaceStack];
    while (updated && spaceStackCopy.length > 1) {
      const node = spaceStackCopy.pop()![1];
      // Ensure non stale component config
      node.component = changes.components.get(node.component.slug)!;
      const closestSpace = spaceStackCopy[spaceStackCopy.length - 1][1];
      const { component, subSpaceComponent, spaceDef } = getCurrentChanges(
        changes,
        spaces,
        node.component,
        closestSpace.component
      );
      updated = rewriteExternalPaths(
        node,
        schemaTree,
        spaceStack,
        component,
        subSpaceComponent,
        spaceDef
      );
      if (updated) updateChanges(changes, updated);
    }
  }
  return changes;
}

function updateChanges(
  changes: Changes,
  newChanges: {
    component: SpaceComponentObject;
    subSpaceComponent: SpaceComponentObject;
    spaceDef: BaseSpaceConfig;
  }
) {
  changes.spaces.set(newChanges.spaceDef.slug, newChanges.spaceDef);
  changes.components.set(
    newChanges.subSpaceComponent.slug,
    newChanges.subSpaceComponent
  );
  changes.components.set(newChanges.component.slug, newChanges.component);
}

function getCurrentChanges(
  changes: Changes,
  spaces: Map<string, BaseSpaceConfig>,
  component: SpaceComponentObject,
  subSpaceComponent: SpaceComponentObject
) {
  const currentComponent = changes.components.has(component.slug)
    ? changes.components.get(component.slug)!
    : component;
  const currentSubSpaceComponent = changes.components.has(subSpaceComponent.slug)
    ? changes.components.get(subSpaceComponent.slug)!
    : subSpaceComponent;
  const spaceSlug = subSpaceComponent.properties.sub_space;
  const currentSpaceDef = changes.spaces.has(spaceSlug)
    ? changes.spaces.get(spaceSlug)!
    : spaces.get(spaceSlug)!;

  return {
    component: currentComponent,
    subSpaceComponent: currentSubSpaceComponent,
    spaceDef: currentSpaceDef
  };
}

function rewriteExternalPaths(
  node: ComponentNode,
  schemaTree: SchemaTree,
  spaceStack: [number, ComponentNode][],
  component: SpaceComponentObject,
  subSpaceComponent: SpaceComponentObject,
  spaceDef: BaseSpaceConfig
) {
  const inputBindings = findInputBindings(node.component.properties, new Set(), true);
  if (!inputBindings.size) return null;
  const externalBindings = new Set<string>();
  const expandedBindingMap = new Map<string, string>();
  inputBindings.forEach(ib => {
    let expandedPath: string | undefined;
    try {
      expandedPath = schemaTree.expandPath(node, ib);
    } catch (e) {
      // Component MOVEs may result in invalid binding paths. Binding paths
      // cannot be generally rewritten as a MOVE may result in binding target
      // being nested in a repeated component, and binding paths through repeated
      // are ambiguous.
      console.warn(
        `${ib} binding path in ${node.component.slug} is now invalid and cannot be rewritten.`
      );
    }
    let parentSpace;
    if (
      !spaceStack.length ||
      (node.component.type === "SUB_SPACE" && spaceStack.length === 1)
    ) {
      // If this is a root component or 1 deep sub_space
      // any binding is at root level and does not need re-writing
      return;
    } else if (node.component.type === "SUB_SPACE") {
      // If it's a nested sub_space, check against the parent space's bindings
      parentSpace = spaceStack[spaceStack.length - 2][1];
    } else {
      // If it's a component, check against the sub_space's bindings
      parentSpace = spaceStack[spaceStack.length - 1][1];
    }

    if (
      typeof expandedPath === "string" &&
      !SchemaTree.isPathInBranch(parentSpace, expandedPath)
    ) {
      externalBindings.add(ib);
      expandedBindingMap.set(ib, expandedPath);
    }
  });
  if (!externalBindings.size) return null;

  const spaceDefCopy = cloneDeep(spaceDef)!;
  const componentCopy = cloneDeep(component);
  const subSpaceComponentCopy = cloneDeep(subSpaceComponent);
  const pathRewriteMap = new Map<string, string>();
  externalBindings.forEach(eb => {
    const { name, ...bindingType } = schemaTree.getPathSchema(
      expandedBindingMap.get(eb)!
    );
    let paramName = null;
    let paramNameSuffix = 0;

    // Does a param already exist for this binding?
    const existingParam = subSpaceComponentCopy.properties.input_parameters.find(
      (ip: SubSpaceInputParameter) => ip.binding === eb
    );

    if (existingParam) {
      // Re-use exisiting param
      pathRewriteMap.set(eb, `params.${existingParam.name}`);
    } else {
      // Add param to space and bind it to the external binding
      while (!paramName) {
        const nextParam = `param${++paramNameSuffix}`;
        if (!spaceDefCopy?.parameters.some(p => p.name === nextParam)) {
          paramName = `param${paramNameSuffix}`;
        }
      }
      spaceDefCopy?.parameters.push({
        name: paramName,
        bindingType: bindingType as Binding
      });
      subSpaceComponentCopy.properties.input_parameters = [
        ...subSpaceComponentCopy?.properties.input_parameters,
        {
          name: paramName,
          binding: expandedBindingMap.get(eb)
        }
      ];
      pathRewriteMap.set(eb, `params.${paramName}`);
    }
  });

  // Rewrite component bindings
  componentCopy.properties = rewritePaths(componentCopy.properties, pathRewriteMap);

  return {
    component: componentCopy,
    subSpaceComponent: subSpaceComponentCopy,
    spaceDef: spaceDefCopy
  };
}

export function rewritePaths(
  properties: SpaceComponentObject["properties"],
  pathMap: Map<string, string>
) {
  return mapProperties(properties, (entry: [string, any]) => {
    const [key, val] = entry;
    if (key === "binding" && pathMap.has(val)) {
      return pathMap.get(val);
    }
    if (key.indexOf("template") > -1 && typeof val === "string") {
      return [...pathMap.entries()].reduce(
        (memo, [from, to]) => memo.replaceAll(from, to),
        val
      );
    }

    return entry[1];
  });
}

export function mapProperties(properties: {}, updater: (node: [string, any]) => any) {
  function processEntry(entry: [string, any]): any {
    if (Array.isArray(entry[1])) {
      return [
        entry[0],
        entry[1].map(val => {
          if (Array.isArray(val)) {
            return processEntry([entry[0], val]);
          } else if (isObjectLike(val)) {
            return Object.fromEntries(Object.entries(val).map(processEntry));
          } else {
            return updater([entry[0], val]);
          }
        })
      ];
    } else if (isObjectLike(entry[1])) {
      const entries = Object.entries(entry[1]).map(processEntry);
      return [entry[0], Object.fromEntries(entries)];
    } else {
      return [entry[0], updater(entry)];
    }
  }

  return Object.fromEntries(Object.entries(properties).map(processEntry));
}
