import gql from "graphql-tag";
import { QueryHookOptions, useQuery } from "react-apollo";

const QUERY = gql`
  query AccountStatusQuery {
    accountStatus {
      subscriptionStatus
      featuresStatus {
        userLimit {
          value
          limit
          overLimit
        }
        roles {
          value
          limit
          overLimit
        }
        sso {
          value
          limit
          overLimit
        }
        permissions {
          value
          limit
          overLimit
        }
        twoFactor {
          value
          limit
          overLimit
        }
        environments {
          value
          limit
          overLimit
        }
        dataSources {
          value
          limit
          overLimit
        }
        versionHistory {
          value
          limit
          overLimit
        }
        auditLogs {
          value
          limit
          overLimit
        }
      }
    }
  }
`;

export interface BooleanFeature {
  value: boolean;
  limit: boolean;
  overLimit: boolean;
}

export interface IntegerFeature {
  value: number;
  limit: number;
  overLimit: boolean;
}

export interface AccountStatusData {
  accountStatus: {
    subscriptionStatus: string;
    featuresStatus: {
      userLimit: IntegerFeature;
      roles: IntegerFeature;
      sso: BooleanFeature;
      permissions: BooleanFeature;
      twoFactor: BooleanFeature;
      environments: BooleanFeature;
      dataSources: IntegerFeature;
      versionHistory: BooleanFeature;
      auditLogs: BooleanFeature;
    };
  };
}

export default function useAccountStatus(options: QueryHookOptions<AccountStatusData>) {
  return useQuery<AccountStatusData>(QUERY, options);
}
