import React from "react";

import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import styled from "styled-components";

import { Connection, RelayNode } from "../../../types";
import { useEnvironmentContext } from "../../common/contexts/EnvironmentContext";
import useAuthUser from "../../common/hooks/useAuthUser";
import { H5, P } from "../../common/StyledComponents";
import { useSpacesContext } from "../hooks/useSpacesManager/useSpacesManager";

const BaseCard = styled.section`
  padding: ${props => props.theme.spacermd};
  background: ${props => props.theme.cardBackgroundColor};
  border: solid 1px transparent;
  border-radius: 4px;
`;

const RootEmptyState = styled(BaseCard)`
  padding: ${props => props.theme.spacerxl};
  text-align: center;
`;

const FinePrint = styled(P)`
  font-size: 14px;
  margin-bottom: 0;
`;

export default function EmptyState() {
  const { activeQuery, queryVariables } = useSpacesContext();
  const { getCurrentEnvironment } = useEnvironmentContext();
  const { data, loading } = useQuery<{ allSpaces: Connection<RelayNode> }>(
    FETCH_SPACES_EXIST,
    {
      variables: { environmentId: getCurrentEnvironment().id, spaceType: "space" }
    }
  );
  const { isAdmin, isSpaceAdmin } = useAuthUser();

  const canManageSpaces = isAdmin || isSpaceAdmin;
  const spacesExist = (data?.allSpaces.edges || []).length > 0;

  if (loading) return null;

  let headline = "No spaces yet";
  let fineprint = "Contact your admin for more details";
  if (!spacesExist && canManageSpaces) {
    headline = "No spaces yet";
    fineprint = "Click New space to get started";
  } else if (queryVariables.nameContains) {
    headline = "No matching spaces";
    fineprint = "Adjust your search to find spaces";
  } else if (activeQuery === "favorites") {
    headline = "No favorited spaces";
    fineprint = "Click All spaces to find spaces to favorite";
  } else if (activeQuery === "drafts") {
    headline = "No unpublished spaces";
    fineprint = "All existing spaces have been published to this environment";
  } else if (canManageSpaces) {
    headline = "No published spaces yet";
    fineprint = "Publish a space to this environment to see it here";
  }

  return (
    <RootEmptyState>
      <H5>{headline}</H5>
      <FinePrint>{fineprint}</FinePrint>
    </RootEmptyState>
  );
}

const FETCH_SPACES_EXIST = gql`
  query FetchSpacesExist($environmentId: ID, $spaceType: String) {
    allSpaces(
      environmentId: $environmentId
      isPublished: false
      spaceType: $spaceType
      first: 1
    ) {
      edges {
        node {
          id
        }
      }
    }
  }
`;
