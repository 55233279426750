import { ScmSyncStatus } from "../../../../../types";
import { assertNever } from "../../../../util/assertNever";

interface Result {
  canSync: boolean;
  content: string;
  okText: string;
}

export const getHelper = (status: ScmSyncStatus | null): Result => {
  switch (status) {
    case ScmSyncStatus.CURRENT:
      return {
        canSync: false,
        content: "Your space is already up to date!",
        okText: "Done"
      };
    case ScmSyncStatus.UNCOMMITTED_CHANGES:
      return {
        canSync: true,
        content:
          "Syncing versions will remove all uncommitted changes. Would you like to proceed?",
        okText: "Yes, sync and remove uncommitted changes"
      };
    case ScmSyncStatus.NOT_CURRENT:
      return {
        canSync: true,
        content: "Would you like to proceed?",
        okText: "Yes, sync"
      };
    case ScmSyncStatus.REMOTE_BRANCH_NOT_FOUND:
    case ScmSyncStatus.REMOTE_SPACE_NOT_FOUND:
    case ScmSyncStatus.REMOTE_ERROR:
    case null:
      return {
        canSync: false,
        content: "An error occurred.",
        okText: "Done"
      };
    default:
      return assertNever(status);
  }
};
