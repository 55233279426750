import React from "react";

import { Alert } from "../../ConfigPanel/styledComponents";

import { OptionsComponent } from "./types";

export { DISPLAY_MESSAGE } from "./DisplayMessage";
export { REFRESH_COMPONENT } from "./RefreshComponent";
export { SET_VARIABLE } from "./SetVariable";

// Add simple effects here for now rather than creating dedicated modules.
const EmptyOptions: OptionsComponent<{}> = _props => null;

const AutoUpdateByKeyOptions: OptionsComponent<{}> = _props => (
  <Alert
    message="Components that are bound to this function will automatically be updated by key."
    type="info"
    showIcon
  />
);
export const AUTO_UPDATE_BY_KEY = {
  name: "Auto update by key",
  type: "auto_update_by_key" as const,
  permanent: true,
  Options: AutoUpdateByKeyOptions
};

export const RESET_FORM_INPUTS = {
  name: "Reset form inputs",
  type: "reset_form_inputs" as const,
  Options: EmptyOptions
};

export const MAINTAIN_SELECTED_ROWS_BY_ID = {
  name: "Maintain selected rows by id",
  type: "maintain_selected_rows_by_id" as const,
  Options: EmptyOptions
};

export const MAINTAIN_SELECTED_ROWS_BY_INDEX = {
  name: "Maintain selected rows by index",
  type: "maintain_selected_rows_by_index" as const,
  Options: EmptyOptions
};

export const SELECT_FIRST_ROW = {
  name: "Select first row",
  type: "select_first_row" as const,
  Options: EmptyOptions
};
