import { Binding, BindingShape } from "../../../../../types";
import { SpaceDefinition } from "../../../types";
import { ComponentNode } from "../../RenderTreeContext";
import { getColumnsSchema } from "../common/ColumnListManager/getColumnsOutputShape";

export function getSchema(
  node: ComponentNode,
  spaces: Map<string, SpaceDefinition>
): Binding[] {
  return [
    {
      title: "Selected Item",
      name: "selectedItem",
      shape: BindingShape.OBJECT,
      attributes: getColumnsSchema(node, spaces)
    }
  ];
}
