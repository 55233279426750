import React from "react";

import { Icon, Input } from "antd";
import { InputProps } from "antd/lib/input";
import styled from "styled-components";

import { ErrorIcon } from "../../../../common/Icons";
import withChangeOnBlur from "../../../../hoc/WithChangeOnBlur";
import { useSpaceConfigContext } from "../../SpaceConfigContext";
import HeaderPanel from "../HeaderPanel";

const Name = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.textColorOnDark};
  font-size: ${props => props.theme.mediumFontSize};
  font-family: var(--light-header-font-family);

  &:hover {
    cursor: pointer;
  }
`;

export const Error = styled.p`
  color: ${props => props.theme.errorColor};
  font-size: ${props => props.theme.smallFontSize};
  margin-bottom: 0px;
`;

export const StyledErrorIcon = styled(ErrorIcon)`
  transform: scale(0.7);
  margin-top: 1px;
  margin-right: ${props => props.theme.spacerxs};
`;

export const NameEditorPanel = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: ${props => props.theme.headerHeight};
  left: calc(50% - 150px);
  background-color: ${props => props.theme.darkBackgroundColor};
  color: ${props => props.theme.textColorOnDark};
  padding: ${props => props.theme.spacermd};
  width: 300px;
`;
NameEditorPanel.displayName = "NameEditorPanel";

const IconContainer = styled.div`
  margin-left: 2px;
  height: ${props => props.theme.headerHeight};
  padding: 0 ${props => props.theme.spacersm};
  display: flex;
  justify-content: center;
  align-items: center;
  &.popperOpen {
    background-color: ${props => props.theme.darkBackgroundColor};
  }
`;

const StyledInput = styled(Input)`
  background-color: ${props => props.theme.darkInputOnDarkBackground};
  border: 0px;
  color: ${props => props.theme.textColorOnDark};
  &:hover,
  &:focus,
  &:active {
    outline: 1px solid ${props => props.theme.primaryColorHover};
  }
`;

interface BlurOnEnterPressInputProps extends InputProps {
  isInputVisible?: boolean;
}

const BlurOnEnterInput = ({
  value,
  onBlur,
  onChange,
  isInputVisible
}: BlurOnEnterPressInputProps) => {
  const ref = React.useRef<Input>(null);

  const onKeyUp = React.useCallback((e: React.KeyboardEvent) => {
    if (e.keyCode === 13) {
      ref.current?.blur();
    }
  }, []);

  React.useEffect(() => {
    if (isInputVisible) {
      ref.current?.focus();
      if (ref.current?.input.value) {
        ref.current.input.setSelectionRange(0, ref.current.input.value.length);
      }
    }
  }, [isInputVisible]);

  return (
    <div onKeyUp={onKeyUp}>
      <StyledInput ref={ref} onChange={onChange} onBlur={onBlur} value={value} />
    </div>
  );
};

export const NameInput =
  withChangeOnBlur<{ isInputVisible: boolean }>(BlurOnEnterInput);

export default function NameEditor() {
  const [visible, setVisible] = React.useState(false);
  const { state, dispatch, loading } = useSpaceConfigContext();
  const rootSpace = state.spaces.get(state.rootSpaceSlug);
  const name = rootSpace?.name || "";
  const { hasNameError } = state;
  const onKeyUp = React.useCallback((e: React.KeyboardEvent) => {
    if (e.keyCode === 13 || e.keyCode === 27) {
      setVisible(false);
    }
  }, []);

  if (loading) return null;
  return (
    <>
      <Name data-test="nameAndEdit" onClick={() => setVisible(true)}>
        {hasNameError && <StyledErrorIcon />} {name || "Untitled Space"}
        <IconContainer className={visible ? "popperOpen" : ""}>
          <Icon type="edit" />
        </IconContainer>
      </Name>
      {visible && (
        <HeaderPanel maskClosable onCancel={() => setVisible(false)}>
          <NameEditorPanel onKeyUp={onKeyUp} data-test="nameInputPanel">
            <p>Changes are published as soon as you save the Space.</p>
            <NameInput
              isInputVisible={visible}
              onChange={e => {
                dispatch({
                  type: "SET_SPACE_NAME",
                  payload: {
                    name: e.target.value
                  }
                });
              }}
              value={name}
            />
            {hasNameError && <Error>Space name cannot be blank</Error>}
          </NameEditorPanel>
        </HeaderPanel>
      )}
    </>
  );
}
