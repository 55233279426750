import { v4 as uuid } from "uuid";

import { toGlobalId } from "../../util/graphql";
import { SpaceDefinition } from "../types";

export default function createSpace(
  opts: Partial<SpaceDefinition> = {},
  slugs = new Set<string>()
): SpaceDefinition {
  const slug =
    opts.slug && !slugs.has(opts.slug) ? opts.slug : `space${uuid().replace(/-/g, "")}`;
  return {
    parameters: [],
    slug,
    name: "space",
    id: null,
    versionId: toGlobalId("SpaceVersonNode", uuid()),
    scmIsCurrent: false,
    scmStatus: null,
    scmSyncStatus: null,
    branchCount: 0,
    ...opts
  };
}
