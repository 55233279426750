import styled from "styled-components";

import DownRightArrow from "../../../../../../assets/down_right_arrow.svg";
import { colorTokens } from "../../../../../../cssConstants";
import { C2 } from "../../../../StyledComponents";
import { LIGHTEN_BACKGROUND_COLOR } from "../common/styledComponents";

import ConditionSummary from "./ConditionSummary";

export const SelectLabel = styled(C2)`
  display: block;
  line-height: 18px;
  color: ${props => props.theme.surfaceSecondary};
  padding-bottom: 4px;
`;

export const Hr = styled.hr<{ margin?: number }>`
  background-color: ${colorTokens.grey1600};
  margin: ${props => (props.margin !== undefined ? props.margin : 24)}px 0;
  height: 1px;
`;

export const ConditionCaseHeader = styled.div`
  margin-bottom: 24px;
`;

export const ConditionIconWrapper = styled.div`
  margin-top: 27px;
  height: 28px;
  width: 68px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;

export const ConditionRightContent = styled.div`
  margin-top: ${props => props.theme.spacermd};
  padding: ${props => props.theme.spacermd};
  padding-top: 0;
`;

export const ConditionSummaryContainer = styled.div`
  width: 100%;
  padding: 16px;
  border-radius: ${props => props.theme.containerPrimaryBorderRadius};
`;

export const HorizontalContent = styled.div`
  display: flex;
  align-items: start;
`;

export const Arrow = styled(DownRightArrow)`
  width: 12px;
  height: 14px;
`;

export const ConditionSummaryButton = styled(ConditionSummary)`
  background: ${LIGHTEN_BACKGROUND_COLOR};
  border: 1px solid ${LIGHTEN_BACKGROUND_COLOR};

  &:hover {
    cursor: pointer;
  }
`;

export const ConditionSummaryHeader = styled(ConditionSummary)`
  border: none;
  background-color: ${props => props.theme.grey1837};
`;
