import React from "react";

import styled from "styled-components";

import BaseButton from "../../../../../common/Button";
import { LayoutUnit, ElementLayout } from "../../../../layout/util";
import { useStableSpaceContext } from "../../../SpaceContext";
import { useComponentStateContext } from "../../contexts/ComponentStateContext";

import { ButtonBackgroundToBorderColorMap, ButtonTypes } from "./constants";

type ButtonOptions = {
  width: string;
  height: string;
  editMode: boolean;
  backgroundColor: string;
  type: ButtonTypes;
};

const StyledButton = styled(
  ({ width, height, editMode, backgroundColor, shape, color, ...rest }) => (
    <BaseButton {...rest} />
  )
)<ButtonOptions>`
  width: ${props => (props.width === LayoutUnit.AUTO ? LayoutUnit.AUTO : "100%")};

  &.ant-btn.ant-btn-primary,
  &.ant-btn.ant-btn-primary:hover,
  &.ant-btn.ant-btn-primary:active,
  &.ant-btn.ant-btn-primary:focus {
    background-color: ${props =>
      props.type === ButtonTypes.Link
        ? "undefined"
        : props.backgroundColor || props.theme.primaryButtonBackground};
    border-color: ${props =>
      ButtonBackgroundToBorderColorMap[props.backgroundColor] ||
      props.backgroundColor ||
      props.theme.primaryButtonBackground};
    color: ${props => props.color || props.theme.primaryButtonColor};

    /* in edit mode, show preview of color. in view mode, show default disabled colors. */
    &:disabled {
      background-color: ${props =>
        props.editMode
          ? props.backgroundColor || props.theme.primaryButtonBackground
          : props.theme.buttonDisabledBackground};
      border-color: ${props =>
        props.editMode
          ? ButtonBackgroundToBorderColorMap[props.backgroundColor] ||
            props.backgroundColor ||
            props.theme.primaryButtonBackground
          : props.theme.buttonDisabledBorderColor};
      color: ${props =>
        props.editMode ? props.color : props.theme.buttonDisabledColor};
    }

    &,
    &.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
      padding-left: ${props =>
        props.type === ButtonTypes.Circle
          ? props.theme.spacersm
          : props.theme.spacermd};
      padding-right: ${props =>
        props.type === ButtonTypes.Circle
          ? props.theme.spacersm
          : props.theme.spacermd};
    }
  }

  &.ant-btn.ant-btn-link,
  &.ant-btn.ant-btn-link:hover,
  &.ant-btn.ant-btn-link:active,
  &.ant-btn.ant-btn-link:focus {
    color: ${props => props.color || props.theme.primaryButtonBackground};

    /* in edit mode, show preview of color. in view mode, show default disabled colors. */
    &:disabled {
      color: ${props =>
        props.editMode
          ? props.color || props.theme.primaryButtonBackground
          : props.theme.buttonDisabledColor};
    }

    &,
    &.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
      padding-left: ${props => props.theme.spacermd};
      padding-right: ${props => props.theme.spacermd};
    }
  }

  &:hover {
    filter: brightness(0.9);
  }
  &:active,
  &:focus {
    filter: brightness(0.8);
  }

  /* determine radius based on height to allow for rounded corners if but width is longer */
  border-radius: ${props =>
    props.shape === ButtonTypes.Circle
      ? `${props.theme.buttonHeightValue / 2}px`
      : props.theme.borderRadiusmd};
`;

export default function Button(
  props: Parameters<typeof BaseButton>[0] & { layout?: ElementLayout }
) {
  const { layout } = props;
  const { editMode } = useStableSpaceContext();
  const { componentNode } = useComponentStateContext();
  const properties = componentNode?.component.properties;

  const themeProperties = properties?.theme_properties || {};

  return (
    <StyledButton
      width={layout?.width || LayoutUnit.AUTO}
      height={layout?.height || LayoutUnit.AUTO}
      editMode={editMode}
      color={themeProperties["text-color"]}
      backgroundColor={properties?.button_background_color}
      shape={properties?.button_type || ButtonTypes.Rectangle}
      icon={properties?.button_icon}
      textStyle={properties?.styles?.root}
      {...props}
    />
  );
}
