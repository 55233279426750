import React from "react";

import { Link } from "react-router-dom";

import * as styled from "./styledComponents";

export interface TabItem {
  tab: string;
  title: string;
  description: string;
  icon: React.ComponentType;
}

interface Props {
  tabs: TabItem[];
  activeTab: string;
  errors: Record<string, boolean>;
  className?: string;
}

const Navigation = ({ tabs, activeTab, errors, className = "" }: Props) => {
  return (
    <div className={className}>
      <styled.Timeline>
        {tabs.map(item => {
          const TabIcon = item.icon;
          return (
            <styled.TimelineItem
              key={item.tab}
              dot={
                <styled.IconContainer isActive={item.tab === activeTab}>
                  <TabIcon />
                </styled.IconContainer>
              }
            >
              <Link to={`?tab=${item.tab}`}>
                <styled.TitleWithErrorContainer>
                  {errors[item.tab] && <styled.ErrorIcon />}
                  <styled.TimelineTitle selected={activeTab === item.tab}>
                    {item.title}
                  </styled.TimelineTitle>
                </styled.TitleWithErrorContainer>
              </Link>
              <styled.TimelineDescription>
                {activeTab === item.tab ? item.description : ""}
              </styled.TimelineDescription>
            </styled.TimelineItem>
          );
        })}
      </styled.Timeline>
    </div>
  );
};

export default Navigation;
