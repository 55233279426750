import gql from "graphql-tag";
import { QueryHookOptions, useQuery } from "react-apollo";

import { StateNode } from "../../App/Queue/useQueueClient/queries/AllTasks";

const QUERY = gql`
  query StateActivity($queueId: ID!, $fromTimestamp: Int!, $toTimestamp: Int) {
    stateActivity(
      queueId: $queueId
      fromTimestamp: $fromTimestamp
      toTimestamp: $toTimestamp
    ) {
      state {
        id
        name
        color
      }
      enterCount
      exitCount
      averageTimeInState
    }
  }
`;

interface Variables {
  queueId: string;
  fromTimestamp: number;
  toTimestamp?: number;
}

export interface StateActivity {
  state: StateNode;
  enterCount: number;
  exitCount: number;
  averageTimeInState: number | null;
}

interface Data {
  stateActivity: StateActivity[];
}

export const useStateActivity = (options?: QueryHookOptions<Data, Variables>) =>
  useQuery<Data, Variables>(QUERY, options);
