import { QueryResult } from "@apollo/react-common";
import gql from "graphql-tag";
import { QueryHookOptions, useQuery } from "react-apollo";

const QUERY = gql`
  query TransitionActivity(
    $queueId: ID!
    $stateId: ID!
    $transitionId: ID!
    $fromTimestamp: Int!
    $toTimestamp: Int
  ) {
    transitionActivity(
      queueId: $queueId
      stateId: $stateId
      transitionId: $transitionId
      fromTimestamp: $fromTimestamp
      toTimestamp: $toTimestamp
    ) {
      user {
        id
        name
      }
      count
    }
  }
`;

interface Variables {
  queueId: string;
  stateId: string;
  transitionId: string;
  fromTimestamp: number;
  toTimestamp?: number;
}

export interface TransitionActivity {
  user: {
    id: string;
    name: string;
  };
  count: number;
}

interface Data {
  transitionActivity: TransitionActivity[];
}

export const useTransitionActivity = (options?: QueryHookOptions<Data, Variables>) =>
  useQuery<Data, Variables>(QUERY, options);

interface QueueVariables {
  slug: string;
}

export interface TransitionNode {
  id: string;
  name: string;
}

export interface StateNode {
  id: string;
  name: string;
  color: string;
  isArchive: boolean;
  fromTransitions: TransitionNode[];
}

interface QueueData {
  queueBySlug: {
    id: string;
    name: string;
    description: string;
    slug: string;
    states: StateNode[];
  };
}

const QUEUE_QUERY = gql`
  query QueueBySlug($slug: String!) {
    queueBySlug(slug: $slug) {
      id
      name
      description
      slug
      states {
        id
        name
        color
        isArchive
        fromTransitions {
          id
          name
        }
      }
    }
  }
`;

export type QueueResult = QueryResult<QueueData, QueueVariables>;

export const useQueueBySlug = (
  options?: QueryHookOptions<QueueData, QueueVariables>
): QueueResult => useQuery<QueueData, QueueVariables>(QUEUE_QUERY, options);
