import React from "react";

import { useMutation, useQuery } from "@apollo/react-hooks";
import { Col, Form, Input, Row } from "antd";
import { PasswordInput } from "antd-password-input-strength";
import { FormComponentProps } from "antd/lib/form";
import gql from "graphql-tag";
import _ from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { Routes } from "../../constants";
import { colorTokens } from "../../cssConstants";
import { publicClient } from "../../graphql";
import { setTrackingUserId } from "../../logging";
import { AuthAnchor } from "../auth/styledComponents";
import ButtonNew from "../common/ButtonNew/ButtonNew";
import { AUTH_STATUS } from "../common/hooks/useAuthStatus";
import usePaths from "../common/hooks/usePaths";
import Message from "../common/Message";
import { DarkTheme, FormItem } from "../common/StyledComponents";
import { VALIDATION_MESSAGES } from "../util/ClientValidator";

export enum Flow {
  INITIAL = "INITIAL",
  USER = "USER",
  EXPIRED = "EXPIRED"
}

const SIGNUP_TOKEN_FLOW = gql`
  query SignupTokenFlow($uid: String!, $token: String!) {
    signupTokenFlow(uid: $uid, token: $token)
  }
`;

const COMPLETE_SIGNUP_MUTATION = gql`
  mutation CompleteSignup(
    $uid: String!
    $token: String!
    $password: String!
    $firstName: String!
    $lastName: String!
    $phoneNumber: String
    $organizationName: String
  ) {
    completeSignup(
      uid: $uid
      token: $token
      password: $password
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      organizationName: $organizationName
    ) {
      user {
        id
        email
        organization {
          id
        }
      }
      error
    }
  }
`;

const FormContainer = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`;

const TOS = styled.div`
  color: ${colorTokens.white};
  font-size: 12px;
  line-height: 1.83;
  margin-top: 24px;
  text-align: center;
`;

const FormFooter = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledPasswordInput = styled(PasswordInput)`
  & input {
    &:hover {
      border-color: ${props => props.theme.primaryColor} !important;
    }
  }
`;

interface CompleteSignupVariables {
  uid: string;
  token: string;
  password: string;
  firstName: string;
  lastName: string;
  phoneNumber?: string;
  organizationName?: string;
}

interface Params extends Record<string, string> {
  uid: string;
  token: string;
}

interface Fields {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  organizationName: string;
  password: string;
}

type Props = FormComponentProps;

const CompleteSignupForm = (props: Props) => {
  const navigate = useNavigate();
  const { uid, token } = useParams<Params>();
  const { getFieldDecorator } = props.form;
  const { getSetupDataSource, getDashboard } = usePaths();
  const [completeSignup, { loading: submitting }] = useMutation(
    COMPLETE_SIGNUP_MUTATION,
    {
      client: publicClient,
      refetchQueries: [{ query: AUTH_STATUS }],
      awaitRefetchQueries: true,
      onCompleted: data => {
        const { user, error } = data.completeSignup;
        if (user) {
          setTrackingUserId(user.id);
          if (flow === Flow.INITIAL) {
            navigate(getSetupDataSource());
          } else {
            navigate(getDashboard());
          }
        } else {
          Message.error(error || "An unknown error occurred, please try again.");
        }
      }
    }
  );

  const { loading, data, error } = useQuery(SIGNUP_TOKEN_FLOW, {
    client: publicClient,
    variables: { uid, token }
  });
  if (loading) {
    return null;
  }
  if (error) {
    Message.error("An error occurred while confirming your email, please try again.");
    return null;
  }

  const flow = data.signupTokenFlow;
  if (flow === Flow.EXPIRED) {
    Message.error("This verification link has expired, please try again.");
    navigate(Routes.SIGNUP);
    return null;
  }

  const validateConfirmPassword = (
    rule: any,
    value: string,
    callback: (msg?: string) => void
  ) => {
    const form = props.form;
    if (value && value !== form.getFieldValue("password")) {
      callback(VALIDATION_MESSAGES.confirmPassword);
    } else {
      callback();
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    props.form.validateFields((errors: any, values: Fields) => {
      if (errors) {
        return;
      }

      const variables: CompleteSignupVariables = {
        uid: uid!,
        token: token!,
        password: values.password,
        firstName: values.firstName,
        lastName: values.lastName
      };
      if (flow === Flow.INITIAL) {
        variables.phoneNumber = values.phoneNumber;
        variables.organizationName = values.organizationName;
      }

      completeSignup({ variables });
    });
  };

  return (
    <React.Fragment>
      <DarkTheme />
      <FormContainer>
        <Form onSubmit={handleSubmit}>
          {flow === Flow.INITIAL && (
            <Row>
              <Col span={24}>
                <FormItem>
                  {getFieldDecorator("organizationName", {
                    validateTrigger: "onSubmit",
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: VALIDATION_MESSAGES.requiredField
                      }
                    ]
                  })(<Input placeholder="Company name" disabled={submitting} />)}
                </FormItem>
              </Col>
            </Row>
          )}
          <Row gutter={8}>
            <Col span={12}>
              <FormItem>
                {getFieldDecorator("firstName", {
                  validateTrigger: "onSubmit",
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: VALIDATION_MESSAGES.requiredField
                    }
                  ]
                })(
                  <Input
                    placeholder="First name"
                    autoComplete="given-name"
                    disabled={submitting}
                  />
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem>
                {getFieldDecorator("lastName", {
                  validateTrigger: "onSubmit",
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: VALIDATION_MESSAGES.requiredField
                    }
                  ]
                })(
                  <Input
                    placeholder="Last name"
                    autoComplete="family-name"
                    disabled={submitting}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          {flow === Flow.INITIAL && (
            <Row>
              <Col span={24}>
                <FormItem>
                  {getFieldDecorator(
                    "phoneNumber",
                    {}
                  )(
                    <Input
                      placeholder="Phone number"
                      autoComplete="tel"
                      disabled={submitting}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
          )}
          <Row>
            <Col span={24}>
              <FormItem>
                {getFieldDecorator("password", {
                  validateTrigger: "onSubmit",
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: VALIDATION_MESSAGES.requiredField
                    },
                    {
                      min: 8,
                      message: "Password must be at least 8 characters"
                    }
                  ]
                })(
                  <StyledPasswordInput
                    inputProps={{
                      placeholder: "Password",
                      type: "password",
                      disabled: submitting,
                      autoComplete: "new-password"
                    }}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormItem>
                {getFieldDecorator("confirmPassword", {
                  validateTrigger: "onSubmit",
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: VALIDATION_MESSAGES.requiredField
                    },
                    { validator: validateConfirmPassword }
                  ]
                })(
                  <Input
                    placeholder="Confirm password"
                    type="password"
                    disabled={submitting}
                    autoComplete="new-password"
                  />
                )}
              </FormItem>
            </Col>
          </Row>

          <FormFooter>
            <ButtonNew block htmlType="submit" type="brand" loading={submitting}>
              Next
            </ButtonNew>
            <TOS>
              By clicking “Next” you agree to our{" "}
              <AuthAnchor href="/tos" target="_blank">
                Terms of Service
              </AuthAnchor>{" "}
              and{" "}
              <AuthAnchor href="/privacy" target="_blank">
                Privacy Policy
              </AuthAnchor>
              .
            </TOS>
          </FormFooter>
        </Form>
      </FormContainer>
    </React.Fragment>
  );
};

export default Form.create<FormComponentProps>({
  name: "completeSignupForm"
})(CompleteSignupForm);
