import React from "react";

import { Checkbox, Col, Tooltip } from "antd";
import Column from "antd/lib/table/Column";

import { RoleNode } from "../../../../types";
import { useEnvironmentContext } from "../../../common/contexts/EnvironmentContext";
import Message from "../../../common/Message";
import {
  FunctionParameterRow,
  getFunctionParameterRow
} from "../../../common/Permissions/permissions";
import {
  FunctionPolicyActionTypes,
  PolicySpaceFunctions,
  PolicySpaceReducerAction
} from "../../../common/Permissions/reducer";
import { RightCol } from "../../../common/Permissions/styledComponents";
import { B3 } from "../../../common/StyledComponents";
import { HelpIcon } from "../SpaceDetail/styledComponents";

import {
  AutoGrantAllHeaderSwitch,
  FlexRow,
  PermissionsTable,
  StyledInternalEmpty,
  StyledTableRow,
  StyledTableRowHeader
} from "./styledComponents";
import {
  EnvironmentOptionType,
  useFunctionPoliciesForSpace
} from "./useSpaceRolePolicies";

interface Props {
  dispatch: (action: PolicySpaceReducerAction) => void;
  environmentOptionType: EnvironmentOptionType;
  page: number;
  pageSize: number;
  role: RoleNode;
  searchText: string | undefined;
  spaceId: string;
  state: PolicySpaceFunctions;
}

const NO_DISABLE_PERMISSIONS_FOR_ADMIN = "Permissions cannot be disabled for Admins";

export default function PermissionsTableWithEnvironmentContext({
  dispatch,
  environmentOptionType,
  page,
  pageSize,
  role,
  searchText,
  spaceId,
  state
}: Props) {
  const [expandedRowKeys, setExpandedRowKeys] = React.useState<string[]>([]);
  const roleIsAdmin = role.isManaged && role.slug === "admin";
  const { getCurrentEnvironment } = useEnvironmentContext();

  const environmentOption = {
    type: environmentOptionType,
    payload: getCurrentEnvironment()
  };

  const { loading, error } = useFunctionPoliciesForSpace({
    spaceId: spaceId,
    environment: environmentOption,
    roleId: role.id,
    first: pageSize,
    offset: pageSize * (page - 1),
    searchText: searchText,
    dispatch
  });

  const dataSource = state.functions.map(func =>
    getFunctionParameterRow(
      func,
      action => dispatch(action),
      expandedRowKeys,
      roleIsAdmin
    )
  );

  React.useEffect(() => {
    if (error) Message.error("Something went wrong. Please try again.");
  }, [error]);

  return (
    <PermissionsTable
      data-test="spaceRowPermissionContent"
      dataSource={dataSource}
      pagination={false}
      indentSize={0}
      components={{
        header: {
          row: StyledTableRow,
          cell: StyledTableRowHeader
        },
        body: {
          row: StyledTableRow
        }
      }}
      locale={{
        emptyText: <StyledInternalEmpty content="No functions to display" />
      }}
      onRow={record => {
        const functionRow = record as FunctionParameterRow;
        return {
          onClick: () => {
            // Don't expand beyond top level
            if (functionRow.level > 0) {
              return;
            }

            const expanded = expandedRowKeys.includes(functionRow.id);
            if (expanded) {
              setExpandedRowKeys([]);
            } else if (functionRow.fieldsCount) {
              // Only if it has fields, expand.
              setExpandedRowKeys([functionRow.id]);
            }
          }
        };
      }}
      loading={loading}
      expandedRowKeys={expandedRowKeys}
      expandIcon={() => <></>}
      rowKey="id"
      rowClassName={record => {
        const element = record as { id: string };
        return expandedRowKeys.includes(element.id) ? "expanded" : "";
      }}
    >
      <Column
        title="Function"
        dataIndex="name"
        width="28%"
        render={text => <B3>{text}</B3>}
      />
      <Column
        title="Data source"
        dataIndex="dataSource"
        width="20%"
        render={text => <B3>{text}</B3>}
      />
      <Column
        className="full-width"
        title={() => (
          <FlexRow type="flex" justify="space-between">
            <Col>
              <Tooltip
                title={roleIsAdmin ? NO_DISABLE_PERMISSIONS_FOR_ADMIN : undefined}
              >
                <Checkbox
                  data-test="allPermissionsCheckBox"
                  checked={roleIsAdmin || state.fieldsEnabled === "all"}
                  disabled={roleIsAdmin}
                  indeterminate={state.fieldsEnabled === "some"}
                  onClick={() =>
                    dispatch({
                      type: FunctionPolicyActionTypes.TOGGLE_ALL_FIELDS_FOR_ROLE
                    })
                  }
                >
                  Parameters and attributes
                </Checkbox>
              </Tooltip>
            </Col>

            <RightCol>
              Auto-grant all
              <Tooltip title="Turning Auto-grant all on will enable access to all parameters and attributes (current and future)">
                <HelpIcon type="question-circle" />
              </Tooltip>
              <Tooltip
                title={roleIsAdmin ? NO_DISABLE_PERMISSIONS_FOR_ADMIN : undefined}
              >
                <AutoGrantAllHeaderSwitch
                  data-test="autoGrantToggle"
                  disabled={roleIsAdmin}
                  checked={roleIsAdmin || state.autoGrant === "all"}
                  indeterminate={state.autoGrant === "some"}
                  onClick={() =>
                    dispatch({
                      type: FunctionPolicyActionTypes.SET_ALLOW_ALL_FOR_ROLE,
                      payload: {
                        allowAll: state.autoGrant !== "all"
                      }
                    })
                  }
                >
                  Parameters and attributes
                </AutoGrantAllHeaderSwitch>
              </Tooltip>
            </RightCol>
          </FlexRow>
        )}
        dataIndex="permission"
      />
    </PermissionsTable>
  );
}
