import React from "react";

import { SpaceComponentObject, ConfigValidationError } from "../../../../../../types";
import { findInvalidInputBindings } from "../../../../SpaceRoot/RenderTreeContext/util";
import { useStableSpaceContext } from "../../../../SpaceRoot/SpaceContext";
import { collectComponents } from "../../../../SpaceRoot/SpaceContext/SpaceContext";
import { fromComponents, findComponentNodeBySlug } from "../../../../util/tree";
import { BaseSpaceConfig } from "../reducer/reducer";

export default function useConfigErrors(
  spaces: Map<string, BaseSpaceConfig>,
  componentTreeNodes: SpaceComponentObject[]
): Record<string, ConfigValidationError[]> {
  const renderTree = React.useMemo(
    () => fromComponents(componentTreeNodes),
    [componentTreeNodes]
  );

  // componentTreeNodes provides the SpaceComponentObject graph where
  // all parent / child relationships have been connected and must be
  // used to provide the component / config state passed to error selectors
  const componentLookup = React.useMemo(() => {
    const componentNodes = collectComponents(componentTreeNodes);
    return Object.fromEntries(componentNodes.map(cn => [cn.slug, cn]));
  }, [componentTreeNodes]);

  const { findSpaceComponentPackage } = useStableSpaceContext();

  const wrappedFindInvalidBindings = React.useCallback(
    (component: SpaceComponentObject) =>
      findInvalidInputBindings(
        findComponentNodeBySlug(renderTree, component.slug)!,
        renderTree,
        spaces
      ),
    [renderTree, spaces]
  );

  return React.useMemo(
    () =>
      Object.fromEntries(
        Array.from(spaces.values())
          .flatMap(spaces => Array.from(spaces.components.values()))
          .map(config => {
            const pkg = findSpaceComponentPackage(config.draftComponent.type);
            if (!pkg)
              throw new Error(
                `Expected package for type: ${config.draftComponent.type}`
              );
            const component = componentLookup[config.draftComponent.slug];
            // TODO: Bandaid to handle view child components
            if (!component) {
              return [config.draftComponent.slug, []];
            }
            return [
              config.draftComponent.slug,
              pkg.errorSelector(
                {
                  ...config,
                  draftComponent: componentLookup[config.draftComponent.slug]!
                },
                wrappedFindInvalidBindings,
                componentLookup
              )
            ];
          })
      ),
    [spaces, componentLookup, findSpaceComponentPackage, wrappedFindInvalidBindings]
  );
}
