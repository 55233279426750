import React, { useCallback, useEffect, useState } from "react";

import { useQuery } from "@apollo/react-hooks";
import { Button, Input, Modal, Select, Tooltip } from "antd";
import { ApolloError } from "apollo-client";
import { isEqual } from "lodash";
import SplitPane from "react-split-pane";

import { DARK_THEME_POPPER_PORTAL_ID } from "../../../../constants";
import {
  FETCH_DATA_PROVIDERS_AND_BASE_FUNCTIONS,
  FetchDataProvidersAndBaseFunctionsData
} from "../../../../graphql/queries";
import {
  BaseFunctionName,
  BaseFunctionNodeBasic,
  FunctionNode,
  Metadata
} from "../../../../types";
import { CacheUpdaterFunctionNode } from "../../../spaces/SpaceConfig/LeftMenu/AppDataFlyout/FunctionList/queries";
import { VALIDATION_MESSAGES } from "../../../util/ClientValidator";
import { CacheUpdaterFn } from "../../../util/updateCache";
import useCloneFunction from "../../hooks/useCloneFunction";
import Message from "../../Message";
import usePrompt from "../../usePrompt";
import { DataSourceNodeWithFunctions } from "../forms/types";
import FunctionExecuteConfig from "../FunctionExecuteConfig";
import {
  BaseCode,
  FunctionEditor,
  FunctionValidationStatus,
  ParameterValues
} from "../index";
import { SupportedIntegration } from "../support";
import { FunctionFragmentNode } from "../useFunctionEditor/queries";
import { State } from "../useFunctionEditor/reducer";
import { useFunctionEditor } from "../useFunctionEditor/useFunctionEditor";
import { getDefaultFunctionState, getFunctionState } from "../useFunctionEditor/util";
import useFunctionPreviewExecuter from "../useFunctionPreviewExecuter/useFunctionPreviewExecuter";

import { useGetFunctionByIdWithDataSource } from "./queries";
import * as styled from "./styledComponents";
import { selectSupportedDataSources } from "./util";

export interface InitializePipelineProps {
  functionId: string;
}

export interface CloneFunctionProps {
  dataSourceId?: string;
  functionId: string;
}

interface Props {
  key: string;
  cacheUpdater?: CacheUpdaterFn<CacheUpdaterFunctionNode>;
  cacheEvictor?: CacheUpdaterFn<string>;
  dataSourceId?: string;
  functionId?: string;
  initializePipeline?: InitializePipelineProps;
  onClone: (options: CloneFunctionProps) => void;
  onClose: () => void;
  onSave?: (func: FunctionFragmentNode<Metadata<BaseCode>>) => void;
}

const validate = (
  state: Readonly<State>,
  dataSource: any | null,
  editorHasError: boolean
) => {
  const errors = {
    count: 0,
    fields: { title: "", dataSourceId: "", editor: "" }
  };
  if (!state.title) {
    errors.fields.title = VALIDATION_MESSAGES.requiredField;
    errors.count++;
  }
  if (!dataSource) {
    errors.fields.dataSourceId = VALIDATION_MESSAGES.requiredField;
    errors.count++;
  }
  if (editorHasError) {
    errors.fields.editor = "The editor has unresovled errors.";
    errors.count++;
  }
  return errors;
};

export const FunctionEditorModal = ({
  functionId,
  dataSourceId,
  initializePipeline,
  onSave: onCallbackSave,
  cacheUpdater,
  cacheEvictor,
  onClone,
  onClose
}: Props) => {
  const [initialPipelineStepFunction, setInitialPipelineStepFunction] = useState<
    FunctionNode | undefined
  >(undefined);

  const [validationStatus, setValidationStatus] = useState<FunctionValidationStatus>(
    FunctionValidationStatus.VALID
  );

  const [showErrors, setShowErrors] = useState(false);
  const [editorHasError, setEditorHasError] = useState(false);
  const [createError, setCreateError] = useState("");
  const [dataSources, setDataSources] = useState<
    DataSourceNodeWithFunctions<BaseFunctionNodeBasic>[]
  >([]);
  const [selectedDataSource, setSelectedDataSource] =
    useState<DataSourceNodeWithFunctions<BaseFunctionNodeBasic> | null>(null);

  const editor = useFunctionEditor(functionId, cacheUpdater, cacheEvictor);

  const errors = validate(editor.state, selectedDataSource, editorHasError);
  const isEditing = !!editor.state.functionId;
  const menuTitle = isEditing ? `Function: ${editor.state.title}` : "Create a Function";

  const [environmentId, setEnvironmentId] = useState<string | undefined>();
  const [parameterValues, setParameterValues] = useState<ParameterValues>({});
  const { state, loading, execute } = useFunctionPreviewExecuter<BaseCode>({
    authorizationFlows: editor.state.authorizationFlows,
    parameters: editor.state.parameters,
    onPreviewResult: editor.onPreviewResult
  });

  const executeFunction = useCallback(() => {
    if (!selectedDataSource || !environmentId) {
      return;
    }

    execute({
      baseFunctionId: editor.state.baseFunctionId,
      baseFunctionName: editor.state.baseFunctionName as BaseFunctionName,
      environmentId: environmentId,
      integration: selectedDataSource.integration,
      reducer: editor.state.reducer,
      metadata: editor.state.metadata,
      parameterValues: parameterValues,
      baseFunctionParameterMapping: editor.state.baseFunctionParameterMapping,
      metadataReducer: editor.state.metadataReducer,
      pipelineSteps: editor.state.pipelineSteps
    });
  }, [editor, environmentId, execute, selectedDataSource, parameterValues]);

  const { loading: dataSourcesLoading } =
    useQuery<FetchDataProvidersAndBaseFunctionsData>(
      FETCH_DATA_PROVIDERS_AND_BASE_FUNCTIONS,
      {
        fetchPolicy: "network-only",
        onCompleted: data => {
          const dataSources = selectSupportedDataSources(data);
          setDataSources(dataSources);

          if (dataSourceId) {
            const selectedDataSource = dataSources.find(ds => ds.id === dataSourceId);
            if (selectedDataSource) {
              setSelectedDataSource(selectedDataSource);
              if (!functionId) {
                editor.onDataSourceChange(selectedDataSource);
              }
            }
          }
        }
      }
    );

  const onDataSourceSelected = useCallback(
    (dataSourceId: string) => {
      const dataSource = dataSources.find(ds => ds.id === dataSourceId);
      if (!dataSource) {
        throw new Error(
          `FunctionConfigFlyout: could not find dataSource: ${dataSourceId}`
        );
      }
      setSelectedDataSource(dataSource);
      editor.onDataSourceChange(dataSource);
    },
    [dataSources, editor]
  );

  const { loading: initializePipelineLoading } = useGetFunctionByIdWithDataSource({
    variables: {
      id: initializePipeline?.functionId || ""
    },
    skip: !initializePipeline?.functionId,
    onCompleted: data => {
      if (data) {
        setInitialPipelineStepFunction(data.node);
      }
    }
  });

  useEffect(() => {
    if (
      dataSources.length &&
      initialPipelineStepFunction &&
      !editor.state.pipelineSteps?.length
    ) {
      const pipelinesDataSource = dataSources.find(
        ds => ds.integration === SupportedIntegration.PIPELINES
      );

      if (pipelinesDataSource && !editor.state.functionId) {
        onDataSourceSelected(pipelinesDataSource.id);
        editor.onAddLoadedPipelineStep(
          initialPipelineStepFunction,
          initialPipelineStepFunction.dataSource!
        );
        editor.onSelectPipelineStep(0);
        // Clear out the initial function data so we don't keep setting it
        setInitialPipelineStepFunction(undefined);
      }
    }
  }, [
    initialPipelineStepFunction,
    selectedDataSource,
    dataSources,
    editor,
    onDataSourceSelected,
    setInitialPipelineStepFunction
  ]);

  const _onBaseFunctionNameChange = useCallback(
    (name: BaseFunctionName) => {
      if (!selectedDataSource) return null;

      editor.onBaseFunctionNameChange(selectedDataSource, name);
    },
    [editor, selectedDataSource]
  );

  const onSaveMutationError = useCallback(
    (error: ApolloError) => {
      const e = error.graphQLErrors?.length ? error.graphQLErrors[0] : undefined;
      const GENERAL_ERROR = "An error occurred while trying to save your function";
      if (!e) {
        return setCreateError(`${GENERAL_ERROR}.`);
      }
      const message =
        e.message.indexOf("duplicate key value") > -1
          ? `"${editor.state.title}" already exists.`
          : e.message;
      setCreateError(`${GENERAL_ERROR}: ${message}`);
    },
    [editor.state.title]
  );

  const onSave = useCallback(async () => {
    setShowErrors(true);

    if (errors.count) {
      return;
    }

    setCreateError("");
    try {
      const result = await editor.save();
      onCallbackSave?.(result);
      Message.success("Saved");
    } catch (e) {
      onSaveMutationError(e as ApolloError);
    }
  }, [errors.count, editor, onCallbackSave, onSaveMutationError]);

  const [cloneFunction] = useCloneFunction({
    refetchQueries: ["FetchFunctions"],
    onCompleted: data => {
      dataSourceId &&
        onClone({ dataSourceId, functionId: data.cloneFunction.function.id });
    }
  });

  const onDelete = useCallback(() => {
    Modal.confirm({
      getContainer: document.getElementById(DARK_THEME_POPPER_PORTAL_ID),
      title: "Are you sure?",
      content:
        "Your function will be deleted.  Spaces that use this function will need to be updated.",
      okText: "Delete",
      cancelText: "Cancel",
      mask: false,
      maskClosable: true,
      onOk: async () => {
        setCreateError("");
        try {
          await editor.delete();
          Message.success("Deleted");
          onClose();
        } catch (e) {
          setCreateError("An error occurred while deleting your function.");
        }
      }
    });
  }, [editor, setCreateError, onClose]);

  const hasUnsavedChanges = () => {
    if (editor.state.lastSavedFunctionState) {
      return !isEqual(
        editor.state.lastSavedFunctionState,
        getFunctionState(editor.state)
      );
    } else if (selectedDataSource && editor.state.baseFunctionName) {
      const defaultState = getDefaultFunctionState(
        selectedDataSource,
        editor.state.baseFunctionName
      );
      return !isEqual(defaultState, getFunctionState(editor.state));
    } else {
      return !!editor.state.title;
    }
  };

  usePrompt(
    {
      getContainer: document.getElementById(DARK_THEME_POPPER_PORTAL_ID),
      title: "Are you sure?",
      content: "Any unsaved work will be lost.",
      okText: "Discard changes",
      cancelText: "Continue editing"
    },
    hasUnsavedChanges()
  );

  return (
    <styled.Container>
      <styled.HeaderSection data-test="functionEditorHeader">
        <styled.MenuTitle>
          <div data-test="functionEditorTitle">{menuTitle}</div>
          <div>
            <styled.TitleLinkButton
              data-test="closeButton"
              type="link"
              onClick={onClose}
            >
              Close
            </styled.TitleLinkButton>
            <Button
              data-test="saveButton"
              type="primary"
              onClick={onSave}
              loading={editor.isSaving}
            >
              Save
            </Button>
            {functionId && (
              <Tooltip title="Duplicate function" trigger="hover" placement="bottom">
                <styled.TitleLinkButton
                  data-test="copyFunction"
                  type="link"
                  icon="copy"
                  onClick={() => cloneFunction({ variables: { functionId } })}
                />
              </Tooltip>
            )}
            <Tooltip title="Delete function" trigger="hover" placement="bottom">
              <styled.TitleLinkButton
                data-test="deleteFunction"
                type="link"
                icon="delete"
                loading={editor.isDeleting}
                onClick={onDelete}
                disabled={!isEditing}
              />
            </Tooltip>
          </div>
        </styled.MenuTitle>
      </styled.HeaderSection>

      <styled.LeftContentWrapper>
        <SplitPane
          split="horizontal"
          primary="second"
          defaultSize={selectedDataSource ? "max(35%, 160px)" : 0}
          minSize={selectedDataSource ? 160 : 0}
        >
          <styled.SplitPane
            showResizer={!!selectedDataSource}
            split="vertical"
            primary="second"
            defaultSize={selectedDataSource ? "max(25%, 300px)" : 0}
            minSize={300}
            pane1Style={{ overflow: "auto" }}
          >
            <styled.CenterSection>
              <div>
                <styled.ErrorMessageFieldWithMargin errorMessage={createError} />
                {createError && (
                  <styled.DismissButton
                    data-test="dismissButton"
                    icon="close-circle"
                    type="link"
                    onClick={() => setCreateError("")}
                  />
                )}
              </div>
              <styled.NameSection>
                <styled.FieldWithErrorGrow
                  errorMessage={showErrors ? errors.fields.title : ""}
                >
                  <Input
                    data-test="nameInput"
                    placeholder="Give your function a name"
                    onChange={evt => editor.onTitleChange(evt.target.value)}
                    value={editor.state.title}
                  />
                </styled.FieldWithErrorGrow>
                <styled.FieldWithErrorGrow
                  errorMessage={showErrors ? errors.fields.dataSourceId : ""}
                >
                  <Select
                    data-test="dataSourceSelect"
                    placeholder="Select a data source"
                    onChange={onDataSourceSelected}
                    getPopupContainer={trigger => trigger.parentNode as HTMLElement}
                    value={selectedDataSource?.id}
                    disabled={isEditing}
                    loading={dataSourcesLoading || initializePipelineLoading}
                  >
                    {dataSources.map(dataSource => (
                      <Select.Option value={dataSource.id} key={dataSource.id}>
                        {dataSource.name}
                      </Select.Option>
                    ))}
                  </Select>
                </styled.FieldWithErrorGrow>
                <styled.HelpPrompt>
                  <styled.HelpIcon />
                  <span>
                    Need help? Check out the{" "}
                    <a
                      href="https://www.internal.io/docs/functions"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      docs
                    </a>
                    .
                  </span>
                </styled.HelpPrompt>
              </styled.NameSection>

              {selectedDataSource && (
                <FunctionEditor
                  isLoading={editor.isLoading}
                  dataSource={selectedDataSource}
                  editorState={editor.state}
                  showErrors={showErrors}
                  validationStatus={validationStatus}
                  setValidationStatus={setValidationStatus}
                  onHasError={setEditorHasError}
                  onBaseFunctionNameChange={_onBaseFunctionNameChange}
                  onBaseConfigChange={editor.onBaseConfigChange}
                  onMetadataChange={editor.onMetadataChange}
                  onReducerChange={editor.onReducerChange}
                  onMetadataReducerChange={editor.onMetadataReducerChange}
                  onParameterChange={editor.onParameterChange}
                  onAttributesChange={editor.onAttributesChange}
                  onPreviewResult={editor.onPreviewResult}
                  onRefreshAttributes={editor.onRefreshAttributes}
                  onActiveEditorTabChange={editor.onActiveEditorTabChange}
                  onReturnSchemaChange={editor.onReturnSchemaChange}
                  onAuthorizationFlowChange={editor.onAuthorizationFlowChange}
                  onDescribeColumnsChange={editor.onDescribeColumnsChange}
                  onAddPipelineStep={editor.onAddPipelineStep}
                  onRemovePipelineStep={editor.onRemovePipelineStep}
                  onSelectPipelineStep={editor.onSelectPipelineStep}
                  onSelectPipelineStepCondition={editor.onSelectPipelineStepCondition}
                  onSetPipelineStepName={editor.onSetPipelineStepName}
                  onSetPipelineStepType={editor.onSetPipelineStepType}
                  onSetPipelineStepDataSource={editor.onSetPipelineStepDataSource}
                  onSetPipelineStepFunction={editor.onSetPipelineStepFunction}
                  onSetPipelineStepExpression={editor.onSetPipelineStepExpression}
                  onSetPipelineStepInputParameters={
                    editor.onSetPipelineStepInputParameters
                  }
                  onSetPipelineStepCondition={editor.onSetPipelineStepCondition}
                  onSetPipelineStepConditionActionType={
                    editor.onSetPipelineStepConditionActionType
                  }
                  onSetPipelineStepConditionFunction={
                    editor.onSetPipelineStepConditionFunction
                  }
                  onUpdatePipelineParameter={editor.onUpdatePipelineParameter}
                  onLoadPipelineStepConditionFunction={
                    editor.onLoadPipelineStepConditionFunction
                  }
                  onSetPipelineStepConditionInputParameters={
                    editor.onSetPipelineStepConditionInputParameters
                  }
                  onSetPipelineStepIteratorBinding={
                    editor.onSetPipelineStepIteratorBinding
                  }
                  onSetPipelineStepIteratorItemName={
                    editor.onSetPipelineStepIteratorItemName
                  }
                />
              )}
            </styled.CenterSection>

            {selectedDataSource ? (
              <FunctionExecuteConfig
                environmentId={environmentId}
                parameters={editor.state.parameters}
                filtersOptions={editor.state.metadata.filters?.options || []}
                sortByOptions={editor.state.metadata.sortBy?.options || []}
                functionExecuting={loading}
                baseFunctionId={editor.state.baseFunctionId}
                validationStatus={validationStatus}
                setEnvironmentId={setEnvironmentId}
                parameterValues={parameterValues}
                setParameterValues={setParameterValues}
                executeFunction={executeFunction}
              />
            ) : (
              <div></div>
            )}
          </styled.SplitPane>
          <styled.OverlayFunctionPreview state={state} functionExecuting={loading} />
        </SplitPane>
      </styled.LeftContentWrapper>
    </styled.Container>
  );
};
