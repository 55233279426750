/* eslint-disable */
export const APP_ID = "tt4z6ve5";

window.intercomSettings = {
  app_id: APP_ID
};

export default function installIntercom() {
  const w = window;
  const ic = w.Intercom;
  if (typeof ic === "function") {
    ic("reattach_activator");
    ic("update", w.intercomSettings);
  } else {
    const d = document;
    var i: any = function () {
      i.c(arguments);
    };
    i.q = [];
    i.c = function (args: any) {
      i.q.push(args);
    };
    w.Intercom = i;
    const l = function () {
      const s = d.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src = "https://widget.intercom.io/widget/" + APP_ID;
      const x: any = d.getElementsByTagName("script")[0];
      x.parentNode.insertBefore(s, x);
    };
    if (document.readyState === "complete") {
      l();
    } else if ((w as any).attachEvent) {
      (w as any).attachEvent("onload", l);
    } else {
      w.addEventListener("load", l, false);
    }
  }
}
