import {
  SpaceComponentObject,
  ConfigValidationError
} from "../../../../../../../types";
import { ComponentConfigState, EventHandler } from "../../../../../types";
import selectEffectsErrors from "../../effects/selectErrors";
import { PointerEventType } from "../../effects/usePointerEffects/usePointerEffects";
import selectParameterErrors from "../../ParametersManager/selectors/selectErrors";
import { selectViewConfigErrors } from "../../useViewConfig";
import selectVisibilityRuleErrors from "../../VisibilityRulesManager/selectErrors";
import {
  selectMergedFunctions,
  ensureSubmittableComponentConfigState
} from "../reducer/reducer";

export const errorSelector = (
  state: ComponentConfigState,
  findInvalidInputBindings: (c: SpaceComponentObject) => string[],
  componentLookup: Record<string, SpaceComponentObject>
): ConfigValidationError[] => {
  const submittableComponentConfigState = ensureSubmittableComponentConfigState(state);
  const { draftComponent } = submittableComponentConfigState;
  const errors: ConfigValidationError[] = [];
  const func = state.draftComponent.functions.edges[0]?.node;
  if (draftComponent.type === "FUNCTION_BUTTON") {
    // Allow no function if an on click effect is present
    const effects = draftComponent.properties.effects as EventHandler[];
    // TODO: `any` is removeable after MODAL feature flag is removed
    const hasClickEffect = effects.some(
      eff => eff.type === (PointerEventType.CLICK as any)
    );
    if (!hasClickEffect && !func?.id.length) {
      errors.push({
        field: "FUNCTION",
        message: "Please select a function or add an on click effect."
      });
    }
  } else {
    if (!func?.id.length) {
      errors.push({
        field: "FUNCTION",
        message: "Please select a function."
      });
    }
  }

  const mergedFns = selectMergedFunctions(submittableComponentConfigState);
  if (mergedFns.some(mf => mf.type === "MISSING")) {
    errors.push({
      field: "FUNCTION",
      message: "Some selected functions no longer exist. Please remove them."
    });
  }
  if (state.draftComponent.type.includes("BULK")) {
    errors.push(...selectViewConfigErrors(draftComponent));
  }

  return errors
    .concat(selectVisibilityRuleErrors(draftComponent))
    .concat(
      selectEffectsErrors(draftComponent, findInvalidInputBindings, componentLookup)
    )
    .concat(selectParameterErrors(state, draftComponent, findInvalidInputBindings));
};
