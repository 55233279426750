import React, { MutableRefObject } from "react";

import { isEqual } from "lodash";
import styled from "styled-components";

import { SpaceComponentObject } from "../../../../types";
import usePrevious from "../../../common/hooks/usePrevious";
import { useComponentPathContext } from "../../SpaceRoot/SpaceComponent/contexts/ComponentPathContext";
import { useCanvasViewportContext } from "../Canvas/CanvasViewportContext";
import Draggable from "../Draggable";
import { useLayoutContext } from "../LayoutContext/LayoutContext";
import { useSelectionStateContext } from "../TransformationContext/TransformationContext";
import { subtractPoints, addPoints } from "../util";

import Tag, { TagMode } from "./Tag";

interface SelectionProps {
  component: SpaceComponentObject;
  isHovered?: boolean;
  isSelected?: boolean;
  isActiveDropTarget?: boolean;
  resizeable?: boolean;
  transforming?: boolean;
  elRef: MutableRefObject<null | HTMLElement>;
  onResizeStart?: () => void;
  onResize?: (rect: DOMRect) => void;
  onResizeEnd?: () => void;
}

enum HandleName {
  "TOP_LEFT" = "topLeft",
  "TOP_RIGHT" = "topRight",
  "BOTTOM_RIGHT" = "bottomRight",
  "BOTTOM_LEFT" = "bottomLeft",
  "TOP" = "top",
  "RIGHT" = "right",
  "BOTTOM" = "bottom",
  "LEFT" = "left"
}

const initialSelectionState = {
  layoutOffset: new DOMPoint(),
  dragging: false,
  localDragStart: new DOMPoint(),
  dragHandleName: null as HandleName | null,
  fixedPoint: new DOMPoint(),
  elementRectStart: new DOMRect(),
  elementRectCurrent: new DOMRect()
};

type SelectionState = typeof initialSelectionState;

type SelectionAction =
  | {
      type: "START_DRAG";
      payload: {
        dragStart: DOMPoint;
        handleName: HandleName;
        elementRect: DOMRect;
      };
    }
  | { type: "DRAG"; payload: { dragCurrent: DOMPoint } }
  | { type: "END_DRAG" }
  | { type: "UPDATE_LAYOUT_OFFSET"; payload: { layoutOffset: DOMPoint } };

function selectionReducer(state: SelectionState, action: SelectionAction) {
  switch (action.type) {
    case "START_DRAG": {
      const { layoutOffset } = state;
      const {
        dragStart,
        handleName,
        elementRect: { width, height },
        elementRect
      } = action.payload;
      const elementOffset = subtractPoints(
        new DOMPoint(Math.round(elementRect.x), Math.round(elementRect.y)),
        layoutOffset
      );
      elementRect.x = elementOffset.x;
      elementRect.y = elementOffset.y;
      const offset = addPoints(layoutOffset, elementOffset);
      const localDragStart = subtractPoints(dragStart, offset);
      const { x, y } = localDragStart;

      const fixedPoint = new DOMPoint();
      switch (handleName) {
        case HandleName.TOP_RIGHT:
          fixedPoint.x = x - width;
          fixedPoint.y = y + height;
          break;
        case HandleName.BOTTOM_RIGHT:
          fixedPoint.x = x - width;
          fixedPoint.y = y - height;
          break;
        case HandleName.BOTTOM_LEFT:
          fixedPoint.x = x + width;
          fixedPoint.y = y - height;
          break;
        case HandleName.TOP_LEFT:
          fixedPoint.x = x + width;
          fixedPoint.y = y + height;
          break;
        case HandleName.TOP:
          fixedPoint.x = x;
          fixedPoint.y = y + height;
          break;
        case HandleName.RIGHT:
          fixedPoint.x = x - width;
          fixedPoint.y = y;
          break;
        case HandleName.BOTTOM:
          fixedPoint.x = x;
          fixedPoint.y = y - height;
          break;
        case HandleName.LEFT:
          fixedPoint.x = x + width;
          fixedPoint.y = y;
          break;
      }
      return {
        ...state,
        localDragStart,
        fixedPoint,
        dragHandleName: handleName,
        elementRectStart: elementRect
      };
    }

    case "DRAG": {
      const {
        localDragStart,
        fixedPoint,
        dragHandleName,
        layoutOffset,
        elementRectStart,
        elementRectCurrent
      } = state;
      const { dragCurrent } = action.payload;
      const elementOffsetStart = new DOMPoint(elementRectStart.x, elementRectStart.y);
      const offset = addPoints(layoutOffset, elementOffsetStart);
      const localDragCurrent = subtractPoints(dragCurrent, offset);

      const { width, height } = calcDimensions(
        localDragCurrent,
        fixedPoint,
        dragHandleName,
        elementRectStart
      );

      const { x, y } = calcOffset(
        elementOffsetStart,
        localDragStart,
        localDragCurrent,
        fixedPoint,
        dragHandleName
      );

      const elementRectNext = new DOMRect(x, y, width, height);
      if (isEqual(elementRectCurrent, elementRectNext)) return state;
      return {
        ...state,
        dragging: true,
        elementRectCurrent: elementRectNext
      };
    }

    case "END_DRAG": {
      return {
        ...state,
        dragging: false
      };
    }

    case "UPDATE_LAYOUT_OFFSET": {
      const { layoutOffset } = action.payload;
      if (state.dragging) return state;
      return {
        ...state,
        layoutOffset
      };
    }

    default:
      throw new Error();
  }
}

const MemoTag = React.memo(Tag);

export default function Selection(props: SelectionProps) {
  const { hovered, selected, activeDropTarget } = useSelectionStateContext();
  const path = useComponentPathContext();

  let isHovered = hovered === props.component.slug;
  const isSelected = selected === props.component.slug;
  const isActiveDropTarget = activeDropTarget?.path === path;
  // If there is an activeDropTarget turn off hovers so drop target is prominent
  if (!!activeDropTarget?.path) {
    isHovered = false;
  }

  if (!isHovered && !isSelected && !isActiveDropTarget) return null;

  return (
    <SelectionImpl
      isHovered={isHovered}
      isSelected={isSelected}
      isActiveDropTarget={isActiveDropTarget}
      {...props}
    />
  );
}

function SelectionImpl({
  component,
  elRef,
  resizeable = true,
  transforming = false,
  isHovered = false,
  isSelected = false,
  isActiveDropTarget = false,
  onResizeStart = () => {},
  onResize = () => {},
  onResizeEnd = () => {}
}: SelectionProps) {
  const [state, dispatch] = React.useReducer(selectionReducer, initialSelectionState);

  let tagMode: TagMode = "HIDDEN";
  if (isActiveDropTarget) {
    tagMode = "ACTIVE_DROP_TARGET";
  } else if (isSelected) {
    tagMode = "SELECTED";
  } else if (isHovered) {
    tagMode = "HOVERED";
  }

  return (
    <Root
      isActiveDropTarget={isActiveDropTarget}
      isHovered={isHovered}
      isSelected={isSelected}
    >
      <TagPositioner component={component} mode={tagMode} />
      {resizeable && isSelected && (
        <ResizeHandles
          dispatch={dispatch}
          transforming={transforming}
          state={state}
          elRef={elRef}
          onResizeStart={onResizeStart}
          onResize={onResize}
          onResizeEnd={onResizeEnd}
        />
      )}
    </Root>
  );
}

function TagPositioner({
  component,
  mode = "HIDDEN"
}: {
  component: SpaceComponentObject;
  mode: TagMode;
}) {
  return <div>{origin !== null && <MemoTag component={component} mode={mode} />}</div>;
}

function ResizeHandles({
  transforming,
  state,
  elRef,
  onResizeStart,
  onResize,
  onResizeEnd,
  dispatch
}: {
  transforming: boolean;
  state: SelectionState;
  elRef: MutableRefObject<HTMLElement | null>;
  onResizeStart: () => void;
  onResize: (rect: DOMRect) => void;
  onResizeEnd: () => void;
  dispatch: React.Dispatch<SelectionAction>;
}) {
  const { viewportOffset: layoutOffset } = useCanvasViewportContext();
  const { canvasScrollTop } = useLayoutContext();

  React.useEffect(() => {
    if (!state.dragging) return;
    onResize(state.elementRectCurrent);
  }, [state.dragging, state.elementRectCurrent, onResize]);

  React.useEffect(() => {
    dispatch({
      type: "UPDATE_LAYOUT_OFFSET",
      payload: {
        layoutOffset: new DOMPoint(layoutOffset.x, layoutOffset.y - canvasScrollTop)
      }
    });
  }, [layoutOffset, canvasScrollTop, dispatch]);

  const prevDragging = usePrevious(state.dragging);
  const shouldStartResize = state.dragging && !prevDragging;
  const shouldEndResize = !state.dragging && prevDragging;
  React.useEffect(() => {
    if (shouldStartResize) {
      onResizeStart();
    } else if (shouldEndResize) {
      onResizeEnd();
    }
  }, [shouldStartResize, shouldEndResize, onResizeStart, onResizeEnd]);

  return (
    <>
      {handleNames.map((hn: HandleName) => (
        <MemoResizeHandle
          key={hn}
          dispatch={dispatch}
          handleName={hn}
          elRef={elRef}
          transforming={transforming}
        />
      ))}
    </>
  );
}

interface ResizeHandleProps {
  dispatch: React.Dispatch<SelectionAction>;
  handleName: HandleName;
  transforming: boolean;
  elRef: MutableRefObject<HTMLElement | null>;
}

const MemoResizeHandle = React.memo(
  ResizeHandle,
  (prevProps: ResizeHandleProps, nextProps: ResizeHandleProps) => {
    if (nextProps.transforming) return true;
    return Object.entries(prevProps).every(p => {
      return nextProps[p[0] as keyof ResizeHandleProps] === p[1];
    });
  }
);

function ResizeHandle({ dispatch, elRef, handleName }: ResizeHandleProps) {
  const Handle = handleComponentMap[handleName];

  const handleDragStart = React.useCallback(
    function handleDragStart(dragStart: DOMPoint) {
      dispatch({
        type: "START_DRAG",
        payload: {
          dragStart,
          handleName,
          elementRect: elRef.current?.getBoundingClientRect() || new DOMRect()
        }
      });
    },
    [handleName, elRef, dispatch]
  );

  const handleDrag = React.useCallback(
    function handleDrag(dragCurrent: DOMPoint) {
      dispatch({
        type: "DRAG",
        payload: { dragCurrent }
      });
    },
    [dispatch]
  );

  const handleDragEnd = React.useCallback(
    function handleDragEnd() {
      dispatch({ type: "END_DRAG" });
    },
    [dispatch]
  );

  return (
    <Handle>
      <Draggable
        onDragStart={handleDragStart}
        onDrag={handleDrag}
        onDragEnd={handleDragEnd}
      >
        <InnerHandle />
      </Draggable>
    </Handle>
  );
}

function calcDimensions(
  dragCurrent: DOMPoint,
  dragStart: DOMPoint,
  dragHandleName: HandleName | null,
  originalRect: DOMRect
) {
  const dimensions = {
    width: Math.abs(dragCurrent.x - dragStart.x),
    height: Math.abs(dragCurrent.y - dragStart.y)
  };

  if (dragHandleName === HandleName.TOP || dragHandleName === HandleName.BOTTOM) {
    dimensions.width = originalRect.width;
  } else if (
    dragHandleName === HandleName.RIGHT ||
    dragHandleName === HandleName.LEFT
  ) {
    dimensions.height = originalRect.height;
  }

  return dimensions;
}

function calcOffset(
  elementOffsetStart: DOMPoint,
  dragStart: DOMPoint,
  dragCurrent: DOMPoint,
  fixedPoint: DOMPoint,
  handleName: HandleName | null
) {
  const offset = elementOffsetStart;
  const dragDistance = subtractPoints(dragCurrent, dragStart);
  let x = 0;
  let y = 0;

  switch (handleName) {
    case HandleName.TOP_LEFT:
      if (dragCurrent.x < fixedPoint.x) {
        x = offset.x + dragDistance.x;
      } else {
        x = offset.x + fixedPoint.x;
      }
      if (dragCurrent.y < fixedPoint.y) {
        y = offset.y + dragDistance.y;
      } else {
        y = offset.y + fixedPoint.y;
      }
      break;
    case HandleName.TOP_RIGHT:
      if (dragCurrent.x < fixedPoint.x) {
        x = offset.x + dragCurrent.x - fixedPoint.x;
      } else {
        x = offset.x;
      }
      if (dragCurrent.y < fixedPoint.y) {
        y = offset.y + dragDistance.y;
      } else {
        y = offset.y + fixedPoint.y;
      }
      break;
    case HandleName.BOTTOM_RIGHT:
      if (dragCurrent.x < fixedPoint.x) {
        x = offset.x + dragCurrent.x - fixedPoint.x;
      } else {
        x = offset.x;
      }
      if (dragCurrent.y < fixedPoint.y) {
        y = offset.y + dragCurrent.y - fixedPoint.y;
      } else {
        y = offset.y;
      }
      break;
    case HandleName.BOTTOM_LEFT:
      if (dragCurrent.x < fixedPoint.x) {
        x = offset.x + dragDistance.x;
      } else {
        x = offset.x + fixedPoint.x;
      }
      if (dragCurrent.y < fixedPoint.y) {
        y = offset.y + dragCurrent.y - fixedPoint.y;
      } else {
        y = offset.y;
      }
      break;
    case HandleName.TOP:
      if (dragCurrent.y < fixedPoint.y) {
        y = offset.y + dragDistance.y;
      } else {
        y = offset.y + fixedPoint.y;
      }
      x = offset.x;
      break;
    case HandleName.RIGHT:
      if (dragCurrent.x < fixedPoint.x) {
        x = offset.x + dragCurrent.x - fixedPoint.x;
      } else {
        x = offset.x;
      }
      y = offset.y;
      break;
    case HandleName.BOTTOM:
      if (dragCurrent.y < fixedPoint.y) {
        y = offset.y + dragCurrent.y - fixedPoint.y;
      } else {
        y = offset.y;
      }
      x = offset.x;
      break;
    case HandleName.LEFT:
      if (dragCurrent.x < fixedPoint.x) {
        x = offset.x + dragDistance.x;
      } else {
        x = offset.x + fixedPoint.x;
      }
      y = offset.y;
      break;
  }

  return new DOMPoint(x, y);
}

const handleNames = Object.keys(HandleName).map(
  k => (HandleName as any)[k]
) as HandleName[];

const SELECTION_GUTTER = 0;
const DEFAULT_OUTLINE_WIDTH = 1;
const SELECTED_OUTLINE_WIDTH = 2;

const HANDLE_SIZE = 10;
const HANDLE_BORDER_WIDTH = 1;
const HANDLE_BUFFER = 0;
const HANDLE_OFFSET = (HANDLE_SIZE - SELECTED_OUTLINE_WIDTH) / -2;
const SIDE_HANDLE_OFFSET = HANDLE_SIZE / 2;

function getOutline(props: {
  isHovered: boolean;
  isSelected: boolean;
  isActiveDropTarget: boolean;
  theme: any;
}) {
  const { isHovered, isSelected, isActiveDropTarget, theme } = props;
  if (!isHovered && !isSelected && !isActiveDropTarget) return undefined;
  let color = "";
  if (isSelected) {
    color = theme.primaryColor;
  } else if (isHovered) {
    color = theme.midGrey;
  } else if (isActiveDropTarget) {
    color = theme.primaryColor;
  }
  let outlineWidth = DEFAULT_OUTLINE_WIDTH;
  if (isSelected) {
    outlineWidth = SELECTED_OUTLINE_WIDTH;
  }
  return `solid ${outlineWidth}px ${color}`;
}

const Root = styled.div<{
  isHovered: boolean;
  isSelected: boolean;
  isActiveDropTarget: boolean;
}>`
  position: absolute;
  pointer-events: none;
  top: ${SELECTION_GUTTER}px;
  right: ${SELECTION_GUTTER}px;
  bottom: ${SELECTION_GUTTER}px;
  left: ${SELECTION_GUTTER}px;
  outline: ${props => getOutline(props)};
  outline-offset: -${props => (props.isSelected ? SELECTED_OUTLINE_WIDTH : DEFAULT_OUTLINE_WIDTH)}px;
  z-index: ${props => props.theme.zIndex.selection};
`;

const Handle = styled.div`
  position: absolute;
  width: ${HANDLE_SIZE + HANDLE_BUFFER * 2}px;
  height: ${HANDLE_SIZE + HANDLE_BUFFER * 2}px;
  padding: ${HANDLE_BUFFER}px;
  pointer-events: auto;
`;

const InnerHandle = styled.div`
  width: ${HANDLE_SIZE}px;
  height: ${HANDLE_SIZE}px;
  background: white;
  border: solid ${HANDLE_BORDER_WIDTH}px ${props => props.theme.primaryColor};
`;

const TopLeftHandle = styled(Handle)`
  cursor: nwse-resize;
  top: ${HANDLE_OFFSET}px;
  left: ${HANDLE_OFFSET}px;
`;

const TopRightHandle = styled(Handle)`
  cursor: nesw-resize;
  top: ${HANDLE_OFFSET}px;
  right: ${HANDLE_OFFSET}px;
`;

const BottomRightHandle = styled(Handle)`
  cursor: nwse-resize;
  bottom: ${HANDLE_OFFSET}px;
  right: ${HANDLE_OFFSET}px;
`;

const BottomLeftHandle = styled(Handle)`
  cursor: nesw-resize;
  bottom: ${HANDLE_OFFSET}px;
  left: ${HANDLE_OFFSET}px;
`;
const TopHandle = styled(Handle)`
  cursor: ns-resize;
  top: ${HANDLE_OFFSET}px;
  left: calc(50% - ${SIDE_HANDLE_OFFSET}px);
`;

const RightHandle = styled(Handle)`
  cursor: ew-resize;
  top: calc(50% - ${SIDE_HANDLE_OFFSET}px);
  right: ${HANDLE_OFFSET}px;
`;

const BottomHandle = styled(Handle)`
  cursor: ns-resize;
  bottom: ${HANDLE_OFFSET}px;
  left: calc(50% - ${SIDE_HANDLE_OFFSET}px);
`;

const LeftHandle = styled(Handle)`
  cursor: ew-resize;
  top: calc(50% - ${SIDE_HANDLE_OFFSET}px);
  left: ${HANDLE_OFFSET}px;
`;

const handleComponentMap: Record<HandleName, React.ComponentType<any>> = {
  [HandleName.TOP_LEFT]: TopLeftHandle,
  [HandleName.TOP_RIGHT]: TopRightHandle,
  [HandleName.BOTTOM_RIGHT]: BottomRightHandle,
  [HandleName.BOTTOM_LEFT]: BottomLeftHandle,
  [HandleName.TOP]: TopHandle,
  [HandleName.RIGHT]: RightHandle,
  [HandleName.BOTTOM]: BottomHandle,
  [HandleName.LEFT]: LeftHandle
};
