import {
  SpaceComponentNode,
  SpaceComponentObject,
  SpaceNode,
  StableSpaceKeys,
  StableSpace
} from "../../../../types";
import { SUPERFICIAL_COMPONENT_TYPES } from "../SpaceComponent/constants";

export function getStableSpace(space: SpaceNode): StableSpace {
  return Object.fromEntries(StableSpaceKeys.map(k => [k, space[k]])) as StableSpace;
}

// This is used for cases where we need to traverse the component tree and not the render tree.
// For example, when we are configuring a dropdown in a modal form. The dropdown
// will not have been rendered yet, so we cannot use the render tree.
export function getAncestorComponents(
  slug: string | undefined,
  components: SpaceComponentObject[]
) {
  if (!slug) {
    return [];
  }
  const ancestorComponents: SpaceComponentObject[] = [];
  let currentSlug: string | undefined = slug;
  const getNode = (slug: string) => components.find(c => c.slug === slug);
  while (currentSlug !== undefined) {
    const currentNode: SpaceComponentObject | undefined = getNode(currentSlug);
    if (!currentNode || SUPERFICIAL_COMPONENT_TYPES.includes(currentNode.type)) {
      break;
    }
    ancestorComponents.unshift(currentNode); // add parent to front of array
    currentSlug = currentNode?.container?.slug;
  }
  return ancestorComponents;
}

export function buildComponentTree(components: SpaceComponentNode[]) {
  const nodeMap = new Map<string | null, SpaceComponentNode[]>();
  const componentTree: SpaceComponentNode[] = [];
  components.forEach(_c => {
    const c = { ..._c };
    const key = c.container?.slug || null;
    const nodes = nodeMap.get(key) || [];
    c.componentTreeNodes = [];
    nodes.push(c);
    nodeMap.set(c.container?.slug || null, nodes);
  });
  function attachNodes(container: SpaceComponentNode) {
    (nodeMap.get(container.slug) || [])
      .sort(c => c.order!)
      .forEach(c => {
        c.container = container;
        container.componentTreeNodes.push(c);
        attachNodes(c);
      });
  }
  (nodeMap.get(null) || [])
    .sort(c => c.order!)
    .forEach(c => {
      attachNodes(c);
      componentTree.push(c);
    });
  return componentTree;
}
