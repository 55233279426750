import React from "react";

import { Button } from "antd";
import styled from "styled-components";

import { useEnvironmentContext } from "../../../common/contexts/EnvironmentContext";
import { H6 } from "../../../common/StyledComponents";
import { loadMore, useActivityLogs } from "../queries/AllActivityLogs";

import ActivityCard from "./ActivityCard";
import ActivityLogModal from "./ActivityLogModal";

const POLL_INTERVAL = 30 * 1000; // poll every 30s

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: ${props => props.theme.spacermd};
`;

const HeaderDescription = styled.div`
  display: flex;
  flex-direction: column;
`;

const Environment = styled.div`
  color: ${props => props.theme.disabledTextColor};
  font-size: 12px;
  margin-top: ${props => props.theme.spacersm};
`;

const H6NoMargin = styled(H6)`
  margin-bottom: 0;
`;

const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 145px;
  color: ${props => props.theme.textColorMid};
  background-color: ${props => props.theme.innerContainerBackground};
  border-radius: ${props => props.theme.containerPrimaryBorderRadius};
  font-size: 13px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${props => props.theme.spacermd};
`;

interface Props {
  automationId: string;
  automationTitle: string;
  environmentId: string;
  shouldPoll: boolean;
}

export const ActivityLog = (props: Props) => {
  const [showModal, setShowModal] = React.useState(false);
  const [selectedLogId, setSelectedLogId] = React.useState<string | undefined>();
  const { data, loading, fetchMore } = useActivityLogs({
    variables: {
      automation: props.automationId,
      environment: props.environmentId
    },
    fetchPolicy: "network-only",
    pollInterval: props.shouldPoll ? POLL_INTERVAL : 0
  });
  const { getCurrentEnvironment } = useEnvironmentContext();

  const isEmpty = !loading && data?.allAuditLogs.edges.length === 0;

  return (
    <div>
      <Header>
        <HeaderDescription>
          <H6NoMargin>Latest activity</H6NoMargin>
          <Environment>{getCurrentEnvironment().name}</Environment>
        </HeaderDescription>
        {!isEmpty && (
          <Button type="link" size="small" onClick={() => setShowModal(true)}>
            View all
          </Button>
        )}
      </Header>
      {isEmpty ? (
        <EmptyContainer>No activity yet</EmptyContainer>
      ) : (
        data && (
          <>
            {data.allAuditLogs.edges.map(edge => {
              const node = edge.node;
              return (
                <ActivityCard
                  key={node.id}
                  title={node.function.title}
                  integration={node.function.dataSource.integration}
                  status={node.executionStatus}
                  user={node.user}
                  createdAt={node.createdAt}
                  onClick={() => {
                    setSelectedLogId(node.id);
                    setShowModal(true);
                  }}
                />
              );
            })}
            {data.allAuditLogs.pageInfo?.hasNextPage && (
              <ButtonContainer>
                <Button
                  className="loadMore"
                  type="primary"
                  onClick={() =>
                    loadMore(
                      props.automationId,
                      props.environmentId,
                      fetchMore,
                      data.allAuditLogs.pageInfo!.endCursor
                    )
                  }
                >
                  Load More
                </Button>
              </ButtonContainer>
            )}
          </>
        )
      )}
      {showModal && (
        <ActivityLogModal
          id={props.automationId}
          title={props.automationTitle}
          environmentId={props.environmentId}
          selectedLogId={selectedLogId}
          onCancel={() => {
            setSelectedLogId(undefined);
            setShowModal(false);
          }}
        />
      )}
    </div>
  );
};

export default ActivityLog;
