import React, { useState } from "react";

import {
  ResponsiveContainer,
  BarChart as BaseBarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Cell
} from "recharts";

import { chartColors, getChartLabelColor } from "../../../../../../cssConstants";
import { useComponentStateContext } from "../../contexts/ComponentStateContext";

import { chartFillColor } from "./Chart";
import { ChartData } from "./selectChartData";
import * as styled from "./styledComponents";

interface Props {
  value: ChartData;
}

const CustomTooltip = (props: any) => {
  return (
    <styled.TooltipContainer>
      <p>{props.label}</p>
      {(props.payload || []).map((p: any, i: number) => {
        // Not all data points contain all secondary values
        // Therefore we cannot use index to choose color
        return (
          <p key={`${p.name}:${i}`} style={{ color: getChartLabelColor(p.fill) }}>
            {p.name}: {p.payload[p.name]}
          </p>
        );
      })}
    </styled.TooltipContainer>
  );
};

export default function BarChart({ value: { data, dataKeys, xAxisFilter } }: Props) {
  const colors = Object.values(chartColors);

  const { recursivelyClearOutput, updateOutput } = useComponentStateContext();
  const [activeIdx, setActiveIdx] = useState(-1);
  const [hoverIdx, setHoverIdx] = useState(-1);

  const handleSelectItemClick = (arg: any, index: number) => {
    if (index === activeIdx) {
      recursivelyClearOutput();
      setActiveIdx(-1);
    } else {
      updateOutput({ selectedItem: { data: xAxisFilter(arg.payload.__xAxisDataKey) } });
      setActiveIdx(index);
    }
  };

  const cells = (colorIdx: number) =>
    data.map((_entry, index) => (
      <Cell
        key={`cell-${index}`}
        fill={chartFillColor(index, activeIdx, hoverIdx, colorIdx)}
      />
    ));

  const bars = dataKeys.map((k, i) => (
    <Bar
      key={k}
      dataKey={k}
      fill={colors[i % colors.length]}
      onClick={handleSelectItemClick}
      onMouseEnter={(_, index: number) => setHoverIdx(index)}
      onMouseLeave={() => setHoverIdx(-1)}
    >
      {cells(i)}
    </Bar>
  ));
  return (
    <ResponsiveContainer>
      <BaseBarChart data={data}>
        <XAxis dataKey="__xAxisDataKey" />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        {bars}
      </BaseBarChart>
    </ResponsiveContainer>
  );
}
