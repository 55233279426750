import React from "react";

import { useNavigate } from "react-router-dom";

import usePaths from "../../../common/hooks/usePaths";
import { TimeDurationMessage } from "../../../common/TimeDurationMessage/TimeDurationMessage";
import AnalyticCard, { DEFAULT_ERROR_MESSAGE } from "../AnalyticCard/AnalyticCard";

import { TaskNode, useOldestTasks } from "./queries";
import { TaskLink, TaskList, TaskRow } from "./styledComponents";

const TASKS_TO_SHOW = 5;

// If the list of tasks is bigger than the page, we can't guarantee a task will be in the list
// without specifying the proper page parameters.
// To do this, we get the transitionedAt of a newer task, and only show tasks
// after that. If there is none, then it should be displayed without pagination.
// This is brittle, because if we change the default sort order (transitionedAt)
// we need to change it here.
function generateTaskParams(tasks: TaskNode[], index: number): string {
  const task = tasks[index];
  const params = new URLSearchParams({
    task_id: task.id,
    taskTab: "details"
  });

  if (index < tasks.length - 1) {
    params.set("afterCursor", tasks[index + 1].transitionedAt);
  }

  return "?" + params.toString();
}

export function OldestTaskStatisticsView({
  tasks,
  className,
  queueSlug
}: {
  tasks: TaskNode[];
  className?: string;
  queueSlug?: string;
}) {
  const navigate = useNavigate();
  const { queuesapp_getQueue } = usePaths();

  const partialTasks = tasks.slice(0, TASKS_TO_SHOW);

  return (
    <TaskList className={className}>
      {partialTasks.map((task: TaskNode, index: number) => (
        <TaskRow key={task.id}>
          <TaskLink
            onClick={() => {
              if (!queueSlug) {
                return;
              }
              const path =
                queuesapp_getQueue(queueSlug) + generateTaskParams(tasks, index);
              navigate(path);
            }}
          >
            {task.title}
          </TaskLink>
          <TimeDurationMessage isoDate={task.createdAt} />
        </TaskRow>
      ))}
    </TaskList>
  );
}

export function OldestTaskStatistics({
  queueId,
  queueSlug,
  className
}: {
  queueId?: string;
  queueSlug?: string;
  className?: string;
  onActionClick?: () => void;
}) {
  const { data, loading, error } = useOldestTasks({
    variables: {
      queueId: queueId!,
      // Get one more for pagination, see generateTaskParams() at the top of this file
      first: TASKS_TO_SHOW + 1
    },
    skip: !queueId,
    fetchPolicy: "cache-and-network"
  });

  return (
    <AnalyticCard
      title="Oldest tasks"
      loading={loading}
      errorMessage={error ? DEFAULT_ERROR_MESSAGE : undefined}
      className={className}
    >
      <OldestTaskStatisticsView queueSlug={queueSlug} tasks={data?.oldestTasks || []} />
    </AnalyticCard>
  );
}
