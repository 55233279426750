import React, { useState, useEffect, useMemo } from "react";

import {
  BindingShape,
  DataSourceNode,
  FunctionNode,
  FunctionScope,
  InputParameter
} from "../../../../../../types";
import FormBuilderModal from "../../../../FormBuilderModal";
import { FunctionNode as FormBuilderFunctionNode } from "../../../../FormBuilderModal/types";
import FunctionPicker from "../../../../FunctionPicker";
import Message from "../../../../Message";
import { BindingSchema, getUserEnvironmentBindingSchema } from "../../../../schema";
import { LinkButtonNew } from "../../../../StyledComponents";
import { PipelineStep } from "../../../useFunctionEditor/queries";
import { useGetFunctionById } from "../common/queries";
import * as styled from "../common/styledComponents";
import { SUPPORTED_PARAM_TYPES } from "../utils";

interface Props {
  className?: string;
  step: PipelineStep;
  previousStepsSchema: Record<string, BindingSchema> | undefined;
  onSetPipelineStepDataSource: (stepIndex: number, dataSource: DataSourceNode) => void;
  onSetPipelineStepFunction: (stepIndex: number, func: FunctionNode) => void;
  onSetPipelineStepInputParameters: (
    stepIndex: number,
    parameters: InputParameter[]
  ) => void;
}

export default function ConfigFunctionStep({
  className,
  step,
  previousStepsSchema,
  onSetPipelineStepDataSource,
  onSetPipelineStepFunction,
  onSetPipelineStepInputParameters
}: Props) {
  const [showFormBuilder, setShowFormBuilder] = useState<boolean>(false);

  const [pendingFunctionId, setPendingFunctionId] = useState<string | null>(
    step.function ? step.function.id : null
  );

  const formBuilderFunction: FormBuilderFunctionNode = useMemo(() => {
    return {
      functionParameters: {
        edges: step.function?.functionParameters?.edges || []
      }
    };
  }, [step.function]);

  const { error: functionError } = useGetFunctionById({
    variables: {
      id: pendingFunctionId!
    },
    skip: !pendingFunctionId,
    onCompleted: data => {
      // When the step is loaded, it might have a function already.
      // Don't trigger a set function step when the function hasn't changed.
      if (data.node.id !== step.function?.id) {
        onSetPipelineStepFunction(step.order, data.node);
      }
    }
  });

  const schema = useMemo(() => {
    return {
      ...previousStepsSchema,
      ...getUserEnvironmentBindingSchema()
    };
  }, [previousStepsSchema]);

  useEffect(() => {
    if (functionError) {
      Message.error("Something went wrong. Please try again.");
    }
  }, [functionError]);

  // Keep pending funciton in sync with step
  // Otherwise changing Step Type (which wipes function data) will not show in UI
  useEffect(() => {
    setPendingFunctionId(step.function?.id || null);
  }, [step.function?.id]);

  return (
    <>
      <div className={className}>
        <FunctionPicker
          functionId={pendingFunctionId}
          functionScope={FunctionScope.Submittable}
          onChange={setPendingFunctionId}
          onDataSourceChange={ds => onSetPipelineStepDataSource(step.order, ds)}
          showLabels
          allowPipelineFunctions
        />
        <styled.ActionLinks>
          <LinkButtonNew
            disabled={!step.function?.functionParameters?.edges.length}
            onClick={() => setShowFormBuilder(true)}
          >
            Configure
          </LinkButtonNew>
        </styled.ActionLinks>
      </div>
      {showFormBuilder && (
        <FormBuilderModal
          visible={showFormBuilder}
          func={formBuilderFunction}
          title={step.function?.title || ""}
          schema={schema}
          allowedParameterTypes={SUPPORTED_PARAM_TYPES}
          initialInputParameters={step.inputParameters}
          onCancel={() => setShowFormBuilder(false)}
          allowedBindingShapes={[BindingShape.SCALAR, BindingShape.OBJECT]}
          onSave={e => {
            setShowFormBuilder(false);
            onSetPipelineStepInputParameters(step.order, e);
          }}
        />
      )}
    </>
  );
}
