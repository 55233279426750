import { SpaceComponentObject } from "../../../../../../../types";
import { reportException } from "../../../../../../util/exceptionReporting";
import { ElementLayout } from "../../../../../layout/util";
import { ComponentConfigState } from "../../../../../types";
import { BaseSpaceConfig, TreeNode } from "../reducer";

function _selectSpaceComponentTree(
  spaceComponents: Map<string, ComponentConfigState>,
  treeNode: TreeNode,
  layouts: Map<string, Partial<ElementLayout>>,
  container: SpaceComponentObject | null
): SpaceComponentObject {
  const componentNode = {
    ...spaceComponents.get(treeNode.slug!)?.draftComponent
  } as SpaceComponentObject;
  if (!componentNode) {
    reportException(new Error("Expected to find draftComponent for TreeNode"), {
      extra: {
        treeNode,
        componentSlugs: Object.entries(spaceComponents).map(([k]) => k)
      }
    });
    throw new Error("Expected to find draftComponent for TreeNode");
  }
  componentNode.container = container;
  componentNode.componentTreeNodes = treeNode.treeNodes.map(
    tn =>
      _selectSpaceComponentTree(
        spaceComponents,
        tn,
        layouts,
        componentNode
      ) as SpaceComponentObject
  );
  if (layouts.has(treeNode.slug!)) {
    componentNode.layout = layouts.get(treeNode.slug!) as ElementLayout;
  }
  return componentNode;
}

export default function selectSpaceComponentTree(
  space: BaseSpaceConfig
): SpaceComponentObject[] {
  return (
    space.tree?.treeNodes.map(tn =>
      _selectSpaceComponentTree(space.components, tn, space.elementLayouts, null)
    ) || []
  );
}
