import React, { useState } from "react";

import {
  ResponsiveContainer,
  PieChart as BasePieChart,
  Pie,
  Cell,
  // eslint-disable-next-line
  PieLabelRenderProps
} from "recharts";

import { chartColors, getChartLabelColor } from "../../../../../../cssConstants";
import { useComponentStateContext } from "../../contexts/ComponentStateContext";

import { chartFillColor } from "./Chart";
import { ChartData } from "./selectChartData";

interface Props {
  value: ChartData;
}

const renderLabel = ({ x, cx, y, name, value, index }: PieLabelRenderProps) => {
  const colors = Object.values(chartColors);
  return (
    <text
      x={x}
      y={y}
      fill={getChartLabelColor(colors[index! % colors.length])}
      textAnchor={x > (cx || 0) ? "start" : "end"}
    >
      {name}: {value}
    </text>
  );
};

export default function PieChart({ value: { data, xAxisFilter } }: Props) {
  const { recursivelyClearOutput, updateOutput } = useComponentStateContext();
  const [activeIdx, setActiveIdx] = useState(-1);
  const [hoverIdx, setHoverIdx] = useState(-1);

  const handleSelectItemClick = (arg: any, index: number) => {
    if (index === activeIdx) {
      recursivelyClearOutput();
      setActiveIdx(-1);
    } else {
      updateOutput({
        selectedItem: { data: xAxisFilter(arg.payload.__xAxisDataKey) }
      });
      setActiveIdx(index);
    }
  };

  return (
    <ResponsiveContainer>
      <BasePieChart>
        <Pie
          isAnimationActive={false}
          data={data}
          dataKey="value"
          nameKey="__xAxisDataKey"
          label={renderLabel}
          onClick={handleSelectItemClick}
          onMouseEnter={(_, index: number) => setHoverIdx(index)}
          onMouseLeave={() => setHoverIdx(-1)}
        >
          {data.map((_entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={chartFillColor(index, activeIdx, hoverIdx, index)}
            />
          ))}
        </Pie>
      </BasePieChart>
    </ResponsiveContainer>
  );
}
