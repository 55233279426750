import React, { useEffect } from "react";

import { Row, Col, Alert, Form, Input } from "antd";
import { Navigate, useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { useConfigContext } from "../../../ConfigContext";
import { Routes } from "../../../constants";
import { setTrackingUserId } from "../../../logging";
import { AuthStatus } from "../../../types";
import DashText from "../../common/DashText";
import usePaths from "../../common/hooks/usePaths";
import { GoogleLoginButton, FormItem } from "../../common/StyledComponents";
import { AuthCTAButton, AuthLink } from "../styledComponents";
import { AuthStatusProps } from "../WithAuthStatus/withAuthStatus";

import { LoginUser } from "./queries";
import {
  AlertContainer,
  FormContainer,
  ForgotPasswordDiv,
  FormFooter,
  ErrorField
} from "./styledComponents";
import useLogin from "./useLogin";
import useSSOState from "./useSSOState";

interface LocationState {
  message?: string;
  messageTitle?: string;
}

export default function LoginForm(props: AuthStatusProps) {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [valid, setValid] = React.useState(true);
  const [submitting, setSubmitting] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const paths = usePaths();
  const [params] = useSearchParams();
  const next = params.get("next");

  const locationState = location.state as LocationState | undefined;

  const { isGoogleOAuthEnabled } = useConfigContext();

  const nextLocation = (need2FA: boolean) => {
    if (need2FA) {
      return next
        ? `${Routes.LOGIN_TWO_FACTOR}?next=${encodeURIComponent(next)}`
        : Routes.LOGIN_TWO_FACTOR;
    } else {
      const url = new URL(next || paths.getDashboard(), window.location.href);
      return url.pathname + url.search;
    }
  };

  const {
    loading: ssoLoading,
    required: ssoRequired,
    url: ssoUrl
  } = useSSOState(email);
  const ssoRedirect = `${ssoUrl}?next=${encodeURIComponent(next || Routes.LOGIN)}`;

  const { login, loading } = useLogin(
    (user?: LoginUser) => {
      if (user) {
        setTrackingUserId(user.id);
        navigate(nextLocation(user.hasDevice));
      } else {
        setValid(false);
      }
    },
    () => setValid(false)
  );

  useEffect(() => {
    if (!submitting || ssoLoading) {
      return;
    }

    setSubmitting(false);
    if (ssoRequired) {
      window.location.replace(ssoRedirect);
    } else {
      login(email, password);
    }
  }, [submitting, ssoLoading, ssoRequired, ssoRedirect, email, password, login]);

  const disabled = loading || submitting;
  let googleLoginUrl = Routes.LOGIN_GOOGLE_AUTH;
  if (next) {
    googleLoginUrl += `?next=${encodeURIComponent(next)}`;
  }

  switch (props.status) {
    case AuthStatus.Authenticated:
      return <Navigate to={nextLocation(false)} />;
    case AuthStatus.Partial:
      return <Navigate to={nextLocation(true)} />;
  }

  return (
    <>
      {locationState && locationState.message && (
        <AlertContainer>
          <Alert
            message={locationState.messageTitle || "Success"}
            description={locationState.message}
            type="success"
            onClose={() =>
              navigate("", {
                replace: true,
                state: {
                  ...locationState,
                  message: undefined,
                  messageTitle: undefined
                }
              })
            }
            closable
            showIcon
          />
        </AlertContainer>
      )}

      <FormContainer>
        <Col>
          <Row>
            <a href={googleLoginUrl}>
              <GoogleLoginButton disabled={!isGoogleOAuthEnabled}>
                Sign in with Google
              </GoogleLoginButton>
            </a>
          </Row>
          <DashText>or</DashText>
          <Row>
            <Form
              onSubmit={e => {
                e.preventDefault();
                setSubmitting(true);
              }}
            >
              <FormItem>
                <Input
                  placeholder="Email"
                  disabled={disabled}
                  onChange={e => setEmail(e.target.value)}
                />
              </FormItem>
              {!ssoRequired && (
                <FormItem>
                  <Input
                    placeholder="Password"
                    type="password"
                    onChange={e => setPassword(e.target.value)}
                    disabled={disabled}
                  />
                </FormItem>
              )}
              <FormFooter>
                {!valid && <ErrorField>Incorrect username and password</ErrorField>}
                <AuthCTAButton block htmlType="submit" type="brand" loading={disabled}>
                  {ssoRequired ? "Log in with Single Sign-On" : "Sign in"}
                </AuthCTAButton>
                <ForgotPasswordDiv>
                  <AuthLink to={Routes.RESET_PASSWORD}>Forgot password</AuthLink>
                </ForgotPasswordDiv>
              </FormFooter>
            </Form>
          </Row>
        </Col>
      </FormContainer>
    </>
  );
}
