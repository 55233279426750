import React from "react";

import moment from "moment-timezone";

import { AttributeTypes, TIME_SAVE_FORMAT } from "../../../../../../constants";
import { AttributeValueProps } from "../../../../../common/AttributeValue";
import WarningIcon from "../../../../../common/WarningIcon";

export default function DateTemplateRenderer({
  value,
  attribute,
  template,
  className,
  style
}: AttributeValueProps) {
  if (!value) return <div className={className}>{null}</div>;

  let momentValue;
  switch (attribute.sourceType) {
    case AttributeTypes.TIME:
      momentValue = moment(String(value), TIME_SAVE_FORMAT);
      break;
    case AttributeTypes.INT:
    case AttributeTypes.FLOAT:
    case AttributeTypes.DECIMAL:
      momentValue = moment(parseInt(value));
      break;
    case AttributeTypes.STRING:
    default:
      momentValue = moment(String(value));
      if (!momentValue.isValid()) {
        momentValue = moment(parseInt(value));
      }
      break;
  }

  return (
    <div className={className} style={style}>
      <span>
        {momentValue.isValid() ? (
          momentValue.tz(moment.tz.guess()).format(template)
        ) : (
          <span>
            <WarningIcon tooltip="This value cannot be displayed as a date/time." />
            {String(value)}
          </span>
        )}
      </span>
    </div>
  );
}
