import { Button } from "antd";
import styled from "styled-components";

export const DataSourceButton = styled(Button)`
  height: auto;
  padding: 0px;
  border-radius: ${props => props.theme.borderRadiussm};

  svg {
    width: 166px;
    height: 176px;
  }
`;

export const DataSourceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
  margin-bottom: 40px;

  .row {
    display: flex;
  }

  .row + .row {
    margin-top: 40px;
  }
  button + button {
    margin-left: 40px;
  }

  p {
    margin-bottom: 24px;
  }
`;

export const MissingLogo = styled.div`
  width: 166px;
  height: 176px;
`;

export const RoundedSquareLogo = styled.div`
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 156px;
    height: 156px;
    border-radius: ${props => props.theme.spacerxs};
  }
`;
