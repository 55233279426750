import React from "react";

import { Skeleton } from "antd";
import { cloneDeep } from "lodash";
import styled from "styled-components";

import ScaledImage, { useTrackImageErrors } from "../common/ScaledImage";
import useSignedUrl from "../common/useSignedUrl";
import { useComponentStateContext } from "../contexts/ComponentStateContext";
import { Props as BaseProps } from "../SpaceComponent";

import { ensureViewlessImageComponent } from "./ViewlessImageConfig";

const Root = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;
`;

export default function SpaceViewlessImage({
  spaceComponent,
  layoutConstraints = { width: 75, height: 75 }
}: BaseProps) {
  const { input } = useComponentStateContext();
  const imageComponent = ensureViewlessImageComponent(cloneDeep(spaceComponent));
  const { imageErrored, handleImageError } = useTrackImageErrors(imageComponent);
  const { url } = useSignedUrl(imageComponent, input, imageErrored);

  if (!url) {
    return (
      <Root>
        <Skeleton avatar active title={false} paragraph={{ rows: 0 }} />
      </Root>
    );
  }

  return (
    <Root>
      <ScaledImage
        src={url}
        name={imageComponent.name}
        inline
        layoutConstraints={layoutConstraints}
        onError={handleImageError}
      />
    </Root>
  );
}
