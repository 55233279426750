import React from "react";

import { ComponentConfigProps } from "../../../../../types";
import SelectComponentConfig, {
  TerseConfig as SelectTerseConfig,
  InlineConfig as SelectInlineConfig
} from "../common/SelectBackedComponent/Config";

import DesignConfig from "./DesignConfig";

export default function Config({ slug }: ComponentConfigProps) {
  return <SelectComponentConfig slug={slug} designConfig={<DesignConfig />} isMulti />;
}

export function TerseConfig({ slug }: ComponentConfigProps) {
  return <SelectTerseConfig slug={slug} isMulti />;
}

export function InlineConfig({ slug }: ComponentConfigProps) {
  return <SelectInlineConfig slug={slug} isMulti />;
}
