import React from "react";

import { Dropdown, Menu } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";

import Expando from "../../../../../../assets/expando.svg";
import GitHubLogo from "../../../../../../assets/github-mark.svg";
import OpenNewWindowIcon from "../../../../../../assets/icons/open-new-window.svg";
import { ScmStatus } from "../../../../../../types";
import usePaths from "../../../../../common/hooks/usePaths";
import useScmConfig from "../../../../../common/hooks/useScmConfig";
import Message from "../../../../../common/Message";
import { ModalNew } from "../../../../../common/Modal";
import { H6 } from "../../../../../common/StyledComponents";
import { updateScmStatus } from "../common";

import useDisconnectScm from "./useDisconnectScm";

const ProviderContainer = styled.div`
  background: rgba(41, 41, 45, 0.04);
  border: 1px solid rgba(41, 41, 45, 0.13);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 11px 10px 11px 15px;
`;

const ProviderName = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
`;

const StyledGitHubLogo = styled(GitHubLogo)`
  width: 26px;
  margin-right: 11px;
`;

const StyledExpando = styled(Expando)`
  height: 24px;
  width: 24px;
  background: rgba(41, 41, 45, 0.06);
  border-radius: 2px;
  cursor: pointer;
`;

const StyledMenu = styled(Menu)`
  width: 257px;

  .ant-dropdown-menu-item > a {
    color: ${props => props.theme.linkColor};
  }

  .ant-dropdown-menu-item > button.danger {
    color: ${props => props.theme.errorColor};
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
  }
`;

interface Props {
  spaceId: string;
}

export default function ScmConnection({ spaceId }: Props) {
  const config = useScmConfig();
  const { getScmSettings } = usePaths();
  const [showModal, setShowModal] = React.useState(false);

  const [disconnect, { loading, client }] = useDisconnectScm({
    variables: {
      spaceId
    },
    onCompleted: _ => {
      setShowModal(false);
      updateScmStatus(client!, spaceId, ScmStatus.DISCONNECTED);
      Message.success("Your space is disconnected");
    },
    onError: error => {
      setShowModal(false);
      Message.error(error.toString());
    }
  });

  const menu = (
    <StyledMenu>
      <Menu.Item>
        <Link to={getScmSettings()}>SCM Settings</Link>
      </Menu.Item>
      <Menu.Item>
        <a href={config?.repositoryUrl} target="_blank" rel="noreferrer">
          View Repository on Github <OpenNewWindowIcon />
        </a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <button className="danger" onClick={_ => setShowModal(true)}>
          Disconnect this space
        </button>
      </Menu.Item>
    </StyledMenu>
  );
  return (
    <>
      <div>
        <H6>Source Code Management</H6>
        <ProviderContainer>
          <ProviderName>
            <StyledGitHubLogo /> GitHub
          </ProviderName>
          <Dropdown overlay={menu}>
            <StyledExpando />
          </Dropdown>
        </ProviderContainer>
      </div>
      <ModalNew
        title="Are you sure?"
        visible={showModal}
        okText="Yes, disconnect this space"
        buttonType="primary"
        width="527px"
        confirmLoading={loading}
        onCancel={() => setShowModal(false)}
        onOk={() => disconnect()}
      >
        <p>Disconnecting this space from GitHub will remove all tracked branches.</p>
        <p>This action cannot be undone. Would you like to proceed?</p>
      </ModalNew>
    </>
  );
}
