import { createPath, parsePath } from "../../../../../../util/binding";
import { SpaceDefinition } from "../../../../../types";

/**
 * Returns the portion of a component path that is part of the
 * requested subSpace.
 *
 * @param path - Full component path
 * @param subSpaceSlug - Slug of space to scope path to
 * @param componentToSpaceMap - Lookup Map to find a component's space
 * @returns Partial component path scoped to a subSpace
 *
 */
export default function toSubSpacePath(
  path: string,
  subSpaceSlug: string,
  componentToSpaceMap: Map<string, SpaceDefinition>
) {
  const parts = parsePath(path);
  const subSpacePathParts = [];
  for (let i = parts.length - 1; i >= 0; i--) {
    if (componentToSpaceMap.get(String(parts[i]))?.slug !== subSpaceSlug) {
      break;
    }
    subSpacePathParts.unshift(parts[i]);
  }
  return createPath(subSpacePathParts);
}
