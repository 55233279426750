import React from "react";

import _ from "lodash";

import FunctionIcon from "../../../../../assets/spaceicons/function.svg";
import { SpaceFunctionType } from "../../../../../types";
import usePrevious from "../../../../common/hooks/usePrevious";
import { processEffects as processQueueEffects } from "../../../../queues/App/Queue/utils";
import { createSpaceFunction } from "../../../FunctionExecutor/FunctionExecutor";
import { useStableSpaceContext } from "../../SpaceContext";
import useSubmittableEffects, {
  SubmittableEventType
} from "../common/effects/useSubmittableEffects/useSubmittableEffects";
import {
  HeadlessComponentIcon,
  HeadlessComponentErrorIcon
} from "../common/HeadlessComponentIcon/HeadlessComponentIcon";
import {
  isBoundParameterDenied,
  isRequiredParameterDenied
} from "../common/PermissionFeedback";
import useFuncParams from "../common/useFuncParams";
import useFunctionAccess from "../common/useFunctionAccess";
import useSpaceFunction from "../common/useSpaceFunction";
import { FunctionResult } from "../common/useSpaceFunction/useSpaceFunction";
import { useComponentStateContext } from "../contexts/ComponentStateContext";
import { Props } from "../SpaceComponent";

import { generateFilterParameters } from "./util";

export default function SpaceFunctionHeadless({
  spaceComponent: {
    properties: { input_parameters: propInputParameters = [] }
  },
  spaceComponent,
  hasConfigError,
  spaceApi
}: Props) {
  const { spaceId, editMode } = useStableSpaceContext();
  const { input, updateOutput, recursivelyClearOutput } = useComponentStateContext();
  const { triggerEffects } = useSubmittableEffects(spaceApi);

  const onCompleted = React.useCallback(
    (result: FunctionResult, metadata: unknown) => {
      triggerEffects({ type: SubmittableEventType.SUBMIT_SUCCESS });
      processQueueEffects(metadata);
      updateOutput({ lastExecutionResult: result });
    },
    [updateOutput, triggerEffects]
  );

  const onError = React.useCallback(
    error => {
      triggerEffects({ type: SubmittableEventType.SUBMIT_FAILURE, error });
    },
    [triggerEffects]
  );

  const func = React.useMemo(
    () => createSpaceFunction(spaceComponent),
    [spaceComponent]
  );

  const access = useFunctionAccess(func);
  const { executeFunction } = useSpaceFunction(
    func,
    propInputParameters,
    spaceId,
    onCompleted,
    onError
  );

  const inputParameters = React.useMemo(
    () => [
      ...propInputParameters,
      ...generateFilterParameters(
        func,
        spaceComponent.properties.filters,
        spaceComponent.properties.is_filter_required
      )
    ],
    [
      func,
      propInputParameters,
      spaceComponent.properties.filters,
      spaceComponent.properties.is_filter_required
    ]
  );

  const {
    funcParams,
    hasRequiredBindings,
    hasRequiredValues,
    hasValidValues,
    getCurrentFuncParams
  } = useFuncParams(func, inputParameters, input);
  const previousFuncParams = usePrevious(funcParams);

  React.useEffect(() => {
    if ([SpaceFunctionType.VOID, SpaceFunctionType.NOT_VISIBLE].includes(func.type)) {
      return;
    }

    const changed = !_.isEqual(funcParams, previousFuncParams);
    if (!changed) {
      return;
    }

    if (hasRequiredBindings && hasRequiredValues && hasValidValues) {
      executeFunction(getCurrentFuncParams());
    } else {
      recursivelyClearOutput();
    }
  }, [
    func,
    executeFunction,
    hasRequiredBindings,
    hasRequiredValues,
    hasValidValues,
    previousFuncParams,
    funcParams,
    getCurrentFuncParams,
    recursivelyClearOutput
  ]);

  const paramDenied = isRequiredParameterDenied(inputParameters, func, access);
  const boundDenied = React.useMemo(
    () => isBoundParameterDenied(inputParameters, func.functionParameters, funcParams),
    [inputParameters, func.functionParameters, funcParams]
  );

  if (paramDenied || boundDenied || func.type === SpaceFunctionType.NOT_VISIBLE) {
    return (
      <HeadlessComponentErrorIcon
        message={`Function Permission Error (${spaceComponent.name})`}
        tooltipMessage="This function is disabled."
        tooltipDescription={
          paramDenied
            ? "You don't have access to one or more fields required to complete this action. Please contact your admin to update your permissions."
            : "At least one value required for this action could not be retrieved because you do not have permissions to access the data. Please contact your admin to update your permissions."
        }
      />
    );
  } else if (editMode) {
    return (
      <HeadlessComponentIcon Icon={FunctionIcon} hasConfigError={!!hasConfigError} />
    );
  } else {
    return null;
  }
}
