import React from "react";

import { useNavigate } from "react-router-dom";

import ButtonNew from "../../../../common/ButtonNew/ButtonNew";
import { useEnvironmentContext } from "../../../../common/contexts/EnvironmentContext";
import useAuthUser from "../../../../common/hooks/useAuthUser";
import usePaths from "../../../../common/hooks/usePaths";
import Spacer from "../../../../common/Spacer";
import {
  H6,
  IconButton,
  P,
  SecondaryButtonList
} from "../../../../common/StyledComponents";
import SettingsModal from "../../../Config/SettingsModal/SettingsModal";
import ActivityLog from "../../ActivityLog";
import AutomationStatus from "../../AutomationStatus";
import { PermissionsModal } from "../../Permissions/PermissionsModal";
import { useAutomationById } from "../../queries/AutomationById";
import RunAutomationButton from "../../RunAutomationButton";
import RunInformation from "../../RunInformation";
import DeleteAutomationsModal from "../DeleteAutomationsModal";

type AutomationModal = "settings" | "permissions" | "delete" | undefined;
const POLL_INTERVAL = 30 * 1000; // poll every 30s for lastRun / nextRun updates

interface Props {
  id: string;
  onAutomationChange: () => void;
  onAutomationDeletion: () => void;
}

const AutomationDetails = ({ id, onAutomationChange, onAutomationDeletion }: Props) => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = React.useState<AutomationModal>(undefined);
  const [wasManuallyRun, setWasManuallyRun] = React.useState(false);
  const { isAdmin } = useAuthUser();
  const { automationsapp_getEditAutomation } = usePaths();

  const { getCurrentEnvironment } = useEnvironmentContext();

  const environmentId = getCurrentEnvironment().id;

  const { data, refetch } = useAutomationById({
    variables: {
      id
    },
    pollInterval: POLL_INTERVAL
  });
  const automation = data?.node;

  if (!automation) return null;
  return (
    <div>
      <H6>{automation.name}</H6>
      <Spacer size="lg" />
      {automation.description && <P>{automation.description}</P>}
      {isAdmin && (
        <>
          <SecondaryButtonList>
            <IconButton
              title="Update automation settings"
              type="link"
              shape="circle"
              icon="setting"
              onClick={() => {
                setOpenModal("settings");
              }}
            />
            <IconButton
              title="Delete automation"
              type="link"
              shape="circle"
              icon="delete"
              onClick={() => {
                setOpenModal("delete");
              }}
            />
          </SecondaryButtonList>
          <ButtonNew
            type="primary"
            size="sm"
            block
            onClick={() => {
              navigate(automationsapp_getEditAutomation(automation.slug));
            }}
          >
            Edit automation
          </ButtonNew>
          <Spacer size="smd" />
          <RunAutomationButton
            id={automation.id}
            onComplete={() => setWasManuallyRun(true)}
          />
          <RunInformation automation={automation} />
        </>
      )}

      <AutomationStatus id={automation.id} onChange={refetch} />
      {/* Expose permissions modal when share functionality is added */}
      {/* <LinkButtonNew onClick={() => setOpenModal("permissions")}>
        Manage access and permissions
      </LinkButtonNew> */}

      <ActivityLog
        automationId={automation.id}
        automationTitle={automation.name}
        environmentId={environmentId}
        shouldPoll={wasManuallyRun || automation.isActive}
      />
      {automation && openModal === "settings" && (
        <SettingsModal
          automation={automation}
          onCancel={() => setOpenModal(undefined)}
          onComplete={() => {
            onAutomationChange();
            setOpenModal(undefined);
          }}
        />
      )}
      {automation && openModal === "permissions" && (
        <PermissionsModal
          automationName={automation.name}
          automationSlug={automation.slug}
          onCancel={() => setOpenModal(undefined)}
        />
      )}
      {automation && openModal === "delete" && (
        <DeleteAutomationsModal
          automationId={automation?.id}
          onSuccess={() => onAutomationDeletion()}
          onComplete={() => setOpenModal(undefined)}
          onCancel={() => setOpenModal(undefined)}
        />
      )}
    </div>
  );
};

export default AutomationDetails;
