import gql from "graphql-tag";

import { FunctionNode } from "../../../../../../types";
import {
  FUNCTION_PARAMETER_FRAGMENT,
  FUNCTION_ATTRIBUTE_FRAGMENT
} from "../../../../../common/FunctionEditor/useFunctionEditor/queries";

export type FunctionWithParametersQuery = { node: FunctionNode };
export const FUNCTION_WITH_PARAMETERS = gql`
  query FunctionParameters($functionId: ID!) {
    node(id: $functionId) {
      ... on FunctionNode {
        id
        title
        name
        returnSchema
        isPipeline
        isUserGenerated
        metadata
        access {
          onlyParameters
          onlyAttributes
        }
        dataSource {
          id
          name
        }
        functionParameters {
          edges {
            node {
              ...FunctionParameterFragment
            }
          }
        }
        functionAttributes {
          edges {
            node {
              ...FunctionAttributeFragment
            }
          }
        }
      }
    }
  }
  ${FUNCTION_PARAMETER_FRAGMENT}
  ${FUNCTION_ATTRIBUTE_FRAGMENT}
`;
