import React from "react";

import moment from "moment";

import { AttributeValueBaseProps } from "..";
import { DATE_DISPLAY_FORMAT } from "../../../../constants";

export default function DateValue({
  style = {},
  className,
  value
}: AttributeValueBaseProps) {
  let displayedValue = value || null;
  if (value && moment(value).isValid()) {
    displayedValue = moment(value).format(DATE_DISPLAY_FORMAT);
  }
  return (
    <div style={style} className={className} title={displayedValue}>
      {displayedValue}
    </div>
  );
}
