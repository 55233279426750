import React from "react";

import styled from "styled-components";

import {
  useSelectionStateContext,
  useTransformationActionContext
} from "../../layout/TransformationContext/TransformationContext";
import useIsComponentVisible from "../../SpaceRoot/SpaceComponent/common/useIsComponentVisible";
import useNestedStatus from "../../SpaceRoot/SpaceComponent/common/useNestedStatus";
import {
  useComponentPathContext,
  useIsFirstInstance
} from "../../SpaceRoot/SpaceComponent/contexts/ComponentPathContext";
import { useStableSpaceContext } from "../../SpaceRoot/SpaceContext";
import { useCanvasViewportContext } from "../Canvas/CanvasViewportContext";
import Draggable from "../Draggable";
import Selection from "../Selection/Selection";
import { ElementLayout } from "../util";

import { ElementProps } from "./AbsoluteElement";

export default function StaticElement({ component, layout, children }: ElementProps) {
  const { slug } = component;
  const { select, hover, unhover, startMove } = useTransformationActionContext();
  const { selected } = useSelectionStateContext();
  const { isManaged, isRepeated } = useNestedStatus();
  const { editMode } = useStableSpaceContext();
  const path = useComponentPathContext();
  const { getCanvasPointForPagePoint } = useCanvasViewportContext();
  const measurerRef = React.useRef<HTMLDivElement | null>(null);

  const isVisible = useIsComponentVisible(component.properties);
  const isFirstInstance = useIsFirstInstance();

  const styles = component.properties.styles;
  const style = React.useMemo(() => {
    return {
      ...(styles ? styles.root || {} : {}),
      ...(layout ? ElementLayout.stripNonStyleProps(layout) : {}),
      display: isVisible ? "block" : "none"
    };
  }, [styles, layout, isVisible]);

  const isAncestorSelected = React.useMemo(() => {
    let curr = component.container;
    while (curr) {
      if (curr.slug === selected) return true;
      curr = curr.container;
    }
    return false;
  }, [component, selected]);

  const handleMouseEnter = React.useCallback(() => {
    if (!editMode) return;
    hover(slug);
  }, [editMode, hover, slug]);

  const handleMouseLeave = React.useCallback(() => {
    if (!editMode) return;
    unhover(slug);
  }, [editMode, slug, unhover]);

  const handleClick = React.useCallback(
    (evt: React.MouseEvent) => {
      if (!editMode) return;
      evt.stopPropagation();
      select(slug);
    },
    [editMode, slug, select]
  );

  const handleDragStart = React.useCallback(
    pagePt => {
      const rect = measurerRef.current?.getBoundingClientRect() || new DOMRect();
      const offset = getCanvasPointForPagePoint(new DOMPoint(rect.left, rect.top));
      startMove(
        path,
        pagePt,
        layout,
        new DOMRect(offset.x, offset.y, rect.width, rect.height)
      );
    },
    [path, layout, getCanvasPointForPagePoint, startMove]
  );

  const hasSelection = !isRepeated || isFirstInstance;

  return (
    <Root
      id={isFirstInstance ? `element-${slug}` : undefined}
      data-test={`element-${slug}`}
      style={style}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <Draggable
        disable={isManaged || isAncestorSelected}
        onDragStart={handleDragStart}
      >
        <Positioner>
          <ChildWrapper ref={measurerRef}>{children}</ChildWrapper>
          {hasSelection && (
            <Selection elRef={measurerRef} component={component} resizeable={false} />
          )}
        </Positioner>
      </Draggable>
    </Root>
  );
}

const Root = styled.div``;

const Positioner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const ChildWrapper = styled.div`
  width: 100%;
  height: 100%;
`;
