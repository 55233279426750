import React from "react";

import { Modal as BaseModal } from "antd";
import styled from "styled-components";

import ButtonNew from "../../common/ButtonNew/ButtonNew";
import IconButton from "../../common/IconButton";
import Spacer from "../../common/Spacer";
import { B3 } from "../../common/StyledComponents";

interface ModalProps {
  visible: boolean;
  children: React.ReactNode;
  footer: React.ReactNode;
  onClose: () => void;
}

export default function Modal({ visible, footer, children, onClose }: ModalProps) {
  return (
    <StyledModal width="60%" visible={visible} footer={null} closable={false}>
      <StyledMain>
        <StyledCloseButton icon="close" onClick={onClose} />
        {children}
        <Spacer size="xl" />
        <StyledHr />
        <Spacer size="lg" />
        <Footer>
          <StyledFooterText>{footer}</StyledFooterText>
          <ButtonNew type="secondary" onClick={onClose}>
            Close
          </ButtonNew>
        </Footer>
      </StyledMain>
    </StyledModal>
  );
}

const StyledModal = styled(BaseModal)`
  min-width: 555px;
  max-width: 850px;
  .ant-modal-body {
    padding: 0;
  }
`;

const StyledMain = styled.main`
  padding: ${props => props.theme.spacerxxl} 24px ${props => props.theme.spacerlg};
`;

const StyledCloseButton = styled(IconButton)`
  position: absolute;
  top: 14px;
  left: 14px;
`;

const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
`;

const StyledHr = styled.hr`
  width: calc(100% + 48px);
  margin: 0 -24px;
`;

const StyledFooterText = styled(B3)`
  display: flex;
  align-items: center;
`;

export const FooterLink = styled.a`
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`;
