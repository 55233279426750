import React from "react";

import { Select } from "antd";

import { ICON_NAMES, MaterialIconType } from "../Icons/MaterialIcons";
import { ensureHexValue } from "../utils";

import * as styled from "./styledComponents";

interface Props {
  value?: string;
  color?: string;
  className?: string;
  onChange: (icon: MaterialIconType) => void;
}

export const IconSelect = React.forwardRef<Select<string>, Props>(
  (props: Props, ref) => {
    const { value, color, className, onChange } = props;
    return (
      <Select
        ref={ref}
        data-test="iconSelectDropdown"
        value={value}
        className={className}
        dropdownRender={children => {
          return (
            <styled.IconSelectOptionsWrapper>
              {children}
            </styled.IconSelectOptionsWrapper>
          );
        }}
        onChange={(ev: string) => onChange(ev as MaterialIconType)}
      >
        {ICON_NAMES.map(n => (
          <Select.Option data-test="iconOption" key={n} title={n} value={n}>
            <styled.Icon color={ensureHexValue(color || "")} type={n} />
          </Select.Option>
        ))}
      </Select>
    );
  }
);
