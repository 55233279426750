import React, { useMemo, useState } from "react";

import { BindingShape, InputParameter } from "../../../../../../types";
import { DebouncedEditor } from "../../../../Editor";
import FormBuilderModal from "../../../../FormBuilderModal";
import { BindingSchema, getUserEnvironmentBindingSchema } from "../../../../schema";
import { LinkButtonNew } from "../../../../StyledComponents";
import { PipelineStep } from "../../../useFunctionEditor/queries";
import * as styled from "../common/styledComponents";
import { inputParametersToCreateFunctionNode, SUPPORTED_PARAM_TYPES } from "../utils";

const EDITOR_HEIGHT = "240px";

interface Props {
  className?: string;
  step: PipelineStep;
  previousStepsSchema: Record<string, BindingSchema> | undefined;
  onSetPipelineStepExpression: (stepIndex: number, expression: string) => void;
  onSetPipelineStepInputParameters: (
    stepIndex: number,
    parameters: InputParameter[]
  ) => void;
}

export default function ConfigExpressionStep({
  className,
  step,
  previousStepsSchema,
  onSetPipelineStepExpression,
  onSetPipelineStepInputParameters
}: Props) {
  const [showFormBuilder, setShowFormBuilder] = useState<boolean>(false);

  const functionAdapter = useMemo(() => {
    return inputParametersToCreateFunctionNode(step.inputParameters);
  }, [step.inputParameters]);

  const schema = useMemo(() => {
    return {
      ...previousStepsSchema,
      ...getUserEnvironmentBindingSchema()
    };
  }, [previousStepsSchema]);

  return (
    <>
      <div className={className}>
        <DebouncedEditor
          className="editor-transformer"
          mode="javascript"
          height={EDITOR_HEIGHT}
          value={step.expression || ""}
          onChange={value => onSetPipelineStepExpression(step.order, value as string)}
        />
        <styled.ActionLinks>
          <LinkButtonNew
            disabled={!step.inputParameters.length}
            onClick={() => setShowFormBuilder(true)}
          >
            Configure inputs
          </LinkButtonNew>
        </styled.ActionLinks>
      </div>
      {showFormBuilder && step.inputParameters.length && (
        <FormBuilderModal
          visible={showFormBuilder}
          func={functionAdapter}
          title={step.function?.title || ""}
          schema={schema}
          allowedParameterTypes={SUPPORTED_PARAM_TYPES}
          initialInputParameters={step.inputParameters}
          onCancel={() => setShowFormBuilder(false)}
          allowedBindingShapes={[BindingShape.SCALAR, BindingShape.OBJECT]}
          onSave={e => {
            setShowFormBuilder(false);
            onSetPipelineStepInputParameters(step.order, e);
          }}
        />
      )}
    </>
  );
}
