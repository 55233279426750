import { SpaceComponentHostType, SpaceComponentPackage } from "../../../../../types";
import { commonComponentReducer } from "../../../SpaceConfig/SpaceConfigContext/useSpaceConfig/reducer";

import errorSelector from "./errorSelector";
import getSchema from "./getSchema";
import SpaceView from "./SubSpace";
import SubSpaceConfig from "./SubSpaceConfig";

const pkg: SpaceComponentPackage = {
  type: "SUB_SPACE",
  displayName: "Sub Space",
  allowedHosts: new Set<SpaceComponentHostType>([]),
  Component: SpaceView,
  ConfigurationComponent: SubSpaceConfig,
  ConfigurationSection: null,
  componentConfigReducer: commonComponentReducer,
  ensureComponent: component => {
    if (Array.isArray(component.properties.input_parameter)) return component;
    return {
      ...component,
      properties: { ...component.properties, input_parameters: [] }
    };
  },
  getSchema,
  isHeadless: false,
  isContainer: true,
  allowSelfBinding: false,
  allowAncestorBinding: true,
  errorSelector
};

export default pkg;
