import wretch from "wretch";

import { AttributeTypes } from "../../../constants";
import { encodeAttributeValue } from "../utils";

import { CustomError, ExecutorClientError } from "./errors";

export default async (
  functionId: string,
  environmentId: string | undefined,
  spaceId: string | undefined,
  values: any
) => {
  function getCookie(name: string) {
    if (!document.cookie) {
      return null;
    }
    const token = document.cookie
      .split(";")
      .map(c => c.trim())
      .filter(c => c.startsWith(name + "="));

    if (token.length === 0) {
      return null;
    }
    return decodeURIComponent(token[0].split("=")[1]);
  }

  const csrftoken = getCookie("csrftoken") || "";
  const data = await wretch(window.location.origin + "/api/s/executions")
    .headers({ "X-CSRFToken": csrftoken })
    .post({
      function_id: functionId,
      environment_id: environmentId,
      space_id: spaceId,
      values
    })
    .badRequest(err => {
      throw new ExecutorClientError(err);
    })
    .json(data => data)
    .catch(err => {
      if (err instanceof CustomError) throw err;
      throw new Error(JSON.parse(err.text).error.detail);
    });
  return data;
};

interface FunctionParameterType {
  name: string;
  type: AttributeTypes;
}

export function encodeParameters(
  params: Record<string, any>,
  functionParameters: FunctionParameterType[]
) {
  const parameterTypeMap = new Map(functionParameters.map(p => [p.name, p.type]));
  return Object.entries(params).reduce<Record<string, any>>((acc, [key, value]) => {
    const type = parameterTypeMap.get(key)!;
    acc[key] = type ? encodeAttributeValue(type, value) : value;
    return acc;
  }, {});
}
