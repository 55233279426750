import React from "react";

import moment from "moment-timezone";

import { AttributeValueBaseProps } from "..";
import { TIMESTAMP_DISPLAY_FORMAT } from "../../../../constants";

export default function TimestampValue({
  style = {},
  className,
  value
}: AttributeValueBaseProps) {
  let displayedValue = value || null;
  if (value && moment(value).isValid()) {
    displayedValue = moment(value)
      .tz(moment.tz.guess())
      .format(TIMESTAMP_DISPLAY_FORMAT);
  }
  return (
    <div style={style} className={className} title={displayedValue}>
      {displayedValue}
    </div>
  );
}
