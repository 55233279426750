import React, { useCallback, useMemo } from "react";

import { ComponentConfigProps } from "../../../../../../types";
import ColorPicker from "../../../../../common/ColorPicker";
import { LayoutUnit } from "../../../../layout/util";
import BaseComponentConfigSection from "../BaseComponentConfigSection";
import {
  DEFAULT_BUTTON_BACKGROUND_COLOR,
  DEFAULT_BUTTON_TEXT_COLOR
} from "../Button/constants";
import ButtonDesignConfig from "../ButtonDesignConfig";
import { ItemContainer } from "../CollectionItemContainer/CollectionItemContainer";
import {
  ComponentConfigContextContainer,
  useComponentConfigContext
} from "../ComponentConfigContext";
import { ConfigSection, Field } from "../ConfigPanel";
import { BoxLayoutValueField } from "../DesignConfig/Inputs";
import HeaderConfigSection from "../HeaderConfig";
import ParametersConfigSection from "../ParametersManager/ParametersConfigSection";
import VisibilityRulesManagerSection from "../VisibilityRulesManager";

import EffectsConfig from "./EffectsConfig/EffectsConfig";
import FunctionConfig from "./FunctionConfig";
import { ensureSubmittableComponentConfigState } from "./reducer/reducer";
import TextConfig from "./TextConfig";

export const DEFAULT_FORM_POPOVER_WIDTH = "520px";

export default function SubmittableComponentConfig(props: ComponentConfigProps) {
  const dataContent = React.useMemo(() => <DataConfig />, []);
  const effectsContent = React.useMemo(() => <EffectsConfig />, []);
  const designContent = React.useMemo(() => <DesignConfig />, []);
  return (
    <ComponentConfigContextContainer
      slug={props.slug}
      dataConfig={dataContent}
      effectsConfig={effectsContent}
      designConfig={designContent}
    />
  );
}

export function DataConfig() {
  const context = useComponentConfigContext();
  const state = ensureSubmittableComponentConfigState(context.state);
  const { dispatch } = context;
  const func = state.draftComponent.functions.edges[0]?.node;
  const functionId = func?.id;

  return (
    <>
      <FunctionConfig state={state} dispatch={dispatch} allowPipelineFunctions />
      <BaseComponentConfigSection>
        <TextConfig state={state} dispatch={dispatch} />
      </BaseComponentConfigSection>
      {/* always render ParametersConfigSection so that component cleanup can occur when data source changes and functionId is undefined */}
      <ItemContainer itemKey="fieldset">
        <ParametersConfigSection title="Fields" />
      </ItemContainer>
      {!!functionId && <VisibilityRulesManagerSection />}
    </>
  );
}

function FormLayoutConfig() {
  const context = useComponentConfigContext();
  const { dispatch } = context;
  const state = context.state;

  const themeProperties = useMemo(() => {
    return (
      state.draftComponent.properties.theme_properties || {
        width: DEFAULT_FORM_POPOVER_WIDTH
      }
    );
  }, [state.draftComponent.properties.theme_properties]);

  const updateThemeProperties = useCallback(
    change => {
      dispatch({
        type: "UPDATE_COMPONENT_THEME_PROPERTIES",
        payload: {
          properties: {
            ...themeProperties,
            ...change
          }
        }
      });
    },
    [dispatch, themeProperties]
  );

  return (
    <ConfigSection title="Form layout">
      <BoxLayoutValueField
        label="Width"
        value={themeProperties["width"]}
        onChange={value => {
          updateThemeProperties({
            width: value
          });
        }}
        onChangeUnit={(unit: LayoutUnit) => {
          const numericValue = parseFloat(themeProperties["width"]);
          updateThemeProperties({
            width: `${numericValue}${unit}`
          });
        }}
      />
    </ConfigSection>
  );
}

function FormStyleConfig() {
  const context = useComponentConfigContext();
  const { dispatch } = context;
  const state = context.state;

  const themeProperties = useMemo(() => {
    return (
      state.draftComponent.properties.theme_properties || {
        width: DEFAULT_FORM_POPOVER_WIDTH
      }
    );
  }, [state.draftComponent.properties.theme_properties]);

  const updateThemeProperties = useCallback(
    change => {
      dispatch({
        type: "UPDATE_COMPONENT_THEME_PROPERTIES",
        payload: {
          properties: {
            ...themeProperties,
            ...change
          }
        }
      });
    },
    [dispatch, themeProperties]
  );

  return (
    <ConfigSection title="Form style">
      <Field>
        <label>Button fill</label>
        <ColorPicker
          value={
            themeProperties["submit-background-color"] ||
            DEFAULT_BUTTON_BACKGROUND_COLOR
          }
          size="small"
          onChange={value => {
            updateThemeProperties({ "submit-background-color": value });
          }}
        />
      </Field>
      <Field>
        <label>Button text color</label>
        <ColorPicker
          value={themeProperties["submit-text-color"] || DEFAULT_BUTTON_TEXT_COLOR}
          size="small"
          onChange={value => {
            updateThemeProperties({ "submit-text-color": value });
          }}
        />
      </Field>
    </ConfigSection>
  );
}

function DesignConfig() {
  const context = useComponentConfigContext();
  const { draftComponent } = context.state;

  return (
    <>
      {draftComponent.type === "FUNCTION_MODAL_FORM" && (
        <>
          <FormLayoutConfig />
          <FormStyleConfig />
        </>
      )}
      <ButtonDesignConfig />
      {draftComponent.type === "FUNCTION_FORM" && <HeaderConfigSection />}
    </>
  );
}
