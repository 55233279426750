import React, { useEffect, useMemo } from "react";

import { Icon } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";

import ButtonNew from "../components/common/ButtonNew/ButtonNew";
import useAccountStatus from "../components/common/hooks/useAccountStatus";
import useAuthUser from "../components/common/hooks/useAuthUser";
import usePaths from "../components/common/hooks/usePaths";
import Message from "../components/common/Message";
import { B3, Card } from "../components/common/StyledComponents";
import { Urls } from "../constants";
import ErrorPageLayout, { ErrorPageSidebarLayout } from "../layouts/ErrorPageLayout";
import PageHeader from "../layouts/Header/Header";
import { LeftCol } from "../layouts/Layout";
import Nav from "../layouts/Nav";

const LeftRail = styled(LeftCol)``;

const Cols = styled.div`
  display: flex;
  align-self: stretch;
  align-items: stretch;
  font-weight: 500;
`;

export const ServiceUnavailable = () => {
  const { getDashboard } = usePaths();
  return (
    <ErrorPageLayout>
      <p>The service is currently unavailable. Please try again in a few moments.</p>
      <Link to={getDashboard()}>Back to home</Link>
    </ErrorPageLayout>
  );
};

export const Unauthorized = () => {
  const { getDashboard } = usePaths();
  return (
    <ErrorPageLayout>
      <p>You are not authorized to view this page.</p>
      <Link to={getDashboard()}>Back to home</Link>
    </ErrorPageLayout>
  );
};

export const NotFound = () => {
  const { getDashboard } = usePaths();
  return (
    <ErrorPageLayout>
      <p>The page you were looking for does not exist.</p>
      <Link to={getDashboard()}>Back to home</Link>
    </ErrorPageLayout>
  );
};

const FeatureOverLimitContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

const ErrorText = styled(B3)`
  color: ${props => props.theme.errorColor};
`;

// Use a wrapper because antd icon uses theme property which doesn't match any of our themes
const ErrorIcon = styled.div`
  color: ${props => props.theme.errorColor};
  font-size: 18px;
`;

const OverLimitCard = styled(Card)`
  margin-bottom: 20px;
`;

function FeatureOverLimit({ children }: { children: React.ReactNode }) {
  return (
    <FeatureOverLimitContainer>
      <ErrorIcon>
        <Icon type="exclamation-circle" theme="filled" />
      </ErrorIcon>
      <ErrorText>{children}</ErrorText>
    </FeatureOverLimitContainer>
  );
}

function IntegerFeatureOverLimit({
  name,
  value,
  limit
}: {
  name: string;
  value: number;
  limit: number;
}) {
  const message = `Maximum of ${limit} ${name} (currently exceeding max ${name} by ${
    value - limit
  })`;

  return <FeatureOverLimit>{message}</FeatureOverLimit>;
}

export const AccountLocked = () => {
  const { isAdmin } = useAuthUser();

  const { data, error } = useAccountStatus({});

  useEffect(() => {
    if (error) {
      Message.error("Unable to load account status");
    }
  }, [error]);

  const hasFeatureOverLimit = useMemo(() => {
    if (!data) {
      return false;
    }

    return Object.values(data.accountStatus?.featuresStatus || {}).some(
      feature => feature.overLimit
    );
  }, [data]);

  // The AccountStatus endpoint is locked to Admins, null is returned otherwise.
  const featuresStatus = data?.accountStatus?.featuresStatus;

  return (
    <>
      <PageHeader key="space-home" />
      <Cols>
        {(isAdmin || process.env.INTERNALIO_ONPREM !== "1") && (
          <LeftRail open>
            <Nav />
          </LeftRail>
        )}
        <ErrorPageSidebarLayout>
          <p>Your account has been locked. Please contact support for more details.</p>
          {featuresStatus && hasFeatureOverLimit && (
            <OverLimitCard>
              {data.accountStatus.subscriptionStatus === "past_due" && (
                <FeatureOverLimit>Subscription is past due</FeatureOverLimit>
              )}
              {featuresStatus.dataSources.overLimit && (
                <IntegerFeatureOverLimit
                  name="data sources"
                  value={featuresStatus.dataSources.value}
                  limit={featuresStatus.dataSources.limit}
                />
              )}
              {featuresStatus.userLimit.overLimit && (
                <IntegerFeatureOverLimit
                  name="users"
                  value={featuresStatus.userLimit.value}
                  limit={featuresStatus.userLimit.limit}
                />
              )}
              {featuresStatus.roles.overLimit && (
                <IntegerFeatureOverLimit
                  name="roles"
                  value={featuresStatus.roles.value}
                  limit={featuresStatus.roles.limit}
                />
              )}
              {featuresStatus.twoFactor.overLimit && (
                <FeatureOverLimit>
                  'Require employees to use Two-Step Verification' option is turned{" "}
                  {featuresStatus.twoFactor.value ? "on" : "off"}
                </FeatureOverLimit>
              )}
              {featuresStatus.sso.overLimit && (
                <FeatureOverLimit>
                  'Require employees to use Single Sign-On' option is turned{" "}
                  {featuresStatus.sso.value ? "on" : "off"}
                </FeatureOverLimit>
              )}
            </OverLimitCard>
          )}
          <ButtonNew
            type="brand"
            onClick={() => window.open(Urls.SUPPORT_URL, "_blank")}
          >
            Contact Support
          </ButtonNew>
        </ErrorPageSidebarLayout>
      </Cols>
    </>
  );
};

export function AccountLockedGate({ children }: { children: React.ReactNode }) {
  const { authUser } = useAuthUser();
  if (authUser?.organization.locked) return <AccountLocked />;
  return <>{children}</>;
}
