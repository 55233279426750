import produce from "immer";
import { cloneDeep, flow, partial, isEqual } from "lodash";

import { SpaceComponentObject, SourceType } from "../../../../../../types";
import commonComponentReducer from "../../../../SpaceConfig/SpaceConfigContext/useSpaceConfig/reducer/componentReducer";
import {
  SpaceConfigAction,
  BaseConfigAction,
  ComponentConfigState,
  BaseComponentConfigState
} from "../../../../types";
import filterListManagerReducer, {
  INITIAL_STATE as FILTER_LIST_MANAGER_INITIAL_STATE,
  FilterListManagerState,
  FilterListManagerAction,
  getInitialFilterState
} from "../../common/FilterListManager/reducer/reducer";
import parameterConfigReducer, {
  getInitialParameterState,
  INITIAL_STATE as PARAMETER_CONFIG_INITIAL_STATE,
  ParameterConfigState
} from "../../common/ParametersManager/reducer/reducer";
import viewDataConfigReducer, {
  INITIAL_STATE as VIEW_DATA_CONFIG_INITIAL_STATE,
  ViewConfigState,
  ViewConfigAction
} from "../../common/useViewConfig/reducer";
import { functionTitleToComponentName } from "../../common/util";
import {
  SpaceChartComponent,
  ChartType,
  AggregationFunctionType,
  GroupingFunctionType,
  SubSeriesMode
} from "../types";

export interface ChartConfigState
  extends BaseComponentConfigState<SpaceChartComponent>,
    ViewConfigState,
    FilterListManagerState,
    ParameterConfigState {
  subSeriesMode: SubSeriesMode;
}

interface ChangeChartTypeAction extends BaseConfigAction {
  type: "CHANGE_CHART_TYPE";
  payload: { chart_type: ChartType };
}

interface ChangeYAxisAggregationFunctionType extends BaseConfigAction {
  type: "CHANGE_Y_AXIS_AGGREGATION_FUNCTION_TYPE";
  payload: { type: AggregationFunctionType };
}

interface ChangeSubSeriesMode extends BaseConfigAction {
  type: "CHANGE_SUB_SERIES_MODE";
  payload: { mode: SubSeriesMode; retain?: boolean };
}

interface ChangeSubGroupingFunctionAttribute extends BaseConfigAction {
  type: "CHANGE_SUB_GROUPING_FUNCTION_ATTRIBUTE";
  payload: { attribute: string | -1 };
}

interface AddXAxisGroupingFunctionType extends BaseConfigAction {
  type: "ADD_X_AXIS_GROUPING_FUNCTION_TYPE";
}

interface RemoveXAxisGroupingFunctionType extends BaseConfigAction {
  type: "REMOVE_X_AXIS_GROUPING_FUNCTION_TYPE";
  payload: { groupingFunctionTypeIndex: number };
}

export type ChartConfigAction =
  | ViewConfigAction
  | FilterListManagerAction
  | ChangeChartTypeAction
  | ChangeYAxisAggregationFunctionType
  | ChangeSubGroupingFunctionAttribute
  | ChangeSubSeriesMode
  | AddXAxisGroupingFunctionType
  | RemoveXAxisGroupingFunctionType;

export const INITIAL_STATE: ChartConfigState = {
  type: "CHART",
  draftComponent: {} as SpaceChartComponent,
  subSeriesMode: SubSeriesMode.NONE,
  ...VIEW_DATA_CONFIG_INITIAL_STATE,
  ...FILTER_LIST_MANAGER_INITIAL_STATE,
  ...PARAMETER_CONFIG_INITIAL_STATE
};

export function ensureSpaceChartComponent(
  component: SpaceComponentObject
): SpaceChartComponent {
  const nextComponent = {
    ...component,
    sourceType: component.sourceType || SourceType.VIEW,
    properties: {
      ...component.properties,
      chart_type: component.properties.chart_type || ChartType.COLUMN,
      y_axis: component.properties.y_axis || {
        aggregation_function: {
          type: AggregationFunctionType.COUNT,
          attribute: undefined
        }
      },
      x_axis: component.properties.x_axis || {
        grouping_function: {
          types: [GroupingFunctionType.EQUALITY],
          attribute: undefined
        }
      },
      sub_group_functions: component.properties.sub_group_functions || [],
      preaggregated_attributes: component.properties.preaggregated_attributes || [],
      columns: component.properties.columns || [],
      filters: component.properties.filters || [],
      is_header_enabled: component.properties.is_header_enabled ?? true,
      is_filter_required: component.properties.is_filter_required ?? false,
      order: component.properties.order || [],
      input_parameters: component.properties.input_parameters || []
    }
  };
  return isEqual(component, nextComponent) ? component : nextComponent;
}

function isChartConfigState(state: ComponentConfigState): state is ChartConfigState {
  return state.type === "CHART";
}

export function ensureChartConfigState(state: ComponentConfigState): ChartConfigState {
  if (isChartConfigState(state)) return state;

  throw new Error("Expected chart config state.");
}

export function makeInitialState(
  draftComponent: SpaceComponentObject
): ChartConfigState {
  return {
    ...INITIAL_STATE,
    draftComponent: ensureSpaceChartComponent(cloneDeep(draftComponent)),
    ...getInitialFilterState(draftComponent),
    ...getInitialParameterState(draftComponent)
  };
}

function reducer(state: ChartConfigState, action: SpaceConfigAction): ChartConfigState {
  switch (action.type) {
    case "LOAD_FUNCTION": {
      if (state.draftComponent.name) return state;
      return produce(state, (draftState: ChartConfigState) => {
        const humanized = functionTitleToComponentName(action.payload.title);
        draftState.draftComponent.name = `${humanized} chart`;
        draftState.draftComponent.properties.title = `${humanized} chart`;
      });
    }

    case "CHANGE_FUNCTION": {
      return produce(state, (draftState: ChartConfigState) => {
        draftState.draftComponent.properties.y_axis.aggregation_function = {
          type: AggregationFunctionType.COUNT,
          attribute: undefined
        };
        draftState.draftComponent.properties.x_axis.grouping_function = {
          types: [GroupingFunctionType.EQUALITY],
          attribute: undefined
        };
        draftState.draftComponent.properties.sub_group_functions = [];
      });
    }

    case "CHANGE_CHART_TYPE": {
      return produce(state, (draftState: ChartConfigState) => {
        draftState.draftComponent.properties.chart_type = action.payload.chart_type;

        if (action.payload.chart_type === ChartType.PIE) {
          draftState.subSeriesMode = SubSeriesMode.NONE;
          draftState.draftComponent.properties.sub_group_functions = [];
          draftState.draftComponent.properties.preaggregated_attributes = [];
        }
      });
    }

    case "CHANGE_Y_AXIS_AGGREGATION_FUNCTION_TYPE": {
      const { type } = action.payload;
      return produce(state, (draftState: ChartConfigState) => {
        draftState.draftComponent.properties.y_axis.aggregation_function = {
          type,
          attribute: undefined
        };
      });
    }

    case "CHANGE_SUB_SERIES_MODE": {
      return produce(state, (draftState: ChartConfigState) => {
        draftState.subSeriesMode = action.payload.mode;
        if (!action.payload.retain) {
          draftState.draftComponent.properties.sub_group_functions = [];
          draftState.draftComponent.properties.preaggregated_attributes = [];
        }
      });
    }

    case "CHANGE_SUB_GROUPING_FUNCTION_ATTRIBUTE": {
      const { attribute } = action.payload;
      return produce(state, (draftState: ChartConfigState) => {
        draftState.draftComponent.properties.sub_group_functions =
          attribute === -1
            ? []
            : [{ types: [GroupingFunctionType.EQUALITY], attribute }];
      });
    }

    default:
      return state;
  }
}

export default (state: ChartConfigState, action: SpaceConfigAction) =>
  flow([
    commonComponentReducer,
    partial(viewDataConfigReducer, partial.placeholder, action),
    partial(parameterConfigReducer, partial.placeholder, action),
    partial(filterListManagerReducer, partial.placeholder, action),
    partial(reducer, partial.placeholder, action)
  ])(state, action);
