import { Dispatch } from "react";

import { cloneDeep } from "lodash";

import { deprecate } from "../../../../../../logging";
import {
  SpaceComponentObject,
  SpaceComponentPackage,
  SpaceComponentType,
  SpaceNode,
  RelayNode
} from "../../../../../../types";
import * as exceptionReporting from "../../../../../util/exceptionReporting";
import { ElementLayout } from "../../../../layout/util";
import {
  BaseComponentConfigState,
  BaseConfigAction,
  ComponentConfigState,
  SpaceConfigAction,
  SpaceParameter
} from "../../../../types";
import { findInputBindings } from "../../../../util";
import createSpace from "../../../../util/createSpace";
import rewriteSubSpacePaths, {
  rewritePaths
} from "../../../../util/rewriteSubSpacePaths";
import { UseConfigMutationData } from "../useConfigMutation/useConfigMutation";

import commonComponentReducer from "./componentReducer";
import {
  extractState,
  insertComponentInTree,
  removeComponentFromNode,
  selectAllSlugs
} from "./util";
import { extractComponentState } from "./util/extractState";
import { insertNodeInTree } from "./util/insertComponentInTree";
import makeComponent from "./util/makeComponent";
import moveTreeNode from "./util/moveTreeNode";
import removeComponent from "./util/removeComponent";
import toSubSpacePath from "./util/toSubSpacePath";

export interface TreeNode {
  slug: null | string;
  treeNodes: TreeNode[];
  container: null | TreeNode;
  type: null | SpaceComponentType;
}

export interface BaseSpaceConfig extends Omit<SpaceNode, "id"> {
  id?: string | null;
  components: Map<string, ComponentConfigState>;
  tree: TreeNode | null;
  elementLayouts: Map<string, Partial<ElementLayout>>;
  parameters: Array<SpaceParameter & Partial<RelayNode>>;
  versionId?: string;
  scmVersion?: string | null;
}

export const createSpaceConfig = (
  opts: Partial<BaseSpaceConfig> = {}
): BaseSpaceConfig => ({
  name: "name",
  slug: "slug",
  branchCount: 0,
  branches: { __typename: "BranchNodeConnection", edges: [] },
  scmIsCurrent: false,
  scmStatus: null,
  scmSyncStatus: null,
  components: new Map(),
  tree: { slug: "root", container: null, type: null, treeNodes: [] },
  elementLayouts: new Map(),
  parameters: [],
  ...opts
});

export interface SpaceConfigState {
  hasNameError: boolean;
  spaces: Map<string, BaseSpaceConfig>;
  rootSpaceSlug: string;
  apiErrors: UseConfigMutationData["spaceUpdate"]["source"]["slugs"];
  removedSlugs: Map<string, Set<string>>;
  submitted: boolean;
  dirty: boolean;
  isStateLoaded: boolean;
  touchedComponents: Set<string>; // components that have been updated (we currently only validate components that have been touched),
  packages: Map<SpaceComponentType, SpaceComponentPackage>;
  uiStateOverrides: {
    openModals: Set<string>;
  };
}

interface SetComponentAction extends BaseConfigAction {
  type: "SET_COMPONENT";
  payload: { slug: string; component: SpaceComponentObject };
}

export interface InsertComponentPayload {
  componentType: SpaceComponentType;
  parentSlug: string | null;
  componentConfig?: Partial<SpaceComponentObject>;
  spaceSlug?: string;
}
interface InsertComponentAction extends BaseConfigAction {
  type: "INSERT_COMPONENT";
  payload: InsertComponentPayload;
}

// TODO: Rename once all components are flexible layout ;)
interface InsertFlexibleLayoutComponent extends BaseConfigAction {
  type: "INSERT_FLEXIBLE_LAYOUT_COMPONENT";
  payload: {
    spaceSlug?: string;
    componentConfig: {
      type: SpaceComponentType;
      layout: ElementLayout;
    } & Partial<SpaceComponentObject>;
  };
}

interface UpdateComponentLayout extends BaseConfigAction {
  type: "UPDATE_COMPONENT_LAYOUT";
  payload: {
    slug: string;
    layout: Partial<ElementLayout>;
  };
}

interface SaveSuccessAction extends BaseConfigAction {
  type: "HANDLE_SAVE";
}

interface InitNewSpaceAction extends BaseConfigAction {
  type: "INIT_NEW_SPACE";
  payload: {};
}

interface InitExistingSpaceAction extends BaseConfigAction {
  type: "INIT_EXISTING_SPACE";
  payload: { space: SpaceNode; force?: boolean };
}

interface SetSpaceNameAction extends BaseConfigAction {
  type: "SET_SPACE_NAME";
  payload: { name: string };
}

interface RemoveComponentAction extends BaseConfigAction {
  type: "REMOVE_COMPONENT";
  payload: {
    slug: string;
  };
}

interface SetConfigErrors extends BaseConfigAction {
  type: "SET_API_ERRORS";
  payload: {
    errors: UseConfigMutationData["spaceUpdate"]["source"]["slugs"];
  };
}

interface SetComponentErrorState extends BaseConfigAction {
  type: "SET_COMPONENT_ERROR_STATE";
  payload: {
    slug: string;
    hasError: boolean;
  };
}

interface Submit extends BaseConfigAction {
  type: "SUBMIT";
}

interface SetNameError extends BaseConfigAction {
  type: "SET_NAME_ERROR";
}

interface RegisterDraftComponentReducers extends BaseConfigAction {
  type: "REGISTER_PACKAGES";
  payload: {
    packages: SpaceComponentPackage[];
  };
}

interface MoveComponent extends BaseConfigAction {
  type: "MOVE_COMPONENT";
  payload: {
    sourcePath: string;
    destinationPath: string;
    index: number;
    spaceSlug?: string;
  };
}

interface CreateSubSpace extends BaseConfigAction {
  type: "CREATE_SUB_SPACE";
  payload: {
    sourceComponentSlug: string;
    name?: string;
  };
}

interface AddSpaceParam extends BaseConfigAction {
  type: "ADD_SPACE_PARAM";
  payload: {
    spaceSlug: string;
    param: SpaceParameter;
  };
}

interface RemoveSpaceParam extends BaseConfigAction {
  type: "REMOVE_SPACE_PARAM";
  payload: {
    spaceSlug: string;
    index: number;
  };
}

interface UpdateSpaceParam extends BaseConfigAction {
  type: "UPDATE_SPACE_PARAM";
  payload: {
    spaceSlug: string;
    index: number;
    param: Partial<SpaceParameter>;
  };
}

export interface UpdateScmVersion extends BaseConfigAction {
  type: "UPDATE_SCM_VERSION";
  payload: { spaceSlug: string; scmVersion: string };
}

export interface UpdateUIStateOverrides extends BaseConfigAction {
  type: "UPDATE_UI_STATE_OVERRIDES";
  payload: { uiStateOverrides: SpaceConfigState["uiStateOverrides"] };
}

export type RootSpaceConfigAction =
  | InitNewSpaceAction
  | InitExistingSpaceAction
  | InsertComponentAction
  | InsertFlexibleLayoutComponent
  | UpdateComponentLayout
  | SetSpaceNameAction
  | SetComponentAction
  | SetComponentErrorState
  | SetConfigErrors
  | Submit
  | SetNameError
  | SaveSuccessAction
  | RemoveComponentAction
  | RegisterDraftComponentReducers
  | MoveComponent
  | CreateSubSpace
  | AddSpaceParam
  | RemoveSpaceParam
  | UpdateSpaceParam
  | UpdateScmVersion
  | UpdateUIStateOverrides;

export type SpaceConfigDispatch = Dispatch<SpaceConfigAction>;

export const INITIAL_STATE: SpaceConfigState = {
  spaces: new Map(),
  rootSpaceSlug: "",
  apiErrors: {},
  submitted: false,
  hasNameError: false,
  dirty: false,
  isStateLoaded: false,
  touchedComponents: new Set(),
  packages: new Map(),
  removedSlugs: new Map(),
  uiStateOverrides: {
    openModals: new Set<string>()
  }
};

export function selectComponentToSpaceMap(state: SpaceConfigState) {
  return new Map(
    Array.from(state.spaces.values()).flatMap(space =>
      Array.from(space.components.entries()).map(([componentSlug]) => [
        componentSlug,
        space
      ])
    )
  );
}

export function createBaseComponentConfigState(
  component: SpaceComponentObject = makeComponent(new Set(), {
    type: "VOID" as SpaceComponentType
  })
): BaseComponentConfigState {
  return {
    type: component.type,
    draftComponent: component
  };
}

export function createInitialComponentConfigState(
  type: SpaceComponentType,
  slugs: Set<string>,
  packages: Map<SpaceComponentType, SpaceComponentPackage>,
  componentConfig: Partial<SpaceComponentObject> = {}
): ComponentConfigState {
  const _package = packages.get(type);
  if (_package === undefined) {
    console.warn(`Expected package for component type ${type}.`);
    throw new Error("Expected to find component package.");
  }

  let component = makeComponent(slugs, {
    type,
    ...componentConfig
  });
  component = _package.ensureComponent
    ? _package.ensureComponent(component)
    : component;

  let getInitialDraftState = _package.getInitialDraftState;
  if (typeof getInitialDraftState !== "function") {
    getInitialDraftState = createBaseComponentConfigState;
  }

  return getInitialDraftState(component);
}

let lastCheckString = "";
function ensureBindings(state: SpaceConfigState) {
  let ensuredState = state;
  const bindingRelatedState: Record<
    string,
    { components: Map<string, ComponentConfigState>; tree: TreeNode }
  > = {};
  state.spaces.forEach(space => {
    bindingRelatedState[space.slug] = {
      components: space.components,
      tree: space.tree!
    };
  });
  const checkString = JSON.stringify(bindingRelatedState, function (key, value) {
    if (key === "container" && this.type && this.treeNodes) {
      return null;
    } else if (key === "container" && this.type && this.componentTreeNodes) {
      return null;
    } else if (value instanceof Map) {
      return {
        dataType: "Map",
        value: Array.from(value.entries()) // or with spread: value: [...value]
      };
    }
    return value;
  });
  if (lastCheckString !== checkString) {
    lastCheckString = checkString;
    ensuredState = rewriteSubSpacePaths(state);
  }
  return ensuredState;
}

export default function wrappedReducer(
  state: SpaceConfigState = cloneDeep(INITIAL_STATE),
  action: SpaceConfigAction
) {
  let nextState = reducer(state, action);
  nextState = ensureBindings(nextState);
  return nextState;
}

export function reducer(
  state: SpaceConfigState = cloneDeep(INITIAL_STATE),
  action: SpaceConfigAction
): SpaceConfigState {
  setTimeout(() => {
    exceptionReporting.addBreadCrumb({
      type: "info",
      level: exceptionReporting.Severity.Info,
      category: "space config reducer",
      message: `Space config reducer action ${action.type}`,
      data: {
        action,
        flattened: exceptionReporting.getFlattenedRedactedData(action)
      }
    });
  }, 10);

  const componentToSpace = selectComponentToSpaceMap(state);

  const getSlugsSpace = (slug: string | undefined | null) =>
    slug ? componentToSpace.get(slug) : state.spaces.get(state.rootSpaceSlug);

  if ("componentSlug" in action && action.componentSlug) {
    const { componentSlug } = action;
    const space = getSlugsSpace(componentSlug);
    if (!space) throw new Error("Expected to find space.");
    const draftState = space.components.get(componentSlug)!;
    let componentReducer = null;
    if (draftState === undefined) {
      exceptionReporting.reportException(
        new Error("Expected draft state for component."),
        { extra: { action: JSON.stringify(action) } }
      );
    } else {
      componentReducer = state.packages.get(
        draftState.draftComponent.type
      )?.componentConfigReducer;
    }
    if (!componentReducer) {
      componentReducer = commonComponentReducer;
    }
    const nextComponentState = componentReducer(draftState, action);
    const touchedComponents = new Set(state.touchedComponents);

    // Do not break memoizations when component reducer no-oped
    if (nextComponentState === draftState) return state;

    space.components = new Map(space.components).set(componentSlug, nextComponentState);
    touchedComponents.add(componentSlug);
    const { [componentSlug]: obsoleteError, ...apiErrors } = state.apiErrors;
    return {
      ...state,
      dirty: true,
      touchedComponents,
      apiErrors: obsoleteError ? apiErrors : state.apiErrors,
      spaces: new Map(state.spaces).set(space.slug, { ...space })
    };
  }

  switch (action.type) {
    case "REGISTER_PACKAGES": {
      const { packages } = action.payload;
      const packagesMap = packages.reduce((acc, p) => {
        if (!p) return acc;
        acc.set(p.type, p);
        return acc;
      }, new Map());

      return {
        ...state,
        packages: packagesMap
      };
    }

    case "INIT_NEW_SPACE": {
      if (state.isStateLoaded) return state;
      const newSlugs = new Set<string>();
      const params = makeComponent(newSlugs, {
        type: "PARAMS",
        container: null,
        slug: "params",
        properties: { url_parameters: [] }
      });
      const header = makeComponent(newSlugs, {
        type: "HEADER",
        container: null,
        layout: new ElementLayout({
          left: "2%",
          top: "0px",
          width: "96%",
          height: "86px",
          snapToGrid: true
        })
      });
      return {
        ...cloneDeep(INITIAL_STATE),
        rootSpaceSlug: "",
        spaces: new Map([
          [
            "",
            {
              parameters: [],
              name: "",
              slug: "",
              branchCount: 0,
              scmIsCurrent: false,
              scmStatus: null,
              scmSyncStatus: null,
              ...extractComponentState([header, params], state.packages)
            }
          ]
        ]),
        packages: state.packages,
        isStateLoaded: true
      };
    }

    case "INIT_EXISTING_SPACE": {
      if (state.isStateLoaded && !action.payload.force) return state;
      const { space } = action.payload;
      return {
        ...INITIAL_STATE,
        ...extractState(space, state.packages),
        rootSpaceSlug: space.slug,
        packages: state.packages,
        dirty: false,
        isStateLoaded: true
      };
    }

    case "SET_SPACE_NAME": {
      const { name } = action.payload;

      return {
        ...state,
        spaces: new Map(state.spaces).set(state.rootSpaceSlug, {
          ...state.spaces.get(state.rootSpaceSlug)!,
          name
        }),
        hasNameError: !name && name !== "new",
        dirty: true
      };
    }

    case "SET_NAME_ERROR": {
      return { ...state, hasNameError: true };
    }

    // SET_COMPONENT is legacy and only used by header config at this point. TODO: remove
    case "SET_COMPONENT": {
      deprecate("SpaceConfigReducer SET_COMPONENT");
      const { slug, component } = cloneDeep(action.payload);
      const space = state.spaces.get(state.rootSpaceSlug)!;
      let apiErrors = state.apiErrors;
      if (apiErrors[slug]) {
        apiErrors = cloneDeep(state.apiErrors);
        delete apiErrors[slug];
      }
      space.components = new Map(space.components).set(slug, {
        ...space.components.get(slug)!,
        draftComponent: component
      });

      // update touchedComponents so the component is included in config validation
      const touchedComponents = state.touchedComponents.has(slug)
        ? state.touchedComponents
        : new Set(state.touchedComponents).add(slug);

      return {
        ...state,
        spaces: new Map(state.spaces).set(state.rootSpaceSlug, space),
        dirty: true,
        apiErrors,
        touchedComponents
      };
    }

    case "INSERT_FLEXIBLE_LAYOUT_COMPONENT": {
      const {
        spaceSlug = state.rootSpaceSlug,
        componentConfig: { type, ...initialConfig }
      } = action.payload;

      const space = state.spaces.get(spaceSlug);
      if (!space) throw new Error("Expected to find space.");
      const initialDraftState = createInitialComponentConfigState(
        type,
        selectAllSlugs(state),
        state.packages,
        initialConfig
      );
      const { slug } = initialDraftState.draftComponent;
      space.components = new Map(space.components).set(
        initialDraftState.draftComponent.slug,
        initialDraftState
      );

      if (space.tree === null) {
        throw new Error("Expected tree to be present.");
      }
      space.tree = insertComponentInTree(
        space.tree,
        initialDraftState.draftComponent,
        null
      );
      space.elementLayouts = new Map(space.elementLayouts).set(
        slug,
        initialConfig.layout
      );

      return {
        ...state,
        spaces: new Map(state.spaces).set(spaceSlug, space),
        dirty: true
      };
    }

    case "UPDATE_COMPONENT_LAYOUT": {
      const { slug, layout } = action.payload;
      const space = getSlugsSpace(slug);
      if (!space) throw new Error("Expected to find space.");

      const rawExistingLayout = space.elementLayouts.get(slug) || new ElementLayout();
      const existingLayout = new ElementLayout(rawExistingLayout);
      const nextElementLayout = new ElementLayout(rawExistingLayout);
      nextElementLayout.merge(layout);
      if (
        space.elementLayouts.has(slug) &&
        rawExistingLayout &&
        Object.keys(rawExistingLayout).length ===
          Object.keys(nextElementLayout).length &&
        existingLayout.isEqual(nextElementLayout)
      ) {
        return state;
      }
      space.elementLayouts = new Map(space.elementLayouts).set(slug, nextElementLayout);

      return {
        ...state,
        dirty: true,
        spaces: new Map(state.spaces).set(space.slug, space)
      };
    }

    case "INSERT_COMPONENT": {
      const { parentSlug, componentType, componentConfig, spaceSlug } = action.payload;
      const space = spaceSlug ? state.spaces.get(spaceSlug) : getSlugsSpace(parentSlug);

      if (!space) throw new Error("Expected space.");

      const initialDraftState = createInitialComponentConfigState(
        componentType,
        selectAllSlugs(state),
        state.packages,
        componentConfig || {}
      );
      const component = initialDraftState.draftComponent;
      space.components = new Map(space.components).set(
        component.slug,
        initialDraftState
      );
      space.tree = insertComponentInTree(space.tree, component, parentSlug);
      if (componentConfig?.layout) {
        space.elementLayouts = new Map(space.elementLayouts).set(
          component.slug,
          componentConfig.layout
        );
      }

      // update touchedComponents so the component is included in config validation
      const touchedComponents = new Set(state.touchedComponents).add(component.slug);

      return {
        ...state,
        dirty: true,
        spaces: new Map(state.spaces).set(space.slug, space),
        touchedComponents
      };
    }

    case "REMOVE_COMPONENT":
      const apiErrors = cloneDeep(state.apiErrors);

      const { slug } = action.payload;
      const space = getSlugsSpace(slug);
      if (!space) throw new Error("Expected space.");

      if (!space.tree) {
        throw new Error("Expected tree to be present.");
      }

      const { treeNode, removedSlugs } = removeComponentFromNode(space.tree, slug);
      space.tree = treeNode;
      const components = new Map(space.components);
      const elementLayouts = new Map(space.elementLayouts);

      const removedComponent = components.get(slug);
      const spaces = new Map(state.spaces);
      if (removedComponent?.draftComponent.type === "SUB_SPACE") {
        const flatComponents = Array.from(state.spaces.values()).flatMap(s =>
          Array.from(s.components.values())
        );
        if (
          !flatComponents.some(
            c =>
              c.type === "SUB_SPACE" &&
              c.draftComponent.slug !== slug &&
              c.draftComponent.properties.sub_space ===
                removedComponent.draftComponent.properties.sub_space
          )
        ) {
          // No more references to this sub space, so remove it from the spaces map
          spaces.delete(removedComponent.draftComponent.properties.sub_space);
        }
      }

      removedSlugs.forEach(rs => {
        components.delete(rs);
        elementLayouts.delete(rs);
        delete apiErrors[rs];
      });

      space.components = components;
      space.elementLayouts = elementLayouts;
      const allRemovedSlugs = state.removedSlugs?.set(
        space.slug,
        new Set(
          removedSlugs.concat(
            Array.from(
              state.removedSlugs.has(space.slug)
                ? state.removedSlugs.get(space.slug)!
                : []
            )
          )
        )
      );

      return {
        ...state,
        dirty: true,
        spaces: spaces.set(space.slug, space),
        apiErrors,
        removedSlugs: allRemovedSlugs
      };

    case "MOVE_COMPONENT": {
      const { sourcePath, destinationPath, index } = action.payload;
      const sourceComponentSlug = sourcePath.split(".").pop() as string;
      let sourceSpace = getSlugsSpace(sourceComponentSlug);
      const destinationComponentSlug = destinationPath.split(".").pop() as
        | string
        | undefined;
      let destinationSpace = getSlugsSpace(destinationComponentSlug)!;

      if (!destinationSpace) throw new Error("Expected space.");

      if (sourceSpace?.tree === null || destinationSpace.tree === null) return state;
      sourceSpace = cloneDeep(sourceSpace);
      destinationSpace = cloneDeep(destinationSpace);

      if (sourceSpace) state.spaces.set(sourceSpace?.slug, sourceSpace);
      state.spaces.set(destinationSpace.slug, destinationSpace);

      if (sourceSpace && sourceSpace.slug !== destinationSpace.slug) {
        // Remove component from sourceSpace
        const removeResult = removeComponent(sourceSpace, sourceComponentSlug);
        // Inject component into destinationSpace
        let sourceComponent: SpaceComponentObject | undefined;
        removeResult.componentConfigs.forEach(componentConfig => {
          if (componentConfig.draftComponent.slug === sourceComponentSlug) {
            sourceComponent = componentConfig.draftComponent;
          }
          destinationSpace.components.set(
            componentConfig.draftComponent.slug,
            componentConfig
          );
          destinationSpace.elementLayouts.set(
            componentConfig.draftComponent.slug,
            removeResult.elementLayouts.get(componentConfig.draftComponent.slug)!
          );
        });
        if (!sourceComponent) {
          throw new Error("Expected sourceComponent to be present.");
        }
        destinationSpace.tree = insertNodeInTree(
          destinationSpace.tree!,
          removeResult.removedTreeNode,
          destinationComponentSlug || null,
          index
        );

        const spaces = new Map(state.spaces);
        return {
          ...state,
          spaces,
          dirty: true
        };
      }

      let nextTree;
      if (!sourceSpace?.tree) throw new Error("Expected sourceSpace to be present.");
      try {
        // If it's a move within a subspace, the paths of the move includes ancestor spaces,
        // that part should be removed before moving the components within the subspace's tree
        const isRoot = state.rootSpaceSlug === sourceSpace.slug;
        let scopedSourcePath = sourcePath;
        let scopedDestinationPath = destinationPath;
        if (!isRoot) {
          scopedSourcePath = toSubSpacePath(
            sourcePath,
            sourceSpace.slug,
            componentToSpace
          );
          scopedDestinationPath = toSubSpacePath(
            destinationPath,
            destinationSpace.slug,
            componentToSpace
          );
        }
        nextTree = moveTreeNode(
          sourceSpace.tree,
          scopedSourcePath,
          scopedDestinationPath,
          index
        );
      } catch (err) {
        exceptionReporting.reportException(err, { extra: action.payload });
        return state;
      }
      sourceSpace.tree = nextTree;

      return {
        ...state,
        dirty: true,
        spaces: new Map(state.spaces).set(sourceSpace.slug, sourceSpace)
      };
    }

    case "CREATE_SUB_SPACE": {
      const { sourceComponentSlug, name = "" } = action.payload;
      const sourceSpace = cloneDeep(getSlugsSpace(sourceComponentSlug));
      if (!sourceSpace) throw new Error("Expected sourceSpace.");
      const removeResult = removeComponent(sourceSpace, sourceComponentSlug);
      const { removedTreeNode } = removeResult;
      let sourceTree = removeResult.treeNode;

      const subSpace = createSpace(
        {},
        new Set(Array.from(state.spaces.values()).map(s => s.slug))
      );
      const subSpaceTreeRoot = {
        slug: "root",
        container: null,
        type: null,
        treeNodes: [removedTreeNode]
      };
      removedTreeNode.container = subSpaceTreeRoot;
      const sourceComponents = new Map(sourceSpace.components);
      const sourceLayouts = new Map(sourceSpace.elementLayouts);
      const subSpaceComponents = new Map<string, ComponentConfigState>(
        removeResult.componentConfigs.map(c => [c.draftComponent.slug, c])
      );
      const subSpaceLayouts = removeResult.elementLayouts;
      const subSpaceComponentLayout =
        removeResult.elementLayouts.get(sourceComponentSlug)!;
      const replacementLayout = new ElementLayout({
        width: "100%",
        height: "100%"
      });
      removeResult.elementLayouts.set(sourceComponentSlug, replacementLayout);

      // Use the elementLayout of the converted component as the subSpaceComponent's
      const subSpaceComponent = createInitialComponentConfigState(
        "SUB_SPACE",
        selectAllSlugs(state),
        state.packages,
        {
          name,
          properties: {
            sub_space: subSpace.slug
          }
        }
        // sourceSpace.slug !== state.rootSpaceSlug ? sourceSpace.slug : undefined
      );
      sourceComponents.set(subSpaceComponent.draftComponent.slug, subSpaceComponent);
      sourceLayouts.set(subSpaceComponent.draftComponent.slug, subSpaceComponentLayout);

      // Insert the subSpaceComponent in the tree at the same place as the converted component
      sourceTree = insertComponentInTree(
        sourceTree,
        subSpaceComponent.draftComponent,
        removeResult.removedParent,
        removeResult.removedIndex
      )!;

      const spaces = new Map(state.spaces);
      spaces.set(subSpace.slug, {
        ...subSpace,
        name: name || "sub space",
        tree: subSpaceTreeRoot,
        components: subSpaceComponents,
        elementLayouts: subSpaceLayouts,
        branchCount: 0,
        scmIsCurrent: false,
        scmStatus: null
      });
      spaces.set(sourceSpace.slug, {
        ...sourceSpace,
        tree: sourceTree,
        components: sourceComponents,
        elementLayouts: sourceLayouts
      });
      return {
        ...state,
        spaces,
        dirty: true
      };
    }

    case "ADD_SPACE_PARAM": {
      const { spaceSlug, param } = action.payload;
      const space = state.spaces.get(spaceSlug);
      if (!space) throw new Error("Expected space.");
      space.parameters = [...space.parameters, param];
      const spaces = state.spaces.set(spaceSlug, { ...space });

      // Add a placer InputParam to each instance SubSpaceComponent
      spaces.forEach(s => {
        s.components.forEach(c => {
          if (
            c.draftComponent.type === "SUB_SPACE" &&
            c.draftComponent.properties.sub_space === spaceSlug
          ) {
            s.components.set(c.draftComponent.slug, {
              ...c,
              draftComponent: {
                ...c.draftComponent,
                properties: {
                  ...c.draftComponent.properties,
                  input_parameters: [
                    ...c.draftComponent.properties.input_parameters,
                    {
                      name: param.name,
                      binding: ""
                    }
                  ]
                }
              }
            });
            spaces.set(s.slug, s);
          }
        });
      });

      return {
        ...state,
        spaces,
        dirty: true
      };
    }

    case "REMOVE_SPACE_PARAM": {
      const { spaceSlug, index } = action.payload;
      const space = state.spaces.get(spaceSlug);
      if (!space) throw new Error("Expected space.");
      space.parameters = space.parameters.filter((_, i) => i !== index);
      const spaces = state.spaces.set(spaceSlug, { ...space });

      // Add a placer InputParam to each instance SubSpaceComponent
      spaces.forEach(s => {
        s.components.forEach(c => {
          if (
            c.draftComponent.type === "SUB_SPACE" &&
            c.draftComponent.properties.sub_space === spaceSlug
          ) {
            s.components.set(c.draftComponent.slug, {
              ...c,
              draftComponent: {
                ...c.draftComponent,
                properties: {
                  ...c.draftComponent.properties,
                  input_parameters: (
                    c.draftComponent.properties.input_parameters as []
                  ).filter((_, i) => i !== index)
                }
              }
            });
            spaces.set(s.slug, s);
          }
        });
      });

      return {
        ...state,
        spaces,
        dirty: true
      };
    }

    case "UPDATE_SPACE_PARAM": {
      const { spaceSlug, index, param } = action.payload;
      const space = state.spaces.get(spaceSlug);
      if (!space) throw new Error("Expected space.");
      const oldName = space.parameters[index].name;
      const spaceParamsCopy = [...space.parameters];
      spaceParamsCopy[index] = {
        ...spaceParamsCopy[index],
        ...param
      };
      space.parameters = spaceParamsCopy;
      const spaces = state.spaces.set(spaceSlug, { ...space });

      const subSpaceComponentSlugs = new Set();
      spaces.forEach(s => {
        s.components.forEach(c => {
          if (
            c.draftComponent.type === "SUB_SPACE" &&
            c.draftComponent.properties.sub_space === spaceSlug
          ) {
            subSpaceComponentSlugs.add(c.draftComponent.slug);
          }
        });
      });

      if (param.name !== undefined) {
        spaces.forEach(s => {
          s.components.forEach(c => {
            // Add or update InputParam for each SubSpaceComponent of the update space
            if (
              c.draftComponent.type === "SUB_SPACE" &&
              c.draftComponent.properties.sub_space === spaceSlug
            ) {
              // Make sure all params are present and correctly named
              const nextInputParams = space.parameters.map((p, i) => {
                const currentIp = c.draftComponent.properties.input_parameters[i];
                return {
                  name: i === index ? param.name : p.name,
                  binding: currentIp ? currentIp.binding : undefined
                };
              });
              s.components.set(c.draftComponent.slug, {
                ...c,
                draftComponent: {
                  ...c.draftComponent,
                  properties: {
                    ...c.draftComponent.properties,
                    input_parameters: nextInputParams
                  }
                }
              });
              spaces.set(s.slug, s);
            }

            // Check component bindings for any that match `${subSpaceComponent.slug}.params.${oldName}` and rewrite
            // to new name
            const bindings = findInputBindings(
              c.draftComponent.properties,
              new Set(),
              true
            );
            const rewriteMap = new Map();
            bindings.forEach(b => {
              subSpaceComponentSlugs.forEach(slug => {
                if (b.includes(`${slug}.params.${oldName}`)) {
                  rewriteMap.set(
                    b,
                    b.replace(
                      `${slug}.params.${oldName}`,
                      `${slug}.params.${param.name}`
                    )
                  );
                }
              });
            });
            if (rewriteMap.size) {
              const updatedComponent = { ...c.draftComponent };
              updatedComponent.properties = rewritePaths(
                c.draftComponent.properties,
                rewriteMap
              );
              s.components.set(updatedComponent.slug, {
                ...c,
                draftComponent: updatedComponent
              });
            }
          });
        });

        // For the space whose param got updated rewrite any binding paths using the old name
        space.components.forEach(c => {
          const rewriteMap = new Map();
          const bindings = findInputBindings(c, new Set(), true);
          bindings.forEach(b => {
            if (b.startsWith(`params.${oldName}`)) {
              rewriteMap.set(b, b.replace(`params.${oldName}`, `params.${param.name}`));
            }
          });
          if (rewriteMap.size) {
            const updatedComponent = { ...c.draftComponent };
            updatedComponent.properties = rewritePaths(
              c.draftComponent.properties,
              rewriteMap
            );
            space.components.set(updatedComponent.slug, {
              ...c,
              draftComponent: updatedComponent
            });
          }
        });
      }

      return {
        ...state,
        spaces,
        dirty: true
      };
    }

    case "UPDATE_SCM_VERSION": {
      const { spaceSlug, scmVersion } = action.payload;
      const spaces = state.spaces;
      const space = spaces.get(spaceSlug);
      if (!space) throw new Error("space not found: " + spaceSlug);
      spaces.set(spaceSlug, { ...space, scmVersion });
      return { ...state, spaces };
    }

    case "SUBMIT":
      return { ...state, submitted: true };

    case "HANDLE_SAVE": {
      return { ...state, dirty: false };
    }

    case "SET_API_ERRORS":
      const { errors } = action.payload;

      return {
        ...state,
        apiErrors: errors
      };

    case "UPDATE_UI_STATE_OVERRIDES": {
      const { uiStateOverrides } = action.payload;

      return {
        ...state,
        uiStateOverrides
      };
    }

    default:
      return state;
  }
}
