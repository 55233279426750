import React from "react";

import { Button, Icon } from "antd";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import usePaths from "../../../../common/hooks/usePaths";
import { useStableSpaceContext } from "../../../SpaceRoot/SpaceContext";
import { useBranchContext } from "../../../SpaceRoot/SpaceContext/BranchContext";
import { useSpaceConfigContext } from "../../SpaceConfigContext";
import { MutationType } from "../../SpaceConfigContext/useSpaceConfig";

export const SaveLinkButton = styled(Button)`
  &.ant-btn {
    height: 30px;
    line-height: 30px;
    padding: 0;
    font-size: 13px;
    border: 0;
    color: ${props => props.theme.inputColor};

    &:hover,
    &:focus,
    &:active {
      color: ${props => props.theme.inputColor};
    }
  }
`;

export const LoadingIcon = styled(Icon)`
  font-size: 20px;
  padding: 0 ${props => props.theme.spacermd};
  color: ${props => props.theme.inputColor};
`;

export default function SaveButton() {
  const navigate = useNavigate();
  const { state, save, mutationLoading } = useSpaceConfigContext();
  const { spaceSlug } = useStableSpaceContext();
  const { getSpace } = usePaths();
  const { branch } = useBranchContext();
  const { dirty } = state;

  if (mutationLoading === MutationType.SAVE) {
    return <LoadingIcon type="loading" />;
  }

  return (
    <SaveLinkButton
      disabled={mutationLoading === MutationType.DESTROY}
      type="link"
      onClick={dirty ? () => save() : () => navigate(getSpace(spaceSlug, { branch }))}
      data-test="space-config-save"
    >
      {dirty ? "Save" : "Done"}
    </SaveLinkButton>
  );
}
