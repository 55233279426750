import React from "react";

import { Link } from "react-router-dom";

import { ScmStatus, ScmSyncStatus, StableSpace } from "../../../../../types";
import usePaths from "../../../../common/hooks/usePaths";
import Message from "../../../../common/Message";
import { assertNever } from "../../../../util/assertNever";
import { SyncModal } from "../../modals";
import { useSpaceContext, useStableSpaceContext } from "../../SpaceContext";
import { useBranchContext } from "../../SpaceContext/BranchContext";

import * as styled from "./styledComponents";

const Banner = ({
  children,
  isError
}: {
  children?: React.ReactNode;
  isError?: boolean;
}) => (
  <styled.BannerContainer isError={isError}>
    <styled.BannerContent>{children}</styled.BannerContent>
  </styled.BannerContainer>
);

const StatusBanner = ({
  space,
  onStartSync
}: {
  space: StableSpace;
  onStartSync: () => void;
}) => {
  const { editMode } = useStableSpaceContext();
  const { getEditSpace } = usePaths();
  const { branch } = useBranchContext();

  switch (space.scmSyncStatus) {
    case null:
    case ScmSyncStatus.CURRENT:
    case ScmSyncStatus.UNCOMMITTED_CHANGES:
      return null;
    case ScmSyncStatus.NOT_CURRENT:
      return editMode ? (
        <Banner>
          <span>Newer version available</span>
          <styled.SyncLink onClick={onStartSync}>Sync now</styled.SyncLink>
        </Banner>
      ) : (
        <Banner>
          <span>
            Newer version available. To sync and publish changes,{" "}
            <Link to={getEditSpace(space.slug, { branch })}>open edit mode</Link>.
          </span>
        </Banner>
      );
    case ScmSyncStatus.REMOTE_BRANCH_NOT_FOUND:
      return (
        <Banner isError>
          Space was not loaded because the branch could not be found.
        </Banner>
      );
    case ScmSyncStatus.REMOTE_ERROR:
      return (
        <Banner isError>
          Connection error with GitHub. Try refreshing in a few minutes.
        </Banner>
      );
    case ScmSyncStatus.REMOTE_SPACE_NOT_FOUND:
      return (
        <Banner isError>
          Space was not loaded because the space was not found on this branch.
        </Banner>
      );
    default:
      return assertNever(space.scmSyncStatus);
  }
};

export default function ScmBanner() {
  const { space } = useSpaceContext();
  const { branch } = useBranchContext();
  const [syncModalVisible, setSyncModalVisible] = React.useState(false);

  if (space.scmStatus !== ScmStatus.CONNECTED || !branch) return null;

  return (
    <>
      <StatusBanner space={space} onStartSync={() => setSyncModalVisible(true)} />
      {syncModalVisible && (
        <SyncModal
          spaceId={space.id}
          branch={branch}
          onCancel={() => setSyncModalVisible(false)}
          onSuccess={() => {
            setSyncModalVisible(false);
            Message.success("Sync successful! This page will automatically refresh.");
            setTimeout(() => window.location.reload(), 500);
          }}
          onError={() => {
            setSyncModalVisible(false);
            Message.error(
              "Sync failed. Please try again. Contact support if the problem persists."
            );
          }}
        />
      )}
    </>
  );
}
