import { stringify, parse } from "querystring";

import { useCallback } from "react";

import { some } from "lodash";
import { generatePath, matchPath, useLocation, useNavigate } from "react-router-dom";

import {
  createResourceCursor,
  createCursorFilters,
  Routes,
  encodeCursor,
  encode,
  decode
} from "../../../../constants";
import {
  RecordIdentifier,
  RecordView,
  FunctionDescriptor,
  CursorType
} from "../../../../types";
import { SpaceConfigMenuPathItem } from "../../../spaces/SpaceConfig";
import { useBranchContext } from "../../../spaces/SpaceRoot/SpaceContext/BranchContext";
import { useEnvironmentContext } from "../../contexts/EnvironmentContext";

const ROUTES_WITH_ENVIRONMENT_IN_HASH = [Routes.SPACE_NEW, Routes.SPACE_EDIT];

export default function usePaths() {
  const navigate = useNavigate();
  const location = useLocation();
  const hashParams = location.hash.substring(location.hash.indexOf("#") + 1);
  const { defaultBranch } = useBranchContext();
  const { getCurrentEnvironment } = useEnvironmentContext();

  const getValidCurrentEnvironment = useCallback(() => {
    const e = getCurrentEnvironment();
    if (!e.slug) {
      return { id: "unknown", slug: "default" };
    }
    return e;
  }, [getCurrentEnvironment]);

  const ensureEnvironmentSlug = useCallback(() => {
    return getCurrentEnvironment().slug || "default";
  }, [getCurrentEnvironment]);

  // For usage with routes that rely on hashParams
  const getParamsWithEnvironment = useCallback(() => {
    const currentParams = parse(hashParams);
    currentParams.environment = getValidCurrentEnvironment().slug;
    return currentParams;
  }, [getValidCurrentEnvironment, hashParams]);

  const updateHashParams = (params: Record<string, any>): string => {
    const qs = location.hash.substring(location.hash.indexOf("#") + 1);
    const currParams = parse(qs);
    const nextParams = Object.entries(params).reduce<Record<string, any>>(
      (acc, [key, val]) => {
        if (val) {
          acc[key] = encode(JSON.stringify(val))!;
        } else {
          delete acc[key];
        }
        return acc;
      },
      currParams
    );
    return `#${stringify(nextParams)}`;
  };

  const getDashboard = useCallback(() => generatePath(Routes.DASHBOARD), []);

  const getSpace = useCallback(
    (
      spaceSlug: string,
      { resourceCursor, branch }: { resourceCursor?: string; branch?: string } = {}
    ) => {
      let basePath = resourceCursor
        ? generatePath(Routes.SPACE_WITH_RESOURCE_CURSOR, {
            spaceSlug,
            environment: getValidCurrentEnvironment().slug,
            resourceCursor: encodeURIComponent(resourceCursor)
          })
        : generatePath(Routes.SPACE, {
            spaceSlug,
            environment: getValidCurrentEnvironment().slug
          });
      if (branch && branch !== defaultBranch) {
        basePath = `${basePath}?__branch=${branch}`;
      }
      return basePath;
    },
    [getValidCurrentEnvironment, defaultBranch]
  );

  const getEditSpace = (
    spaceSlug: string,
    { menu, branch }: { menu?: SpaceConfigMenuPathItem[]; branch?: string } = {}
  ) => {
    const environment = ensureEnvironmentSlug();
    let basePath =
      spaceSlug === ""
        ? generatePath(Routes.SPACE_NEW, {
            environment
          })
        : generatePath(Routes.SPACE_EDIT, {
            spaceSlug,
            environment
          });

    if (branch && branch !== defaultBranch) {
      basePath = `${basePath}?__branch=${branch}`;
    }
    if (menu !== undefined) {
      basePath = basePath + updateHashParams({ menu });
    }
    return basePath;
  };

  const getSpaceFunctionConfig = (
    spaceSlug: string,
    props: { dataSourceId?: string; functionId: string } | { pendingFunctionId: string }
  ) =>
    getEditSpace(spaceSlug, {
      menu: [
        { type: "APP_DATA" },
        {
          type: "FUNCTION_CONFIG",
          props
        }
      ]
    });

  return {
    changeEnvironment: (newSlug: string) => {
      if (some(ROUTES_WITH_ENVIRONMENT_IN_HASH, r => matchPath(location.pathname, r))) {
        const updatedParams = {
          ...getParamsWithEnvironment(),
          environment: newSlug
        };
        navigate(`#${stringify(updatedParams)}`, { replace: true });
        return;
      }
      navigate(
        location.pathname.replace(
          `/${getValidCurrentEnvironment().slug}/`,
          `/${newSlug}/`
        ),
        { replace: true }
      );
    },
    getAuthSettings: () => generatePath(Routes.SETTINGS_AUTH_PROVIDERS),
    getDataSourceSettings: () => generatePath(Routes.SETTINGS_DATA_SOURCES),
    getUsersSettings: () => generatePath(Routes.SETTINGS_USERS),
    getSecuritySettings: () => generatePath(Routes.SETTINGS_SECURITY),
    getScmSettings: () => generatePath(Routes.SETTINGS_SCM),
    getProductSettings: () => generatePath(Routes.SETTINGS_PLAN),
    getBillingSettings: () => generatePath(Routes.SETTINGS_BILLING),
    getUserSettings: (userId: string) =>
      generatePath(Routes.SETTINGS_USER_DETAILS, { userId }),
    getAuthSettingsDetails: (authProviderId: string) =>
      generatePath(Routes.SETTINGS_AUTH_PROVIDERS_DETAILS, { authProviderId }),
    getDashboard,
    getRecordFromCursor: (cursor: string, view: string) =>
      generatePath(Routes.RECORD, {
        cursor: encodeURIComponent(cursor),
        view,
        environment: getValidCurrentEnvironment().slug
      }),
    getRecord: (
      record: RecordIdentifier,
      view: RecordView = "detail",
      contextRecord?: RecordIdentifier
    ) => {
      const cursor = encodeURIComponent(
        createResourceCursor(record.slug, createCursorFilters(record.key))
      );
      const contextCursor = contextRecord
        ? encodeURIComponent(
            createResourceCursor(
              contextRecord.slug,
              createCursorFilters(contextRecord.key)
            )
          )
        : undefined;

      return generatePath(contextCursor ? Routes.RECORD_WITH_CONTEXT : Routes.RECORD, {
        environment: getValidCurrentEnvironment().slug,
        cursor,
        view,
        contextCursor
      });
    },
    getFunctionRecord: (
      functionRecordDescriptor: FunctionDescriptor,
      view: RecordView = "detail",
      contextDescriptor?: FunctionDescriptor
    ) => {
      const cursor = encodeURIComponent(
        encodeCursor({
          ...functionRecordDescriptor,
          type: CursorType.FUNCTION
        })
      );
      const contextCursor = contextDescriptor
        ? encodeURIComponent(
            encodeCursor({
              ...contextDescriptor,
              type: CursorType.FUNCTION
            })
          )
        : undefined;
      return generatePath(contextCursor ? Routes.RECORD_WITH_CONTEXT : Routes.RECORD, {
        environment: getValidCurrentEnvironment().slug,
        cursor,
        view,
        contextCursor
      });
    },
    getSetupDataSource: () => generatePath(Routes.SETUP_DATA_SOURCE),
    getSelectDataSource: (dataSourceProviderId: string) =>
      generatePath(Routes.SELECT_DATA_SOURCE, { dataSourceProviderId }),
    getSettingsRolesAndPermissions: () =>
      generatePath(Routes.SETTINGS_ROLES_AND_PERMISSIONS),
    getSettingsViewRole: (roleId: string) =>
      generatePath(Routes.SETTINGS_VIEW_ROLE, { roleId }),
    getNewSpace: () => {
      const environment = getValidCurrentEnvironment().slug;
      return generatePath(Routes.SPACE_NEW, { environment });
    },
    getEditSpace,
    getSpaceFunctionConfig,
    openSpaceFunctionConfig: (
      spaceSlug: string,
      props: { dataSourceId: string; functionId: string }
    ) => {
      navigate(getSpaceFunctionConfig(spaceSlug, props));
    },
    getAuditSpace: (spaceSlug: string) =>
      generatePath(Routes.SPACE_ACTIVITY, {
        spaceSlug,
        environment: getValidCurrentEnvironment().slug
      }),
    getSpacesHome: (environment?: string) =>
      generatePath(Routes.SPACES_HOME, {
        environment: environment || getValidCurrentEnvironment().slug
      }),
    getSpace,
    getSpaceExportPath: (slug: string, versionUuid: string) =>
      generatePath("/api/spaces/:slug/versions/:versionUuid.json", {
        slug,
        versionUuid
      }),
    getNotFound: () => generatePath(Routes.NOT_FOUND),
    updateHashParams,
    getEncodedHashParam: (name: string): string | undefined => {
      const { hash } = location;
      const parsed = parse(hash.substring(hash.indexOf("#") + 1));
      return parsed[name] ? decode(parsed[name].toString()) : undefined;
    },

    ////////////////////// Start Queues //////////////////////
    queuesapp_getHome: () =>
      generatePath("/environments/:environment/queues", {
        environment: getValidCurrentEnvironment().slug
      }),
    queuesapp_getNewQueue: () =>
      generatePath("/environments/:environment/queues/new", {
        environment: getValidCurrentEnvironment().slug
      }),
    queuesapp_getEditQueue: (slug: string) =>
      generatePath("/environments/:environment/queues/:slug/edit", {
        environment: getValidCurrentEnvironment().slug,
        slug
      }),
    queuesapp_getQueue: (slug: string) =>
      generatePath("/environments/:environment/queues/:slug", {
        environment: getValidCurrentEnvironment().slug,
        slug: slug
      }),
    queuesapp_getQueueAnalytics: (slug: string) =>
      generatePath("/environments/:environment/queues/:slug/analytics", {
        environment: getValidCurrentEnvironment().slug,
        slug: slug
      }),
    ////////////////////// End Queues //////////////////////

    ////////////////////// Start Automations //////////////////////
    automations_getHome: () =>
      generatePath("/environments/:environment/automations", {
        environment: getValidCurrentEnvironment().slug
      }),
    automationsapp_getNewAutomation: () =>
      generatePath("/environments/:environment/automations/new", {
        environment: getValidCurrentEnvironment().slug
      }),
    automationsapp_getEditAutomation: (slug: string) =>
      generatePath("/environments/:environment/automations/:slug/edit", {
        environment: getValidCurrentEnvironment().slug,
        slug
      })
    ////////////////////// End Automations //////////////////////
  };
}
