import { useEffect } from "react";

import { useLocation, useSearchParams } from "react-router-dom";

/**
 * useSetParameterEffect monitors for hash parameters in the format of
 * `__set_varname=val`.  When a variable is set that matches this format
 * and editing is `false`, then a query string parameter is set as
 * `varname=val`.
 *
 * @param editing denotes whether or not the space is in edit mode.
 */
export default function useSetParameterEffect(editing: boolean) {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (editing) return;

    const hashParams = new URLSearchParams(location.hash.substring(1));
    const paramsToSet = Array.from(hashParams.entries()).reduce((acc, [k, v]) => {
      if (k.startsWith("__set_")) {
        acc.set(k.substring(6), v);
      }
      return acc;
    }, new URLSearchParams());

    if (paramsToSet.toString()) {
      setSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        ...Object.fromEntries(paramsToSet.entries())
      });
    }
  }, [searchParams, location.hash, setSearchParams, editing]);
}
