import React from "react";

import { Icon as AntIcon } from "antd";
import classNames from "classnames";
import styled from "styled-components";

import IntegrationLogo from "../../../../common/IntegrationLogo";

const Root = styled.div`
  width: 48px;
  height: 48px;
  border-radius: ${props => props.theme.containerPrimaryBorderRadius};
  overflow: hidden;
`;

const IconRoot = styled(Root)`
  background-color: ${props => props.theme.iconBackground};
`;

const EmptyRoot = styled(Root)`
  color: white;
  background-color: ${props => props.theme.newContainerPrimaryTint};
  padding: ${props => props.theme.spacersmd};
`;

const Icon = styled(AntIcon)`
  font-size: 26px;
`;

const integrationsWithBackgroundColor = new Set(["http", "pipelines", "queues"]);

interface Props {
  integration: string | undefined;
  className?: string;
}

const ActionIcon = (props: Props) => {
  if (!props.integration) {
    return (
      <EmptyRoot className={classNames(props.className, "empty")}>
        <Icon type="setting" theme="filled" />
      </EmptyRoot>
    );
  }

  if (integrationsWithBackgroundColor.has(props.integration)) {
    // Some integration icons provide their own background color
    // If we set a background color, it bleeds through a little, so we don't set one.
    return (
      <Root className={props.className}>
        <IntegrationLogo name={props.integration} />
      </Root>
    );
  }

  return (
    <IconRoot className={props.className}>
      <IntegrationLogo name={props.integration} />
    </IconRoot>
  );
};

export default ActionIcon;
