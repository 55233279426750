import React from "react";

import { ConfigValidationError } from "../../../../../../../types";
import { SpaceConfigAction } from "../../../../../types";
import { ImageConfigState } from "../../../SpaceImage/ImageConfig/reducer/reducer";
import ColumnOrder from "../../ColumnOrder";
import { ConfigSection } from "../../ConfigPanel";
import FilterListManagerSection from "../../FilterListManager";
import ParametersConfigSection from "../../ParametersManager";
import CloudStorageConfig from "../../useCloudStorage";
import { hasSource } from "../../util";
import VisibilityRulesManagerSection from "../../VisibilityRulesManager";

interface Props {
  state: ImageConfigState;
  dispatch: React.Dispatch<SpaceConfigAction>;
  errors: ConfigValidationError[]; // only used for config panel UI
}

export default function AdvancedConfig({ state, dispatch, errors }: Props) {
  const { draftComponent, filtersOptions, sortByOptions } = state;

  return (
    <>
      {hasSource(draftComponent) && (
        <>
          <ParametersConfigSection title="Parameters" />
          <FilterListManagerSection title="Filters" filtersOptions={filtersOptions} />
          <ConfigSection title="Sorting">
            <ColumnOrder sortByOptions={sortByOptions} />
          </ConfigSection>
        </>
      )}
      <CloudStorageConfig state={state} dispatch={dispatch} errors={errors} />
      <VisibilityRulesManagerSection />
    </>
  );
}
