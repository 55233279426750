import { cloneDeep, flow, partial } from "lodash";

import { SpaceComponentObject, SourceType } from "../../../../../../types";
import commonComponentReducer from "../../../../SpaceConfig/SpaceConfigContext/useSpaceConfig/reducer/componentReducer";
import {
  SpaceConfigAction,
  BaseComponentConfigState,
  ComponentConfigState
} from "../../../../types";
import filterListManagerReducer, {
  INITIAL_STATE as FILTER_LIST_MANAGER_INITIAL_STATE,
  FilterListManagerState,
  getInitialFilterState
} from "../../common/FilterListManager/reducer/reducer";
import parameterConfigReducer, {
  getInitialParameterState,
  INITIAL_STATE as PARAMETER_CONFIG_INITIAL_STATE,
  ParameterConfigState
} from "../../common/ParametersManager/reducer/reducer";
import viewDataConfigReducer, {
  INITIAL_STATE as VIEW_DATA_CONFIG_INITIAL_STATE,
  ViewConfigState
} from "../../common/useViewConfig/reducer";
import { functionTitleToComponentName } from "../../common/util";
import { SpaceTableComponent } from "../types";

export interface TableConfigState
  extends BaseComponentConfigState<SpaceTableComponent>,
    ViewConfigState,
    FilterListManagerState,
    ParameterConfigState {
  type: "TABLE";
}

export const INITIAL_STATE = {
  type: "TABLE" as const,
  draftComponent: {} as SpaceTableComponent,
  ...VIEW_DATA_CONFIG_INITIAL_STATE,
  ...FILTER_LIST_MANAGER_INITIAL_STATE,
  ...PARAMETER_CONFIG_INITIAL_STATE
};

function isTableConfigState(state: ComponentConfigState): state is TableConfigState {
  return state.type === "TABLE";
}

export function ensureTableConfigState(state: ComponentConfigState): TableConfigState {
  if (isTableConfigState(state)) return state;

  throw new Error("Expected table config state.");
}

export function ensureSpaceTableComponent(
  component: SpaceComponentObject
): SpaceTableComponent {
  if (
    Array.isArray(component.properties.columns) &&
    Array.isArray(component.properties.filters) &&
    Array.isArray(component.properties.order) &&
    Array.isArray(component.properties.input_parameters) &&
    Array.isArray(component.properties.effects)
  ) {
    return component;
  }

  return {
    ...component,
    sourceType: component.sourceType || SourceType.VIEW,
    properties: {
      ...component.properties,
      columns: component.properties.columns || [],
      filters: component.properties.filters || [],
      order: component.properties.order || [],
      input_parameters: component.properties.input_parameters || [],
      effects: component.properties.effects || [],
      is_header_enabled: component.properties.is_header_enabled ?? true,
      is_search_enabled: component.properties.is_search_enabled ?? true,
      is_csv_export_enabled: component.properties.is_csv_export_enabled ?? false,
      is_export_hidden_columns_enabled:
        component.properties.is_export_hidden_columns_enabled ?? false
    }
  };
}

export function makeInitialState(
  draftComponent: SpaceComponentObject
): TableConfigState {
  return {
    ...INITIAL_STATE,
    draftComponent: ensureSpaceTableComponent(cloneDeep(draftComponent)),
    ...getInitialFilterState(draftComponent),
    ...getInitialParameterState(draftComponent)
  };
}

function reducer(state: TableConfigState, action: SpaceConfigAction): TableConfigState {
  switch (action.type) {
    case "LOAD_FUNCTION": {
      if (state.draftComponent.name) return state;
      const draftState = cloneDeep(state);
      const humanized = functionTitleToComponentName(action.payload.title);
      draftState.draftComponent.name = `${humanized} table`;
      draftState.draftComponent.properties.title = `${humanized} table`;
      return draftState;
    }

    case "CHANGE_SOURCE": {
      const { sourceType } = action.payload;
      if (sourceType !== SourceType.BINDING) return state;
      const draftState = cloneDeep(state);
      draftState.draftComponent.properties.is_csv_export_enabled = false;
      draftState.draftComponent.properties.is_export_hidden_columns_enabled = false;
      return draftState;
    }

    case "UPDATE_COMPONENT_STYLE": {
      const { styles } = action.payload;

      const draftState = cloneDeep(state);
      draftState.draftComponent.properties.styles = styles;

      return draftState;
    }

    default:
      return state;
  }
}

export default (state: TableConfigState, action: SpaceConfigAction) =>
  flow([
    commonComponentReducer,
    partial(viewDataConfigReducer, partial.placeholder, action),
    partial(parameterConfigReducer, partial.placeholder, action),
    partial(filterListManagerReducer, partial.placeholder, action),
    partial(reducer, partial.placeholder, action)
  ])(state, action);
