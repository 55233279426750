import { isEqual } from "lodash";
import { UseTableHooks, Column } from "react-table";

import { SpaceComponentObject } from "../../../types";
import { AttributeColumn } from "../../spaces/SpaceRoot/SpaceComponent/common/ColumnListManager";

export default function useSyncComponentDefs(hooks: UseTableHooks<object>) {
  hooks.columnsDeps.push((deps, { instance }) => {
    return [...deps, (instance as any).state.columnDefs];
  });

  hooks.columns.push((columns: Column[], { instance }) => {
    // If columns are out of sync with state.columnDefs they should be updated
    // and synced on next render.
    if (
      !columns.every(
        (c, idx) => c?.accessor === (instance as any).state.columnDefs[idx]?.accessor
      )
    ) {
      return columns;
    }

    return columns.map((_c, idx) => {
      const c = _c as unknown as Column & {
        component?: SpaceComponentObject;
        options?: AttributeColumn;
      };
      const lastCol = (instance as any).state.columnDefs[idx];
      return c.component || !isEqual(c.options, lastCol.options)
        ? { ...lastCol, minWidth: c.minWidth }
        : c;
    });
  });
}
