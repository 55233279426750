import React, { useState } from "react";

import { useMutation } from "@apollo/react-hooks";
import { Icon, Menu, Dropdown, Table, Modal } from "antd";
import _MenuItem from "antd/lib/menu/MenuItem";
import * as moment from "moment";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { UserNode } from "../../../types";
import Button from "../../common/Button";
import { useEnvironmentsQuery } from "../../common/contexts/EnvironmentContext/EnvironmentContext";
import usePaths from "../../common/hooks/usePaths";
import Message from "../../common/Message";
import { AuthorizationFlow } from "../../spaces/SpaceRoot/AuthorizationContext/AuthorizationContext";
import { LOGOUT_MUTATION } from "../../spaces/SpaceRoot/AuthorizationContext/queries";

import { AuthorizationFlowNode } from "./types";

interface AuthProviderTableProps {
  authProviders: AuthorizationFlowNode[];
  isLoading: boolean;
  refetchProviders: () => void;
  enableRowClick: boolean;
  hiddenColumns?: string[];
}

interface AuthProviderActionButtonProps {
  refetchProviders: () => void;
  flow: AuthorizationFlowNode;
  onLoginClick?: (environmentId: string) => void;
}

class MenuItem extends _MenuItem {
  render() {
    return <tr>{this.props.children}</tr>;
  }
}

const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const EmptyText = styled.p`
  font-style: italic;
`;

const PaddedTableData = styled.td`
  padding-left: 10px;
  padding-right: 10px;
`;

const LogoutLink = styled.a`
  color: ${props => props.theme.errorColor};
  &:hover {
    color: ${props => props.theme.darkErrorColor};
  }
`;

const Disabled = styled.span`
  color: ${props => props.theme.surfaceDisabled};
`;

const AuthProviderActionButton = (props: AuthProviderActionButtonProps) => {
  const allEnvironments = useEnvironmentsQuery({}).data?.allEnvironments;
  const loggedInEnvIds = props.flow.environmentsWithState.edges.map(
    ({ node }) => node.id
  );
  const [logout, { loading }] = useMutation<
    { ok: boolean },
    { id: string; environmentId: string }
  >(LOGOUT_MUTATION, {
    onCompleted: props.refetchProviders,
    onError: () => Message.error("Something went wrong. Please try again!")
  });
  const menu = (
    <Menu selectable={false}>
      {allEnvironments?.edges?.map(({ node }) => (
        <table key={node.id}>
          <tbody onClick={event => event.stopPropagation()}>
            <MenuItem key={node.id}>
              <>
                <PaddedTableData>
                  {loggedInEnvIds.includes(node.id) ? (
                    <Disabled>{node.name} Environment</Disabled>
                  ) : (
                    <a
                      href="/"
                      onClick={event => {
                        event.preventDefault();
                        event.stopPropagation();
                        props.onLoginClick!(node.id);
                      }}
                    >
                      {node.name} Environment
                    </a>
                  )}
                </PaddedTableData>
                {loggedInEnvIds.includes(node.id) && (
                  <PaddedTableData>
                    <LogoutLink
                      href="/"
                      onClick={event => {
                        event.preventDefault();
                        event.stopPropagation();
                        logout({
                          variables: { id: props.flow.id, environmentId: node.id }
                        });
                      }}
                    >
                      Log Out
                    </LogoutLink>
                  </PaddedTableData>
                )}
              </>
            </MenuItem>
          </tbody>
        </table>
      ))}
    </Menu>
  );
  const buttonTitle =
    loggedInEnvIds.length > 0
      ? "Logged in (" +
        loggedInEnvIds.length +
        "/" +
        allEnvironments?.edges.length +
        ")"
      : "Login";
  const style =
    loggedInEnvIds.length > 0 ? {} : { background: "black", color: "white" };
  return (
    <div onClick={event => event.stopPropagation()}>
      <Dropdown overlay={menu}>
        <Button loading={loading} style={style}>
          {buttonTitle} <Icon type="down" />
        </Button>
      </Dropdown>
    </div>
  );
};

const AuthProviderTable = (props: AuthProviderTableProps) => {
  const navigate = useNavigate();
  const { getAuthSettingsDetails } = usePaths();
  const [showModal, setShowModal] = useState(false);
  const [flow, setFlow] = useState<AuthorizationFlowNode>({} as AuthorizationFlowNode);
  const [environmentId, setEnvironmentId] = useState<string>();
  const columns = [
    {
      title: "Name",
      key: "name",
      width: "40%",
      dataIndex: "name"
    },
    {
      title: "Type",
      key: "description",
      width: "15%",
      dataIndex: "description"
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "created_at",
      width: "15%",
      render: (text: string) => moment.utc(text).local().fromNow()
    },
    {
      title: "Added By",
      key: "added_by",
      dataIndex: "createdBy",
      width: "15%",
      render: (user: UserNode | null) => user?.fullName || "Internal"
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "authorized",
      width: "15%",
      render: (_: any, flow: AuthorizationFlowNode) => (
        <AuthProviderActionButton
          refetchProviders={props.refetchProviders}
          flow={flow}
          onLoginClick={environmentId => {
            setShowModal(true);
            setFlow(flow);
            setEnvironmentId(environmentId);
          }}
        />
      )
    }
  ].filter(col => !props.hiddenColumns?.includes(col.dataIndex));

  const handleRowClick = (authProvider: AuthorizationFlowNode) => {
    navigate(getAuthSettingsDetails(authProvider.id));
  };

  const onRow = props.enableRowClick
    ? (record: AuthorizationFlowNode) => ({
        onClick: () => handleRowClick(record)
      })
    : undefined;

  return (
    <>
      <Table
        loading={props.isLoading}
        rowKey={provider => (provider as AuthorizationFlowNode).id}
        dataSource={props.authProviders}
        columns={columns}
        pagination={false}
        locale={{
          emptyText: (
            <EmptyContainer>
              <EmptyText>You haven't added any Authorization Providers yet.</EmptyText>
            </EmptyContainer>
          )
        }}
        onRow={onRow}
      />
      <Modal
        title="Authorization"
        visible={showModal}
        onCancel={() => {
          setShowModal(false);
        }}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        closable={false}
        footer={null}
      >
        {showModal && (
          <AuthorizationFlow
            flow={flow}
            environmentId={environmentId}
            onComplete={() => {
              setShowModal(false);
              props.refetchProviders();
            }}
            displayText={<h2>{flow.name} login</h2>}
          />
        )}
      </Modal>
    </>
  );
};

export default AuthProviderTable;
