import { useMutation } from "@apollo/react-hooks";
import { MutationHookOptions } from "@apollo/react-hooks/lib/types";
import gql from "graphql-tag";

import { ImporterSpaceNode } from "./types";

interface Data {
  spaceImport: {
    space: ImporterSpaceNode;
  };
}

interface Variables {
  spaceInput: unknown;
  spaceId?: string;
}

const QUERY = gql`
  mutation ImportSpace($spaceInput: GenericScalar!, $spaceId: ID) {
    spaceImport(spaceInput: $spaceInput, spaceId: $spaceId) {
      space {
        id
        slug
      }
    }
  }
`;

export default function useSpaceImport(options?: MutationHookOptions<Data, Variables>) {
  return useMutation<Data, Variables>(QUERY, options);
}
