import React, { useMemo } from "react";

import styled from "styled-components";

import { SpaceFunctionType } from "../../../../../types";
import { ButtonTypes as AntButtonTypes } from "../../../../common/Button/Button";
import { ErrorIcon } from "../../../../common/Icons";
import { WarningTooltip } from "../../../../common/ToolTips";
import { createSpaceFunction } from "../../../FunctionExecutor/FunctionExecutor";
import { useSpaceConfigContext } from "../../../SpaceConfig/SpaceConfigContext";
import { useStableSpaceContext } from "../../SpaceContext";
import Button from "../common/Button";
import { ButtonTypes } from "../common/Button/constants";
import useSubmittableEffects, {
  SubmittableEventType
} from "../common/effects/useSubmittableEffects/useSubmittableEffects";
import { isBoundParameterDenied } from "../common/PermissionFeedback";
import useFuncParams, { InputParameter } from "../common/useFuncParams";
import useFunctionAccess from "../common/useFunctionAccess";
import { useComponentContext } from "../contexts/ComponentContext";
import { useComponentStateContext } from "../contexts/ComponentStateContext";
import { Props } from "../SpaceComponent";

import BulkImportModal from "./BulkImportModal";

const Root = styled.div``;

const Container = styled.div`
  width: 100%;
  text-align: left;
`;

export const ConfigContainer = styled.div<{ buttonType?: AntButtonTypes }>`
  position: relative;
  cursor: default;

  ${props =>
    props.buttonType === "link"
      ? ""
      : `button: disabled {
      color: white;
      background-color: ${props.theme.primaryColor};
    }`}
`;

const ConfigEventMask = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const StyledErrorIcon = styled(ErrorIcon)`
  position: absolute;
  z-index: 1;
  top: 4px;
  left: 4px;
`;

export default function SpaceFunctionBulkImport({
  spaceComponent: {
    slug,
    sourceType,
    properties: { binding, button_text = "Import", input_parameters = [] }
  },
  spaceApi,
  spaceComponent
}: Props) {
  const func = useMemo(() => createSpaceFunction(spaceComponent), [spaceComponent]);

  const { shouldDisplayError } = useSpaceConfigContext();
  const { spaceId, editMode } = useStableSpaceContext();
  const { input } = useComponentStateContext();
  const access = useFunctionAccess(func);
  const [isModalVisible, setModalVisible] = React.useState<boolean>(false);

  const inputParams = useMemo(() => {
    return input_parameters.map((p: InputParameter) => ({
      ...p,
      // input_parameters should be treated as hidden since no way to view / edit
      hidden: true,
      // NOTE(ryan): If the binding refers to repeated item, replace with source binding.
      // This is necessary to ensure that validation (requiredness, blank) checks succeed.
      binding: p.binding?.startsWith("repeateditem") ? binding : p.binding
    }));
  }, [input_parameters, binding]);

  const { funcParams, hasRequiredValues, hasRequiredBindings, hasValidValues } =
    useFuncParams(func, inputParams, input);
  const { triggerEffects } = useSubmittableEffects(spaceApi);

  const functionParameters = func?.functionParameters || [];
  const requiredParameterIsDenied = functionParameters.some(
    fp => fp.required && !access.parameterAllowed(fp.name)
  );
  const boundParameterIsDenied = isBoundParameterDenied(
    input_parameters,
    func.functionParameters,
    funcParams
  );

  const onCancel = React.useCallback(() => {
    setModalVisible(false);
  }, [setModalVisible]);

  const onComplete = React.useCallback(() => {
    setModalVisible(false);
    triggerEffects({ type: SubmittableEventType.SUBMIT_SUCCESS });
  }, [setModalVisible, triggerEffects]);

  const onError = React.useCallback(
    error => {
      triggerEffects({ type: SubmittableEventType.SUBMIT_FAILURE, error });
    },
    [triggerEffects]
  );

  const {
    component: { layout }
  } = useComponentContext();

  const buttonType =
    spaceComponent.properties.button_type === ButtonTypes.Link ? "link" : "primary";

  if (editMode) {
    const maybeErrorIcon = shouldDisplayError(slug) ? <StyledErrorIcon /> : null;
    return (
      <Root className="spaceButtonRoot">
        <ConfigContainer buttonType={buttonType}>
          {maybeErrorIcon}
          <Button type={buttonType} disabled layout={layout}>
            {button_text}
          </Button>
          <ConfigEventMask />
        </ConfigContainer>
      </Root>
    );
  }

  if (
    !func ||
    [SpaceFunctionType.INVALID, SpaceFunctionType.VOID].includes(func.type)
  ) {
    return (
      <WarningTooltip
        message="This bulk import needs to be updated"
        description="The function associated with this bulk import cannot be found or is invalid. It's
possible that the name of the function was changed or that the
function was removed from your system."
        placement="left"
      >
        <span>
          <StyledErrorIcon />
          <Button type="primary" layout={layout} disabled>
            {button_text}
          </Button>
        </span>
      </WarningTooltip>
    );
  }

  if (
    requiredParameterIsDenied ||
    boundParameterIsDenied ||
    func.type === SpaceFunctionType.NOT_VISIBLE
  ) {
    return (
      <WarningTooltip
        message="This bulk import is disabled."
        description={
          requiredParameterIsDenied
            ? "You don't have access to one or more fields required to complete this action. Please contact your admin to update your permissions."
            : "At least one value required for this action could not be retrieved because you do not have permissions to access the data. Please contact your admin to update your permissions."
        }
        placement="left"
      >
        <span>
          <StyledErrorIcon />
          <Button type="primary" layout={layout} disabled>
            {button_text}
          </Button>
        </span>
      </WarningTooltip>
    );
  }

  return (
    <Root className="spaceButtonRoot">
      <Container>
        <Button
          type={buttonType}
          loading={false}
          layout={layout}
          disabled={!hasRequiredValues || !hasRequiredBindings || !hasValidValues}
          fixedWidthLoadingState
          onClick={() => setModalVisible(true)}
        >
          {button_text}
        </Button>
        <BulkImportModal
          spaceId={spaceId}
          function={func}
          binding={binding}
          sourceType={sourceType}
          onComplete={onComplete}
          onError={onError}
          onCancel={onCancel}
          inputParameters={input_parameters}
          visible={isModalVisible}
          inputState={input}
        />
      </Container>
    </Root>
  );
}
