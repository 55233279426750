import { SpaceComponentObject } from "../../../types";

import TreeTraverser from "./TreeTraverser";

export const injectSpaces = (
  components: SpaceComponentObject[],
  spaces: Map<string, SpaceComponentObject[]>
) => {
  // Make a temporary root node
  const root = {
    slug: "root",
    type: null,
    container: null,
    componentTreeNodes: components
  } as unknown as SpaceComponentObject;
  const traverser = new TreeTraverser(root, {
    getParent: node => node.container,
    getChildren: node => node.componentTreeNodes
  });
  traverser.updateNodes((node: SpaceComponentObject) => {
    if (node.type !== "SUB_SPACE" || node.componentTreeNodes.length) return node;
    const space = spaces.get(node.properties.sub_space);
    if (!space) throw new Error(`Could not find space ${node.properties.sub_space}`);
    node.componentTreeNodes =
      space.map(ctn => {
        ctn.container = node;
        return ctn;
      }) || [];
    return node;
  });

  // Ignore temporary root node
  return (traverser.tree as unknown as SpaceComponentObject).componentTreeNodes;
};
