import Icon from "../../../../../assets/spaceicons/cloud-uploader.svg";
import {
  SpaceComponentHostType,
  SpaceComponentPackage,
  SpaceComponentTags
} from "../../../../../types";
import { ElementLayout } from "../../../layout/util";
import reducer, {
  makeInitialState,
  SubmittableComponentConfigState
} from "../common/FunctionBackedPopover/reducer/reducer";

import Config, { ensureComponent } from "./Config";
import { errorSelector } from "./errorSelector";
import SpaceCloudUploader from "./SpaceCloudUploader";
import { SpaceCloudUploaderComponent, SpaceCloudUploaderProperties } from "./types";

const pkg: SpaceComponentPackage<
  SpaceCloudUploaderProperties,
  SpaceCloudUploaderComponent,
  SubmittableComponentConfigState
> = {
  type: "CLOUD_UPLOADER",
  displayName: "Cloud Uploader",
  Icon,
  allowedHosts: new Set<SpaceComponentHostType>([
    "HEADER",
    "TABLE",
    "DETAIL",
    "CARD_LIST",
    "FLEX_BOX"
  ]),
  Component: SpaceCloudUploader as any,
  ConfigurationComponent: Config,
  ensureComponent,
  componentConfigReducer: reducer,
  defaultElementLayout: new ElementLayout({
    width: "auto",
    height: "auto",
    snapToGrid: false
  }),
  fallbackWidth: 80,
  getInitialDraftState: makeInitialState,
  getSchema: () => [],
  allowSelfBinding: false,
  allowAncestorBinding: true,
  tags: [SpaceComponentTags.Base],
  isHeadless: false,
  errorSelector,
  isContainer: false,
  featureFlag: "CLOUD_UPLOADER"
};

export default pkg;
