import styled from "styled-components";

import { colors } from "../../../../cssConstants";
import { B3, LinkButtonNew } from "../../../common/StyledComponents";
import BaseUpsellBadge from "../common/UpsellBadge";

export const Root = styled.div<{ gap?: number }>`
  background-color: ${colors.newContainerPrimary};
  border: 1px solid ${colors.newContainerPrimaryTint};
  border-radius: 8px;
  padding: 24px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Header = styled(B3)`
  display: flex;
  align-items: baseline;
`;

export const Title = styled(B3)`
  color: ${props => props.theme.surfaceSecondary};
`;

export const CenterCardContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`;

export const AnalyticsAction = styled(LinkButtonNew)`
  padding: 0;
`;

export const Space = styled.div`
  flex-grow: 1;
`;

export const UpsellBadge = styled(BaseUpsellBadge)`
  margin-left: 14px;
`;
