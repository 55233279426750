import { colorTokens } from "../../cssConstants";

export const styles = {
  control: (provided: Record<string, any>) => ({
    ...provided,
    height: "30px",
    minHeight: "30px",
    boxShadow: "none",
    minWidth: "100px",
    background: "transparent",
    borderColor: colorTokens.grey1400,
    "&:hover": {
      borderColor: colorTokens.purple700
    },
    maxHeight: "100%"
  }),
  valueContainer: (provided: Record<string, any>) => ({
    ...provided,
    position: "static",
    paddingLeft: "14px",
    userSelect: "none"
  }),
  singleValue: (provided: Record<string, any>) => ({
    ...provided,
    color: "white",
    fontSize: "12px",
    fontWeight: "500",
    maxWidth: "calc(100% - 40px)"
  }),
  dropdownIndicator: (provided: Record<string, any>) => ({
    ...provided,
    padding: "5px 16px",
    color: colorTokens.grey1400,
    zoom: 0.7,
    "&:hover": {
      color: colorTokens.purple400
    }
  }),
  groupHeading: (provided: Record<string, any>) => {
    return {
      ...provided,
      display: "none"
    };
  },
  indicatorSeparator: (provided: Record<string, any>) => ({
    ...provided,
    display: "none"
  }),
  input: (provided: Record<string, any>) => ({
    ...provided,
    margin: "unset"
  }),
  menu: (provided: Record<string, any>) => ({
    ...provided,
    marginTop: "9px"
  }),
  menuList: (provided: Record<string, any>) => ({
    ...provided,
    fontSize: "12px",
    fontWeight: 500,
    background: colorTokens.grey2000,
    color: "white"
  }),
  placeholder: (provided: Record<string, any>) => ({
    ...provided,
    fontSize: "12px",
    fontWeight: 500
  })
};

export const theme = (provided: Record<string, any>) => ({
  ...provided,
  colors: {
    ...provided.colors,
    primary25: colorTokens.purpleTransparent400,
    primary50: colorTokens.purpleTransparent400,
    primary75: colorTokens.purpleTransparent400,
    primary: colorTokens.purple700,
    danger: colorTokens.red300
  }
});
