import styled from "styled-components";

import { InfiniteScrollTable } from "../../../../common/InfiniteScrollTable/InfiniteScrollTable";

export const StyledContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const StyledInfiniteScrollTable = styled(InfiniteScrollTable)`
  overflow: scroll;
  white-space: nowrap;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  margin-bottom: ${props => props.theme.scrollBarSize};

  &:hover {
    margin-bottom: 0;
  }

  .ant-table-body .ant-table-thead th {
    border-top: none;
  }

  .resourceSearchBarDropdown {
    .ant-select-dropdown-menu-item.defautResourceSearchOption {
      white-space: normal;
      i {
        margin-right: 8px;
      }
      &:hover {
        .anticon.anticon-check {
          color: transparent;
        }
      }
    }
    .ant-select-dropdown-menu-item-disabled {
      white-space: normal;
    }
  }

  .scrollTableRow {
    td {
      &:first-child {
        border-left: solid 1px transparent;
      }

      &:last-child {
        border-right: solid 1px transparent;
      }
    }
  }

  .selectedRow {
    td {
      position: relative;
      transition: none !important;
      background-color: #faf0ff !important;
      border-bottom-color: ${props => props.theme.primaryColor};

      &:before {
        content: "";
        z-index: 10;
        position: absolute;
        top: -1px;
        left: 0;
        right: 0;
        height: 1px;
        background-color: ${props => props.theme.primaryColor};
      }

      &:first-child {
        border-left-color: ${props => props.theme.primaryColor};
      }

      &:last-child {
        border-right-color: ${props => props.theme.primaryColor};
      }
    }
  }

  @supports (-ms-ime-align: auto) {
    & .ant-table .ant-table-content .ant-table-body,
    & .ant-table .ant-table-content .ant-table-tbody {
      overflow: visible !important;
    }
  }

  /* Scrolls appear on hover in webkit. Add some spacing to prevent shifting when they appear */
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    .ant-table-body,
    .ant-row-flex-center,
    .ant-table-placeholder {
      margin-right: ${props => props.theme.scrollBarSize};
    }

    &:hover .ant-table-body,
    &:hover .ant-row-flex-center,
    &:hover .ant-table-placeholder {
      margin-right: 0 !important;
    }

    @-moz-document url-prefix() {
      .ant-table-body,
      .ant-row-flex-center {
        margin-right: 0;
      }
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .ant-table-body,
      .ant-row-flex-center {
        margin-right: 0;
      }
    }

    @supports (-ms-ime-align: auto) {
      .ant-table-body,
      .ant-row-flex-center {
        margin-right: 0;
      }
    }
  }

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:horizontal {
    height: 0;
  }

  &::-webkit-scrollbar:vertical {
    width: 0px;
  }

  &:hover::-webkit-scrollbar:horizontal {
    height: ${props => props.theme.scrollBarSize};
  }

  &:hover::-webkit-scrollbar:vertical {
    width: ${props => props.theme.scrollBarSize};
  }

  &::-webkit-scrollbar-thumb {
    border-radius: ${props => props.theme.spacersm};
    border: 2px solid white;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: ${props => props.theme.spacersm};
  }

  .ant-table-thead > tr > th {
    &:first-child {
      padding-left: ${props => props.theme.spacerlg} !important;
    }
  }

  .ant-table-tbody > tr > td {
    max-width: 380px;
    overflow-wrap: break-word;
    word-wrap: break-word;

    &:first-child {
      padding-left: ${props => props.theme.spacerlg} !important;
    }

    &:last-child {
      padding-right: ${props => props.theme.spacerlg} !important;
    }

    div {
      white-space: normal;
      width: max-content;
      max-width: 320px;
    }
  }

  th.disable-pointer-events:active {
    pointer-events: none;
  }
`;
StyledInfiniteScrollTable.displayName = "InfiniteScrollTable";

export const StyledSearchDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;

  & .ant-select-selection--multiple .ant-select-selection__choice {
    /* Ensure at least 2 filters render per line and minimize shifting when filters toggle */
    max-width: 47%;
  }

  .resourceSearchBar {
    width: 100%;
    min-width: 50%;
    max-width: 500px;
  }

  .resourceSearchBar.ant-select-auto-complete .ant-select-selection--single {
    margin-right: -46px;
  }
  .resourceSearchBar.ant-select-auto-complete .ant-input-affix-wrapper .ant-input {
    padding-right: 62px;
  }
`;
