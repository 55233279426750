import React from "react";

import { ApolloError } from "apollo-client";
import styled from "styled-components";

import { colors } from "../../../../../cssConstants";
import { ScmStatus } from "../../../../../types";
import { ButtonTypes } from "../../../../common/ButtonNew/ButtonNew";
import { ModalNew } from "../../../../common/Modal";
import { assertNever } from "../../../../util/assertNever";
import { SpaceTileNode } from "../../../hooks/useSpacesManager/useSpacesManager";

import { updateScmStatus } from "./common";
import CopyLinkButton from "./CopyLinkButton";
import ManageButton from "./ManageButton";
import reducer, { ActionType } from "./reducer";
import ScmConnection from "./ScmConnection";
import useInitSpaceScm from "./useInitScm";

interface Props {
  space: SpaceTileNode;
}

const StyledManageButton = styled(ManageButton)`
  margin-bottom: 16px;
`;

export const ErrorMessage = styled.p`
  color: ${props => props.theme.errorColor};
  margin-top: ${props => props.theme.spacersm};
`;
ErrorMessage.displayName = "ErrorMessage";

const PRLinkContainer = styled.p`
  border: 1px solid ${colors.dropdownBorderColor};
  border-radius: 3px;
  padding: 2px 7px 2px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default function ManageScm({ space }: Props) {
  const [state, dispatch] = React.useReducer(reducer, {
    approvalHref: "",
    initState: "confirm",
    showModal: false,
    error: ""
  });

  const spaceId = space.id;
  const { approvalHref, error, initState, showModal } = state;

  const onError = (error: Error | ApolloError) => {
    dispatch({ type: ActionType.ERROR, payload: error.message });
  };

  const [initSpaceScm, { client }] = useInitSpaceScm({
    variables: { spaceId },
    onError,
    onCompleted: data => {
      switch (data.initSpaceScm.__typename) {
        case "InitSpaceScmSuccess":
          dispatch({
            type: ActionType.COMPLETE,
            payload: data.initSpaceScm.approvalHref
          });
          updateScmStatus(client!, spaceId, ScmStatus.CONNECTED);
          break;
        case "ClientErrorResult":
          onError(new Error(data.initSpaceScm.message));
          break;
        default:
          assertNever(data.initSpaceScm);
          break;
      }
    }
  });

  let title, okText, buttonType: ButtonTypes | undefined, onCancel;
  switch (state.initState) {
    case "confirm":
      title = "Manage this space with GitHub";
      okText = "Yes, manage with GitHub";
      buttonType = "brand";
      onCancel = () => dispatch({ type: ActionType.CLOSE });
      break;
    case "error":
      title = "Manage this space with GitHub";
      okText = "Yes, manage with GitHub";
      buttonType = "brand";
      onCancel = () => dispatch({ type: ActionType.CLOSE });
      break;
    case "initializing":
      title = "Manage this space with GitHub";
      okText = "Initializing";
      buttonType = "brand";
      onCancel = () => dispatch({ type: ActionType.CLOSE });
      break;
    case "done":
      title = "PR is ready to review!";
      okText = "Done";
      buttonType = "primary";
      onCancel = undefined;
      break;
    default:
      assertNever(state.initState);
  }

  return (
    <>
      {space.scmStatus === ScmStatus.CONNECTED && <ScmConnection spaceId={spaceId} />}
      {space.scmStatus !== ScmStatus.CONNECTED && (
        <StyledManageButton onClick={_ => dispatch({ type: ActionType.OPEN })} />
      )}
      <ModalNew
        title={title}
        visible={showModal}
        okText={okText}
        confirmLoading={initState === "initializing"}
        buttonType={buttonType}
        width="527px"
        destroyOnClose
        onCancel={onCancel}
        onOk={() => {
          if (initState === "done") {
            dispatch({ type: ActionType.CLOSE });
          } else {
            dispatch({ type: ActionType.INIT });
            initSpaceScm();
          }
        }}
      >
        {initState === "confirm" && (
          <>
            <p>
              This will create an initial PR for this space against your configured
              repository. Would you like to proceed?
            </p>
          </>
        )}
        {initState === "initializing" && (
          <p>Creating initialization branch and pull request...</p>
        )}
        {initState === "error" && (
          <>
            <p>An error occurred:</p>
            <ErrorMessage>{error}</ErrorMessage>
            <p>Would you like to try again?</p>
          </>
        )}
        {initState === "done" && (
          <>
            <p>A pull request has been generated for your space:</p>
            <PRLinkContainer>
              <a href={approvalHref} target="_blank" rel="noreferrer">
                {approvalHref}
              </a>
              <CopyLinkButton href={approvalHref} />
            </PRLinkContainer>
          </>
        )}
      </ModalNew>
    </>
  );
}
