import React from "react";

import { DefaultTheme } from "../../../../../../cssConstants";
import { IconTooltipPopper } from "../../../../../common/ToolTips";

const PermissionWarningIcon = ({ className }: { className?: string }) => {
  return (
    <IconTooltipPopper
      iconType="warning"
      color={DefaultTheme.warningColor}
      tooltip="This value could not be retrieved because you do not have permission to read it."
      className={className}
    />
  );
};

export default PermissionWarningIcon;
