import React from "react";

import styled from "styled-components";

import { Props } from "..";
import { DefaultTheme } from "../../../../../cssConstants";
import { useEvaluaterContext } from "../../../../common/CodeSandbox/EvaluaterContext";
import { EVALUATION_ERROR_PREFIX } from "../../../../common/CodeSandbox/useCodeSandbox";
import EditableText from "../../../../common/EditableText";
import { AbsoluteErrorIcon } from "../../../../common/Icons";
import { IconTooltipPopper } from "../../../../common/ToolTips";
import { useTransformationActionContext } from "../../../layout/TransformationContext/TransformationContext";
import { useSpaceConfigContext } from "../../../SpaceConfig/SpaceConfigContext";
import { useSpaceContext, useStableSpaceContext } from "../../SpaceContext";
import PermissionWarningIcon from "../common/PermissionWarningIcon";
import { useComponentStateContext } from "../contexts/ComponentStateContext";

import { ensureSpaceCalculatedField } from "./Config";

const Root = styled.div`
  max-height: 100%;
  white-space: pre-wrap;
`;

export default function SpaceCalculatedField(props: Props) {
  const calculatedField = ensureSpaceCalculatedField(props.spaceComponent);
  const { input, updateOutput } = useComponentStateContext();
  const { lock, unlock } = useTransformationActionContext();
  const { editMode } = useStableSpaceContext();
  const { debugMode } = useSpaceContext();
  const { dispatch } = useSpaceConfigContext();
  const { evaluate, getConsoleError } = useEvaluaterContext();
  const { template } = calculatedField.properties;
  const [evaluated, setEvaluated] = React.useState<string | null>(null);
  const [error, setError] = React.useState("");

  React.useEffect(() => {
    const evalExpression = async () => {
      try {
        const text = await evaluate(template, input);
        setEvaluated(text as string);
        setError("");
      } catch (e) {
        // clear template if it cannot be evaluated (likely due to bindings not fulfilled)
        if (typeof e === "string" && e.indexOf(EVALUATION_ERROR_PREFIX) > -1) {
          setEvaluated(editMode ? template : "error");
          setError(e);
        }
        if (editMode) {
          console.warn(getConsoleError(e));
        }
      }
    };
    evalExpression();
  }, [template, input, evaluate, updateOutput, editMode, getConsoleError]);

  React.useEffect(() => {
    updateOutput({ value: evaluated });
  }, [updateOutput, evaluated]);

  const feedbackIcons = [];
  const showDebugFeedback = editMode || debugMode;
  let hasCodeFeedack = false;
  if (String(evaluated).match("no access")) {
    feedbackIcons.push(<PermissionWarningIcon />);
  }

  if (showDebugFeedback) {
    if (evaluated?.includes("undefined")) {
      hasCodeFeedack = true;
      feedbackIcons.push(
        <IconTooltipPopper
          iconType="warning"
          color={DefaultTheme.warningColor}
          tooltip="This template likely includes variables which are currently empty."
        />
      );
    } else if (error) {
      hasCodeFeedack = true;
      feedbackIcons.push(
        <IconTooltipPopper
          iconType="warning"
          color={DefaultTheme.warningColor}
          tooltip={<Code>{error}</Code>}
        />
      );
    }
    if (hasCodeFeedack) {
      feedbackIcons.push(
        <IconTooltipPopper iconType="code" tooltip={<Code>{template}</Code>} />
      );
    }
  }

  const enableClickToEdit = editMode && window.FEATURE_FLAGS.includes("CLICK_TO_EDIT");
  let text = evaluated;
  if (editMode && template.trim() !== "" && text === "") {
    // Remove wrapping backticks
    text = template.replace(/^`|`$/g, "");
  }
  const loading = evaluated === null && !error;
  if (loading) {
    return null;
  }

  return (
    <Root>
      {props.hasConfigError && <AbsoluteErrorIcon />}
      {enableClickToEdit ? (
        <EditableText
          value={text || ""}
          onChange={value => {
            dispatch({
              type: "MERGE_DRAFT_COMPONENT",
              componentSlug: props.spaceComponent.slug,
              payload: {
                change: {
                  properties: { template: value }
                }
              }
            });
          }}
          onEditStart={() => {
            lock(props.spaceComponent.slug);
          }}
          onEditEnd={() => {
            unlock(props.spaceComponent.slug);
          }}
        />
      ) : (
        text
      )}
      {<IconContainer>{feedbackIcons}</IconContainer>}
    </Root>
  );
}

const Code = styled.code`
  font-size: 12px;
`;

const IconContainer = styled.div`
  display: inline-flex;
  pointer-events: all;
  gap: 4px;
  background: inherit;
  opacity: 0.6;
`;
