import { cloneDeep } from "lodash";

import { ReturnSchema } from "../../../../constants";
import { ClientErrorInfo } from "../../../../types";
import { assertNever } from "../../../util/assertNever";

import { HttpTransformExceptions, HttpTransformResults } from "./queries";

export interface PreviewError {
  message: string;
  errorInfo?: ClientErrorInfo;
}

export interface FunctionPreviewState {
  error?: PreviewError;
  showResults: boolean;
  isValidResponse: boolean;
  previewResults: HttpTransformResults;
  previewExceptions: HttpTransformExceptions;
  previewReturnSchema: ReturnSchema | undefined;
}

interface ResetAction {
  type: "RESET";
}

interface SetErrorAction {
  type: "SET_ERROR";
  payload: {
    message: string;
    errorInfo?: ClientErrorInfo;
  };
}

interface SetResponseAction {
  type: "SET_RESPONSE";
  payload: {
    results: HttpTransformResults;
    exceptions: HttpTransformExceptions;
    returnSchema: ReturnSchema;
    isValidResponse: boolean;
  };
}

export type FunctionPreviewAction = ResetAction | SetErrorAction | SetResponseAction;

const INITIAL_PREVIEW_RESULTS: HttpTransformResults = {
  data: null,
  transformedData: null,
  metadata: null,
  transformedMetadata: null
};

export const getInitialState = (
  mixin?: Partial<FunctionPreviewState>
): FunctionPreviewState => ({
  isValidResponse: false,
  showResults: false,
  previewReturnSchema: undefined,
  previewResults: cloneDeep(INITIAL_PREVIEW_RESULTS),
  previewExceptions: {},
  ...mixin
});

export const reducer = (
  state: FunctionPreviewState,
  action: FunctionPreviewAction
): FunctionPreviewState => {
  switch (action.type) {
    case "RESET":
      return {
        ...state,
        error: undefined,
        isValidResponse: false,
        previewResults: cloneDeep(INITIAL_PREVIEW_RESULTS),
        previewExceptions: {},
        showResults: true
      };
    case "SET_ERROR":
      const error = action.payload;
      if (error.errorInfo?.__typename) {
        delete error.errorInfo.__typename;
      }
      return {
        ...state,
        error,
        isValidResponse: false
      };
    case "SET_RESPONSE":
      return {
        ...state,
        previewReturnSchema: action.payload.returnSchema,
        previewResults: action.payload.results,
        previewExceptions: action.payload.exceptions,
        isValidResponse: action.payload.isValidResponse
      };
    default:
      return assertNever(action);
  }
};
