import React from "react";

import styled from "styled-components";

import { ExecutionStatus } from "../../queries/common";
import RunStatusIcon from "../RunStatusIcon";

const Container = styled.div`
  display: flex;
  align-items: center;
`;
const StatusContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StatusText = styled.div`
  margin-left: ${props => props.theme.spacersm};
`;

export function humanizeExecutionStatus(status: ExecutionStatus): string {
  switch (status) {
    case ExecutionStatus.Started:
      return "Running";
    case ExecutionStatus.Succeeded:
      return "Success";
    case ExecutionStatus.Failed:
      return "Error";
  }
}

interface Props {
  status: ExecutionStatus;
}
export const RunStatus = ({ status }: Props) => {
  return (
    <Container>
      <StatusContainer>
        <RunStatusIcon status={status} />
        <StatusText>{humanizeExecutionStatus(status)}</StatusText>
      </StatusContainer>
    </Container>
  );
};

export default RunStatus;
