import React from "react";

import { get } from "lodash";
import moment from "moment";
import { v4 as uuid } from "uuid";

import { useEvaluaterContext } from "../../../../../common/CodeSandbox/EvaluaterContext";
import { EVALUATION_ERROR_PREFIX } from "../../../../../common/CodeSandbox/useCodeSandbox";
import {
  BLANK_VALUE_TYPE_MAP,
  BlankValueType,
  DefaultValueType
} from "../../../constants";
import { useStableSpaceContext } from "../../../SpaceContext";
import { useComponentStateContext } from "../../contexts/ComponentStateContext";

export default function useDefaultValue() {
  const { componentNode, input } = useComponentStateContext();
  const { evaluate, getConsoleError } = useEvaluaterContext();
  const { editMode } = useStableSpaceContext();
  const [evaluatedTemplate, setEvaluatedTemplate] = React.useState<string | undefined>(
    ""
  );
  const [error, setError] = React.useState<string | null>(null);

  const properties = componentNode?.component.properties || {};

  const {
    blank_value_type,
    default_value_type,
    default_value,
    default_value_template,
    default_value_binding
  } = properties;

  React.useEffect(() => {
    if (
      default_value_type !== DefaultValueType.TEMPLATE ||
      default_value_template === undefined
    ) {
      return;
    }

    const evalExpression = async () => {
      try {
        const template = await evaluate(default_value_template, input);
        setError(null);
        setEvaluatedTemplate(
          template === "undefined" ? undefined : (template as string)
        );
      } catch (e) {
        // clear template if it cannot be evaluated (likely due to bindings not fulfilled)
        if (typeof e === "string" && e.indexOf(EVALUATION_ERROR_PREFIX) > -1) {
          setEvaluatedTemplate("");
        }
        setError(e as string);
        if (editMode) {
          console.warn(getConsoleError(e));
        }
      }
    };
    evalExpression();
  }, [
    default_value_type,
    default_value_template,
    evaluate,
    input,
    editMode,
    getConsoleError
  ]);

  const defaultValue = React.useMemo(() => {
    switch (default_value_type) {
      case DefaultValueType.NULL:
        return null;
      case DefaultValueType.UUID:
        return uuid();
      case DefaultValueType.CSV_VALUE:
        return default_value?.split(",") || [];
      case DefaultValueType.CURRENT_DATETIME:
        return moment().format();
      case DefaultValueType.BOOLEAN_VALUE:
      case DefaultValueType.DATETIME_VALUE:
      case DefaultValueType.FILE_VALUE:
      case DefaultValueType.TEXT_VALUE:
        return default_value;
      case DefaultValueType.JSON_VALUE:
        // default_value is a string. if it can be parsed into valid json, return valid json,
        // otherwise return undefined.
        try {
          const json = JSON.parse(default_value);
          return json;
        } catch (e) {
          return undefined;
        }
      case DefaultValueType.BINDING:
        return get(input, default_value_binding?.binding);
      case DefaultValueType.TEMPLATE:
        if (default_value_template === undefined) return;
        return evaluatedTemplate;
      default:
        throw new Error(`Unexpected default value type: ${default_value_type}`);
    }
  }, [
    default_value_type,
    default_value,
    default_value_binding,
    default_value_template,
    input,
    evaluatedTemplate
  ]);

  const blankValue = React.useMemo(() => {
    return blank_value_type === undefined
      ? undefined
      : BLANK_VALUE_TYPE_MAP[blank_value_type as BlankValueType];
  }, [blank_value_type]);

  return {
    defaultValue,
    blankValue,
    isBlank: defaultValue === blankValue,
    error
  };
}
