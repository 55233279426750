import React, { useState } from "react";

import { Icon } from "antd";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";
import styled from "styled-components";

import { newColors } from "../../../cssConstants";
import { PORTAL_QUERY_SELECTOR } from "../MomentFormItemPopper/utils";

const IconTooltipPopper = ({
  tooltip,
  iconType,
  color = newColors.surfaceTertiary,
  className
}: {
  tooltip: React.ReactNode;
  iconType: string;
  color?: string;
  className?: string;
}) => {
  const [hovered, setHovered] = useState(false);
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "top",
    modifiers: [
      { name: "offset", options: { offset: [0, 5] } },
      { name: "arrow", options: { element: arrowElement } }
    ]
  });
  return (
    <Root
      ref={setReferenceElement}
      className={className}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <StyledIcon style={{ color }} type={iconType} />
      {hovered &&
        createPortal(
          <Tooltip ref={setPopperElement} style={styles.popper} {...attributes.popper}>
            {tooltip}
            <Arrow ref={setArrowElement} style={styles.arrow} />
          </Tooltip>,
          document.querySelector(PORTAL_QUERY_SELECTOR) as HTMLElement
        )}
    </Root>
  );
};

const Root = styled.div`
  width: 1em;
  height: 1em;
`;

const Tooltip = styled.div`
  background: ${props => props.theme.textColor};
  padding: ${props => props.theme.spacersm};
  color: white;
  width: 200px;
  border-radius: 4px;
`;

const StyledIcon = styled(Icon)`
  cursor: help;
`;

const Arrow = styled.div`
  background: ${props => props.theme.textColor};
  margin-top: 7px;
  width: 10px;
  height: 10px;
  clip-path: polygon(0% 0%, 50% 60%, 100% 0%);
`;

export default IconTooltipPopper;
