import { cloneDeep, flow, partial } from "lodash";

import { createFunctionNode } from "../../../../../../../__mocks__/factories";
import {
  FunctionNode,
  SpaceComponentObject,
  SourceType,
  SpaceFunctionType,
  BaseComponentProperties
} from "../../../../../../../types";
import {
  disambiguateName,
  createSpaceFunction,
  PipelineFunction
} from "../../../../../FunctionExecutor/FunctionExecutor";
import {
  commonComponentReducer,
  createBaseComponentConfigState
} from "../../../../../SpaceConfig/SpaceConfigContext/useSpaceConfig/reducer";
import {
  BaseComponentConfigState,
  BaseConfigAction,
  ComponentConfigState,
  SpaceConfigAction,
  EventHandler
} from "../../../../../types";
import { MessageType } from "../../effects/Effects/DisplayMessage";
import { SubmittableEventType } from "../../effects/useSubmittableEffects/useSubmittableEffects";
import parameterConfigReducer, {
  getInitialParameterState
} from "../../ParametersManager/reducer/reducer";
import { generateInputParameters } from "../../ParametersManager/utils";
import { InputParameter } from "../../useFuncParams";

export type SubmittableComponentProps = BaseComponentProperties & {
  input_parameters: InputParameter[];
  function_pipeline?: string[] | null;
  title?: string;
  instructions?: string;
  button_text?: string;
  effects: EventHandler[];
};
export type SubmittableComponent = SpaceComponentObject<SubmittableComponentProps>;

export type PendingFunction = { id: string | null; index: number };
export interface BaseSubmittableComponentConfigState extends BaseComponentConfigState {
  parameterValueErrors: Record<string, boolean>;
  pendingFunctions: PendingFunction[];
}

interface BulkImportComponentConfigState extends BaseSubmittableComponentConfigState {
  type: "FUNCTION_BULK_IMPORT";
}

interface ButtonComponentConfigState extends BaseSubmittableComponentConfigState {
  type: "FUNCTION_BUTTON";
}

interface FormComponentConfigState extends BaseSubmittableComponentConfigState {
  type: "FUNCTION_FORM";
}

interface HeadlessComponentConfigState extends BaseSubmittableComponentConfigState {
  type: "FUNCTION_HEADLESS";
}

interface ModalFormComponentConfigState extends BaseSubmittableComponentConfigState {
  type: "FUNCTION_MODAL_FORM";
}

interface CloudUploaderComponentConfigState
  extends BaseSubmittableComponentConfigState {
  type: "CLOUD_UPLOADER";
}

export type SubmittableComponentConfigState =
  | BulkImportComponentConfigState
  | ButtonComponentConfigState
  | FormComponentConfigState
  | HeadlessComponentConfigState
  | ModalFormComponentConfigState
  | CloudUploaderComponentConfigState;

interface SetFunctionId extends BaseConfigAction {
  type: "SET_FUNCTION_ID";
  payload: {
    functionId: string;
    index: number;
  };
}

interface LoadSubmittableFunction extends BaseConfigAction {
  type: "LOAD_SUBMITTABLE_FUNCTION";
  payload: {
    functionNode: FunctionNode;
  };
}

interface UpdateFunctionDefinition extends BaseConfigAction {
  type: "UPDATE_FUNCTION_DEFINITION";
  payload: {
    functionNode: FunctionNode;
  };
}

interface AddPendingFunction extends BaseConfigAction {
  type: "ADD_PENDING_FUNCTION";
}

interface RemoveFunction extends BaseConfigAction {
  type: "REMOVE_FUNCTION";
  payload: {
    index: number;
  };
}

export type SubmittableComponentConfigAction =
  | SetFunctionId
  | LoadSubmittableFunction
  | UpdateFunctionDefinition
  | AddPendingFunction
  | RemoveFunction;

export function ensureSubmittableComponent(
  sc: SpaceComponentObject
): SubmittableComponent {
  // TODO can this be delegated to param reducer
  if (!sc.properties.input_parameters) {
    sc.properties.input_parameters = [];
  }

  if (!sc.properties.effects) {
    sc.properties.effects = [
      {
        type: SubmittableEventType.SUBMIT_SUCCESS,
        effect: { type: "auto_update_by_key" }
      }
    ];
    if (sc.type !== "FUNCTION_HEADLESS") {
      sc.properties.effects.push({
        type: SubmittableEventType.SUBMIT_SUCCESS,
        effect: {
          type: "display_message",
          options: {
            type: MessageType.SUCCESS,
            message: ""
          }
        }
      });
    }
    sc.properties.effects.push({
      type: SubmittableEventType.SUBMIT_FAILURE,
      effect: {
        type: "display_message",
        options: {
          type: MessageType.ERROR,
          message: ""
        }
      }
    });
    if (sc.type === "FUNCTION_FORM") {
      sc.properties.effects.push({
        type: SubmittableEventType.SUBMIT_SUCCESS,
        effect: { type: "reset_form_inputs" }
      });

      sc.properties.is_header_enabled =
        typeof sc.properties.is_header_enabled === "boolean"
          ? sc.properties.is_header_enabled
          : true;
    }
  }

  if (sc.type.includes("FORM") && !sc.properties.title) {
    sc.properties.title = "";
  }

  if (sc.type.includes("BULK") && !sc.sourceType) {
    sc.sourceType = SourceType.FILE;
  }

  return sc;
}

export function makeInitialState(
  draftComponent: SpaceComponentObject
): SubmittableComponentConfigState {
  const submittableComponent = ensureSubmittableComponent(draftComponent);
  return {
    ...createBaseComponentConfigState(ensureSubmittableComponent(submittableComponent)),
    pendingFunctions: draftComponent.functions.edges.length
      ? []
      : [{ id: null, index: 0 }],
    ...getInitialParameterState(draftComponent)
  } as SubmittableComponentConfigState;
}

const isSubmittableComponentConfigState = (
  state: ComponentConfigState
): state is SubmittableComponentConfigState =>
  state.type === "FUNCTION_BULK_IMPORT" ||
  state.type === "FUNCTION_BUTTON" ||
  state.type === "FUNCTION_HEADLESS" ||
  state.type === "FUNCTION_FORM" ||
  state.type === "FUNCTION_MODAL_FORM" ||
  state.type === "CLOUD_UPLOADER";

export function ensureSubmittableComponentConfigState(
  state: ComponentConfigState
): SubmittableComponentConfigState {
  if (isSubmittableComponentConfigState(state)) return state;
  throw new Error("Expected submittable component config state.");
}

/*
  # SubmittableComponents reducer

  Submittable components have an associated function. That function may be a
  pipeline function. The associated function is defined by the intersection of
  the component's functions Connection<FunctionNode> and the function_pipeline
  property if present. In the case where the associated function is a pipeline
  there will be one or more functions in the component's functions and two or
  more function ids in the function_pipeline array. A FunctionNode may be
  repeated in a pipeline function. If a component's associated function is not
  a pipeline, there will be one function in the component's functions and the
  function_pipeline property will be undefined or null.

  The arguments to functions are FunctionParameters. SubmittableComponents
  define an input_parameters array in their properties. The InputParameters
  listed in input_parameters describe how the component should determine the
  values to use as FunctionParameters when invoking their function.
  InputParameters are mapped to their FunctionParameter using their
  corresponding name property. In the case of pipeline functions
  FunctionParameter names are disambiguated by first prefixing the
  FunctionNode's DataSource’s name and its name, and then if necessary
  postfixing an incremented number if the FunctionNode occurs multiple times
   in the pipeline sequence.

   When a component's function transitions from RemoteFunction ->
   PipelineFunction any existing InputParameters of the original RemoteFunction
   must have their name disambiguated. Similarly when a component's function
   transitions from a PipelineFunction -> RemoteFunction the names of remaining
   InputParameters must have their disambiguation reversed. If a FunctionNode
   occurred multiple times in the function pipeline, instances of that
   FunctionNode occurring after the removed FunctionNode’s index in the
   sequence must have their disambiguated parameter names modified.

   When a new function is added to a component a slot for it is added to
   pendingFunctions. Initially id is tracked as null. Once SET_FUNCTION_ID is
   dispatched for that index, the function's id replaces the null placeholder.
   Consuming components are responsible for querying for any pending functions
   with non null ids. Once fetched the consuming component dispatches a
   LOAD_SUBMITTABLE_FUNCTION action with the fetched FunctionNode. That
   FunctionNode is then added to the component's function and if necessary the
   component's function is transitioned to a pipeline.
*/

function reducer(
  state: SubmittableComponentConfigState,
  action: SpaceConfigAction
): SubmittableComponentConfigState {
  switch (action.type) {
    case "CHANGE_SOURCE":
      const { sourceType } = action.payload;
      return {
        ...state,
        draftComponent: {
          ...state.draftComponent,
          sourceType: sourceType
        }
      };

    case "CHANGE_BINDING":
      const { path } = action.payload;
      return {
        ...state,
        draftComponent: {
          ...state.draftComponent,
          properties: {
            ...state.draftComponent.properties,
            binding: path
          }
        }
      };

    /*
      ADD_PENDING_FUNCTION
      Adds a pending function with a null id at the next function index.
    */
    case "ADD_PENDING_FUNCTION": {
      const nextFnIndex = state.pendingFunctions.length + selectPipeline(state).length;
      return {
        ...state,
        pendingFunctions: state.pendingFunctions.concat({
          id: null,
          index: nextFnIndex
        })
      };
    }

    /*
      SET_FUNCTION_ID
      Sets a pending function with the id and index defined in the payload.
      The set of functions for the draft component are tracked between pendingFunctions
      draftComponent.functions and function_pipeline. draftComponent.functions tracks
      those functions whose definitions have already been loaded, while state.pendingFunctions
      tracks functions which are either being edited or loaded. If a SET_FUNCTION_ID
      occurs for an already LOADED function that function and its associated input_parameters
      are removed, and if that change causes the function to transition from Pipeline
      input_parameter disambiguation is reversed.
    */
    case "SET_FUNCTION_ID": {
      const { functionId, index } = action.payload;
      const draftComponent = cloneDeep(state.draftComponent);

      const mergedFns = selectMergedFunctions(state);

      // Detect no-ops
      if (mergedFns[index]?.fn?.id === functionId) {
        return state;
      }

      // Set pending function removing existing func at index if present
      const prevFuncAtIndex = mergedFns[index];
      const pendingFn = { id: functionId, index };
      let pendingFunctions = [...state.pendingFunctions];
      if (
        prevFuncAtIndex === undefined ||
        ["LOADED", "MISSING"].includes(prevFuncAtIndex.type)
      ) {
        // Track new pending func
        pendingFunctions.push(pendingFn);
      } else if (prevFuncAtIndex.type === "PENDING") {
        // Replace currently pending
        const replacedIndex = pendingFunctions.findIndex(pf => pf.index === index);
        if (replacedIndex === -1) {
          throw new Error("Expected to find pending function to replace at index.");
        }
        pendingFunctions[replacedIndex] = pendingFn;
      }
      pendingFunctions = pendingFunctions.sort((a, b) => a.index - b.index);

      let nextState = {
        ...state,
        draftComponent,
        pendingFunctions
      };
      // If a previously LOADED or MISSING fn is being
      // removed, also remove it's input_parameters
      if (["LOADED", "MISSING"].includes(prevFuncAtIndex.type)) {
        nextState = removeFunction(
          nextState,
          prevFuncAtIndex.fn as FunctionNode | null,
          index
        );
      }

      // If the function already exists in the functions connection
      // just construct the next pipeline now.
      const matchingFn = draftComponent.functions.edges.find(
        ({ node }) => node.id === functionId
      );
      if (matchingFn) {
        nextState = addFunction(nextState, matchingFn.node);
      }

      return nextState;
    }

    /*
      LOAD_SUBMITTABLE_FUNCTION
      Action dispatched after the function definition for a pendingFunction
      is loaded. Moves loaded function from pendingFunctions -> draftComponent.functions
      generating or mutating function_pipeline if required. If a LOAD_SUBMITTABLE_FUNCTION
      action results in a function_pipeline being generated, the initial
      REMOTE_FUNCTION will have its parameters disambiguated and retained.
      Any new required function_parameters will have associated input_paramters generated.
      Component text may also be defaulted if empty at the time of dispatch.
    */
    case "LOAD_SUBMITTABLE_FUNCTION": {
      const lastState = cloneDeep(state);
      const { functionNode } = cloneDeep(action.payload);
      const nextState = addFunction(lastState, functionNode);

      // Default component text
      const newTitle = functionNode.title || "";
      if (nextState.draftComponent.name === "") {
        nextState.draftComponent.name = newTitle;
      }

      const { draftComponent } = nextState;
      if (!draftComponent.properties.button_text) {
        draftComponent.properties.button_text = newTitle.split(" ")[0];
      }

      if (
        draftComponent.type.includes("FORM") &&
        draftComponent.properties.title === ""
      ) {
        draftComponent.properties.title = newTitle;
      }

      return nextState;
    }

    /*
      REMOVE_FUNCTION
      Removes the function at the index defined in the payload. The function to
      remove may be either loaded or pending. If it is loaded, associated input_parameters
      are pruned and if a function_pipeline is present, the function is also removed from there.
      If the function removal transitions the component's function from a pipeline -> non-pipeline
      input_paramter disambiguation is reversed.
    */
    case "REMOVE_FUNCTION": {
      const { index } = action.payload;
      const mergedFns = selectMergedFunctions(state);
      const funcAtIndex = mergedFns[index];
      const draftPendingFunctions: PendingFunction[] = [];
      state.pendingFunctions.forEach(pf => {
        // If funcAtIndex is pending do not add to next pendingFunctions
        // and if the funcAtIndex appears before a pendingFunction shift
        // its index down.
        if (funcAtIndex.type === "LOADED" || pf.index !== index) {
          draftPendingFunctions.push(
            pf.index > index ? { ...pf, index: pf.index - 1 } : pf
          );
        }
      });
      const draftComponent = state.draftComponent;
      let nextState = {
        ...state,
        draftComponent,
        pendingFunctions: draftPendingFunctions
      };
      if (funcAtIndex.type === "LOADED" || funcAtIndex.type === "MISSING") {
        nextState = removeFunction(nextState, funcAtIndex.fn, index);
      }

      return {
        ...nextState,
        pendingFunctions: draftPendingFunctions
      };
    }

    /*
      UPDATE_FUNCTION_DEFINITION
      Dispatched when FunctionNodes in apollo cache change allowing
      copies of FunctioNodes stored here to be syncronized with
      external changes, such as edits within the function editor.
    */
    case "UPDATE_FUNCTION_DEFINITION": {
      const { functionNode } = action.payload;
      const fnIndex = state.draftComponent.functions.edges.findIndex(
        ({ node }) => node.id === functionNode.id
      );
      if (fnIndex === -1) return state;
      const nextEdges = [...state.draftComponent.functions.edges];
      nextEdges.splice(fnIndex, 1, {
        node: functionNode
      });
      return {
        ...state,
        draftComponent: {
          ...state.draftComponent,
          functions: { edges: nextEdges }
        }
      };
    }

    default:
      return state;
  }
}

function selectPipeline(state: SubmittableComponentConfigState) {
  if (state.draftComponent.properties.function_pipeline) {
    return [...state.draftComponent.properties.function_pipeline];
  } else if (state.draftComponent.functions.edges.length) {
    return [state.draftComponent.functions.edges[0].node.id];
  }
  return [];
}

export type MergedFunction =
  | { type: "PENDING"; fn: PendingFunction }
  | { type: "LOADED"; fn: FunctionNode }
  | { type: "MISSING"; fn: null };
export function selectMergedFunctions(state: SubmittableComponentConfigState) {
  // Ordered list of functions merging both ready and pending
  const readyFnsMap = state.draftComponent.functions.edges.reduce<
    Record<string, FunctionNode>
  >((acc, curr) => {
    acc[curr.node.id] = curr.node;
    return acc;
  }, {});
  const mergedFns: Array<MergedFunction> = [];
  state.pendingFunctions.forEach(pf => {
    mergedFns[pf.index] = {
      type: "PENDING",
      fn: pf
    };
  });

  // Merge ready funcs by filling gaps in sparse array of pending funcs
  let pipeline = state.draftComponent.properties.function_pipeline;
  if (!pipeline) {
    pipeline = state.draftComponent.functions.edges[0]
      ? [state.draftComponent.functions.edges[0].node.id]
      : [];
  }
  const totalFnCount = pipeline.length + state.pendingFunctions.length;
  let pipelineIndex = 0;
  for (let i = 0; i < totalFnCount; i++) {
    if (mergedFns[i] !== undefined) continue;
    const fn = readyFnsMap[pipeline[pipelineIndex]];
    mergedFns[i] = fn
      ? {
          type: "LOADED",
          fn
        }
      : { type: "MISSING", fn: null };
    pipelineIndex++;
  }
  return mergedFns;
}

function removeFunction(
  state: SubmittableComponentConfigState,
  removedFunc: FunctionNode | null,
  removedFuncPipelineIndex = -1
): SubmittableComponentConfigState {
  const lastComponent = state.draftComponent as SubmittableComponent;
  const wasPipeline = (lastComponent.properties.function_pipeline || [])?.length > 1;
  const draftComponent = cloneDeep(lastComponent);
  let parameterValueErrorsMap = {
    last: state.parameterValueErrors,
    next: {},
    renamed: new Set<string>()
  };

  // Remove InputParameters for the removedFunc accounting for
  // parameter name disambiguation being dependent on the function's
  // occurence count in the pipeline when multiple instances of the
  // same function are present.
  const precedingPipeline =
    lastComponent.properties.function_pipeline?.slice(0, removedFuncPipelineIndex) ||
    [];
  const previousInstanceCount = precedingPipeline.filter(
    id => id === removedFunc?.id
  ).length;
  const params = removedFunc?.functionParameters?.edges || [];
  const paramNamesToRemove =
    params.map(({ node }) =>
      disambiguateName(
        node.name,
        removedFunc!,
        wasPipeline,
        previousInstanceCount ? previousInstanceCount : undefined
      )
    ) || [];
  draftComponent.properties.input_parameters =
    draftComponent.properties.input_parameters.filter(
      ip => !paramNamesToRemove.includes(ip.name)
    );

  // If there were multiple occurences of removedFunc, those appearing after
  // removedFuncPipelineIndex in pipeline will need their disambiguation index
  // shifted down by 1
  const rawParamsToRename = params.map(e => e.node.name) || [];
  let instanceCounter = 0;
  draftComponent.properties.function_pipeline?.forEach((id, i) => {
    if (id === removedFunc?.id && i <= removedFuncPipelineIndex) {
      instanceCounter++;
    }
    if (id === removedFunc?.id && i > removedFuncPipelineIndex) {
      const paramsToRename = rawParamsToRename.map(p =>
        disambiguateName(p, removedFunc, wasPipeline, instanceCounter)
      );
      const nextInputParameters: InputParameter[] = [];
      draftComponent.properties.input_parameters.forEach(ip => {
        if (!paramsToRename.includes(ip.name)) {
          nextInputParameters.push(ip);
          return;
        }
        const shiftedIndex = instanceCounter - 1;
        const newName = disambiguateName(
          rawParamsToRename[paramsToRename.indexOf(ip.name)],
          removedFunc,
          wasPipeline,
          shiftedIndex ? shiftedIndex : undefined
        );
        nextInputParameters.push({
          ...ip,
          name: newName
        });
        parameterValueErrorsMap = migrateParameterValueError(
          parameterValueErrorsMap,
          newName,
          ip.name
        );
      });
      draftComponent.properties.input_parameters = nextInputParameters;
      instanceCounter++;
    }
  });
  migrateUntouchedParameterValueErrors(parameterValueErrorsMap);

  // Cleanup functions tracked in pipeline
  if (wasPipeline) {
    draftComponent.properties.function_pipeline =
      draftComponent.properties.function_pipeline!.filter(
        (_, i) => i !== removedFuncPipelineIndex
      );
  }

  // Remove function from component.functions if last occurence
  if (
    removedFunc !== null &&
    (!draftComponent.properties.function_pipeline ||
      draftComponent.properties.function_pipeline.indexOf(removedFunc.id) === -1)
  ) {
    draftComponent.functions.edges = draftComponent.functions.edges.filter(
      e => e.node.id !== removedFunc.id
    );
  }

  if (
    draftComponent.properties.function_pipeline &&
    draftComponent.properties.function_pipeline.length === 1
  ) {
    delete draftComponent.properties.function_pipeline;
  }

  // If this change causes the function to no longer be a pipeline input parameters
  // for the remaining function need to be un-disambiguated
  if (wasPipeline && draftComponent.properties.function_pipeline === undefined) {
    // If there are missing fns need to shim in placer copies so original
    // parameter disambiguation can be simulated, and the parameters of
    // the still valid functions may be mapped to their new names
    const validFnIds = lastComponent.functions.edges.map(({ node: { id } }) => id);
    const missingFnIds = (lastComponent.properties.function_pipeline || []).filter(
      id => !validFnIds.includes(id)
    );
    const lastFn = createSpaceFunction({
      ...lastComponent,
      functions: {
        edges: lastComponent.functions.edges.concat(
          missingFnIds.map(id => ({ node: createFunctionNode({ id }) }))
        )
      }
    });

    const nextInputParameters: InputParameter[] = [];
    draftComponent.properties.input_parameters.forEach(ip => {
      try {
        const paramDescriptor = lastFn.describeFunctionParameter(ip.name);
        if (paramDescriptor === undefined) {
          throw new Error(
            "Expected parameterDescriptor to exist while reverting from pipeline."
          );
        }

        const newName = paramDescriptor?.param.name;
        nextInputParameters.push({
          ...ip,
          name: newName
        });
        parameterValueErrorsMap = migrateParameterValueError(
          parameterValueErrorsMap,
          newName,
          ip.name
        );
      } catch (e) {
        // The associated fn is missing so leave unchanged for now.
        // This orphaned InputParameter will be cleaned by the next step.
        nextInputParameters.push(ip);
      }
    });
    draftComponent.properties.input_parameters = nextInputParameters;
    migrateUntouchedParameterValueErrors(parameterValueErrorsMap);
  }

  // Clean InputParameters whose functions have been removed.
  const nextFn = createSpaceFunction(draftComponent);
  draftComponent.properties.input_parameters =
    draftComponent.properties.input_parameters.filter(ip => {
      try {
        const descriptor = nextFn.describeFunctionParameter(ip.name);
        return !!descriptor;
      } catch (e) {
        return false;
      }
    });

  // Remove any parameterValueErrors associated with the now removed params
  let nextParameterValueErrors = cleanParameterValueErrors(
    parameterValueErrorsMap
  ).next;
  nextParameterValueErrors = Object.fromEntries(
    Object.entries(nextParameterValueErrors).filter(
      ([paramName]) =>
        !!draftComponent.properties.input_parameters.find(ip => ip.name === paramName)
    )
  );

  return {
    ...state,
    draftComponent,
    parameterValueErrors: nextParameterValueErrors as Record<string, boolean>
  };
}

function addFunction(
  lastState: SubmittableComponentConfigState,
  functionNode: FunctionNode
): SubmittableComponentConfigState {
  const insertedIndices = [];
  const lastPipeline = selectPipeline(lastState);
  let parameterValueErrorsMap = {
    last: lastState.parameterValueErrors,
    next: {},
    renamed: new Set<string>()
  };

  // Resolve pending functions and insert into pipeline at correct indexes as needed
  const nextPipeline = [];
  const mergedFns = selectMergedFunctions(lastState);
  for (let i = 0; i < mergedFns.length; i++) {
    const currFn = mergedFns[i];
    if (currFn.type === "LOADED") {
      nextPipeline.push(currFn.fn.id);
    } else if (currFn.type === "PENDING" && currFn.fn.id === functionNode.id) {
      insertedIndices.push(i);
      nextPipeline.push(currFn.fn.id);
    }
  }

  const draftComponent = cloneDeep(lastState.draftComponent) as SubmittableComponent;

  // Add to component's function connection
  if (!draftComponent.functions.edges.some(e => e.node.id === functionNode.id)) {
    draftComponent.functions.edges = draftComponent.functions.edges.concat({
      node: functionNode
    });
  }

  const nextPendingFunctions = lastState.pendingFunctions.filter(
    pf => pf.id !== functionNode.id
  );

  if (nextPipeline.length > 1) {
    draftComponent.properties.function_pipeline = nextPipeline;
  } else {
    delete draftComponent.properties.function_pipeline;
  }

  // If the component's function transitioned into a pipeline existing input_parameters
  // require disambiguation.
  const transitionedToPipeline = lastPipeline.length < 2 && nextPipeline.length >= 2;
  if (transitionedToPipeline) {
    const originalFn = lastState.draftComponent.functions.edges[0]?.node;
    if (originalFn === undefined) {
      throw new Error(
        "Expected to find an initial function while transitioning to pipeline."
      );
    }
    const inputParameters: InputParameter[] = [];
    draftComponent.properties.input_parameters.forEach((ip: InputParameter) => {
      const newName = disambiguateName(ip.name, originalFn, true);
      inputParameters.push({
        ...ip,
        name: newName
      });
      parameterValueErrorsMap = migrateParameterValueError(
        parameterValueErrorsMap,
        newName,
        ip.name
      );
    });
    draftComponent.properties.input_parameters = inputParameters;
    migrateUntouchedParameterValueErrors(parameterValueErrorsMap);
  }

  // If we're adding a fn which was already present in the pipeline
  // and the existing instances of the fn follow those being inserted
  // their disambiguation indices must shift up to make room for those
  // instances being inserted.
  const fn = createSpaceFunction(draftComponent);
  if (fn.type === SpaceFunctionType.PIPELINE) {
    let newFnOffset = 0;
    let existingFnOffset = 0;
    for (let i = 0; i < nextPipeline.length; i++) {
      const fnId = nextPipeline[i];
      if (insertedIndices.includes(i)) {
        newFnOffset++;
      } else if (fnId === functionNode.id) {
        if (newFnOffset > 0) {
          const fnToShift = (fn as PipelineFunction).spaceFunctions.find(
            sf => sf.id === fnId
          );
          const paramsToShift = fnToShift?.functionParameters || [];
          for (let j = 0; j < paramsToShift.length; j++) {
            const fp = paramsToShift[j];
            const prevParamName = disambiguateName(
              fp.name,
              fnToShift!.config,
              true,
              existingFnOffset
            );
            const inputParameterIndex =
              draftComponent.properties.input_parameters.findIndex(
                ip => ip.name === prevParamName
              );
            const nextParamName = disambiguateName(
              fp.name,
              fnToShift!.config,
              true,
              newFnOffset + existingFnOffset
            );
            draftComponent.properties.input_parameters.splice(inputParameterIndex, 1, {
              ...draftComponent.properties.input_parameters[inputParameterIndex],
              name: nextParamName
            });
            parameterValueErrorsMap = migrateParameterValueError(
              parameterValueErrorsMap,
              nextParamName,
              prevParamName
            );
          }
          existingFnOffset++;
        }
      }
    }
    migrateUntouchedParameterValueErrors(parameterValueErrorsMap);
  }

  // Select component's function and regenerate input_parameters
  draftComponent.properties.input_parameters = generateInputParameters(draftComponent);

  return {
    ...lastState,
    draftComponent,
    parameterValueErrors: cleanParameterValueErrors(parameterValueErrorsMap)
      .next as Record<string, boolean>,
    pendingFunctions: nextPendingFunctions
  };
}

type ParameterValueErrorsMap = {
  last: Record<string, boolean>;
  next: Record<string, boolean | null>;
  renamed: Set<string>;
};
function migrateParameterValueError(
  parameterValueErrorsMap: ParameterValueErrorsMap,
  nextName: string,
  prevName: string
) {
  // Copy error to new name marking empty errors as null
  parameterValueErrorsMap.renamed.add(prevName);
  parameterValueErrorsMap.next[nextName] =
    parameterValueErrorsMap.last[prevName] !== undefined
      ? parameterValueErrorsMap.last[prevName]
      : null;
  return parameterValueErrorsMap;
}

function migrateUntouchedParameterValueErrors(
  parameterValueErrorsMap: ParameterValueErrorsMap
) {
  const toCopy = Object.entries(parameterValueErrorsMap.last).filter(
    ([name]) => !parameterValueErrorsMap.renamed.has(name)
  );
  toCopy.forEach(([key, val]) => {
    if (val !== undefined && parameterValueErrorsMap.next[key] === undefined)
      parameterValueErrorsMap.next[key] = val;
  });
  return parameterValueErrorsMap;
}

function cleanParameterValueErrors(
  parameterValueErrorsMap: ParameterValueErrorsMap
): ParameterValueErrorsMap {
  parameterValueErrorsMap.next = Object.fromEntries(
    Object.entries(parameterValueErrorsMap.next).filter(
      ([_, val]) => val !== null && val !== undefined
    )
  );
  return parameterValueErrorsMap;
}

export default (state: SubmittableComponentConfigState, action: SpaceConfigAction) =>
  flow([
    commonComponentReducer,
    partial(parameterConfigReducer, partial.placeholder, action),
    partial(reducer, partial.placeholder, action)
  ])(state, action);
