import gql from "graphql-tag";

import {
  SPACE_PARAMETER_FRAGMENT,
  TREE_NODE_FRAGMENT
} from "../../../../SpaceRoot/queries";

export const SPACE_UPDATE = gql`
  mutation SpaceUpdate($spaceInput: SpaceInput!, $environmentsToPublish: [ID]!) {
    spaceUpdate(
      spaceInput: $spaceInput
      environmentIdsToPublish: $environmentsToPublish
    ) {
      ok
      message
      source
      space {
        id
        name
        slug
        version(includeUnpublished: true) {
          __typename
          ... on NoPublishedVersionErrorResult {
            message
          }
          ... on SpaceVersionNode {
            id
            scmVersion
            ...spaceParameterFields
            components {
              ...treeNodeFields
            }
            subSpaces {
              slug
              id
              name
              version(includeUnpublished: true) {
                __typename
                ... on NoPublishedVersionErrorResult {
                  message
                }
                ... on SpaceVersionNode {
                  id
                  scmVersion
                  ...spaceParameterFields
                  components {
                    ...treeNodeFields
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${TREE_NODE_FRAGMENT}
  ${SPACE_PARAMETER_FRAGMENT}
`;
