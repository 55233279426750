import React, { useCallback } from "react";

import { get, isEqual, uniqueId } from "lodash";
import moment from "moment";

import { AttributeTypes } from "../../../../../../constants";
import {
  Cursor,
  CursorType,
  DataValue,
  Filter as FilterConfig,
  FiltersOption,
  FilterTypes,
  ListFunctionCursor,
  ViewFilter,
  ViewFilterOperator
} from "../../../../../../types";
import { useStableSpaceContext } from "../../../SpaceContext";
import { SpaceStateInputs } from "../../SpaceComponent";
import { maybeParseAsISOString } from "../Conditionals/evalConditional";

interface Params {
  filterConfigs: FilterConfig[];
  input: SpaceStateInputs | null;
  filtersOptions: FiltersOption[] | undefined;
  syncToLocationFilters: boolean;
}

export interface RequestFilter {
  sourceName: string;
  operator: ViewFilterOperator;
  value: DataValue;
}

function resolveConfigFilter(
  filter: FilterConfig,
  input: SpaceStateInputs,
  filtersOptions: FiltersOption[] = [],
  i: number
): ViewFilter | null {
  let value;
  switch (filter.type) {
    case FilterTypes.Value:
      value = filter.value;
      break;
    case FilterTypes.Binding:
      value = get(input, filter.binding!);
      break;
    case FilterTypes.Duration:
      const duration = moment.duration(filter.duration_iso);
      const roundingUnit = filter.duration_iso?.includes("T") ? "minute" : "hour";
      if (!moment.isDuration(duration))
        throw new Error(`Unexpected duration filter ${filter.duration_iso}`);
      value = moment().subtract(duration).startOf(roundingUnit).format();
      break;
    default:
      throw new Error("Unsupported filter type");
  }

  const filtersOption = filtersOptions.find(o => o.sourceName === filter.attribute);
  return filtersOption !== undefined && value !== undefined
    ? {
        sourceName: filter.attribute,
        operator: filter.operator,
        value,
        __clientId: `configFilter${i}`
      }
    : null;
}

// Filters have __clientId that is used to determine when a filter should replace
// an existing one or be appended to the set of filters. Most recent filters are
// at the tail of the filters array and should replace an existing filter sharing
// the same __clientId.
const dedupe = (arr: ViewFilter[]) =>
  Object.values(
    arr.reduce<Record<string, ViewFilter>>((acc, curr) => {
      acc[curr.__clientId as string] = curr;
      return acc;
    }, {})
  );

const offsetRegex = /(\+|-).*$/;
export const maybeCoerceDateTime = (
  filter: RequestFilter,
  filterOptions: FiltersOption[]
) => {
  const option = filterOptions.find(o => o.sourceName === filter.sourceName);
  if (!option) return filter.value;
  if (
    ![
      AttributeTypes.DATETIME,
      AttributeTypes.DATE,
      AttributeTypes.TIME,
      AttributeTypes.TIMESTAMP
    ].includes(option.sourceType)
  ) {
    return filter.value;
  }
  const coerced = maybeParseAsISOString(filter.value);
  if (coerced === filter.value) return filter.value;
  switch (option.sourceType) {
    case AttributeTypes.DATETIME:
      const split = coerced.split("T");
      return split[0] + "T" + split[1].replace(offsetRegex, "");
    case AttributeTypes.DATE:
      return coerced.split("T")[0];
    case AttributeTypes.TIME:
      return coerced.split("T")[1].replace(offsetRegex, "");
    case AttributeTypes.TIMESTAMP:
      return coerced;
    default:
      throw new Error("Unexpected attribute type");
  }
};

/*
  This hook is responsible for constructing the set of filters to be applied to a view.
  These filters may come from:

  1. The view's component's configuration and bindings.
  2. Manually applied filters by the end user.
  3. For _Managed Spaces_ filters are encoded into the querystring for the primary table
     of the managed space.

  Multiple filters may be applied to a given filterOption of the view. The combination
  of those filters may not be viable and no attempt is made to enforce what combinations of
  filters make sense. User interaction should always be given precedence over other filter
  sources. ie. If a user deletes a filter whose source is the querystring, that filter
  should be removed from the set applied to the view as well as the querystring.
*/
export default function useFilters({
  input,
  filterConfigs,
  filtersOptions,
  syncToLocationFilters
}: Params) {
  const { resourceQueryDescriptor } = useStableSpaceContext();

  const getFilterQsParams = useCallback(() => {
    if (!syncToLocationFilters || !resourceQueryDescriptor) return [];
    if (filtersOptions === undefined) return null;

    const cursor = resourceQueryDescriptor as Cursor;
    const cursorFilters =
      cursor.type === CursorType.FUNCTION
        ? (cursor as ListFunctionCursor).params.filters
        : cursor.filters;

    return cursorFilters.reduce<ViewFilter[]>(
      (acc, filter, i) =>
        filtersOptions.some(o => o.sourceName === filter.attribute)
          ? [
              ...acc,
              {
                sourceName: filter.attribute,
                operator: filter.operator,
                value: filter.value,
                __clientId: filter.__clientId || `qsFilter${i}`
              }
            ]
          : acc,
      []
    );
  }, [filtersOptions, resourceQueryDescriptor, syncToLocationFilters]);

  const getResolvedFilterConfigs = useCallback(() => {
    if (filtersOptions === undefined) return null;
    return filterConfigs
      .map((f, i) => resolveConfigFilter(f, input || {}, filtersOptions, i))
      .filter((f: ViewFilter | null) => f !== null) as ViewFilter[];
  }, [filterConfigs, input, filtersOptions]);

  const [filters, setFilters] = React.useState(
    (() => {
      const qsFilters = getFilterQsParams();
      const configFilters = getResolvedFilterConfigs();
      return qsFilters === null || configFilters === null
        ? undefined
        : dedupe(qsFilters.concat(configFilters));
    })()
  );

  const getUniqueFilterClientId = React.useCallback(
    function getClientId(prefix: string) {
      const ids = new Set((filters || []).map(f => f.__clientId));
      let __clientId = uniqueId(prefix);
      while (ids.has(__clientId)) {
        __clientId = uniqueId(prefix);
      }
      return __clientId;
    },
    [filters]
  );

  const [qsParamFilters, setQsParamFilters] = React.useState(getFilterQsParams());
  const [resolvedFilterConfigs, setResolvedFilterConfigs] = React.useState(
    getResolvedFilterConfigs()
  );
  const filter = React.useCallback(
    (nextFilters: ViewFilter[]) => setFilters(dedupe(nextFilters)),
    [setFilters]
  );

  // If filters options becomes undefined, reset any existing filters
  React.useEffect(() => {
    if (filtersOptions !== undefined) return;
    if (qsParamFilters !== null) setQsParamFilters(null);
    if (resolvedFilterConfigs !== null) setResolvedFilterConfigs(null);
    if (filters !== null) setFilters(undefined);
  }, [filtersOptions, qsParamFilters, resolvedFilterConfigs, filters]);

  // Apply filters from the qs
  React.useEffect(() => {
    if (!syncToLocationFilters) return;
    const nextQsParamFilters = getFilterQsParams();
    if (
      filtersOptions === undefined ||
      nextQsParamFilters === null ||
      isEqual(nextQsParamFilters, qsParamFilters)
    )
      return;

    setQsParamFilters(nextQsParamFilters);
    filter(
      (filters || [])
        .concat(getResolvedFilterConfigs() || [])
        .concat(nextQsParamFilters)
    );
  }, [
    syncToLocationFilters,
    getFilterQsParams,
    qsParamFilters,
    getResolvedFilterConfigs,
    filter,
    filters,
    filtersOptions
  ]);

  // Apply filters from component config
  React.useEffect(() => {
    const nextResolvedFilterConfigs = getResolvedFilterConfigs();
    if (
      filtersOptions === undefined ||
      nextResolvedFilterConfigs === null ||
      isEqual(nextResolvedFilterConfigs, resolvedFilterConfigs)
    )
      return;

    const removedFilterIds = (resolvedFilterConfigs || [])
      .filter(
        f => !nextResolvedFilterConfigs.some(nf => nf.__clientId === f.__clientId)
      )
      .map(f => f.__clientId);
    setResolvedFilterConfigs(nextResolvedFilterConfigs);
    filter(
      (filters || [])
        .concat(getFilterQsParams() || [])
        .concat(nextResolvedFilterConfigs)
        .filter(f => !removedFilterIds.includes(f.__clientId))
    );
  }, [
    getResolvedFilterConfigs,
    resolvedFilterConfigs,
    setResolvedFilterConfigs,
    getFilterQsParams,
    filter,
    filters,
    filtersOptions
  ]);

  // Prepare filters.
  const requestFilters: RequestFilter[] = React.useMemo(() => {
    return (filters || []).map(f => {
      const { __clientId, value, ...requestFilter } = f;
      return { value: maybeCoerceDateTime(f, filtersOptions || []), ...requestFilter };
    });
  }, [filters, filtersOptions]);

  const allConfigFiltersSatisfied = React.useMemo(() => {
    const sourceNames = (filters || []).map(f => f.sourceName);
    return filterConfigs.every(f => sourceNames.includes(f.attribute));
  }, [filters, filterConfigs]);

  return {
    ready: filters !== undefined,
    filters,
    filter,
    requestFilters,
    allConfigFiltersSatisfied,
    getUniqueFilterClientId
  };
}
