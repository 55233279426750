import { useMutation } from "@apollo/react-hooks";
import { MutationHookOptions } from "@apollo/react-hooks/lib/types";
import gql from "graphql-tag";

import { RelayNode } from "../../../../../types";

const QUERY = gql`
  mutation CommitLatestSpaceVersion($spaceId: ID!, $message: String!) {
    commitLatestSpaceVersion(spaceId: $spaceId, message: $message) {
      __typename
      ... on ScmErrorResult {
        message
      }
      ... on SpaceVersionNode {
        id
        scmVersion
        space {
          id
          slug
        }
      }
    }
  }
`;

export interface SpaceVersionNode extends RelayNode {
  __typename: "SpaceVersionNode";
  scmVersion: string;
  space: {
    id: string;
    slug: string;
  };
}

interface Data {
  commitLatestSpaceVersion:
    | SpaceVersionNode
    | { __typename: "ScmErrorResult"; message: string; code: number };
}

interface Variables {
  spaceId: string;
  message: string;
}

const useCommitLatestSpaceVersion = (
  options?: MutationHookOptions<Data, Variables>
) => {
  return useMutation<Data, Variables>(QUERY, options);
};

export default useCommitLatestSpaceVersion;
