import React from "react";

import { useQuery } from "@apollo/react-hooks";
import { Skeleton } from "antd";
import { useForm, FormProvider } from "react-hook-form";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";

import ScheduleIcon from "../../../assets/icons/schedule-icon.svg";
import SettingsIcon from "../../../assets/icons/settings-icon.svg";
import StateIcon from "../../../assets/icons/state-icon.svg";
import PageHeader from "../../../layouts/Header/Header";
import ButtonNew from "../../common/ButtonNew/ButtonNew";
import usePaths from "../../common/hooks/usePaths";
import Message from "../../common/Message";
import Navigation, { TabItem } from "../../common/Navigation";
import * as common from "../../common/StyledComponents";
import { assertNever } from "../../util/assertNever";
import HomeButton from "../common/HomeButton";

import ActionsAndEnvironments from "./ActionsAndEnvironments";
import General from "./General";
import * as AutomationBySlug from "./queries/AutomationBySlug";
import { AutomationNode } from "./queries/common";
import { useSaveAutomation, ValidationErrorMessage } from "./queries/SaveAutomation";
import { ConfigAction, ConfigState } from "./reducer";
import reducer, { INITIAL_STATE, ReducerActions } from "./reducer/reducer";
import { getSaveAutomationInput } from "./reducer/utils";
import Schedule from "./Schedule";

export const StyledNavigation = styled(Navigation)`
  flex-basis: 300px;
`;

const DoneButton = styled(ButtonNew)`
  margin-right: ${props => props.theme.spacersm};
`;

interface PathParams extends Record<string, string> {
  environment: string;
  slug: string;
}

const TabContent = styled.div<{ isActive: boolean }>`
  display: ${props => (props.isActive ? "block" : "none")};
`;

enum Tab {
  GENERAL = "general",
  SCHEDULE = "schedule",
  ACTIONS = "actions"
}

const tabs: TabItem[] = [
  {
    tab: Tab.GENERAL,
    title: "General settings",
    description: "Manage name, description, and other settings for this automation.",
    icon: SettingsIcon
  },
  {
    tab: Tab.SCHEDULE,
    title: "Schedule",
    description: "Manage schedule for your automation.",
    icon: ScheduleIcon
  },
  {
    tab: Tab.ACTIONS,
    title: "Actions and environments",
    description: "Manage actions and environments for your automation.",
    icon: StateIcon
  }
];

const ConfigForm = ({
  state,
  dispatch
}: {
  state: ConfigState;
  dispatch: React.Dispatch<ConfigAction>;
}) => {
  const [query] = useSearchParams();
  const navigate = useNavigate();
  const { state: navState } = useLocation();
  const { slug } = useParams<PathParams>();
  const tab = (query.get("tab") as Tab) || Tab.GENERAL;
  const { automationsapp_getEditAutomation, automations_getHome } = usePaths();
  const methods = useForm<ConfigState>();
  const { formState, handleSubmit, setError } = methods;

  // Loads the automation after a save occurs (the URL may or may not have changed as a result of the save)
  React.useEffect(() => {
    const node = navState as AutomationNode | undefined;
    if (!node?.id) return;

    dispatch({
      type: ReducerActions.LOAD_AUTOMATION,
      payload: navState as AutomationNode
    });
  }, [navState, dispatch]);

  // Loads the automation by the URL slug when there is no previous state
  const { loading } = useQuery<AutomationBySlug.Data, AutomationBySlug.Variables>(
    AutomationBySlug.QUERY,
    {
      variables: { slug: slug! },
      skip: !slug || !!state.id,
      onCompleted: data => {
        dispatch({
          type: ReducerActions.LOAD_AUTOMATION,
          payload: data.automationBySlug
        });
      }
    }
  );

  const [save, { loading: saving }] = useSaveAutomation({
    onCompleted: data => {
      switch (data.saveAutomation.__typename) {
        case "AutomationNode": {
          Message.success("Saved");
          const search = tab ? `tab=${tab}` : undefined;
          navigate(
            {
              pathname: automationsapp_getEditAutomation(data.saveAutomation.slug),
              search
            },
            { replace: true, state: data.saveAutomation }
          );
          break;
        }
        case "ValidationErrorResult":
          const errorMessages = data.saveAutomation.messages;
          errorMessages.forEach((errorMessage: ValidationErrorMessage) => {
            const field = errorMessage.index[0] as keyof ConfigState;
            setError(field, {
              type: "server",
              message: errorMessage.text
            });
          });
          break;
        default:
          return assertNever(data.saveAutomation);
      }
    }
  });

  const hasGeneralErrors = React.useMemo(() => {
    return Object.keys(formState.errors).some(key =>
      ["name", "description", "icon", "color"].includes(key)
    );
  }, [formState]);

  const hasScheduleErrors = React.useMemo(() => {
    return Object.keys(formState.errors).some(key =>
      [
        "intervalPeriod",
        "frequency",
        "daysOfWeek",
        "daysOfMonth",
        "triggerTime",
        "startAt",
        "endAt"
      ].includes(key)
    );
  }, [formState]);

  const hasActionErrors = React.useMemo(() => {
    return Object.keys(formState.errors).some(key =>
      ["functionId", "inputParameters", "environmentIds"].includes(key)
    );
  }, [formState]);

  const _onSubmit = React.useCallback(
    _data => {
      // using state instead of _data only because some fields are rendered
      // conditionally and may not be initialized in the formState
      const automation = getSaveAutomationInput(state);
      save({ variables: { automation } });
    },
    [state, save]
  );

  const onSubmit = handleSubmit(_onSubmit);

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <PageHeader
          isInline
          homeButton={<HomeButton />}
          key="automations-config"
          title={state.name}
          controls={
            <>
              {slug && (
                <DoneButton
                  to={automations_getHome() + "?selectedAutomation=" + slug}
                  type="noFill"
                >
                  Done
                </DoneButton>
              )}
              <ButtonNew htmlType="submit" type="brand" loading={saving}>
                Save
              </ButtonNew>
            </>
          }
        />
        {loading ? (
          <Skeleton />
        ) : (
          <common.ConfigContainer>
            <StyledNavigation
              tabs={tabs}
              activeTab={tab}
              errors={{
                [Tab.GENERAL]: hasGeneralErrors,
                [Tab.SCHEDULE]: hasScheduleErrors,
                [Tab.ACTIONS]: hasActionErrors
              }}
            />
            <common.Content>
              <TabContent isActive={tab === Tab.GENERAL}>
                <General state={state} dispatch={dispatch} />
              </TabContent>
              <TabContent isActive={tab === Tab.SCHEDULE}>
                <Schedule state={state} dispatch={dispatch} />
              </TabContent>
              <TabContent isActive={tab === Tab.ACTIONS}>
                <ActionsAndEnvironments state={state} dispatch={dispatch} />
              </TabContent>
            </common.Content>
          </common.ConfigContainer>
        )}
      </form>
    </FormProvider>
  );
};

export const Config = () => {
  const [state, dispatch] = React.useReducer(reducer, INITIAL_STATE);
  // remount form when automation id changes
  return <ConfigForm key={state.id} state={state} dispatch={dispatch} />;
};
