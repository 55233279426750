import Icon from "../../../../../assets/spaceicons/custom.svg";
import {
  SpaceComponentHostType,
  SpaceComponentPackage,
  SpaceComponentTags
} from "../../../../../types";
import { DEFAULT_SIZES } from "../../../layout/constants";
import {
  DEFAULT_ELEMENT_LAYOUT_OPTIONS,
  ElementLayout,
  LayoutUnit
} from "../../../layout/util";
import { commonComponentReducer } from "../../../SpaceConfig/SpaceConfigContext/useSpaceConfig/reducer";

import Config, { errorSelector } from "./Config";
import SpaceIFrame from "./SpaceIFrame";
import {
  SpaceIFrameComponent,
  SpaceIFrameComponentConfigState,
  SpaceIFrameProperties
} from "./types";

const pkg: SpaceComponentPackage<
  SpaceIFrameProperties,
  SpaceIFrameComponent,
  SpaceIFrameComponentConfigState
> = {
  type: "IFRAME",
  displayName: "IFrame",
  Icon,
  allowedHosts: new Set<SpaceComponentHostType>([
    "CARD_LIST",
    "DETAIL",
    "TABLE",
    "FLEX_BOX"
  ]),
  Component: SpaceIFrame,
  ConfigurationComponent: Config,
  ensureComponent: c => c,
  componentConfigReducer: commonComponentReducer,
  elementLayoutOptions: {
    ...DEFAULT_ELEMENT_LAYOUT_OPTIONS,
    width: [LayoutUnit.PERCENTAGE, LayoutUnit.PIXEL],
    height: [LayoutUnit.PERCENTAGE, LayoutUnit.PIXEL]
  },
  defaultElementLayout: new ElementLayout({
    snapToGrid: true,
    width: DEFAULT_SIZES.STANDARD.width,
    height: DEFAULT_SIZES.STANDARD.height
  }),
  getSchema: () => [],
  allowSelfBinding: false,
  allowAncestorBinding: true,
  isHeadless: false,
  errorSelector,
  isContainer: false,
  tags: [SpaceComponentTags.Content]
};

export default pkg;
