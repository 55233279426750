import { useQuery } from "@apollo/react-hooks";
import { QueryHookOptions } from "@apollo/react-hooks/lib/types";
import gql from "graphql-tag";

export const OLDEST_TASKS_QUERY = gql`
  query OldestTasks($queueId: ID!, $first: Int!) {
    oldestTasks(queueId: $queueId, first: $first) {
      id
      createdAt
      updatedAt
      transitionedAt
      title
    }
  }
`;

export interface TaskNode {
  id: string;
  createdAt: string;
  updatedAt: string;
  transitionedAt: string;
  title: string;
}

interface Variables {
  queueId: string;
  first: number;
}

interface Data {
  oldestTasks: TaskNode[];
}

export const useOldestTasks = (options?: QueryHookOptions<Data, Variables>) => {
  const variables = options?.variables;
  return useQuery<Data, Variables>(OLDEST_TASKS_QUERY, {
    ...options,
    variables: variables
  });
};
