import React, { ChangeEvent } from "react";

import { Icon } from "antd";

import Button from "../../../../common/Button/Button";
import { Container } from "../common/CloudUploader/styledComponents";

interface Props {
  onChange?: (file: File | null) => void;
}

export default function FileInput({ onChange = () => null }: Props) {
  const [fileName, setFileName] = React.useState<string | null | undefined>();

  const _onChange = React.useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.length ? e.target.files[0] : null;
      setFileName(file?.name);
      onChange(file);
    },
    [onChange]
  );

  const inputRef = React.useRef<HTMLInputElement>(null);
  return (
    <Container>
      <div className="visualUploader">
        <input
          className="custom-file-input"
          hidden
          multiple={false}
          onChange={_onChange}
          ref={inputRef}
          type="file"
        />
        <Button onClick={() => inputRef.current?.click()}>
          <Icon type="upload" />
          Choose a file
        </Button>
        {!!fileName && <p>{fileName}</p>}
      </div>
    </Container>
  );
}
