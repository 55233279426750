import { Binding } from "../../../../../types";
import { SpaceDefinition } from "../../../types";
import { ComponentNode } from "../../RenderTreeContext";
import { getColumnsSchema } from "../common/ColumnListManager/getColumnsOutputShape";

export function getSchema(
  node: ComponentNode,
  spaces: Map<string, SpaceDefinition>
): Binding[] {
  return getColumnsSchema(node, spaces);
}
