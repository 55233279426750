import React, { useMemo } from "react";

import { get } from "lodash";

import { SpaceComponentState } from "..";
import { SpaceComponentObject, DataValue } from "../../../../../types";
import debug from "../../../../util/debug";
import { findInputBindings } from "../../../util";
import { useRenderTreeContext, ComponentNode } from "../../RenderTreeContext";
import { useStableSpaceContext } from "../../SpaceContext";
import { SUPERFICIAL_COMPONENT_TYPES, PERMANENT_COMPONENT_TYPES } from "../constants";
import { StateTree } from "../SpaceComponent";
import { useInputState } from "../util/util";

import ComponentPathContext, { useNextComponentPath } from "./ComponentPathContext";

export interface ComponentStateContextValue {
  // The ComponentNode from the ComponentGraph
  componentNode: ComponentNode | undefined;
  // The ComponentNodes's bound inputs from the StateTree
  input: Record<string, DataValue> | null;
  // The ComponentNode's output into the StateTree
  output: SpaceComponentState | null;
  // The user defined variables present for a space or sub space
  variables: Record<string, any> | null;
  // Merges state into the ComponentNode's output in the StateTree
  updateOutput: (state: Object) => void;
  // Dynamically register an input binding for this ComponentNode
  registerBinding: (bindingPath: string) => void;
  // Unregister a dynamically registered input binding
  unregisterBinding: (bindingPath: string) => void;
  // Recursively sets this componenent and all its descendant components' output to null
  // Optionally specify a childPath to start recursion, ie. <componentPath>.<childPath>
  recursivelyClearOutput: (childPath?: string) => void;
  setVariable: (variableName: string, value: DataValue) => void;
}

export const initialContext = {
  key: null,
  componentNode: undefined,
  input: null,
  output: null,
  variables: null,
  updateOutput: (_state: Object) => {},
  registerBinding: (_bindingPath: string) => {},
  unregisterBinding: (_bindPath: string) => {},
  recursivelyClearOutput: (_childPath?: string) => {},
  setVariable: (_variableName: string, _value: DataValue) => {}
};

const ComponentStateContext =
  React.createContext<ComponentStateContextValue>(initialContext);

export default ComponentStateContext;

export const useComponentStateContext = () => React.useContext(ComponentStateContext);

// StateTreeContext is a seperate context since it updates with each state change.
// Use ComponentStateContext instead of it to access the StateTree.
export const StateTreeContext = React.createContext({} as Record<string, any>);
export function RootComponentStateContainer({
  children
}: {
  children: React.ReactNode;
}) {
  const { stateTree: rootStateTree, variables, setVariable } = useRenderTreeContext();
  const value = useMemo(() => {
    return {
      ...initialContext,
      setVariable: (_variableName: string, _value: DataValue) => {
        setVariable("", _variableName, _value);
      }
    };
  }, [setVariable]);

  const scopedVariables = useMemo(() => {
    const scoped = variables.get("");
    if (!scoped) return {};
    return Object.fromEntries(scoped.entries());
  }, [variables]);
  const stateTree = React.useMemo(() => {
    const stateTree = rootStateTree;

    const stateTreeProxy = new Proxy(stateTree, {
      get(target, prop, receiver) {
        if (prop === "variables") {
          return scopedVariables;
        }
        return Reflect.get(target, prop, receiver);
      }
    });

    return stateTreeProxy;
  }, [rootStateTree, scopedVariables]);

  return (
    <ComponentStateContext.Provider value={value}>
      <ParamsContext.Provider value={rootStateTree.params}>
        <StateTreeContext.Provider value={stateTree}>
          {children}
        </StateTreeContext.Provider>
      </ParamsContext.Provider>
    </ComponentStateContext.Provider>
  );
}

// Params proxy
const ParamsContext = React.createContext({});

export function ComponentStateContainer({
  component,
  collectionKey,
  index,
  itemKey,
  children
}: {
  component: SpaceComponentObject;
  collectionKey?: string;
  index?: number;
  itemKey?: string;
  children: React.ReactNode;
}) {
  debug("Start render ComponentStateContainer");
  const [bindingRegistrationState, bindingRegistrationDispatch] = React.useReducer(
    bindingRegistrationReducer,
    initialBindingRegistrationState
  );
  const isSuperficialComponent = SUPERFICIAL_COMPONENT_TYPES.includes(component.type);
  const isPermanentComponent = PERMANENT_COMPONENT_TYPES.includes(component.type);
  const isSubSpace = component.type === "SUB_SPACE";
  const componentPath = useNextComponentPath(component, collectionKey, index, itemKey);
  const { variables } = useRenderTreeContext();
  const {
    findNode,
    updateOutput,
    recursivelyClearOutput,
    registerNode,
    unregisterNode,
    setVariable
  } = useRenderTreeContext();

  const stateTree = useStateTree(component, componentPath);
  const registerBinding = React.useCallback(
    function registerBinding(binding: string) {
      bindingRegistrationDispatch({
        type: "REGISTER_BINDING",
        payload: { binding }
      });
    },
    [bindingRegistrationDispatch]
  );

  const unregisterBinding = React.useCallback(
    function unregisterBinding(binding: string) {
      bindingRegistrationDispatch({
        type: "UNREGISTER_BINDING",
        payload: { binding }
      });
    },
    [bindingRegistrationDispatch]
  );

  const nextComponentStateContext = useNextComponentStateContext(
    component,
    componentPath,
    stateTree,
    bindingRegistrationState,
    variables.get(componentPath),
    registerBinding,
    unregisterBinding,
    findNode,
    updateOutput,
    recursivelyClearOutput,
    setVariable
  );

  React.useLayoutEffect(() => {
    if (isSuperficialComponent) return;
    registerNode(componentPath);
    return () => {
      if (isSuperficialComponent || isPermanentComponent) return;
      unregisterNode(componentPath);
    };
    // eslint-disable-next-line
  }, [componentPath]);

  let MaybeParamsContextProvider, paramsContextProps;
  if (isSubSpace) {
    MaybeParamsContextProvider = ParamsContext.Provider;
    paramsContextProps = { value: nextComponentStateContext.output?.params };
  } else {
    MaybeParamsContextProvider = React.Fragment as any;
    paramsContextProps = {};
  }

  debug("Finish render ComponentStateContainer");

  return (
    <ComponentPathContext.Provider value={componentPath}>
      <StateTreeContext.Provider value={stateTree}>
        <MaybeParamsContextProvider {...paramsContextProps}>
          <ComponentStateContext.Provider value={nextComponentStateContext}>
            {children}
          </ComponentStateContext.Provider>
        </MaybeParamsContextProvider>
      </StateTreeContext.Provider>
    </ComponentPathContext.Provider>
  );
}

function useNextComponentStateContext(
  component: SpaceComponentObject,
  componentPath: string,
  stateTree: StateTree,
  registeredBindings: Set<string>,
  variables: Map<string, any> | undefined,
  registerBinding: (binding: string) => void,
  unregisterBinding: (binding: string) => void,
  // TODO fix anys
  findNode: any,
  rootUpdateOutput: any,
  rootRecursivelyClearOutput: any,
  setVariable: any
) {
  debug("Rendering useNextComponentStateContext", componentPath);
  const isSubSpace = component.type === "SUB_SPACE";
  const { findSpaceComponentPackage } = useStableSpaceContext();
  const { setVariable: parentSetVariable } = useComponentStateContext();

  const componentNode = React.useMemo(
    () => findNode(componentPath),
    [findNode, componentPath]
  );
  // HACK: If this is a pseudo component need to grab parent component's
  // properties to calculate input selection. Psuedo components don't have
  // properties and any binding config they depend on is in the parent.
  const pkg = findSpaceComponentPackage(component.type);
  component = pkg?.isPseudoComponent
    ? componentNode?.parent?.component || component
    : component;

  const bindings = React.useMemo(() => {
    const bindings = findInputBindings(component.properties);
    return new Set([...bindings, ...registeredBindings]);
  }, [component.properties, registeredBindings]);

  const input = useInputState(stateTree, bindings);
  // NOTE: `componentNode` is a mutable object and will not change between
  //       renders. `updateOutput` similarly should not break memomization.
  //       Components often have `updateOutput` as useEffect a dependency
  //       and if it changes too frequently they will set output too frequently.
  const updateOutput = React.useCallback(
    function updateOutput(state: Record<string, any>) {
      rootUpdateOutput(componentPath, state);
    },
    [rootUpdateOutput, componentPath]
  );

  const recursivelyClearOutput = React.useCallback(
    function recursivelyClearOutput(childPath?: string) {
      rootRecursivelyClearOutput(
        childPath ? `${componentPath}.${childPath}` : componentPath
      );
    },
    [componentPath, rootRecursivelyClearOutput]
  );
  const output = componentNode?.output || null;
  const scopedSetVariable = React.useCallback(
    function scopedSetVariable(name: string, value: any) {
      isSubSpace
        ? setVariable(componentPath, name, value)
        : parentSetVariable(name, value);
    },
    [isSubSpace, componentPath, setVariable, parentSetVariable]
  );

  const value = React.useMemo(
    () => ({
      componentNode,
      input,
      output,
      variables: variables ? Object.fromEntries(variables.entries()) : {},
      setVariable: scopedSetVariable,
      updateOutput,
      recursivelyClearOutput,
      registerBinding,
      unregisterBinding
    }),
    [
      componentNode,
      input,
      output,
      variables,
      updateOutput,
      recursivelyClearOutput,
      registerBinding,
      unregisterBinding,
      scopedSetVariable
    ]
  );
  return value;
}

const initialBindingRegistrationState = new Set<string>();
function bindingRegistrationReducer(
  state: typeof initialBindingRegistrationState,
  action:
    | { type: "REGISTER_BINDING"; payload: { binding: string } }
    | { type: "UNREGISTER_BINDING"; payload: { binding: string } }
) {
  switch (action.type) {
    case "REGISTER_BINDING": {
      if (state.has(action.payload.binding)) return state;
      const nextState = new Set(Array.from(state));
      nextState.add(action.payload.binding);
      return nextState;
    }

    case "UNREGISTER_BINDING": {
      if (!state.has(action.payload.binding)) return state;
      const nextState = new Set(Array.from(state));
      nextState.delete(action.payload.binding);
      return nextState;
    }

    default:
      throw new Error(`Unexpected action: ${(action as any).type}`);
  }
}

function useStateTree(component: SpaceComponentObject, componentPath: string) {
  const isSubSpace = component.type === "SUB_SPACE";
  const { stateTree: rootStateTree, variables } = useRenderTreeContext();
  const parentStateTree = React.useContext(StateTreeContext);
  const scopedVariables = variables.get(componentPath);
  const params = React.useContext(ParamsContext);
  const stateTree = React.useMemo(() => {
    const isSuperficialComponent = SUPERFICIAL_COMPONENT_TYPES.includes(component.type);
    const stateTree = Object.keys(parentStateTree).length
      ? parentStateTree
      : rootStateTree;

    if (isSuperficialComponent) return stateTree;

    const ownState = get(stateTree, componentPath, {});

    const stateTreeProxy = new Proxy(stateTree, {
      get(target, prop, receiver) {
        if (prop === component.slug || prop === component.type.toLowerCase()) {
          return ownState;
        } else if (prop === "params") {
          return params;
        }
        // Support sub space global variables
        if (isSubSpace && prop === "variables") {
          return {
            ...stateTree.variables!,
            ...Object.fromEntries((scopedVariables || []).entries())
          };
        }
        return Reflect.get(target, prop, receiver);
      }
    });

    return stateTreeProxy;
  }, [
    rootStateTree,
    parentStateTree,
    params,
    component.slug,
    component.type,
    componentPath,
    scopedVariables,
    isSubSpace
  ]);
  return stateTree;
}

export function useChildComponentInput(component: SpaceComponentObject) {
  const componentPath = useNextComponentPath(component);
  const stateTree = useStateTree(component, componentPath);

  const bindings = React.useMemo(() => {
    return findInputBindings(component.properties);
  }, [component.properties]);
  const childInput = useInputState(stateTree, bindings);

  return childInput;
}
