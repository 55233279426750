import { Icon, Progress } from "antd";
import styled from "styled-components";

import { colors } from "../../../../cssConstants";
import { B2, B3, H2 } from "../../../common/StyledComponents";

export const StatValue = styled(B3)`
  line-height: 21px;
`;

export const StyledProgress = styled(Progress)`
  .ant-progress-inner {
    background-color: ${colors.newContainerPrimaryTint};
  }

  .ant-progress-success-bg,
  .ant-progress-bg {
    border-radius: 0;
  }
`;

export const StyledIcon = styled(Icon)`
  font-size: ${props => props.theme.mediumFontSize};
  color: ${props => props.theme.warningColor};
  vertical-align: middle;
  cursor: help;
`;

export const Main = styled.div`
  display: flex;
`;

export const StateLabel = styled.div`
  display: flex;
  gap: 12px;
  align-items: baseline;
`;

export const TaskCount = styled(StatValue)`
  text-align: right;
  padding: 0 12px;
`;

export const Column = styled.div<{ grow?: number }>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  ${props => (props.grow ? "flex-grow: " + props.grow : "")}
`;

export const StateProgress = styled(Column)`
  flex-grow: 1;
`;

export const TaskData = styled.div`
  display: flex;
  align-items: baseline;
`;

export const Headline = styled(H2)`
  margin-bottom: 0;
`;

export const Dash = styled.div`
  width: 8px;
  height: 1px;
  background-color: black;
`;

export const DashContainer = styled.div`
  display: flex;
  height: 21px;
  justify-content: right;
  align-items: center;
`;

export const CenterMessage = styled(B2)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: ${props => props.theme.surfaceSecondary};
`;

export const BlockWrapper = styled.div`
  height: 21px;
  display: flex;
  align-items: center;
`;

export const Block = styled.div`
  background-color: ${colors.blockedOut};
  border-radius: ${props => props.theme.containerPrimaryBorderRadius};
  height: 16px;
  width: 100%;
`;

export const PremiumBlockHeader = styled(Block)`
  height: 36px;
`;
