import styled from "styled-components";

import { H6 } from "../../../common/StyledComponents";
import { Column, StatValue } from "../common/styledComponents";

export const DurationColumn = styled(Column)`
  min-width: 140px;
  width: 20%;
  padding-right: 16px;
`;

export const AverageTimeInStateDuration = styled(StatValue)`
  text-align: right;
`;

export const HalfContainer = styled.div`
  display: flex;
  width: 50%;
`;

export const AverageTimeInStateHeader = styled(H6)`
  text-align: right;
`;

export const LockedAverageTimeInStateHeader = styled(H6)`
  text-align: left;
`;

export const AverageTimeContainer = styled.div`
  min-height: 21px;
`;
