import React, { Dispatch, useCallback, useMemo } from "react";

import { Button } from "antd";
import { useQuery } from "react-apollo";
import { useFormContext, Controller } from "react-hook-form";
import { useSearchParams } from "react-router-dom";

import PipelineTeaser from "../../../../assets/pipeline_teaser.png";
import EnvironmentSelector from "../../../common/EnvironmentSelector";
import { FunctionEditorModal } from "../../../common/FunctionEditor/FunctionEditorModal/Modal";
import { SupportedIntegration } from "../../../common/FunctionEditor/support";
import { useGetDataSources } from "../../../common/FunctionPicker/queries";
import useSearchParamsUpdater from "../../../common/hooks/useSearchParamsUpdater";
import { ModalNew } from "../../../common/Modal";
import Spacer from "../../../common/Spacer";
import { Hr, B3, ErrorField } from "../../../common/StyledComponents";
import ThemeContainer, { Theme } from "../../../common/ThemeContainer/ThemeContainer";
import { ConfigAction, ConfigState, ReducerActions } from "../reducer";
import * as styled from "../styledComponents";

import ActionPicker from "./ActionPicker";
import { FunctionNodeWithDataSource } from "./ActionPicker/ActionPicker";
import { SELECTED_FUNCTION_QUERY } from "./ActionPicker/queries";

const SHOW_PIPELINES_MODAL = "showPipelinesModal";
const SHOW_EDIT_FUNCTION_MODAL = "showEditFunctionModal";

interface Props {
  state: ConfigState;
  dispatch: Dispatch<ConfigAction>;
}
const ActionsAndEnvironments = ({ state, dispatch }: Props) => {
  const [searchParams] = useSearchParams();
  const showPipelineModal = searchParams.get(SHOW_PIPELINES_MODAL) === "true";
  const showEditFunctionModal = searchParams.get(SHOW_EDIT_FUNCTION_MODAL) === "true";

  const updateParams = useSearchParamsUpdater();
  const setShowPipelineModal = useCallback(
    (show: boolean) => {
      updateParams({ [SHOW_PIPELINES_MODAL]: show ? "true" : undefined });
    },
    [updateParams]
  );

  const setShowEditFunctionModal = useCallback(
    (show: boolean) => {
      updateParams({ [SHOW_EDIT_FUNCTION_MODAL]: show ? "true" : undefined });
    },
    [updateParams]
  );

  const { control, formState } = useFormContext();
  const handleEnvironmentsChange = React.useCallback(
    value =>
      dispatch({
        type: ReducerActions.UPDATE_SETTINGS,
        payload: {
          environmentIds: value
        }
      }),
    [dispatch]
  );

  const { data: dataSources } = useGetDataSources();
  const pipelinesDataSource = useMemo(() => {
    return dataSources?.allDataSources.edges.find(
      e => e.node.integration === SupportedIntegration.PIPELINES
    )?.node;
  }, [dataSources]);

  const handleFunctionChange = React.useCallback(
    value =>
      dispatch({
        type: ReducerActions.UPDATE_SETTINGS,
        payload: {
          functionId: value
        }
      }),
    [dispatch]
  );

  const handleParameterValuesChange = React.useCallback(
    value =>
      dispatch({
        type: ReducerActions.UPDATE_SETTINGS,
        payload: {
          inputParameters: value
        }
      }),
    [dispatch]
  );

  const { data, loading, refetch } = useQuery<
    { function: FunctionNodeWithDataSource | undefined },
    { id: string | undefined }
  >(SELECTED_FUNCTION_QUERY, {
    skip: !state.functionId,
    variables: {
      id: state.functionId
    }
  });

  const selectedFunction = data?.function;

  const isPipelineFunction =
    selectedFunction?.dataSource.integration === SupportedIntegration.PIPELINES;

  return (
    <div>
      <styled.Title>Actions and environments</styled.Title>
      <styled.Section>
        <styled.SectionTitle>Actions</styled.SectionTitle>
        <styled.SectionDescription>
          What happens when this automation is run
        </styled.SectionDescription>
        <ActionPicker
          selectedFunction={selectedFunction}
          loading={loading}
          inputParameters={state.inputParameters}
          onChange={handleFunctionChange}
          onParameterValuesChange={handleParameterValuesChange}
          setShowEditFunction={setShowEditFunctionModal}
        />
        {!isPipelineFunction && (
          <styled.ButtonContainer>
            <Button
              icon="plus"
              size="small"
              onClick={() => setShowPipelineModal(true)}
            />
          </styled.ButtonContainer>
        )}
      </styled.Section>
      <Hr />
      <styled.Section>
        <styled.SectionTitle>Environments</styled.SectionTitle>
        <styled.SectionDescription>
          Which environments this automation will run in
        </styled.SectionDescription>
        {formState.errors?.environmentIds && (
          <ErrorField>{formState.errors.environmentIds.message}</ErrorField>
        )}
        <Controller
          rules={{
            validate: value => {
              return value.size > 0 || "You must select at least one environment.";
            }
          }}
          render={({ field }) => (
            <EnvironmentSelector
              {...field}
              value={state.environmentIds}
              onChange={value => {
                field.onChange(value);
                handleEnvironmentsChange(value);
              }}
            />
          )}
          control={control}
          name="environmentIds"
          defaultValue={state.environmentIds}
        />
      </styled.Section>

      {/* TODO: Double wrapped ThemeContainer */}
      {showPipelineModal &&
        (!!pipelinesDataSource ? (
          <ThemeContainer theme={Theme.Default}>
            <ThemeContainer theme={Theme.Dark}>
              <styled.FullScreen>
                <FunctionEditorModal
                  key="editorKey"
                  onClone={() => null}
                  onClose={() => setShowPipelineModal(false)}
                  dataSourceId={pipelinesDataSource.id}
                  initializePipeline={
                    state.functionId
                      ? {
                          functionId: state.functionId
                        }
                      : undefined
                  }
                  onSave={func => handleFunctionChange(func.id)}
                />
              </styled.FullScreen>
            </ThemeContainer>
          </ThemeContainer>
        ) : (
          <ModalNew
            title="Pipelines coming soon!"
            okText="Close"
            visible={showPipelineModal}
            onOk={() => setShowPipelineModal(false)}
            width={441}
          >
            <img src={PipelineTeaser} alt="Pipeline of functions" width="399px" />
            <Spacer size="md" />
            <B3>
              Create multi-step automations with Pipelines. Execute database queries,
              API calls, and custom JavaScript to design any workflow you need.
              Pipelines will also support if/then logic and loops!
            </B3>
          </ModalNew>
        ))}

      {showEditFunctionModal && (
        <>
          {/* TODO: Double wrapped ThemeContainer */}
          <ThemeContainer theme={Theme.Default}>
            <ThemeContainer theme={Theme.Dark}>
              <styled.FullScreen>
                <FunctionEditorModal
                  key="editorKey"
                  onClone={() => null}
                  onClose={() => {
                    refetch();
                    setShowEditFunctionModal(false);
                  }}
                  dataSourceId={selectedFunction?.dataSource.id}
                  functionId={selectedFunction?.id}
                />
              </styled.FullScreen>
            </ThemeContainer>
          </ThemeContainer>
        </>
      )}
    </div>
  );
};

export default ActionsAndEnvironments;
