// _pseudoContainer is a mapping of components to their
// immediate child pseudo component and the slug of that
// pseudo component.  It's used to create pseudo components
// for container components in the `fromComponent` methods.
import { SpaceComponentType, StableSpace } from "../../types";

type PseudoContainerType = [SpaceComponentType, string];
export const PSEUDO_CONTAINER_MAP: Record<
  SpaceComponentType,
  PseudoContainerType[] | []
> = {
  ACCORDION: [],
  TABLE: [["ROW", "rows"]],
  CARD_LIST: [["CARD", "cards"]],
  DROPDOWN: [["OPTION", "options"]],
  TAG_SELECTOR: [["OPTION", "options"]],
  CALCULATED_FIELD: [],
  CARD: [],
  IFRAME: [],
  CHART: [],
  CHECKBOX: [],
  CONTEXT_PARAM: [],
  CUSTOM_FIELD: [],
  DATA_VIEWER: [],
  DATE_TIME_PICKER: [],
  DETAIL: [],
  DOCUMENT_VIEWER: [],
  DYNAMIC_FUNCTION: [],
  ENVIRONMENT: [],
  FIELDSET: [],
  FILE_PICKER: [],
  FUNCTION_BULK_IMPORT: [["REPEATEDITEM", "repeateditem"]],
  FUNCTION_BUTTON: [["FIELDSET", "fieldset"]],
  FUNCTION_FORM: [["FIELDSET", "fieldset"]],
  FUNCTION_HEADLESS: [["FIELDSET", "fieldset"]],
  FUNCTION_MODAL_FORM: [["FIELDSET", "fieldset"]],
  CLOUD_UPLOADER: [["FIELDSET", "fieldset"]],
  HEADER: [],
  IMAGE: [],
  JSON_INPUT: [],
  LINK: [],
  MODAL: [],
  OPTION: [],
  PARAMS: [],
  PROGRESS: [],
  RADIO_BUTTON: [],
  REPEATEDITEM: [],
  ROW: [],
  S3_UPLOADER: [],
  FLEX_BOX: [],
  STAT: [],
  TABS: [],
  TEXT_AREA: [],
  USER: [],
  SUB_SPACE: [],
  VIDEO: [],
  VIEWLESS_IMAGE: [],
  VOID: []
};

export const EMPTY_SPACE: StableSpace = Object.freeze({
  name: "",
  slug: "",
  id: "",
  branchCount: 0,
  scmIsCurrent: false,
  scmStatus: null,
  scmSyncStatus: null,
  version: { id: "", components: [], subSpaces: [], scmVersion: null }
});
