import React, { useEffect, useLayoutEffect, useRef } from "react";

import { uniqueId } from "lodash";
import styled from "styled-components";

import mouseCoords from "../../../util/mouseCoords";
import { useCanvasViewportContext } from "../Canvas/CanvasViewportContext";
import {
  BOUNDARY_PROXIMITY_AUTOSCROLL_THRESHOLD,
  EDGE_DRAG_ADJUST
} from "../constants";
import { addPoints, subtractPoints } from "../util";

const DRAG_THRESHOLD = 3;
interface DraggableProps {
  children?: React.ReactNode;
  className?: string;
  disable?: boolean;
  transferDragStart?: DOMPoint;
  onClick?: (event: React.MouseEvent) => void;
  onDragStart: (coords: DOMPoint) => void;
  onDrag?: (coords: DOMPoint) => void;
  onDragEnd?: (coords: DOMPoint) => void;
  onTransfer?: () => void;
}

function detectDrag(a: DOMPoint, b: DOMPoint) {
  const diff = subtractPoints(a, b);
  return Math.abs(diff.x) >= DRAG_THRESHOLD || Math.abs(diff.y) >= DRAG_THRESHOLD;
}

const NOOP = () => {};

const DraggableRoot = styled.div`
  width: 100%;
  height: 100%;
`;

export default function Draggable({
  children,
  transferDragStart,
  disable,
  onDragStart,
  onDrag = NOOP,
  onDragEnd = NOOP,
  onTransfer = NOOP
}: DraggableProps) {
  const ref = useRef<null | HTMLDivElement>(null);
  const draggableId = useRef<string>(uniqueId("draggable"));
  const draggableNode = useRef<DraggableNode>();
  const { viewportRect, canvasRect } = useCanvasViewportContext();
  const top = viewportRect.top;
  const bottom = viewportRect.bottom;
  const scrolledToTop = viewportRect.y === canvasRect.y;

  // Keep a DraggableNode instance for the life time of the component
  useLayoutEffect(
    () => {
      draggableNode.current = new DraggableNode(
        ref.current!,
        draggableId.current,
        !!disable,
        top,
        bottom,
        scrolledToTop,
        onDragStart,
        onDrag,
        onDragEnd,
        onTransfer,
        transferDragStart
      );
      return () => {
        draggableNode.current?.destroy();
      };
    },
    // eslint-disable-next-line
    []
  );

  // Mutate the draggable node as its args change
  useEffect(() => {
    if (!draggableNode.current) return;
    draggableNode.current.disable = !!disable;
    draggableNode.current.top = top;
    draggableNode.current.bottom = bottom;
    draggableNode.current.scrolledToTop = scrolledToTop;
    draggableNode.current.onDragStart = onDragStart;
    draggableNode.current.onDrag = onDrag;
    draggableNode.current.onDragEnd = onDragEnd;
  }, [
    disable,
    top,
    bottom,
    scrolledToTop,
    transferDragStart,
    onDragStart,
    onDrag,
    onDragEnd
  ]);

  return (
    <DraggableRoot
      ref={ref}
      data-draggable-id={draggableId.current}
      data-draggable-disabled={disable}
    >
      {children}
    </DraggableRoot>
  );
}

type DragEventHandler = (coords: DOMPoint) => void;
class DraggableNode {
  node: HTMLElement;
  draggableId: string;
  disable: boolean;
  top: number;
  bottom: number;
  scrolledToTop: boolean;
  isDragging: boolean;
  startCoords: DOMPoint;
  currCoords: DOMPoint;
  adjustment: DOMPoint;
  monitorHandle: any;
  onDragStart: DragEventHandler;
  onDrag: DragEventHandler;
  onDragEnd: DragEventHandler;

  constructor(
    node: HTMLElement,
    draggableId: string,
    disable: boolean,
    top: number,
    bottom: number,
    scrolledToTop: boolean,
    onDragStart: DragEventHandler,
    onDrag: DragEventHandler,
    onDragEnd: DragEventHandler,
    onTransfer: () => void,
    transferDragStart?: DOMPoint
  ) {
    this.isDragging = false;
    this.node = node;
    this.draggableId = draggableId;
    this.disable = disable;
    this.top = top;
    this.bottom = bottom;
    this.scrolledToTop = scrolledToTop;
    this.onDragStart = onDragStart;
    this.onDrag = onDrag;
    this.onDragEnd = onDragEnd;

    this.startCoords = new DOMPoint();
    this.currCoords = new DOMPoint();
    this.adjustment = new DOMPoint();

    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
    this.handleDragEnd = this.handleDragEnd.bind(this);
    this.node.addEventListener("mousedown", this.handleMouseDown);

    if (transferDragStart) {
      this.handleDragStart(transferDragStart);
      onTransfer();
    }
  }

  destroy() {
    document.removeEventListener("mouseup", this.handleDragEnd);
    this.node.removeEventListener("mousedown", this.handleMouseDown);
    this.stopCoordsMonitor();
  }

  handleMouseDown(_event: MouseEvent) {
    if (this.disable) return;
    const event: MouseEvent & { draggableId: string } = _event as MouseEvent & {
      draggableId: string;
    };
    if (event.button !== 0) return;
    // Don't trigger ancestor draggables
    const closestDraggable = (event.target as HTMLElement).closest(
      `[data-draggable-id=${this.draggableId}]`
    ) as HTMLElement;
    if (
      (event.draggableId && event.draggableId !== this.draggableId) ||
      (closestDraggable?.dataset?.draggableDisabled !== "true" &&
        closestDraggable?.dataset?.draggableId !== this.draggableId)
    ) {
      return;
    }

    event.draggableId = this.draggableId;

    this.handleDragStart(new DOMPoint(event.pageX, event.pageY));
  }

  startCoordsMonitor() {
    this.monitorHandle = setInterval(() => {
      if (!this.isDragging && !detectDrag(this.currCoords, mouseCoords.page)) return;
      this.handleDrag(mouseCoords.page);
    }, 10);
  }

  stopCoordsMonitor() {
    clearInterval(this.monitorHandle);
  }

  handleDragStart(coords: DOMPoint) {
    this.startCoords = coords;
    this.currCoords = coords;
    this.startCoordsMonitor();
    document.addEventListener("mouseup", this.handleDragEnd);
  }

  handleDrag(coords: DOMPoint) {
    this.currCoords = coords;
    if (!this.isDragging) {
      this.onDragStart(this.startCoords);
    }
    this.isDragging = true;

    // Autoscroll at canvas edges
    // TODO deal with scrolled to top. See if that can be determined without relying on scoll
    // or CanvasRect as they both are volatile
    if (this.bottom - mouseCoords.page.y <= BOUNDARY_PROXIMITY_AUTOSCROLL_THRESHOLD) {
      this.adjustment = addPoints(this.adjustment, new DOMPoint(0, EDGE_DRAG_ADJUST));
    } else if (
      !this.scrolledToTop &&
      mouseCoords.client.y - this.top <= BOUNDARY_PROXIMITY_AUTOSCROLL_THRESHOLD
    ) {
      if (this.scrolledToTop) return;
      this.adjustment = addPoints(
        this.adjustment,
        new DOMPoint(0, EDGE_DRAG_ADJUST * -1)
      );
    }

    this.onDrag(addPoints(this.currCoords, this.adjustment));
  }

  handleDragEnd(event: MouseEvent) {
    document.removeEventListener("mouseup", this.handleDragEnd);
    this.stopCoordsMonitor();
    if (!this.isDragging) return;
    const endCoords = addPoints(
      new DOMPoint(event.pageX, event.pageY),
      this.adjustment
    );
    // The last onDrag event was likely throttled and cancelled.
    // Simulate it before firing onDragEnd
    this.onDrag(endCoords);
    this.onDragEnd(endCoords);
    this.isDragging = false;
    this.startCoords = new DOMPoint();
    this.currCoords = new DOMPoint();
    this.adjustment = new DOMPoint();
  }
}
