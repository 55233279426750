import React from "react";

import styled from "styled-components";

import {
  useTransformationActionContext,
  useTransformationStateContext
} from "../../layout/TransformationContext/TransformationContext";

const Mask = styled.div`
  width: 100%;
  height: 100%;
`;

export default function CanvasEventMask({ children }: { children: React.ReactNode }) {
  const { clearSelection } = useTransformationActionContext();
  const { transformations } = useTransformationStateContext();

  return (
    <Mask
      onClick={event => {
        // Only clear selected element, if we are not clicking on the element
        const elementKey = (event.target as HTMLElement).dataset.key;
        if (!transformations.size && elementKey === "grid-root") {
          clearSelection();
        }
      }}
    >
      {children}
    </Mask>
  );
}
