import { SET_VARIABLE } from "../common/effects/Effects";
import { DialogEventType } from "../common/effects/useDialogEffects/useDialogEffects";

export const MODAL_EVENTS = [
  {
    name: "On close",
    type: DialogEventType.CLOSE,
    effects: [SET_VARIABLE],
    defaultEffect: {
      type: "set_variable" as const,
      options: {
        variable_name: "variable_name",
        expression: "true"
      }
    }
  }
];
