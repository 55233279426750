import React from "react";

import { useMutation } from "@apollo/react-hooks";

import { SpaceStateInputs } from "../..";
import {
  EXECUTE_PRESIGN,
  ExecutePresignData,
  ExecutePresignVars
} from "../../../../../../graphql/function";
import { useEvaluaterContext } from "../../../../../common/CodeSandbox/EvaluaterContext";
import { EVALUATION_ERROR_PREFIX } from "../../../../../common/CodeSandbox/useCodeSandbox";
import { assertNever } from "../../../../../util/assertNever";
import { useStableSpaceContext } from "../../../SpaceContext";
import { SpaceImageComponent } from "../../SpaceImage/types";
import { SpaceViewlessImageComponent } from "../../SpaceViewlessImage/types";

export interface Result {
  url: string;
  hasError: boolean;
}

type ImageUnionComponent = SpaceImageComponent | SpaceViewlessImageComponent;

type Action =
  | { type: "START_PRESIGN" }
  | { type: "COMPLETE_PRESIGN"; payload: { signedUrl: string } }
  | { type: "FAIL_PRESIGN" }
  | { type: "CHANGE_URL"; payload: { url: string } };

const initialState = {
  url: "",
  signedUrl: "",
  hasError: false,
  signing: false
};
type State = typeof initialState;

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "CHANGE_URL": {
      const { url } = action.payload;
      return {
        hasError: false,
        signedUrl: "",
        signing: false,
        url
      };
    }
    case "START_PRESIGN": {
      return {
        ...state,
        signing: true
      };
    }
    case "FAIL_PRESIGN": {
      return {
        ...state,
        hasError: true,
        signing: false,
        signedUrl: ""
      };
    }
    case "COMPLETE_PRESIGN": {
      const { signedUrl } = action.payload;
      return {
        ...state,
        hasError: false,
        signing: false,
        signedUrl
      };
    }
    default: {
      return assertNever(action);
    }
  }
}

export default function useSignedUrl(
  component: ImageUnionComponent,
  input: SpaceStateInputs | null,
  skipEncode: boolean
): Result {
  const { editMode } = useStableSpaceContext();
  const [{ signedUrl, url, hasError, signing }, dispatch] = React.useReducer(
    reducer,
    initialState
  );
  const { evaluate, getConsoleError } = useEvaluaterContext();

  const [presign] = useMutation<ExecutePresignData, ExecutePresignVars>(
    EXECUTE_PRESIGN,
    {
      onCompleted: res => {
        dispatch(
          res.executePresign.ok
            ? {
                type: "COMPLETE_PRESIGN",
                payload: { signedUrl: res.executePresign.urlString }
              }
            : { type: "FAIL_PRESIGN" }
        );
      },
      onError: _error => {
        dispatch({ type: "FAIL_PRESIGN" });
      }
    }
  );

  const { properties } = component;

  const functionId = properties.authentication?.function_id;

  React.useEffect(() => {
    const evalExpression = async () => {
      try {
        const template = await evaluate(properties.template, input);
        if (template !== url) {
          dispatch({
            type: "CHANGE_URL",
            payload: { url: template as string }
          });
        }
      } catch (e) {
        if (typeof e !== "string") {
          throw new Error("unexpected error type: " + e);
        }
        if (e.indexOf(EVALUATION_ERROR_PREFIX) > -1) {
          dispatch({
            type: "CHANGE_URL",
            payload: { url: "" }
          });
        }
        if (editMode) {
          console.warn(getConsoleError(e));
        }
      }
    };
    evalExpression();
  }, [input, properties.template, url, evaluate, editMode, getConsoleError]);

  const shouldSign = functionId && url && !signedUrl && !signing && !hasError;
  React.useEffect(() => {
    if (!shouldSign || !functionId) return;

    dispatch({ type: "START_PRESIGN" });
    presign({
      variables: {
        functionId,
        path: url
      }
    });
  }, [shouldSign, url, functionId, presign]);

  const unsignedUrl = skipEncode ? url : encodeURI(url);

  return {
    url: !!functionId ? signedUrl : unsignedUrl,
    hasError
  };
}
