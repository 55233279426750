import React from "react";

import { Select } from "antd";
import produce from "immer";

import { AttributeTypes } from "../../../../../../../../constants";
import {
  BindingFilter,
  BindingShape,
  DurationFilter,
  Filter,
  FilterTypes,
  ValueFilter,
  ViewFilterOperator as Op
} from "../../../../../../../../types";
import { DebouncedAttributeInput } from "../../../../../../../common/AttributeInput";
import { BindingCascader as BindingOptionCascader } from "../../../BindingCascader";
import * as styled from "../StyledComponents";

import { getDurationISO, getObjectFromDurationString } from "./util";

interface Props {
  valueType?: AttributeTypes;
  filter: Filter;
  onChange: (filter: Filter) => void;
  setFilterValueValidity: (valid: boolean) => void;
}

export default function FilterValue({
  valueType,
  filter,
  onChange,
  setFilterValueValidity
}: Props) {
  const onBoundComponentChange = (path: string) => {
    const updated = {
      type: FilterTypes.Binding,
      attribute: filter.attribute,
      operator: filter.operator,
      binding: path
    };
    onChange(updated as BindingFilter);
  };

  const onValueChange = (value: any) => {
    onChange(
      produce(filter, (nextFilter: ValueFilter) => {
        nextFilter.value = valueType === AttributeTypes.BOOL ? value === "true" : value;
      })
    );
  };

  if (
    [Op.EQUALS, Op.NOT_EQUALS].includes(filter.operator) &&
    filter.type === FilterTypes.Value &&
    filter.value === null
  ) {
    return null;
  }

  if (filter.type === FilterTypes.Binding) {
    return (
      <BindingOptionCascader
        className="bindingSelect"
        placeholder="Enter a value"
        value={filter.binding || ""}
        selectable={[BindingShape.SCALAR, BindingShape.SCALAR_ARRAY]}
        onChange={onBoundComponentChange}
        data-test="bindingSelect"
      />
    );
  }

  if (filter.type === FilterTypes.Value) {
    return (
      <styled.StyledTextSelect>
        <DebouncedAttributeInput
          sourceType={valueType || AttributeTypes.STRING}
          sourceName="value"
          sourceNullable={false}
          placeholder="Enter a value"
          value={filter.value}
          onChange={onValueChange}
          onValidate={valid => {
            setFilterValueValidity(valid);
          }}
          required
          verbose
        />
      </styled.StyledTextSelect>
    );
  }

  if (filter.type === FilterTypes.Duration) {
    const currentValue = getObjectFromDurationString(filter.duration_iso);
    return (
      <styled.RelativeValue>
        <styled.NumberInput
          data-test="quantityInput"
          sourceType={AttributeTypes.INT}
          sourceName="quantity"
          sourceNullable={false}
          placeholder="How many"
          value={currentValue.quantity}
          onChange={(quantity: any) => {
            onChange(
              produce(filter, (nextFilter: DurationFilter) => {
                nextFilter.duration_iso = getDurationISO(currentValue.unit, quantity);
              })
            );
          }}
        />
        <Select
          getPopupContainer={trigger => trigger.parentNode as HTMLElement}
          data-test="timeUnitSelect"
          placeholder="Select a unit of time"
          value={currentValue.unit}
          onChange={(key: any) => {
            onChange(
              produce(filter, (nextFilter: DurationFilter) => {
                nextFilter.duration_iso = getDurationISO(key, currentValue.quantity);
              })
            );
          }}
        >
          <Select.Option key="minute">Minutes</Select.Option>
          <Select.Option key="hour">Hours</Select.Option>
          <Select.Option key="day">Days</Select.Option>
          <Select.Option key="week">Weeks</Select.Option>
          <Select.Option key="month">Months</Select.Option>
          <Select.Option key="year">Years</Select.Option>
        </Select>
      </styled.RelativeValue>
    );
  }
  return null;
}
