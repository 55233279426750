import React from "react";

type UploadFn = (
  method: string,
  uri: string,
  // eslint-disable-next-line no-undef
  body: Document | XMLHttpRequestBodyInit | null
) => Promise<void>;

interface Result {
  progress: number;
  uploading: boolean;
}

export default function useUpload(): [UploadFn, Result] {
  const [uploading, setUploading] = React.useState(false);
  const [progress, setProgress] = React.useState(0);

  const upload = React.useCallback((method, uri, body) => {
    return new Promise<void>((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.upload.addEventListener("progress", e => {
        setProgress((e.loaded / e.total) * 100);
      });
      xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          setUploading(false);
          setProgress(0);
          if (xhr.status === 200) {
            resolve();
          } else {
            reject(new Error("Upload failed"));
          }
        }
      };
      setUploading(true);
      xhr.open(method, uri);
      xhr.send(body);
    });
  }, []);

  return [upload, { uploading, progress }];
}
