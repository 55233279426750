import { useMutation } from "@apollo/react-hooks";
import { MutationHookOptions } from "@apollo/react-hooks/lib/types";
import gql from "graphql-tag";

interface Data {
  disconnectSpaceScm: {
    ok: boolean;
  };
}

interface Variables {
  spaceId: string;
}

const QUERY = gql`
  mutation DisconnectSpaceScm($spaceId: ID!) {
    disconnectSpaceScm(spaceId: $spaceId) {
      ok
    }
  }
`;

const useDisconnectScm = (options?: MutationHookOptions<Data, Variables>) =>
  useMutation(QUERY, options);

export default useDisconnectScm;
