import React, { ReactNode } from "react";

import { Modal as AntModal } from "antd";
import { NativeButtonProps } from "antd/lib/button/button";
import { ModalProps as AntModalProps } from "antd/lib/modal";
import classNames from "classnames";
import styled from "styled-components";
import { useDebouncedCallback } from "use-debounce/lib";

import { colors, getWindowSize } from "../../../cssConstants";
import ButtonNew, { ButtonProps } from "../ButtonNew/ButtonNew";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { H5 } from "../StyledComponents";

export interface ModalProps extends AntModalProps {
  children?: ReactNode;
  height?: string;
}

const StyledModal = styled(AntModal)`
  .row:last-child {
    margin-bottom: ${props => props.theme.spacers.lg};
  }
`;

const StyledModalBody = styled.div`
  height: 65vh;
  margin: -24px;
  padding: 24px;
  overflow: auto;
`;

export const SubTitle = styled.div`
  margin-bottom: ${props => props.theme.spacers.lg};
  font-size: 16px;
  font-weight: 500;
  color: ${props => props.theme.textColorMid};
`;
SubTitle.displayName = "SubTitle";

const WIDTHS = {
  default: "95%",
  sm: "80%",
  md: "70%",
  lg: "60%",
  xl: "50%"
};

const Modal = ({ children, destroyOnClose = true, ...props }: ModalProps) => {
  const { width: windowWidth } = useWindowDimensions();
  const windowSize = getWindowSize(windowWidth);
  const modalWidth = WIDTHS[windowSize];

  return (
    <StyledModal
      width={modalWidth}
      style={{ height: props.height }}
      destroyOnClose={destroyOnClose}
      {...props}
    >
      <StyledModalBody style={{ height: props.height }}>{children}</StyledModalBody>
    </StyledModal>
  );
};

export default Modal;

interface AdditionalModalRootProps {
  absolutePositioning: boolean | undefined;
  backgroundColor?: string;
}

const ModalRoot = styled(AntModal)<AdditionalModalRootProps>`
  top: 10vh;

  .ant-modal-header {
    border-bottom: none;
    background-color: ${props =>
      props.backgroundColor || props.theme.modalBackgroundColor};
  }

  &.headerDropShadow {
    .ant-modal-header {
      z-index: 1;
      box-shadow: 0px 4px 12px 0px ${colors.dropShadow};
    }
  }

  .ant-modal-content {
    max-height: 85vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: ${props =>
      props.backgroundColor || props.theme.modalBackgroundColor};
  }

  .ant-modal-body {
    height: 100%;
    display: flex;
    margin-right: -24px;
    flex-direction: column;
    overflow: hidden;
    background-color: ${props =>
      props.backgroundColor || props.theme.modalBackgroundColor};

    > * {
      padding-right: 24px;
    }
  }

  .ant-modal-footer {
    border-top: none;
  }

  position: ${props => (props.absolutePositioning ? "absolute" : "relative")};
`;

const Title = styled(H5)`
  margin-bottom: ${props => props.theme.spacermd};
`;

const Body = styled.main`
  overflow: auto;
  font-size: 12px;
  font-weight: 500;
`;

const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
`;

export function ModalNew({
  children,
  title,
  width = "350px",
  cancelText = "Cancel",
  okText = "Done",
  confirmLoading,
  onCancel,
  onOk,
  tertiaryAction = null,
  hasFooter = true,
  closeIcon = <></>,
  header,
  headerDropshadowOnScroll = true,
  backgroundColor,
  absolutePositioning,
  buttonType,
  ...props
}: AntModalProps & {
  children: React.ReactNode;
  tertiaryAction?: React.ReactNode;
  hasFooter?: boolean;
  closeIcon?: React.ReactNode;
  header?: string | React.ReactNode;
  headerDropshadowOnScroll?: boolean;
  backgroundColor?: string;
  absolutePositioning?: boolean;
  buttonType?: ButtonProps["type"];
}) {
  const [showDropShadow, setShowDropShadow] = React.useState(false);
  const debounceSetDropShadow = useDebouncedCallback(scrollTop => {
    if (!headerDropshadowOnScroll) {
      return;
    }

    const showDropShadowNow = scrollTop !== 0;
    if (showDropShadowNow !== showDropShadow) {
      setShowDropShadow(showDropShadowNow);
    }
  }, 20).callback;

  const { className, okButtonProps, ...otherProps } = props;
  const { size, ...validOkButtonProps } = (okButtonProps as NativeButtonProps) || {};

  return (
    <ModalRoot
      width={width}
      backgroundColor={backgroundColor}
      absolutePositioning={absolutePositioning}
      closeIcon={closeIcon}
      onCancel={onCancel}
      title={header}
      className={classNames({
        [className || ""]: !!className,
        headerDropShadow: showDropShadow
      })}
      footer={
        hasFooter && (
          <Footer>
            <div>{tertiaryAction}</div>
            <div>
              {onCancel && (
                <ButtonNew onClick={ev => onCancel(ev)}>{cancelText}</ButtonNew>
              )}
              {onOk && (
                <ButtonNew
                  {...validOkButtonProps}
                  type={buttonType || "primary"}
                  loading={confirmLoading}
                  onClick={ev => onOk(ev)}
                >
                  {okText}
                </ButtonNew>
              )}
            </div>
          </Footer>
        )
      }
      {...otherProps}
    >
      {title && <Title>{title}</Title>}
      <Body onScroll={event => debounceSetDropShadow(event.currentTarget.scrollTop)}>
        {children}
      </Body>
    </ModalRoot>
  );
}

export const confirm = AntModal.confirm;
