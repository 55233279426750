import { Input } from "antd";
import styled from "styled-components";

import { colorTokens } from "../../cssConstants";
import { B2 } from "../common/StyledComponents";

export const Contents = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 36px;
`;

export const LinkPrompt = styled(B2)`
  color: ${colorTokens.white};
`;

export const StyledSearch = styled(Input.Search)`
  .ant-btn.ant-btn-primary.ant-input-search-button {
    background-color: ${props => props.theme.primaryColorDark};
    border-color: ${props => props.theme.primaryColorDark};

    &:hover {
      background-color: ${props => props.theme.primaryColorMuted};
      border-color: ${props => props.theme.primaryColorMuted};
    }
  }
`;
