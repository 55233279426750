import React from "react";

import { Modal, Skeleton } from "antd";
import * as AntForm from "antd/lib/form/Form";

import { SpaceComponentObject } from "../../../../../types";
import { SubTitle } from "../../../../common/Modal";
import {
  SpaceFunction,
  FunctionExecutionStatus
} from "../../../FunctionExecutor/FunctionExecutor";
import SpaceApi from "../../../SpaceApi";
import {
  DEFAULT_BUTTON_BACKGROUND_COLOR,
  DEFAULT_BUTTON_TEXT_COLOR
} from "../common/Button/constants";
import PermissionFeedback from "../common/PermissionFeedback";
import { InputParameter } from "../common/useFuncParams";
import useFunctionAccess from "../common/useFunctionAccess";
import FunctionForm from "../SpaceFunctionForm/FunctionForm";

import { StyledCancelButton, StyledSubmitButton, Title } from "./styledComponents";

interface FunctionModalProps {
  children?: React.ReactNode;
  form: AntForm.WrappedFormUtils<any>;
  func: SpaceFunction;
  prefillParams: Record<string, any>;
  funcParams: Record<string, any>;
  spaceApi: SpaceApi;
  spaceComponent: SpaceComponentObject;
  inputParameters: InputParameter[];
  loading: boolean;
  contentLoading?: boolean;
  emptyContent?: React.ReactNode;
  disabled: boolean;
  functionExecutionStatus: FunctionExecutionStatus;
  isValid: boolean;
  width?: string;
  themeProperties?: Record<string, any>;
  onClose: () => void;
  onSubmit: () => void;
}

export default function FunctionModalForm({
  children,
  form,
  func,
  prefillParams,
  funcParams,
  spaceApi,
  spaceComponent: { slug, properties },
  spaceComponent,
  inputParameters,
  disabled,
  loading,
  contentLoading,
  emptyContent = <Skeleton active />,
  functionExecutionStatus,
  isValid,
  width,
  themeProperties,
  onClose,
  onSubmit
}: FunctionModalProps) {
  const uiFormId = `${slug}-function-modal-form`;
  const submitDisabled = contentLoading || loading || disabled;

  const access = useFunctionAccess(func);

  const textColor = themeProperties?.["submit-text-color"] || DEFAULT_BUTTON_TEXT_COLOR;
  const backgroundColor =
    themeProperties?.["submit-background-color"] || DEFAULT_BUTTON_BACKGROUND_COLOR;

  return (
    <Modal
      visible={true}
      maskClosable={false}
      onCancel={onClose}
      width={width}
      footer={[
        <StyledCancelButton key="back" outlineColor={backgroundColor} onClick={onClose}>
          Cancel
        </StyledCancelButton>,
        <StyledSubmitButton
          key="submit"
          type="primary"
          textColor={textColor}
          backgroundColor={backgroundColor}
          loading={loading}
          form={uiFormId}
          htmlType="submit"
          disabled={submitDisabled}
        >
          Submit
        </StyledSubmitButton>
      ]}
    >
      {contentLoading ? (
        emptyContent
      ) : (
        <>
          <Title>
            {properties.title}{" "}
            <PermissionFeedback
              attributes={func.functionAttributes}
              parameters={func.functionParameters.filter(fp =>
                inputParameters.some(ip => ip.name === fp.name)
              )}
              access={access}
            />
          </Title>
          {properties.instructions ? (
            <SubTitle>{properties.instructions}</SubTitle>
          ) : null}
          <FunctionForm
            formId={uiFormId}
            form={form}
            func={func}
            spaceApi={spaceApi}
            spaceComponent={spaceComponent}
            inputParameters={inputParameters}
            initialValues={prefillParams}
            values={funcParams}
            disabled={false}
            onSubmit={onSubmit}
            functionExecutionStatus={functionExecutionStatus}
            isValid={!submitDisabled && isValid}
          />
          {children}
        </>
      )}
    </Modal>
  );
}
