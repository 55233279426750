import React from "react";

import { ComponentConfigProps } from "../../../../../../types";
import BaseComponentConfigSection from "../../common/BaseComponentConfigSection";
import { ComponentConfigContextContainer } from "../../common/ComponentConfigContext";
import NameFields from "../../common/NameFields";
import VisibilityRulesManagerSection from "../../common/VisibilityRulesManager";

import DesignConfig from "./DesignConfig";
import TabsSection from "./TabsSection";

const MemoDataConfig = React.memo(SpaceTabsConfigContent);
export default function SpaceTabsConfig({ slug }: ComponentConfigProps) {
  return (
    <ComponentConfigContextContainer
      dataConfig={<MemoDataConfig />}
      dataTabTitle="Main"
      slug={slug}
      designConfig={<DesignConfig />}
    />
  );
}

function SpaceTabsConfigContent() {
  return (
    <>
      <BaseComponentConfigSection>
        <NameFields />
      </BaseComponentConfigSection>
      <TabsSection />
      <VisibilityRulesManagerSection />
    </>
  );
}
