import React from "react";

import { SpaceComponentPackage, SpaceComponentNode } from "../../../../types";
import makeComponent from "../../SpaceConfig/SpaceConfigContext/useSpaceConfig/reducer/util/makeComponent";

import { useStableSpaceContext } from "./StableSpaceContext";

export function createClientProvidedComponent(p: SpaceComponentPackage) {
  return makeComponent(new Set(), {
    id: `__clientProvided${p.type}`,
    slug: p.type.toLowerCase(),
    name: p.displayName,
    type: p.type
  }) as SpaceComponentNode;
}

export function getClientProvidedComponents(pkgs: SpaceComponentPackage[]) {
  return pkgs
    .filter(p => p.isClientProvidedComponent)
    .map(createClientProvidedComponent);
}

export default function useClientProvidedComponents() {
  const { getSpaceComponentPackages } = useStableSpaceContext();
  const clientProvidedComponents: SpaceComponentNode[] = React.useMemo(() => {
    return getClientProvidedComponents(getSpaceComponentPackages());
  }, [getSpaceComponentPackages]);
  return clientProvidedComponents;
}
