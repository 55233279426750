import React from "react";

import moment from "moment";
import styled from "styled-components";

import { UserAvatar } from "../../../../common/Avatar/Avatar";
import { B3 } from "../../../../common/StyledComponents";
import { UserNode } from "../../queries/common";

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const StyledAvatar = styled(UserAvatar)`
  width: 20px;
  height: 20px;
  margin-left: ${props => props.theme.spacerxs};
  margin-right: ${props => props.theme.spacerxs};
`;

interface Props {
  user: UserNode;
  createdAt: string;
}
export const RunDateAndUser = (props: Props) => {
  return (
    <Container>
      <B3>{moment(props.createdAt).format("M/D/YY h:mm a z")} by </B3>
      <StyledAvatar userId={props.user.id}>
        {props.user.firstName?.[0] || props.user.lastName?.[0]}
      </StyledAvatar>
      <B3>
        {props.user.firstName} {props.user.lastName}
      </B3>
    </Container>
  );
};

export default RunDateAndUser;
