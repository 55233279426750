import React from "react";

import BackgroundBits from "../../../assets/background-bits.svg";

import {
  BackgroundBitsContainer,
  BottomGradient,
  CenterContent,
  LeftChildContainer,
  RightChildContainer,
  Container,
  ContentAlign,
  Main,
  StyledHeader
} from "./styledComponents";

interface Props {
  left: React.ReactNode;
  right: React.ReactNode;
  align?: "start" | "center";
  showActions?: boolean;
}

export default function Layout({ left, right, align, showActions }: Props) {
  return (
    <Container>
      <BackgroundBitsContainer>
        <BackgroundBits />
      </BackgroundBitsContainer>
      <Main>
        <StyledHeader showActions={!!showActions} />
        <CenterContent>
          <ContentAlign align={align ? align : "start"}>
            <LeftChildContainer>{left}</LeftChildContainer>
            <RightChildContainer>{right}</RightChildContainer>
          </ContentAlign>
        </CenterContent>
      </Main>
      <BottomGradient />
    </Container>
  );
}
