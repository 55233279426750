import React from "react";

import AnalyticCard, { DEFAULT_ERROR_MESSAGE } from "../AnalyticCard/AnalyticCard";
import { Headline, PremiumBlockHeader } from "../common/styledComponents";
import { TimeRange } from "../DurationSelect/DurationSelect";
import { formatDuration } from "../util";

import { useAverageTimeToClose } from "./queries";

export default function AverageTimeToCloseStatistics({
  queueId,
  timeRange,
  locked,
  className
}: {
  queueId?: string;
  timeRange: TimeRange;
  locked: boolean;
  className?: string;
}) {
  const { data, loading, error } = useAverageTimeToClose({
    variables: {
      queueId: queueId!,
      fromTimestamp: timeRange.fromTimestamp,
      toTimestamp: timeRange.toTimestamp
    },
    skip: !queueId,
    fetchPolicy: "cache-and-network"
  });

  return (
    <AnalyticCard
      title="Average time to close"
      loading={loading}
      errorMessage={error ? DEFAULT_ERROR_MESSAGE : undefined}
      className={className}
      showUpsell={locked}
    >
      {locked ? (
        <PremiumBlockHeader />
      ) : (
        <Headline>
          {data?.averageTimeToClose
            ? formatDuration(data.averageTimeToClose)
            : "No data available"}
        </Headline>
      )}
    </AnalyticCard>
  );
}
