import React from "react";

import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Routes } from "../../../constants";
import { AuthCTAButton } from "../../auth/styledComponents";

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const RequestPasswordResetSent = () => {
  const navigate = useNavigate();

  return (
    <ButtonWrapper>
      <AuthCTAButton block onClick={() => navigate(Routes.LOGIN)} type="brand">
        Back to sign in
      </AuthCTAButton>
    </ButtonWrapper>
  );
};

export default RequestPasswordResetSent;
