import Icon from "../../../../../assets/spaceicons/dropdown.svg";
import {
  SpaceComponentPackage,
  SpaceComponentHostType,
  SpaceComponentTags
} from "../../../../../types";
import { DEFAULT_SIZES } from "../../../layout/constants";
import { ElementLayout } from "../../../layout/util";
import errorSelector from "../common/SelectBackedComponent/Config/errorSelector";
import componentConfigReducer, {
  getMakeInitialState
} from "../common/SelectBackedComponent/Config/reducer";
import { getSchema } from "../common/SelectBackedComponent/getSchema";
import { SelectConfigState } from "../common/SelectBackedComponent/types";

import SpaceDropdownConfig, {
  SpaceDropdownTerseConfig,
  SpaceDropdownInlineConfig
} from "./Config";
import SpaceDropdown from "./SpaceDropdown";
import { SpaceDropdownProperties, SpaceDropdownComponent } from "./types";
import { ensureDropdownComponent, getComponentProperties } from "./utils";

const pkg: SpaceComponentPackage<
  SpaceDropdownProperties,
  SpaceDropdownComponent,
  SelectConfigState
> = {
  type: "DROPDOWN",
  displayName: "Dropdown",
  Icon,
  allowedHosts: new Set<SpaceComponentHostType>([
    "SPACE",
    "TABLE",
    "DETAIL",
    "CARD_LIST",
    "FUNCTION_FORM",
    "FUNCTION_MODAL_FORM",
    "FLEX_BOX",
    "CLOUD_UPLOADER"
  ]),
  Component: SpaceDropdown,
  ConfigurationComponent: SpaceDropdownConfig,
  TerseConfigurationComponent: SpaceDropdownTerseConfig,
  InlineConfigurationComponent: SpaceDropdownInlineConfig,
  componentConfigReducer,
  getInitialDraftState: getMakeInitialState("DROPDOWN"),
  ensureComponent: ensureDropdownComponent,
  getComponentProperties,
  getSchema,
  allowSelfBinding: true,
  defaultElementLayout: new ElementLayout({
    width: DEFAULT_SIZES.INPUT.width,
    height: DEFAULT_SIZES.INPUT.height,
    minWidth: 110, // in px; 88px for select component (incl 16px loader) + 22px for warning icon
    snapToGrid: false
  }),
  allowAncestorBinding: false,
  tags: [SpaceComponentTags.Input],
  isHeadless: false,
  errorSelector,
  isContainer: false
};

export default pkg;
