import React from "react";

import { ComponentConfigProps } from "../../../../../../types";
import BaseComponentConfigSection from "../../common/BaseComponentConfigSection";
import ButtonDesignConfig from "../../common/ButtonDesignConfig";
import { ItemContainer } from "../../common/CollectionItemContainer/CollectionItemContainer";
import {
  ComponentConfigContextContainer,
  useComponentConfigContext
} from "../../common/ComponentConfigContext";
import { ConfigSection } from "../../common/ConfigPanel";
import {
  DISPLAY_MESSAGE,
  REFRESH_COMPONENT,
  AUTO_UPDATE_BY_KEY
} from "../../common/effects/Effects";
import { MessageType } from "../../common/effects/Effects/DisplayMessage";
import EffectsManager from "../../common/effects/EffectsManager/EffectsManager";
import { SubmittableEventType } from "../../common/effects/useSubmittableEffects/useSubmittableEffects";
import FunctionConfig from "../../common/FunctionBackedPopover/FunctionConfig";
import { ensureSubmittableComponentConfigState } from "../../common/FunctionBackedPopover/reducer/reducer";
import TextConfig from "../../common/FunctionBackedPopover/TextConfig";
import ParametersConfigSection from "../../common/ParametersManager/ParametersConfigSection";
import VisibilityRulesManagerSection from "../../common/VisibilityRulesManager";

import SourceConfig from "./SourceConfig";

export default function BulkImportConfig(props: ComponentConfigProps) {
  const dataContent = React.useMemo(() => <DataConfig />, []);
  const effectsConfig = React.useMemo(() => <EffectsConfig />, []);
  const designContent = React.useMemo(() => <ButtonDesignConfig />, []);
  return (
    <ComponentConfigContextContainer
      slug={props.slug}
      dataConfig={dataContent}
      effectsConfig={effectsConfig}
      designConfig={designContent}
    />
  );
}

export function DataConfig() {
  const context = useComponentConfigContext();
  const state = ensureSubmittableComponentConfigState(context.state);
  const { dispatch } = context;
  const func = state.draftComponent.functions.edges[0]?.node;
  const functionId = func?.id;

  return (
    <>
      <FunctionConfig state={state} dispatch={dispatch} />
      <ConfigSection title="Source" data-test="data-source">
        <SourceConfig />
      </ConfigSection>

      {!!functionId && (
        <>
          <BaseComponentConfigSection>
            <TextConfig state={state} dispatch={dispatch} />
          </BaseComponentConfigSection>
          <ItemContainer itemKey="repeateditem">
            <ParametersConfigSection title="Fields" />
          </ItemContainer>
          <VisibilityRulesManagerSection />
        </>
      )}
    </>
  );
}

const EVENT_OPTIONS = [
  {
    name: "On Successful Submit",
    type: SubmittableEventType.SUBMIT_SUCCESS,
    effects: [DISPLAY_MESSAGE, REFRESH_COMPONENT, AUTO_UPDATE_BY_KEY],
    defaultEffect: {
      type: "refresh_component" as const,
      options: {
        component: undefined
      }
    }
  },
  {
    name: "On Failed Submit",
    type: SubmittableEventType.SUBMIT_FAILURE,
    effects: [DISPLAY_MESSAGE],
    defaultEffect: {
      type: "display_message" as const,
      options: {
        type: MessageType.ERROR,
        message: "Something went wrong. Please try again."
      }
    }
  }
];

function EffectsConfig() {
  return <EffectsManager eventOptions={EVENT_OPTIONS} />;
}
