import React, { useState } from "react";

import { ConfigProvider, Empty } from "antd";
import { HotKeys } from "react-hotkeys";
import { Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";

import CodeSandbox from "./components/common/CodeSandbox/CodeSandbox";
import FlashMessages from "./components/common/FlashMessages";
import HotkeysModal from "./components/common/HotkeysModal";
import ThemeContainer, { Theme } from "./components/common/ThemeContainer";
import { registerPackages } from "./components/spaces/SpaceRoot/SpaceComponent/packages";
import ErrorBoundary from "./components/util/ErrorBoundary";
import ReloadBanner from "./components/util/ReloadBanner";
import {
  DARK_THEME_POPPER_PORTAL_ID,
  DEFAULT_THEME_POPPER_PORTAL_ID,
  EmptyImage,
  Routes as Paths
} from "./constants";
import { DefaultTheme } from "./cssConstants";
import {
  automations,
  dashboard,
  dataviewer,
  dev,
  integrations,
  legal,
  login,
  queues,
  settings,
  setup,
  signup,
  spaces,
  users
} from "./pages";
import { NotFound } from "./pages/errors";

const PopperPortal = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: ${props => props.theme.zIndex.popoverOverDrawer};
`;

const App: React.FC = () => {
  const [showHotkeysModal, setShowHotkeysModal] = useState<boolean>(false);
  const keyHandlers = {
    SHOW_HOTKEYS: () => setShowHotkeysModal(true)
  };

  const keyMap: Readonly<Record<string, string[]>> = {
    SHOW_HOTKEYS: ["?"],
    SEARCH: ["/"],
    ESCAPE: ["esc"],
    DEBUG_MODE: ["ctrl+i"]
  };

  registerPackages();

  // custom empty component used in Tables and other components when there is no data.
  const customEmpty = () => <Empty image={EmptyImage} description={<span />} />;

  return (
    <HotKeys keyMap={keyMap} handlers={keyHandlers} focused={true} attach={window}>
      <FlashMessages />
      <ReloadBanner />
      <ThemeProvider theme={DefaultTheme}>
        <ConfigProvider renderEmpty={customEmpty}>
          <HotkeysModal
            keyMap={keyMap}
            visible={showHotkeysModal}
            handleOk={() => setShowHotkeysModal(false)}
          />
          <BrowserRouter>
            <ErrorBoundary>
              {/* prettier-ignore */}
              <Routes>
                <Route path="/tos" element={<legal.TermsOfService />} />
                <Route path="/privacy" element={<legal.Privacy />} />

                {/* Signup */}
                <Route path="/" element={<signup.New />} />
                <Route path={Paths.SIGNUP} element={<signup.New />} />
                <Route path={Paths.SIGNUP_CONFIRM_EMAIL} element={<signup.CompleteSignup />} />
                <Route path={Paths.ACCEPT_INVITE} element={<signup.AcceptInvite />} />

                {/* Login */}
                <Route path={Paths.LOGIN} element={<login.New />} />
                <Route path={Paths.LOGIN_TWO_FACTOR} element={<login.TwoFactor />} />
                <Route path={Paths.RESET_PASSWORD} element={<login.ResetPasswordRequest />} />
                <Route path={Paths.RESET_PASSWORD_CONFIRM} element={<login.ResetPasswordConfirm />} />
                <Route path={Paths.RESET_PASSWORD_SENT} element={<login.ResetPasswordComplete />} />
                <Route path={Paths.SETUP_TWO_FACTOR} element={<login.SetupTwoFactor />} />
                <Route path={Paths.CONFIRM_TWO_FACTOR} element={<login.ConfirmTwoFactor />} />

                <Route path={Paths.USER_PROFILE} element={<users.Detail />} />
                <Route path={Paths.SELECT_DATA_SOURCE} element={<setup.DataSource />} />

                <Route path={Paths.DASHBOARD} element={<dashboard.Redirect />} />

                <Route path={Paths.SETUP_DATA_SOURCE} element={<integrations.Select />} />

                <Route path="settings">
                  <Route path="change-plan/:planSlug/:term">
                    <Route index element={<settings.BillingCheckout />} />
                  </Route>
                  <Route path="change-plan/confirmation" element={<settings.Confirmation />} />
                  <Route path=":tab">
                    <Route index element={<settings.Index />} />
                    <Route path=":id" element={<settings.Flyout />} />
                  </Route>
                </Route>
                <Route path="/spaces">
                  <Route path="new" element={<spaces.New />} />
                  <Route path=":spaceSlug/edit" element={<spaces.Edit />} />
                </Route>
                <Route path="/environments/:environment">
                  <Route path="automations">
                    <Route index element={<automations.Home />} />
                    <Route path="new" element={<automations.NewAutomation />} />
                    <Route path=":slug">
                      <Route index element={<automations.Home />} />
                      <Route path="edit" element={<automations.EditAutomation />} />
                    </Route>
                  </Route>
                  <Route path="queues">
                    <Route index element={<queues.Home />} />
                    <Route path="new" element={<queues.NewQueue />} />
                    <Route path="assigned" element={<queues.Assigned />} />
                    <Route path=":slug">
                      <Route index element={<queues.Queue />} />
                      <Route path="edit" element={<queues.EditQueue />} />
                      <Route path="analytics" element={<queues.QueueAnalytics />} />
                    </Route>
                  </Route>
                  <Route path="spaces/">
                    <Route index element={<spaces.Home />} />
                    <Route path="new" element={<spaces.New />} />
                    <Route path=":spaceSlug">
                      <Route index element={<spaces.Detail />} />
                      <Route path="edit" element={<spaces.Edit />} />
                      <Route path="activity" element={<spaces.Activity />} />
                      <Route path="embed" element={<spaces.Embed />} />
                      <Route path=":resourceCursor" element={<spaces.WithResourceCursor />} />
                    </Route>
                  </Route>
                  <Route path="record/:cursor/view/:view">
                    <Route index element={<dataviewer.Detail />} />
                    <Route path="context/:contextCursor" element={<dataviewer.Flyout />} />
                  </Route>
                  
                </Route>
                {/* FIXME: This route should not exist */}
                <Route
                  path={Paths.NOT_FOUND}
                  element={<NotFound />}
                />
                {process.env.NODE_ENV !== "production" && (
                  <>
                    <Route path="/style-guide" element={<dev.StyleGuide />} />
                  </>
                )}
                <Route
                  path="*"
                  element={<NotFound />}
                />
              </Routes>
              {/* the reason for including this here is to provide root `popupContainer` nodes for
                  any components that have "popups" (ie. cascader, select, popover), with the following
                  constraints:
                  (1) the popupContainer needs to be in the correct DOM hierarchy for theming
                    (ie. a child of proper ThemeContainer), and
                  (2) the popupContainer needs to be in a different DOM hierarchy than parent elements
                    that need to be scrollable, such as ConfigPanel or ConfigPanelPopper, so that they can
                    "overflow" properly (since an element with css `overflow-y: scroll` cannot also have
                    `overflow-x: visible` https://www.w3.org/TR/css-overflow-3/#overflow-properties)
                */}
              <ThemeContainer theme={Theme.Dark}>
                <PopperPortal id={DARK_THEME_POPPER_PORTAL_ID} />
              </ThemeContainer>
              <ThemeContainer theme={Theme.Default}>
                <PopperPortal id={DEFAULT_THEME_POPPER_PORTAL_ID} />
              </ThemeContainer>
              <CodeSandbox />
            </ErrorBoundary>
          </BrowserRouter>
        </ConfigProvider>
      </ThemeProvider>
    </HotKeys>
  );
};

export default App;
