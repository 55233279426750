import React from "react";

import styled from "styled-components";

import { useEnvironmentContext } from "../../../common/contexts/EnvironmentContext";
import { B3 } from "../../../common/StyledComponents";
import { AutomationNode } from "../queries/AllAutomations";

import { getFormattedDate } from "./utils";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  color: ${props => props.theme.surfaceSecondary};
  margin-top: 24px;
  margin-bottom: 24px;
`;

const DateText = styled(B3)`
  & + & {
    margin-top: ${props => props.theme.spacersm};
  }
`;

interface Props {
  automation: AutomationNode;
}
const RunInformation = ({ automation }: Props) => {
  const { getCurrentEnvironment } = useEnvironmentContext();
  const environmentId = getCurrentEnvironment().id;

  const automationEnvironment = automation.automationEnvironments?.edges.find(
    edge => edge.node.environment.id === environmentId
  )?.node;
  if (!automationEnvironment) {
    return null;
  }

  return (
    <Root>
      {automationEnvironment.lastRunAt ? (
        <DateText>
          Last run {getFormattedDate(automationEnvironment.lastRunAt)}
        </DateText>
      ) : (
        <DateText>No last run</DateText>
      )}
      {automationEnvironment.nextRunAt ? (
        <DateText>
          Next run {getFormattedDate(automationEnvironment.nextRunAt)}
        </DateText>
      ) : (
        <DateText>No next run</DateText>
      )}
    </Root>
  );
};

export default RunInformation;
