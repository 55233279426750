import { useReducer, useEffect, useMemo, useCallback } from "react";

import { TaskFilters } from "../..";
import useStableRef from "../../../../common/hooks/useStableRef";
import useWindowVisible from "../../../../common/hooks/useWindowVisible";
import { fromGlobalId } from "../../../../util/graphql";

import { useAllPollingTasks } from "./queries/AllPollingTasks";
import { TasksConnectionPageInfo, useAllTasks } from "./queries/AllTasks";
import { useTaskCheck } from "./queries/TaskCheck";
import { ReducerActions } from "./reducer/actions";
import { getDefaultState, queueClientReducer } from "./reducer/reducer";
import useQueue from "./useQueue/useQueue";

export const PAGE_SIZE = 50;
export const POLL_INTERVAL = 5 * 1000;
export const POLL_PAGE_SIZE = 100;

export type TasksPageInfo = TasksConnectionPageInfo & { hasPreviousPage: boolean };

interface Props {
  queueSlug: string;
  taskFilters?: Partial<TaskFilters>;
  beforeCursor?: string;
  afterCursor?: string;
  setCursors: (cursors: Record<string, string | undefined>) => void;
}

/**
 * useQueueClient
 *
 * Discovers the queue from its slug, supporting both local and dedicated queues. Once the
 * queue is discovered a filter is constructed to query tasks with. Also starts polling
 * to display new and updated tasks.
 *
 * Paging
 * ======
 * Pagination is somewhat complex as the order of filtered task list is mutable and it
 * is not reasonable to generally know if previous and next pages are available without
 * attempting to request them.
 *
 * The current approach is to enable the next button based on the `hasNextPage` field
 * as returned by the AllTasks query, while the previous button is enabled if
 * paging cursors are present. If a previous page occurs, and that query returns
 * no tasks, then the `beforeCursor` is removed and another query is made, thus
 * returning the task list to its initial state with a disabled previous button.
 *
 * The `beforeCursor` and `endCursor` used to page are either the `createdAt` or
 * `updatedAt` of the first and last tasks in the list, respectively. `createdAt`
 * is used for the cursor when the order is `-createdAt`, while `updatedAt` is used
 * when the order is `-updatedAt`.
 *
 * Polling
 * =======
 * Polls for new and updated tasks. Polls concurrently with two sets of variables
 * creating a subscribedPoller and an allPoller. The subscribedPoller is used to
 * poll for tasks that match the `subscribedVariables` excluding paging related
 * variables. The allPoller is used to poll for all tasks and is required
 * to update the state of tasks which are present on the page, but have transitioned
 * in such a way that they would no longer be returned by the subscribedVariables.
 * subscribedPoller poller polls for any tasks newer than the `subscribedPollerCursor`
 * and manages the current count of such task. The allPoller poller polls for all tasks
 * newer than the `allPollerCursor`, updating any found in the apollo cache.
 *
 * The pollers have a multi step startup process to ensure that tasks are neither missed
 * nor duplicated. The startup process is as follows:
 * 1. Task list queries for its tasks along with the `pollingCursor` field on the
 *    TasksConnection.
 * 2. The `pollingCursor` is used as the initial cursor for both pollers.
 * 3. Start both pollers with a large page size for `first`.
 * 4. If new or updated tasks are found during a poll move the cursor forward to
 *    the `startCursor` of the poller's query response.
 */

export default function useQueueClient({
  queueSlug,
  beforeCursor,
  afterCursor,
  setCursors,
  taskFilters = {}
}: Props) {
  const windowVisible = useWindowVisible();
  const queue = useQueue(queueSlug, taskFilters);
  const variables = useStableRef({
    ...queue?.taskFilters,
    ...taskFilters,
    first: PAGE_SIZE,
    after: afterCursor,
    before: beforeCursor
  });

  const [state, dispatch] = useReducer(queueClientReducer, getDefaultState());

  useEffect(() => {
    dispatch({
      type: ReducerActions.CHANGE_TASK_QUERY,
      payload: { filters: variables }
    });
  }, [variables]);

  const tasksResult = useAllTasks({
    variables,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    skip: !queue,
    onCompleted: data => {
      // If paging back and a full page was not found reset to the
      // head of the queue.
      if (!data.allTasks.pageInfo.hasNextPage && beforeCursor) {
        setCursors({ beforeCursor: undefined, afterCursor: undefined });
        return;
      }
      dispatch({
        type: ReducerActions.LOAD_TASK_QUERY,
        payload: { connection: data.allTasks }
      });
    }
  });

  const allPollerVariables = useMemo(
    () => ({
      first: POLL_PAGE_SIZE,
      before: state.allPollingCursor,
      orderBy: "-transitioned_at" as const
    }),
    [state.allPollingCursor]
  );

  const {
    startPolling: startAllPoller,
    stopPolling: stopAllPoller,
    refetch: refetchAllPoller
  } = useAllPollingTasks({
    variables: allPollerVariables,
    skip: !state.allPollingCursor || !windowVisible,
    fetchPolicy: "network-only",
    /* onCompleted will only be invoked when polling if notifyOnNetworkStatusChange is true */
    notifyOnNetworkStatusChange: true,
    onError: console.error,
    onCompleted: data => {
      if (state.restartingPoller || !data?.allTasks.edges.length) return;

      dispatch({
        type: ReducerActions.POLL_ALL,
        payload: {
          connection: data.allTasks,
          startCursor: data.allTasks.pageInfo?.startCursor || ""
        }
      });
    }
  });

  useEffect(() => {
    if (state.restartingPoller) {
      stopAllPoller();
      refetchAllPoller(allPollerVariables);
      startAllPoller(POLL_INTERVAL);
      dispatch({ type: ReducerActions.RESTART_POLLER });
    }
  }, [
    state.restartingPoller,
    allPollerVariables,
    startAllPoller,
    stopAllPoller,
    refetchAllPoller,
    dispatch
  ]);

  useEffect(() => {
    if (taskFilters.search && !state.stopPolling) {
      stopAllPoller();
      dispatch({ type: ReducerActions.STOP_POLL });
    } else if (!taskFilters.search && state.stopPolling) {
      dispatch({ type: ReducerActions.RESTART_POLLER });
    }
  }, [taskFilters.search, stopAllPoller, dispatch, state.stopPolling]);

  const refetchTasks = useCallback(() => {
    tasksResult.refetch();
    setCursors({ afterCursor: undefined, beforeCursor: undefined });
  }, [tasksResult, setCursors]);

  const onTaskDeleted = useCallback(
    (taskId: string) => {
      dispatch({
        type: ReducerActions.DELETE_TASK,
        payload: { taskId }
      });
    },
    [dispatch]
  );

  const taskCheck = useTaskCheck({
    variables: variables,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    skip: true
  });

  const onTaskCreated = useCallback(
    async (taskId: string) => {
      dispatch({
        type: ReducerActions.CREATE_TASK_CHECK,
        payload: { taskId: taskId }
      });

      const result = await taskCheck.refetch({
        ...variables,
        id: taskId
      });

      dispatch({
        type: ReducerActions.CREATE_TASK_CHECK_COMPLETE,
        payload: {
          connection: result.data.allTasks
        }
      });
    },
    [dispatch, taskCheck, variables]
  );

  const onTaskUpdated = useCallback(
    async (taskId: string) => {
      dispatch({
        type: ReducerActions.UPDATE_TASK_CHECK,
        payload: { taskId: taskId }
      });

      const result = await taskCheck.refetch({
        ...variables,
        id: fromGlobalId(taskId)[1]
      });

      dispatch({
        type: ReducerActions.UPDATE_TASK_CHECK_COMPLETE,
        payload: {
          connection: result.data.allTasks
        }
      });
    },
    [dispatch, taskCheck, variables]
  );

  const onTaskTransitioned = useCallback(
    async (taskId: string) => {
      dispatch({
        type: ReducerActions.TRANSITION_TASK_CHECK,
        payload: { taskId: taskId }
      });

      const result = await taskCheck.refetch({
        ...variables,
        id: fromGlobalId(taskId)[1]
      });

      dispatch({
        type: ReducerActions.TRANSITION_TASK_CHECK_COMPLETE,
        payload: {
          connection: result.data.allTasks
        }
      });
    },
    [dispatch, taskCheck, variables]
  );

  const pageInfo: TasksPageInfo = useMemo(() => {
    return {
      ...(tasksResult.data?.allTasks.pageInfo || {
        __typename: "PageInfo",
        hasNextPage: false,
        hasPreviousPage: false,
        startCursor: "",
        endCursor: "",
        beforeCursor: ""
      }),
      hasPreviousPage: !!(afterCursor || beforeCursor)
    };
  }, [tasksResult, beforeCursor, afterCursor]);

  return {
    queue,
    tasks: state.tasks || [],
    taskChangeCount: state.taskChangeCount,
    totalTaskCount: state.totalTaskCount,
    beforeTaskCount: state.beforeTaskCount,
    pageInfo,
    refetchTasks,
    onTaskCreated,
    onTaskUpdated,
    onTaskTransitioned,
    onTaskDeleted
  };
}
