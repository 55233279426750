import React, { useRef, useState } from "react";

import { Placement, PositioningStrategy } from "@popperjs/core";
import { SketchPicker } from "react-color";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";
import styled from "styled-components";

import { colorTokens } from "../../../cssConstants";

const swatchGutter = "2px";

const BaseRoot = styled.div<{ border: boolean }>`
  position: relative;
  padding: ${swatchGutter};
  background: ${props => (props.border ? colorTokens.grey600 : "none")};
`;

const NormalRoot = styled(BaseRoot)`
  width: ${props => props.theme.inputHeight};
  height: ${props => props.theme.inputHeight};
`;

const SmallRoot = styled(BaseRoot)`
  width: ${props => props.theme.inputHeightSm};
  height: ${props => props.theme.inputHeightSm};
`;

const Swatch = styled.div`
  border-radius: 2px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  display: inline-block;
  cursor: pointer;
  width: 100%;
  height: 100%;
`;

const Cover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;

const SketchPickerContainer = styled.div`
  z-index: 1;
`;

const StyledSketchPicker = styled(SketchPicker)`
  z-index: 1;
`;

export const DEFAULT_COLOR_OPTIONS = [
  colorTokens.greyTransparent1300,
  colorTokens.red300,
  colorTokens.red200,
  colorTokens.orange300,
  colorTokens.yellow200,
  colorTokens.green400,
  colorTokens.blue200,
  colorTokens.black
];

interface ColorPickerProps {
  value: string;
  colorOptions?: string[];
  className?: string;
  border?: boolean;
  size?: "small" | "normal";
  placement?: Placement;
  strategy?: PositioningStrategy;
  onChange: (value: string) => void;
  onChangeComplete?: (value: string) => void;
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
}

const ColorPicker = React.forwardRef<HTMLDivElement, ColorPickerProps>(
  (
    {
      value,
      colorOptions = DEFAULT_COLOR_OPTIONS,
      className,
      border = true,
      placement,
      strategy,
      size = "normal",
      onChange,
      onChangeComplete,
      getPopupContainer
    }: ColorPickerProps,
    ref
  ) => {
    const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
    const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
    const contentRef = useRef<HTMLDivElement | null>(null);

    const { styles, attributes } = usePopper(referenceElement, popperElement, {
      placement: placement ? placement : "bottom-start",
      strategy: strategy ? strategy : "absolute",
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 5]
          }
        }
      ]
    });

    const [popperOpen, setPopperOpen] = React.useState(false);
    const Root = size === "normal" ? NormalRoot : SmallRoot;

    const content = (
      <div ref={contentRef}>
        <Cover
          onClick={() => {
            setPopperOpen(false);
          }}
        />
        <SketchPickerContainer
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          <StyledSketchPicker
            color={value}
            presetColors={colorOptions}
            disableAlpha={true}
            onChange={color => {
              onChange(color.hex);
            }}
            onChangeComplete={color => {
              onChangeComplete?.(color.hex);
            }}
          />
        </SketchPickerContainer>
      </div>
    );

    return (
      <Root className={className} border={border} ref={ref}>
        <Swatch
          style={{ background: value }}
          ref={setReferenceElement}
          onClick={() => {
            setPopperOpen(!popperOpen);
          }}
        />
        {popperOpen &&
          (getPopupContainer && contentRef.current
            ? createPortal(content, getPopupContainer?.(contentRef.current))
            : content)}
      </Root>
    );
  }
);

/**
 * CachedColorPicker only updates the color value onChangeComplete.
 * This allows the UI picker to show updates, but reduces UI rerendering on each change.
 */
export function CachedColorPicker(props: ColorPickerProps) {
  const [color, setColor] = React.useState(props.value);

  React.useEffect(() => {
    setColor(props.value);
  }, [props.value, setColor]);

  return (
    <ColorPicker
      {...props}
      value={color}
      onChange={setColor}
      onChangeComplete={props.onChange}
    />
  );
}

export default ColorPicker;
