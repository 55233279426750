import React, { useState, useEffect } from "react";

import { Button, Dropdown, Menu } from "antd";
import classNames from "classnames";
import _ from "lodash";
import JSONPretty from "react-json-pretty";
import ReactJson from "react-json-view";
import styled, { ThemeContext } from "styled-components";

import { ErrorValues, isBlankValue } from "../../constants";

import { LinkButton } from "./StyledComponents";

// TODO use regex instead?
const getTitleCaseLabel = (originalLabel: string) => {
  return _.startCase(originalLabel);
};

const isEmpty = (value: any) => {
  return !value || !(typeof value === "object") || !Object.keys(value).length;
};

const isJson = (value: any) => typeof value === "object" && value !== null;

// if string value, convert to json if possible, otherwise return value
const getPossiblyJsonValue = (value: any) => {
  let possiblyJsonValue = value;
  if (typeof value === "string") {
    try {
      possiblyJsonValue = JSON.parse(value);
    } catch (e) {
      return value;
    }
  }
  return possiblyJsonValue;
};

// Styles for main component
interface StyledJsonContainerProps {
  isTerse: boolean;
  showContainerBorder: boolean;
}
const StyledJsonContainer = styled.div<StyledJsonContainerProps>`
  width: 100%;
  padding: ${props =>
    props.showContainerBorder
      ? `${props.theme.spacersm} ${props.theme.spacermd}`
      : props.isTerse
      ? `${props.theme.spacersm}`
      : "0"};

  ${props =>
    props.showContainerBorder ? `border: 1px solid ${props.theme.borderGrey};` : ""}
  border-radius: 4px;
  word-wrap: break-word;
  ul {
    padding: 0;
    margin-bottom: 0;
    > li {
      margin-left: ${props => props.theme.spacermd};
    }
  }

  &:not(:last-child) {
    margin-bottom: ${props => props.theme.spacermd};
  }
  p {
    margin-bottom: 0;
  }
`;

interface StyledContentProps {
  shouldIndent: boolean;
}
const StyledContent = styled.div<StyledContentProps>`
  ${props => (props.shouldIndent ? `margin-left: ${props.theme.spacermd};` : "")}
`;

const RenderValue = ({ value }: { value: any }) => (
  <span data-test="json-viewer-value">
    {!isBlankValue(value) ? <p>{String(value)}</p> : <Empty value={value} />}
  </span>
);

// Empty state component
const StyledEmpty = styled.p`
  color: ${props => props.theme.greyBackgroundColor};
`;
interface EmptyProps {
  text?: string;
  value?: any;
}
const Empty = ({ text, value }: EmptyProps) => {
  if (text) return <StyledEmpty>{text}</StyledEmpty>;

  let display = "None";
  if (value === "") {
    display = "(Empty String)";
  } else if (value === ErrorValues.permissionDenied) {
    display = "no access";
  }

  return <StyledEmpty>{display}</StyledEmpty>;
};
Empty.displayName = "JsonViewerEmpty";

const StyledCode = styled.code`
  font-size: ${props => props.theme.smallFontSize};
`;

// Label and collapse button components
interface LabelWithCollapseProps {
  isNested: boolean;
}
const LabelWithCollapse = styled.div<LabelWithCollapseProps>`
  display: flex;
  align-items: center;
  min-height: 21px;
  > button {
    transition: transform 300ms linear;
    line-height: 21px;

    &.expanded {
      transform: rotate(90deg);
    }
  }
`;
const Label = styled.label`
  font-size: ${props => props.theme.tinyFontSize};
  line-height: 21px;
  color: ${props => props.theme.textColorMid};
  text-transform: uppercase;
`;

// Wrapper around all label and content items
const StyledAttributeContainer = styled.div`
  &:not(:last-child) {
    margin-bottom: ${props => props.theme.spacermd};
  }
  p {
    margin-bottom: 0;
  }
`;
interface AttributeContainerProps {
  label: string;
  children: React.ReactNode;
}
const AttributeContainer = ({ label, children }: AttributeContainerProps) => {
  return (
    <StyledAttributeContainer key={label}>
      <Label>{getTitleCaseLabel(label)}</Label>
      {children}
    </StyledAttributeContainer>
  );
};

interface JsonChildProps {
  isTerse: boolean;
  label: string;
  value: {};
  depth: number;
  defaultExpandedDepth: number;
}
const JsonChild = ({
  isTerse,
  label,
  value,
  depth,
  defaultExpandedDepth
}: JsonChildProps) => {
  return isTerse ? null : (
    <JsonViewerInternal
      label={label}
      json={value}
      depth={depth}
      canCollapse={true}
      defaultExpandedDepth={defaultExpandedDepth}
    />
  );
};

interface JsonViewerInterface {
  json: any;
  className?: string;
  label?: string;
  isTerse?: boolean; // if true, render shortened view where we hide all nested json
  canCollapse?: boolean; // if consumers pass in true, collapse the root by default; internally, all nodes can collapse
  defaultExpandedDepth?: number; // by default, expand objects nested at this level or lower
  showBorder?: boolean;
  rawMode?: boolean;
  isRawModeInteractive?: boolean;
  expandAll?: boolean; // if true, defaultExpandedDepth is ignored
  showRawModeToggle?: boolean;
}

interface JsonViewerInternalInterface extends JsonViewerInterface {
  depth: number; // consumers should not pass this in; the root node will always default to 0
}

const JsonViewerInternal = ({
  className,
  json,
  label = "",
  isTerse = false,
  canCollapse = false,
  defaultExpandedDepth = 0, // by default, expand objects nested at this level or lower
  depth = 0,
  showBorder = true,
  rawMode = false,
  // below are raw-mode only settings
  isRawModeInteractive = false,
  expandAll = false
}: JsonViewerInternalInterface) => {
  const theme = React.useContext(ThemeContext);
  const shouldCollapse = canCollapse && (depth === 0 || defaultExpandedDepth < depth);
  const [isCollapsed, setIsCollapsed] = useState(shouldCollapse);
  const toggleCollapsedState = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    setIsCollapsed(shouldCollapse);
  }, [shouldCollapse]);

  const isRoot = depth === 0;
  const classes = classNames("jsonViewer", className);

  if (rawMode) {
    if (isJson(json)) {
      return (
        <StyledJsonContainer
          className={classes}
          showContainerBorder={isRoot && showBorder && !canCollapse && !isTerse}
          isTerse={isTerse}
        >
          {isRawModeInteractive ? (
            <ReactJson
              name={false}
              src={json}
              theme={theme.reactJsonStyle === "dark" ? "tomorrow" : "rjv-default"}
              collapsed={expandAll ? false : defaultExpandedDepth + 1}
              enableClipboard
              displayDataTypes={false}
              displayObjectSize={false}
            />
          ) : (
            <JSONPretty
              id="json-pretty"
              data={json}
              mainStyle="font-family:monospace;"
            />
          )}
        </StyledJsonContainer>
      );
    } else {
      switch (json) {
        case undefined:
          return <StyledCode>undefined</StyledCode>;
        case null:
          return <StyledCode>null</StyledCode>;
        case true:
          return <StyledCode>true</StyledCode>;
        case false:
          return <StyledCode>false</StyledCode>;
      }

      return <StyledCode>{json}</StyledCode>;
    }
  }

  if (isEmpty(json)) {
    return isRoot ? null : (
      <AttributeContainer label={label}>
        <Empty value={json} />
      </AttributeContainer>
    );
  }
  const isArray = Array.isArray(json);

  return (
    <StyledJsonContainer
      className={classes}
      showContainerBorder={isRoot && showBorder && !canCollapse && !isTerse}
      isTerse={isTerse}
    >
      <StyledAttributeContainer>
        {canCollapse && (
          <LabelWithCollapse isNested={!isRoot}>
            <Label>{label ? getTitleCaseLabel(label) : "View details"}</Label>
            <LinkButton
              className={isCollapsed ? "collapsed" : "expanded"}
              type="link"
              icon="caret-right"
              onClick={toggleCollapsedState}
            />
          </LabelWithCollapse>
        )}
        <StyledContent shouldIndent={!isRoot || (isRoot && canCollapse)}>
          {!isCollapsed &&
            (isArray ? (
              <ul>
                {(json as Array<any>).map((item: any, index: number) => {
                  const value = getPossiblyJsonValue(item);
                  return isJson(value) ? (
                    <JsonChild
                      key={index}
                      isTerse={isTerse}
                      label={`${index + 1}`}
                      value={value}
                      depth={depth + 1}
                      defaultExpandedDepth={defaultExpandedDepth}
                    />
                  ) : (
                    <li key={index}>
                      <RenderValue value={value} />
                    </li>
                  );
                })}
              </ul>
            ) : (
              <>
                {Object.keys(json).map(key => {
                  const value = getPossiblyJsonValue(json[key]);
                  return isJson(value) ? (
                    <JsonChild
                      key={key}
                      isTerse={isTerse}
                      label={key}
                      value={value}
                      depth={depth + 1}
                      defaultExpandedDepth={defaultExpandedDepth}
                    />
                  ) : (
                    <AttributeContainer key={key} label={getTitleCaseLabel(key)}>
                      <RenderValue value={value} />
                    </AttributeContainer>
                  );
                })}
              </>
            ))}
        </StyledContent>
      </StyledAttributeContainer>
      {isTerse && <Empty text="See record" />}
    </StyledJsonContainer>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 170px;
`;

const SettingsContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
`;

const JsonViewer = (props: JsonViewerInterface) => {
  const [rawMode, setRawMode] = React.useState(props.rawMode || false);
  const toggleRawMode = React.useCallback(() => {
    setRawMode(!rawMode);
  }, [rawMode]);

  // if toggle is not on, we always want to use the `rawMode` passed in
  const renderRawMode = React.useMemo(() => {
    return props.showRawModeToggle ? rawMode : props.rawMode;
  }, [props.showRawModeToggle, rawMode, props.rawMode]);

  // if toggle is not on, we always want to use the `isRawModeInteractive` passed in
  const renderInteractiveRawMode = React.useMemo(() => {
    return props.showRawModeToggle ? true : props.isRawModeInteractive;
  }, [props.showRawModeToggle, props.isRawModeInteractive]);

  const menu = (
    <Menu>
      <Menu.Item key="toggleRawMode" onClick={toggleRawMode}>
        Render {rawMode ? "pretty" : "raw"} mode
      </Menu.Item>
    </Menu>
  );

  return (
    <Root>
      {props.showRawModeToggle && (
        <SettingsContainer>
          <Dropdown overlay={menu}>
            <Button icon="more" size="small" type="link" />
          </Dropdown>
        </SettingsContainer>
      )}
      <JsonViewerInternal
        depth={0}
        {...props}
        rawMode={renderRawMode}
        isRawModeInteractive={renderInteractiveRawMode}
      />
    </Root>
  );
};

export default JsonViewer;
