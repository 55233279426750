import React from "react";

import { useMutation } from "@apollo/react-hooks";
import { Input } from "antd";
import gql from "graphql-tag";
import styled from "styled-components";

import ColorPicker from "../../../common/ColorPicker";
import { MaterialIconType } from "../../../common/Icons/MaterialIcons";
import { IconSelect as BaseIconSelect } from "../../../common/IconSelect";
import LimitCounter from "../../../common/LimitCounter/LimitCounter";
import Message from "../../../common/Message";
import { ModalNew } from "../../../common/Modal";
import { ensureHexValue } from "../../../common/utils";
import { SpaceTileNode } from "../../hooks/useSpacesManager/useSpacesManager";

const Cols = styled.div`
  display: flex;
  justify-content: stretch;
  gap: ${props => props.theme.spacersm};
`;

const StyledInput = styled(Input)`
  margin-bottom: ${props => props.theme.spacermd};
`;

const Label = styled.label``;

const IconSelectLabel = styled(Label)`
  flex-grow: 1;
`;

const IconSelect = styled(BaseIconSelect)`
  display: block;
  margin-bottom: ${props => props.theme.spacermd};
`;

const StyledLimitCounter = styled(LimitCounter)`
  margin-top: ${props => props.theme.spacerxs};
  text-align: right;
`;

export default function ConfigureSpaceModal({
  space,
  onCancel
}: {
  space: SpaceTileNode;
  onCancel: () => void;
}) {
  const [draftSpace, setDraftSpace] = React.useState(space);
  const [configureSpace, { loading, error }] =
    useMutation<ConfigureSpaceVariables>(CONFIGURE_SPACE);

  React.useEffect(() => {
    if (error) {
      Message.error("Something went wrong. Please try again.");
    }
  }, [error]);

  const onChangeSpace = (change: Partial<SpaceTileNode>) => {
    setDraftSpace({
      ...draftSpace,
      ...change
    });
  };

  return (
    <ModalNew
      visible
      title="Space settings"
      confirmLoading={loading}
      onOk={async () => {
        const { id, name, icon, color, description } = draftSpace;
        await configureSpace({
          variables: {
            id,
            name,
            icon,
            // Use empty string for default color
            color: color ? ensureHexValue(color).substring(1) : "",
            description
          }
        });
        Message.success("Space settings updated.");
        onCancel();
      }}
      onCancel={onCancel}
    >
      <Label>
        Name
        <StyledInput
          data-test="spaceNameInput"
          value={draftSpace.name}
          onChange={ev => {
            onChangeSpace({ name: ev.target.value });
          }}
        />
      </Label>
      <Cols>
        <IconSelectLabel>
          Icon (optional)
          <IconSelect
            data-test="iconSelectDropdown"
            value={draftSpace.icon}
            color={draftSpace.color}
            onChange={(icon: MaterialIconType) => {
              onChangeSpace({ icon });
            }}
          />
        </IconSelectLabel>
        <Label>
          Color
          <ColorPicker
            value={ensureHexValue(draftSpace.color || "")}
            strategy="fixed"
            border={false}
            onChange={color => {
              onChangeSpace({ color });
            }}
          ></ColorPicker>
        </Label>
      </Cols>
      <Label>
        Description (optional)
        <Input.TextArea
          data-test="spaceDescInput"
          value={draftSpace.description}
          maxLength={1000}
          onChange={ev => {
            onChangeSpace({ description: ev.target.value });
          }}
        />
        <StyledLimitCounter current={draftSpace.description?.length} limit={1000} />
      </Label>
    </ModalNew>
  );
}

type ConfigureSpaceVariables = {
  id: string;
  name: string;
  icon: string;
  color: string;
  description: string;
};
const CONFIGURE_SPACE = gql`
  mutation ConfigureSpace(
    $id: ID!
    $name: String
    $icon: String
    $color: String
    $description: String
  ) {
    configureSpace(
      id: $id
      name: $name
      icon: $icon
      color: $color
      description: $description
    ) {
      space {
        id
        slug
        name
        description
        icon
        color
      }
    }
  }
`;
