import React from "react";

export const SANDBOX_IFRAME_ID = "code-sandbox";

export default function CodeSandbox() {
  return (
    <iframe
      title="Code Sandbox"
      id={SANDBOX_IFRAME_ID}
      sandbox="allow-scripts"
      src="/sandbox.html?cb=1"
      style={{ display: "none" }}
    ></iframe>
  );
}
