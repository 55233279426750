import React from "react";

import { Icon } from "antd";
import { get } from "lodash";
import styled from "styled-components";

import InputWaitSvg from "../../../../../assets/icons/box-right-arrow.svg";
import { DataValue } from "../../../../../types";
import JsonViewer from "../../../../common/JsonViewer";
import { useStableSpaceContext } from "../../SpaceContext";
import EmptyState from "../common/EmptyState";
import Panel from "../common/Panel";
import { useComponentStateContext } from "../contexts/ComponentStateContext";
import { Props } from "../SpaceComponent";

import { SpaceDataViewerProperties } from "./types";

const Root = styled(Panel)`
  header {
    flex-wrap: wrap;
    padding: 15px;
  }

  h2 {
    flex-grow: 1;
    margin: 5px;
  }
`;
Root.displayName = "Panel";

const DataViewerContainer = styled.div<{ empty?: boolean }>`
  ${props =>
    !props.empty
      ? `padding: ${props.theme.spacerlg};`
      : `width: 100%;
     height: 100%;
    `}
`;

function EmptyDataState({
  displayValue,
  properties,
  editMode
}: {
  displayValue?: DataValue;
  properties: SpaceDataViewerProperties;
  editMode: boolean;
}) {
  if (editMode && properties.binding && !displayValue) {
    return (
      <EmptyState
        message="Awaiting input from another component"
        icon={<Icon component={InputWaitSvg} />}
      />
    );
  }

  return <EmptyState message="No data associated with this component" />;
}

// An extremely simple component which displays a binding in a JSONViewer.
export default function SpaceDataViewer(props: Props) {
  // The spaceComponent represents the state of a particular instance of the SpaceDataViewer.  It's a SpaceComponentObject
  // which contains the attributes that all SpaceComponents share.
  const { spaceComponent } = props;
  // When we configure this component in the UI, this is where the values specific to this component live.
  const properties: SpaceDataViewerProperties = spaceComponent.properties;
  // The slug of the component we're bound to.
  const { binding, is_raw_mode, is_raw_mode_interactive, expand_all } = properties;

  const { input } = useComponentStateContext();
  const { editMode } = useStableSpaceContext();

  // This is the value we're going to display in the JSON Viewer.  We assume that the JSONViewer can handle it.
  const displayValue = get(input, binding);
  const isJSONObject = typeof displayValue === "object";

  // And the rest here is just normal react
  return (
    <Root title={spaceComponent.properties.title} hasError={props.hasConfigError}>
      <DataViewerContainer empty={!displayValue}>
        {displayValue ? (
          isJSONObject ? (
            <JsonViewer
              json={displayValue}
              showBorder={false}
              rawMode={is_raw_mode}
              isRawModeInteractive={is_raw_mode_interactive}
              expandAll={expand_all}
            />
          ) : (
            displayValue
          )
        ) : (
          <EmptyDataState
            displayValue={displayValue}
            properties={properties}
            editMode={editMode}
          />
        )}
      </DataViewerContainer>
    </Root>
  );
}
