import { Button } from "antd";
import styled from "styled-components";

import { colorTokens } from "../../../../../cssConstants";

import BaseConfigPipelineStep from "./ConfigPipelineStep";
import BaseStep from "./Step";
import BaseStepSummary from "./StepSummary";

export const AddStepButton = styled(Button)`
  &.ant-btn:not(.ant-btn-link):not(.new-btn) {
    background: ${colorTokens.grey1837};
    border: 1px solid ${colorTokens.grey1740};
  }
`;

export const StepListContainer = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StepSummary = styled(BaseStepSummary)`
  width: 100%;
  margin-bottom: 24px;
`;

export const ConfigPipelineStep = styled(BaseConfigPipelineStep)`
  width: 100%;
  margin-bottom: 24px;
`;

export const Step = styled(BaseStep)`
  width: 100%;
`;

export const StepSummaryButton = styled(StepSummary)`
  &:hover {
    cursor: pointer;
  }
`;

export const StepSummaryHeader = styled(BaseStepSummary)`
  border: none;
`;
