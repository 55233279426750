import React from "react";

import EmailSent from "../components/auth/EmailSent";
import Layout from "../components/auth/Layout";
import LoginForm from "../components/auth/LoginForm";
import { RequirePartialAuth } from "../components/auth/RequirePartialAuth";
import { LoginLinkButton, Subtitle, Title } from "../components/auth/styledComponents";
import TwoFactorForm from "../components/auth/TwoFactorForm";
import {
  AuthStatusProps,
  withAuthStatus
} from "../components/auth/WithAuthStatus/withAuthStatus";
import RequestPasswordResetSent from "../components/settings/password_reset/RequestPasswordResetConfirmation";
import RequestPasswordResetForm from "../components/settings/password_reset/RequestPasswordResetForm";
import ResetPasswordForm from "../components/settings/password_reset/ResetPasswordForm";
import { default as Confirm2FA } from "../components/settings/TwoFactor/ConfirmTwoFactor";
import SetUpTwoFactor from "../components/settings/TwoFactor/SetUpTwoFactor";
import { Routes } from "../constants";
import { BasicLayout, NoHeaderLayout } from "../layouts";

function Login({ status }: AuthStatusProps) {
  return (
    <NoHeaderLayout backgroundColor="black">
      <Layout
        left={
          <>
            <Title>Welcome back</Title>
            <Subtitle>New to Internal?</Subtitle>
            <div>
              <LoginLinkButton to={Routes.SIGNUP}>
                Register a new organization
              </LoginLinkButton>
            </div>
          </>
        }
        right={<LoginForm status={status} />}
      />
    </NoHeaderLayout>
  );
}

export const New = withAuthStatus(Login);

export const TwoFactor = () => (
  <RequirePartialAuth>
    <BasicLayout>
      <TwoFactorForm />
    </BasicLayout>
  </RequirePartialAuth>
);

export const ResetPasswordRequest = () => (
  <NoHeaderLayout backgroundColor="black">
    <Layout
      left={
        <>
          <Title>Let's get you back on track</Title>
          <Subtitle>
            Enter the email address tied to your account. If there's a match, we'll send
            an email with instructions for how to reset your password.
          </Subtitle>
        </>
      }
      right={<RequestPasswordResetForm />}
    />
  </NoHeaderLayout>
);

export const ResetPasswordComplete = () => (
  <NoHeaderLayout backgroundColor="black">
    <Layout
      left={
        <>
          <EmailSent />
          <Title>Please check your inbox</Title>
          <Subtitle>
            If there's an account that matches the email address you provided, you'll
            receive an email with instructions for how to reset your password.
          </Subtitle>
        </>
      }
      right={<RequestPasswordResetSent />}
      align="center"
    />
  </NoHeaderLayout>
);

export const ResetPasswordConfirm = () => (
  <NoHeaderLayout backgroundColor="black">
    <Layout
      left={
        <>
          <Title>Create a new password</Title>
          <Subtitle>To reset your password, create a new one for your account</Subtitle>
        </>
      }
      right={<ResetPasswordForm />}
    />
  </NoHeaderLayout>
);

export const SetupTwoFactor = () => (
  <RequirePartialAuth>
    <BasicLayout>
      <SetUpTwoFactor />
    </BasicLayout>
  </RequirePartialAuth>
);

export const ConfirmTwoFactor = () => (
  <RequirePartialAuth>
    <BasicLayout>
      <Confirm2FA />
    </BasicLayout>
  </RequirePartialAuth>
);
