import { SpaceComponentObject } from "../../../../types";

/*
  Returns which component to select according to the following logic:
  - if the attempted selection is not in a sub space return it
  - if the attempted selection is in a sub space and the sub space or one of its descendants
    IS NOT already selected return the sub space
  - if the attempted selection is in a sub space and the sub space or one of its descandants
    IS already selected return the requested component
  
  @param slug - slug of the component that was selected
  @param currentSelection - slug of the currently selected component or null
  @param components - definitions of all components in the space
  @returns slug of the component which will be selected
*/
export function getSelectionTarget(
  slug: string,
  currentSelection: string | null,
  components: SpaceComponentObject[]
) {
  const component = components.find(c => c.slug === slug)!;
  // Find the closest ancestor sub space
  let maybeClosestSubSpace: SpaceComponentObject | null = component;
  let closestSubSpace = null;
  while (maybeClosestSubSpace) {
    if (maybeClosestSubSpace.type === "SUB_SPACE") {
      closestSubSpace = maybeClosestSubSpace;
      break;
    }
    maybeClosestSubSpace = maybeClosestSubSpace.container;
  }

  if (closestSubSpace) {
    // Closest sub space is already selected so allow it or its descendants to be selected
    if (currentSelection === closestSubSpace.slug) {
      return slug;
    }

    // Check if another descendant of the closest sub space is selected
    let isSubSpaceDescendantSelected = false;
    if (currentSelection) {
      let maybeSubSpaceDescendant: SpaceComponentObject | null =
        components.find(c => c.slug === currentSelection) || null;
      if (!maybeSubSpaceDescendant)
        throw new Error(`Could not find component with slug ${currentSelection}`);

      while (maybeSubSpaceDescendant) {
        if (maybeSubSpaceDescendant.container?.slug === closestSubSpace.slug) {
          isSubSpaceDescendantSelected = true;
          break;
        }
        maybeSubSpaceDescendant = maybeSubSpaceDescendant.container;
      }
    }

    // A descadant of the sub space is selected so allow the sub space or another descendant to be selected
    if (isSubSpaceDescendantSelected) {
      return slug;
    }

    // Otherwise select the closest sub space
    return closestSubSpace.slug;
  }
  return slug;
}
