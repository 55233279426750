import { isPlainObject } from "is-plain-object";
import _ from "lodash";

import { AttributeTypes, ReturnSchema } from "../../../../constants";
import {
  FunctionAttribute,
  FunctionNode,
  ViewFilter,
  ViewFilterOperator
} from "../../../../types";
import { PipelineStepType } from "../forms/pipeline/constants";
import {
  ParameterValues,
  ReservedListParameter,
  ReservedListParameterDefault
} from "../index";
import {
  ConditionActionType,
  Conditional,
  PipelineStep
} from "../useFunctionEditor/queries";

export const serialize = (parameterValues: ParameterValues) =>
  Object.entries(parameterValues).reduce<{ [s: string]: any }>((acc, [k, v]) => {
    if (v === undefined) return acc;
    if (k === ReservedListParameter.FILTERS) {
      const viewFilters = v as ViewFilter[];
      const filters: { [k: string]: any }[] = [];
      const filter: { [k: string]: any } = {};

      viewFilters.forEach(f => {
        if (!f.sourceName) return;
        filters.push({
          attribute: f.sourceName,
          operator: f.operator,
          value: f.value
        });
        if (f.operator === ViewFilterOperator.EQUALS) {
          filter[f.sourceName] = f.value;
        }
      });
      acc["filters"] = filters;
      acc["filter"] = filter;
    } else if (k !== ReservedListParameter.FILTER) {
      acc[k] = v;
    }
    return acc;
  }, {});

export const fillDefaults = (parameterValues: ParameterValues) => ({
  ...parameterValues,
  ...Object.keys(ReservedListParameterDefault).reduce<ParameterValues>((acc, name) => {
    const key = name as ReservedListParameter;
    if (
      parameterValues[key] === undefined &&
      ReservedListParameterDefault[key] !== undefined
    ) {
      acc[key] = ReservedListParameterDefault[key];
    }
    return acc;
  }, {})
});

export const isValidListOutput = (data: any) => {
  if (_.isArray(data)) {
    return data.length ? isPlainObject(data[0]) : true;
  } else {
    return isPlainObject(data);
  }
};

export const readReturnSchema = (value: any): ReturnSchema => {
  if (isPlainObject(value)) {
    return ReturnSchema.OBJECT;
  } else if (_.isArray(value) && value.length && isPlainObject(value[0])) {
    return ReturnSchema.OBJECT_ARRAY;
  } else {
    return ReturnSchema.UNKNOWN;
  }
};

export const getAttributeType = (v: any) => {
  switch (typeof v) {
    case "number":
      return v % 1 === 0 ? AttributeTypes.INT : AttributeTypes.FLOAT;
    case "boolean":
      return AttributeTypes.BOOL;
    case "string":
      return AttributeTypes.STRING;
    default:
      return AttributeTypes.JSON;
  }
};

export const attributesFromObject = (obj: object) =>
  Object.entries(obj).reduce<Record<string, AttributeTypes>>((acc, [k, v]) => {
    acc[k] = getAttributeType(v);
    return acc;
  }, {});

export const readAttributes = (data: any) => {
  const sample = _.isArray(data) ? data : [data];
  const records = sample.reduce<Record<string, AttributeTypes>>((acc, obj) => {
    return { ...acc, ...attributesFromObject(obj) };
  }, {});
  return Object.entries(records).map<FunctionAttribute>(([k, v]) => ({
    key: false,
    name: k,
    type: v
  }));
};

export type PipelineStepPartial = {
  title: string;
  slug: string;
  type?: PipelineStepType;
  function?: FunctionNode;
  expression?: string;
  conditions?: Conditional[];
  iteratorBinding?: string;
  iteratorItemName?: string;
};

function conditionToString(conditional: Conditional): string {
  if (conditional.condition && conditional.condition !== "") {
    return conditional.condition!;
  }

  if (conditional.parts?.length) {
    const conjunctions = ["", ...(conditional.conjunctions || [])];

    let result = "";

    for (let i = 0; i < conditional.parts!.length; i++) {
      const part = conditional.parts![i];
      result += `${conjunctions[i]} ${part.lhs} ${part.operator || ""} ${part.rhs}`;
    }

    return result.trim();
  }

  return "";
}

// Note: this is currently only used for preview but can potentially be used when
// validating a pipeline upon Save. Might make sense to restructure the output
// to allow for rendering breadcrumbs for errors (ie. at pipeline step level
// and on individual field).
export const validatePipelineSteps = (
  steps: PipelineStepPartial[] | undefined
): string[] => {
  if (!steps || !steps.length) {
    return [
      "This function is missing steps. At least one step needs to be configured."
    ];
  }

  const errors: string[] = [];
  steps.forEach((step, index) => {
    const stepNumber = index + 1;
    if (!step.type) {
      errors.push(`Step ${stepNumber} is missing an action type`);
    }
    switch (step.type) {
      case PipelineStepType.FUNCTION:
        if (!step.function) {
          errors.push(`Step ${stepNumber} is missing a function`);
        }
        break;
      case PipelineStepType.EXPRESSION:
        if (!step.expression) {
          errors.push(`Step ${stepNumber} is missing an expression`);
        }
        break;
      case PipelineStepType.CONDITIONAL:
        if (!step.conditions || step.conditions.length === 0) {
          errors.push(`Step ${stepNumber} needs at least one conditional`);
        } else {
          const conditionString = conditionToString(step.conditions![0]);
          if (conditionString.replaceAll("`", "").trim().length === 0) {
            errors.push(`Step ${stepNumber} needs a condition`);
          }

          const isInvalid = step.conditions.find(c => {
            return (
              c.actionType === ConditionActionType.PENDING ||
              (c.actionType === ConditionActionType.FUNCTION && !c.functionId)
            );
          });
          if (isInvalid) {
            errors.push(
              `Step ${stepNumber}'s conditional needs to be fully configured`
            );
          }
        }
        break;
      case PipelineStepType.LOOP:
        if (!step.iteratorBinding) {
          errors.push(`Step ${stepNumber} is missing a source to loop over`);
        }

        if (!step.function) {
          errors.push(`Step ${stepNumber} is missing a function`);
        }
    }
  });
  return errors;
};

export const toPipelineStepInput = (step: PipelineStep, index: number) => ({
  title: step.title || `step${index}`,
  slug: step.slug,
  type: step.type!,
  inputParameters: step.inputParameters,
  functionId: step.function?.id,
  expression: step.expression,
  iteratorBinding: step.iteratorBinding,
  iteratorItemName: step.iteratorItemName,
  cases: step.conditions?.map(c => ({
    condition: c.condition,
    parts: c.parts,
    conjunctions: c.conjunctions,
    functionId: c.functionId,
    inputParameters: c.inputParameters,
    actionType: c.actionType
  }))
});
