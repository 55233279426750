import React, { useEffect, useRef } from "react";

import useObservedRect from "../../../common/hooks/useObservedRect";
import { useCanvasViewportContext } from "../Canvas/CanvasViewportContext";
import { useTransformationActionContext } from "../TransformationContext/TransformationContext";

export default function DragBoundary({
  disable,
  children
}: {
  disable: boolean;
  children: React.ReactNode;
}) {
  const elRef = useRef<HTMLDivElement>(null);
  const { registerDragBoundary } = useTransformationActionContext();
  const { viewportOffset } = useCanvasViewportContext();
  const rect = useObservedRect(elRef);
  useEffect(() => {
    rect.x -= viewportOffset.x;
    rect.y -= viewportOffset.y;
    registerDragBoundary(disable ? null : rect);
    return () => {
      registerDragBoundary(null);
    };
  }, [rect, viewportOffset, disable, registerDragBoundary]);

  return <div ref={elRef}>{children}</div>;
}
