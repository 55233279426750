import React, { useState } from "react";

import EmailSent from "../auth/EmailSent";
import Layout from "../auth/Layout";
import {
  Subtitle,
  TitleBottom,
  Title,
  TitleTop,
  MediumTitle
} from "../auth/styledComponents";

import CreateOrganizationForm from "./CreateOrganizationForm";

function InformationComponent({
  confirmEmail,
  requestEmail
}: {
  confirmEmail: string;
  requestEmail: string;
}) {
  if (confirmEmail) {
    return (
      <>
        <EmailSent />
        <Title>Please check your inbox</Title>
        <Subtitle>We sent a verification link to {confirmEmail}</Subtitle>
      </>
    );
  }

  const domain = requestEmail.split("@")[1];

  if (requestEmail) {
    return (
      <>
        <Title>Good news!</Title>
        <MediumTitle>Your company has an Internal account.</MediumTitle>
        <Subtitle>
          {domain} already has an Internal account. Do you want to request access?
        </Subtitle>
      </>
    );
  }

  return (
    <>
      <TitleTop>Create a</TitleTop>
      <TitleBottom>free account</TitleBottom>
      <Subtitle>Get started by entering your work email</Subtitle>
    </>
  );
}

export default function CreateOrganization() {
  const [confirmEmail, setConfirmEmail] = useState<string>("");
  const [requestEmail, setRequestEmail] = useState<string>("");

  return (
    <Layout
      showActions={true}
      left={
        <InformationComponent confirmEmail={confirmEmail} requestEmail={requestEmail} />
      }
      right={
        <CreateOrganizationForm
          confirmEmail={confirmEmail}
          setConfirmEmail={setConfirmEmail}
          requestEmail={requestEmail}
          setRequestEmail={setRequestEmail}
        />
      }
      align={confirmEmail ? "center" : "start"}
    />
  );
}
