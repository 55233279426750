import gql from "graphql-tag";

import { Connection, InputParameter, RelayNode } from "../../../../types";
import { IntervalPeriod } from "../types";

export interface FunctionNode extends RelayNode {
  __typename: "FunctionNode";
  name: string;
}

type EnvironmentNode = RelayNode;
export interface AutomationEnvironmentNode extends RelayNode {
  environment: EnvironmentNode;
}
export interface AutomationNode extends RelayNode {
  __typename: "AutomationNode";
  name: string;
  description: string;
  icon: string;
  color: string;
  slug: string;
  intervalPeriod: IntervalPeriod;
  frequency: number;
  daysOfWeek: number[];
  daysOfMonth: number[];
  triggerTime: string | undefined;
  startAt: string | undefined;
  endAt: string | undefined;
  function?: FunctionNode; // function might be empty if it was deleted externally
  inputParameters: InputParameter[];
  automationEnvironments: Connection<AutomationEnvironmentNode>;
}

export const AUTOMATION_NODE_FRAGMENT = gql`
  fragment AutomationNodeFragment on AutomationNode {
    id
    name
    description
    icon
    color
    slug
    intervalPeriod
    frequency
    daysOfWeek
    daysOfMonth
    triggerTime
    startAt
    endAt
    function {
      id
      name
    }
    inputParameters {
      binding
      componentProperties
      componentType
      name
      resolver
      template
      type
      value
    }
    automationEnvironments {
      edges {
        node {
          id
          environment {
            id
          }
        }
      }
    }
  }
`;
