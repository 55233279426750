import styled from "styled-components";

import { colorTokens } from "../../../../../../cssConstants";

export const Container = styled.div`
  background-color: ${colorTokens.grey1837};
  border: 1px solid ${props => props.theme.primaryAccent};
  border-radius: ${props => props.theme.containerPrimaryBorderRadius};
`;

export const HeaderContainer = styled.div`
  padding: 16px;
  background-color: ${colorTokens.grey1837};
  border: 1px solid ${colorTokens.grey1740};
  border-radius: ${props => props.theme.containerPrimaryBorderRadius};

  display: flex;
  gap: 24px;
  align-items: center;
`;

export const BodyContainer = styled.div`
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
`;

export const StepContentContainer = styled.div`
  margin-top: ${props => props.theme.spacermd};
`;
