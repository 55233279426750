import React from "react";

import { Dropdown, Icon, Menu, Tabs } from "antd";
import styled from "styled-components";

import { SpacingUnitValue } from "../../../../../../cssConstants";
import { SpaceComponentObject } from "../../../../../../types";
import TabTitle from "../../../../../common/TabTitle";
import {
  useSelectionStateContext,
  useTransformationActionContext
} from "../../../../layout/TransformationContext/TransformationContext";
import { useSpaceConfigContext } from "../../../../SpaceConfig/SpaceConfigContext";
import { SpaceConfigAction, ComponentConfigState } from "../../../../types";
import { useStableSpaceContext, useSpaceContext } from "../../../SpaceContext";
import { ComponentContextContainer } from "../../contexts/ComponentContext";
import CommonDesignConfig from "../DesignConfig";

import NestedIcon from "./NestedIcon";
import ConfigSection from "./Section";
import { StyledConfigContainer } from "./styledComponents";

const ConfigPanelContainer = styled(StyledConfigContainer)`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.backgroundColor};
  color: ${props => props.theme.textColorMid};
  font-size: ${props => props.theme.defaultFontSize};
  font-weight: normal;
  line-height: ${props => props.theme.defaultLineHeight};
  overflow-y: auto;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.selectBackgroundColor};
  padding: ${props => props.theme.spacermd};
`;

const HeaderText = styled.span`
  margin-bottom: ${props => props.theme.spacerxs};
`;

const HeaderDropdownText = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  h1 {
    margin: 0;
    margin-right: ${props => props.theme.spacersm};
  }
`;

const NestedMenuText = styled.span`
  margin-left: ${props => props.theme.spacersm};
`;

const StyledMenuItem = styled(Menu.Item)<{ index: number }>`
  display: flex;
  align-items: flex-start;
  padding-left: ${props => SpacingUnitValue.sm * (props.index + 1)}px;
`;

const EmptyConfigSection = styled(ConfigSection)`
  padding: ${props => props.theme.spacerxl};
  text-align: center;
`;

const CompactTabs = styled(Tabs)`
  .ant-tabs-nav .ant-tabs-tab {
    margin: 0;
    padding: 12px ${props => props.theme.spacerxs};
  }
`;

interface TabbedConfigProps {
  dataConfigContent: React.ReactNode;
  effectsConfigContent?: React.ReactNode;
  designConfigContent?: React.ReactNode;
  dataTabTitle?: React.ReactNode;
}
const TabbedConfig = (props: TabbedConfigProps) => {
  const hasOnlyDataTab =
    props.dataConfigContent &&
    !props.effectsConfigContent &&
    !props.designConfigContent;

  return hasOnlyDataTab ? (
    <>{props.dataConfigContent}</>
  ) : (
    <CompactTabs>
      <Tabs.TabPane
        key="data"
        tab={
          <TabTitle dataTest="data-tab" errorFillStyle="filled">
            {props.dataTabTitle ? props.dataTabTitle : <div>Data</div>}
          </TabTitle>
        }
      >
        {props.dataConfigContent}
      </Tabs.TabPane>

      {props.effectsConfigContent && (
        <Tabs.TabPane
          key="actions"
          tab={
            <TabTitle dataTest="effects-tab" errorFillStyle="filled">
              <div>Effects</div>
            </TabTitle>
          }
        >
          {props.effectsConfigContent}
        </Tabs.TabPane>
      )}

      {props.designConfigContent && (
        <Tabs.TabPane
          key="design"
          tab={
            <TabTitle dataTest="design-tab" errorFillStyle="filled">
              <div>Design</div>
            </TabTitle>
          }
        >
          {props.designConfigContent}
        </Tabs.TabPane>
      )}
    </CompactTabs>
  );
};

interface ConfigPanelProps {
  spaceComponent: SpaceComponentObject;
  state?: ComponentConfigState;
  dispatch?: React.Dispatch<SpaceConfigAction>;
  valid?: boolean;
  errorMessage?: string;
  dataConfig: React.ReactNode; // for now, required until we have use case to make it optional
  dataTabTitle?: React.ReactNode;
  effectsConfig?: React.ReactNode;
  designConfig?: React.ReactNode;
}

export const ConfigPanelContent = (props: ConfigPanelProps) => {
  const { getSpaceComponentDisplayName } = useStableSpaceContext();
  const { getAncestorComponents } = useSpaceContext();
  const { select } = useTransformationActionContext();
  const { selected: selectedSlug } = useSelectionStateContext();

  const activeAncestorComponentPath: SpaceComponentObject[] = React.useMemo(() => {
    if (!selectedSlug) {
      return [];
    }
    return getAncestorComponents(selectedSlug);
  }, [selectedSlug, getAncestorComponents]);

  const designConfig = React.useMemo(() => {
    return (
      <>
        <CommonDesignConfig />
        {props.designConfig}
      </>
    );
  }, [props.designConfig]);

  const componentDisplayName = getSpaceComponentDisplayName(props.spaceComponent);

  const isRootOpen = activeAncestorComponentPath.length === 1;

  const getMenuItems = () => {
    if (isRootOpen) return null;
    const menuItems: React.ReactNode[] = activeAncestorComponentPath.map(
      (componentNode, index) => {
        const displayName = getSpaceComponentDisplayName(componentNode);

        return (
          <StyledMenuItem
            index={index}
            key={`configPanelMenu-${index}`}
            data-test={`config-panel-ancestor-menu-item-${index}`}
            onClick={() => {
              select(componentNode.slug);
            }}
          >
            {index !== 0 && <NestedIcon />}
            <NestedMenuText>{displayName}</NestedMenuText>
          </StyledMenuItem>
        );
      }
    );
    return menuItems;
  };

  return (
    <ConfigPanelContainer key={props.spaceComponent.slug} data-test="config-panel">
      <HeaderContainer>
        <HeaderText>Selected component</HeaderText>
        {isRootOpen ? (
          <h1>{componentDisplayName}</h1>
        ) : (
          <Dropdown
            getPopupContainer={trigger => trigger.parentNode as HTMLElement}
            trigger={["click"]}
            overlay={<Menu>{getMenuItems()}</Menu>}
          >
            <HeaderDropdownText data-test="config-panel-ancestor-menu-trigger">
              <h1>{componentDisplayName}</h1>
              <Icon type="down" />
            </HeaderDropdownText>
          </Dropdown>
        )}
      </HeaderContainer>

      <TabbedConfig
        dataConfigContent={props.dataConfig}
        dataTabTitle={props.dataTabTitle}
        effectsConfigContent={props.effectsConfig}
        designConfigContent={designConfig}
      />
    </ConfigPanelContainer>
  );
};

function EmptyConfigPanel() {
  return (
    <ConfigPanelContainer>
      <HeaderContainer>
        <HeaderText>Selected component</HeaderText>
        <h1>None</h1>
      </HeaderContainer>
      <EmptyConfigSection>
        Select a component on the canvas to edit it in this panel.
      </EmptyConfigSection>
    </ConfigPanelContainer>
  );
}

function ConfigPanel() {
  const { findSpaceComponentPackage } = useStableSpaceContext();
  const { selected: selectedSlug } = useSelectionStateContext();
  const { getComponentConfig } = useSpaceConfigContext();
  const component = React.useMemo(
    () => (selectedSlug ? getComponentConfig(selectedSlug).draftComponent : undefined),
    [selectedSlug, getComponentConfig]
  );

  if (!selectedSlug || !component) {
    return <EmptyConfigPanel />;
  }

  const pkg = findSpaceComponentPackage(component.type);
  if (!pkg) {
    throw new Error(`Expected Component Package for ${component.type}`);
  }
  const ConfigComponent = pkg.ConfigurationComponent;
  if (!ConfigComponent) {
    return <EmptyConfigPanel />;
  }

  return (
    <ComponentContextContainer component={component}>
      <ConfigComponent slug={selectedSlug} />
    </ComponentContextContainer>
  );
}

export default ConfigPanel;
