import React, { useState } from "react";

import moment from "moment";
import {
  ResponsiveContainer,
  LineChart as BaseLineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Dot
} from "recharts";

import {
  chartColors,
  getChartLabelColor,
  SpacingUnitValue
} from "../../../../../../cssConstants";
import { useComponentStateContext } from "../../contexts/ComponentStateContext";

import { ChartData } from "./selectChartData";
import * as styled from "./styledComponents";

interface Props {
  value: ChartData;
}

export const getTickFormat = (values: string[]) => {
  if (!moment.isMoment(moment(values[0]))) return;
  const duration = moment.duration(
    moment(values[values.length - 1]).diff(moment(values[0]))
  );
  if (!duration.isValid()) return;
  if (Math.abs(duration.asYears()) > 1) {
    return "YYYY";
  }
  if (Math.abs(duration.asYears()) === 1) {
    return "MMM YYYY";
  }
  if (Math.abs(duration.asWeeks()) > 1) {
    return "M/D";
  }
  if (Math.abs(duration.asDays()) > 0) {
    return "M/D ha";
  }

  return "M/D hh:mm";
};

const CustomTooltip = ({ payload, label, canFormat }: any) => {
  const value = canFormat ? moment(label).format("YYYY-MM-DD hh:mm:ss") : label;
  return (
    <styled.TooltipContainer>
      <p>{value}</p>
      {(payload || []).map((p: any, i: number) => {
        // For a line chart with multiple lines, all lines aren't guaranteed the same x-axis value
        // Some tooltips may only explain 2/3 lines
        // Therefore we cannot use index to choose color
        return (
          <p key={`${p.name}:${i}`} style={{ color: getChartLabelColor(p.stroke) }}>
            {p.name}: {p.payload[p.name]}
          </p>
        );
      })}
    </styled.TooltipContainer>
  );
};

export default function LineChart({ value: { data, dataKeys, xAxisFilter } }: Props) {
  const colors = Object.values(chartColors);
  const tickFormat = getTickFormat(data.map(d => d.__xAxisDataKey));

  const { recursivelyClearOutput, updateOutput } = useComponentStateContext();
  const [activeIdx, setActiveIdx] = useState(-1);

  const handleSelectItemClick = (arg: any) => {
    const index = arg.index;
    if (index === activeIdx) {
      recursivelyClearOutput();
      setActiveIdx(-1);
    } else {
      updateOutput({
        selectedItem: { data: xAxisFilter(arg.payload.__xAxisDataKey) }
      });
      setActiveIdx(index);
    }
  };

  const lines = dataKeys.map((k, i) => (
    <Line
      key={k}
      dataKey={k}
      stroke={colors[i % colors.length]}
      dot={(arg: any) => (
        <Dot
          {...arg}
          fill={colors[i % colors.length]}
          r={arg.index === activeIdx ? 5 : 0}
        />
      )}
      connectNulls
      activeDot={{ onClick: handleSelectItemClick, r: 6 }}
    />
  ));
  return (
    <ResponsiveContainer>
      <BaseLineChart data={data}>
        <XAxis
          minTickGap={SpacingUnitValue.lg}
          tickFormatter={(p: string) =>
            tickFormat ? moment(p as string | number).format(tickFormat) : p
          }
          dataKey="__xAxisDataKey"
        />
        <YAxis />
        <Tooltip content={<CustomTooltip canFormat={!!tickFormat} />} />
        <Legend />
        {lines}
      </BaseLineChart>
    </ResponsiveContainer>
  );
}
